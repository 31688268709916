import { marketPlaceEndpoints } from "Pages/MarketplaceNew/marketplace.api";
import api, { marketPlaceUrl } from "../../Utils/api";

export const submitApplication = async (data) => {
  try {
    const res = await api.post(`${marketPlaceUrl}${marketPlaceEndpoints.applyJob}`, data);
    if (res.data && res.data.success) {
      return { success: true, message: res.data.message };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
};
