import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ListOfResources from "./ListOfResources.job";
import ConfirmedScreen from "./ConfirmedScreen.jobs";
import useResource from "Pages/ResourcesPage/useResource";
import axios from "axios";
import { getUserIdFromLocalStorage } from "Utils/helper";
import { applyHandler } from "Pages/Jobs/jobs.api";
import { useAlertProvider } from "Providers/util/Alert";
import api from "Utils/api";
import { useRouteLoaderData } from "react-router-dom";

const Applicants = () => {
  const data = useRouteLoaderData('jobDetail');
  const jobDetails = data?.data?.result || {};
  const [selectedTab, setSelectedTab] = useState("resources");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { success, error } = useAlertProvider();
  const [selectedResources, setSelectedResources] = useState([]);
  const [externalResoiuce, setExternalResoiuce] = useState([])
  const { resourcesData, fetchResourcesDetails } = useResource({
    searchValue: "",
    selectedTab: 0,
  });

  const handleToggleResource = (resource) => {
    const isSelected = selectedResources.includes(resource);
    setSelectedResources((prevSelected) =>
      isSelected
        ? prevSelected.filter((r) => r !== resource)
        : [...prevSelected, resource]
    );
  };

  useEffect(() => {
    if (selectedTab === "resources") {
      fetchResourcesDetails();
    } else {
      const userId = getUserIdFromLocalStorage();
      api
        .get(
          `${process.env.REACT_APP_MARKETPLACE_URL}/jobapplication/get/job/${jobDetails.id}`
        )
        .then((res) => {
          const jobApplication = res?.data?.data?.job_application || [];
          const consultantDetails = res?.data?.data?.consultant_details || [];
          if (jobApplication && jobApplication.length && consultantDetails && consultantDetails.length) {
            const applicantFilter = jobApplication.filter(
              (index) =>
                index.job_id === jobDetails.id && index?.vendor_id !== userId
            );
            const arr = consultantDetails
              .filter((detail) =>
                applicantFilter.some(
                  (applicant) => applicant.applicant_id === detail.id
                )
              )
              .map((consultantDetail) => ({
                ...consultantDetail,
                //isSubmit: true,
              }));
            setExternalResoiuce(arr);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedTab, jobDetails]);

  const fetchData = async (jobId, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MARKETPLACE_URL}/jobapplication/get/job/${jobId}`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      const applicants = response?.data?.data?.consultant_details || [];
      const filteredResources = (resourcesData || []).filter(resource => resource.status === 'Current');

      for (const resource of filteredResources) {
        const isSubmitted = applicants.some(applicant => applicant.id === resource.consultant_id);
        //resource.isSubmit = isSubmitted;
      }

      setExternalResoiuce(filteredResources);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedTab === "resources") {
      fetchData(jobDetails.id, localStorage.getItem('at'));
    }
  }, [selectedTab, resourcesData, jobDetails.id]);

  const submitHandler = async () => {
    const vendorid = getUserIdFromLocalStorage();
    const consultantsToSubmit = selectedResources.map(selected => selected.id)

    let parent_job_id = null;
    const job_owner_id = jobDetails.created_by;
    if (job_owner_id === vendorid) {
      parent_job_id = jobDetails.parent_job_id;
    }

    const postData = JSON.stringify({
      job_id: job_owner_id === jobDetails.created_by ? parent_job_id : jobDetails.id,
      applicant_id: consultantsToSubmit,
      vendor_id: vendorid,
      created_by: job_owner_id,
      //parent_job_id: parent_job_id
    })

    try {
      const resp = await applyHandler(postData)
      if (resp && resp.data && resp.data.success) {
        return success('Applied Successfully')
      }
      error(resp?.data?.error || 'Something went wrong!!!')
    } catch (e) {
      error(e?.response?.data.error)
    }
  }

  return (
    <Box>
      {!isConfirmed && <Box sx={{ marginTop: "10px", marginBottom: "20px" }}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={selectedTab}
            variant="fullWidth"
            onChange={(e, value) => setSelectedTab(value)}
          >
            <Tab
              sx={{ background: selectedTab == "resources" ? "#F2FBFF" : "" }}
              value={"resources"}
              label="Resources"
            />
            <Tab
              sx={{ background: selectedTab == "external" ? "#F2FBFF" : "" }}
              value={"external"}
              label="External applicants"
            />
          </Tabs>
        </Box>
      </Box>}
      {!isConfirmed && selectedTab == "resources" && <ListOfResources selectedResources={selectedResources} resouceData={externalResoiuce} setSelectedResources={setSelectedResources} handleToggleResource={handleToggleResource} />}
      {!isConfirmed && selectedTab == "external" && <ListOfResources selectedResources={selectedResources} resouceData={externalResoiuce} setSelectedResources={setSelectedResources} handleToggleResource={handleToggleResource} />}
      {isConfirmed && <ConfirmedScreen selectedResources={selectedResources} handleToggleResource={handleToggleResource} />}
      <Box sx={{ width: '100%', padding: '30px', display: 'flex', justifyContent: 'space-between', background: 'white', position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <Typography variant="body2Semibold">Selected Resources: {selectedResources.length}</Typography>
        {!isConfirmed && <Button variant="contained" onClick={() => setIsConfirmed(true)}>Confirm & Next</Button>}
        {isConfirmed && <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button variant="contained" onClick={() => setIsConfirmed(false)}>Back</Button>
          <Button variant="contained" onClick={submitHandler}>Submit</Button>
        </Box>}
      </Box>
    </Box>
  );
};

export default Applicants;
