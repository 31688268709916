import Button from 'Components/Common/semantic_tags/Button';
import Typography from 'Components/Common/semantic_tags/Typography';
import PricingCards from 'Pages/Settings/UserManagementTab/PricingPlans.jsx/PricingCards';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectIsUserLoggedIn } from 'Redux/Feature/user';
import { fetchActiveSubscription } from 'services/subscriptions.service';

const tabs = ["CONSULTANT", "VENDOR", "ENTERPRISE"];

const PricingPageNew = () => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const navigate = useNavigate()
    const [subscription, setSubscription] = useState(null)
    const isLoggedIn = useSelector(selectIsUserLoggedIn)

    useEffect(() => {
        isLoggedIn && fetchActiveSubscription().then(({ data }) => setSubscription(data.subscription));
    }, [isLoggedIn])

    return (
        <div className="bg-white p-4 pt-16">
            <div className="text-center mb-12">
                <Typography className='text-[#3985B6] font-semibold tracking-widest'>PRICING</Typography>
                <Typography className="text-[#170F49] text-4xl font-semibold mb-2 tracking-wider leading-[45px]">Unlock Your Potential with<br /> ProLegion's Flexible Pricing Plans</Typography>
                <Typography className="text-[#6F6C90] text-md">Boost the efficiency of your IT workforce with ProLegion - a simplified <br /> and unified platform that enables a seamless experience.</Typography>
                <Button title='Get Started' onClick={() => navigate('/signin')} className='px-4' parentClassName='flex justify-center w-full items-center mt-6' />
            </div>
            <div className='flex justify-center w-full'>
                <div className="flex justify-center mb-8 border-b border-gray-200 gap-[60px] w-[70%]">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            className={`px-4 py-2 text-lg font-medium ${activeTab === tab ? 'text-[#3985B6] border-b-2 border-[#3985B6]' : 'text-[#6F6C90]'}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            </div>
            <PricingCards isPublic={true} activeSubscription={subscription} setIsUpgradePlan={() => {}} roles={activeTab === 'ENTERPRISE' ? 'CLIENT' : activeTab} />
        </div>
    );
};

export default PricingPageNew;
