import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import MuiModal from "Components/Common/MuiModal";
import ReactDropzone from "Components/Common/ReactDropzone";
import MyEditor from "Components/TextEditor";
import useDebounce from "hooks/useDebounce";
import { countLettersWithoutTags } from "Pages/Dashboard/components/Jobs/validation";
import { useAlertProvider } from "Providers/util/Alert";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserDetails } from "Redux/Feature/user";
import { isEmailValid } from "Utils/helper";
import { fetchShareToList, shareDocument } from "./document.api";
import Button from "Components/Common/semantic_tags/Button";
import CustomInput from "Components/Common/semantic_tags/Input";

const initalValue = {
  name: "",
  description: '',
  // isApprovedRequired: false,
  receiver_user_id: [],
}

const DocumentModal = ({ openModal, onClose, options, isDraft, getDocuments }) => {
  const userDetails = useSelector(selectUserDetails);
  const [errorObj, setErrorObj] = useState({});
  const [shareTo, setShareTo] = useState([])
  const [files, setFiles] = useState([]);
  const [selectedOption, setSelectedOption] = useState(options[0]?.value || '')
  const [documentDetails, setDocumentDetails] = useState(initalValue);
	const [currEmail, setCurrEmail] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
  const searchQueryRes = useDebounce(searchQuery, 2000);
  const { success, error } = useAlertProvider();

  useMemo(()=>{
    //refresh the options selected
    setDocumentDetails(initalValue);
    setSelectedOption('')
  },[!openModal])

  const emptyHandler = () => setDocumentDetails(initalValue)
  const changeHandler = (name, value) => {
    return setDocumentDetails({ ...documentDetails, [name]: value })
  };

  const fetchHandler = async (option) => {
    if(selectedOption !== "outside-prolegion") {
    setDocumentDetails({ ...documentDetails, receiver_user_id: [] })
    setShareTo([])
    const resp = await fetchShareToList(userDetails?.role, option, userDetails?.user_id, searchQueryRes)
    setShareTo(resp.data)
  }
  }

  useMemo(()=> {
    if(selectedOption === 'outside-prolegion') {
      setShareTo([]);
      setDocumentDetails((prev)=>({ ...prev, receiver_user_id: [] }))
    }
  },[selectedOption])

  useEffect(() => {
    fetchHandler(selectedOption)
  }, [selectedOption, searchQueryRes])

  const submitHandler = async () => {
    const url = documentDetails?.files[0]?.url || "";
    const id = documentDetails?.files[0]?.document_id || "";
    delete documentDetails?.files;
    const resp = await shareDocument({
      ...documentDetails,
      file_path: url || "",
      // status: 'REQUESTED',
      description: documentDetails.description,
      document_share_type: isDraft ? 'DRAFT' : 'SHARE',
      receiver_user_id: isDraft ? [userDetails.user_id]: selectedOption === "outside-prolegion" ? documentDetails?.receiver_user_id : documentDetails?.receiver_user_id?.map(i => i?.resource_id),
      document_id: JSON.stringify(id),
    });
    if (resp && resp.success) {
      success("Your document sent successfully")
      getDocuments()
      onClose()
      emptyHandler()
    }
  };

  const handleAddEmail = (e) => {
      if (e.keyCode == 13) {
        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const validate = pattern.test(e.target.value)
        if(validate) {
          setDocumentDetails((prev) => ({...prev, receiver_user_id: [...prev.receiver_user_id, e.target.value]}));
          setCurrEmail("");
        } else {
          error("Please enter a valid email");
        }
      }
  }

  const handleEmailChangeAdd = (_, value) => {
    const validEmails = value.filter((details) => {
      const validEmails = isEmailValid(details);
      if(!validEmails) {
        error("Please enter a valid email");
      }
      return validEmails
    });
    setDocumentDetails((prev)=>({ ...prev, receiver_user_id: validEmails }));
  }

  // const handleDeleteEmail = (item, index) =>{
  //   let arr = [...documentDetails.receiver_user_id]
  //   arr.splice(index,1)
  //   setDocumentDetails((prev)=>({...prev, receiver_user_id: arr}))
  // }

  const wordCount = useMemo(() => {
    return countLettersWithoutTags(documentDetails.description);
  }, [documentDetails]);

  return (
    <MuiModal
      title="Add new document"
      open={openModal}
      minWidth={900}
      onCancel={() => (onClose(), emptyHandler())}
    >
      <FormControl fullWidth>
        <CustomInput
          fullWidth
          required
          error={errorObj["name"] || false}
          sx={{ marginTop: "10px" }}
          label="Title"
          value={documentDetails.title}
          placeholder="Enter the title"
          onChange={(e) => changeHandler("name", e.target.value)}
        />
        {/**Todo 300 words validation */}
        <Box paddingBottom={"2rem"} paddingTop={"1rem"}>
          <Box display={"flex"}>
            <Typography>Description</Typography>
            <Typography color={`${wordCount > 300 ? "red" : "unset"}`} paddingLeft={"2px"}>( {wordCount}/300 )</Typography>
          </Box>
          <MyEditor
            html={documentDetails.description}
            updateRaw={(e)=> {
              changeHandler("description", e)
            }}
            height={200}
            // height={"150px"}
          />
        </Box>
        <Box width={"100%"} marginTop={"20px"}>
          <Typography>Select File</Typography>
          <ReactDropzone
            // styles={
            //   {dropzone: {minHeight: "100px"}}
            // }
            multiple={false}
            maxFiles={1}
            files={files}
            setDetails={(val, name) => changeHandler(name, val)}
          />
        </Box>
        {!isDraft && <>
        <Box width={"100%"} marginTop={"20px"}>
          <Typography>Select To *</Typography>
          <Select
            value={selectedOption}
            fullWidth
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select"
            required
            error={errorObj["shareTo"] || false}
            sx={{
              background: "white",
              "&:selected": { background: "white" },
            }}
            label="Share To"
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            {options.map((i) => {
              return <MenuItem value={i.value}>{i.label || ""}</MenuItem>;
            })}
          </Select>
        </Box>
        {selectedOption !== "outside-prolegion" && selectedOption !== "" && <Box width={"100%"} marginTop={"20px"}>
          <Autocomplete
            multiple
            fullWidth
            clearIcon={false}
            onClose={()=> setSearchQuery('')}
            value={documentDetails?.receiver_user_id ? documentDetails.receiver_user_id : []}
            defaultValue={
              documentDetails.receiver_user_id
                ? documentDetails?.receiver_user_id.map((i) => {
                    return { name: i };
                  })
                : []
            }
            getOptionLabel={(option) => (option?.company ? option?.company :`${option?.firstName} ${option?.lastName}`)}
            // filterOptions={handleFilterCreateOption}
            onChange={(_, v) => (changeHandler("receiver_user_id", v))}
            options={shareTo}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option?.company ? option?.company :`${option?.firstName} ${option?.lastName}`}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Share to*"
                onChange={(e)=> (setSearchQuery(e.target.value))}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
          {/* <Typography>Share To *</Typography>
          <Select
            value={documentDetails.receiver_user_id}
            fullWidth
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select"
            required
            error={errorObj["receiver_user_id"] || false}
            sx={{
              background: "white",
              "&:selected": { background: "white" },
            }}
            label="Share To"
            onChange={(e) => changeHandler("receiver_user_id", e.target.value)}
          >
            {shareTo.map((i) => {
              return (
                <MenuItem value={i?.consultant?.id}>
                  {i?.consultant?.firstName || ""}{" "}
                  {i?.consultant?.lastName || ""}
                </MenuItem>
              );
            })}
          </Select> */}
        </Box>}
        {selectedOption === "outside-prolegion" && <Box width={"100%"} marginTop={"20px"}>
          <Autocomplete
            multiple
            options={[currEmail]}
            onChange={handleEmailChangeAdd}
            getOptionLabel={(option) => option}
            value={documentDetails.receiver_user_id}
            freeSolo
            renderTags={(value, getTagProps) =>
              documentDetails.receiver_user_id.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                value={currEmail}
                onChange={(e) => setCurrEmail(e.target.value)}
                onKeyDown={handleAddEmail}
                {...params}
                label="Email"
                required
                placeholder="Enter receipt email"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        </Box>}
        {/* <Box marginTop={"20px"}>
          <FormControlLabel
            control={
              <Switch
                checked={documentDetails.isApprovedRequired}
                onChange={(e) =>
                  changeHandler("isApprovedRequired", e.target.checked)
                }
                name="gilad"
              />
            }
            label="Requies Approval"
          />
        </Box> */}
        </>}
      </FormControl>
      <Box marginTop={"40px"} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'30px'}>
        <Button
          onClick={submitHandler}
          parentClassName="!w-full justify-end"
          disabled={(!documentDetails?.files?.length > 0 || !documentDetails.name || wordCount > 300 || (!isDraft && !documentDetails?.receiver_user_id?.length > 0))}
          title={isDraft ? 'Save Document' : 'Share Document'}
        />
          
      </Box>
    </MuiModal>
  );
};

export default DocumentModal;
