import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomInput from "Components/Common/semantic_tags/Input";
import { applicantList } from "Pages/Task/constant";
import { applicantStatus } from "Pages/Task/constant.task";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApplicantsList from "./ApplicantsList";
import { getSingleJobApplication, getSingleMarketPlaceJob } from "./marketplace.api";
import Skillslist from "./Skillslist";
import { stringAvatar } from "Utils/avatar";
import useDebounce from "hooks/useDebounce";

const { Card, LinearProgress, Box, Typography, Stack, Avatar, TextField } = require("@mui/material")

const MarketPlaceApplicants = () => {
    const [loader, setLoader] = useState(true)
    const [applications, setApplications] = useState(applicantList())
    const [searchText, setSearchText] = useState('')
    const [jobDetails, setJobDetails] = useState(null)
    const searchQueryRes = useDebounce(searchText, 2000);
    const navigate = useNavigate()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setSearchParams((params) => {
            params.set("job-name", jobDetails?.name);
            return params;
        })
    },[jobDetails?.name])

    const fetchData = async () => {
        setLoader(true)
        let applicationData = []
        let job = {}
        if (!searchQueryRes) {
            job = await getSingleMarketPlaceJob(params.id)
            if (job) {
                applicationData = await getSingleJobApplication(job.data.id, searchQueryRes)
            }
            setJobDetails(job.data)
            setApplications(applicantList(job?.data?.parent_job_id))
        } else {
            applicationData = await getSingleJobApplication(jobDetails.id, searchQueryRes)
        }
        if (applicationData && applicationData.data && applicationData.data.data && applicationData.data.data.jobApplications) {
            const details = applicationData.data.data.jobApplications
            const abc = [
                {
                    id: "hold",
                    taskName: applicantStatus.application,
                    list: []
                },
                {
                    id: "reject",
                    taskName: applicantStatus.waitlisted,
                    list: []
                },
                {
                    id: "select",
                    taskName: applicantStatus.shortlisted,
                    list: []
                },
                {...(
                    job?.data?.parent_job_id
                        ? {
                            id: "submit",
                            taskName: applicantStatus.submit,
                            list: []
                        }
                        : {
                            id: "hired",
                            taskName: applicantStatus.hired,
                            list: []
                        }
                )}
            ];
            details.forEach((task) => {
                switch (task.status) {
                    case 'hold':
                        abc[0].list.push(task);
                        break;
                    case 'reject':
                        abc[1].list.push(task);
                        break;
                    case 'select':
                        abc[2].list.push(task);
                        break;
                    case 'hired':
                        abc[3].list.push(task);
                        break;
                    case 'submit':
                        abc[3].list.push(task);
                        break;
                    default:
                        break;
                }
            });
            setApplications([...abc]);
        }
        setLoader(false)
    }

    useEffect(() => {
        fetchData(searchQueryRes)
    }, [searchQueryRes])

    useEffect(() => {
        return () => {
            setJobDetails(null)
        }
    }, [])

    return <Card>
        {loader && <LinearProgress />}
        <Box sx={{ padding: '10px' }}>
            <Typography marginBottom={"40px"} sx={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard/marketplace?tab=My Jobs')}>
                <ArrowBackIcon
                    sx={{
                        background: "#F3F4F6",
                        width: "30px",
                        marginRight: "20px",
                        height: "30px",
                        borderRadius: "8px",
                    }}
                />
                Back
            </Typography>
            <Box sx={{ marginTop: '10px' }}>
                <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                    {jobDetails?.vendorImg ? <Avatar
                        sx={{ width: "50px", height: "50px", mr: 2 }}
                        src={jobDetails?.vendorImg}
                    /> : jobDetails && <Avatar
                        variant="square"
                        className="!rounded-lg !text-xs"
                        {...stringAvatar(jobDetails?.vendorName ?? "ProLegion User", {
                            width: 80,
                            height: 80,
                            padding: 4
                        })}
                    />}
                    <Box fullWidth>
                        <Typography className="!text-md !text-extrabold !mb-2">
                            {jobDetails?.name || ''}
                        </Typography>
                        <div className="!text-xs !text-extrabold !mb-2" dangerouslySetInnerHTML={{ __html: (jobDetails?.description || '')?.substring(0, 320) + (jobDetails?.description?.length > 220 ? "..." : ""), }}>

                        </div>
                        <Skillslist skills={jobDetails?.main_skill || []} />
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <CustomInput
                    placeholder='Search Applicants'
                    value={searchText}
                    fullWidth
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Box>
            <Box>
                <ApplicantsList fetchData={fetchData} jobDetails={jobDetails} taskListArray={applications} />
            </Box>
        </Box>
    </Card>
}

export default MarketPlaceApplicants