import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenSideNav } from 'Redux/Feature/user';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import ManageHeader from '../../Pages/CombinedDashboard/ManageHeader';
import SideNav from '../SideNav/SideNav';
import ContextProviders from '../../Providers/ContextProviders';
import AlertToast from '../AlertToast';
import { getHybridApprovalStatus } from './api';
import PaymentUpgradeModal from 'Components/Common/PaymentUpgradeModal';

export default function SiteWrapper({ marketing, children = null }) {
  const { openSideNav, openSideNavHov, openPaymentModal } = useSelector((state) => state.user);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isLogin = !!localStorage.getItem('userId');
  const isHybrid = !!localStorage.getItem('isHybridConsultant');

  useEffect(()=> {
    if(isLogin && !isHybrid) {
      getHybridApprovalStatus().then((res)=>{
        if(res?.status) {
          localStorage.setItem('isHybridConsultant', res?.status)
        }
      })
    }
  },[isLogin, !isHybrid])

  return marketing ? (
    <Stack
      direction="column"
      flex={1}
      sx={{
        minHeight: '100vh',
        backgroundColor: '#F9FDFF',
      }}
    >
      <Navbar />
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Stack>
  ) : (
    <ContextProviders>
      <AlertToast />
      <ManageHeader expanded={openSideNav || openSideNavHov} />
      {openPaymentModal && <PaymentUpgradeModal />}
      <Stack direction="row" height="100vh">
        <SideNav expanded={openSideNav || openSideNavHov} />
        <Box
          component="main"
          sx={{
            backgroundColor: '#F9FAFB',
            height: '100%',
            transition: "0.3s ease",
            marginTop: '40px',
            flexGrow: 1,
            overflow: 'auto',
            p: {xs : "3rem 1rem 4rem 1rem", md: 4},
            ml: (!openSideNav && !openSideNavHov) ? isMobile ? '0px' : '70px' : {xs : "0", md:'16rem'},
            // minWidth: '1158px',
          }}
        >
          {children || <Outlet />}
        </Box>
      </Stack>
    </ContextProviders>
  );
}
