import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useMediaQuery } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal';
import Heading1 from 'Components/Common/semantic_tags/Heading1';
import Contactus from 'Pages/Contactus/Contactus';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from 'Utils/helper';
import { selectUserDetails, selectUserRole, setOpenSideNav, setOpenSideNavHov } from '../../Redux/Feature/user';
import { navigationBar, navigationBarBelow } from './nav';
import SideNavLink from './SideNav.link';
import FreeTrialBanner from './FreeTrialBanner';
import { useCountProvider } from 'Providers/Common/Count';
import Typography from 'Components/Common/semantic_tags/Typography';
import prolegionlogo from "../../Assets/prolegionLogoBlue.png";
import { Link } from 'react-router-dom';

const SideNavLinkSkeleton = ({ expanded }) => (
  <div className={`flex items-center ${expanded ? 'gap-8 py-2 px-5' : 'gap-2 py-2 px-1'}`}>
    <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse" />
    {expanded && <div className="w-4/5 h-4 bg-gray-200 animate-pulse" />}
  </div>
);

export default function SideNav({ expanded }) {
  const [modalOpen, setModalOpen] = useState(false);
  const { setOpenSideNavHov: openSideNav } = useSelector(state => state.user)
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const permissions = getPermissions();
  const url = new URLSearchParams(window.location.search);
  const user = useSelector(selectUserDetails);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const { counts, fetchCounts } = useCountProvider();
  const { role } = useSelector(selectUserRole);

  useEffect(() => {
    if (role) {
      fetchCounts(role)
    }
  }, [role]);

  useEffect(() => {
    isMobile ? dispatch(setOpenSideNav({ openSideNav: false })) : dispatch(setOpenSideNav({ openSideNav: true }))
  },[isMobile])

  return (
    <div
      className={`h-full pb-4 bg-[white] border-r transition-all duration-300 ${(!expanded && !openSideNav) ? isMobile ? 'w-0' : 'w-15' : 'w-64'} fixed z-[99]`}
    >
      <div className='relative'>
        <button
          className="absolute z-[99] top-16 -right-3 bg-white hover:bg-slate-100 text-slate-500 p-0.5 rounded-full border border-slate-200"
          onClick={() => {
            dispatch(setOpenSideNav({ openSideNav: !expanded }))
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${(expanded) ? "rotate-0" : "rotate-180"
              } transform duration-500 h-4 w-4`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className='flex h-[93vh] overflow-y-scroll flex-col justify-between bg-white '
      onMouseEnter={() => dispatch(setOpenSideNavHov({ openSideNavHov: true }))}
      onMouseLeave={() => dispatch(setOpenSideNavHov({ openSideNavHov: false }))}>
        <div className="flex flex-col gap-1 py-4">
          <Link to="/dashboard" className='pl-4 pb-8'>
            <img style={{ height: "30px" }} alt="ProLegion" src={expanded ? prolegionlogo : 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/prolegionlogo_blue.png'} />
          </Link>
          {loading ? (
            Array.from(new Array(6)).map((_, index) => (
              <SideNavLinkSkeleton key={index} expanded={expanded || openSideNav} />
            ))
          ) : (
            <>
              {navigationBar.filter((nav) => nav.auth({
                isPublic: window.location.pathname.includes('/dashboard/invoice/'),
                isVendorInvoice: !!(url.get('role') === 'VENDOR'),
                permissions,
                isConsultant: user.role === 'CONSULTANT',
                isVendor: user.role === 'VENDOR'
              })).map((nav) => (
                <SideNavLink expanded={expanded || openSideNav} path={nav.route} Icon={nav.Icon} key={nav.name}>
                  <div className='flex justify-between items-center w-full'>
                  <Typography>{nav.name}</Typography>
                    {/* {!!counts[nav?.key] && <Typography className='bg-[#3985B6] w-[20px] h-[20px] rounded-full text-white text-[8px] flex justify-center items-center'>{counts[nav?.key] || ''}</Typography>} */}
                  </div>
                </SideNavLink>
              ))}
              <hr className='my-2' />
              {navigationBarBelow.filter((nav) => nav.auth({
                isPublic: window.location.pathname.includes('/dashboard/invoice/'),
                isVendorInvoice: !!(url.get('role') === 'VENDOR'),
                permissions,
                isConsultant: user.role === 'CONSULTANT',
                isVendor: user.role === 'VENDOR'
              })).map((nav) => (
                <SideNavLink expanded={expanded || openSideNav} path={nav.route} Icon={nav.Icon} key={nav.name}>
                  <div className='flex justify-between items-center w-full'>
                  <Typography>{nav.name}</Typography>
                    {/* <FiberNewIcon className='text-[#40697F]' /> */}
                  </div>
                </SideNavLink>
              ))}
              <hr className='my-2' />
            </>
          )}
        </div>
        {role === 'VENDOR' &&  <FreeTrialBanner expanded={expanded} />}
        <div
          onClick={handleModalOpen}
          className={`m-${(expanded && openSideNav) ? '4' : '1'} mb-20 cursor-pointer m-4 mt-2 border rounded-lg flex flex-col items-center justify-center ${(expanded || openSideNav) ? '' : 'flex justify-center items-center w-[40px] h-[40px]'}`}
        >
          {(expanded || openSideNav) ? (
            <div className='p-2 text-center'>
              <img className='w-[50%] mx-auto' src='https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/Layer+1+(2).png' />
              <Heading1 className="text-md font-semibold mt-4">Need Help?</Heading1>
              <p className="text-xs text-gray-500 text-center">
                Boost the efficiency of your IT workforce with ProLegion.
              </p>
            </div>
          ) : (
            <ContactSupportIcon sx={{ fontSize: '30px' }} className="text-[#0B85BA]" />
          )}
        </div>
        <MuiModal
          open={modalOpen}
          onCancel={handleModalClose}
        >
          <Contactus modalOpen={modalOpen} handleModalClose={handleModalClose} isProfileSupport />
        </MuiModal>
      </div>
    </div>
  );
}
