const getRoleType = (role) => ({
  isVendor: role === 'VENDOR',
  isClient: role === 'CLIENT',
  isConsultant: role === 'CONSULTANT',
});

const roleHelper = {
  getRoleType,
};

export default roleHelper;
