import React from 'react';
import { ResourcesPage } from 'Pages/ResourcesPage';
import ClientMarketPlaceConsultants from 'Pages/JobConsultant/clientMarketPlaceConsultants';
import ClientResourcePage from 'Pages/ResourcesPage/ClientResource/ClientResourcePage';
import AppDefaultPage from 'Pages/AppDefaultPage/AppDefault.page';
import { Link, defer } from 'react-router-dom';
import api from 'Utils/api';
import TimesheetsTable from 'Pages/TimesheetsPage/Timesheets.table';
import { Box } from '@mui/material';
import RequireAuth from './RequireAuth';

export const resourcesRoutes = [
  {
    path: 'resources',
    element: (
      <RequireAuth>
        <ResourcesPage />
      </RequireAuth>
    ),
  },
  {
    path: 'client-resources',
    element: (
      <RequireAuth>
        <ClientResourcePage />
      </RequireAuth>
    ),
  },
  // {
  //   path: 'timesheets',
  //   handle: {
  //     crumb: () => <Link to='/dashboard/timesheets'>Timesheets</Link>,
  //   },
  //   element: (
  //     <RequireAuth>
  //       <AppDefaultPage />
  //     </RequireAuth>
  //   ),
  //   children: [
  //     {
  //       index: true,
  //       handle: {
  //         crumb: () => null,
  //       },
  //       loader: async ({ request }) => {
  //         try {
  //           const { searchParams } = new URL(request.url);
  //           const res = await api.get('/timesheet', {
  //             params: {
  //               page: searchParams.get('page') || 1,
  //               size: searchParams.get('pageSize') || 10,
  //               sortBy: searchParams.get('sortBy') || 'start',
  //               orderBy: searchParams.get('orderBy') || 'desc',
  //             },
  //           });
  //           return defer({
  //             // ...res,
  //             meta: res?.data?.meta,
  //             timesheets: res?.data?.result || [],
  //             totalTimesheets: res?.data?.meta?.total || 0,
  //           });
  //         } catch {
  //           return defer({
  //             timesheets: [],
  //             totalTimesheets: 0,
  //           });
  //         }
  //       },
  //       element: (
  //         <RequireAuth>
  //           <Box sx={{ width: '100%', p: 2 }}>
  //             <TimesheetsTable />
  //           </Box>
  //         </RequireAuth>
  //       ),
  //     },
  //   ]
  // },
  {
    path: 'view-consultants',
    element: (
      <RequireAuth>
        <ClientMarketPlaceConsultants />
      </RequireAuth>
    ),
  },
  // {
  //   path: 'consultants/:id',
  //   element: (
  //     <RequireAuth>
  //       <DashboardPage />
  //     </RequireAuth>
  //   ),
  // },
  // {
  //   path: 'consultants/:id/:timesheetId',
  //   element: (
  //     <RequireAuth>
  //       <DashboardPage />
  //     </RequireAuth>
  //   ),
  // },
];
