const { Paper, Button, Typography } = require("@mui/material");
import KeyIcon from '@mui/icons-material/Key';
import { useNavigate } from 'react-router-dom';

function SignInMessageBox({message}) {
  const navigate = useNavigate();
  return (
    <Paper sx={{display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "8px", p: "2rem", width: "30%"}}>
      <KeyIcon sx={{fontSize: "100px"}} />
      <Typography variant="h4Bold">Sign in</Typography>
      <Typography variant="defaultFont">to see {message}</Typography>
      <Button variant='contained' sx={{marginTop: "1rem"}} onClick={()=> navigate("/signin")}>Sign In</Button>
    </Paper>
  )
}

export default SignInMessageBox