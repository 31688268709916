import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomTabPanel, a11yProps } from '../../../Components/Common/CustomTabs';
import AccountSettingsTab from '../AccountSettingsTab/AccountSettings.tab';
import UserManagementTab from '../UserManagementTab/UserManagement.tab';
import { getPermissions } from 'Utils/helper';
import { Plans } from 'Pages/Subscription/Plans';
import { Subscriptions } from 'Pages/Subscription/Subscriptions';
import { useLocation } from 'react-router-dom';
import { useAlertProvider } from 'Providers/util/Alert';
import ClientUserManagementTab from '../UserManagementTab/ClientUserManagement.tab';

export default function SettingsTabs() {
  const permissions = getPermissions();
  const [currentTab, setCurrentTab] = React.useState(0);
  const { pathname, search } = useLocation();
  const {success} = useAlertProvider();

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const useQuery = () => {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  
  const param = useQuery();
  //settingTab=plansandbilling&&payment=success

  const settingTab = React.useMemo(() => {
    return param.get("settingTab");
  }, [param]);
  
  const paymentSubscription = React.useMemo(() => {
    return param.get("payment");
  }, [param]);

  React.useMemo(()=> {
    if(pathname === "/dashboard/settings" && settingTab === "plansandbilling" && paymentSubscription === "success") {
      setCurrentTab(2);
      success("Payment Successful")
    } else if (param.get('screen') === 'plans') {
      setCurrentTab((permissions.includes('admin-vendor') || permissions.includes('admin-client')) ? 2 : 1);
    } else if (param.get('screen') === 'list') {
      setCurrentTab((permissions.includes('admin-vendor') || permissions.includes('admin-client')) ? 2 : 1);
    }
  },[pathname, settingTab, paymentSubscription])

  return (
    <>
      <Box>
        <Tabs
          value={currentTab}
          aria-label="Settings Options"
          onChange={handleChangeTab}
        >
          <Tab label="Account" {...a11yProps(0)} />
          {(permissions.includes('admin-vendor') || permissions.includes('admin-client')) && <Tab label="User Management" {...a11yProps(1)} />}
          {/* {permissions.includes('admin-vendor') && <Tab label="Subscribed Resources" {...a11yProps(2)} />} */}
          {(permissions.includes('admin-vendor') || permissions.includes('admin-client')) && <Tab label="Plans and Billing" {...a11yProps(permissions.includes('admin-vendor') ? 2 : 1)} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={currentTab} index={0}>
        <AccountSettingsTab />
      </CustomTabPanel>
      {/* <CustomTabPanel value={currentTab} index={2}> */}
        {/* <Plans /> */}
      {/* </CustomTabPanel> */}
      <CustomTabPanel value={currentTab} index={(permissions.includes('admin-vendor') || permissions.includes('admin-client')) ? 2 : 1}>
        <Subscriptions />
      </CustomTabPanel>
      {(permissions.includes('admin-vendor') || permissions.includes('admin-client')) && (
        <CustomTabPanel value={currentTab} index={1}>
          {permissions.includes('admin-client') ? <ClientUserManagementTab /> : <UserManagementTab />}
        </CustomTabPanel>
      )}
    </>
  );
}
