const validatePassword = (value) => {
  if (!value) {
    return 'Password is required';
  }
  if (value.length < 8) {
    return 'Password must be at least 8 characters long';
  }
  if (!/[A-Z]/.test(value)) {
    return 'Password must contain at least one uppercase letter';
  }
  if (!/[a-z]/.test(value)) {
    return 'Password must contain at least one lowercase letter';
  }
  if (!/[0-9]/.test(value)) {
    return 'Password must contain at least one numeric digit';
  }
  if (!/[^a-zA-Z0-9]/.test(value)) {
    return 'Password must contain at least one special symbol';
  }
  return true; // return true if validation passes
};

const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const validateEmail = (value) => {
  if (!value) {
    return 'Email is required';
  }
  if (value.length < 2) {
    return 'Please enter a valid email';
  }
  if (!/[^a-zA-Z0-9]/.test(value)) {
    return 'Email must contain at least one special symbol';
  }
  return true; // return true if validation passes
};

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {
  validatePassword, validateEmail, emailRegex, isValidEmail,
};
