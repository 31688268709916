import React from 'react';
import CustomInput from './Input';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Typography from './Typography';

const CustomDateInput = ({ label, value, onChange, mode = "date", disabled = false, error, maxDate, minDate }) => {
  const getViews = () => {
    switch (mode) {
      case 'month':
        return ['month'];
      case 'year':
        return ['year'];
      default:
        return ['year', 'month', 'day'];
    }
  };

  return (
    <div>
      {label && <label className="font-semibold mb-1 block">{label}</label>}
      <DesktopDatePicker
        views={getViews()}
        disabled={disabled}
        format={mode === 'year' ? 'YYYY' : mode === 'month' ? 'MMM' : 'MM-DD-YYYY'}
        sx={{
          width: "100%",
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
          },
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
            marginTop: '5px',
          },
          '& .MuiInputAdornment-root': {
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px #e5e7eb solid !important',
          }
        }}
        renderInput={(params) => <CustomInput {...params} />}
        value={value}
        onChange={onChange}
        maxDate={maxDate || dayjs(new Date())}
        minDate={minDate}
      />
      {error && <Typography className='text-red-500 text-xs mt-1'>{error}</Typography>}
    </div>
  );
};

export default CustomDateInput;
