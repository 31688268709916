import { InfoOutlined, Star, StarBorder } from '@mui/icons-material';
import { IconButton, Tooltip, Stack } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal';
import Button from 'Components/Common/semantic_tags/Button';
import CustomInput from 'Components/Common/semantic_tags/Input';
import CustomTextArea from 'Components/Common/semantic_tags/TextArea';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { selectUserDetails } from 'Redux/Feature/user';
import { CustomTable } from 'Components';
import Typography from 'Components/Common/semantic_tags/Typography';
import { createFeedBack, getFeedBackTypes } from './feedback.api';
import { useAlertProvider } from 'Providers/util/Alert';

export const StarRating = ({ value, onChange, number = 5, className = '' }) => {
  return (
    <div className="flex">
      {[...Array(number)].map((_, index) => (
        <IconButton
          key={index}
          onClick={() => onChange && onChange(index + 1)}
        >
          {index < value ? <Star className={`text-[#F5BF03] ${className}`} /> : <StarBorder className={className} />}
        </IconButton>
      ))}
    </div>
  );
};

const defaultDetails = {
  rating: 0,
  comments: "",
  communicationSkills: 0,
  technicalSkills: 0,
  businessKnowledge: 0,
  related_type: "job"
};

const FeedbackModal = ({ jobDetails, applicantDetails, isOpen, onClose, setFeebackDetails, setIsSubmitFeedbackRequired, feedbackDetails }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const userDetails = useSelector(selectUserDetails);
  const [options, setOptions] = useState([]);
  const { error, success } = useAlertProvider()
  const [showTable, setShowTable] = useState(false);

  const { setValue, watch, control, handleSubmit, reset } = useForm({
    defaultValues: {
      feedback_type_id: userDetails.id,
      givenBy: userDetails.user_id,
      givenFor: applicantDetails?.consultant?.user_id,
      Job_id: jobDetails.id,
      ...defaultDetails
    },
  });

  const details = watch();

  const onSubmit = async (data) => {
    data['feedback_type_id'] = data['feedback_type_id']?.value
    const resp = await createFeedBack(data)
    if (resp.success) {
      success('Feedback submitted successfully')
      setFeebackDetails([...feedbackDetails, ...resp.data])
      setIsSubmitFeedbackRequired(false)
      return onClose()
    }
    setIsSubmitFeedbackRequired(true)
    return error(resp?.message || 'Something went wrong')
  };

  useEffect(() => {
    getFeedBackTypes().then((res) => {
      if (res && res.data) setOptions(res.data.map((i) => { return { label: i.type_name, value: i.id } }))
    })
  }, []);

  const handleFeedbackTypeChange = (selectedOption) => {
    setValue('feedback_type_id', selectedOption);
    setShowTable(true);
  };

  const handleUpdateRating = (id, value) => {
    setValue(id, value);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "criteria",
        header: "Criteria",
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue()}
          </Typography>
        ),
        enableEditing: false,
      },
      {
        accessorKey: "rating",
        header: "Rating",
        // enableEditing: true,
        Cell: ({ row }) => (
          <StarRating
            value={details[row.original.id]}
            onChange={(value) => handleUpdateRating(row.original.id, value)}
          />
        ),
        size: 80,
      },
    ],
    [validationErrors, details]
  );

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  const rows = [
    { criteria: 'Communication Skills', rating: 0, id: 'communicationSkills' },
    { criteria: 'Technical Skills', rating: 0, id: 'technicalSkills' },
    { criteria: 'Business Knowledge', rating: 0, id: 'businessKnowledge' },
  ];

  return (
    <MuiModal className='max-w-xl' open={isOpen} onCancel={onClose}>
      <div className="bg-white rounded-lg shadow-lg p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <CustomInput
              label="Feedback Given by"
              fullWidth
              disabled
              placeholder={`${userDetails?.first_name} ${userDetails?.last_name}`}
            />
          </div>
          <div className="mb-4">
            <CustomInput
              label="Feedback Given For"
              fullWidth
              disabled
              placeholder={`${applicantDetails?.consultant?.first_name} ${applicantDetails?.consultant?.last_name}`}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="feedback_type_id"
              control={control}
              render={({ field }) => <Select {...field} options={options} onChange={handleFeedbackTypeChange} />}
            />
          </div>
          {showTable && (
            <div className="mb-4">
              <CustomTable
                columns={columns}
                data={rows}
                // editDisplayMode="table"
                // enableEditing
                enableSorting={false}
                enableBottomToolbar={false}
                enableColumnResizing={false}
              />
            </div>
          )}
          <div className="mb-4">
            <label className="block mb-2">Interview Performance</label>
            <StarRating
              value={watch('rating')}
              onChange={(value) => setValue('rating', value)}
            />
          </div>
          <div className="mb-4">
            <CustomTextArea
              label="Comments"
              name='comments'
              rows={4}
              className=''
              value={details.comments}
              onChange={(e) => setValue('comments', e.target.value)}
            />
          </div>
          <div className="flex justify-end">
            <Button
              type="button"
              className="bg-gray-500 text-white"
              onClick={onClose}
              title='Cancel'
            />
            <Button
              type="submit"
              className='ml-2'
              title='Submit'
            />
          </div>
        </form>
      </div>
    </MuiModal>
  );
};

export default FeedbackModal;
