import React, { useRef, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiModal from '../../Components/Common/MuiModal';
import { bgColor, roles } from '../../Utils/constants';
import { downloadExampleResourceCSV } from '../../Utils/downloadCSV';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { parseCSV } from '../../Utils/helper';
import { useConnectionProvider } from '../../Providers/Vendor/Connections';
import Button from 'Components/Common/semantic_tags/Button';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function ImportResources() {
  const [openAddImportModal, setOpenAddImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const openModal = () => setOpenAddImportModal(true);
  const closeModal = () => setOpenAddImportModal(false);
  const ref = useRef(null);

  const handleFileUploadRef = () => {
    ref.current?.click();
  }

  const { inviteNewUser, importVendor } = useConnectionProvider();

  const handleFileChange = (event) => setFile(event.target.files[0]);

  const removeSelectedFile = () => {
    setFile(null);
  };

  const uploadHandler = () => {
    importVendor(file, "RESOURCES");
    // parseCSV(file, (parsedData) => {
    //   // Call the API with the parsed data
    //   parsedData.forEach(async (user) => {
    //     const propertyNames = Object.keys(user);
    //     const firstName = user[propertyNames[0]];
    //     const lastName = user[propertyNames[1]];
    //     const email = user[propertyNames[2]];
    //     const invitingUser = {
    //       invitedBy: localStorage.getItem('userId'),
    //       name: `${firstName} ${lastName}`,
    //       email,
    //       role: roles.consultant,
    //     };
    //     if (invitingUser?.email) {
    //       inviteNewUser(invitingUser, false);
    //     }
    //   });
    closeModal();
    setFile(null);
    // });
  };

  return (
    <Box>
      {/* <Tooltip title="Import Resources" arrow>
        <img
          onClick={openModal}
          className="w-8 h-8 cursor-pointer"
          src="https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/icons8-import-64.png"
          alt="Invite"
        />
      </Tooltip> */}
      <Button
        onClick={openModal}
        title={<><FileDownloadIcon /> Import Resources</>}
      />
      <MuiModal
        open={openAddImportModal}
        title="Import resources"
        onCancel={closeModal}
      >
        <Box sx={{ paddingBottom: '0px' }}>
          <span>Select .csv File* </span>
          <Button
            sx={{
              typography: 'captionMedium',
              color: '#F9FAFB',
              backgroundColor: '#0B85BA',
              py: 1,
              px: 1.5,
              borderRadius: '8px',
            }}
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={handleFileUploadRef}
            title={<>Upload .csv <input style={{ display: "none" }} ref={ref} onChange={handleFileChange} type="file" accept=".csv" /></>}
          />
          {file && (
            <Typography sx={{ mb: 2.5, marginTop: '20px' }} display="flex" gap={10} alignItems="center">
              File Uploaded
              <Typography variant="body2Bold" sx={{ marginLeft: '8px' }}>{file?.name}</Typography>
              <CancelIcon onClick={removeSelectedFile} />
            </Typography>
          )}
          <div className="p-2 mt-4">
            <Typography className="text-sm text-gray-600 mb-2">
              NOTE: Please use the template provided below to ensure your data is formatted correctly. Download the template, fill in your data, and then upload it to our system.
            </Typography>
            <span onClick={downloadExampleResourceCSV} className="py-2 mt-4 text-[#0B85BA] flex items-center cursor-pointer">
              <DownloadForOfflineIcon className="mr-2" /> Download Template
            </span>
          </div>
          {file && (
            <Button
              parentClassName='!w-full justify-end mt-4'
              onClick={uploadHandler}
              title='Invite All'
            />
          )}
        </Box>
      </MuiModal>
    </Box>
  );
}

export default ImportResources;
