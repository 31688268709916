
import ReactDOM from 'react-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { store } from 'Redux/Store'
import { setOpenPaymentModal } from 'Redux/Feature/user';

export const siteURL = process.env.REACT_APP_BASE_URL;
export const baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1`;
export const baseURLV2 = `${process.env.REACT_APP_BASE_URL}/api/v2`;
export const documentUploadURL = `${process.env.REACT_APP_DOCUPUPLOAD_URL}`;
export const marketPlaceUrl = `${process.env.REACT_APP_MARKETPLACE_URL_NEW}`;
export const resourceUrl = `${process.env.REACT_APP_SHARED_RESOURCES_URL}`;

const renderPaymentUpgradeModal = () => {
  store.dispatch(setOpenPaymentModal({ openPaymentModal: true }))
};

const isHybridConsultantCheck = localStorage.getItem('isHybridConsultant');
const role = localStorage.getItem('role');

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('at')}`,
    ...(isHybridConsultantCheck && role === 'VENDOR' && { isHybridConsultant: true }),
  },
});

export const apiV2 = axios.create({
  baseURL: baseURLV2,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('at')}`,
    ...(isHybridConsultantCheck && role === 'VENDOR' && { isHybridConsultant: true }),
  },
});

const handleTokenExpiration = async (error) => {
  const originalRequest = error.config;
  if (
    error.response.status === 401
    && !originalRequest._retry
    && localStorage.getItem('rft') != null
  ) {
    originalRequest._retry = true;
    api
      .post(`${baseURL}/auth/refreshtoken`, {
        refreshToken: localStorage.getItem('rft'),
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('at', res.data.result.access_token);
          localStorage.setItem('rft', res.data.result.refresh_token);
          api.defaults.headers.common.Authorization = `Bearer ${res.data.result.access_token}`;
          // window.location.reload();
          // return api(originalRequest);
        }
      })
      .catch((err) => {
        localStorage.clear();
        window.location.href = '/signin';
      });
    return api(originalRequest);
  }
  return Promise.reject(error);
};

export const refreshAccessToken = async () => {
  const res = await api.post(`${baseURL}/auth/refreshtoken`, {
    refreshToken: localStorage.getItem('rft'),
  });
  if (res.status === 200) {
    localStorage.setItem('at', res.data.result.access_token);
    localStorage.setItem('rft', res.data.result.refresh_token);
    api.defaults.headers.common.Authorization = `Bearer ${res.data.result.access_token}`;
  }
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data?.error === 'Please upgrade your subscription' || error?.response?.data?.error?.includes('upgrade your subscription')) {
      return renderPaymentUpgradeModal()
    }
    if (error.response) {
      return handleTokenExpiration(error);
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use((config) => {
  if (localStorage.getItem('at') != null) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('at')}`;
  }
  return config;
});
apiV2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error?.response?.data?.error === 'Please upgrade your subscription' || error?.response?.data?.error?.includes('upgrade your subscription')) {
        return renderPaymentUpgradeModal()
      }
      return handleTokenExpiration(error);
    }
    return Promise.reject(error);
  },
);

apiV2.interceptors.request.use((config) => {
  if (localStorage.getItem('at') != null) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('at')}`;
  }
  return config;
});

export const logout = async () => {
  if (
    localStorage.getItem('at') !== null
    && localStorage.getItem('rft') !== null
  ) {
    api
      .post(`${baseURL}/auth/signout`, {
        refreshToken: localStorage.getItem('rft'),
      })
      .then((res) => {
        if (res.data.success) {
          localStorage.removeItem('at');
          localStorage.removeItem('rft');
          Swal.fire('', 'Successfully logged out', 'success');
        }
      })
      .catch((err) => {
        console.error(err);
        localStorage.removeItem('at');
        localStorage.removeItem('rft');
      });
  }
};

export const sharedResources = axios.create({
  baseURL: `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/`,
  // baseURL: `http://localhost:3001/api/v1/`,
});

sharedResources.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      return handleTokenExpiration(error);
    }
    return Promise.reject(error);
  },
);

sharedResources.interceptors.request.use((config) => {
  const token = localStorage.getItem('at');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
