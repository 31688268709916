import Vendors from 'Pages/Dashboard/components/Vendors';
import React from 'react';
import AppDefaultPage from 'Pages/AppDefaultPage/AppDefault.page';
import RequireAuth from './RequireAuth';

export const vendorsRoutes = [
  {
    path: 'vendors',
    element: (
      <RequireAuth>
        <AppDefaultPage />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <Vendors />
          </RequireAuth>
        ),
      },
    ],
  },
];
