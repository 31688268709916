export const MuiModalOverride = {
  styleOverrides: {
    root: {
      '& > .MuiPaper-root': {
        maxHeight: '100%',
        overflowY: 'auto',
      },
    },
  },
};
