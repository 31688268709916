import DashboardChartIcon from '../Icons/Dashboard.icon';
import TimesheetsIcon from '../Icons/Timesheets.icon';
import JobsIcon from '../Icons/Jobs.icon';
import VendorsIcon from '../Icons/Vendors.icon';
import ClientsIcon from '../Icons/Clients.icon';
import InvoicesIcon from '../Icons/Invoices.icon';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ArticleIcon from '@mui/icons-material/Article';
import { userPermissions } from '../../Utils/constants';
import MarketplaceIcon from 'Components/Icons/Marketplace.icon';


const AddTaskIcons = () => {
  return <AddTaskIcon sx={{ color: '#003B54', fontSize: '15px', fontWeight: 900, opacity: 0.7 }} />
}

const ArticleIcons = () => {
  return <ArticleIcon sx={{ color: '#003B54', fontSize: '15px', fontWeight: 900, opacity: 0.7 }} />
}

export const navigationBarBelow = [
  {
    name: 'Tasks',
    route: '/dashboard/task-board',
    Icon: AddTaskIcons,
    auth: () => true,
  },
  {
    name: 'Document',
    route: '/dashboard/document',
    Icon: ArticleIcons,
    auth: () => true
  }
]

  export const navigationBar = [
  {
    name: 'Dashboard',
    route: '/dashboard',
    Icon: DashboardChartIcon,
    auth: () => true,
  },
  {
    name: 'Marketplace',
    route: '/dashboard/marketplace',
    Icon: MarketplaceIcon,
    auth: ({ permissions = [] }) => permissions.includes(userPermissions.job.view),
  },
  {
    name: 'Timesheets',
    route: '/dashboard/timesheets',
    Icon: TimesheetsIcon,
    key: 'timesheets',
    auth: ({ isPublic, permissions = [] }) => !isPublic ? permissions.includes(userPermissions.timesheet.view) : true,
  },
  {
    name: 'My Jobs',
    route: '/dashboard/jobs',
    Icon: JobsIcon,
    auth: ({ isConsultant = false }) => !!isConsultant,
  },
  {
    name: 'Jobs',
    route: '/dashboard/jobs',
    Icon: JobsIcon,
    key: 'jobs',
    auth: ({ isPublic, permissions = [], isConsultant = false }) => !isPublic ? !isConsultant && permissions.includes(userPermissions.job.view) : true,
  },
  {
    name: 'Partners',
    route: '/dashboard/vendors',
    Icon: VendorsIcon,
    auth: ({ isVendorInvoice, isPublic, isVendor = false, permissions = [] }) => !isVendorInvoice ? isVendor && permissions.includes(userPermissions.vendorConnection.view) : isPublic,
  },
  {
    name: 'Vendors',
    route: '/dashboard/vendors',
    Icon: VendorsIcon,
    key: 'vendors',
    auth: ({ isVendorInvoice, isPublic, permissions = [], isVendor = false }) => !isPublic ? !isVendor && permissions.includes(userPermissions.vendorConnection.view) : !isVendorInvoice,
  },
  {
    name: 'Clients',
    route: '/dashboard/clients',
    Icon: ClientsIcon,
    key: 'clients',
    auth: ({ isVendorInvoice, permissions = [], isPublic }) => !isVendorInvoice ? permissions.includes(userPermissions.clientConnection.view) : isPublic,
  },
  {
    name: 'Invoices',
    route: '/dashboard/invoices',
    Icon: InvoicesIcon,
    key: 'invoices',
    auth: ({ isPublic, permissions = [] }) => !isPublic ? permissions.includes(userPermissions.invoices.view) : true,
  },
  {
    name: 'Resources',
    route: '/dashboard/resources',
    Icon: InvoicesIcon,
    key: 'consultants',
    auth: ({ isVendorInvoice, isPublic, permissions = [], isVendor = false }) => !isVendorInvoice ? isVendor ? permissions.includes(userPermissions.consultantConnection.view) : false : isPublic,
  },
  {
    name: 'Resources',
    route: '/dashboard/client-resources',
    Icon: InvoicesIcon,
    auth: ({ permissions = [], isVendor = false }) => !isVendor ? permissions.includes(userPermissions.consultantConnection.view) : false,
  }
];
