import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { CustomTable } from "Components/CustomTable";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { InvoiceCreateApi, getJobsWithTimesheets } from "./api";
import { useAlertProvider } from "Providers/util/Alert";
import PreviewInvoice from "./PreviewInvoice";
import { useSelector } from "react-redux";
import { selectUserDetails } from "Redux/Feature/user";
import moment from "moment";
import MyEditor from "Components/TextEditor";

const currencies = [
  { id: "USD", name: "USD" },
  { id: "INR", name: "INR" },
];
const dateRange = [
  { id: "dateRange", name: "Date Range" },
  { id: "month", name: "Month" },
];

const jobs = [];

export default function InvoiceCreateForm() {
  const [loading, setLoading] = React.useState(false);
  const { success, error } = useAlertProvider();
  const userDetails = useSelector(selectUserDetails);
  const [amount, setAmount] = useState(0);
  const [dynamicFields, setDynamicFields] = React.useState([
    { key: "", value: "" },
  ]);
  const [finalAmount, setFinalAmount] = useState(0);
  const { clients = [] } = useLoaderData();
  const [currentState, setCurrentState] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewDetails, setPreviewDetails] = useState(null);
  const navigate = useNavigate();

  const defaultValues = {
    client_id: clients.length > 1 ? "" : clients?.[0]?.id,
    startDate: new Date(),
    endDate: undefined,
    dueDate: undefined,
    adjustment_amount: [],
    currency_code: "USD",
    type: "dateRange",
    comments: "",
    jobs,
  };

  const {
    handleSubmit,
    reset,
    watch,
    clearErrors,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors, isDirty, isSubmitting, isValidating },
  } = useForm({
    defaultValues,
  });

  const { currency_code, startDate, endDate } = getValues();
  const jobsFromForm = watch("jobs", defaultValues.jobs);
  const currencyFromForm = watch("currency_code", defaultValues.jobs);
  const clientFrom = watch("client_id", defaultValues.client_id);
  const typeFrom = watch("type", defaultValues.type);
  const monthFrom = watch("month", defaultValues.month);
  const startDateWatch = watch("startDate", defaultValues.startDate);
  const endDateWatch = watch("endDate", defaultValues.endDate);

  const onSubmit = async () => {
    // const dataObj = { ...data }
    const dataObj = {
      ...currentState,
      adjustment_amount: dynamicFields.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {}),
    };
    if (
      dataObj &&
      (dataObj["jobs"]?.length == 0 ||
        dataObj["jobs"].filter((i) => i.selected)?.length == 0)
    ) {
      return error("No Jobs selected");
    }
    dataObj["jobs"] = (dataObj?.jobs || [])
      .filter((i) => i.selected)
      .map((jo) => {
        return {
          id: jo.id,
          rate: jo.rate,
          currency: jo.currency,
          rate_type: jo.rate_type,
          hours: jo.total_hours,
          amount: jo.amount,
          timesheets: jo.timesheets.map((i) => i.id),
        };
      });
    const res = await InvoiceCreateApi(dataObj);
    if (res) {
      const invoiceId = res.data.result.id;
      success("Invoice generated successfully");
      navigate(`/dashboard/invoices/${invoiceId}?isInvoiceSentTab=true`);
      return;
    }
    return error(res.message);
  };

  const previewHandler = (data) => {
    setOpenPreview(true);
    setCurrentState(data);
    let clientObj = {};
    clients.forEach((client) => {
      if (client.id === clientFrom) {
        clientObj = client;
      }
    });
    const invoiceDetails = {
      invoiceno: "PREVIEW",
      createdAt: new Date(),
      dueDate: data?.dueDate,
      currency: data?.currency_code,
      startDate: data?.startDate,
      endDate: data?.endDate,
      comments: data?.comments,
      sub_total_amount: amount,
      grand_total_amount: finalAmount,
      adjustment: dynamicFields.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {}),
    };
    setPreviewDetails({
      invoiceDetails,
      clientDetails: clientObj,
      vendorDetails: userDetails,
      jobsOfInvoice: data?.jobs.filter((i) => i.selected),
    });
  };

  const closePreviewHandler = () => {
    setPreviewDetails(false);
    setPreviewDetails(null);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Job Name",
        Cell: ({ cell }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {cell.getValue()}
          </Typography>
        ),
        size: 120,
        enableEditing: false,
      },
      {
        accessorKey: "consultant",
        header: "Resource",
        Cell: ({ cell }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {cell.getValue()?.firstName} {cell.getValue()?.lastName}
          </Typography>
        ),
        size: 120,
        enableEditing: false,
      },
      {
        accessorKey: "timesheets",
        header: "Timesheet Days",
        Cell: ({ cell }) => {
          let sum = cell.getValue()?.reduce((acc, curr) => {
            return acc + Number(curr?.total_timesheet_days || 0); // If value is undefined or null, default to 0
          }, 0);
          return <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {sum || 0}
        </Typography>
        },
        size: 120,
        enableEditing: false,
      },
      {
        accessorKey: "total_hours",
        header: "Total Hours",
        Cell: ({ cell }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {cell.getValue()}
          </Typography>
        ),
        size: 120,
        enableEditing: false,
      },
      {
        accessorKey: "rate",
        header: "Rate",
        Cell: ({ cell, row }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {cell.getValue()}
          </Typography>
        ),
        size: 120,
        enableEditing: true,
        muiEditTextFieldProps: ({ row }) => {
          const jobsValue = getValues("jobs");
          const hasError = errors.jobs?.[row.original.id];
          return {
            error: hasError,
            sx: {
              width: "100px",
            },
            InputProps: {
              min: 0,
              max: 24,
              maxLength: 4,
              disabled: loading,
              value:
                jobsValue.find((job) => job.id === row.original.id)?.rate || "",
              endAdornment: hasError ? (
                <Tooltip
                  title={
                    <Stack direction="column">
                      <Typography variant="captionNormal">
                        Rate must be greater than 0
                      </Typography>
                    </Stack>
                  }
                >
                  <InfoOutlined
                    sx={{
                      fontSize: 18,
                      mr: 1,
                      color: hasError ? "error.main" : "",
                    }}
                  />
                </Tooltip>
              ) : (
                <Typography className="disabled" variant="captionNormal">
                  {row.original.rate_type === "HOURLY" ? "H" : "M"}
                </Typography>
              ),
            },
            variant: "outlined",
            onChange: (e) => {
              const val = e.target.value;
              if (isNaN(val) || val < 1) {
                setError(
                  `jobs.${row.original.id}`,
                  { type: "manual", message: "Rate must be greater than 0" },
                  { shouldFocus: true }
                );
              } else {
                clearErrors(`jobs.${row.original.id}`);
              }
              setValue(
                "jobs",
                jobsValue.map((job) => {
                  if (job.id === row.original.id) {
                    return {
                      ...job,
                      rate: val,
                    };
                  }
                  return job;
                })
              );
            },
          };
        },
      },
      {
        accessorKey: "total_amount",
        header: "Amount",
        Cell: ({ cell, row }) => {
          const jobsValue = getValues("jobs");
          const hasError = errors.jobs?.[row.original.id];
          const rate =
            Number(jobsValue.find((job) => job.id === row.original.id)?.rate) ||
            0;
          const hours = Number(row.original.total_hours) || 0;
          const total =
          currency_code === "USD"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(row.original.rate_type === 'HOURLY' ? rate * hours : rate)
              : new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(row.original.rate_type === 'HOURLY' ? rate * hours : rate);
          return (
            <Typography
              variant="body2Normal"
              sx={{ color: hasError ? "error.main" : "#182743" }}
            >
              {total}
            </Typography>
          );
        },
        size: 120,
        enableEditing: false,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "selected",
        header: "",
        Cell: ({ cell, row }) => {
          const selected = cell.getValue();
          return (
            <FormControl>
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    typography: "componentsButtonSmall",
                    color: selected ? "#057A55" : "#6B7280",
                  },
                  backgroundColor: selected ? "#F2FBFF" : "#fff",
                  border: selected ? "1px solid #31C48D" : "1px solid #E5E7EB",
                  borderRadius: "8px",
                  px: 1.5,
                  height: "34px",
                }}
                label={selected ? "Selected" : "Select"}
                control={
                  <Checkbox
                    checked={selected}
                    defaultChecked={selected}
                    checkedIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M4.29324 11C4.0974 11.0009 3.90902 10.9089 3.7684 10.7437L0.226464 6.57747C0.155867 6.49392 0.0995364 6.39434 0.0606889 6.2844C0.0218415 6.17446 0.00123773 6.05633 5.41344e-05 5.93674C-0.00233624 5.69522 0.0745209 5.46244 0.213718 5.28961C0.352915 5.11678 0.543049 5.01806 0.742294 5.01516C0.941538 5.01226 1.13357 5.10543 1.27615 5.27416L4.29624 8.82508L10.7233 1.25881C10.8661 1.09007 11.0583 0.997006 11.2577 1.00007C11.4571 1.00314 11.6473 1.1021 11.7865 1.27517C11.9257 1.44824 12.0025 1.68125 11.9999 1.92294C11.9974 2.16463 11.9158 2.3952 11.773 2.56393L4.81809 10.7437C4.67747 10.9089 4.48909 11.0009 4.29324 11Z"
                          fill="#057A55"
                        />
                      </svg>
                    }
                    sx={{ p: 0 }}
                    onChange={() => {
                      setValue(
                        "jobs",
                        getValues("jobs").map((job) => {
                          if (job.id === row.original.id) {
                            return {
                              ...job,
                              selected: !job.selected,
                            };
                          }
                          return job;
                        })
                      );
                    }}
                  />
                }
              />
            </FormControl>
          );
        },
        size: 120,
        enableEditing: false,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    [jobsFromForm, currency_code]
  );

  const fetchTimesheet = async (id, fromDate, toDate, currencyFromForm, monthFrom, type) => {
    const data = await getJobsWithTimesheets(
      id,
      fromDate,
      toDate,
      "client",
      currencyFromForm,
      monthFrom,
      type
    );
    if (data && data.data) {
      setValue(
        "jobs",
        data.data.map((i) => {
          return { ...i, selected: true };
        })
      );
    }
  };

  useEffect(() => {
    const sum = dynamicFields.reduce((acc, curr) => {
      return acc + Number(curr.value || 0); // If value is undefined or null, default to 0
    }, 0);
    setFinalAmount(amount + sum);
  }, [dynamicFields, amount]);

  useEffect(() => {
    if (clientFrom && ((startDateWatch && endDateWatch && typeFrom === 'dateRange') || (typeFrom === 'month' && monthFrom))) {
      fetchTimesheet(
        clientFrom,
        startDateWatch,
        endDateWatch,
        currencyFromForm,
        monthFrom,
        typeFrom
      );
    }
  }, [clientFrom, startDateWatch, endDateWatch, currencyFromForm, monthFrom]);

  const handleAddField = () => {
    setDynamicFields([...dynamicFields, { key: "", value: "" }]);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index] = { ...updatedFields[index], [field]: value };
    setDynamicFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...dynamicFields];
    updatedFields.splice(index, 1);
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    let sum = 0;
    jobsFromForm
      .filter((i) => i.selected)
      .map((i) => {
        const rate = Number(i?.rate) || 0;
        const hours = Number(i.total_hours) || 0;
        const total = i.rate_type === 'HOURLY' ? rate * hours : rate;
        sum = sum + total;
      });
    setAmount(sum);
  }, [jobsFromForm]);

  return (
    <Grid item xs={16}>
      {previewDetails && (
        <PreviewInvoice
          generateInvoice={onSubmit}
          isOpen={openPreview}
          onClose={closePreviewHandler}
          previewDetails={previewDetails}
        />
      )}
      <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
        <Typography variant="cardHeader">Create New Invoice</Typography>
        <form onSubmit={handleSubmit(previewHandler)}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <Controller
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="client_id" shrink>
                      Client
                    </InputLabel>
                    <Select {...field} id="client_id" required>
                      {clients.map((client) => (
                        <MenuItem value={client.id} key={client.id}>
                          {client.company}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                control={control}
                name="client_id"
                defaultValue={defaultValues.client}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="type" shrink>
                      Select
                    </InputLabel>
                    <Select {...field} required>
                      {dateRange.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                control={control}
                name="type"
                defaultValue={defaultValues.type}
              />
            </Grid>
            {typeFrom === "dateRange" ? (
              <>
                <Grid item xs={2}>
                  <Controller
                    render={({ field }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          ".MuiFormControl-root": {
                            width: "100%",
                          },
                          ".react-datepicker-wrapper": {
                            marginTop: "24px",
                          },
                        }}
                      >
                        <InputLabel htmlFor="startDate" shrink>
                          From
                        </InputLabel>
                        <DatePicker
                          selected={field.value}
                          onChange={field.onChange}
                          selectsStart
                          startDate={field.value}
                          endDate={endDate}
                          customInput={<TextField />}
                          style={{ marginTop: "24px" }}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name="startDate"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    render={({ field }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          ".MuiFormControl-root": {
                            width: "100%",
                          },
                          ".react-datepicker-wrapper": {
                            marginTop: "24px",
                          },
                        }}
                      >
                        <InputLabel htmlFor="endDate" shrink>
                          To
                        </InputLabel>
                        <DatePicker
                          selected={field.value}
                          onChange={field.onChange}
                          selectsEnd
                          startDate={startDate}
                          endDate={field.value}
                          customInput={<TextField />}
                          style={{ marginTop: "24px" }}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name="endDate"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={2}>
                <Controller
                  render={({ field }) => {
                    return (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          ".MuiFormControl-root": {
                            width: "100%",
                          },
                          ".react-datepicker-wrapper": {
                            marginTop: "24px",
                          },
                        }}
                      >
                        <InputLabel htmlFor="endDate" shrink>
                          Select Month
                        </InputLabel>
                        <DatePicker
                          selected={
                            field.value
                              ? moment(field.value, "MMM-YYYY").toDate()
                              : null
                          }
                          onChange={(date) =>
                            field.onChange(
                              date ? moment(date).format("MMM-YYYY") : ""
                            )
                          }
                          showMonthYearPicker
                          customInput={<TextField />}
                          dateFormat="MMM-yyyy"
                          style={{ marginTop: "24px" }}
                        />
                      </FormControl>
                    );
                  }}
                  control={control}
                  name="month"
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <Controller
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{
                      ".MuiFormControl-root": {
                        width: "100%",
                      },
                      ".react-datepicker-wrapper": {
                        marginTop: "24px",
                      },
                    }}
                  >
                    <InputLabel htmlFor="dueDate" shrink>
                      Due Date
                    </InputLabel>
                    <DatePicker
                      selected={field.value}
                      onChange={field.onChange}
                      customInput={<TextField />}
                    />
                  </FormControl>
                )}
                control={control}
                name="dueDate"
              />
            </Grid>
            <Grid item xs={3}>
                <Controller
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel htmlFor="currency_code" shrink>
                        Currency
                      </InputLabel>
                      <Select {...field} required>
                        {currencies.map((option) => (
                          <MenuItem value={option.id} key={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  control={control}
                  name="currency_code"
                  defaultValue={defaultValues.currency_code}
                />
              </Grid>
            <Grid item container>
              <Grid item xs={8}>
                {/* <Controller
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel htmlFor="comments" shrink>
                        Comments
                      </InputLabel>
                      <TextField
                        {...field}
                        variant="outlined"
                        id="comments"
                        sx={{
                          marginTop: "24px",
                        }}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name="comments"
                  defaultValue={defaultValues.comments}
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Typography
            variant="body1Bold"
            sx={{ color: "#182743", mt: 3, mb: 2 }}
            component="h3"
          >
            Select Job(s)
          </Typography>
          <CustomTable
            enableEditing
            editDisplayMode="table"
            initialState={{}}
            enableSorting={false}
            loading={false}
            data={jobsFromForm}
            columns={columns}
            enableBottomToolbar={false}
            enableRowNumbers={true}
            rowNumberDisplayMode={"static"}
            enablePagination={false}
            enableColumnResizing={false}
          />
          {jobsFromForm && jobsFromForm.length > 0 && (
            <Stack
              direction="row"
              // justifyContent="flex-end"
              flexDirection={"column"}
              alignItems={"flex-end"}
              gap={2}
              sx={{ mt: 3 }}
            >
              {dynamicFields.map((field, index) => (
                <Stack
                  direction="row"
                  key={index}
                  width={"40%"}
                  // alignItems="flex-end"
                  spacing={2}
                >
                  <Typography>Adjustment Name</Typography>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name={`adjustment_amount[${index}].key`}
                      control={control}
                      defaultValue={field.key}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={dynamicFields[index].key}
                          onChange={(e) =>
                            handleFieldChange(index, "key", e.target.value)
                          }
                          placeholder="Text Box"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name={`adjustment_amount[${index}].value`}
                      control={control}
                      defaultValue={field.value}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={dynamicFields[index].value}
                          placeholder="Amount"
                          onChange={(e) =>
                            handleFieldChange(index, "value", e.target.value)
                          }
                          variant="outlined"
                          type="number"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                  {index === dynamicFields.length - 1 && (
                    <IconButton onClick={handleAddField} color="primary">
                      <AddIcon />
                    </IconButton>
                  )}
                  {index !== dynamicFields.length - 1 && (
                    <IconButton onClick={() => handleRemoveField(index)}>
                      <Typography variant="body2">Remove</Typography>
                    </IconButton>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
          {jobsFromForm && jobsFromForm.length > 0 && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap={2}
              alignItems={"flex-end"}
              sx={{ mt: 3 }}
            >
              <Box flexDirection={"row"} display={"flex"} width={"20%"}>
                <Typography>Final Amount</Typography>
                <FormControl variant="standard" fullWidth>
                  <TextField value={finalAmount} disabled />
                </FormControl>
              </Box>
            </Stack>
          )}
          {jobsFromForm && jobsFromForm.length > 0 &&
              <Grid item xs={8}>
                <Controller
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel htmlFor="comments" shrink className="!mt-4">
                        Payment Terms and Details
                      </InputLabel>
                      <MyEditor
                        className={'!mt-10'}
                        height={100}
                        html={field.value}
                        updateRaw={field.onChange}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name="comments"
                  defaultValue={defaultValues.comments}
                />
              </Grid>
          }
          {jobsFromForm && jobsFromForm.length > 0 && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap={2}
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={() => reset(defaultValues)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting || isValidating}
              >
                Preview Invoice
              </Button>
              {/* <Button
              variant="contained"
              type="submit"
              disabled={!isDirty || isSubmitting || isValidating}
            >
              {loading ? "Generating..." : "Generate"}
            </Button> */}
            </Stack>
          )}
        </form>
      </Paper>
    </Grid>
  );
}
