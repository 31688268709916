import React from 'react';
import {
  Box, Button, Card, Tab, Tabs,
} from '@mui/material';
import {Info, LightbulbCircle, WorkspacePremium, Business, School, Work} from '@mui/icons-material';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileKeys, profileTabs } from './constant';
import ProfileHeading from './ProfileHeading';
import UserProfile from './profile';
import { UpdateOrg, UpdateUser, UserInfo } from './api';
import { selectProfileDetails, selectProfileErrors, selectResourceDetails } from '../../Redux/Feature/profile/profile.selector';
import { useAlertProvider } from '../../Providers/util/Alert';
import Skill from './Skills';
import Employment from './Employment';
import Certificates from './Certificates';
import { getUserRole, removeKeysFromObject } from '../../Utils/helper';
import { setResourceUpdatedDetails, setUpdatedDetails, updateResourceEditing } from 'Redux/Feature/profile/profile.slice';
import { useDispatch } from 'react-redux';
import Experience from './Experience';
import { isDateCurrentValid } from 'Utils/constants';
import Education from './Education';
import Typography from 'Components/Common/semantic_tags/Typography';
import { useLoaderData, useParams } from 'react-router-dom';

const MemoizedUserProfile = memo(UserProfile);

const deleteKeys = ['experiences', 'profileStrength', 'criteria', 'education', 'id', 'user_id']

function ShowSection({ tab, role, ...props }) {
  const tabs = profileTabs(role);
  if (tab === 0) {
    return <MemoizedUserProfile />;
  } if (tab === 1) return <Skill {...props} />;
  if (tab === 2 && tabs.includes('Certifications')) return <Certificates {...props} />;
  if (tab === 3) return <Experience />;
  if (tab === 4) return <Education />;
  if (tab === 5) return <Employment />;
  // else if (tab === 3 && tabs.includes('References')) return <References />
  return null;
}

export function EditProfile({ isEdit = false }) {
  const [currentTab, setCurrentTab] = useState(0);
  const profile = useSelector(isEdit ? selectResourceDetails :  selectProfileDetails);
  const loaderData = useLoaderData()
  const handleTabChange = (_, value) => {
    setCurrentTab(value)
  };
  const dispatch = useDispatch();
  const errors = useSelector(selectProfileErrors);
  const { success, error } = useAlertProvider();
  const role = useSelector(getUserRole);


const consultantProfileTabs = [
  {name:'About', icon: <Info />},
  {name:'Skills', icon: <LightbulbCircle />},
  {name:'Certifications', icon: <WorkspacePremium />},
  {name:'Experience', icon: <Business />},
  {name:'Education', icon: <School />},
  {name:'Employment', icon: <Work />}
]

  // useEffect(() => {
  //   if (JSON.stringify(profile.skills) !== JSON.stringify(skills)) {
  //     setSkills(ensureNonEmptyArray(profile.skills));
  //   }
  //   if (JSON.stringify(profile.certificates) !== JSON.stringify(certificates)) {
  //     setCertificates(ensureNonEmptyArray(profile.certificates));
  //   }
  //   if (JSON.stringify(profile.services) !== JSON.stringify(services)) {
  //     setServices(ensureNonEmptyArray(profile.services));
  //   }
  // }, [profile.skills, profile.services, profile.certificates]);

  const handleSubmit = async () => {
    if (Object.keys(errors).length > 0) {
      return error(errors[Object.keys(errors)[0]]);
    }
    const profileSection = {
      ...profile,
      state: profile.state?.iso2 || profile.state,
      country: profile.country?.iso2 || profile.country,
      city: profile.city?.name || profile.city,
      business_type: profile?.business_type?.type || profile.business_type,
      company_size: profile?.company_size?.value || profile.company_size,
      availability: profile?.availability?.value || profile.availability,
      portfolio_link: profile.portfolio_link ? profile.portfolio_link : 'test',
    };
    
    profileKeys.forEach((item)=> {
      delete profileSection[item]
    })
    const organisation_details = { ...profileSection.organisation_details };
    delete profileSection.organisation_details;
    const filteredObj = {
      ...removeKeysFromObject(profileSection)
    };
    delete filteredObj.education;
    delete filteredObj.experiences
    delete filteredObj.id;
    delete filteredObj.user_id;
    if (!filteredObj?.availableOption?.date && filteredObj?.availableOption?.name === "After") {
      error("Available Date is Required");
    } else if(filteredObj?.availableOption?.name === "After" && filteredObj?.availableOption?.date === "Invalid Date") {
      error("Please enter a valid Date")
    } else if(filteredObj?.availableOption?.name === "After" && isDateCurrentValid(filteredObj?.availableOption?.date)) {
      error("Please enter date above current date");
    } else {
      delete filteredObj.availableOption;
      delete filteredObj.profileStrength;
      delete filteredObj.criteria;
      const resp = await UpdateUser(filteredObj, role, isEdit, profile?.user_id);
      if (resp?.id) {
        setUpdatedDetails({ ...profile, ...resp })
        success('Profile updated successfully');
      } else {
        error(resp?.message || 'Something went wrong');
      }

    }
    if (profile.role !== 'CONSULTANT' && organisation_details.id) {
      organisation_details.industry_type_id = organisation_details.industry_type_id?.id || organisation_details.industry_type_id;
      organisation_details.company_size = organisation_details.company_size?.value || organisation_details.company_size;
      await UpdateOrg(organisation_details);
    }
    // if (!resp.success) {
    //     return error(resp.message || 'Something went wrong!!!')
    // }
  };

  const prepopulateUserData = async () => {
    const details = await UserInfo();
    if (details && details.data?.result?.organisation) {
      const org = details.data?.result?.organisation ?? {};
      dispatch(
        setUpdatedDetails({
          ...details.data?.result,
          organisation_details: { ...org },
        })
        );
      }
      dispatch(setUpdatedDetails({ ...details.data?.result }));
  };

  useEffect(() => {
    prepopulateUserData()
    dispatch(updateResourceEditing(isEdit))
    if (isEdit) {
      dispatch(setResourceUpdatedDetails(loaderData.data?.result))
    }
    return () => {
      dispatch(updateResourceEditing(false))
    }
  }, [])

  const renderEditButtons = (item, index) => {
    const toggle = currentTab === index;
    return(
      <div className={`flex flex-col items-center min-[300px]:pt-2 cursor-pointer ${index !== consultantProfileTabs.length -1 ? "pr-4" : "pr-0"} ${toggle ? "text-[#3985B6]" : ""}`} onClick={() => setCurrentTab(index)}>
        {item.icon}
        <Typography className='text-[10px]'>{item.name}</Typography>
      </div>
    )
  }

  return (
    <Card sx={{ p: {xs: "24px 12px", md: 3} }}>
      {profile?.username && <ProfileHeading profile={profile} heading="Back" subHeading={!!profile.username && <><strong>Prolegion ID:</strong>{profile.username}</>} isEdit={isEdit} />}
      <div className='min-[300px]:flex min-[300px]:justify-between min-[300px]:items-center md:hidden'>
        <Typography className='font-bold'>Edit Profile</Typography>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            borderRadius: '5px',
            padding: '10px',
            margin: {xs: "1rem 0", md: 0},
            background: '#0B85BA',
            color: 'white',
          }}
        >
          Save Changes
        </Button>
      </div>
      <Box sx={{
        display: 'flex', pt: 1, flexDirection: {xs: "column", md: "row"}, flexWrap: {xs: "wrap", md: "nowrap"}, justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #E5E7EB',
      }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{className: "min-[300px]:!width-0 md:width-"}}
          sx={{ display: {xs: "none", md: "flex"}, marginTop: '16px', borderBottom: "0" }}
        >
          {(profileTabs(profile?.role, isEdit) || []).map((tab) => <Tab label={tab} />)}
        </Tabs>
        <div className={'min-[300px]:flex min-[300px]:flex-wrap min-[300px]:w-full min-[300px]:pb-2 min-[300px]:pl-2 min-[960px]:hidden'}>{consultantProfileTabs.map(renderEditButtons)}</div>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            display: {xs: "none", md: "block"},
            borderRadius: '5px',
            padding: '10px',
            margin: {xs: "1rem 0", md: 0},
            background: '#0B85BA',
            color: 'white',
          }}
        >
          Save Changes
        </Button>
      </Box>
      <Box sx={{ marginTop: '40px' }}>
        <ShowSection tab={currentTab} role={profile.role} success={success} />
      </Box>
    </Card>
  );
}

export default EditProfile;
