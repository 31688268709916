import React, { useMemo } from 'react';
import { TextField, InputAdornment, Stack, Divider, Chip, Select, MenuItem, Box, Autocomplete, LinearProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomSelect from './CustomSelect';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CategoryIcon from '@mui/icons-material/Category';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import WindowIcon from '@mui/icons-material/Window';
import { useSelector } from 'react-redux';
import { jobsDataSelector } from 'Redux/Feature/jobs';
import Button from 'Components/Common/semantic_tags/Button';

const SearchBar = ({sortOrder, setSortOrder,  mainSkills, skills, fetchHandler, setCurrencyType,categoryId, currencyType, setCategoryId, searchText, setSearchText, setSelectedLayout, selectLayout, deleteCategory, addOrRemoveCategory, selectedCategory }) => {
    const {jobCategories} = useSelector(jobsDataSelector);
    const jobCategoryData = useMemo(() => ((jobCategories || []).map((item)=> ({label: item.category, value: item.id}))),[jobCategories])
    const skillsData = useMemo(() => ((skills || []).map((item)=> ({label: item.name, value: item.name} ))),[skills])

    return <div className=''>
        <form onSubmit={fetchHandler} className="flex flex-col md:flex-row items-center rounded-lg mb-4 border-[0.85px] shadow-prolegion border-[solid] border-[#E4E5E8] px-4 py-2 justify-between">
            <TextField
                placeholder="UI/UX Designer"
                variant="outlined"
                fullWidth
                value={searchText}
                className='w-[100%] md:w-[40%]'
                onChange={(e) => setSearchText(e.target.value)}
                sx={{
                    background: 'white !important',
                    '& .MuiOutlinedInput-root': {
                        background: 'white',
                        '& fieldset': {
                            borderColor: 'white',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#0B85BA' }} />
                        </InputAdornment>
                    ),
                }}
            />
            <Divider orientation='vertical' flexItem sx={{ border: 'none', minWidth: '1px', backgroundColor: 'rgba(76, 78, 100, 0.12)' }} />
            <CustomSelect
                icon={<LocationOnIcon sx={{ color: '#0B85BA' }} />}
                value={currencyType}
                onClick={(e) => setCurrencyType(e.target.value)}
                options={[{ label: 'Select Location', value: 'Select' }, { label: "India", value: 'INR' }, { label: "USA", value: "USD" }]}
            />
            <Divider orientation='vertical' flexItem sx={{ border: 'none', minWidth: '1px', backgroundColor: 'rgba(76, 78, 100, 0.12)' }} />
            <CustomSelect
                icon={<CategoryIcon sx={{ color: '#0B85BA' }} />}
                onClick={(e) => setCategoryId(e.target.value)}
                value={categoryId}
                options={[{ label: 'Select Category', value: 'Select' }, ...jobCategoryData]}
            />
            <Divider orientation='vertical' flexItem sx={{ border: 'none', minWidth: '1px', backgroundColor: 'rgba(76, 78, 100, 0.12)' }} />
            <Autocomplete
                className="w-[100%] md:w-[50%]"
                getOptionLabel={(option) => (option.label ? option.label : option)}
                onChange={(_, v) => addOrRemoveCategory(v)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        background: 'white',
                    },
                }}
                value={mainSkills}
                options={[{ label: 'Select Skills', value: 'Select' }, ...skillsData]}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        sx={{
                            background: 'white',
                            '& .MuiOutlinedInput-root': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            },
                        }}
                        value={mainSkills}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                    />
                )}
            />
            <Button type='submit' variant='contained' title='Find Job' />
        </form>
        <div className='flex flex-col md:flex-row justify-between mb-4 items-center'>
            <div className='flex item-center gap-[8px] flex-wrap max-w-[60%]'>
                {
                    selectedCategory.map((val) => {
                        return  <Chip sx={{ background: '#F1F2F4' }} label={val} onDelete={() => deleteCategory(val)} />
                    })
                }
            </div>
            <Box className='flex items-center justify-end gap-2 w-[100%] md:w-[40%]'>
                <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} sx={{ width: '30%', background: 'white' }} className="ml-4" defaultValue="Posted By">
                    <MenuItem value="DESC">Latest</MenuItem>
                    <MenuItem value="ASC">Oldest</MenuItem>
                </Select>
                <Stack className='rounded-lg p-2 border-[0.85px] shadow-prolegion border-[solid] border-[#E4E5E8]' flexDirection={'row'} alignItems={'center'} gap={'8px'} justifyContent={'center'}>
                    <div onClick={() => setSelectedLayout('grid')} className={`cursor-pointer p-1 ${selectLayout === 'grid' ? 'bg-[#F1F2F4]' : ''}`}><WindowIcon /></div>
                    <div onClick={() => setSelectedLayout('row')} className={`cursor-pointer p-1 ${selectLayout !== 'grid' ? 'bg-[#F1F2F4]' : ''}`}><ViewStreamIcon /></div>
                </Stack>
            </Box>
        </div>
    </div>
};

export default SearchBar;
