import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userReducer from './Feature/user/user.slice';
import profileReducer from './Feature/profile/profile.slice';
import createTimesheetReducer from './Feature/createTimesheetSlice';
import tokenReducer from './Feature/tokenSlice';
import sharedConsultantDataReducer from './Feature/sharedConsultantDataSlice';
import userTypeSlice from './Feature/userTypeSlice';
import jobSliceReducer from './Feature/jobs/jobSlice';

const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  createtimesheet: createTimesheetReducer,
  token: tokenReducer,
  sharedConsultantData: sharedConsultantDataReducer,
  userType: userTypeSlice,
  jobsData: jobSliceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});
