import React, { useEffect, useState } from "react";
import moment from "moment";
import { fetchInvitedJobs,  } from "./jobapplication.api";
import { useSelector } from "react-redux";
import { selectUserDetails } from "Redux/Feature/user";
import Button from "Components/Common/semantic_tags/Button";
import { useNavigate } from "react-router-dom";
import { invitedJobsHeaders } from "./utils";

function InvitedJobs() {
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);
  const [jobs, setJobs] = useState([]);

  const fetchDetails = async (id) => {
    const resp = await fetchInvitedJobs(id);
    setJobs(resp.data);
  };

  useEffect(() => {
    if (userDetails && userDetails?.user_id) {
      fetchDetails(userDetails?.user_id);
    }
  }, [userDetails]);

  const renderInvitedJobHeaders = (item, index) => (
    <p className="text-sm w-1/3" key={index}>{item}</p>
  )

  return (
    <div>
      <div className="bg-gray-100 p-2.5 mb-5 rounded-md">
        <div className="flex justify-between items-center">
          {invitedJobsHeaders.map(renderInvitedJobHeaders)}
        </div>
      </div>
      <div className="p-2.5 mb-5 rounded-md">
        {jobs &&
          jobs.map((job) => (
            <div
              key={job.id}
              className="border-b border-gray-300 pb-2.5 mb-2.5 w-full"
            >
              <div className="flex justify-between items-center">
                <div className="text-xs w-1/3">
                  <strong>{job?.Job?.name}</strong>
                  <br />
                  <span className="text-normal mt-2">
                    Posted on {moment(job?.created_at).format("DD MMM, YYYY")}
                  </span>
                </div>
                <div className="w-1/3">
                  <div className="font-bold text-sm flex items-center gap-1">
                    {job?.vendor_company?.toUpperCase()}
                  </div>
                </div>
                <div className="w-1/3">
                  <Button
                    title="View Job"
                    className="border-[##3985B6]"
                    onClick={() =>
                      navigate(`/dashboard/marketplace?id=${job?.job_id}`)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default InvitedJobs;
