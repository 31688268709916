import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React from "react";
import { CertificateIcon } from "Assets/SvgIcons";

function CertificateCard({ tab, index, viewHandler, handleRemoveCertificate }) {
  return (
    <div
      key={tab.title + index}
      className="flex justify-between p-4 min-[300px]:w-full md:w-[32%] mb-4 mr-4 shadow-[0_0_5px_#d1d5db]"
    >
      <div className="flex flex-col items-start">
        <div className="w-11">
        <CertificateIcon />
        </div>
        <div className="flex flex-col min-[300px]:pt-1">
          <span className="font-bold min-[300px]:text-base md:text-lg">{tab.title}</span>
          <span className="min-[300px]:text-xs md:text-sm">{tab?.organization}</span>
          {tab?.issue_date && <span className="min-[300px]:text-xs md:text-sm">Issued {tab?.issue_date}</span>}
          {tab?.credential_id && <span className="min-[300px]:text-xs md:text-sm">Credential ID {tab?.credential_id}</span>}
        </div>
      </div>
      <div className="flex gap-2">
        {tab?.url && <RemoveRedEyeIcon
          className="cursor-pointer"
          onClick={() => viewHandler(tab.url)}
        />}
        <CancelIcon
          className="cursor-pointer"
          onClick={() => handleRemoveCertificate(tab, index)}
        />
      </div>
    </div>
  );
}

export default CertificateCard;
