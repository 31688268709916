import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import parse from "html-react-parser";
import moment from 'moment';
import React from 'react';
import BrandImg from '../../../Assets/prolegionLogoBlue.png';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#F9FAFC'
  },
  container: {
    padding: 32
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: 8,
    backgroundColor: '#F3F4F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 26,
  },
  vendorDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#B2B7C2',
    textAlign: 'right',
    fontSize: 26,
    fontWeight: 600,
    lineHeight: 1.11,
    letterSpacing: -1.08,
  },
  invoiceNumber: {
    color: '#5E6470',
    textAlign: 'right',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.33,
    letterSpacing: 0.24,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
    borderTop: '1px solid #D7DAE0',
    borderBottom: '1px solid #D7DAE0',
    paddingTop: 16,
    paddingBottom: 16,
  },
  sectionItem: {
    flex: 1,
    paddingLeft: 8,
    paddingRight: 8,
  },
  amountDueContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16,
  },
  amountDueBox: {
    backgroundColor: '#0B85BA',
    padding: 8,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  periodContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
    marginBottom: 16,
  },
  table: {
    marginBottom: 16,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #D7DAE0',
    paddingBottom: 4,
    marginBottom: 4
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  tableCell: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  summary: {
    alignSelf: 'flex-end',
    width: 200,
  },
  summaryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: "row",
    alignItems: 'center',
    borderBottom: '1px solid #D7DAE0',
    paddingVertical: 4,
  },
  summaryTotal: {
    borderTop: '2px solid #0B85BA',
    borderBottom: '2px solid #0B85BA',
  },
  comments: {
    marginTop: 24,
    marginBottom: 8,
  },
  textDark: {
    color: '#1A1C21',
    fontSize: 8,
    fontWeight: 600,
    lineHeight: 1.75,
  },
  textLight: {
    color: '#5E6470',
    fontSize: 8,
    fontWeight: 500,
    lineHeight: 1.75,
  },
  textLabel: {
    color: '#5E6470',
    fontSize: 8,
    fontWeight: 600,
    lineHeight: 1.75,
    letterSpacing: 0.32,
    textTransform: 'uppercase',
  },
});

const JobsBasedInvoicePdf = ({
  invoiceDetails,
  jobsOfInvoice,
  clientDetails,
  vendorDetails,
  headersKeys,
  type
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.logoContainer}>
              {(vendorDetails?.profilePhoto || vendorDetails?.profile_image) ? (
                <Image
                  src={vendorDetails?.profilePhoto || vendorDetails?.profile_image}
                  style={styles.logo}
                />
              ) : (
                <View style={styles.logo}>
                  <Text>{vendorDetails?.firstName?.slice(0, 1)}</Text>
                </View>
              )}
              <View style={styles.vendorDetails}>
                <Text style={styles.textDark}>
                  {vendorDetails?.company ||
                    `${vendorDetails?.firstName} ${vendorDetails?.lastName}`}
                </Text>
                <Text style={styles.textLight}>{vendorDetails?.address}</Text>
                <Text style={styles.textLight}>
                  {vendorDetails?.city} {vendorDetails?.state} {vendorDetails?.country}
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.title}>Invoice</Text>
              <Text style={styles.invoiceNumber}>#{invoiceDetails.invoiceno}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionItem}>
              <Text style={styles.textLabel}>Billed To</Text>
              <Text style={styles.textDark}>
                {clientDetails?.company ||
                  `${clientDetails?.firstName} ${clientDetails?.lastName}`}
              </Text>
              <Text style={styles.textLight}>{clientDetails?.address || ""}</Text>
              <Text style={styles.textLight}>{clientDetails?.email || ""}</Text>
            </View>
            <View style={styles.sectionItem}>
              <Text style={styles.textLabel}>Invoice Date</Text>
              <Text style={styles.textDark}>
                {invoiceDetails?.createdAt &&
                  moment(new Date(invoiceDetails?.createdAt)).format('DD MMM, YYYY')}
              </Text>
              <Text style={styles.textLabel}>Due Date</Text>
              <Text style={styles.textDark}>
                {invoiceDetails?.dueDate &&
                  moment(new Date(invoiceDetails?.dueDate)).format('DD MMM, YYYY')}
              </Text>
            </View>
            <View style={styles.amountDueContainer}>
              <Text style={styles.textLabel}>Amount Due</Text>
              <View style={styles.amountDueBox}>
                <Text style={{ color: "#FFF", fontSize: 10, fontWeight: 700 }}>
                  {invoiceDetails?.currency_code === 'USD'
                    ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.grand_total_amount)
                    : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.grand_total_amount)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.periodContainer}>
            <Text style={styles.textLabel}>Period :</Text>
            <Text style={styles.textDark}>
              {invoiceDetails?.startDate &&
                new Intl.DateTimeFormat().format(new Date(invoiceDetails?.startDate))}{" "}
              {invoiceDetails?.endDate ? '-' : ''} {" "}
              {invoiceDetails?.endDate &&
                new Intl.DateTimeFormat().format(new Date(invoiceDetails?.endDate))}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableCell, styles.textLabel]}>#</Text>
              {
                headersKeys.map((headers) => {
                  return <Text style={[styles.tableCell, styles.textLabel]}>{headers}</Text>
                })
              }
            </View>
            {
              type === 'fixed' && jobsOfInvoice.map((jobdetails, key) => (
                <View style={styles.tableRow} key={key}>
                  <Text style={[styles.tableCell, styles.textDark]}>{key + 1}</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>{jobdetails.name}</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>{jobdetails?.consultant?.firstName}{" "}{jobdetails?.consultant?.lastName}</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>{jobdetails?.total_timesheet_days || 0}</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>{
                    invoiceDetails?.currency_code === 'USD'
                      ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(jobdetails?.rate)
                      : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(jobdetails?.rate)
                  }
                    ( {jobdetails?.rate_type === 'HOURLY' ? "H" : "M"} )</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>{jobdetails?.rate_type === 'HOURLY' ? jobdetails?.amount ? jobdetails?.amount / jobdetails.rate : jobdetails?.total_hours : jobdetails?.hours}</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>
                    {invoiceDetails?.currency_code === 'USD' ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(jobdetails?.rate_type === 'HOURLY' ? jobdetails?.amount || jobdetails?.total_amount : jobdetails?.rate) : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(jobdetails?.rate_type === 'HOURLY' ? jobdetails?.amount || jobdetails?.total_amount : jobdetails?.rate)}
                  </Text>
                </View>
              ))
            }
            {
              type === 'flexbile' && jobsOfInvoice.map((project, key) => (
                <View style={styles.tableRow} key={key}>
                  <Text style={[styles.tableCell, styles.textDark]}>{key + 1}</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>{project.project?.title}</Text>
                  <Text style={[styles.tableCell, styles.textDark]}>
                    {invoiceDetails?.currency_code === 'USD'
                      ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(project?.amount)
                      : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(project?.amount)}
                  </Text>
                </View>
              ))
            }
            {type === 'jobs' && jobsOfInvoice.map((job, key) => (
              <View style={styles.tableRow} key={key}>
                <Text style={[styles.tableCell, styles.textDark]}>{key + 1}</Text>
                <Text style={[styles.tableCell, styles.textDark]}>{job?.job?.name || job?.name}</Text>
                <Text style={[styles.tableCell, styles.textDark]}>
                  {invoiceDetails?.currency_code === 'USD'
                    ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.sub_total_amount)
                    : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.sub_total_amount)}
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.summary}>
            {invoiceDetails?.adjustment && Object.keys(invoiceDetails?.adjustment).length > 0 && Object.keys(invoiceDetails?.adjustment).map((adjust, index) => (
              <View style={styles.summaryItem} key={index}>
                <Text style={styles.textDark}>{adjust}</Text>
                <Text style={styles.textLight}>
                  {invoiceDetails?.currency_code === 'USD'
                    ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.adjustment[adjust])
                    : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.adjustment[adjust])}
                </Text>
              </View>
            ))}
            <View style={styles.summaryItem}>
              <Text style={styles.textDark}>Total</Text>
              <Text style={styles.textLight}>
                {invoiceDetails?.currency_code === 'USD'
                  ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.grand_total_amount)
                  : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.grand_total_amount)}
              </Text>
            </View>
            <View style={[styles.summaryItem, styles.summaryTotal]}>
              <Text style={[styles.textDark, { color: "#0B85BA" }]}>Amount Due</Text>
              <Text style={[styles.textDark, { color: "#0B85BA" }]}>
                {invoiceDetails?.currency_code === 'USD'
                  ? new Intl.NumberFormat('pl', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.grand_total_amount)
                  : new Intl.NumberFormat('pl', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.grand_total_amount)}
              </Text>
            </View>
          </View>
          {invoiceDetails?.comments && (
            <View style={styles.comments}>
              <Text style={styles.textLabel}>Payment Terms and Details :</Text>
              {htmlToReactPDF(invoiceDetails?.comments)}
            </View>
          )}
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

const htmlToReactPDF = (htmlString) => {
  const convertedElements = parse(htmlString, {
    replace: domNode => {
      if (domNode.type === 'tag' && domNode.name === 'p') {
        return <Text style={styles.textDark}>{domNode.children.map(child => child.data).join('')}</Text>;
      }
      if (domNode.type === 'tag' && domNode.name === 'strong') {
        return <Text style={styles.textDark}>{domNode.children.map(child => child.data).join('')}</Text>;
      }
      if (domNode.type === 'tag' && domNode.name === 'em') {
        return <Text style={styles.textDark}>{domNode.children.map(child => child.data).join('')}</Text>;
      }
      if (domNode.type === 'tag' && domNode.name === 'u') {
        return <Text style={styles.textDark}>{domNode.children.map(child => child.data).join('')}</Text>;
      }
      if (domNode.type === 'tag' && domNode.name === 'li') {
        return <Text style={styles.textDark}>{`• ${domNode.children.map(child => child.data).join('')}`}</Text>;
      }
      if (domNode.type === 'tag' && domNode.name === 'ul') {
        return <View style={{ marginLeft: 10 }}>{domNode.children.map(child => htmlToReactPDF(child.outerHTML))}</View>;
      }
    }
  });

  return convertedElements;
};

const footerStyle = StyleSheet.create({
  base: {
    marginTop: "auto",
    backgroundColor: "#414141",
    color: "white",
    padding: 20,
  },
  logoImg: {
    width: 100,
    height: 30,
  },
});


const Footer = () => (
  <View style={footerStyle.base}>
    <Image src={BrandImg} style={footerStyle.logoImg} />
  </View>
);

export default JobsBasedInvoicePdf;
