import React from "react";
import { useDispatch } from "react-redux";
import { setOpenPaymentModal } from "Redux/Feature/user";
import MuiModal from "./MuiModal";
import Button from 'Components/Common/semantic_tags/Button'

const PaymentUpgradeModal = () => {
    const dispatch = useDispatch()

    const handleRedirect = () => {
        dispatch(setOpenPaymentModal({ openPaymentModal: false }))
        window.location.href = '/dashboard/settings?screen=plans'
    }
    return (
        <MuiModal className='max-w-xl'  open={true} divClassName="!z-[99999]" onCancel={() => dispatch(setOpenPaymentModal({ openPaymentModal: false }))}>
                <div className="flex justify-center mb-4 mt-2">
                    <img
                        src="https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/SL.031121.41400.47.jpg"
                        alt="Manatal"
                    />
                </div>
                <h2 className="text-xl font-semibold mb-4 text-center">
                    Unlock the full power of Prolegion
                </h2>
                <p className="text-gray-500 text-center mb-4">
                    Oops! It looks like this feature is not available with your current subscription.
                    Unlock access to all premium features by upgrading your plan now. Enhance your experience with the full range of our powerful tools and exclusive content.
                </p>
                <div className="flex justify-center items-center">
                    <Button className="" title='Subscribe Now' onClick={handleRedirect} />
                </div>
        </MuiModal>
    );
};

export default PaymentUpgradeModal;
