import MuiModal from 'Components/Common/MuiModal'
import React from 'react'

function ViewDescriptionModal({open, onClose, description}) {
  return (
    <MuiModal
      open={open}
      onCancel={onClose}
      title='Description'
      maxWith="sm"
    >
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </MuiModal>
  )
}

export default ViewDescriptionModal