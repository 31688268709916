import React from "react";

const Typography = ({ variant = 'p', className = '', children ,onClick = () => {} }) => {
  let typographyClasses = "!tracking-wider";

  if (variant === 'h1') typographyClasses += " font-extrabold text-xl";
  else if (variant === 'h2') typographyClasses += " font-bold";
  else if (variant === 'h3') typographyClasses += " font-bold text-xl";
  else if (variant === 'link') typographyClasses += " text-blue-500 hover:text-blue-700";


  if (className) typographyClasses += ` ${className}`;

  return (
    <p className={typographyClasses.trim()} onClick={onClick}>{children}</p>
  );
};

export default Typography;
