import { styled } from '@mui/material';
import React from 'react';

const WrapperStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 'calc(1280px - 32px)',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: 'calc(960px - 32px)',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(0, 2),
  },
  minWidth: '300px',
  margin: '0px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const WrapperWithSidebarStyled = styled('div')(() => ({
  width: 'calc(100% - 16px)',
  minWidth: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export default function FullWidthWrapper({
  children,
  sidebar,
  isPublic = false,
  sx,
}) {
  return isPublic ? <>
    {children}
  </> : sidebar ? (
    <WrapperWithSidebarStyled sx={sx}>
      {children}
    </WrapperWithSidebarStyled>
  ) : (
    <WrapperStyled sx={sx}>
      {children}
    </WrapperStyled>
  );
}
