import CurrentClients from 'Pages/DashboardVendor/components/Clients/CurrentClients';
import React from 'react';
import AppDefaultPage from 'Pages/AppDefaultPage/AppDefault.page';
import RequireAuth from './RequireAuth';

export const clientsRoutes = [
  {
    path: 'clients',
    element: (
      <RequireAuth>
        <AppDefaultPage />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <CurrentClients />
          </RequireAuth>
        ),
      },
    ],
  },
];
