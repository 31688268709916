const homepageGetStartedClient = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/HomepageGetStarted/client.png';
const homepageGetStartedVendor = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/HomepageGetStarted/vendor.png';
const homepageGetStartedConsultant = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/HomepageGetStarted/consultant.png';

export const homepageRoles = ['client', 'vendor', 'consultant'];

export const homepageToggleRolesData = [
  {
    title: 'Experience an effortless cloud-based vendor management',
    subtitle1: 'Oversee your  workforce efficiently and build ',
    subtitle2: 'long-lasting relationships with your vendors and resources.',
    img: homepageGetStartedClient,
  },
  {
    title: 'Get ahead of the competition with ProLegion\'s innovative solutions',
    subtitle1: 'Join for free and unlock the full potential of your business with ProLegion',
    subtitle2: 'unlock the full potential of your business with ProLegion',
    img: homepageGetStartedVendor,
  },
  {
    title: 'Boost your career with ProLegion\'s innovative platform',
    subtitle1: 'Build your profile and expand your professional',
    subtitle2: 'network while collaborating with clients',
    img: homepageGetStartedConsultant,
  },
];
