import Marketpalce from 'Pages/Marketplace/Marketplace.page';
import React from 'react';
import { Link, defer, useLocation } from 'react-router-dom';
import MarketPlaceApplicants from 'Pages/MarketplaceNew/MarketPlaceApplicants';
import RequireAuth from './RequireAuth';
import MarketplaceApplicantProfile from 'Pages/MarketplaceNew/MarketplaceApplicantProfile';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { ChevronRight } from '@mui/icons-material';
import useQuery from 'hooks/useQuery';

export const marketplaceRoutes = [
  {
    path: 'marketplace',
    handle: () => <Link to='/marketplace'>Marketplace</Link>,
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            {/* <Breadcrumbs /> */}
            <Marketpalce />
          </RequireAuth>
        ),
      },
      {
        path: 'applicants/:id',
        handle: {
          crumb: (res) => {
            const query = useQuery();
            const {pathname} = useLocation();
            const location = useLocation();
            const jobId = pathname.split('/')[pathname.split('/').length - 1];
            const jobName = query.get("job-name") !== "undefined" ? query.get("job-name") : "";
            return(
              <>
                <Link to='/dashboard/marketplace'>Marketplace</Link>
                <ChevronRight sx={{ mx: 1 }} />
                <Link to='/dashboard/marketplace?tab=My Jobs'>My Jobs</Link>
                <ChevronRight sx={{ mx: 1 }} />
                <Link to={`${location.pathname + location.search}`}>{jobName}</Link>
              </>
            )
          },
        },
        element: (
          <RequireAuth>
            <div className="pb-4">
              <Breadcrumbs />
            </div>
            <MarketPlaceApplicants />
          </RequireAuth>
        ),
      },
      {
        path: 'applicants/:id/view/:profileId',
        element: (
          <RequireAuth>
            <MarketplaceApplicantProfile />
          </RequireAuth>
        ),
      },
    ],
  },
];
