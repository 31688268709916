import React, { useEffect, useState } from 'react';
import Certificate from 'Pages/editProfile/common/Certificate';
import { deleteCertificateById } from 'Pages/editProfile/api';
import Button from 'Components/Common/semantic_tags/Button';
import Heading1 from 'Components/Common/semantic_tags/Heading1';
import Span from 'Components/Common/semantic_tags/Span';
import CustomInput from 'Components/Common/semantic_tags/Input';
import CertificateCard from './CertificateCard';
import { useSelector } from 'react-redux';
import { selectProfileDetails, selectResourceDetails } from 'Redux/Feature/profile/profile.selector';
import { useAlertProvider } from 'Providers/util/Alert';

const Certificates = () => {
  const { success } = useAlertProvider()
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const profile = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const [certificates, setCertificates] = useState(profile?.certificates || [])
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');

  const viewHandler = (url) => {
    window.open(url, '__blank');
  };

  useEffect(() => {
    setCertificates(profile?.certificates)
  }, [profile?.certificates])

  const handleRemoveCertificate = async (item, index) => {
    const updatedCertificates = [...certificates];
    updatedCertificates.splice(index, 1);
    setCertificates(updatedCertificates);
    await deleteCertificateById(item.id, isResourceEditing, profile?.user_id).then(() => {
      success('Certificate Removed');
    });
  };

  return (
    <div className='flex flex-col'>
      <Certificate open={openModal} onClose={() => setOpenModal(false)} />
      <Heading1 className='mb-2'>Certificates</Heading1>
      <div className='flex gap-2 mb-6'>
        <CustomInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search by title'
          parentClassName='min-[300px]:w-[66%] md:w-[30%]'
        />
        <Button
          onClick={() => setOpenModal(true)}
          title='Add Certification'
        />
      </div>
      <div className='flex flex-wrap justify-start'>
        {((search ? certificates.filter((i) => i.title.includes(search)) : certificates) || []).map((tab, j) => (
          <CertificateCard tab={tab} index={j} viewHandler={viewHandler} handleRemoveCertificate={handleRemoveCertificate}/>
        ))}
      </div>
    </div>
  );
};

export default Certificates;
