import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Button from "Components/Common/semantic_tags/Button";
import { ProfilePage } from "Pages/ProfilePage";
import { useJobProvider } from "Providers/Vendor/Jobs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateJobApplicationInMarketplace } from "./jobapplication.api";
import { getSingleJobApplication, getSingleMarketPlaceJob } from "./marketplace.api";
import FeedbackModal, { StarRating } from './feedback/FeedbackModal';
import { getFeedBack } from './feedback/feedback.api';
import Typography from 'Components/Common/semantic_tags/Typography';
import { useAlertProvider } from 'Providers/util/Alert';
import { submitApplication } from 'Pages/Marketplace/marketplace.api';


const MarketplaceApplicantProfile = () => {
    const { id, profileId } = useParams()
    const { success, error } = useAlertProvider()
    const [jobDetails, setJobId] = useState(null)
    const [applicantDetails, setApplicantDetails] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [isSubmitFeedbackRequired, setIsSubmitFeedbackRequired] = useState(false)
    const { assignConsultantToJob, assignSubVendorToJob } = useJobProvider()
    const [loading, setLoading] = useState(true);
    const [feedbackDetails, setFeebackDetails] = useState([])
    const navigate = useNavigate()

    const fetchFeedback = async (id, applicant_id, job) => {
        setLoading(true)
        const resp = await getFeedBack(id, applicant_id, false)
        if (resp.success) {
            if (resp.data && resp.data?.length > 0) {
                setFeebackDetails(resp?.data || [])
                let requiredSubmission = true
                resp.data.forEach((detail)=> {
                    if(detail.givenBy === job?.createdBy) {
                        requiredSubmission = false
                    }
                })
                setIsSubmitFeedbackRequired(requiredSubmission)
            } else {
                setIsSubmitFeedbackRequired(true)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (id) {
            getSingleMarketPlaceJob(id).then((resp) => {
                setJobId(resp.data)
                getSingleJobApplication(id).then((res) => {
                    if (res && res.data && res.data?.data && res.data.data.jobApplications) {
                        res.data.data.jobApplications.forEach((data) => {
                            if (data.job_id === id && data?.consultant?.username === profileId) {
                                setApplicantDetails(data)
                                fetchFeedback(resp.data?.id, data?.applicant_id, resp.data)
                            }
                        })
                    }
                })
            })
        }
    }, [id])

    const hireCandidate = async () => {
        applicantDetails?.vendor_id && await assignSubVendorToJob({ job_id: jobDetails?.primary_job_id, vendor_id: applicantDetails?.vendor_id, type: 'vendor' }, false)
        applicantDetails?.applicant_id && await assignConsultantToJob({ job_id: jobDetails?.primary_job_id, consultant_id: applicantDetails?.applicant_id }, false)
        await updateJobApplicationInMarketplace(applicantDetails.id, { status: 'hired' });
        jobDetails?.primary_job_id && navigate(`/dashboard/jobs/${jobDetails?.primary_job_id}`)
        return
    }

    const submitHandler = async () => {
        if (!!isSubmitFeedbackRequired && !jobDetails?.isFeedbackGiven) return error('Please provide your interview feedback')
        const respObj = {
            vendor_id: applicantDetails?.vendor_id,
            created_by: jobDetails.createdBy,
            job_id: jobDetails.parent_job_id,
            parent_job_id: jobDetails.parent_job_id,
            applicantIds: [applicantDetails.applicant_id]
        }
        const resp = await submitApplication(respObj)
        if (resp.success) {
            success(resp.message || 'Job Application submitted Successfully')
            await updateJobApplicationInMarketplace(applicantDetails.id, { status: 'submit' });
            navigate(`/dashboard/marketplace/applicants/${jobDetails.id}`)
        } else error(resp.error || resp.message || 'Something went wrong!!!')
    }

    return <div className="">
        <ProfilePage isMarketingView={true}>
            {
                <div className='mt-4'>
                    {(!isSubmitFeedbackRequired && jobDetails?.status !== 'HIRED' && applicantDetails?.status !== 'submit') && <Button onClick={jobDetails?.parent_job_id ? submitHandler : hireCandidate}
                        title={<><ArrowCircleDownIcon sx={{ transform: 'rotate(-90deg)' }} />{jobDetails?.parent_job_id ? 'Submit Candidate' : 'Hire Candidate'}</>}
                    />}
                    {!loading && isSubmitFeedbackRequired && <Button onClick={() => setOpenModal(true)} title='Submit Feedback' className='mt-4' />}
                    {feedbackDetails?.length > 0 && <FeebackDetails details={feedbackDetails} /> }
                </div>
            }
            {openModal && <FeedbackModal feedbackDetails={feedbackDetails} setIsSubmitFeedbackRequired={setIsSubmitFeedbackRequired} setFeebackDetails={setFeebackDetails} applicantDetails={applicantDetails} isOpen={openModal} onClose={() => setOpenModal(false)} jobDetails={jobDetails} />}
        </ProfilePage>
    </div>
}

const FeebackDetails = ({ details }) => {
    const [openIndex, setOpenIndex] = useState(0);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="space-y-4 mt-2">
            {details.map((detail, index) => (
                <div key={index} className="border-b border-gray-200">
                    <div
                        className="cursor-pointer p-2 bg-gray-100 hover:bg-gray-200"
                        onClick={() => toggleAccordion(index)}
                    >
                        <Typography className="text-gray-500 font-bold text-sm">
                            {detail.feedback_type?.type_name} by {detail.Job?.vendorName}
                        </Typography>
                    </div>
                    {openIndex === index && (
                        <div className="p-4 bg-white">
                            <Typography className="text-gray-500 text-xs mt-2">
                                <strong>Job Name:</strong> {detail.Job?.name}
                            </Typography>
                            <Typography className="text-gray-500 text-xs flex items-center gap-2">
                                <strong className="w-[40%]">Overall Rating:</strong>
                                <StarRating className="font-[8px]" value={detail.rating} />
                            </Typography>
                            <Typography className="text-gray-500 text-xs flex items-center gap-2">
                                <strong className="w-[40%]">Technical Rating:</strong>
                                <StarRating className="font-[8px]" value={detail.technicalSkills} />
                            </Typography>
                            <Typography className="text-gray-500 text-xs flex items-center gap-2">
                                <strong className="w-[40%]">Business Rating:</strong>
                                <StarRating className="font-[8px]" value={detail.businessKnowledge} />
                            </Typography>
                            <Typography className="text-gray-500 text-xs flex items-center gap-2">
                                <strong className="w-[40%]">Communication Rating:</strong>
                                <StarRating className="font-[8px]" value={detail.communicationSkills} />
                            </Typography>
                            <Typography className="text-gray-500 text-xs mt-4">
                                <strong>Comments:</strong> {detail.comments}
                            </Typography>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default MarketplaceApplicantProfile