import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { uploadFile } from "Pages/editProfile/api";
import { getUserIdFromLocalStorage } from "Utils/helper";

const ReactDropzone = ({ setDetails, files, disabled = false, multiple = true, maxFiles = 20, setIsUploading = () => {}, styles = {} }) => {
  const [uploading, setUploading] = useState(false);

  const handleChangeStatus = async ({ meta, file }, status) => {
    if (status === "preparing") {
      setUploading(true);
      setIsUploading(true);
      const resp = await uploadFile({ cur: file, user: { id: getUserIdFromLocalStorage() }, photo_type: 'profile_photo', content: meta.type.split('/')[0] });
      setUploading(false);
      setIsUploading(false);
      if(resp.url) {
        if (multiple) setDetails([...files, { id: meta.id, url: resp.url, document_id: resp.id }], 'files')
        else setDetails([{ id: meta.id, url: resp.url, document_id: resp.id }], 'files')
      }
    }
    if (status == 'removed') {
      const filteredResult = files.filter(i => i.id !== meta.id)
      setDetails(filteredResult, 'files')
    }
  };

  return (
    <div>
      <Dropzone
        styles={styles}
        multiple={multiple}
        disabled={disabled || uploading} // Disable Dropzone while uploading
        inputContent={uploading ? "Uploading..." : "Drag and Drop attachments"}
        onChangeStatus={handleChangeStatus}
        accept="image/*,application/*"
        maxFiles={maxFiles}
      />
      {uploading && <div>Uploading...</div>} {/* Show loader while uploading */}
    </div>
  );
};

export default ReactDropzone;
