import {
  Avatar, Box, Button, CircularProgress, Stack, Typography, styled,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { updateAvatar, uploadFile } from '../api';
import { useAlertProvider } from '../../../Providers/util/Alert';
import { setResourceUpdatedDetails, setUpdatedDetails } from '../../../Redux/Feature/profile/profile.slice';
import { selectProfileDetails, selectResourceDetails } from '../../../Redux/Feature/profile/profile.selector';
import MuiModal from '../../../Components/Common/MuiModal';
import VideoUpload from 'Pages/Dashboard/components/VideoUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function UploadProfile() {
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const userDetails = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const dispatch = useDispatch();
  const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { success, error } = useAlertProvider();

  const openModal = () => setOpenProfilePhotoModal(true);
  const closeModal = () => setOpenProfilePhotoModal(false);

  const handleUpload = async (event) => {
    try {
      const file = event.target.files[0];

      if (!file) {
        return error('No file selected.');
      }
      const allowedExtensions = ['png', 'jpg', 'jpeg'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        return error('Invalid file format. Please upload a PNG, JPG, or JPEG file.');
      }

      setUploading(true);

      const uploadedUrl = await uploadFile({ cur: file, user: userDetails });

      if (!uploadedUrl.success) {
        setUploading(false);
        return error(uploadedUrl.error);
      }
      const resp = await updateAvatar(uploadedUrl.url, userDetails, isResourceEditing, userDetails.user_id);

      if (resp.success) {
        dispatch(isResourceEditing ? setResourceUpdatedDetails({ profile_image: uploadedUrl.url }) : setUpdatedDetails({ profile_image: uploadedUrl.url }));
      }

      success('Profile photo updated successfully');
      setUploading(false);
    } catch (e) {
      error('Something went wrong!!!');
      setUploading(false);
    }
  };

  const deleteUpload = async () => {
    try {
      const resp = await updateAvatar('', userDetails, isResourceEditing, userDetails.user_id);
      if (resp.success) {
        dispatch(isResourceEditing ? setResourceUpdatedDetails({ profile_image: '' }) : setUpdatedDetails({ profile_image: '' }));
      }
      success('Profile photo deleted successfully');
    } catch (e) {
      error('Something went wrong!!!');
    }
  };

  return (
    <Stack gap={1} sx={{ mb: 3 }}>
      <div className='flex min-[300px]:flex-col md:flex-row items-center justify-between'>
      <Box sx={{ width: '200px', height: '200px', cursor: "pointer", marginBottom: {xs :"1rem", md: "0"} }} onClick={openModal}>
        {
          userDetails?.profile_image
            ? (
              <Avatar
                src={userDetails?.profile_image}
                sx={{
                  width: '100%', height: '100%', background: '#F3F4F6', borderRadius: '10px',
                }}
              />
            )
            : (
              <Box
                className="make-content-center"
                sx={{
                  width: '100%', height: '100%', background: '#F3F4F6', borderRadius: '10px',
                }}
              >
                <Typography variant="h4Bold">{userDetails?.first_name?.slice(0, 2).toUpperCase()}</Typography>
              </Box>
            )
        }
      </Box>
      {userDetails?.role === 'CONSULTANT' && <VideoUpload />}
      </div>
      <MuiModal
        open={openProfilePhotoModal}
        onCancel={closeModal}
        minWidth={500}
        title='Upload Profile Picture'
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <Box sx={{
            width: '400px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '40px',
          }}
          >
            {
              userDetails?.profile_image
                ? (
                  <Avatar
                    src={userDetails?.profile_image}
                    sx={{
                      width: '100%', height: '100%', background: '#F3F4F6', borderRadius: '50%',
                    }}
                  />
                )
                : (
                  <Box
                    className="make-content-center"
                    sx={{
                      width: '100%', height: '100%', background: '#F3F4F6', borderRadius: '50%',
                    }}
                  >
                    <Typography variant="h4Bold">{userDetails?.first_name?.slice(0, 2).toUpperCase()}</Typography>
                  </Box>
                )
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Button
              disabled={uploading}
              component="label"
              style={{
                borderRadius: '5px',
                padding: '10px',
                background: '#0B85BA',
                color: 'white',
              }}
            >
              {uploading ? <CircularProgress sx={{ color: 'white', width: '10px', height: '10px' }} /> : 'Update'}
              <VisuallyHiddenInput onChange={handleUpload} type="file" accept=".png, .jpeg, .jpg" />
            </Button>
            {userDetails?.profile_image && (
              <Button
                onClick={deleteUpload}
                style={{
                  borderRadius: '5px',
                  padding: '10px',
                  background: '#D1D5DB',
                  color: 'black',
                }}
              >
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </MuiModal>
    </Stack>
  );
}

export default UploadProfile;
