import React, { useState, createContext, useContext } from "react";

const nullFn = () => null;
const paramFn = (param) => null;
const AlertContext = createContext({
  open: false,
  type: "",
  text: "",
  success: paramFn,
  error: paramFn,
  warning: nullFn,
  handleClose: nullFn,
});

export const useAlertProvider = () => useContext(AlertContext);

const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [text, setText] = useState("");

  const success = (message) => {
    setText(message);
    setType("success");
    setOpen(true);
  };
  const error = (message) => {
    setText(message);
    setType("error");
    setOpen(true);
  };
  const warning = (message) => {
    setText(message);
    setType("warning");
    setOpen(true);
  };
  const handleClose = () => setOpen(!open);

  const value = {
    open,
    type,
    text,
    success,
    error,
    warning,
    handleClose,
  };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export default AlertProvider;
