import React, { useState, createContext, useContext } from "react";
import api from "../../Utils/api";

const apiPath = {
  fetchJobsByProject: "/jobnproject/getjobsbyproject",
  fetchProjects: "/project/get-projects"
};

const nullFn = () => null;
const CommonJobContext = createContext({
  loading: false,
  fetchAllJobs: nullFn,
  fetchAllProjects: nullFn,
  allJobs: [],
  allProjects: [],
  setAllProjects: nullFn
});

export const useCommonJobProvider = () => useContext(CommonJobContext);
const CommonJobProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [allProjects, setAllProjects] = useState([]);

  const fetchAllJobs = async () => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.fetchJobsByProject);
      if (res.status === 200 && res.data?.success) {
        setAllJobs(res.data.result);
      } else {
        throw new Error("Error occurred while fetching jobs");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllProjects = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchProjects);
      if (res.status === 200 && res.data?.success) {
        
        setAllProjects(res.data.result);
      } else {
        throw new Error("Error occurred while fetching projects");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    loading,
    fetchAllJobs,
    fetchAllProjects,
    allJobs,
    allProjects,
    setAllProjects
  };

  return (
    <CommonJobContext.Provider value={value}>
      {children}
    </CommonJobContext.Provider>
  );
};

export default CommonJobProvider;
