import api from "Utils/api";
import moment from "moment";


export const InvoiceCreateApi = async (body) => {
    return api
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/invoice/create`, body)
      .then((data) => {
        return {
          data: {
            success: true,
            result: data.data,
          },
        };
      })
      .catch((err) => {
        return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
      });
  };

  export const getInvitedId = async (id = '') => {
    return api
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/invite/invite-client-for-invoice?id=${id}`)
      .then((data) => {
        localStorage.setItem("at", data.data.result.access_token);
        localStorage.setItem("rft", data.data.result.refresh_token);
        return data
      })
      .catch((err) => {
        return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
      });
  };
  
  export const getInvoiceApi = async (invoiceId, type = '') => {
    return api
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/invoice?invoice_type=${type}&invoice_ids=["${invoiceId}"]`)
      .then((data) => {
        return data
      })
      .catch((err) => {
        return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
      });
  };

  export const updateInvoice = async (invoiceid, body) => {
    return api
      .patch(`${process.env.REACT_APP_BASE_URL}/api/v1/invoice/${invoiceid}`, body)
      .then((data) => {
        return {
          data: {
            success: true,
            result: data.data,
          },
        };
      })
      .catch((err) => {
        return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
      });
  };

export const getJobsWithTimesheets = async (id, fromDate, toDate, type = 'client', currencyFromForm, monthFrom, typeDate = 'dateRange', isJobBased = false) => {
  const startDay = fromDate ? new Date(fromDate) : new Date();
  const endDay = toDate ? new Date(toDate) : new Date();
  const startOfDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0, 0);
  const endOfDay = new Date(endDay.getFullYear(), endDay.getMonth(), endDay.getDate(), 23, 59, 59, 999);
  let query = ''
  if (typeDate === 'month') {
    query = `&month=${moment(monthFrom).format('YYYY-MM')}`
  }
  return api.get(`${process.env.REACT_APP_BASE_URL}/api/v1/timesheet/jobs?${type}=${id}&from_date=${startOfDay?.toISOString()}&to_date=${endOfDay?.toISOString()}${query}&currency=${currencyFromForm}${isJobBased ? '&requestType=JOB_BASED' : ''}`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
    });
};

export const getJobsWithTimesheetsByJob = async (id, fromDate, toDate) => {
  const startDay = new Date(fromDate);
  const endDay = new Date(toDate);
  // const startOfDay = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate(), 0, 0, 0, 0);
  // const endOfDay = new Date(endDay.getFullYear(), endDay.getMonth(), endDay.getDate(), 23, 59, 59, 999);
  return api.get(`${process.env.REACT_APP_BASE_URL}/api/v1/timesheet/getTimesheetsBetweenDates?job_id=${id}&from_date=${moment(startDay).format('YYYY-MM-DD')}&to_date=${moment(endDay).format('YYYY-MM-DD')}&currency=USD`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
    });
};