import React from 'react';
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import { Box, Typography } from '@mui/material';

export default function CustomTable(props) {
  const table = useMaterialReactTable({
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableColumnFilters: false,
    enableTopToolbar: false,
    enableRowActions: false,

    muiPaginationProps: {
      showRowsPerPage: false,
      sx: {
        borderRadius: '8px',
        '.MuiPaginationItem-root': {
          typography: 'body2Medium',
          color: '#6B7280',
          borderRadius: 0,
          margin: 0,
          p: (theme) => theme.spacing(0.75, 1.5),
          '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#3985B6',
            borderRadius: '50%'
          },
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        borderRadius: '8px 8px 0 0',
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        border: '1px solid #E5E7EB',
        borderRadius: '8px',
      },
    },
    muiTableHeadProps: {
      sx: {
        '.MuiTableCell-root': {
          typography: 'p',
          color: '#4a5568',
          borderBottomColor: '#E5E7EB',
          backgroundColor: 'rgb(249 250 251/10)',
          p: 2,
          fontWeight: 'bold',
          textAlign: 'left',
        },
      },
    },
    muiTableBodyProps: {
      sx: {
        'tr:last-of-type td': {
          borderBottom: 'none',
        },
        td: {
          typography: 'p',
          color: '#374151',
          backgroundColor: '#FFFFFF',
          p: 2,
          textAlign: 'left',
        },
        'tr:hover td': {
          backgroundColor: 'white',
        },
      },
    },
    renderEmptyRowsFallback: () => (
      <Box sx={{ py: 5, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
          No results
        </Typography>
      </Box>
    ),
    ...props,
  });

  return <MaterialReactTable table={table} />;
}
