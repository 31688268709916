import React, { useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useConnectionProvider } from "../../../../Providers/Vendor/Connections";
import CustomTable from "../../../../Components/Table";
import { stringAvatar } from "../../../../Utils/avatar";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";

import ListItemIcon from "@mui/material/ListItemIcon";
import { Grid, Typography } from "@mui/material";

const headCells = [
  {
    id: "Vendor Name",
    numeric: false,
    disablePadding: false,
    label: "Vendor Name",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

export function SelectVendors({ selectedVendors, setSelectedVendors }) {
  const { vendors, fetchVendors } = useConnectionProvider();

  const isDisabled = (data) => {
    return data?.status !== "Current";
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  function isChecked(item) {
    return (
      selectedVendors.findIndex((data) => {
        return data.id === item.id;
      }) !== -1
    );
  }

  function computeName(data) {
    return data.companyName
      ? data.companyName
      : data.firstName
      ? `${data.firstName} ${data?.lastName || ""}`
      : data.email;
  }

  const handleToggle = (value) => () => {
    if (isDisabled(value)) return;

    const currentIndex = selectedVendors.findIndex(
      (data) => data.id === value.id
    );
    const newChecked = [...selectedVendors];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedVendors(newChecked);
  };

  return (
    <div className="select-vendors">
      <div className="grid w-full items-center pb-8 pt-4">
        <div className="flex flex-row space-x-2 items-center">
          <Avatar
            sx={{ bgcolor: "white", color: "#139588" }}
            className="border-primary"
          >
            2
          </Avatar>
          <div className="text-greyish"> Select vendor</div>
        </div>
      </div>
      <List sx={{ width: "100%", minHeight: 450, bgcolor: "background.paper" }}>
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="body1"
          id="select-vendors-title"
          className="p-3 pl-8 border-bottom-grey"
          component="div"
        >
          {selectedVendors ? selectedVendors.length : 0} Selected
        </Typography>
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="select-vendors-title"
          className="p-4 pl-8 border-bottom-grey"
          component="div"
        >
          Vendor
        </Typography>
        {vendors &&
          vendors.map((value) => {
            const labelId = `checkbox-list-label-${value.id}`;

            return (
              <ListItem key={value.id} className="border-bottom-grey">
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isChecked(value)}
                      disabled={isDisabled(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar
                      size="large"
                      className="mr-2.5"
                      src={value?.profilePhoto}
                      {...stringAvatar(computeName(value))}
                    />
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={computeName(value)} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
    </div>
  );
}
