import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Back from "../../../../Components/Back";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Badge, Grid, Typography } from "@mui/material";
import { SelectVendors } from "./SelectVendors";
import PrimaryButton from "../../../../Components/Shared/PrimaryButton";
import MailIcon from "@mui/icons-material/Mail";
import { ToastNotifier } from "../../../../Components/Shared/ToastNotifier";
import { shareResources } from "../../../../services/shared-resources";
import { useNavigate } from 'react-router-dom'

export const SelectVendorToShareConsultant = ({ onBack, selected }) => {
  const MESSAGES = {
    success: "Resource shared successfully",
    error: "",
  };
  const [selectedVendors, setSelectedVendors] = React.useState([]);
  const [checked, setChecked] = React.useState([...selected]);
  const [showMessage, setShowMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(MESSAGES.success);
  const [errorMessages, setErrorMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [successCases, setSuccessCases] = useState([])
  const [errorCases, setErrorCases] = useState([])



  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex((data) => data.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  function isChecked(item) {
    const checker = (data) => data.id === item.id;
    return checked.findIndex(checker) !== -1;
  }

  function createResources() {
    let resources = [];
    const userId = localStorage.getItem("userId");

    checked.forEach((consultant) => {
      selectedVendors.forEach((vendor) => {
        const payload = {
          resource_id: consultant.id,
          shared_by_user: userId,
          shared_to_user: vendor?.vendor_id,
          permission: "read",
          status: "active",
        };

        resources.push(payload);
      });
    });

    resources = resources.map((resource) => {
      return shareResources(userId, resource);
    });
    return resources;
  }

  async function onShareResources() {
    try {
      setIsLoading(true);

      let errorCount = 0;
      const promises = await Promise.allSettled(createResources());
      promises.forEach(({ status, reason }) => {
        if (status === "rejected") {
          errorCount++;
          setErrorCases([...errorCases, { message: `Resource  is already shared` }])
        } else {
          setSuccessCases([...successCases, { message: `Resource shared successfully` }])
        }

      })

      if (errorCount === 0) {
        setTimeout(() => {
          onBack();
        }, 2000);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-2 w-full items-center">
        <div className="flex flex-row space-x-2">
          <Back
            onClick={() => {
              onBack();
            }}
          />
          <span className="">Consultants</span>
        </div>
      </div>

      <div className="flex w-full">
        <div className="flex-none">
          <div className="grid w-full items-center pb-8 pt-4">
            <div className="flex flex-row space-x-2 items-center">
              <Avatar sx={{ bgcolor: "#139588" }}>1</Avatar>
              <div className="text-greyish"> Select consultant</div>
            </div>
          </div>
          <List
            sx={{
              width: "100%",
              minHeight: 450,
              minWidth: 300,
              bgcolor: "background.paper",
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="body1"
              id="select-vendors-title"
              className="p-3 pl-8 border-bottom-grey"
              component="div"
            >
              {selected ? selected.length : 0} Selected
            </Typography>
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="select-vendors-title"
              className="p-4 pl-8 border-bottom-grey"
              component="div"
            >
              Consultants
            </Typography>
            {selected &&
              selected.map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;

                return (
                  <ListItem key={value.id} className="border-bottom-grey">
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={isChecked(value)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value + 1}`}
                          src={`/static/images/avatar/${value + 1}.jpg`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={`${value?.firstName ?? ""} ${value?.lasttName ?? ""
                          }`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </div>
        <div className="flex-1 pl-8 ml-8">
          <SelectVendors
            selectedVendors={selectedVendors}
            setSelectedVendors={setSelectedVendors}
          />
        </div>
      </div>

      <div className="row flex mb-4">
        <div class="flex flex-none gap-x-4 justify-end py-4">
          <PrimaryButton label={"Back"} outlined onClick={onBack} />
        </div>
        <div class="flex flex-1 gap-x-4 justify-end py-4">
          <PrimaryButton label={"Cancel"} onClick={onBack} outlined />

          <PrimaryButton label={"Send"} onClick={onShareResources} disabled={isLoading} />
        </div>
      </div>

      {successCases?.map(({ message }) => <ToastNotifier message={message} type='success' />)}
      {errorCases?.map(({ message }, idx) => <ToastNotifier message={message} type='error' />)}

    </>
  );
};
