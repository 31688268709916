import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { FullWidthWrapper } from "../../Common";

export default function PageHeader (props) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  return (
    <Box sx={{ backgroundColor: "primary.dark", pt: isMobile ? 4 : 8, pb: isMobile ? 3 : 6 }}>
      <FullWidthWrapper>
        <Grid container px={3}>
          <Grid item lg={12} sx={{ color: "common.white" }}>
            {props.children || <>
            <Typography variant={isMobile ? 'h4' : "h2"} mb={1}>
              {props.title}
            </Typography>
            {props.subtitle && (
              <Typography variant={isMobile ? 'body1' : 'h4'}>
                {props.subtitle}
              </Typography>
            )}
            {props.content && (
              <Typography variant={"body1"}>
                {props.content}
              </Typography>
            )}
            </>}
          </Grid>
        </Grid>
      </FullWidthWrapper>
    </Box>
  )
}