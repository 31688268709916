import React from 'react';

export default function DashboardIcon({ color, size = '16px' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path d="M6.73684 16C4.95012 16 3.23658 15.2902 1.97318 14.0268C0.709773 12.7634 0 11.0499 0 9.26316C0 7.47644 0.709773 5.76289 1.97318 4.49949C3.23658 3.23609 4.95012 2.52632 6.73684 2.52632C6.96018 2.52632 7.17438 2.61504 7.3323 2.77296C7.49023 2.93089 7.57895 3.14508 7.57895 3.36842V8.42105H12.6316C12.8549 8.42105 13.0691 8.50977 13.227 8.6677C13.385 8.82563 13.4737 9.03982 13.4737 9.26316C13.4717 11.0493 12.7613 12.7616 11.4983 14.0246C10.2353 15.2876 8.52295 15.998 6.73684 16ZM5.89474 4.28042C4.98045 4.43514 4.12637 4.83848 3.42604 5.44627C2.72571 6.05406 2.20615 6.84286 1.92426 7.72626C1.64237 8.60966 1.60903 9.55361 1.82788 10.4547C2.04673 11.3558 2.50933 12.1793 3.16502 12.835C3.82072 13.4907 4.64421 13.9533 5.5453 14.1721C6.44639 14.391 7.39034 14.3576 8.27374 14.0757C9.15714 13.7938 9.94594 13.2743 10.5537 12.574C11.1615 11.8736 11.5649 11.0196 11.7196 10.1053H6.73684C6.5135 10.1053 6.29931 10.0165 6.14138 9.85862C5.98346 9.70069 5.89474 9.4865 5.89474 9.26316V4.28042Z" fill={color || '#003B54'}/>
      <path d="M15.1579 7.57895H9.26316C9.03982 7.57895 8.82563 7.49023 8.6677 7.3323C8.50977 7.17438 8.42105 6.96018 8.42105 6.73684V0.842105C8.42105 0.618765 8.50977 0.404572 8.6677 0.246647C8.82563 0.0887215 9.03982 0 9.26316 0C11.0493 0.00200599 12.7616 0.712422 14.0246 1.97539C15.2876 3.23836 15.998 4.95074 16 6.73684C16 6.96018 15.9113 7.17438 15.7534 7.3323C15.5954 7.49023 15.3812 7.57895 15.1579 7.57895ZM10.1053 5.89474H14.2459C14.0691 4.86089 13.5759 3.90737 12.8343 3.16572C12.0926 2.42407 11.1391 1.93085 10.1053 1.75411V5.89474Z" fill={color || '#003B54'} />
    </svg>
  );
}
