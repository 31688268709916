import React from 'react';
import { Link, defer } from 'react-router-dom';
import Jobs from 'Pages/Dashboard/components/Jobs';
import api from 'Utils/api';
import { JobCard } from 'Components/JobCard';
import TimesheetsTable from 'Pages/TimesheetsPage/Timesheets.table';
import JobDetailsTab from 'Components/JobCard/JobDetails.tab';
import Applicants from 'Components/JobCard/Applicants.jobs';
import { Box, Stack } from '@mui/material';
import TaskWrapperInJob from 'Components/JobCard/TaskWrapperInJob';
import ClosedTask from 'Components/JobCard/ClosedTask';
import RequireAuth from './RequireAuth';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';

export const jobsRoutes = [
  {
    path: 'jobs',
    handle: {
      crumb: () => <Link to='/dashboard/jobs'>Jobs</Link>,
    },
    element: (
      <RequireAuth>
        <Jobs />
      </RequireAuth>
    ),
  },
  {
    id: 'jobDetail',
    path: 'jobs/:jobId',
    handle: {
      crumb: (res) => {
        return <Link to={`/dashboard/jobs`}>Jobs</Link>
      },
    },
    loader: ({ params }) => api.get(`/jobnproject/getjobbyid/${params.jobId}`),
    element: (
      <RequireAuth>
        <Box sx={{ p: 2 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
            <Breadcrumbs />
          </Stack>
          <JobCard />
        </Box>
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        path: '',
        element: (
          <RequireAuth>
            <JobDetailsTab />
          </RequireAuth>
        ),
      },
      {
        index: true,
        path: 'applicants',
        element: (
          <RequireAuth>
            <Applicants />
          </RequireAuth>
        ),
      },
      {
        path: 'details',
        element: (
          <RequireAuth>
            <JobDetailsTab />
          </RequireAuth>
        ),
      },
      {
        path: 'tasklist',
        element: (
          <RequireAuth>
            <TaskWrapperInJob />
          </RequireAuth>
        ),
      },
      {
        path: 'closedtask',
        element: (
          <RequireAuth>
            <ClosedTask />
          </RequireAuth>
        ),
      },
      {
        path: 'timesheets',
        children: [
          {
            index: true,
            element: (
              <RequireAuth>
                <TimesheetsTable isJobDetailsComponent />
              </RequireAuth>
            ),
          },
        ],
      },
    ],
  },
];
