import React, { useState, createContext, useContext } from "react";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import { roles } from "../../Utils/constants";
export const apiPath = {
  inviteNewUser: "/invite/createinvite",
  getVendorForConsultant: "/user/get-vendor-for-consultant",
  getVendorRequests: "/user/invitesvcm",
  requestVendor: "/user/requestvcm",
  getSharedByMe:""
};

const nullFn = () => null;
const ConnectionContext = createContext({
  loading: false,
  vendorRequest: [],
  inviteVendor: nullFn,
  requestVendor: param => null,
  fetchVendor: nullFn,
  fetchvendorRequests: nullFn,
});

export const useConnectionProvider = () => useContext(ConnectionContext);
const ConnectionProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [vendorRequest, setVendorRequest] = useState(false);
  const userId = localStorage.getItem("userId");

  const requestVendor = async (user) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.requestVendor, user);
      if (res?.data?.success) {
        setLoading(false);
        return true;
      } else {
        // throw new Error("Error occurred while inviting new user");
        return false;
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      if (e?.response?.status >= 400 && e?.response?.status <= 500) {
        Swal.fire({
          title: "Error",
          text: e.response.data?.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
      return false;
    }
  };

  const inviteVendor = async (user) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.inviteNewUser, user);
      if (res?.data?.success) {
        setLoading(false);
        return true;
      } else {
        // throw new Error("Error occurred while inviting new user");
        return false;
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      if (e?.response?.status >= 400 && e?.response?.status <= 500) {
        Swal.fire({
          title: "Error",
          text: e.response.data?.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
      return false;
    }
  };

  const fetchVendor = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        apiPath.getVendorForConsultant + "?id=" + userId
      );
      if (res?.data?.success) {
        setLoading(false);
        return res.data.result;
      } else {
        throw new Error("Error occurred while fetching vendor");
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  const fetchvendorRequests = async () => {
    try {
      const res = await api.get(apiPath.getVendorRequests, {
        params: {
          for: roles.consultant,
          id: userId,
        },
      });
      if (res?.data?.success) {
        setLoading(false);
        setVendorRequest(res.data.result);
        return res.data.result;
      } else {
        throw new Error("Error occurred while fetching vendor Request");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    loading,
    vendorRequest,
    inviteVendor,
    requestVendor,
    fetchVendor,
    fetchvendorRequests,
  };

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionProvider;
