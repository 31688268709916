import api from 'Utils/api'

export const fetchProjectBasedOnClientsAndCurrency = async (client_id, currency, type = 'client_id') => {
    return api.get(`flexibleInvoice/projects?${type}=${client_id}&currency=${currency}`).then((data) => {
        return data;
    })
        .catch((err) => {
            return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
        });
}

export const FlexibleInvoiceCreateApi = async (body, query = '') => {
    return api
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/flexibleInvoice/create${query}`, body)
      .then((data) => {
        return {
            success: true,
            result: data.data,
        };
      })
      .catch((err) => {
        return { success: false, message: err?.response?.data?.error || 'Something went wrong' };
      });
  };