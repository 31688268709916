import {
  Grid, Paper, Stack, Tab, Tabs, Typography,
} from '@mui/material';
import React from 'react';
import { InvoicesTable } from './InvoicesTable';
import { CustomTabPanel, a11yProps } from '../../Components/Common/CustomTabs';
import CreateInvoiceButton from './CreateInvoice.button';
import { getUserRole } from 'Utils/helper';

export default function InvoicesTabs() {
  const [currentTab, setCurrentTab] = React.useState(0);
  const role = getUserRole();

  const handleChangeTab = (_, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Grid item xs={16}>
      <Paper elevation={1} sx={{ p: 3, borderRadius: '8px', width: '100%' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="cardHeader">Invoices</Typography>
          <CreateInvoiceButton />
        </Stack>
        {role === 'CLIENT' ? (
          <InvoicesTable outgoing={false} />
        ) : (
          <>
            <Tabs
              value={currentTab}
              aria-label="Invoices Tabs"
              onChange={handleChangeTab}
            >
              <Tab label="Invoice sent" {...a11yProps(0)} />
              <Tab label="Invoice received" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={currentTab} index={0}>
              <InvoicesTable outgoing isInvoiceSentTab={true} />
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={1}>
              <InvoicesTable outgoing={false} isInvoiceSentTab={false} />
            </CustomTabPanel>
          </>
        )}
      </Paper>
    </Grid>
  );
}
