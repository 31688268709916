import { Box, FormLabel, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form';

function HookFormTextInput({
  name,
  type,
  control,
  label,
  validation,
  placeholder,
  required,
  errors,
  disabled
  // handleChange,
}) {
  return (
    <Box display={"flex"} flexDirection={"column"} paddingBottom={"4px"}>
      {/* <FormLabel sx={{ paddingLeft: "1rem" }}>{label}</FormLabel> */}
      {control &&
        <Controller
          name={name}
          control={control}
          rules={validation || {}}
          render={({ field: { onChange, value, onBlur } }) => (
            <>
              <TextField
                disabled={disabled}
                required={required}
                variant='outlined'
                value={value}
                name={name}
                type={type}
                label={label}
                placeholder={placeholder}
                onChange={onChange}
                inputProps={{
                  
                }}
                hiddenLabel={true}
                InputLabelProps={{
                  // sx: {display: "none"}
                }}
              />
              {/* <TextField
                required
                id="outlined-required"
                type={type}
                label={label}
                name={name}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  // if(typeof handleChange === "function") {
                  //   handleChange(e.target.value);
                  // }
                }}
                sx={{ width: "100%" }}
              /> */}
            </>
          )}
        />}
      {errors && <Typography paddingBottom={"1rem"} color={"red"}>{errors?.message}</Typography>}
    </Box>
  )
}

export default HookFormTextInput;