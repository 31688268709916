export const resourcesTab = [
  {
    name: 'My resources',
  },
  {
    name: 'Shared with me',
  },
  {
    name: 'Shared by me',
  },
  {
    name: 'Past resource',
  },
  {
    name: 'Requests/Invites',
  },
];

export const resourcesSubTab = [
  'Disconnection Request',
  'Connection Request',
  'Invites'
]

export const createData = (Name, Client, Status) => ({
  Name,
  Client,
  Status,
});
