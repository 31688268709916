import React, { useEffect, useState } from 'react';
import ProfileCard from './ResourceCard';
import CustomInput from 'Components/Common/semantic_tags/Input';
import useDebounce from 'hooks/useDebounce';
import { fetchProfiles } from './marketplace.api';
import { useSelector } from 'react-redux';
import { selectUserRole } from 'Redux/Feature/user';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ResourceFilterHookForm from './ResourceFilter';
import TuneIcon from '@mui/icons-material/Tune';

const ResourceList = () => {
    const [searchedVal, setSearchedVal] = useState('')
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [profiles, setProfiles] = useState([])
    const { role } = useSelector(selectUserRole)
    const debounceSearch = useDebounce(searchedVal, 300)
    const [totalCount, setTotalCount] = useState(0)
    const [advanceFilter, setAdvanceFilter] = useState(false)

    const fetchProfilesHandler = async (query = '') => {
        const resp = await fetchProfiles(debounceSearch, role === 'VENDOR' ? 'CONSULTANT' : role === 'CLIENT' ? "VENDOR" : '', page, query) 
        if (resp.success) {
            setProfiles(resp.data.data)
            setTotalCount(resp.data.meta.totalItems)
        }
    }

    const totalPages = Math.ceil(totalCount / limit);

    useEffect(() => {
        fetchProfilesHandler()
    }, [page, debounceSearch])

    useEffect(() => {
        setPage(1)
    }, [debounceSearch])

    const handlePageClick = (pageNumber) => {
        setPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 3;
        const startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={`w-8 h-8 mx-1 rounded-full ${i === page ? 'bg-[#3985B6] text-white' : 'bg-gray-200 text-gray-700'}`}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };


    return (
        <div className="p-4 min-h-[60vh]">
            <div className='flex gap-4'>
                <CustomInput className='mb-4 w-full' parentClassName='w-full' value={searchedVal} onChange={(e) => setSearchedVal(e.target.value)} placeholder={role === 'VENDOR' ? 'Search Resources' : 'Search Vendors'} />
                {role === 'VENDOR' && <div onClick={() => setAdvanceFilter(!advanceFilter)} className='border rounded-lg p-2 inline-block flex justify-center items-center cursor-pointer h-[42px]'><TuneIcon /> Filters</div>}
            </div>
            {role === 'VENDOR' && advanceFilter && <ResourceFilterHookForm fetchProfilesHandler={fetchProfilesHandler} />}
            {profiles.map((profile, index) => (
                <ProfileCard key={index} {...profile} mainRole={role} />
            ))}
                        {profiles && profiles.length > 0 && <div className="flex justify-center mt-20 items-center">
                <button
                    onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                    className="p-2"
                >
                    <ArrowCircleLeftIcon className={`text-2xl ${page === 1 ? 'text-gray-400' : 'text-[#3985B6]'}`} />
                </button>
                {renderPageNumbers()}
                <button
                    onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={page === totalPages}
                    className="p-2"
                >
                    <ArrowCircleRightIcon className={`text-2xl ${page === totalPages ? 'text-gray-400' : 'text-[#3985B6]'}`} />
                </button>
            </div>}
        </div>
    );
};

export default ResourceList;
