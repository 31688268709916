import { ChevronRight } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { useMatches } from 'react-router-dom';

export default function Breadcrumbs() {
  let matches = useMatches();
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data)).filter((crumb) => !!crumb);

  return !!crumbs?.length && (
    <Stack direction="row" sx={{ typography: 'body2Medium', color: '#4B5563' }}>
      {crumbs.map((crumb, index) => (
        <Stack
          key={index}
          direction="row"
          sx={{
            'a:hover': {
              cursor: 'pointer',
              color: '#182743',
              textDecoration: 'underline',
            },
          }}
        >
          {crumb}
          {index !== crumbs?.length - 1 && <ChevronRight sx={{ mx: 1 }} />}
        </Stack>
      ))}
    </Stack>
  );
}
