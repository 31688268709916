import { Avatar, Box, Paper, TextField, Typography } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal';
import ShareableInviteField from 'Components/Shared/ShareableInviteField';
import { CheckEmailExist } from 'Pages/Auth/Helper';
import { getPermissions } from 'Utils/helper';
import { isValidEmail } from 'Utils/validation';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { CustomTable } from "../../../../Components";
import { useConnectionProvider } from '../../../../Providers/Vendor/Connections';
import { stringAvatar } from '../../../../Utils/avatar';
import { roles, userPermissions } from '../../../../Utils/constants';
import Button from 'Components/Common/semantic_tags/Button';

export default function CurrentClients() {
  const [rowCount, setRowCount] = React.useState(0);
  const [emailUsed, setEmailUsed] = useState(false);
  const [filledEmail, setFilledEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const debouncedValue = useDebounce(filledEmail, 500)
  const [isUserEmail, setIsUserEmail] = useState({
    email: "",
    isLoginedEmail: false
  });
  const [reloadAPI, setReloadAPI] = useState(false);
  const [requestUser, setRequestUser] = useState({
    email: '',
  });
  const [inviteUser, setInviteUser] = useState({
    invitedBy: localStorage.getItem('userId'),
    email: '',
    role: roles.vendor,
  });
  const [sorting, setSorting] = React.useState([
    { id: "companyName", desc: true },
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  const getAddNewClientQuery = searchParams.get('add-new-clients');

  const {
    clients,
    loading,
    inviteNewUser,
    fetchClientsList,
    acceptRequestFromClient,
    declineRequestFromClient,
    sendRequestToClient
  } = useConnectionProvider();
  const permissions = getPermissions();
  useEffect(() => {
    if (
      permissions?.includes('admin-vendor')
      || permissions?.includes(userPermissions.clientConnection.view)
    ) {
      fetchClientsList(pagination, setRowCount);
    }
  }, [pagination, reloadAPI]);

  const clientsColumnsDefs = {
    name: {
      accessorKey: "companyName",
      header: "Clients",
      Cell: ({ row, cell }) => {
        return (
          <Link target="_blank" to={`/${row?.original?.username || row?.original?.id}`}>
            <span className="flex flex-row items-center">
              <Avatar
                {...stringAvatar(
                  row?.original?.companyName
                    ? row?.original?.companyName
                    : row?.original?.firstName
                      ? `${row?.original?.firstName} ${row?.original?.lastName}`
                      : row?.original?.email,
                )}
                className="mr-2.5"
                size="large"
                src={row?.original?.profilePhoto}
              />
              {row?.original?.companyName
                ? row?.original?.companyName
                : row?.original?.firstName
                  ? `${row?.original?.firstName} ${row?.original?.lastName}`
                  : row?.original?.email}
            </span>
          </Link>
        )
      }
    },
    status: {
      accessorKey: "status",
      header: "Status",
      Cell: ({ row, cell }) => {
        return (
          <>
            {row?.original?.status === 'Requested' && row?.original?.requestFrom === 'CLIENT' ? (
              permissions?.includes(userPermissions.clientConnection.add) ? (
                <div className="flex gap-x-4">
                  <button
                    className="bg-green-400 text-white px-2 py-1 rounded-md"
                    onClick={() => (acceptRequestFromClient(row?.original?.id), setReloadAPI(!reloadAPI))}
                  >
                    Accept
                  </button>
                  <button
                    className="bg-red-400 text-white px-2 py-1 rounded-md"
                    onClick={() => (declineRequestFromClient(row?.original?.id), setReloadAPI(!reloadAPI))}
                  >
                    Reject
                  </button>
                </div>
              ) : null
            ) : (
              row?.original?.status
            )}
          </>
        )
      }
    }
  }

  const handleSubmit = (e) => {
    const validEmail = isValidEmail(filledEmail);
    if (validEmail && !emailUsed) {
      inviteUser['emails'] = [inviteUser.email]
      delete inviteUser.email
      inviteNewUser({ ...inviteUser, role: 'CLIENT' }).then(() => {
        setInviteUser({ ...inviteUser, email: '' });
        setOpen(false);
      });
    } else if (validEmail) {
      if (!requestUser?.email) {
        requestUser.email = filledEmail;
      }
    } if (emailUsed) {
      sendRequestToClient(requestUser).then(() => {
        setRequestUser({ email: '' });
        setOpen(false);
      })
    }
  }

  const checkEmailUsed = (email) => {
    CheckEmailExist({ email }).then((res) => {
      setEmailUsed(res?.data?.status);
      handleRequestChange(res?.data?.username, 'username');
      handleRequestChange(email, 'email');
    });
    if (email === isUserEmail.email) {
      setIsUserEmail((prev) => ({
        ...prev,
        isLoginedEmail: true
      }))
    } else if (email !== isUserEmail.email) {
      setIsUserEmail((prev) => ({
        ...prev,
        isLoginedEmail: false
      }))
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleRequestChange = (e, name) => {
    setRequestUser({
      ...requestUser,
      id: localStorage.getItem('userId'),
      requestFrom: 'VENDOR',
      [name]: e?.target?.value || e,
    });
  };

  const handleInviteChange = (e, name) => {
    setInviteUser({
      ...inviteUser,
      id: localStorage.getItem('userId'),
      [name]: e?.target?.value || e,
    });
  };

  useEffect(() => {
    checkEmailUsed(debouncedValue);
    if (emailUsed) {
      handleRequestChange(debouncedValue, 'email');
    } else {
      handleInviteChange(debouncedValue, 'email');
    }
  }, [debouncedValue])

  useEffect(() => {
    if(getAddNewClientQuery === 'true') {
      setOpen(true);
    }
  },[getAddNewClientQuery])

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
        {/* <Grid item xs={16}> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            variant="subtitle1Bold"
            color="#182743"
            component="h3"
            sx={{ mb: 2.5 }}
          >
            Clients
          </Typography>
          {/* {permissions?.includes(`admin-client`) ? ( */}
          <Button
            onClick={showModal}
            title='Add Client'
          />
          {/* ) : null} */}
        </Box>
        <CustomTable
          state={{
            isLoading: loading,
            sorting,
            pagination,
          }}
          data={clients}
          columns={[
            clientsColumnsDefs.name,
            clientsColumnsDefs.status
          ]}
          manualPagination
          paginationDisplayMode="pages"
          rowCount={rowCount}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
        />
        {/* </Grid> */}
      </Paper>
      <MuiModal
        open={open}
        onCancel={() => setOpen(false)}
        title="Add Client"
      >
        <Box paddingBottom={'20px'}>
          <div className="flex flex-col">
            <TextField
              label="Email"
              placeholder="Enter Email"
              onChange={(e) => { setFilledEmail(e.target.value) }}
              required
            />
          </div>
          <ShareableInviteField type={roles.client} />
          <div className='flex justify-end'>
            <Button
              className='w-full mt-2'
              disabled={!requestUser?.email}
              onClick={handleSubmit} title='Invite Client' />
          </div>
        </Box>
      </MuiModal>
    </Box>
  );
}
