import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar, Box, Container, Divider, Drawer, IconButton, List, ListItem,
  ListItemButton, ListItemText, MenuItem, Stack, Toolbar,
  Typography,
  Menu as MenuComponent,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import { removeDataFromLocalStorage } from 'Utils/helper';
import { ProLegionBlue } from '../Assets/SvgIcons';
import { FullWidthWrapper } from './Common/FullWidthWrapper';
import { selectIsUserLoggedIn, selectUserDetails, setLogout } from '../Redux/Feature/user';
import Button from './Common/semantic_tags/Button';
import UserMenu from './UserMenu';

export default function Navbar() {
  const userDetails = useSelector(selectUserDetails);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [hoverMarketPlaceMenu, setHoverMarketPlaceMenu] = React.useState(false);
  const [hoverSupportMenu, setHoverSupportMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = React.useState(null);
  const [marketPlaceAnchorEl, setmarketPlaceAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openCategory = Boolean(categoryAnchorEl);
  const openMarketPlace = Boolean(marketPlaceAnchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setCategoryAnchorEl(null);
    setmarketPlaceAnchorEl(null);
  };

  const handleOpenHeaderMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleOpenHeaderMenuCategory = (event) => {
  //   setCategoryAnchorEl(event.currentTarget);
  //   setTimeout(()=> { return setCategoryAnchorEl(null)}, 5000);
  // };

  const handleOpenHeaderMenuMarketplace = (event) => {
    setmarketPlaceAnchorEl(event.currentTarget);
    setTimeout(()=> { return setmarketPlaceAnchorEl(null)}, 5000);
  };

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navItems = [
    // {
    //   name: 'Marketplace',
    //   href: '/marketplace',
    // },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Blog', href: 'https://blog.prolegion.com' },
    { name: 'About', href: '/aboutus' },
  ];

  const mobileNavItems = [
    { name: 'Enterprises', href: '/enterprises'},
    { name: 'Vendors', href: '/vendor' },
    { name: 'Consultants', href: '/consultant' },
    { name: 'Contact us', href: '/contact' },
  ]

  const supportMenu = {
    name: 'Support',
    links: [
      {
        name: 'Contact us',
        href: '/contact',
      },
      {
        name: 'FAQ\'s',
        href: '/faq',
      },
    ],
  };

  const marketPlace = {
    name: 'Marketplace',
    links: [
      ...(
        (userDetails?.role !== 'CONSULTANT')
          ? [{
            name: 'Post a Job',
            href: '/signup?redirecTo=/dashboard/jobs?type=create',
          }]
          : []
      ),
      {
        name: 'Find a Job',
        href: '/marketplace',
      },
      {
        name: 'Find a Resource',
        href: '/signup?marketPlacetype=client',
      },
    ],
  };

  const businessMenu = {
    name: 'Category',
    links: [
      {
        name: 'Enterprises',
        href: '/enterprises',
      },
      {
        name: 'Vendors',
        href: '/vendor',
      },
      {
        name: 'Consultants',
        href: '/consultant',
      },
    ],
  };

  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const dispatch = useDispatch();

  const container = window !== undefined ? () => window.document.body : undefined;

  const userActions = !isLoggedIn ? (
    <>
      <Button
        onClick={() => navigate('/signin')}
        title='Login'
        className='px-4'
      />
      <Button
        onClick={() => navigate('/signup')}
        title='Sign up'
        className='px-4'
      />
    </>
  ) : (
    <>
      <Button
        onClick={() => navigate('/dashboard')}
        title='Dashboard'
      />
      <UserMenu />
      {/* <Button
        onClick={() => {
          navigate('/');
          dispatch(setLogout());
          removeDataFromLocalStorage();
        }}
        title='Logout'
      /> */}
    </>
  );

  const renderMobileNavItems = (item, index) => (
    <ListItem key={item.name + index} disablePadding>
      <ListItemButton
        sx={{ textAlign: 'left', p: 2 }}
        onClick={() => navigate(item.href)}
      >
        <ListItemText primary={item.name} sx={{ mb: 0 }} />
      </ListItemButton>
    </ListItem>
  )

  return (
    <>
      <AppBar
        position='sticky'
        component='nav'
        sx={{
          borderRadius: 0,
          backgroundColor: 'common.white',
          boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Container
          maxWidth='xl'
          sx={{ pl: '0 !important', pr: '0 !important' }}
        >
          <FullWidthWrapper>
            <Toolbar
              disableGutters
              sx={{
                justifyContent: 'space-between',
                flex: 1,
                py: 2,
              }}
            >
              <Link to='/' style={{ height: '40px' }}>
                <ProLegionBlue />
                {/* <img
                  src={prolegionlogo}
                  style={{ height: '40px' }}
                  alt='Logo'
                /> */}
              </Link>
              <IconButton
                color='inherit'
                aria-label='open navigation drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{ display: { md: 'none' } }}
              >
                <Menu sx={{ color: 'text.primary' }} />
              </IconButton>
              <Stack
                direction='row'
                gap={4}
                alignItems='center'
                justifyContent='center'
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex',
                  },
                  color: 'grey.600',
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '16px',
                  paddingLeft: '1rem',
                  'a:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <div className='relative'>
                <Typography
                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                  variant='defaultFont'
                  onMouseEnter={()=> {setHover(true)}}
                  onMouseLeave={()=> {setHover(false)}}
                >
                  Solutions
                </Typography>
                <div className={`absolute top-5 bg-[#fff] p-2 rounded-lg shadow shadow-[#00000025] ${hover ? "block" : "hidden"}`}
                  onMouseEnter={()=> {setHover(true)}}
                  onMouseLeave={()=> {setHover(false)}}
                >
                  {businessMenu.links.map((item) => (
                    <MenuItem>
                      <Link onClick={handleClose} key={item.name} to={item.href}>{item.name}</Link>
                    </MenuItem>
                  ))}
                </div>
                </div>
                <div className='relative'>
                <Typography
                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                  variant='defaultFont'
                  onMouseEnter={()=> {setHoverMarketPlaceMenu(true)}}
                  onMouseLeave={()=> {setHoverMarketPlaceMenu(false)}}
                >
                  Marketplace
                </Typography>
                <div className={`absolute top-5 bg-[#fff] p-2 rounded-lg shadow shadow-[#00000025] ${hoverMarketPlaceMenu ? "block" : "hidden"}`}
                  onMouseEnter={()=> {setHoverMarketPlaceMenu(true)}}
                  onMouseLeave={()=> {setHoverMarketPlaceMenu(false)}}
                >
                  {marketPlace.links.map((item) => (
                    <MenuItem>
                      <Link onClick={handleClose} key={item.name} to={item.href}>{item.name}</Link>
                    </MenuItem>
                  ))}
                </div>
                </div>
                {navItems.map(
                  (item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      style={{ color: window.location.pathname.includes(item.href) ? '#0B85BA' : '' }}
                    >
                      {item.name}
                    </Link>
                  ),
                )}
                <div className='relative'>
                <Typography
                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                  variant='defaultFont'
                  onMouseEnter={()=> {setHoverSupportMenu(true)}}
                  onMouseLeave={()=> {setHoverSupportMenu(false)}}
                >
                  Support
                </Typography>
                <div className={`absolute top-5 bg-[#fff] p-2 rounded-lg shadow shadow-[#00000025] ${hoverSupportMenu ? "block" : "hidden"}`}
                  onMouseEnter={()=> {setHoverSupportMenu(true)}}
                  onMouseLeave={()=> {setHoverSupportMenu(false)}}
                >
                  {supportMenu.links.map((item) => (
                    <MenuItem>
                      <Link onClick={handleClose} key={item.name} to={item.href}>{item.name}</Link>
                    </MenuItem>
                  ))}
                </div>
                </div>
              </Stack>
              <Stack
                direction='row'
                gap={1}
                alignItems='center'
                justifyContent='center'
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {userActions}
              </Stack>
            </Toolbar>
          </FullWidthWrapper>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <Box onClick={handleDrawerToggle}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ p: 2 }}
            >
              <Link to='/' style={{ width: '150px' }}>
                <ProLegionBlue />
                {/* <img src={prolegionlogo} alt='Logo' width='150px' /> */}
              </Link>
              <Menu tabIndex={0} />
            </Stack>
            <Divider sx={{ backgroundColor: 'primary.dark' }} />
            <Stack direction='row' justifyContent='flex-start' py={2} gap={2}>
              {userActions}
            </Stack>
            <Divider sx={{ backgroundColor: 'primary.dark' }} />

            <List sx={{ p: 0 }}>
              {mobileNavItems.map(renderMobileNavItems)}
              {navItems.map(
                (item) => !item.hideOnMobile && (
                <ListItem key={item.name} disablePadding>
                  <ListItemButton
                    sx={{ textAlign: 'left', p: 2 }}
                    onClick={() => navigate(item.href)}
                  >
                    <ListItemText primary={item.name} sx={{ mb: 0 }} />
                  </ListItemButton>
                </ListItem>
                ),
              )}
            </List>
          </Box>
        </Drawer>
      </nav>
    </>
  );
}
