import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCommonUserProvider } from '../../../../Providers/Common/User';
import { emailRegex } from '../../../../Utils/validation';
import { useCommonJobProvider } from '../../../../Providers/Common/Jobs';
import { subRolesArr } from './utils';
import MuiModal from 'Components/Common/MuiModal';

export default function ClientModifyUserModal({ open, userId, onClose }) {
  const { fetchAllProjects } = useCommonJobProvider();
  const {
    addChildUser, updateChildUser, getUserDetails, loading,
  } = useCommonUserProvider();
  const {
    control, getValues, setValue, reset, watch, formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      'add-timesheet': false,
      'plans-billings': false,
      'view-timesheet': false,
      'add-vendor-connection': false,
      'view-vendor-connection': false,
      'pay-invoice': false,
      'view-invoice': false,
      'add-task': false,
      'view-task': false,
      'send-document': false,
      'save-document': false,
      'view-document': false,
      'add-job': false,
      'view-job': false,
      'edit-job': false,
      'edit-account': false,
      'view-account': false,
      'create-project': false
    },
  });

  const isAdministrator = watch([
    'add-timesheet',
    'view-timesheet',
    'add-vendor-connection',
    'view-vendor-connection',
    'pay-invoice',
    'plans-billings',
    'view-invoice',
    'add-task',
    'view-task',
    'send-document',
    'save-document',
    'view-document',
    'add-job',
    'view-job',
    'edit-job',
    'edit-account',
    'view-account',
    'create-project'
  ]).every((val) => val === true);
  const handleTogglePermissions = () => {
    reset({
      "add-timesheet": !isAdministrator,
      "view-timesheet": !isAdministrator,
      "add-vendor-connection": !isAdministrator,
      "view-vendor-connection": !isAdministrator,
      "pay-invoice": !isAdministrator,
      "view-invoice": !isAdministrator,
      "add-task": !isAdministrator,
      "plans-billings": !isAdministrator,
      "view-task": !isAdministrator,
      "send-document": !isAdministrator,
      "save-document": !isAdministrator,
      "view-document": !isAdministrator,
      "add-job": !isAdministrator,
      "view-job": !isAdministrator,
      "edit-job": !isAdministrator,
      "edit-account": !isAdministrator,
      "view-account": !isAdministrator,
      "create-project": !isAdministrator,
    });
  };

  const subRolesSelectedValues = useMemo(()=>{
    return subRolesArr.filter(subRole => getValues(subRole));
  },[getValues()])

  const isSubroleEmpty = useMemo(()=>{
    return subRolesSelectedValues.length === 0 
  },[subRolesSelectedValues]);

  const handleAddUser = () => {
    const { firstName, lastName, email } = getValues();
    addChildUser({
      firstName,
      lastName,
      email,
      subroles: subRolesSelectedValues
    })
      .then((res) => {
        if (res) {
          onClose(true);
        }
      });
  };

  // TODO: implement edit user
  const handleEditUser = (userId) => {
    updateChildUser(userId, {
      newRoles: subRolesSelectedValues
    }).then((res) => {
      if (res) {
        onClose(true);
      }
    });
  };

  React.useEffect(() => {
    if (open && userId) {
      (async () => {
        await getUserDetails(userId).then((userDetails) => {
          if (!userDetails) {
            onClose();
            return;
          }
          const fetchUserRoleObject = [];
          userDetails?.keycloakUserRoleDetails?.forEach((item)=>{
            item.child.forEach((itemChild)=>{
              fetchUserRoleObject.push({
                name: itemChild.name,
                isSelected: itemChild.isSelected
              })
            })
          });
          function getIsSelectedByName(name) {
            const item = fetchUserRoleObject.find(item => item.name === name);
            return item ? item.isSelected : null;
          }
          reset({
            firstName: userDetails?.keycloakUserDetails?.firstName,
            lastName: userDetails?.keycloakUserDetails?.lastName,
            email: userDetails?.keycloakUserDetails?.email,

            // job permissions
            'add-job': getIsSelectedByName('add-job'),
            'view-job': getIsSelectedByName('view-job'),
            'edit-job': getIsSelectedByName('edit-job'),

            // document permissions
            'save-document': getIsSelectedByName('save-document'),
            'view-document': getIsSelectedByName('view-document'),
            'add-task': getIsSelectedByName('add-task'),
            'view-task': getIsSelectedByName('view-task'),

            // invoice permissions
            // 'add-invoice': getIsSelectedByName('add-invoice'),
            // 'view-invoice': getIsSelectedByName('view-invoice'),
            'pay-invoice': getIsSelectedByName('pay-invoice'),
            'create-project': getIsSelectedByName('create-project'),

            // vendor permissions
            'add-vendor-connection': getIsSelectedByName('add-vendor-connection'),
            'view-vendor-connection': getIsSelectedByName('view-vendor-connection'),
            'assign-vendors': getIsSelectedByName('assign-vendors'),
            'plans-billings': getIsSelectedByName('plans-billings'),

            // consultant permissions
            'add-timesheet': getIsSelectedByName('add-timesheet'),
            'view-timesheet': getIsSelectedByName('view-timesheet'),
            'assign-consultants': getIsSelectedByName('assign-consultants'),


            // account permissions
            'view-account': getIsSelectedByName('view-account'),
            'edit-account': getIsSelectedByName('edit-account'),
            // 'edit-settings': getIsSelectedByName('edit-settings'),
          });
        });
      })();
    } if(!open) {
      reset({
        firstName: '',
        lastName: '',
        email: '',
        'add-timesheet': false,
        'view-timesheet': false,
        'add-vendor-connection': false,
        'view-vendor-connection': false,
        'pay-invoice': false,
        'view-invoice': false,
        'add-task': false,
        'view-task': false,
        'send-document': false,
        'plans-billings': false,
        'save-document': false,
        'view-document': false,
        'add-job': false,
        'view-job': false,
        'edit-job': false,
        'edit-account': false,
        'view-account': false,
        'create-project': false
      },);
    }
  }, [open, userId]);

  React.useEffect(() => {
    fetchAllProjects();
  }, []);

  const isUpdateUser = useMemo(()=> {
    return userId !== null;
  },[userId]);

  const isAdduserForm = useMemo(()=> {
    return isUpdateUser ? false : !isValid
  },[isUpdateUser, isValid]);

  return (
    <MuiModal open={open} onCancel={onClose}>
      <Paper
        sx={{
          width: '980px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 4,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="subtitle1Bold" color="#182743">
            {userId === null ? 'Add New' : 'Edit'}
            {' '}
            User
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth required error={!!errors.firstName} variant="standard" disabled={isUpdateUser}>
              <InputLabel
                htmlFor="firstName"
              >
                First Name
              </InputLabel>
              <InputBase
                id="firstName"
                {...control.register('firstName', { required: true })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required error={!!errors.lastName} variant="standard" disabled={isUpdateUser}>
              <InputLabel
                htmlFor="lastName"
              >
                Last Name
              </InputLabel>
              <InputBase
                id="lastName"
                {...control.register('lastName', { required: true })}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <FormControl fullWidth required error={!!errors.email} variant="standard" disabled={isUpdateUser}>
              <InputLabel
                htmlFor="email"
              >
                Email
              </InputLabel>
              <InputBase
                id="email"
                type="email"
                {...control.register('email', { required: true, pattern: { value: emailRegex, message: 'Please enter a valid email address' } })}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Stack direction="row" my={2}>
          <FormControlLabel
            control={<Checkbox checked={isAdministrator} onChange={handleTogglePermissions} />}
            label="Administrator"
          />
        </Stack>
        <Grid
          container
          sx={{
            typography: 'body2Bold',
            color: '#182743',
            '.MuiCheckbox-root': { padding: 0 },
            '.MuiFormControlLabel-root': { mx: 0 },
          }}
          spacing={2.5}
        >
          <Grid item xs={4} />
          <Grid item xs={4}>Add Permission</Grid>
          <Grid item xs={4}>View Permission</Grid>
          <Grid item xs={4}>Edit Permission</Grid>
          <Grid item xs={4}>Timesheet</Grid>
          <Grid item xs={4}>
            <Controller
              name="add-timesheet"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can Add Timesheet"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="view-timesheet"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can View Timesheet"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={4}>Vendors</Grid>
          <Grid item xs={4}>
            <Controller
              name="add-vendor-connection"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can Add Vendors"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="view-vendor-connection"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can View Vendors"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={4}>Task</Grid>
          <Grid item xs={4}>
            <Controller
              name="add-task"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can Create Tasks"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="view-task"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can View Tasks"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={4}>Documents</Grid>
          <Grid item xs={4}>
            <Controller
              name="save-document"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can Add Invoices"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="view-document"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can View Documents"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={4}>Jobs</Grid>
          <Grid item xs={4}>
            <Controller
              name="add-job"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can Create Jobs"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="view-job"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can View Jobs"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="edit-job"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can Edit Jobs"
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>Account</Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Controller
              name="view-account"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can View Account"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="edit-account"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  title="Can Edit Account"
                />
              )}
            />
          </Grid>

          <Grid item xs={16} sx={{ borderBottom: '1px solid #4C4E641F' }} />

          <Grid item xs={4}>Other Permissions</Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={(
                <Controller
                  name="plans-billings"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
)}
              label="Plans and billing"
              sx={{ '& .MuiFormControlLabel-label': { typography: 'body2Normal', color: '#6B7280' } }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={(
                <Controller
                  name="create-project"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
)}
              label="Create Project"
              sx={{ '& .MuiFormControlLabel-label': { typography: 'body2Normal', color: '#6B7280' } }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={(
                <Controller
                  name="pay-invoice"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
)}
              label="Pay Invoice"
              sx={{ '& .MuiFormControlLabel-label': { typography: 'body2Normal', color: '#6B7280' } }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              disabled={loading}
              onClick={onClose}
              sx={{
                typography: 'captionMedium',
                borderColor: '#0B85BA',
                py: 1,
                px: 1.5,
                borderRadius: '8px',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={userId === null ? handleAddUser : ()=> handleEditUser(userId)}
              variant="contained"
              disabled={loading || isAdduserForm || isSubroleEmpty}
              sx={{
                typography: 'captionMedium',
                color: '#F9FAFB',
                backgroundColor: '#0B85BA',
                py: 1,
                px: 1.5,
                borderRadius: '8px',
              }}
            >
              {userId === null ? 'Add User' : 'Save Changes'}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </MuiModal>
  );
}
