import React from 'react';
import TaskWrapper from 'Pages/Task';
import { Link } from 'react-router-dom';
import RequireAuth from './RequireAuth';

export const taskRoutes = [
  {
    path: 'task-board',
    index: true,
    handle: {
      crumb: () => <Link to='/dashboard/task-board'>Dashboard</Link>,
    },
    element: (
      <RequireAuth>
        <TaskWrapper />
      </RequireAuth>
    ),
  },
];
