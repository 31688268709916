import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material"
import MuiModal from "Components/Common/MuiModal"
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import EmailIcon from '@mui/icons-material/Email';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useJobProvider } from "Providers/Vendor/Jobs";
import { useNavigate } from "react-router-dom";
import { useAlertProvider } from "Providers/util/Alert";

const ApplicantViewModal = ({
    open,
    details,
    jobDetails,
    updateTaskHandler,
    submitHandler,
    close
}) => {
    const { error } = useAlertProvider()
    const navigate = useNavigate()
    const { assignConsultantToJob, assignSubVendorToJob } = useJobProvider()
    const fileName = details?.consultant?.resume?.split('/')[details?.consultant?.resume?.split('/').length - 1]?.split('.')[0]
    const hireCandidate = async () => {
        await updateTaskHandler(details.id, 'hired')
        await assignSubVendorToJob({ job_id: jobDetails?.primary_job_id, vendor_id: details?.vendor_id, type: 'vendor' }, false)
        await assignConsultantToJob({ job_id: jobDetails?.primary_job_id, consultant_id: details?.applicant_id }, false)
        return navigate(`/dashboard/jobs/${jobDetails?.primary_job_id}`)
    }

    const submitCandidate = async () => {
        if (!jobDetails?.isFeedbackGiven) return error('Please provide feedback interview feedback')
        await submitHandler(details)
    }

    return <MuiModal open={open} onCancel={close} className="max-w-4xl">
        <Stack flexDirection={'row'} alignItems={'flex-start'} marginBottom={'40px'} justifyContent={'space-between'} marginTop={'40px'}>
            <Box sx={{ width: '60%' }}>
                <Stack flexDirection={'row'} alignItems={'center'} marginBottom={'40px'}>
                    <Avatar
                        sx={{ width: "70px", height: "70px", mr: 2 }}
                        src={details?.consultant?.profile_image}
                    />
                    <Box>
                        <Box
                            sx={{ inlineSize: "100%", overflowWrap: "break-word !important" }}
                            className="!text-2xl font-bold"
                        >
                            {details?.consultant?.first_name}{" "}{details?.consultant?.last_name}
                        </Box>
                        <Typography className="!font-bold !text-md">{details?.consultant?.company}</Typography>
                    </Box>
                </Stack>
                <Typography className="!font-bold !text-md mt-4">BIOGRAPHY</Typography>
                <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    gap={"2px"}
                    alignItems={"center"}
                    marginTop={"10px"}
                    marginBottom={"10px"}
                >
                    <Box
                        className="text-xs"
                        sx={{ 
                            inlineSize: "100%",
                            color: '#6B7280',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                details?.consultant?.about_me
                        }}
                    ></Box>
                </Stack>
            </Box>
            <Box sx={{ width: '30%' }}>
                {details?.consultant?.resume && <Box sx={{ border: '1.4px solid #CEE0F5B2', borderRadius: '8px', padding: '10px', marginBottom: '20px' }}>
                    <Typography className="!font-bold !text-sm">Download My Resume</Typography>
                    <Stack flexDirection={'row'} marginTop={'10px'} justifyContent={'space-between'} alignItems={'center'}>
                        <Stack flexDirection={'row'} marginTop={'10px'} gap='10px' alignItems={'center'}>
                            <DescriptionIcon sx={{ fontSize: '50px', color: '#E4E5E8' }} />
                            <Box>
                                <Typography className="!text-xs">{details?.consultant?.first_name || ''} Resume</Typography>
                                <Typography className="!font-bold !text-lg">PDF</Typography>
                            </Box>
                        </Stack>
                        <Box sx={{ background: '#E7F0FA', borderRadius: '8px', padding: '6px' }}>
                            <FileDownloadIcon onClick={() => window.open(details?.consultant?.resume, '__blank')} className="cursor-pointer" sx={{ color: '#006A98' }} />
                        </Box>
                    </Stack>
                </Box>}
                <Box sx={{ border: '1.4px solid #CEE0F5B2', borderRadius: '8px', padding: '10px' }}>
                    <Typography className="!font-bold !text-sm">Contact Information</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack flexDirection={'row'} gap={'10px'} marginTop={'10px'} alignItems={'center'}>
                        <WifiCalling3Icon sx={{ color: '#006A98', fontSize: '40px' }} />
                        <Box>
                            <Typography className="!text-xs">Phone</Typography>
                            <Typography className="!font-medium !text-sm mt-2">{details?.consultant?.phone}</Typography>
                        </Box>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    <Stack flexDirection={'row'} gap={'10px'} marginTop={'10px'} alignItems={'center'}>
                        <EmailIcon  sx={{ color: '#006A98', fontSize: '40px' }} />
                        <Box>
                            <Typography className="!text-xs">Email</Typography>
                            <Typography className="!font-medium !text-sm mt-2">{details?.consultant?.email}</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box>
                    {jobDetails?.status !== 'HIRED' && <Button onClick={jobDetails?.parent_job_id ? submitCandidate : hireCandidate} sx={{ background: '#006A98', width: '100%', marginTop: '30px', color: 'white', '&:hover': { background: '#006A98' } }}>
                        <ArrowCircleDownIcon sx={{ transform: 'rotate(-90deg)' }} /> 
                        {jobDetails?.parent_job_id ? 'Submit Candidate' : 'Hire Candidate'}
                    </Button>}
                </Box>
            </Box>
        </Stack>
    </MuiModal>
}

export default ApplicantViewModal