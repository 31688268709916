import { Box, Typography } from "@mui/material";
import MuiModal from "Components/Common/MuiModal";
import { useEffect, useMemo, useState } from "react";
import { getSharedDocuments } from "./document.api";
import { CustomTable } from "Components";

const ViewDetailModal = ({ open, onClose, details }) => {
    const [loading, setLoading] = useState(false);
  const [documentData, setDocumentData] = useState([]);

  const fetchHandler = async () => {
    setLoading(true)
    const resp = await getSharedDocuments(details?.document_id);
    if (resp && resp.success) {
        setDocumentData(resp.data)
    }
    setLoading(false)
  };

  const mappedDocument = useMemo(()=>{
    return documentData.map((item)=> {
      if(item.receiver_user === null) {
        return {
          ...item,
          receiver_user: item.email
        }
      }
      else {
        return item;
      }
    })
  },[documentData]);

  useEffect(() => {
    if (open && details?.document_id) {
      fetchHandler();
    }
  }, [open]);

  const columnsDefs = {
    name: {
      accessorKey: "receiver_user",
      header: "Sent to",
      Cell: ({ cell }) => {
        const isTypeObj = typeof(cell.getValue()) === "object";
        return(
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {isTypeObj ? `${cell.getValue()?.firstName} ${cell.getValue()?.lastName}` : cell.getValue()}
          </Typography>
        )
      },
      size: 120,
    },
    status: {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()}
        </Typography>
      ),
      size: 120,
    },
    comment: {
      accessorKey: "comment",
      header: "Comment",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()}
        </Typography>
      ),
      size: 120,
    },
    }

    const columns = [
        columnsDefs.name,
        // columnsDefs.status,
        // columnsDefs.comment
    ]
  return (
    <MuiModal
      title="View Document Detail"
      open={open}
      onCancel={onClose}
      maxWith="sm"
    >
      <CustomTable
        state={{
            isLoading: loading
        }}
        columns={columns}
        data={mappedDocument}
        enableColumnActions
        enableColumnFilterModes
        enableColumnFilters
        enableTopToolbar
      />
    </MuiModal>
  );
};

export default ViewDetailModal;
