import themeBase from "../themeBase";

export const MuiInputBaseOverride = {
  styleOverrides: {
    root: {
      backgroundColor: '#F9FAFB',
      '&.MuiInputBase-root': {
        borderRadius: '8px',

        '&.Mui-focused': {
          borderColor: '#0B85BA',
        },
      },
      'label + &': {
        marginTop: themeBase.spacing(3),
      },
      '& .MuiInputBase-input': {
        color: '#6B7280',
        borderRadius: '8px',
        position: 'relative',
        fontSize: '16px',
        padding: '10px 12px',
        ...themeBase.typography.body2Normal,
      },
      'fieldset > legend > span': {
        display: 'none',
      },
    },
  },
};
