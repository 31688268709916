import React, { useEffect } from 'react';

const useGtag = (trackingId) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', trackingId);
    };
    script.onerror = (error) => {
      console.error('Error loading GTM script:', error);
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [trackingId]);
};

export default useGtag;
