import { Close, Pause, PlayArrow, Stop, UploadFile, VideoCameraFront } from '@mui/icons-material';
import BackupIcon from '@mui/icons-material/Backup';
import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton, LinearProgress } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal';
import Button from 'Components/Common/semantic_tags/Button';
import Typography from 'Components/Common/semantic_tags/Typography';
import { UpdateUser, uploadVideo } from 'Pages/editProfile/api';
import { useAlertProvider } from 'Providers/util/Alert';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfileDetails, selectResourceDetails } from 'Redux/Feature/profile/profile.selector';
import { setResourceUpdatedDetails, setUpdatedDetails } from 'Redux/Feature/profile/profile.slice';
import { roles } from 'Utils/constants';
import ShowProfileVideo from './ShowProfileVideo';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DeleteModal } from 'Pages/editProfile/Education';

const VideoUpload = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const userDetails = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [recording, setRecording] = useState(false);
  const { success, error } = useAlertProvider()
  const [mediaRecording, setMediaRecording] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false)
  const [paused, setPaused] = useState(false);
  const videoRef = useRef(null);
  const dispatch = useDispatch()
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    if (isUploading) return error('Uploading is in progress');
    setIsModalOpen(false);
    setUploadType(null);
    setVideoPreview(null);
    setRecording(false);
    setPaused(false);
    stopRecording();
    setVideoFile(null);
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    setVideoFile(file);
    setVideoPreview(URL.createObjectURL(file));
  };

  const removeVideo = () => {
    setVideoFile(null);
    setVideoPreview(null);
    setUploadType(null);
    setPaused(false)
    setRecording(false)
  };

  const retry = () => {
    setVideoFile(null);
    setVideoPreview(null);
    setRecording(false)
    setPaused(false)
    uploadType === 'capture' && initiateRecording();
  };

  const initiateRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      streamRef.current = stream;

      const videoElement = videoRef.current;
      videoElement.srcObject = stream;
      videoElement.addEventListener('loadeddata', () => {
        videoElement.play();
      });

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      const chunks = [];

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const file = new File([blob], 'captured_video.webm', { type: 'video/webm' });
        setVideoFile(file);
        setVideoPreview(URL.createObjectURL(blob));
        videoElement.srcObject = null;
      };
      setMediaRecording(mediaRecorder)
    } catch (err) {
      console.error('Error accessing the camera: ', err);
    }
  };

  const startRecording = () => {
    mediaRecording?.start()
    setRecording(true);
    setPaused(false);
  }

  const stopRecording = () => {
    if (recording || paused) {
      mediaRecorderRef.current.stop();
      streamRef.current.getTracks().forEach((track) => track.stop());
      setRecording(false);
    }
  };

  const resumeRecording = () => {
    if (paused) {
      mediaRecorderRef.current.resume();
      setRecording(true);
      setPaused(false);
    }
  };

  const pauseRecording = () => {
    if (recording) {
      mediaRecorderRef.current.pause();
      setRecording(false);
      setPaused(true);
    }
  };

  const captureVideo = () => {
    setUploadType('capture');
    setVideoPreview(null);
    initiateRecording();
  };

  const uploadFromLocal = () => {
    setVideoPreview(null);
    setRecording(false);
    setPaused(false);
    setVideoFile(null);
    setUploadType('local');
    document.getElementById('videoUploadInput').click();
  };

  const uploadHandler = async () => {
    setIsUploading(true);
    setUploadProgress(0);

    const resp = await uploadVideo({
      cur: videoFile,
      user: userDetails,
    }, (progress) => {
      setUploadProgress(progress);
    });

    if (!resp.success) {
      error(resp?.error || 'Something went wrong')
    } else {
      await UpdateUser({ profile_video_url: resp.url }, roles.consultant)
      success('Video uploaded successfully')
      setIsModalOpen(false)
      dispatch(isResourceEditing ? setResourceUpdatedDetails(({ profile_video_url: resp.url })) : setUpdatedDetails({ profile_video_url: resp.url }))
    }
    setIsUploading(false);
  };

  const removeHandler = async () => {
    await UpdateUser({ profile_video_url: '' }, roles.consultant)
    success('Video removed successfully')
    dispatch(isResourceEditing ? setResourceUpdatedDetails(({ profile_video_url: '' })) : setUpdatedDetails({ profile_video_url: '' }))
  }


  return (
    <div className={`${userDetails?.profile_video_url ? 'min-[300px]:w-full md:max-w-[30%]' : 'min-[300px]:w-full md:w-[60%]'}`}>
      {userDetails?.profile_video_url
        ? <div className='w-full flex'>
          <ShowProfileVideo profile_video_url={userDetails?.profile_video_url} isDashboard={true} />
          <div className='flex flex-col items-center justify-center'>
            <div
              className="bg-red-500 m-2 inline-block rounded-full p-2 cursor-pointer"
              onClick={() => setDeleteModal(true)}
            >
              <DeleteForeverIcon className="text-white" />
            </div>
            <div
              className="bg-[#3985B6] m-2 inline-block rounded-full p-2 cursor-pointer"
              onClick={handleOpenModal}
            >
              <BackupIcon className="text-white" />
            </div>
          </div>
          {deleteModal && <DeleteModal open={deleteModal} onDelete={removeHandler} isClose={() => setDeleteModal(false)} text='Profile Video' />}
        </div>
        : <div className='flex min-[300px]:flex-col md:flex-row items-center'>
          <img
            src='https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/3755670.jpg'
            className='h-[200px]'
            alt='Video Upload Illustration'
          />
          <div className='min-[300px]:flex min-[300px]:flex-col min-[300px]:items-center md:block ml-4'>
            <Typography className='text-left text-sm font-bold'>Short Introductory Video</Typography>
            <Typography className='text-xs'>
              Upload a brief video introduction to showcase your skills and experience. This quick snapshot helps employers get to know you better and boosts your chances of getting hired.
            </Typography>
            <Button onClick={handleOpenModal} title='Get Started' className='mt-2' />
          </div>
        </div>}

      <MuiModal
        open={isModalOpen}
        onCancel={handleCloseModal}
        title="Short Introductory Video"
      >
        <div className="space-y-4">
          <p className="text-gray-600">
            Please upload a video to enhance your profile visibility. Your video should meet the following guidelines:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>Maximum file size: 20 MB</li>
            <li>Accepted formats: .mp4, .mov, .avi, .webm</li>
            <li>Ideal Time: 30 sec to 1 min</li>
            <li>Ensure good lighting and sound quality</li>
          </ul>

          {isUploading ? (
            <div className="flex flex-col items-center mt-10">
              <LinearProgress variant="determinate" value={uploadProgress} className="w-full" />
              <p className="mt-2 text-gray-600">Uploading: {uploadProgress}%</p>
            </div>
          ) : videoPreview ? (
            <div className="relative">
              <video src={videoPreview} controls className="w-full h-auto rounded-lg" />
              <div className='flex justify-center items-center mt-8'>
                <div
                  className="bg-red-500 m-2 inline-block rounded-full p-2 cursor-pointer"
                  onClick={removeVideo}
                >
                  <Close className="text-white" />
                </div>
                {uploadType === 'capture' && <div
                  className="bg-gray-500 m-2 inline-block rounded-full p-2 cursor-pointer"
                  onClick={retry}
                >
                  <ReplayIcon className="text-white" />
                </div>}
                <div
                  className="bg-[#3985B6] m-2 inline-block rounded-full p-2 cursor-pointer"
                  onClick={uploadHandler}
                >
                  <BackupIcon className="text-white" />
                </div>
              </div>
            </div>
          ) : (
              <div className="relative">
                {uploadType === 'capture' && <video ref={videoRef} muted className="w-full h-auto rounded-lg" />}
                <div className="absolute bottom-0 left-0 right-0 flex justify-center space-x-4 m-2">
                  {recording ? (
                    <>
                      <IconButton onClick={stopRecording}>
                        <Stop className="text-red-500" />
                      </IconButton>
                      <IconButton onClick={pauseRecording}>
                        <Pause className="text-yellow-500" />
                      </IconButton>
                    </>
                  ) : paused ? (
                      <>
                        <IconButton onClick={stopRecording}>
                          <Stop className="text-red-500" />
                        </IconButton>
                        <IconButton onClick={resumeRecording}>
                          <PlayArrow className="text-green-500" />
                        </IconButton>
                      </>
                  ) : uploadType === 'capture' ? <>
                    <div onClick={startRecording} className='w-10 h-10 rounded-full bg-white flex justify-center items-center'>
                      <PlayArrow className="text-green-500 text-lg" />
                    </div>
                  </> : <></>
                  }
                </div>
              </div>
          )}

              {!videoPreview && !isUploading && !recording && !paused && !isUploading && (
                <div className="flex flex-col items-center space-y-4">
                  <div className="flex justify-between items-center mt-10 gap-20">
                    <div
                      className="flex items-center space-x-2 cursor-pointer"
                      onClick={uploadFromLocal}
                    >
                      <UploadFile className="text-blue-500" />
                      <span>Upload from Local</span>
                    </div>

                    <input
                      type="file"
                      id="videoUploadInput"
                      accept="video/*"
                      className="hidden"
                      onChange={handleUpload}
                    />

                    <div
                      className="flex items-center space-x-2 cursor-pointer"
                      onClick={captureVideo}
                    >
                      <VideoCameraFront className="text-blue-500" />
                      <span>Capture Video</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
      </MuiModal>
    </div>
  );
};

export default VideoUpload;
