import { Box, Divider } from "@mui/material";
import Typography from "Components/Common/semantic_tags/Typography";
import moment from "moment";
import React from "react";

export default function ProjectCard({ project, setProjectId, isActive, setProjectDetails, isExpand }) {
  return (
    <div
      onClick={() => (setProjectId(project.id), setProjectDetails(project))}
      className="cursor-pointer rounded-md bg-white w-full pt-4"
      style={{ background: isActive ? '#F2FBFF' : '' }}
    >
      <div className="flex flex-row justify-between items-center px-4">
        {isExpand ? <Box className="flex flex-row space-x-2" sx={{ width: '100%' }}>
          <Box
            sx={{
              width:'100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Typography className="text-sm font-semibold">{project.title}</Typography>
            <Typography className="text-xs font-light">
              {project.createdAt
                ? moment.utc(project.createdAt).format("DD MMM, YYYY")
                : ""}
            </Typography>
          </Box>
        </Box>
        : <div className="fot-bold text-md text-center flex">
          {project?.title.split("")[0].toUpperCase()}
            {' '}
          {project?.title.split(" ").length > 1 && project?.title.split(" ")[1].split('')[0]?.toUpperCase()}
          </div>
        }
      </div>
      {isExpand && project.description && (
        <span className="flex items-center !text-xs mt-2 px-4">
            About: &nbsp;<span className="!text-xs" dangerouslySetInnerHTML={{ __html: project.description.slice(0, 50) + `${project?.description?.length > 50 ? '...' : ''}`  }}></span>
        </span>
      )}
      <Divider sx={{ backgroundColor: '#E5E7EB', my: 2 }} />
    </div>
  );
}
