import { baseURL } from "../../../Utils/api";
import Swal from "sweetalert2";
import api from "../../../Utils/api";
export const Login = async (body) => {
  return api
    .post(`${baseURL}/auth/signin`, body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export const Register = async (type, body, isPublic = false) => {
  var url = ``;
  if (type === "Vendor") {
    url = `${baseURL}/auth/vendorsignup`;
  } else if (type === "Client") {
    url = `${baseURL}/auth/clientsignup`;
  } else if (type === "Consultant") {
    url = `${baseURL}/auth/consultantsignup`;
  }
  if (isPublic) {
    url = `${baseURL}/auth/complete-client-signup`;
    body['newPassword'] = body.password
    delete body.password
  }

  return api
    .post(url, body, {headers: type === "Consultant" ? {
      'Content-Type': 'multipart/form-data'
    }: {}})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Swal.fire(err.response?.data?.msg, "", "error");
      return null;
    });
};

export const RegisterWithInvite = async (body) => {
  let url = `${baseURL}/invite/register-user-with-invite`;

  return api
    .post(url, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Swal.fire(err.response?.data?.msg, "", "error");
      return err;
    });
};

export const SendOTP = async (body, isPublic) => {
  var url = `${baseURL}/auth/sendotp${isPublic ? '?otpType=CLINET_INVOICE_SIGNUP' : ''}`;
  return api
    .post(url, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Swal.fire(err.response?.data?.msg, "", "error");
      return err;
    });
};

export const SendEmailOTP = async (body, isPublic) => {
  var url = `${baseURL}/user/sendemailotp${isPublic ? '?otpType=CLINET_INVOICE_SIGNUP' : ''}`;
  return api
    .post(url, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Swal.fire(err.response?.data?.msg, "", "error");
      return err;
    });
};

export const ResendOTP = async (body, isPublic) => {
  var url = `${baseURL}/auth/resendotp${isPublic ? '?otpType=CLINET_INVOICE_SIGNUP' : ''}`;
  return api
    .post(url, body)
    .then((res) => {
      Swal.fire(
        "OTP Resend successful",
        "Please check you mailbox for OTP",
        "success"
      );
      return res;
    })
    .catch((err) => {
      Swal.fire(err.response?.data?.msg, "", "error");
      return err;
    });
};

export const VerifyOTP = async (body) => {
  var url = `${baseURL}/auth/verifyotp`;
  return api
    .post(url, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const UserInfo = async (body) => {
  const isHybridConsultant = localStorage.getItem('isHybridConsultant');
  const role = localStorage.getItem('role');
  const hybridConsultantParam = role === "VENDOR" ? "?isHybridConsultant=true" : "?isHybridConsultant=false"
  
  return api
    .get(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles${isHybridConsultant ? hybridConsultantParam : ""}`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export const PublicUserInfo = async (body) => {
  return api
    .post(`${baseURL}/user/getpublicdetailsbykeycloakid`, body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export const UpdateUser = async (body) => {
  return api
    .put(`${baseURL}/user/updateuser`, body)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return null;
    });
};
export const CheckEmailExist = async (body) => {
  return api
    .post(`${baseURL}/auth/checkemailexist`, body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export const InvitedUser = async (inviteid) => {
  return api
    .get(`${baseURL}/invite/get-invite-byid`, {
      params: {
        id: inviteid,
      },
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export const CheckUsernameExist = async (body) => {
  return api
    .post(`${baseURL}/auth/checkusernameexist`, body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};
