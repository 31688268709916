import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteLoaderData } from 'react-router-dom';
import Typography from 'Components/Common/semantic_tags/Typography';
import UserAvatar from 'Components/UserAvatar';
import { apiPath } from 'Providers/Client/Jobs';
import { useAlertProvider } from 'Providers/util/Alert';
import { selectUserDetails } from 'Redux/Feature/user';
import api from 'Utils/api';
import moment from 'moment';
import Button from 'Components/Common/semantic_tags/Button';

export default function JobDetailsTab() {
  const userDetails = useSelector(selectUserDetails);
  const [toggleDescriptionText, setToggleDescriptionText] = useState(false);
  const [isClosedModal, setIsClosedModal] = useState(false);
  const { success, error } = useAlertProvider();

  const handleToggleDescriptionText = () => {
    setToggleDescriptionText(!toggleDescriptionText);
  };

  const data = useRouteLoaderData('jobDetail');
  const jobDetails = data?.data?.result || {};

  const descriptionText = useMemo(() => {
    const cleanHTML = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
    };
  
    const cleanedDescription = cleanHTML(jobDetails?.description);
  
    return { text: cleanedDescription, html: jobDetails?.description };
  }, [toggleDescriptionText, jobDetails?.description]);

  const closeJobHandler = async () => {
    try {
      const resp = await api.patch(apiPath.updateJob + `/${jobDetails.id}?status=CLOSED`);
      if (resp && !resp?.data?.success) {
        return error(resp?.data?.msg || 'Something went wrong');
      }
      if (resp && resp.data) {
        success("Job Closed successfully");
        setIsClosedModal(false);
      }
    } catch (e) {
      if (!e?.response?.data?.success) return error(e?.response?.data?.msg || 'Something went wrong');
      error('Something went wrong');
    }
  };

  return (
    <div className="p-4">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div className="flex flex-wrap items-center space-x-4 border-b pb-2 border-gray-300">
            {jobDetails?.start_date && (
              <div className="flex items-center space-x-2 border-r h-full pr-2">
                <Typography className="text-xs font-bold">Start Date:</Typography>
                <Typography className="text-xs">{moment(new Date(jobDetails?.start_date)).format('DD MMM, YYYY')}</Typography>
              </div>
            )}
            {(jobDetails?.consultant?.firstName || jobDetails?.consultant?.lastName) && (
              <div className="flex items-center space-x-2 border-r h-full pr-2">
                <Typography className="text-xs font-bold">Resource:</Typography>
                <UserAvatar user={jobDetails?.resource || jobDetails?.consultant} />
              </div>
            )}
            {(jobDetails?.vendor?.company || jobDetails?.vendor?.firstName || jobDetails?.vendor?.lastName) && (
              <div className="flex items-center space-x-2 border-r h-full pr-2">
                <Typography className="text-xs font-bold">Vendor:</Typography>
                <Typography className="text-xs">{jobDetails?.vendor?.company || `${jobDetails?.vendor?.firstName} ${jobDetails?.vendor?.lastName}`}</Typography>
              </div>
            )}
            {jobDetails?.project?.title && (
              <div className="flex items-center space-x-2 border-r h-full pr-2">
                <Typography className="text-xs font-bold">Project:</Typography>
                <Typography className="text-xs">{jobDetails?.project?.title}</Typography>
              </div>
            )}
            {jobDetails?.jobCategory?.category && (
              <div className="flex items-center space-x-2 border-r h-full pr-2">
                <Typography className="text-xs font-bold">Category:</Typography>
                <Typography className="text-xs">{jobDetails?.jobCategory?.category}</Typography>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-2">
          <Typography variant="body1Semibold" className="text-black">About the job</Typography>
          <div className="uniform-font" style={{ inlineSize: "100%", color: '#6B7280 !important', wordWrap: 'break-word', overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: jobDetails?.description }} />
        </div>
        <div className="flex justify-between">
          <div className=''>
            <Typography variant="body1Semibold" className="text-black">Skills</Typography>
            <div className="flex flex-wrap gap-2">
              {(jobDetails?.main_skill || []).map((job) => (
                <div key={job} className="px-3 py-1 border rounded-lg text-sm">{job}</div>
              ))}
            </div>
          </div>
          {jobDetails?.status === 'ONGOING' && userDetails?.role !== 'CONSULTANT' && (
            <Button className='bg-red-600 hover:bg-red-600 focus:bg-red-600 text-xs' onClick={() => setIsClosedModal(true)} title='Close Job' />
          )}
        </div>
      </div>
      {isClosedModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-sm">
            <Typography>Do you want to close this job? If yes, no more timesheets can be created for this Job?</Typography>
            <div className="mt-4 flex justify-between">
              <button className="px-4 py-2 bg-gray-300 rounded" onClick={() => setIsClosedModal(false)}>Cancel</button>
              <button className="px-4 py-2 bg-blue-600 text-white rounded" onClick={closeJobHandler}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
