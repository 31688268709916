import { Alert, Snackbar } from "@mui/material";
import React, { useState } from "react";

// type values will be success, error
export const ToastNotifier = ({ message, type }) => {
  const [handleClose, setHandleClose] = useState(true);
  return (
    <div className="toast-notifier">
      <Snackbar
        open={handleClose}
        className="toast-notifier-snackbar"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => {
          setHandleClose(false);
        }}
      >
        <Alert
          onClose={() => {
            setHandleClose(false);
          }}
          severity={type}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
