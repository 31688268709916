import { MenuItem, Select, Stack } from '@mui/material';
import React from 'react';

const CustomSelect = ({ icon, options, onClick, value, className }) => (
    <Stack className='w-[100%] md:w-[50%]' flexDirection={'row'} paddingLeft={'20px'} alignItems={'center'} gap={'10px'}>
        {icon}
        <Select className={className} onChange={onClick} value={value} sx={{
            background: 'white',
            width: '90%',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
        }}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option?.value}>
                    {option?.label || option}
                </MenuItem>
            ))}
        </Select>
    </Stack>
);

export default CustomSelect;
