import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Chip, createFilterOptions, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setResourceUpdatedDetails, setUpdatedDetails } from '../../Redux/Feature/profile/profile.slice';
import { selectProfileDetails, selectResourceDetails } from '../../Redux/Feature/profile/profile.selector';
import Heading1 from 'Components/Common/semantic_tags/Heading1';
import Button from 'Components/Common/semantic_tags/Button';
import Typography from 'Components/Common/semantic_tags/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import ServicesComponent from './Services';
import { useJobProvider } from 'Providers/Job.provider';
import AddTabs from './common/AddTabs';

const SkillsComponent = () => {
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const profile = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const muiListfilter = createFilterOptions();
  const skills = profile?.skills || [];
  const primarySkills = profile?.skills_with_rating || [];
  const { getAllSkills, skills: allSkills } = useJobProvider();
  const [inputSkills, setInputSkills] = useState([])
  const [inputPrimarySkills, setInputPrimarySkills] = useState([])
  const dispatch = useDispatch();

  const removeSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    dispatch(isResourceEditing ? setResourceUpdatedDetails({ skills: updatedSkills }) : setUpdatedDetails({ skills: updatedSkills }));
  };

  const sortedSkills = useMemo(() => (allSkills.sort((a, b) => a.name.localeCompare(b.name))), [allSkills]);

  useEffect(() => {
    getAllSkills()
  }, [])

  const removePrimarySkill = (index) => {
    const updatedSkills = [...primarySkills];
    updatedSkills.splice(index, 1);
    dispatch(isResourceEditing ? setResourceUpdatedDetails({ skills_with_rating: updatedSkills }) : setUpdatedDetails({ skills_with_rating: updatedSkills }));
  };

  const addPrimarySkill = () => {
      dispatch(
        isResourceEditing ?
        setResourceUpdatedDetails({
        skills_with_rating: [
          ...primarySkills,
          ...inputPrimarySkills.map(i => { return { name: i?.name || i, rating: 1, status: 'Beginner' } })
        ]
        }) :
        setUpdatedDetails({
        skills_with_rating: [
          ...primarySkills,
          ...inputPrimarySkills.map(i => { return { name: i?.name || i, rating: 1, status: 'Beginner' } })
        ]
        })
    );
      setInputPrimarySkills([]);
  };

  const addOtherSkill = () => {
      dispatch(isResourceEditing ? setResourceUpdatedDetails({ skills: [...skills, ...inputSkills.map(i => i?.name || i)] }) :  setUpdatedDetails({ skills: [...skills, ...inputSkills.map(i => i?.name || i)] }));
      setInputSkills([])
  };

  const handleRatingChange = (skillName, newRating) => {
    const updatedSkills = primarySkills.map(skill =>
      skill.name === skillName ? { ...skill, rating: parseInt(newRating, 10), status: newRating <= 3 ? 'Beginner' : newRating >= 4 && newRating <= 7 ? 'Intermediate' : 'Advanced' } : skill
    );
    dispatch(isResourceEditing ? setResourceUpdatedDetails({ skills_with_rating: updatedSkills }) : setUpdatedDetails({ skills_with_rating: updatedSkills }));
  };

  const handleFilterCreateOption = (options, params) => {
    const filtered = muiListfilter(options, params);
    const { inputValue } = params;
    const isExistingValue = options.some((option) => (inputValue === option?.name || inputValue === option));
    if (inputValue !== '' && !isExistingValue) {
      filtered.push({
        inputValue,
        name: `${inputValue}`
      })
    }
    return filtered;
  }

  const handleDragSkillRating = (e, skill) => {
    const slider = e.target.closest('.skill-rating-slider-container');
    if (slider) {
      const rect = slider.getBoundingClientRect(); // Get the bounding box of the slider
      const offsetX = e.clientX - rect.left; // Calculate the X position of the click relative to the slider
      const newRating = Math.min(10, Math.max(0, (offsetX / rect.width) * 10)); 
      handleRatingChange(skill, newRating > 9.4 ? 10 : newRating < 0.9 ? 1 : newRating);
    }
  };

  return (
    <>
      {["VENDOR", "CLIENT"].includes(profile.role)
        ? <>
          <ServicesComponent />
          <Typography className="font-bold text-md">Skills (up to 25)</Typography>
          {skills.length < 25 && (
            <div className="mt-2 flex flex-col sm:flex-row gap-4">
              <Autocomplete
                multiple
                clearIcon={false}
                className="w-[40%]"
                value={inputSkills || []}
                defaultValue={inputSkills}
                getOptionLabel={(option) => (option.name ? option.name : option)}
                filterOptions={handleFilterCreateOption}
                onChange={(_, v) => setInputSkills(v)}
                options={sortedSkills}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name ? option.name : option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder='Type to add skills'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
              <Button
                type="button"
                disabled={inputSkills?.length === 0 || [...inputSkills, ...skills].length > 25}
                className="px-4 py-2 rounded text-white bg-primary-600 w-full sm:w-auto"
                onClick={addOtherSkill}
                title={'Add Skills'}
              />
            </div>
          )}
          {/* <div className="grid grid-cols-3 mt-4"> */}
            <AddTabs
              style={{ marginBottom: "40px" }}
              tabs={skills}
              removeTabs={removeSkill}
            />
          {/* </div> */}
        </>
        :
        <Box>
          <Heading1 className="text-lg font-semibold">Skill set (max 25)</Heading1>
          <div className="mt-8">
            <Typography className="font-bold text-md">Primary Skills (up to 10)</Typography>
            {primarySkills.length < 10 && (
              <div className="mt-2 flex flex-col sm:flex-row gap-4 items-center">
                {/* <input
                  className="w-full sm:w-2/5 p-2 border border-gray-300 rounded"
                  value={newPrimarySkill}
                  onChange={(e) => setNewPrimarySkill(e.target.value)}
                  placeholder={'Add a primary skill'}
                /> */}
                <Autocomplete
                multiple
                clearIcon={false}
                className="min-[300px]:w-full md:w-[40%]"
                value={inputPrimarySkills || []}
                getOptionLabel={(option) => (option.name ? option.name : option)}
                filterOptions={handleFilterCreateOption}
                onChange={(_, v) => setInputPrimarySkills(v)}
                options={sortedSkills}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name ? option.name : option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder='Type to add skills'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
                <Button
                  type="button"
                  disabled={inputPrimarySkills?.length === 0 || [...inputPrimarySkills, ...primarySkills].length > 10}
                  className="px-4 py-2 rounded text-white bg-primary-600 w-full sm:w-auto"
                  onClick={addPrimarySkill}
                  title={'Add Primary Skills'}
                />
              </div>
            )}
            <div className="mt-4" >
              {primarySkills.map((skill, index) => (
                <div
                  className="flex min-[300px]:flex-col-reverse md:flex-row items-center mt-4"
                >
                  <div className="border rounded-lg min-[300px]:px-4 min-[300px]:py-2 md:p-2 min-[300px]:w-full min-[300px]:mb-8 md:mb-0 md:w-[70%]">
                    <div className="flex min-[300px]:flex-col min-[300px]:items-start md:flex-row md:items-center space-y-4 sm:space-y-0 sm:space-x-4 min-[300px]:gap-1 md:gap-4">
                      <div className="flex justify-start items-center space-x-2 w-full min-[300px]:w-full md:w-[30%] overflow-hidden">
                        <div className="w-8 h-8 flex items-center justify-center">
                          <VerifiedIcon className="text-[#006A98]" sx={{fontSize: {xs: "16px", md: "unset"}}} />
                        </div>
                        <span className="min-[300px]:text-sm md:text-lg font-medium">{skill.name}</span>
                      </div>
                      
                      {/**Mobile UI Slider Rating */}
                      <div className="min-[300px]:flex md:hidden flex-col h-[10px] w-full !ml-0 rounded-lg bg-slate-200 cursor-pointer skill-rating-slider-container" onClick={(e) => handleDragSkillRating(e, skill.name)}>
                        <div className={`relative h-full rounded-full bg-[#3985B6]`} style={{ width: `${skill.rating * 10}%` }}>
                          <div className='w-[20px] h-[20px] rounded-full bg-[#3985B6] flex justify-center items-center text-white absolute top-[-6px] right-[-10px]' draggable="true" onDrag={handleDragSkillRating}>
                            <span className='text-[10px]'>{skill.rating}</span>
                          </div>
                        </div>
                      </div>

                      <div className="md:grid min-[300px]:hidden min-[300px]:grid-cols-3 sm:grid-cols-10 text-center gap-2 pb-6 pr-4">
                        {[...Array(10)].map((_, i) => (
                          <label key={i} className="relative flex flex-col inline-block items-center">
                            <input
                              type="radio"
                              value={i + 1}
                              checked={i + 1 <= Number(skill.rating)}
                              onClick={() => handleRatingChange(skill.name, i + 1)}
                              className="hidden peer"
                            />
                            <div className="flex items-center justify-center min-[300px]:w-6 md:w-10 min-[300px]:h-6 md:h-10 border-2 border-[#3985B6] rounded-lg peer-checked:bg-[#3985B6] peer-checked:text-white text-[#3985B6]">
                              {i + 1}
                            </div>
                            <span className="mt-1 text-xs text-gray-500 absolute min-[300px]:top-5 md:top-10">
                              {i + 1 === 1 ? 'Beginner' : i + 1 === 5 ? 'Intermediate' : i + 1 === 10 ? 'Advanced' : ''}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Button
                    onClick={() => removePrimarySkill(index)}
                    parentClassName="flex justify-end min-[300px]:items-end md:items-center min-[300px]:mb-1 sm:mt-4 min-[300px]:w-full md:w-auto sm:mt-0 sm:ml-4"
                    className="bg-[#F1F2F4] hover:!bg-[#F1F2F4] !border-slate-200"
                    title={<CancelIcon className="text-black" />}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-8">
            <Typography className="font-bold text-md">Other Skills (up to 15)</Typography>
            {skills.length < 15 && (
              <div className="mt-2 flex flex-col sm:flex-row gap-4 items-center">
                <Autocomplete
                multiple
                clearIcon={false}
                className="min-[300px]:w-full md:w-[40%]"
                value={inputSkills || []}
                getOptionLabel={(option) => (option.name ? option.name : option)}
                filterOptions={handleFilterCreateOption}
                onChange={(_, v) => setInputSkills(v)}
                options={sortedSkills}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name ? option.name : option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder='Type to add skills'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
                {/* <input
                  className="w-full sm:w-2/5 p-2 border border-gray-300 rounded"
                  value={newOtherSkill}
                  onChange={(e) => setNewOtherSkill(e.target.value)}
                  placeholder={'Add another skill'}
                /> */}
                <Button
                  type="button"
                  disabled={inputSkills?.length === 0 || [...inputSkills, ...skills].length > 15}
                  className="px-4 py-2 rounded text-white bg-primary-600 w-full sm:w-auto"
                  onClick={addOtherSkill}
                  title={'Add Other Skills'}
                />
              </div>
            )}
            <div
              className="grid md:grid-cols-3 mt-4"
            >
              {skills.map((skill, j) => (
                <div className="flex flex-col min-[300px]:flex-row items-center mt-4">
                  <div className="p-2 w-full overflow-hidden">
                    <div className="flex justify-start items-center overflow-hidden">
                      <div className="w-8 h-8 flex items-center justify-center">
                        <VerifiedIcon className="text-[#006A98]" />
                      </div>
                      <span className="break-words min-[300px]:w-[200px] md:w-auto text-md font-medium">{skill}</span>
                    </div>
                  </div>
                  <CancelIcon className="cursor-pointer" onClick={() => removeSkill(j)} />
                </div>
              ))}
            </div>
          </div>
        </Box>
      }
    </>
  );
};

export default SkillsComponent;
