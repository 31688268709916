import { Close } from '@mui/icons-material';
import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, Modal, Paper, Stack, TextField, Typography } from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import { isInt } from 'validator';
import { getUserRole, isValidFourNumbers } from 'Utils/helper';
import { useAlertProvider } from 'Providers/util/Alert';
import { useTimesheetProvider } from 'Providers/Timesheet.provider';
import { useParams } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import React from 'react'
import MuiModal from 'Components/Common/MuiModal';
import { useSelector } from 'react-redux';
import { timesheetSelector } from 'Redux/Feature/timesheet';
import { monthNames } from 'Utils/constants';

function SubmitTimesheetViaLinkModal() {
  const [openTimesheetShareModal, setOpenTimesheetShareModal] = React.useState(false);
  const [secure, setSecure] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const [emails, setEmails] = React.useState([]);
  const isValidPINDigits = isValidFourNumbers(pin);
  const role = getUserRole();
  const { success } = useAlertProvider();
  const { generateSecureLinkForMonthlyTimesheet, loading } = useTimesheetProvider();
  const { jobId } = useParams();
  const emailsValid = emails.length > 0 && emails.every((email) => isEmail(email));
  const pinValid = secure ? isInt(pin, { min: 0, max: 9999 }) : true;
  const isValid = emailsValid && pinValid;
  const { timesheetDate } = useSelector(timesheetSelector);  

  const submitTimesheetViaLink = ({ shouldCopy = false }) => {
    generateSecureLinkForMonthlyTimesheet({
      job_id: jobId,
      emails: shouldCopy ? [] : emails,
      pin: parseInt(pin),
      year: timesheetDate.year(),
      month: monthNames[timesheetDate.month()],
    }).then((linkId) => {
      if (linkId !== null) {
        if (shouldCopy) {
          navigator.clipboard
            .writeText(`${window.location.protocol}//${window.location.hostname}/timesheet/${linkId}`)
            .then(() => success('Timesheet Link Copied to Clipboard'));
        } else {
          success('Timesheet submitted successfully');
        }

        setOpenTimesheetShareModal(false);
        setEmails([]);
        setSecure(false);
        setPin('');
      }
    });
  };
  return (
    <>
      {role === 'VENDOR' &&
        <Button
          variant='contained'
          onClick={() => setOpenTimesheetShareModal(true)}
          sx={{ padding: '8px', height: '42px', marginLeft: '10px' }}
        >
          <LinkIcon sx={{ marginRight: "8px" }} /> Send via link
        </Button>}
      <MuiModal
        open={openTimesheetShareModal}
        onCancel={() => setOpenTimesheetShareModal(false)}
        title='Submit Monthly Timesheet via Link'
        styling={{ padding: "2rem" }}
      // onClose={() => setOpenTimesheetShareModal(false)}
      >

        <Stack gap={2} alignItems="center" mt={3}>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => submitTimesheetViaLink({ shouldCopy: true })}
            sx={{
              typography: 'captionMedium',
              borderColor: '#0B85BA',
              py: 1,
              px: 4,
              borderRadius: '8px',
            }}
          >
            Copy Link to Clipboard
          </Button>

          <Typography variant="body2Normal" sx={{ color: '#6B7280', mb: 2 }}>Or</Typography>
        </Stack>

        <Stack gap={3}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="email"
            >
              Send Link To Email
            </InputLabel>
            <Autocomplete
              sx={{paddingTop: "8px"}}
              id="email"
              multiple
              freeSolo
              clearIcon={null}
              clearOnBlur
              onChange={(e, value) => setEmails(value.filter((email) => isEmail(email)))}
              value={emails}
              itemType=""
              options={[]}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  FormHelperTextProps={{
                    style: {margin: "8px 0"}
                  }}
                  helperText="To add one or more recipients, press enter after each address"
                  onBlur={(e) => {
                    if (isEmail(e.target.value)) {
                      if (!emails.includes(e.target.value)) setEmails([...emails, e.target.value]);
                    }
                  }}
                />
              )}
            />
            {!!emails.length && (
              <Stack direction="row" gap={1} my={2}>
                {emails.map((email) => (
                  <Chip
                    key={email}
                    label={email}
                    onDelete={() => setEmails(emails.filter((e) => e !== email))}
                  />
                ))}
              </Stack>
            )}
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={secure}
                  onChange={(_, val) => setSecure(val)}
                  disableRipple
                />
              )}
              label="Secure with a PIN"
            />
            <FormHelperText sx={{ m: 0 }}>
              This will prevent anyone without the PIN from accessing the timesheet.
              {' '}
              Simply communicate the selected PIN with the Client.
            </FormHelperText>
          </FormControl>

          {secure ? (
            <FormControl sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", height: "30px" }}><InputLabel for="pin">PIN</InputLabel></Box>
              <TextField
                sx={{ width: '150px' }}
                placeholder="Enter 4-digit PIN"
                maxLength="4"
                value={pin}
                onChange={(e) => {
                  const str = e.target.value;
                  if (str.length === 0) {
                    setPin('');
                  } else if (isInt(str, { min: 0, max: 9999 })) {
                    setPin(str);
                  }
                }}
              />
              {!isValidPINDigits && <Typography variant='defaultFont' sx={{ color: "red", fontSize: "13px" }}>Please Enter 4 digits</Typography>}
            </FormControl>
          ) : null}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button
            onClick={() => submitTimesheetViaLink({ shouldCopy: false })}
            variant="contained"
            disabled={loading || !isValid || secure && !isValidPINDigits}
            sx={{
              typography: 'captionMedium',
              color: '#F9FAFB',
              backgroundColor: '#0B85BA',
              py: 1,
              px: 1.5,
              borderRadius: '8px',
            }}
          >
            Submit
          </Button>
        </Stack>
      </MuiModal>
    </>
  )
}

export default SubmitTimesheetViaLinkModal;