import React, { useEffect, useState } from 'react';
import {
  Alert, Box, Card, Checkbox, FormControl, FormControlLabel, InputBase, InputLabel, Link, Stack, Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import { Login } from './Helper';
import { setToken } from '../../Redux/Feature/tokenSlice';
import api from '../../Utils/api';
import { roles } from '../../Utils/constants';
import { setLogin } from '../../Redux/Feature/user';
import Button from 'Components/Common/semantic_tags/Button';

function SignInPage({ isPublic, signupHandler, submitHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [LoginError, setLoginError] = useState({
    open: false,
    msg: '',
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const username = watch('username');
  const password = watch('password');

  useEffect(() => {
    if (localStorage.getItem('userId')) {
      navigate('/dashboard');
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    Login(data)
      .then((res) => {
        setLoading(false);
        if (res && res.data?.success) {
          dispatch(
            setLogin({
              role: res.data?.role, permissions: res.data?.permissions, userId: res.data?.userId, isHybridConsultant: res.data?.isHybridConsultant ?? false,
            }),
          );
          if (res.data?.isHybridConsultant) api.defaults.headers.Role = roles.consultant;
          api.defaults.headers.Authorization = `Bearer ${res.data.result.access_token}`;
          localStorage.setItem('at', res.data.result.access_token);
          localStorage.setItem('rft', res.data.result.refresh_token);
          localStorage.setItem('userId', res.data.userId);
          localStorage.setItem('permission', JSON.stringify(res.data?.permissions));
          localStorage.setItem('role', res.data?.role);
          res.data?.isHybridConsultant && localStorage.setItem('isHybridConsultant', res.data?.isHybridConsultant);
          dispatch(setToken(res.data.result.access_token));
          if (!isPublic) return navigate('/dashboard');
          else submitHandler()
          return;
        }
        if (res.response.status >= 400 && res.response.status <= 500) {
          setLoginError({
            open: true,
            msg:
              res.response.status === 403
                ? res?.response?.data?.data ? res?.response?.data?.data : 'Your account is not approved yet'
                : 'Invalid email or password',
          });
          return;
        }
        setLoginError({
          open: true,
          msg: 'Something Went Wrong',
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ ...(!isPublic && { backgroundColor: '#cfd8dc60', minHeight: '80vh' }) }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className='sm:flex sm:justify-center min-[300px]:w-full min-[300px]:px-4 sm:w-full md:w-auto'>
        <Card elevation={isPublic ? 0 : 1} sx={{ width: {xs: "100%", sm: '500px'}, p: 4 }}>
          <Typography variant="h5Bold">Welcome back</Typography>
          {!loading && (
            <Typography variant="body2" sx={{ display: 'flex', gap: '4px' }}>
              Don&apos;t have an account?
              {' '}
              {
                !isPublic
                  ? <Link
                    onClick={() => navigate('/signup')}
                    style={{ color: '#0B85BA' }}
                    underline="hover"
                  >
                    Sign up
                  </Link>
                  : <Box onClick={signupHandler} style={{ color: '#0B85BA', cursor: 'pointer' }}>
                    Sign up
                  </Box>
              }
            </Typography>
          )}
          <Stack
            alignItems="center"
            sx={{ marginTop: '20px' }}
            justifyContent="center"
          >
            {loading ? (
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible
              />
            ) : (
              <>
                {LoginError.open && (
                  <Alert severity="error" className="mb-4">
                    {LoginError.msg}
                  </Alert>
                )}

                <FormControl
                  fullWidth
                  required
                  error={!!errors.username}
                  variant="standard"
                  sx={{ mb: 2 }}
                >
                  <InputLabel htmlFor="username">Email</InputLabel>
                  <InputBase
                    placeholder="name@example.com"
                    id="username"
                    {...control.register('username', { required: true })}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  required
                  error={!!errors.password}
                  variant="standard"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <InputBase
                    id="password"
                    type="password"
                    placeholder="******"
                    {...control.register('password', { required: true })}
                  />
                </FormControl>

                <Button
                  type="submit"
                  parentClassName='!w-full mt-6'
                  className='w-full'
                  disabled={loading || !username?.length || !password?.length}
                  title='Log In'
                />
                  
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%', mt: 2 }}
                >
                  <Controller
                    name='rememberme'
                    control={control}
                    render={({field: {name, onChange, value}})=>(
                      <FormControlLabel
                        value={value}
                        onChange={onChange}
                        control={<Checkbox checked={value} />}
                        label="Remember Me"
                      />
                    )} 
                  />
                  {!isPublic && <Link
                    onClick={() => navigate('/resetpassword')}
                    style={{ color: '#0B85BA' }}
                    underline="hover"
                    variant="body2"
                  >
                    Forgot Password?
                  </Link>}
                </Stack>
              </>
            )}
          </Stack>
        </Card>
      </form>
    </Stack>
  );
}

export default SignInPage;
