import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper, TextField } from '@mui/material';
import { useConnectionProvider } from 'Providers/Vendor/Connections';
import { selectUserRole } from 'Redux/Feature/user';
import { getPermissions } from 'Utils/helper';
import Papaparse from 'papaparse';
import { useSelector } from 'react-redux';
import ShareableInviteField from '../../../Components/Shared/ShareableInviteField';
import { useVendorProvider } from '../../../Providers/Client/Vendor';
import { roles, userPermissions } from '../../../Utils/constants';
import { downloadExampleCSV } from '../../../Utils/downloadCSV';
import { isValidEmail } from '../../../Utils/validation';
import { CheckEmailExist, UserInfo } from '../../Auth/Helper';
import CurrentVendors from './Vendors/CurrentVendors';
import Button from 'Components/Common/semantic_tags/Button';
import Typography from 'Components/Common/semantic_tags/Typography';
import MuiModal from 'Components/Common/MuiModal';
import { useSearchParams } from 'react-router-dom';

export default function Vendors() {
  const [open, setOpen] = useState(false);
  const [emailUsed, setEmailUsed] = useState(false);
  const [filledEmail, setFilledEmail] = useState();
  const { sendInvite, sendRequestToVendor, sendRequestVendorToNewVendor } = useVendorProvider();
  const { importVendor } = useConnectionProvider();
  const permissions = getPermissions();
  const [file, setFile] = useState(null);
  const [isUserEmail, setIsUserEmail] = useState({
    email: "",
    isLoginedEmail: false
  });
  const userRole = useSelector(selectUserRole);
  const ref = useRef(null);
  const name = userRole.role === 'CLIENT' ? 'Vendor' : 'Partner';
  const [searchParams, setSearchParams] = useSearchParams();
  const getAddNewVendorQuery = searchParams.get('add-new-vendor');

  const handleFileUploadRef = () => {
    ref.current?.click();
  }

  const getUserInfoAPI = async () => {
    const user = await UserInfo();
    setIsUserEmail((prev) => ({
      ...prev,
      email: user?.data?.email
    }));
  }

  useEffect(() => {
    getUserInfoAPI();
  }, []);

  const checkEmailUsed = (email) => {
    CheckEmailExist({ email }).then((res) => {
      setEmailUsed(res?.data?.status);
      handleRequestChange(res?.data?.username, 'username');
      handleRequestChange(email, 'email');
    });
    if (email === isUserEmail.email) {
      setIsUserEmail((prev) => ({
        ...prev,
        isLoginedEmail: true
      }))
    } else if (email !== isUserEmail.email) {
      setIsUserEmail((prev) => ({
        ...prev,
        isLoginedEmail: false
      }))
    }
  };

  const [inviteUser, setInviteUser] = useState({
    invitedBy: localStorage.getItem('userId'),
    email: '',
    role: roles.vendor,
  });

  const [requestUser, setRequestUser] = useState({
    email: '',
  });

  const handleInviteChange = (e, name) => {
    setInviteUser({
      ...inviteUser,
      id: localStorage.getItem('userId'),
      [name]: e.target.value,
    });
  };

  const handleRequestChange = (e, name) => {
    setRequestUser({
      ...requestUser,
      id: localStorage.getItem('userId'),
      requestFrom: 'CLIENT',
      [name]: e?.target?.value || e,
    });
  };

  useEffect(() => {
    if(getAddNewVendorQuery) {
      setOpen(true);
    }
  },[getAddNewVendorQuery])

  const showModal = () => setOpen(true);
  const handleCancel = () => setOpen(false);

  const handleFileUpload = () => {
    importVendor(file, "PARTNER");
    // parseCSV(file, (parsedData) => {
    //   // Call the API with the parsed data
    //   parsedData.forEach((user) => {
    //     const propertyNames = Object.keys(user);
    //     const firstName = user[propertyNames[0]];
    //     const lastName = user[propertyNames[1]];
    //     const email = user[propertyNames[2]];
    //     const invitingUser = {
    //       invitedBy: localStorage.getItem('userId'),
    //       name: `${firstName} ${lastName}`,
    //       email,
    //       role: roles.vendor,
    //     };
    //     if (invitingUser?.email) {
    //       sendInvite(invitingUser, false);
    //     }
    //   });
    //   setFile(null);
    //   Swal.fire({
    //     title: 'Success',
    //     text: 'Invitation sent/Already Invited',
    //     icon: 'success',
    //   });
    // });
  };

  const handleRemoveFile = () => {
    setFile(null);
    ref.current = null;
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    event.target.value = null;
  };

  const parseCSV = (file, onDataParsed) => {
    Papaparse.parse(file, {
      complete: (result) => {
        onDataParsed(result.data);
      },
      header: true,
    });
  };

  const [openImportModal, setOpenImportModal] = useState(false);
  const showImportModal = () => {
    setOpenImportModal(true);
  };
  const handleImportCancel = () => {
    setOpenImportModal(false);
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
        {/* <Grid> */}
        <div className="flex justify-between items-center space-x-2 mb-4">
          <Box>
            <Typography
              variant="h3"
            >
              {`${name}s`}
            </Typography>
          </Box>
          <div className="flex justify-end space-x-2">
            {permissions?.includes('admin-client')
              || permissions?.includes(userPermissions.vendorConnection.add) ? (
              <Button
                onClick={showModal}
                title={`Add ${name}s`}
              />
            ) : null}
            <Button onClick={showImportModal} title={`Import ${name}s`} />
          </div>
        </div>
        <MuiModal
          open={openImportModal}
          title={`Import ${name}s`}
          onCancel={handleImportCancel}
        >
          <div>
            <span>Select .csv File* </span>
          </div>
          <div className="flex justify-start mt-4">
            <input type="file" accept=".csv" onChange={handleFileChange} name="file" id="file" className="sr-only" ref={ref} />
            <label
              htmlFor="file"
              className="text-center"
            >
              <div>
                <Button
                  onClick={handleFileUploadRef}
                  className=""
                  title='Select File'
                />
              </div>
            </label>
          </div>
          <div className="p-2 mt-4">
            <Typography className="text-sm text-gray-600 mb-2">
              NOTE: Please use the template provided below to ensure your data is formatted correctly. Download the template, fill in your data, and then upload it to our system.
            </Typography>
            <span onClick={downloadExampleCSV} className="py-2 mt-4 text-[#0B85BA] flex items-center cursor-pointer">
              <DownloadForOfflineIcon className="mr-2" /> Download Template
            </span>
          </div>

          <div className="flex flex-col justify-between mt-4">
            {file && <span>Selected File:</span>}
            {file && (
              <div className="rounded-md items-center bg-[#F5F7FB] py-2 px-8 mt-2 w-fit">
                <div className="flex items-center justify-between">
                  <span className="truncate pr-3 text-base font-medium text-[#07074D]">
                    {file?.name}
                  </span>
                  <div onClick={handleRemoveFile} className='cursor-pointer' title='Remove the file selected'>
                    <CloseIcon sx={{ fontSize: "20px" }} />
                  </div>
                </div>
              </div>
            )}
            {file && (
              <Button
                parentClassName='!w-full justify-end mt-2'
                onClick={() => {
                  handleFileUpload();
                }}
                title='Invite All'
              />
            )}
          </div>
        </MuiModal>
        <MuiModal
          open={open}
          title={`Add ${name}`}
          onCancel={handleCancel}
        >
          <Box>
            <div className="flex flex-col">
              <TextField
                label="Email"
                placeholder="Enter Email"
                onChange={(e) => {
                  checkEmailUsed(e.target.value);
                  setFilledEmail(e.target.value);
                  if (emailUsed) {
                    handleRequestChange(e, 'email');
                  } else {
                    handleInviteChange(e, 'email');
                  }
                }}
                required
              />
            </div>
            <ShareableInviteField type={roles.vendor} />
            <div className='flex justify-end w-full mt-4'>
              <Button
              disabled={isUserEmail.isLoginedEmail}
              onClick={(e) => {
                const validEmail = isValidEmail(filledEmail);
                if (validEmail && !emailUsed) {
                  sendInvite(inviteUser).then(() => {
                    setInviteUser({ ...inviteUser, email: '' });
                    setOpen(false);
                  });
                } else if (validEmail) {
                  if (!requestUser?.email) {
                    requestUser.email = filledEmail;
                  }
                } if (emailUsed) {
                  {
                    userRole.isVendor && sendRequestVendorToNewVendor(requestUser).then(() => {
                      setRequestUser({ email: '' });
                      setOpen(false);
                    })
                  }
                  {
                    userRole.isClient && sendRequestToVendor(requestUser).then(() => {
                      setRequestUser({ email: '' });
                      setOpen(false);
                    })
                  }
                }
              }}
              title={`Invite ${name}`}
            /></div>
          </Box>
        </MuiModal>
        <div className="mt-2">
          <CurrentVendors name={name} />
        </div>
        {/* </Grid> */}
        </Paper>
    </Box>
  );
}
