import { CopyOutlined } from '@ant-design/icons';
import { Edit, GetApp } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VerifiedIcon from '@mui/icons-material/Verified';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  Stack,
  Typography
} from '@mui/material';
import { BackButton } from 'Components/JobCard/Job.card';
import { UserInfo } from 'Pages/Auth/Helper';
import { publicProfileDetails } from 'Pages/editProfile/api';
import { useAlertProvider } from 'Providers/util/Alert';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { selectProfileDetails } from 'Redux/Feature/profile/profile.selector';
import { getPermissions } from 'Utils/helper';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import {
  FullWidthWrapper,
} from '../../Components/Common';
import {
  selectIsUserLoggedIn,
  setLoading,
  setUser
} from '../../Redux/Feature/user';
import { userPermissions } from '../../Utils/constants';
import ProfileTabs from './ProfileTabs';
import ShowProfileVideo from 'Pages/Dashboard/components/ShowProfileVideo';
import { updateResourceEditing } from 'Redux/Feature/profile/profile.slice';

export default function ProfilePage({ isMarketingView = false, children = '' }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [details, setDetails] = React.useState(null);
  const [referenceAPI, setReferenceAPI] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);
  const { success, error } = useAlertProvider()
  const userDetails = useSelector(selectProfileDetails);
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const loading = useSelector((state) => state.user.loading);
  const permissions = getPermissions();
  const dispatch = useDispatch()
  const { userid, profileId } = useParams();
  const isConsultant = details?.role === 'CONSULTANT';
  const [popupMessage, setPopupMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const canEdit = (userDetails?.id) === details?.id
    && (isConsultant
      || permissions?.includes('admin-vendor')
      || [userPermissions.myAccount.view, userPermissions.myAccount.edit].some(
        (Permission) => permissions.includes(Permission),
      ));

  const fetchDetails = async () => {
    if (profileId) {
      const data = await publicProfileDetails(profileId);
      dispatch(setLoading({ loading: false }));
      return data;
    }
    return { data: { result: userDetails } }
  };

  React.useEffect(() => {
    fetchDetails()
      .then(async (res) => {
        if(res?.response?.status === 404 && res?.response?.data?.error === "Profile doesnot exists") {
          navigate('/profileError?error=Profile does not exist');
        }
        let orgInfo = {};
        if (res.data?.result?.role !== 'CONSULTANT') {
            orgInfo = res.data?.result?.organisation || res.data?.result?.organisation_details || {}; 
        }
        
        setDetails({
          ...res?.data?.result,
          about: res?.data?.result?.about_me,
          industry: res?.data?.result?.industry,
          industryExperience: res?.data?.result?.industryExperience,
          username: res?.data?.result?.username,
          references: res?.data?.result?.references,
          email: res?.data?.result?.email,
          firstName: res?.data?.result?.first_name,
          lastName: res?.data?.result?.last_name,
          location: res?.data?.result?.address,
          state: res?.data?.result?.state,
          country: res?.data?.result?.country,
          linkedin: res?.data?.result?.linkdin,
          phone: res?.data?.result?.phone,
          skills: res?.data?.result?.skills,
          website: res?.data?.result?.website,
          profilePhoto: res?.data?.result?.profile_image,
          organisation_details: orgInfo,
          name: `${res?.data?.result?.first_name ?? ""} ${
            res?.data?.result?.last_name ?? ""
          }`.trim(),
          city: res?.data?.result?.city,
          company: res?.data?.result?.company,
          createdAt: res?.data?.result?.created_at,
        });
      })
      .catch((err) => {
        console.log('err', err)
        error('Something went wrong !!!')
      });
  }, [userDetails]);

  React.useEffect(() => {
    // dispatch(setLoading({ loading: true }));
      UserInfo()
        .then((res) => {
          if (res && res.data && res.data) {
            dispatch(setUser(res.data));
            // dispatch(setLoading({ loading: false }));
          }
        })
        .catch((err) => {
          console.error(err);
          // dispatch(setLoading({ loading: false }));
        })
        .finally(() => {
        });
  }, [currentTab, location.pathname]);

  // useMemo(()=>{
  //   if(popupMessage !== "") {
  //     success(popupMessage)
  //   }
  // },[popupMessage])

  const withMarketingWrapper = (children) => (
    <Box
      sx={{
        background: 'linear-gradient(0deg, #00364D 0%, #05699C 100%), #F9FAFB',
        backgroundSize: '100% 180px',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        py: 15,
      }}
    >
      <FullWidthWrapper>{children}</FullWidthWrapper>
    </Box>
  );

  const viewResume = () => {
    window.open(details?.resume, '__blank');
  };

  function Profile() {
    
    const handleDownloadCertificates = (cert) => {
      window.open(cert, '_blank')
    }

    const handleCopyLink = () => {
      const profileLink = `${window.location.protocol}//${window.location.hostname}/${details?.username}`
      navigator.clipboard.writeText(profileLink);
      success("Copied to clipboard");
    }

    const countryName = typeof details?.country === 'string' ? details.country : details?.country?.name;
    const stateName = typeof details?.state === 'string' ? details.state : details?.state?.name;
    const cityName = typeof details?.city === 'string' ? details.city : details?.city?.name;

    return (
      <Grid container sx={{ marginBottom: !isMarketingView ? {xs: "", md:'20vh'} : '50px' }}>
        {!isMarketingView && <Box
          sx={{
            left: 0,
            right: 0,
            height: '200px',
            background: 'linear-gradient(0deg, #00364D 0%, #05699C 100%)',
            position: 'absolute',
            zIndex: 1,
          }}
        />}
        <Grid
          sx={{ zIndex: 10, margin: {xs : "0", md: '0px 80px'}, marginTop: !isMarketingView ? {xs: "40px", md: '120px'} : '30px' }}
          item
          sm={16}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px',
              p: 3,
              boxShadow: '0px 0px 16px 0px rgba(17, 17, 26, 0.05)',
            }}
          >
            {isMarketingView && <BackButton onClick={() => navigate(-1)} label='Back to applicants' />}
            <Grid container>
              <Grid
                item
                md={10}
                sx={{ display: 'flex', flexDirection: {xs: "column", md:'row'}, width: {xs: "100%", md: "unset"}, gap: 3,  }}
              >
                <div className="min-[300px]:relative lg:hidden">
                {canEdit && (
                  <IconButton
                    sx={{display: {xs: "block", md: "none"}, position: {xs: "absolute", md: "unset"}, right: {xs: "-13px", md: "unset"}, top: {xs: "-16px", md: "unset"}}}
                    onClick={() => navigate('/dashboard/myaccount/edit')}
                  >
                    <Edit />
                  </IconButton>
                )}
                </div>
                <Box
                  sx={{ display: {xs:"flex", md: "block"}, justifyContent: {xs: "center", md: "unset"}, width: {xs: "100%", md: '144px'}, height: '144px', marginTop:{xs: 0, md: "18px"}, overflow: 'hidden' }}
                >
                  {details?.profilePhoto ? (
                    <img
                      src={details.profilePhoto}
                      alt="profilephoto"
                      className='w-[144px] h-[144px] object-cover min-[300px]:rounded-full min-[300px]:border-[#3985B6] min-[300px]:border-4 md:border-0 md:rounded-lg'
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '144px',
                        height: '144px',
                        backgroundColor: '#3985B6',
                        display: 'flex',
                        borderRadius: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="h5Bold" color="white">
                        {details?.firstName?.[0]?.toUpperCase()}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Stack direction="row" gap={1} alignItems="center" mb={1} sx={{justifyContent: {xs: "center", md: "start"}}}>
                    <Typography variant="h5Bold">
                      {isConsultant
                        ? `${details?.firstName} ${details?.lastName}`: details?.company}
                    </Typography>
                    {canEdit && (
                      <IconButton
                        sx={{display: {xs: "none", md: "block"}}}
                        onClick={() => navigate('/dashboard/myaccount/edit')}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {details?.isVendorConnected &&  <IconButton
                        onClick={() => (dispatch(updateResourceEditing(true)),navigate(`/dashboard/resources/${profileId}/edit`))}
                      >
                        <Edit />
                      </IconButton>}
                  </Stack>
                  <Stack
                    direction="column"
                    gap={1}
                    sx={{ color: '#6B7280', dd: { width: '140px' } }}
                  >
                    {!!details?.industry_experience && <Stack direction="row" sx={{justifyContent: {xs: "space-between", md:"flex-start"}}}>
                      <Typography variant="body2Normal" component="dd" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                        Experience
                      </Typography>
                      <Typography variant="body2Semibold" component="dt" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                        {details?.industry_experience}
                      </Typography>
                    </Stack>}
                    {isConsultant ? (
                      <>
                      {details?.availability && <Stack direction="row" sx={{justifyContent: {xs: "space-between", md:"flex-start"}}}>
                        <Typography variant="body2Normal" component="dd" sx={{fontSize: {xs: "13px", md: "unset"}}}>Availability</Typography>
                        <Typography variant="body2Semibold" component="dt" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                          {details?.availability}
                        </Typography>
                      </Stack>}
                      </>
                    ) : (
                      <Stack direction="row" sx={{justifyContent: {xs: "space-between", md:"flex-start"}}}>
                        <Typography variant="body2Normal" component="dd">Company Size</Typography>
                        <Typography variant="body2Semibold" component="dt">
                          {details?.organisation_details?.company_size ? `${details?.organisation_details?.company_size} employees` : '10,000+ employees'}
                        </Typography>
                      </Stack>
                    )}
                    {details?.country && <Stack direction="row" sx={{justifyContent: {xs: "space-between", md:"flex-start"}}}>
                      <Typography variant="body2Normal" component="dd" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                        {isConsultant ? 'Location' : 'Headquarters'}
                      </Typography>
                      <Typography variant="body2Semibold" component="dt" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                        {/* {!!details?.address && `${details?.address}, `} */}
                        {cityName},
                        {stateName},
                        {countryName}
                      </Typography>
                    </Stack>}
                    {!!details?.website && (
                      <Stack direction="row" justifyContent="flex-start">
                        <Typography variant="body2Normal" component="dd" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                          {details?.Website}
                        </Typography>
                        <Typography variant="body2Semibold" component="dt" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                          <MuiLink
                            href={
                              details?.website.includes('http')
                                ? details?.website
                                : `http://${details?.website}`
                            }
                            target="_blank"
                            underline="hover"
                            sx={{ color: '#0B85BA' }}
                          >
                            {details?.website}
                          </MuiLink>
                        </Typography>
                      </Stack>
                    )}
                    {!!details?.linkdin && isConsultant && (
                      <Stack direction="row" sx={{justifyContent: {xs: "space-between", md:"flex-start"}}}>
                        <Typography variant="body2Normal" component="dd" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                          Linkedin
                        </Typography>
                        <Typography variant="body2Semibold" component="dt" sx={{fontSize: {xs: "13px", md: "unset"}}}>
                          <MuiLink
                            href={
                              details?.linkdin.includes('http')
                                ? details?.linkdin
                                : `http://${details?.linkdin}`
                            }
                            target="_blank"
                          >
                            <div className='min-[300px]:hidden md:block text-[#0B85BA] cursor-pointer hover:underline'>{details?.linkdin}</div>
                            <div className='min-[300px]:block md:hidden text-[#0B85BA] cursor-pointer hover:underline'>Click here</div>
                          </MuiLink>
                        </Typography>
                      </Stack>
                    )}
                    {/* TODO: Implement company size and availability */}
                  </Stack>
                </Box>
              </Grid>

              {isConsultant ? (
                <Grid
                  item
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: {xs: "row", md:"column"},
                    alignItems: 'flex-end',
                    flexWrap: "wrap",
                    justifyContent: userDetails?.id === details?.id ? 'space-between' : {xs: "space-between", md:"flex-end"},
                  }}
                >
                  {userDetails?.id === details?.id && <div className="flex items-center min-[300px]:pb-1 md:justify-end md:w-full">
                    <Typography className='pr-2 text-[#6B7280] min-[300px]:text-sm'>Profile ID: {details?.username}</Typography>
                    <Button onClick={handleCopyLink} variant="contained" sx={{ padding: '9px', width: "fit-content", minWidth: "fit-content" }} ><CopyOutlined width={"fit-content"} /></Button>
                  </div>}
                  {details?.resume && <Button variant="outlined" onClick={viewResume}>
                      <span className='min-[300px]:hidden md:block'>View / Download Resume</span>
                      <span className='min-[300px]:block md:hidden'>Resume <GetApp /></span>
                    </Button>}
                </Grid>
              ) : (
                <Grid item sx={{width: "37%"}}>
                  <Stack direction="row" sx={{ height: '100%' }} gap={7}>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        border: 'none',
                        mt: 2.5,
                        minWidth: '1px',
                        height: '120px',
                        backgroundColor: 'rgba(76, 78, 100, 0.12)',
                      }}
                    />
                    <Box sx={{ width: '100%' }}>
                      {userDetails?.id === details?.id && <div className="flex items-center justify-end w-full">
                        <Typography className='pr-2 text-[#6B7280]'>Profile ID: {details?.username}</Typography>
                        <Button onClick={handleCopyLink} variant="contained" sx={{ padding: '9px', width: "fit-content", minWidth: "fit-content" }} ><CopyOutlined width={"fit-content"} /></Button>
                      </div>}
                      <div>
                      <Typography
                        variant="body1Bold"
                        component="h3"
                        sx={{ color: '#182743', mb: 1.5 }}
                      >
                        Contact Executive
                      </Typography>
                      {/* TODO: Implement Contact Executive photo */}
                      <Stack direction="row" gap={2}>
                        <Stack direction="column" gap={0.5}>
                          <Typography
                            variant="body2Semibold"
                            color="#182743"
                            component="h3"
                          >
                            {!details?.organisation?.contact_person_name ? `${details?.firstName || ''} ${details?.lastName || ''}` : details?.organisation?.contact_person_name || ''}
                          </Typography>
                          <Typography variant="body2Normal" >
                            {isLoggedIn && details?.phone}
                          </Typography>
                          <Typography variant="body2Normal" >
                            {!details?.organisation?.contact_person_email ? details?.email : details?.organisation?.contact_person_email || ''}
                          </Typography>
                        </Stack>
                      </Stack>
                      {/* TODO: contact us button */}
                      {/* <Button
                        variant="contained"
                        fullWidth
                      >
                        Contact us
                      </Button> */}
                      </div>
                    </Box>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid sx={{ zIndex: 15, margin: {xs: "0" ,md: '0px 80px'}, paddingLeft: "0 !important", width: {xs:"100%", md:"85%"} }} item container sm={16}>
          <Grid
            item
            sm={16}
            md={isConsultant ? 10 : 16}
            sx={{ height: {xs: "fit-content", md: '100%'}, width: {xs : "100%", md: "unset"} }}
          >
            <ProfileTabs details={details} setDetails={setDetails} currentTab={currentTab} setCurrentTab={setCurrentTab} referenceAPI={referenceAPI} setReferenceAPI={setReferenceAPI} setPopupMessage={setPopupMessage}/>
          </Grid>
          {isConsultant && (
            <Grid item sm={16} md={6} sx={{width: {xs: "100%", md: "unset"}}}>
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  p: 3,
                  boxShadow: '0px 0px 16px 0px rgba(17, 17, 26, 0.05)',
                  height: '100%',
                }}
              >
                <Typography
                  variant="body2Bold"
                  component="h3"
                  className='border-b'
                  sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: '2px' }}
                >
                  <VerifiedIcon className="text-[#006A98]" />
                  Skills
                </Typography>
                {(details?.skills_with_rating?.length === 0 && details?.skills?.length === 0) && (
                  <Typography
                    variant="captionNormal"
                    component="p"
                    
                  >
                    No Skills Added
                  </Typography>
                )}
                {details?.skills_with_rating?.length > 0 && <Typography variant="captionNormal" component='p' sx={{ marginBottom: '10px' }}>Primary Skills</Typography>}
                <div className='grid grid-cols-2 gap-4'>
                  {(details?.skills_with_rating || []).map((skill, index) => (
                    <div className="mb-4">
                      <div className="mb-2">
                        {/* <BlurOnIcon className="text-[#006A98]" sx={{ fontSize: '10px' }} /> */}
                        <Typography variant="body2Semibold" component="h3" className='font-bold text-lg'>{skill.name}</Typography>
                        <Typography variant="captionNormal" component='p'>{skill.status}</Typography>
                      </div>
                      <div className="bg-gray-300 rounded-full h-2">
                        <div className={`relative h-full rounded-full bg-[#3985B6]`} style={{ width: `${skill.rating * 10}%` }}>
                          <div className='w-[20px] h-[20px] rounded-full bg-[#3985B6] flex justify-center items-center text-white absolute top-[-6px] right-0'>
                            <span className='text-[10px]'>{skill.rating}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {details?.skills?.length > 0 && <Typography variant="captionNormal" component='p' sx={{ marginBottom: '10px' }}>
                  {details?.skills_with_rating?.length > 0 ? 'Other Skills' : ''}
                </Typography>}
                <div className="grid grid-cols-1 min-[300px]:grid-cols-2 lg:grid-cols-3 gap-4">
                  {(details?.skills || []).map((skill, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <BlurOnIcon className="text-[#006A98]" sx={{ fontSize: '10px' }} />
                      <p className="text-xs font-normal opacity-80 line-clamp-2" title={skill}>
                        {skill}
                      </p>
                    </div>
                  ))}
                </div>

                <Typography
                  variant="body2Bold"
                  component="h3"
                  
                  className='border-b'
                  sx={{ mb: 1, mt: 4 }}
                >
                  <MilitaryTechIcon className="text-[#006A98]" sx={{ fontSize: '30px' }} />
                  Certifications
                </Typography>
                {!(details?.userStrengths?.certs || details?.certificates)?.length && (
                  <Typography
                    variant="captionNormal"
                    component="p"
                    
                  >
                    No Certifications Added
                  </Typography>
                )}
                  {(details?.userStrengths?.certs || details?.certificates || []).map((cert) => (
                    <div className='flex items-start p-4 pr-0 pl-0'>
                      <BlurOnIcon className="text-[#006A98]" sx={{ fontSize: '10px', marginTop: '4px' }} />
                      <div className="flex flex-col pl-4 w-[70%]">
                        <Typography variant='body2Semibold' component="h3"  className='pr-2 cursor-pointer'>{cert?.title}</Typography>
                        {cert?.organization && <div className='flex items-center text-xs'><strong className=' pr-2'>Issuing Organization:</strong>{cert?.organization} {!cert?.credential_id && cert?.url && <Button onClick={() => handleDownloadCertificates(cert?.url)} sx={{ background: "none", minWidth: "40px", ml: "8px", padding: "0", "&:hover": { background: "none" } }} startIcon={<CloudDownloadIcon sx={{ color: "#3985B6" }} />} />}</div>}
                        {cert?.credential_id && <div className="flex items-center">
                          <span className='text-xs'>Credential ID: {cert?.credential_id}</span>
                          {cert?.url && <Button onClick={() => handleDownloadCertificates(cert?.url)} sx={{ background: "none", minWidth: "40px", ml: "8px", padding: "0", "&:hover": { background: "none" } }} startIcon={<CloudDownloadIcon sx={{ color: "#3985B6" }} />} />}
                        </div>}
                      </div>
                    </div>
                  ))}
                {children}
              </Box>
            </Grid>
          )}
        </Grid>
        {details?.profile_video_url && <ShowProfileVideo profile_video_url={details?.profile_video_url} open={open} handleClose={handleClose} />}
      </Grid>
    );
  }
  if (loading) {
    return (
      <Box sx={{ width: '100%', pt: 10, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return userid ? withMarketingWrapper(<Profile />) : <Profile />;
}
