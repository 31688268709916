import { useEffect, useMemo, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack, Divider, Box, Typography } from "@mui/material";
import { fetchSingleTask, updateTask } from "./task.api";
import moment from "moment";
import { taskStatueses } from "./constant.task";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const { default: MuiModal } = require("Components/Common/MuiModal");

const ViewTaskModal = ({ open, closeModal, fetchtask, editHandler, taskId }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [taskDetails, setTaskDetails] = useState({ status: "TODO" });

  const fetchTaskDetails = async () => {
    const resp = await fetchSingleTask(taskId || queryParams.get("id"));
    if (resp && resp.success) {
      return setTaskDetails(resp.data);
    }
    closeModal();
  };

  const handleChange = (name, value) =>
    setTaskDetails({ ...taskDetails, [name]: value });

  useEffect(() => {
    if (open) fetchTaskDetails();
    else {
      setTaskDetails({ status: "TODO" });
      fetchtask();
    }
  }, [open]);

  const statusChange = async (name, value) => {
    handleChange(name, value);
    await updateTask(taskDetails.id, { status: value });
  };
  
  const createdByName = useMemo(()=> {
    if(taskDetails?.assignee_details?.role !== "CONSULTANT") {
      return taskDetails?.assignee_details?.company;
    } else {
      return `${taskDetails?.assignee_details?.firstName} ${taskDetails?.assignee_details?.lastName}`;
    }
  },[taskDetails?.assignee_details]);
  
  return (
    <MuiModal
      styling={{ height: "60vh", overflowY: "scroll", paddingTop: "30px" }}
      className="max-w-4xl"
      open={open}
      onCancel={closeModal}
    >
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Box sx={{ width: "60%" }}>
          <Typography
            variant="cardHeader"
            sx={{ inlineSize: "100%", overflowWrap: "break-word !important" }}
          >
            {taskDetails?.name}
          </Typography>
          <Box sx={{ marginTop: "10px" }}>
            <Typography variant="componentsButtonSmallBold">
              Description
            </Typography>
            <Box
              sx={{
                fontSize: "12px",
                inlineSize: "100%",
                overflowWrap: "break-word !important",
              }}
              dangerouslySetInnerHTML={{ __html: taskDetails?.description }}
            ></Box>
          </Box>
          {taskDetails?.file_path?.length > 0 && <Box marginTop={"40px"}>
            <Typography variant="componentsButtonSmallBold">
              Attachment
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                marginTop: "10px",
              }}
            >
              {taskDetails?.file_path?.map((file, index) => {
                const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file);
                const isPDF = /\.pdf$/i.test(file);
                if (isImage) {
                  return (
                    <img
                      key={index}
                      src={file}
                      onClick={() => window.open(file, "_blank")}
                      alt={`Attachment ${index + 1}`}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  );
                } else if (isPDF) {
                  return (
                    <Box
                      key={index}
                      onClick={() => window.open(file, "_blank")}
                      sx={{ width: '60px', fontSize: '16px', cursor: "pointer", textAlign: "center", padding: '10px', borderRadius: '10px' }}
                    >
                      <FilePresentIcon /> 
                      <Typography variant="caption" component="p">
                        PDF
                      </Typography>
                    </Box>
                  );
                } else {
                  return (
                    <div key={index}>
                      <Typography variant="caption" component="p">
                        Unsupported File Type
                      </Typography>
                    </div>
                  );
                }
              })}
            </Box>
          </Box>}
        </Box>
        <Box sx={{ width: "30%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <Select
                sx={{
                  background: "#0B85BA",
                  color: "white",
                  marginBottom: "10px",
                  width: "50%",
                  height: "30px",
                  border: "unset",
                  outline: "unset",
                }}
                id="demo-simple-select"
                value={taskDetails?.status}
                label="status"
                onChange={(e) => statusChange("status", e.target.value)}
              >
                {Object.keys(taskStatueses).map((status, k) => {
                  return (
                    <MenuItem
                      sx={{ fontSize: "12px" }}
                      value={taskStatueses[status]}
                    >
                      {taskStatueses[status]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <DriveFileRenameOutlineIcon
              sx={{ cursor: "pointer" }}
              onClick={() => editHandler(taskDetails)}
            />
          </Box>
          <Box
            sx={{
              border: "1px solid #DFE1E6",
              borderRadius: "8px",
              height: "80%",
              padding: "10px",
            }}
          >
            <Typography variant="componentsButtonSmallBold">Details</Typography>
            <Divider sx={{ marginBottom: "20px" }} />
            <Stack
              flexDirection={"row"}
              marginBottom={"20px"}
              justifyContent={"flex-start"}
              gap={"60px"}
            >
              <Typography
                variant="componentsButtonSmall"
                sx={{ fontWeight: 600, minWidth: "30%" }}
              >
                Assignee:
              </Typography>
              <Typography variant="componentsButtonSmall">
                {taskDetails?.assign_to?.name || ""}
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              marginBottom={"20px"}
              justifyContent={"flex-start"}
              gap={"60px"}
            >
              <Typography
                variant="componentsButtonSmall"
                sx={{ fontWeight: 600, minWidth: "30%" }}
              >
                Start Time:
              </Typography>
              <Typography variant="componentsButtonSmall">
                {moment(taskDetails?.start_date).format("DD MMM, YYYY")}
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              marginBottom={"20px"}
              justifyContent={"flex-start"}
              gap={"60px"}
            >
              <Typography
                variant="componentsButtonSmall"
                sx={{ fontWeight: 600, minWidth: "30%" }}
              >
                End Time:
              </Typography>
              <Typography variant="componentsButtonSmall">
                {taskDetails?.end_date && moment(taskDetails?.end_date).format("DD MMM, YYYY")}
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              marginBottom={"20px"}
              justifyContent={"flex-start"}
              gap={"60px"}
            >
              <Typography
                variant="componentsButtonSmall"
                sx={{ fontWeight: 600, minWidth: "30%" }}
              >
                Created By:
              </Typography>
              <Typography variant="componentsButtonSmall">
                {createdByName}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </MuiModal>
  );
};

export default ViewTaskModal;
