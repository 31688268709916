import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import TableCell from '@mui/material/TableCell';
import { Avatar, Button, CircularProgress, Typography } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import {
  Input, Switch,
} from 'antd';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';
import PublicTimesheetProvider, {
  usePublicTimesheetProvider,
} from 'Providers/Public/PublicTimesheet.provider';
import ShareNavbar from '../../Components/ShareNavbar';
import { stringAvatar } from '../../Utils/avatar';
import { timesheetStatus } from '../../Utils/constants';
import CustomTable from '../../Components/Table';
import { selectIsUserLoggedIn, selectUserRole } from '../../Redux/Feature/user';
import VerifyPinModal from './VerifyPinModal';
import { useDispatch } from 'react-redux';
import { setTimesheetToken } from 'Redux/Feature/createTimesheetSlice';
import { weekNames } from 'Utils/termsandConditions';
import ApproveRejectModal from './ApproveRejectModal';


function CustomSummaryRow({ row }) {

  const renderWeeks = (item, index) => {
    return(
      <TableCell key={index} width="10%" component="th" scope="row">
        {row?.days?.[item.toLowerCase()]}
      </TableCell>
    )
  }
  
  return (
    <TableRow
      key={row.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell width="14%" component="th" scope="row">
        {moment.utc(row.start).format('DD, MMMM')} - {moment.utc(row.end).format('DD, MMMM')}
      </TableCell>
      {weekNames.map(renderWeeks)}
      <TableCell width="10%">
        {row?.hours}
      </TableCell>
    </TableRow>
  );
}

function ShareTimesheet() {
  // Initial Setup
  const { token } = useParams();
  const [emailFromToken, setEmailFromToken] = useState(null);
  const [linkId, setLinkId] = useState(null);
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const userType = useSelector(selectUserRole);
  const [openModal, setModalOpen] = useState(true);
  const [weeklySummary, setWeeklySummary] = useState(false);
  const [timesheetId, setTimeSheetId] = useState([]);
  const [modal, setModal] = React.useState({
    open: false,
    type: timesheetStatus.approved,
  });
  const dispatch = useDispatch();

  const isEmail = useMemo(()=> {
    return !!emailFromToken;
  },[!!emailFromToken]);

  function CustomRow({ row }) {
    const handleSetTimesheetId = () => {
      navigate(`/timesheet/timesheet-details/view-details/${row?.id}`)
    }
    return (
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell width="18%" component="th" scope="row">
          {row?.consultant ? `${row?.consultant?.firstName} ${row?.consultant?.lastName}` : "Resource Name"}
        </TableCell>
        <TableCell width="30%" component="th" scope="row">
          {moment.utc(row.start).format('ddd, MMMM DD, YYYY')} - {moment.utc(row.end).format('ddd, MMMM DD, YYYY')}
        </TableCell>
        <TableCell width="15%">
          {row?.hours?.toString().padStart(2, '0') || 0}
          :
          {row?.minutes?.toString().padStart(2, '0') || '00'}
        </TableCell>
        <TableCell width={"15%"}>{row?.status}</TableCell>
        {/* <TableCell className="truncate max-w-[250px] grow">{row?.description}</TableCell> */}
        <TableCell width={"15%"} onClick={handleSetTimesheetId} sx={{ color: "#0B85BA", cursor: "pointer"}}>View Details</TableCell>
        {isEmail && row?.status === 'SUBMITTED' ? <TableCell scope="row" width={"22%"} sx={{ display: "flex" ,color: "#0B85BA", cursor: "pointer"}}>
         <Button
            variant='contained'
            sx={{
              marginRight: "1rem",
              backgroundColor: "#2E7D32",
              "&:hover" : {
                backgroundColor: "#2E7D32"
              }
            }}
            className="bg-[#2EBAA3] text-white px-2 py-1.5 rounded-md border border-[#2EBAA3] w-[100px]"
            onClick={() => {setModal({
              open: true,
              type: timesheetStatus.approved,
            }); setTimeSheetId([row?.id]);}}
          >
            Approve
          </Button>
          <Button
            variant='contained'
            sx={{
              backgroundColor: "#ff0000",
              "&:hover" : {
                backgroundColor: "#ff0000"
              }
            }}
            className="px-2 py-1.5 text-[#139588] rounded-md border border-[#139588] w-[100px]"
            onClick={() => {setModal({
              open: true,
              type: timesheetStatus.rejected,
            }); setTimeSheetId([row?.id]);}}
          >
            Reject
          </Button>
        </TableCell> : <TableCell scope="row" width={"22%"}></TableCell>}
      </TableRow>
    );
  }

  useEffect(() => {
    if (token) {
      if (isLoggedIn && userType !== 'CONSULTANT') {
        navigate(`/dashboard/timesheetscard/timesheet/${token}`);
      }
      const secret = 'rJK7?E@KynQ!5F39C-gy';
      const wordArray = CryptoJS.enc.Base64url.parse(token);
      const string = wordArray.toString(CryptoJS.enc.Base64);
      const bytes = CryptoJS.AES.decrypt(string, secret);
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      !!data.pin && setPinRequired(true);
      dispatch(setTimesheetToken(token));
      setEmailFromToken(data.email);
      setLinkId(data.linkId);
    }
  }, [token]);

  // PIN
  const [isMounted, setIsMounted] = useState(false);
  const [pinRequired, setPinRequired] = useState(false);
  const [pin, setPin] = useState('');

  // Check if pin is required
  const { isPinRequired, verifyPin, fetchTimesheetDetails, timesheets } = usePublicTimesheetProvider();

  useEffect(() => {
    if(weeklySummary) {
      const ids = timesheets?.map((item) => item?.id);
      setTimeSheetId(ids);
    } else {
      setTimeSheetId([]);
    }
  },[weeklySummary])
    
  useEffect(() => {
    if (timesheets) {
      const ids = timesheets.map((t) => t.id);
      fetchTimesheetDetails(ids);
      dispatch(setTimesheetToken(token));
    } 
  }, [timesheets, token]);

  useEffect(() => {
    if (linkId) {
      isPinRequired(linkId).then((res) => {
        if (res !== null) {
          setIsMounted(true);
        } else {
          Swal.fire('Error', 'Something went wrong', 'error');
        }
      });
    }
  }, [linkId]);

  // Timesheet status
  const [status, setStatus] = useState(null);
  const [summaryWeek, setSummaryWeek] = useState();
  const [approvalDetails, setApprovalDetails] = useState(null);

  useEffect(() => {
    if (timesheets?.every((t) => t.status === timesheets[0].status)) {
      setStatus(timesheets[0].status);
      setApprovalDetails({
        approvalDate: timesheets[0].approvalDate,
        approvedByExternal: timesheets[0].approvedByExternal,
        approved_by: timesheets[0].approvedBy,
        clientComments: timesheets[0]?.clientComments,
      });
    }
  }, [timesheets]);

  // Timesheet
  const { timesheetMaster, days } = usePublicTimesheetProvider();

  const weeklyTotalHours = (data) => {
    let totalHours = 0; let
      minutes = 0;

    if (data) {
      for (let i = 0; i < data.length; i++) {
        minutes += Number(data[i].minutes);
        totalHours += Number(data[i].hours);
      }
      if (minutes >= 60) {
        totalHours += Number(minutes / 60);
        minutes %= 60;
      }

      return { totalHours, minutes };
    }
  };

  function convertToHHMM(time) {
    const [hour, minute] = time.split(':');
    const formattedHour = hour.padStart(2, '0');
    const formattedMinute = minute.padStart(2, '0');
    return `${formattedHour}:${formattedMinute}`;
  }

  useEffect(() => {
    const newDaysArray = [];

    for (let i = 0; i < days.length; i += 7) {
      const { totalHours, minutes } = weeklyTotalHours(days);

      newDaysArray.push({
        id: days[i].id,
        week: `${moment(days[i]?.date).format('LL')} - ${moment(days[i + 6]?.date).format('LL')}`,
        hours: convertToHHMM(`${totalHours || '00'}:${minutes || '00'}`),
        description: '',
      });
    }
    setSummaryWeek(newDaysArray);
  }, [days]);

  const headCells = () => {
    const headerArr = ['Resource Name','Range', 'Hours', 'Status', 
      // 'Description', 
      'View Details', 'Action'].map((label) => ({ label }));
    if(isEmail) {
      return headerArr;
    } else {
      return headerArr.filter((item)=> {return item.label !== 'Action'})
    }
  }
  const summaryHeadCells = ['Range', ...weekNames.map((item)=>(item?.slice(0,3))), 'Hours' ].map((label) => ({ label }));

  const totaldaysinTimesheet = useMemo(()=> {
    const daysData = days?.map((item) => {
      let count = 0;
      Object.values(item?.days).map((item)=> {
        if(!!item) {
          count = count + 1;
        }
      })
      return count;
    });
    return daysData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    },0)
  },[days]);

  const daysTimesheetTableInfo = useMemo(() => {
    return {
      consultant: days?.[0]?.consultant_name,
      job_name: days?.[0]?.job_name,
      month: moment.utc(days?.[0]?.date).format('MMMM, YYYY'),
      totaldays: "" 
    };
  },[days]);

  return (
    <div
      style={{ background: '#F7F7F7' }}
      className="flex flex-col min-h-screen"
    >
      {/* <ShareNavbar /> */}
      {isMounted ? (
        <div className="flex grow">
          <div
            className={`flex flex-col grow p-8 ${pinRequired ? 'items-center justify-center' : ''
            }`}
          >
            {pinRequired ? (
              <VerifyPinModal open={openModal} setOpen={setModalOpen} props={{ pin, setPin, verifyPin, setPinRequired, token, navigate }} />
            ) : (
              <>
                {/* Header */}
                <div className="flex justify-between mb-2">
                  <div className="flex items-center">
                    <span className="text-2xl font-semibold">Timesheet</span>
                  </div>
                  <div className="space-x-2 py-4">
                    {status === timesheetStatus.approved
                      || status === timesheetStatus.rejected ? (
                        <div
                          className="px-2 py-1 rounded-[5px] font-[18px] leading-[22px]"
                          style={
                          status === timesheetStatus.approved
                            ? { backgroundColor: '#2EBAA34D', color: '#2EBAA3' }
                            : { background: '#FF33334D', color: '#FF3333' }
                        }
                        >
                          {status?.[0]}
                          {status?.slice(1)?.toLowerCase()}
                        </div>
                      ) : emailFromToken && weeklySummary ? (
                        <>
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: "#ff0000",
                              "&:hover" : {
                                backgroundColor: "#ff0000"
                              }
                            }}
                            className="px-2 py-1.5 text-[#139588] rounded-md border border-[#139588] w-[100px]"
                            onClick={() => setModal({
                              open: true,
                              type: timesheetStatus.rejected,
                            })}
                          >
                            Reject
                          </Button>
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: "#2E7D32",
                              "&:hover" : {
                                backgroundColor: "#2E7D32"
                              }
                            }}
                            className="bg-[#2EBAA3] text-white px-2 py-1.5 rounded-md border border-[#2EBAA3] w-[100px]"
                            onClick={() => setModal({
                              open: true,
                              type: timesheetStatus.approved,
                            })}
                          >
                            Approve
                          </Button>
                        </>
                      ) : <></>}
                  </div>
                </div>

                {weeklySummary && <>
                  <div className="flex justify-between mb-2">
                    <div className="flex">
                      <Typography variant='defaultFont' sx={{fontWeight: "bolder"}}>Job: </Typography>
                      <Typography variant='defaultFont' sx={{paddingLeft: "8px"}}>{daysTimesheetTableInfo.job_name}</Typography>
                    </div>
                    <div className="flex">
                      <Typography variant='defaultFont' sx={{fontWeight: "bolder"}}>Consultant: </Typography>
                      <Typography variant='defaultFont' sx={{paddingLeft: "8px"}}>{daysTimesheetTableInfo.consultant}</Typography>
                    </div>
                  </div>
                  <div className="flex justify-between mb-2">
                    <div className="flex">
                      <Typography variant='defaultFont' sx={{fontWeight: "bolder"}}>Month: </Typography>
                      <Typography variant='defaultFont' sx={{paddingLeft: "8px"}}>{daysTimesheetTableInfo.month}</Typography>
                    </div>
                    <div className="flex">
                      <Typography variant='defaultFont' sx={{fontWeight: "bolder"}}>Total Working Days: </Typography>
                      <Typography variant='defaultFont' sx={{paddingLeft: "8px"}}>{totaldaysinTimesheet} days</Typography>
                    </div>
                  </div>
                </>}

                {/* Consultant */}
                {timesheetMaster?.consultant ? (
                  <div className="space-y-4">
                    <div
                      style={{ border: '1px solid #D9D9D9' }}
                      className="flex flex-col bg-white p-4 rounded-md"
                    >
                      <div className="flex flex-row space-x-4">
                        <span style={{ fontWeight: '500' }} className="">
                          Title
                        </span>
                        <span>
                          {timesheetMaster?.job?.name
                            ?? timesheetMaster?.job?.name
                            ?? '-'}
                        </span>
                      </div>
                      <div className="flex flex-row space-x-4">
                        <span style={{ fontWeight: '500' }} className="">
                          Description
                        </span>
                        <span>
                          {timesheetMaster?.job?.description
                            ? timesheetMaster?.job?.description
                            : ''}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        border: '1px solid #D9D9D9',
                        borderRadius: '8px',
                      }}
                      className="flex flex-row justify-between bg-white rounded-sm p-4 mb-8"
                    >
                      <div className="flex flex-col">
                        <span className="font-medium">Consultant</span>
                        <div className="flex flex-col bg-white flex-1 rounded-b-lg mt-2">
                          <div
                            style={{
                              width: '287px',
                              height: '42px',
                            }}
                            className="flex flex-row items-center space-x-6 bg-[#2EBAA3] rounded-lg"
                          >
                            <Link
                              to={`/consultant/profile/${timesheetMaster?.consultant
                                && timesheetMaster.consultant.id
                              }`}
                            >
                              <Avatar
                                className="ml-2"
                                style={{
                                  height: '35px',
                                  width: '35px',
                                }}
                                {...stringAvatar(
                                  `${timesheetMaster?.consultant
                                  && `${timesheetMaster.consultant.firstName
                                  } ${
                                    timesheetMaster.consultant.lastName}`
                                  }`,
                                )}
                              />
                            </Link>
                            <span className="text-white">
                              {timesheetMaster?.consultant
                                && timesheetMaster.consultant.firstName}
                              {' '}
                              {timesheetMaster?.consultant
                                && timesheetMaster.consultant.lastName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="flex flex-col">
                  {status === timesheetStatus.approved
                    || status === timesheetStatus.rejected ? (
                      <>
                        <div className="flex mt-4 mb-1 space-x-2">
                          <span className="font-semibold">{status === timesheetStatus.approved ? 'Approved By:' : 'Rejected By:'}</span>
                          <span>
                            {approvalDetails?.approvedByExternal
                            ?? approvalDetails?.approvedBy?.email}
                          </span>
                        </div>

                        <div className="flex my-1 space-x-2">
                          <span className="font-semibold">Approved On:</span>
                          <span>
                            {moment(
                              approvalDetails?.approvalDate ?? undefined,
                            ).format('dddd, MMMM DD, HH:MM')}
                          </span>
                        </div>

                        <div className="flex mt-1 mb-2 space-x-2">
                          <span className="font-semibold">Comments:</span>
                          <span>{approvalDetails?.clientComments}</span>
                        </div>
                      </>
                    ) : (
                      <div className="mb-6" />
                    )}

                  <div className="flex font-semibold mb-4 space-x-2">
                    <span>Total Hours: </span>
                    <span className="text-[#139588]">
                      {!!summaryWeek ? summaryWeek?.[0]?.hours : 0}
                    </span>
                  </div>
                </div>

                <div className="mb-4 flex flex-row space-x-2 items-center">
                  <span className="border-2 border-[#139588] py-1 px-4 text-[#139588] text-xs">Week</span>
                  <Switch
                    className="w-8 bg-gray-700"
                    onClick={() => {
                      setWeeklySummary(!weeklySummary);
                    }}
                  />
                  <span className="border-2 border-[#139588] py-1 px-4 text-[#139588] text-xs">Day</span>
                </div>

                {/* Timesheet Details */}
                <CustomTable
                  headCells={weeklySummary ? summaryHeadCells : headCells()}
                  // rows={weeklySummary ? summaryWeek : timesheets?.length !=0 ? timesheets : days}
                  rows={weeklySummary ? days : timesheets}
                  CustomRow={weeklySummary ? CustomSummaryRow : CustomRow}
                  showPagination={false}
                  showDensePadding={false}
                  showToolbar={false}
                />

                {/* Modal */}
                <ApproveRejectModal modal={modal} setModal={setModal} emailFromToken={emailFromToken} setApprovalDetails={setApprovalDetails} timesheetId={timesheetId} />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col grow items-center justify-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

function ShareTimesheetWrapper() {
  return (
    <PublicTimesheetProvider>
      <ShareTimesheet />
    </PublicTimesheetProvider>
  );
}

export default ShareTimesheetWrapper;
