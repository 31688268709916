import FileCopyIcon from '@mui/icons-material/FileCopy';
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getShareableLink } from "../../services/vendors";
import { roles } from "../../Utils/constants";
import { useAlertProvider } from 'Providers/util/Alert';

export default function ShareableInviteField({ type }) {
  const [shareableLink, setShareableLink] = useState("");
  const { success } = useAlertProvider()

  function copyShareableLink() {
    navigator.clipboard.writeText(shareableLink);
    success('Copied to clipboard')
  }

  async function fetchShareableLink() {
    try {
      const userId = localStorage.getItem("userId");
      const params = { type: type ?? roles.consultant };

      const { data } = await getShareableLink(userId, { params });
      setShareableLink(data.data);
    } catch (error) {
      console.error("fetchShareableLink error :>> ", error);
    }
  }

  useEffect(() => {
    fetchShareableLink();
  }, []);

  return (
    <div className="shareable-invite-field">
      <div className="flex flex-col mt-2 justify-center">
        <div className="flex relative">
          <TextField
            fullWidth
            label='Invitation Link'
            readOnly
            value={shareableLink}
            style={{ cursor: "default" }}
          />
          <FileCopyIcon onClick={copyShareableLink} className="absolute right-2 top-8 cursor-pointer text-2xl text-[#0B85BA]" />
        </div>
      </div>
    </div>
  );
}
