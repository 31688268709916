import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal';
import Button from 'Components/Common/semantic_tags/Button';
import { useJobProvider } from 'Providers/Client/Jobs';
import { useProjectProvider } from 'Providers/Client/Project';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserRole } from 'Redux/Feature/user';
import { bgColor } from 'Utils/constants';
import * as XLSX from 'xlsx';

const getSheetKeys = (isClient = false) => {
  return [
    {
      "Job Title*": "Software Engineer",
      "Job Description(Upto 3000 Words) *": "Detailed Job Description. ",
      "Job Category(By Default IT)*": "IT(Information Techology",
      "Skills(Comma Seperated Values - skill1,skill2)*": "Java,Python,C++,JavaScript,software development,problem-solving,code reviews,version control (Git),agile methodologies,debugging,scalable applications,clean code,cross-functional collaboration,software design,efficient coding",
      "Currency (INR or USD)*": "INR",
      "Rate Type (Hourly or Monthly)*": "MONTHLY",
      "Rate": 50000,
      "Location(Remove, Onsite or Hybrid)*": "Remote",
      "Job Start Date (DD\/MM\/YYYY)": "07\/01\/2024",
      "Record Timesheet Checkin\/Checkout time (Yes\/No)": "Yes",

      ...(isClient && {
        "Vendor Organization Name": "Sample Tech Solutions Pvt. Ltd. ",
        "Vendor First Name": "Robin",
        "Vendor Last Name": "Smith",
        "Vendor Email": "robin.smith@domain.com",
        "Consultant First Name": "Alex",
        "Consultant Last Name": "Smith",
        "Consultant Email ID": "alex.smith@domain.com",
      }),
      ...(!isClient && {
        "Consultant First Name": "Alex",
        "Consultant Last Name": "Smith",
        "Consultant Email ID": "alex.smith@domain.com",
        "Client Organization Name": "Sample Tech Solutions Pvt. Ltd. ",
        "Client First Name": "Robin",
        "Client Last Name": "Smith",
        "Client Email": "robin.smith@domain.com"
      })
    }
  ]
}

function BulkJobUploadModal({ open, setModalOpen }) {
  const [file, setFile] = React.useState(null);
  const [projectId, setProjectId] = React.useState("");
  const ref = React.useRef(null);
  const { uploadBulkJob } = useJobProvider();
  const { projects } = useProjectProvider();
  const { isClient } = useSelector(selectUserRole);

  const handleFileUploadRef = () => {
    ref.current?.click();
  }

  const removeSelectedFile = () => {
    setFile(null);
  };

  const uploadHandler = () => {
    uploadBulkJob({
      projectId,
      file
    })
    setTimeout(() => { setModalOpen(false) }, 2000)
  }

  const handleFileChange = (event) => setFile(event.target.files[0]);

  const projectList = useMemo(() => projects.map((item) => ({ label: item?.title, value: item?.id })), [projects]);

  const downloadHandler = () => {
    const getRowData = getSheetKeys(isClient);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(getRowData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const link = document.createElement('a');

    link.download = 'template.xlsx';

    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <MuiModal open={open} onCancel={() => setModalOpen(false)} title='Import Bulk Job'>
      <Box sx={{ paddingBottom: '20px' }}>
        <Typography
          color="#182743"
          component="h3"
          variant="body1Bold"
          sx={{ mb: 2.5 }}
        >
          Step 1: Download example .xlsx
        </Typography>
        <div className="py-2 mt-4 mb-4">
          <Typography className="text-sm text-gray-600 mb-2">
            Please use the template provided below to ensure your data is formatted correctly. Download the template, fill in your data, and then upload it to our system.
          </Typography>
          <span onClick={downloadHandler} className="py-2 mt-4 text-[#0B85BA] flex items-center cursor-pointer">
            <DownloadForOfflineIcon className="mr-2" /> Download Template
          </span>
        </div>
        <Typography
          color="#182743"
          component="h3"
          variant="body1Bold"
          sx={{ mb: 2.5 }}
        >
          Step 2: Select Project
        </Typography>
        <Autocomplete
          sx={{ width: "50%", paddingBottom: "1.5rem" }}
          clearIcon={false}
          componentsProps={{
            popper: {
              style: {
                zIndex: 99999,
                width: "284px"
              }
            }
          }}
          getOptionLabel={(option) => (option?.label ? option.label : option)}
          onChange={(_, v) => { setProjectId(v?.value) }}
          options={projectList}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Selecy your project'
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
        <Typography
          color="#182743"
          component="h3"
          variant="body1Bold"
          sx={{ mb: 2.5 }}
        >
          Step 3: Upload updated .xlsx file
        </Typography>
        <Button
          onClick={handleFileUploadRef}
          title={<><CloudUploadIcon /> Upload .xlsx <input style={{ display: "none" }} ref={ref} onChange={handleFileChange} type="file" accept=".xlsx" /></>}
        />
        {file && (
          <Typography sx={{ mb: 2.5, marginTop: '20px' }} display="flex" gap={10} alignItems="center">
            File Uploaded
            <Typography variant="body2Bold" sx={{ marginLeft: '8px' }}>{file?.name}</Typography>
            <CancelIcon onClick={removeSelectedFile} sx={{ cursor: "pointer" }} />
          </Typography>
        )}
        {file && (
          <Button
            onClick={uploadHandler}
            parentClassName='!w-full justify-end mt-2'
            title={<><CloudUploadIcon />Upload Bulk Job</>}
          />
        )}
      </Box>
    </MuiModal>
  )
}

export default BulkJobUploadModal;