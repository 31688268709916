import themeBase from "../themeBase";

export const MuiDividerOverride = {
  styleOverrides: {
    root: {
      minWidth: themeBase.spacing(10),
      backgroundColor: '#4C4E641F',
    },
  },
};
