import api, { apiV2 } from "Utils/api"

export const documentEndpoint = {
    get: '/documentshare',
    post: '/documentshare'
}

export const getAllDocuments = async (showData = 'INCOMING') => {
    try {
        const resp = await api.get(`${documentEndpoint.get}?showData=${showData}`)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.data };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const getSharedDocuments = async (document_id) => {
    try {
        const resp = await api.get(`${documentEndpoint.get}/getDocShareListByUser?doc_id=${document_id}`)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.data };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const updateDocument = async (id, payload) => {
    try {
        const resp = await api.patch(`${documentEndpoint.get}/${id}`, payload)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.data };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const shareDocument = async (data) => {
    try {
        const resp = await api.post(`${documentEndpoint.post}`, data)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.data };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const fetchShareToList = async (role = 'CONSULTANT', type = 'vendor', user_id, resourceSearch) => {
    let resp = { success: true, data: [] }
    if ((type === 'partner' || type === 'vendor') && ['VENDOR', 'CLIENT', 'CONSULTANT'].includes(role)) {
        const response = await apiV2.get(`/vendors?for=current&size=100`)
        if (response && response.data) {
            const prepareData = (response?.data?.data?.result || []).map(i => {
                if (i?.vendor1_id === user_id) {
                    return { ...i, firstName: i?.vendor2?.firstName, lastName: i?.vendor2?.lastName, company: i?.vendor2?.company, resource_id: i.vendor2_id }
                } else if (role === 'VENDOR') {
                    return { ...i, firstName: i?.vendor1?.firstName, lastName: i?.vendor1?.lastName, company: i?.vendor1?.company, resource_id: i.vendor1_id }
                } else {
                    return { ...i, firstName: i?.vendor?.firstName, lastName: i?.vendor?.lastName, resource_id: i.vendor_id }
                }
            })
            resp = { success: true, data: prepareData }
        }
    } else if (type === 'client' && ['VENDOR'].includes(role)) {
        const response = await apiV2.get(`/clients?for=current&size=100`)
        if (response && response.data) {
            const prepareData = (response?.data?.data?.result || []).map(i => {
                return { ...i, firstName: i?.client?.firstName, lastName: i?.client?.lastName, company: i?.client?.company, resource_id: i.client_id }
            })
            resp = { success: true, data: prepareData }
        }
    } else if (type === 'resources' && ['VENDOR'].includes(role)) {
        const response = await apiV2.get(`/resources`, {
            params: {
                search: resourceSearch,
                type: "list",
                for: "current",
                size: 10
            }
        })
        if (response && response.data) {
            const prepareData = (response?.data?.data?.result || []).map(i => {
                return { ...i, firstName: i?.firstName, lastName: i?.lastName, resource_id: i?.id }
            })
            resp = { success: true, data: prepareData }
        }
    }
    return resp
}