export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export const stringAvatar = (name, details) => {
  if (name) {
    const list = name.split(' ');
    const str = list
      .map((item) => item[0])
      .join('')
      .toUpperCase();

    return {
      sx: { bgcolor: stringToColor(name), width: 32, height: 32, borderRadius: '8px', ...details },
      children: str,
      title: name,
    };
  }
  return {
    sx: { bgcolor: stringToColor('NA'), width: 32, height: 32, borderRadius: '8px', ...details },
    children: 'NA',
    title: 'NA',
  };
};
