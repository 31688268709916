import React from "react";
import "./DotLoaders.scss";

function DotLoaders() {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className={`dot-loader-main`}></div>
      <div className={`dot-loader-main`}></div>
      <div className={`dot-loader-main`}></div>
      <div className={`dot-loader-main`}></div>
      <div className={`dot-loader-main`}></div>
    </div>
  );
}

export default DotLoaders;
