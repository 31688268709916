import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

export default function SideNavLink({
  children, Icon, path, alerts = 0,
  expanded
}) {
  const location = useLocation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleSideNavlinks = () => {
    // dispatch(setOpenSideNav({ openSideNav: false }));
  }

  return (
    <NavLink onClick={handleSideNavlinks} to={path} className="text-none">
      {({ isActive }) => {
        const active = path === '/dashboard' ? location.pathname === '/dashboard' : isActive;
        return (
          <button
            className={`w-full text-left py-2.5 px-5 flex items-center justify-between gap-6 
              ${!expanded && isMobile ? 'hidden' : ''}
              ${active ? 'border-l-4 border-[#006A98] bg-[#F2FBFF]' : 'border-l-4 border-white bg-white'}
              hover:border-[#006A98] hover:bg-[#F2FBFF] text-[#003B54]`}
          >
            <div className="w-full flex items-center gap-6 !text-sm">
              <Icon className={`${active ? 'text-[#003B54]' : 'text-gray-400'}`} />
              <div className={`${expanded ? 'w-full' : 'hidden'}`}>{children}</div>
            </div>
            {expanded && alerts > 0 && (
              <div className="flex items-center justify-center bg-[#006A98] rounded-full w-5 h-5">
                <span className="text-white text-xs">{alerts}</span>
              </div>
            )}
          </button>
        );
      }}
    </NavLink>
  );
}
