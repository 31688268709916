import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { CustomTable } from "Components";
import Button from "@mui/material/Button";
import { Link as MuiLink } from "@mui/material";
import {
  fetchCustomerDetails,
  fetchSubscriptionsList,
} from "services/subscriptions.service";
import moment from "moment";

export const SubscriptionsTable = ({ activeSubscription }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isRazorPay, setIsRazorPay] = useState(false);

  const fetchSubscriptions = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchCustomerDetails();
      const { data: subscriptionsList } = await fetchSubscriptionsList(
        data.id,
        { params: { sortBy: "id", sortOrder: "desc" } }
      );

      setSubscriptions(subscriptionsList.result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <div>
      <CustomTable
        initialState={{
          sorting: [{ id: "createdAt", desc: true }],
          pagination: { pageSize: 50, pageIndex: 0 },
        }}
        state={{ isLoading: isLoading }}
        paginationDisplayMode="pages"
        columns={[
          {
            accessorFn: (subscription) => {
              return moment.utc(subscription.start_date).format("MMM Do, YYYY");
            },
            header: "Date",
            Cell: ({ cell }) => (
              <Typography variant="body2Normal" sx={{ color: "#182743" }}>
                {cell.getValue()}
              </Typography>
            ),
            size: 120,
          },
          {
            //default_payment_method
            accessorKey: "default_payment_method",
            header: "Payment Method",
            Cell: ({ cell }) => (
              <Typography variant="body2Normal" sx={{ color: "#182743" }}>
                {cell.getValue()?.toUpperCase()}
              </Typography>
            ),
            size: 120,
          },
          {
            // accessorFn: (subscription) => (
            //   <span className="capitalize">
            //     {subscription?.interval}
            //   </span>
            // ),
            accessorKey: "stripe_subscription_id",
            header: "Subscription Type",
            Cell: ({ row }) => (
              <Typography variant="body2Normal" sx={{ color: "#182743" }}>
                {row.original?.attributes?.id || row.original?.interval}
              </Typography>
            ),
            size: 120,
          },
          {
            accessorFn: ({ invoice }) => (
              <>
                <MuiLink
                  href={invoice?.hosted_invoice_url}
                  target="_blank"
                  className="px-2"
                  underline="none"
                  sx={(theme) => ({
                    color: theme.palette.primary.light,
                  })}
                >
                  View Pdf
                </MuiLink>
                {/* <MuiLink
                  className="px-2"
                  underline="none"
                  href={invoice?.invoice_pdf}
                  target="_blank"
                  sx={(theme) => ({
                    color: theme.palette.primary.light,
                  })}
                >
                  Download Pdf
                </MuiLink> */}
              </>
            ),
            header: "Reciept",
            Cell: ({ cell }) => (
              <Typography variant="body2Normal" sx={{ color: "#182743" }}>
                {cell.getValue()}
              </Typography>
            ),

            size: 120,
          },
        ]}
        data={subscriptions}
      />
    </div>
  );
};
