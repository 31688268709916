import { Avatar, Box, Divider, Stack, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AddNewSprint from "../Task/AddNewLane";
import ApplicantsLaneCard from "./ApplicantsLaneCard";
import DownloadingIcon from '@mui/icons-material/Downloading';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApplicantViewModal from "./ApplicantViewModal";
import { updateJobApplicationInMarketplace } from "./jobapplication.api";
import { applicantList } from "Pages/Task/constant";
import { useAlertProvider } from "Providers/util/Alert";
import { useNavigate } from "react-router-dom";
import MuiModal from "Components/Common/MuiModal";
import Button from "Components/Common/semantic_tags/Button";
import { submitApplication } from "Pages/Marketplace/marketplace.api";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { stringAvatar } from "Utils/avatar";

const options = [
    { label: 'Shortlist Applicant', value: 'select' },
    { label: 'Waitlist Applicant', value: 'reject' }
];
export const ITEM_HEIGHT = 48;

export default function ApplicantsList({ taskListArray, jobDetails, fetchData }) {
    const { success, error } = useAlertProvider();
    const [anchorEls, setAnchorEls] = useState({});
    const [openApplicantDetails, setOpenApplicantDetails] = useState(false);
    const [applicantDetails, setApplicantDetails] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmModalDetails, setConfirmModalDetails] = useState(null)
    const navigate = useNavigate()

    const handleClick = async (id, value) => {
        setAnchorEls((prevState) => ({ ...prevState, [id]: null }));
        await updateTaskHandler(id, value, true);
    };

    const handleMenuOpen = (event, id) => {
        setAnchorEls((prevState) => ({ ...prevState, [id]: event.currentTarget }));
    };

    const handleClose = (id) => {
        setAnchorEls((prevState) => ({ ...prevState, [id]: null }));
    };

    const onBeforeCapture = useCallback(() => { }, []);
    const onBeforeDragStart = useCallback(() => { }, []);
    const onDragStart = useCallback(() => { }, []);
    const onDragUpdate = useCallback(() => { }, []);

    const updateTaskHandler = async (id, status, reloadRequired = false) => {
        await updateJobApplicationInMarketplace(id, { status: status?.id || status });
        reloadRequired && await fetchData();
    };

    const submitHandler = async (details) => {
        const respObj = {
            vendor_id: details?.vendor_id,
            created_by: jobDetails.createdBy,
            job_id: jobDetails.parent_job_id,
            parent_job_id: jobDetails.parent_job_id,
            applicantIds: [details.applicant_id]
        }
        const resp = await submitApplication(respObj)
        if (resp.success) {
            success(resp.message || 'Job Application submitted Successfully')
            await updateTaskHandler(details.id, 'submit', true)
            setConfirmModalDetails(null)
            setConfirmModal(false)
        } else error(resp.error || resp.message || 'Something went wrong!!!')
    }

    const onDragEnd = useCallback((result) => {
        if (!result.destination) {
            if (jobDetails?.status === 'HIRED') return error('Candidate already hired');
            return error('You cannot move candidate directly to hired, please review the candidate properly.');
        }
        const sourceArr = result.source.droppableId.split('_');
        const sourceId = parseInt(sourceArr[sourceArr.length - 1]);
        const sourceIndex = result.source.index;
        const sourceData = taskListArray[sourceId].list[sourceIndex];
        const destinationArr = result.destination.droppableId.split('_');
        const destinationId = parseInt(destinationArr[destinationArr.length - 1]);
        const destinationIndex = result.destination.index;
        if (sourceArr[0] === 'submit') {
            return error('You cannot move candidate from submit lane');
        }
        if (result?.destination?.droppableId.includes('submit')) {
            if (!taskListArray[sourceId].list[sourceIndex]?.isFeedbackGiven) {
                return error('Please add your interview feedback');
            }
            setConfirmModal(true)
            setConfirmModalDetails(taskListArray[sourceId].list[sourceIndex])
            return
        }
        if (sourceId !== destinationId) {
            const newArr = taskListArray[destinationId].list;
            const leftArr = newArr.splice(0, destinationIndex);
            taskListArray[destinationId].list = [...leftArr, sourceData, ...newArr];
            taskListArray[sourceId].list.splice(sourceIndex, 1);
            updateTaskHandler(sourceData.id, applicantList(jobDetails?.parent_job_id)[Object.keys(applicantList(jobDetails?.parent_job_id))[destinationId]]);
        } else {
            const temp = taskListArray[sourceId].list[sourceIndex];
            taskListArray[sourceId].list[sourceIndex] = taskListArray[destinationId].list[destinationIndex];
            taskListArray[destinationId].list[destinationIndex] = temp;
        }
    }, [taskListArray]);

    return (
        <>
            <DragDropContext
                onBeforeCapture={onBeforeCapture}
                onBeforeDragStart={onBeforeDragStart}
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
                onDragEnd={onDragEnd}
            >
                {applicantDetails && (
                    <ApplicantViewModal submitHandler={submitHandler} updateTaskHandler={updateTaskHandler} jobDetails={jobDetails} details={applicantDetails} open={openApplicantDetails} close={() => setOpenApplicantDetails(false)} />
                )}
                <div className="mt-4 overflow-x-scroll grid grid-cols-4 gap-4">
                    {taskListArray.map((lists, j) => (
                        <Droppable droppableId={`${lists.id}_${j}`} key={lists.id} isDropDisabled={lists.id === 'hired' || jobDetails?.status === 'HIRED'}>
                            {(provided) => (
                                <div
                                    key={lists.id}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="min-h-[80vh] mr-2 border-r pr-4"
                                >
                                    <AddNewSprint edit={true} sprintValue={lists.taskName} id={lists.id} />
                                    {lists.list && lists.list.map((l, k) => (
                                        <ApplicantsLaneCard
                                            key={j + k}
                                            number={l.id.toString()}
                                            index={k}
                                        >
                                            <div
                                                // onClick={() => { setOpenApplicantDetails(true); setApplicantDetails(l); }}
                                                onClick={() => { navigate(`/dashboard/marketplace/applicants/${l.job_id}/view/${l.consultant?.username}`) }}
                                                className="rounded hover:shadow-lg border p-4 mt-2 bg-white cursor-pointer"
                                            >
                                                <div className={`flex ${!jobDetails ? '' : l?.status !== 'submit' && jobDetails?.status !== 'HIRED' ? 'justify-between' : ''} items-center mb-2`}>
                                                    {
                                                        l.consultant?.profile_image ?
                                                        <Avatar
                                                        className="w-[35px] h-[35px] mr-2"
                                                        src={l.consultant?.profile_image || ""}
                                                        />
                                                        : <Avatar
                                                            {...stringAvatar(
                                                                `${l.consultant?.first_name ?? ""} ${l.consultant?.last_name ?? ''}`
                                                            )}
                                                            className="w-[40px] h-[40px] mr-2 !rounded-full !text-xs"
                                                        />
                                                }
                                                    <div>
                                                        <div className="break-words text-sm font-bold">
                                                            {l?.consultant?.first_name || ''}{" "}{l?.consultant?.last_name || ''}
                                                        </div>
                                                        {/* <Typography className="font-bold text-xs">{l?.consultant?.company || ''}</Typography> */}
                                                    </div>
                                                    {(l?.status !== 'submit' && jobDetails?.status !== 'HIRED') && (
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={anchorEls[l.id] ? 'long-menu' : undefined}
                                                            aria-expanded={anchorEls[l.id] ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={(e) => { e.stopPropagation(); handleMenuOpen(e, l.id); }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    )}
                                                    <Menu
                                                        MenuListProps={{
                                                            'aria-labelledby': 'long-button',
                                                        }}
                                                        anchorEl={anchorEls[l.id]}
                                                        open={Boolean(anchorEls[l.id])}
                                                        onClose={(e) => { e.stopPropagation(); handleClose(l.id); }}
                                                        PaperProps={{
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                width: '20ch',
                                                                boxShadow: 'none',
                                                                border: '1px solid #E9EAED',
                                                            },
                                                        }}
                                                    >
                                                        {options.map((option) => (
                                                            <MenuItem
                                                                key={`${l.id}_${option.label}`}
                                                                onClick={(e) => (e.stopPropagation(), handleClick(l.id, option.value))}
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Menu>
                                                </div>
                                                {l?.consultant?.about_me && <Divider className="my-1" />}
                                                <div className="flex justify-between gap-1 items-center my-2">
                                                    <Box
                                                        style={{
                                                            color: '#6B7280',
                                                            wordWrap: 'break-word',
                                                            overflowWrap: 'break-word'
                                                        }}
                                                        className="break-words w-full uniform-font text-[10px]"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                (l?.consultant?.about_me || '')?.substring(0, 120) +
                                                                (l?.consultant?.about_me?.length > 120 ? "..." : ""),
                                                        }}
                                                    ></Box>
                                                </div>
                                                {l?.consultant?.skills?.length > 0 && <div className="text-xs mb-4">
                                                    <strong>Skills</strong>: {l?.consultant?.skills?.join(', ')}
                                                </div>}
                                                <div className="flex justify-between items-center">
                                                    {l?.consultant?.industry_experience > 0 && <Typography className="!text-xs"><strong className="text-xs">Total Experience: </strong>{l?.consultant?.industry_experience || '0'}</Typography>}
                                                    {l?.isFeedbackGiven && <Typography className="!text-xs"><strong className="text-xs">Overall Rating: </strong>{l?.isFeedbackGiven ? l?.candidate_rating || '0' : 'Not provided'}</Typography>}
                                                </div>
                                                <div className="flex gap-4 items-center">
                                                    {/* {l?.consultant?.resume && (
                                                        <Typography onClick={(e) => (e.stopPropagation(), window.open(l?.consultant?.resume, '__blank'))} className="text-xs cursor-pointer text-[#3985B6] inline-block">
                                                            <DownloadingIcon fontSize="small" /> Download CV
                                                        </Typography>
                                                    )} */}
                                                    {/* {l?.consultant?.linkdin && (
                                                        <Typography onClick={(e) => (e.stopPropagation(), window.open(l?.consultant?.linkdin, '__blank'))} className="text-xs cursor-pointer text-[#3985B6] inline-block">
                                                            <LinkedInIcon fontSize="small" className="text-[#3985B6]" />
                                                        </Typography>
                                                    )} */}
                                                </div>
                                            </div>
                                        </ApplicantsLaneCard>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </div>
            </DragDropContext>
            {confirmModal && <ConfirmModal open={confirmModal} isClose={() => (setConfirmModal(false), setConfirmModalDetails(null))} onSubmit={() => submitHandler(confirmModalDetails)} />}
        </>
    );
}

const ConfirmModal = ({ open, isClose, onSubmit }) => {
    return <MuiModal open={open} onCancel={isClose}>
        <div className='flex flex-col'>
            <Typography className='text-xl font-bold'>Are you sure you want to final submit the resource ?</Typography>
            <div className='flex justify-end mt-8'>
                <Button title='Cancel' className="focus:!bg-gray-400 hover:!bg-gray-400 bg-gray-400" onClick={isClose} />
                <Button title='Submit' className='ml-4' onClick={onSubmit} />
            </div>
        </div>
    </MuiModal>
}
