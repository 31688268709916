import React from "react";
import AiLoader from "./AiLoader";
import "./AiLoader.scss";

function AiLoaderBox({big}) {
  return (
    <div className="flex justify-center items-center p-[2rem] rounded-lg border-[1px] border-[#3985B6] ai-loader-box-container">
      <AiLoader big={big}/>
      <div className="relative flex w-[60%] animate-pulse gap-2 p-4">
        <div className="flex-1">
          <div className="mb-1 h-5 w-full rounded-lg bg-slate-400 text-lg"></div>
          <div className="h-5 w-[90%] rounded-lg bg-slate-400 text-sm"></div>
        </div>
        <div className="flex-1">
          <div className="mb-1 h-5 w-full rounded-lg bg-slate-400 text-lg"></div>
          <div className="h-5 w-[90%] rounded-lg bg-slate-400 text-sm"></div>
        </div>
      </div>
    </div>
  );
}

export default AiLoaderBox;
