import React from 'react';
import {
  Box,
  Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TabSection } from './TabSection';
import { userTypeCardsContent } from './UserTypeCards.content';
import { FeatureSection } from './FeatureSection';
import { UserTypeTabCard } from './UserTypeTabCard';
import Button from 'Components/Common/semantic_tags/Button';

export function UserTypeTabs({selectedTab, setSelectedTab}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container item xs={16} justifyContent="center">
      <Box
        display={"flex"}
        justifyContent={"center"}
        width={"100%"}
        sx={{ paddingLeft: { xs: "20px", md: "0" } }}
      >
        <TabSection
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={[
            { value: "client", label: "Enterprises" },
            { value: "vendor", label: "Vendors" },
            { value: "consultant", label: "Consultants" },
          ]}
        />
      </Box>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="stretch"
        xs={16}
        columns={12}
      >
        {Object.keys(userTypeCardsContent).map((data) => {
          return userTypeCardsContent[data].cardData.map((card) => (
            <UserTypeTabCard isSelected={selectedTab === data} card={card} key={card.heading} />
          ));
        })}
        {/* {userTypeCardsContent[selectedTab].cardData.map((card) => <UserTypeTabCard card={card} key={card.heading} />)} */}
      </Grid>
      <Grid item xs={16}>
        <Stack
          direction="row"
          justifyContent={isSmallScreen ? "center" : "space-between"}
          alignItems="center"
          flexDirection={isSmallScreen ? "column-reverse" : "row"}
          bgcolor="#F9FDFF"
          sx={{ border: "1px solid #F3F4F6", borderRadius: "12px" }}
        >
          <Grid item xs={14} md={7}>
            <Stack
              gap={4}
              justifyContent="flex-start"
              alignItems="flex-start"
              p={3}
            >
              <Typography variant="h2" className='font-secondary' sx={{fontSize: "30px", fontWeight: 700, lineHeight: "1.3"}}>
                {userTypeCardsContent[selectedTab].fullCard.heading}
              </Typography>
              <Typography variant="body1" className='font-secondary'>
                {userTypeCardsContent[selectedTab].fullCard.subHeading}
              </Typography>
              <Button title='Get Started' onClick={() => navigate("/signup")} />
            </Stack>
          </Grid>
          <Grid item xs={14} md={8}>
            <img
              src={userTypeCardsContent[selectedTab].fullCard.banner}
              alt="Full Card Banner"
              style={{ width: "100%" }}
            />
          </Grid>
        </Stack>
      </Grid>
      <Box paddingTop={"7rem"} paddingBottom={"8px"}>
        <Typography variant='h2' className='font-secondary' sx={{textAlign: "center", color: "#003B54", fontWeight: 600, fontSize: {xs: "30px", md: "48px"}, lineHeight: "56px"}}>{userTypeCardsContent[selectedTab].featureTitle.title1}</Typography>
        <Typography variant='h2' className='font-secondary' sx={{textAlign: "center", color: "#003B54", fontWeight: 600, fontSize: {xs: "30px", md: "48px"}, lineHeight: "56px"}}>{userTypeCardsContent[selectedTab].featureTitle.title2}</Typography>
      </Box>
      <FeatureSection features={userTypeCardsContent[selectedTab].features} />
    </Grid>
  );
}
