import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useAlertProvider } from 'Providers/util/Alert';

export default function Share({ url = '' }) {
  const { success } = useAlertProvider()
  const title = 'This is the title for Prolegion';
  const description = 'This is the description for Prolegion';

  function copyShareableLink() {
    navigator.clipboard.writeText(url);
    success('Copied to clipboard')
  }

  return (
    <ul className='flex flex-row space-x-2 mt-2'>
      <li>
        <FacebookShareButton
          url={url || window.location.href}
          quote={`${title}`}
        >
          <FacebookIcon round={true} size={32} />
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton url={url || window.location.href} title={title} via={description}>
          <TwitterIcon round={true} size={32} />
        </TwitterShareButton>
      </li>
      <li>
        <TelegramShareButton url={url || window.location.href} title={title}>
          <TelegramIcon round={true} size={32} />
        </TelegramShareButton>
      </li>
      <li>
        <LinkedinShareButton url={url || window.location.href} title={title} summary={description} source={url || window.location.href}>
          <LinkedinIcon round={true} size={32} />
        </LinkedinShareButton>
      </li>
      <li>
        <WhatsappShareButton url={url || window.location.href} title={title}>
          <WhatsappIcon round={true} size={32} />
        </WhatsappShareButton>
      </li>
      <li>
        <div onClick={copyShareableLink} className='cursor-pointer bg-[#3985B6] rounded-full w-[31px] h-[31px] flex justify-center items-center'>
          <FileCopyIcon className='text-white' sx={{ fontSize: '16px' }} />
        </div>
      </li>
    </ul>
  );
}
