import React, { useState, useEffect, useMemo } from "react";
import { useProjectProvider } from "../../../../Providers/Vendor/Project";
import CustomTable from "../../../../Components/Table";
import { Avatar, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { stringAvatar } from "../../../../Utils/avatar";
import ListOfVendors from "./ListOfVendors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Back from "../../../../Components/Back";
import { Modal, Select, Space } from "antd";
import api from "../../../../Utils/api";
import { useConnectionProvider } from "../../../../Providers/Vendor/Connections";
import Swal from "sweetalert2";

const headCells = [
  { label: "Date" },
  { label: "Title" },
  { label: "Description" },
  // { label: "Consultant" },
  // { label: "Vendors" },
  // { label: "Time Tracked" },
  { label: "Status" },
];

export default function ProjectInformation({ setProjectStatus }) {
  const [activeTab, setActiveTab] = useState("jobs");
  const [rows, setRows] = useState([]);
  const [client, setClient] = useState(null);
  const [Vendor, setVendors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openVendor, setOpenVendor] = useState(false);
  const [category, setCategory] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isOwnerChanged, setIsOwnerChanged] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [project, setProject] = useState(null)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const { projectid } = useParams()
  const { clients, vendors, fetchCurrentVendors, fetchClients } =
    useConnectionProvider();

  const { fetchJobsWithTimeTracked, fetchClientDetails, fetchAllProjectsWithProfiles, projectsWithProfiles } = useProjectProvider();


  useEffect(() => {
    async function fetchData() {
      const profileData = await fetchAllProjectsWithProfiles();
      const res = await api.get(`/project/get-project-by-id/${projectid}`)
      fetchJobsWithTimeTracked(projectid, profileData);
      fetchClients();
      fetchCurrentVendors();
      setProject(res.data.result)
    }

    fetchData()
  }, [])

  useMemo(()=> {
    const jobsData = projectsWithProfiles.find((item)=> item.id === projectid);
    setRows(jobsData?.jobs || []);
  },[projectid, projectsWithProfiles])

  const showModal = () => setOpen(true);
  const handleCancel = () => setOpen(false);
  const handleOk = async () => {
    setLoading(true);
    if (ownerId && project) {
      const res = await api.post("/project/assign-new-owner", {
        userId: localStorage.getItem("userId"),
        ownerId: ownerId,
        projectId: project?.id,
      });
      if (res.data.success) {
        Swal.fire("Successfully Assigned!", res.data.msg, "success").then(
          () => {
            setCategory(null);
            setOwnerId(null);
            setOpen(false);
            fetchClientDetails(project?.id).then(setClient);
            setIsOwnerChanged(true);
          }
        );
      } else {
        Swal.fire("Error in Assigning !", res.data.msg, "error").then(() =>
          setOpen(false)
        );
      }
    }
  };

  const handleVendorAddition = async () => {
    setLoading(true);
    try {
      const res = await api.post("/project/add-user-to-project", {
        userId: selectedVendor,
        ownerId: localStorage.getItem("userId"),
        projectId: project?.id,
      });

      if (res.data.success) {
        Swal.fire("Vendor Added Successfully!", res.data.msg, "success").then(
          () => {
            setCategory(null);
            setOwnerId(null);
            setOpenVendor(false);
          }
        );
      } else {
        Swal.fire("Error in Adding the Vendor !", res.data.msg, "error").then(
          () => setOpenVendor(false)
        );
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const location = useLocation()
  // useEffect(() => {
  //   if (projectid || project?.id) {
  //     fetchJobsWithTimeTracked(projectid ?? project?.id).then(setRows);
  //     fetchClients();
  //     fetchVendors();
  //   }
  // }, [projectid, project]);

  const CustomRow = ({ row }) => {
    const projectDescription =  new DOMParser().parseFromString(row?.job?.description, "text/html");

    return (
      <TableRow
        hover
        className="cursor-pointer"
        onClick={() => {
          navigate(`/dashboard/jobs/${row?.job?.id}?projectid=${projectid}`, { state: { from: location } })
        }}
      >
        <TableCell>{moment(row?.job?.createdAt).format("DD MMM, YYYY")}</TableCell>
        <TableCell className="truncate max-w-[250px]">{row?.job?.name}</TableCell>
        <TableCell className="truncate max-w-[250px]">{projectDescription.firstChild.textContent}</TableCell>
        {/* <TableCell>
          {row.consultant ? (
            <Avatar
              {...stringAvatar(
                `${row.consultant.firstName} ${row.consultant.lastName}`
              )}
              src={row.consultant?.profilePhoto}
            />
          ) : null}
        </TableCell>
        <TableCell>
          {row.vendor ? (
            <Avatar
              {...stringAvatar(
                `${row.vendor.firstName} ${row.vendor.lastName}`
              )}
              src={row.vendor?.profilePhoto}
            />
          ) : null}
        </TableCell> */}
        <TableCell>
          <span
            className="px-2 py-1 rounded-sm text-sm"
            style={{
              backgroundColor:
                row?.Status === "Inprogress"
                  ? "rgba(246, 104, 104, 0.2)"
                  : row?.Status === "Invoice Ready"
                    ? "rgba(229, 197, 28, 0.2)"
                    : "rgba(19, 149, 136, 0.2)",
              color:
                row?.Status === "Inprogress"
                  ? "rgba(246, 104, 104, 1)"
                  : row?.Status === "Invoice Ready"
                    ? "rgba(229, 197, 28, 1)"
                    : "rgba(19, 149, 136, 1)",
            }}
          >
            {row?.job?.status}
          </span>
        </TableCell>
      </TableRow>
    );
  };

  const descriptionText = useMemo(() => {
    return new DOMParser().parseFromString(project?.description, "text/html");
  }, [project?.description])

  return (
    <div className="">
      <div className="flex flex-row space-x-4 justify-between">
        <div className="flex flex-col grow bg-white rounded-lg font-[Roboto] p-4">
          <Back onClick={() => {
            navigate("/dashboard")
          }} />
          <span className="flex flex-row items-center text-gray-700">
            <span className="mr-8 font-medium">Name:</span>
            <span className="text-sm">{project?.title}</span>
          </span>
          <span className="flex flex-row items-center text-gray-700">
            <span className="mr-8 font-medium">Description:</span>
            <div className="flex flex-col space-y-1">
              <div className="text-justify">
                {isExpanded ? descriptionText.firstChild.textContent.split(' ')?.join(' ') : descriptionText.firstChild.textContent.split(' ')?.slice(0, 70)?.join(' ')}
              </div>
              {project?.description?.split(' ').length >= 70 && <div className="flex flex-row justify-start">
                <button type="button" className="text-blue-400" onClick={toggleExpand}>
                  {isExpanded ? "See Less" : "See More"}
                </button>
              </div>}
            </div>
          </span>
        </div>
        {
          <Modal
            open={open}
            title={
              <span className="text-xl font-semibold">
                Link project owner
              </span>
            }
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <button
                className="text-white hover:font-medium"
                style={{
                  width: "200px",
                  height: "45px",
                  background: "#139588",
                  borderRadius: "4px",
                }}
                loading={loading}
                onClick={handleOk}
              >
                Save Changes
              </button>,
            ]}
          >
            <p className="mt-6">Select Category</p>
            <Space wrap>
              <Select
                defaultValue="Select"
                showSearch
                style={{ width: 470 }}
                listHeight={128}
                value={category}
                onChange={setCategory}
                options={[
                  { value: "Client", label: "Client" },
                  { value: "Vendor", label: "Vendor" },
                ]}
              />
            </Space>
            <p className="mt-3">Job Owner Details</p>
            <Space wrap>
              <Select
                defaultValue="Select Owner"
                showSearch
                style={{ width: 470 }}
                listHeight={128}
                value={ownerId}
                onChange={setOwnerId}
                options={
                  category === "Client"
                    ? clients.map((client) => ({
                      value: client.id,
                      label: `${client.firstName} ${client.lastName}`,
                    }))
                    : vendors.map((vendor) => ({
                      value: vendor.vendor_id,
                      label: `${vendor.firstName} ${vendor.lastName}`,
                    }))
                }
              />
            </Space>
          </Modal>
        }
        {client !== null ? (
          <div
            style={{
              width: "290px",
              height: "310px",
              background: "#FFFFFF",
              borderRadius: "8px",
            }}
          >
            {(project?.isOwner || project?.accessLevel === "1") &&
              !isOwnerChanged && (
                <div className="flex flex-row h-[60px] px-4 items-center justify-between">
                  <span className="font-medium">Client</span>
                  <span
                    onClick={showModal}
                    className="text-blue-500 cursor-pointer"
                  >
                    change
                  </span>
                </div>
              )}
            <div
              className={`flex flex-row text-white h-[64px] px-4 items-center gap-x-3 bg-[#2EBAA3] ${(project?.isOwner || project?.accessLevel === "1") &&
                !isOwnerChanged
                ? ""
                : "rounded-t-lg"
                }`}
            >
              <Avatar
                onClick={() => navigate("/client/profile/" + client.id)}
                className="cursor-pointer"
                {...stringAvatar(
                  `${client?.firstName ?? ""} ${client?.lastName ?? ""}`
                )}
              />
              <span>
                {client?.firstName} {client?.lastName}
              </span>
            </div>
            <div className="flex justify-center font-[poppins] px-3 mt-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
              blandit lorem vel dui rutrum euismod. Prae
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-4">
        {Vendor !== null ? (
          <div className="flex flex-row justify-between mt-8 mb-4 space-x-2">
            <div
              onClick={() => setActiveTab("jobs")}
              style={{
                color: activeTab === "jobs" ? "#1EA596" : "#787878",
                borderColor: activeTab === "jobs" ? "#1EA596" : "#787878",
                borderBottomWidth: "5px",
              }}
              className="cursor-pointer border-b-4 w-[50%] border-solid flex justify-center"
            >
              <span className="mb-2">Jobs</span>
            </div>
            <div
              onClick={() => setActiveTab("Vendor")}
              style={{
                color: activeTab === "Vendor" ? "#1EA596" : "#787878",
                borderColor: activeTab === "Vendor" ? "#1EA596" : "#787878",
                borderBottomWidth: "5px",
              }}
              className="cursor-pointer border-b-4 w-[50%] border-solid flex justify-center"
            >
              <span className="mb-2">Vendor</span>
            </div>
          </div>
        ) : null}
        {activeTab === "jobs" && (
          <CustomTable
            title="List of Jobs"
            headCells={headCells}
            rows={rows}
            CustomRow={CustomRow}
          />
        )}
        {activeTab === "Vendor" && (
          <>
            <div className="flex flex-row justify-end mb-4">
              <span
                onClick={() => {
                  setOpenVendor(true);
                }}
                className="text-white cursor-pointer flex justify-center items-center"
                style={{
                  width: "161px",
                  height: "44px",
                  background: "#2EBAA3",
                  borderRadius: "8px",
                }}
              >
                Add Vendor
              </span>
            </div>
            <ListOfVendors vendors={Vendor} />
          </>
        )}

        <Modal
          open={openVendor}
          title="Add Vendor"
          onOk={handleVendorAddition}
          onCancel={() => {
            setOpenVendor(false);
          }}
          okButtonProps={{
            className: "bg-[#2EBAA3]",
          }}
          okText="Add Vendor"
          cancelButtonProps={{ hidden: true }}
        >
          <Select
            className="w-full mt-4 mb-2"
            placeholder="Select Vendor"
            value={selectedVendor}
            onChange={(id) => {
              setSelectedVendor(id);
            }}
            options={
              vendors &&
              vendors.map((item) => {
                return {
                  value: item.vendor_id,
                  label: `${item.firstName} ${item.lastName}`.trim(),
                };
              })
            }
          />
        </Modal>
      </div>
    </div>
  );
}
