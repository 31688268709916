import { useState } from "react";
import { useInvoiceProvider } from "../../../../Providers/Vendor/invoice";
import Swal from "sweetalert2";
import { Button, Modal } from "antd";
import { Autocomplete, Chip, TextField, Tooltip } from "@mui/material";
import isEmail from "validator/lib/isEmail";
import { LinkIcon } from "@heroicons/react/24/outline";

export default function ShareLinkModal({ invoiceId, setLoading }) {
  const { sendEmailInvoice } = useInvoiceProvider();

  const [submitViaLinkModalToggle, setSubmitViaLinkModalToggle] =
    useState(false);
  const [emails, setEmails] = useState([]);

  const shareEmails = async () => {
    setLoading(true);
    sendEmailInvoice(invoiceId, emails).then((res) => {
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Email Sent",
        });
      }
      setLoading(false);
    });
  };

  return (
    <>
      <button
        className="px-2 py-0.5 border border-[#139588] rounded-[8px] text-[#139588] flex items-center gap-x-1"
        onClick={() => setSubmitViaLinkModalToggle(true)}
      >
        <LinkIcon className="w-5 h-5" />
        Share via Link
      </button>
      <Modal
        open={submitViaLinkModalToggle}
        title="Share Invoice via Link"
        onCancel={() => {
          setSubmitViaLinkModalToggle(false);
          setEmails([]);
        }}
        footer={
          <div className="flex flex-row justify-end">
            <Button
              type="primary"
              style={{
                background: "#2EBAA3",
                borderRadius: "8px",
                width: "177px",
              }}
              onClick={() => shareEmails()}
            >
              Submit
            </Button>
          </div>
        }
      >
        <Autocomplete
          multiple
          freeSolo
          clearIcon={null}
          clearOnBlur
          onChange={(e, value) =>
            setEmails(value.filter((email) => isEmail(email)))
          }
          value={emails}
          itemType=""
          options={[]}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <div className="flex flex-row items-center space-x-8">
              <span>Email</span>
              <Tooltip
                arrow
                title="To add multiple addresses, press enter after each address"
              >
                <TextField
                  {...params}
                  size="small"
                  required
                  label="E-Mail"
                  onBlur={(e) => {
                    if (isEmail(e.target.value)) {
                      if (!emails.includes(e.target.value))
                        setEmails([...emails, e.target.value]);
                    }
                  }}
                  sx={{
                    marginTop: "0.5rem",
                    "& label.Mui-focused": {
                      color: "rgba(0,0,0,0.6)",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(0,0,0,0.87)",
                        borderWidth: "1px",
                      },
                    },
                  }}
                />
              </Tooltip>
            </div>
          )}
        />
      </Modal>
    </>
  );
}
