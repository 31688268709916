import React from 'react';
import {
  Avatar,
  Box, Link, Stack, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Check from './check.png';
import { AboutUsData } from './about.data';

export default function AboutPage() {
  const {
    heading, subheading, bottomImage, sectionTitle, sectionSubtitle, sections, professionals, unwavering, addressData
  } = AboutUsData;

  const renderAddressTemplate = (item, index) => {
    return (
      <Stack key={`renderAddressTemplate-index${index}`} justifyContent={"space-between"} sx={{height:{xs: "unset", md:"130px"}, width:{md:"500px"}, flexDirection: {xs: "column", md: "row"}, alignItems: {xs: "center", md: "unset"}, textAlign: {xs: "center", md: "unset"}, borderLeft : index === 1 ? {xs: "none", md: "2px solid #E5E7EB"} : "", paddingRight : index === 0 ? {xs: "none", md: "1rem"} : "", paddingLeft : index === 1 ? {xs: "none", md: "1rem"} : "", paddingBottom : index === 0 ? {xs: "2rem", md: 0} : "", borderBottom : index === 1 ? {} : "", }}>
        <Box display={"flex"} alignItems={"end"} sx={{width:{xs: "300px",md:"430px"}}}>{item.icon}</Box>
        <Box display={"flex"} flexDirection={"column"} width={"350px"} justifyContent={"space-between"} fontSize={"15px"} sx={{paddingLeft:{xs: 0, md:"1rem", alignItems: {xs: "center", md: "unset"}}}}>
          <Typography variant='h5Bold' textAlign={`${index === 0 && "center"}`} sx={{padding: {xs: "1rem 0", md: 0}}}>{item.country}</Typography>
          <Box sx={{textAlign: {xs: "center", md: "unset"}}}>
          <Box display={"flex"} paddingBottom={"4px"} sx={{}}>
            <PlaceIcon />
            <Typography variant='defaultFont' paddingLeft={"1rem"} lineHeight={"normal"} >{item.address.map}</Typography>
          </Box>
          {item.address.mapSub && <Box display={"flex"} paddingBottom={"4px"} paddingLeft={"40px"}><Typography variant='defaultFont' href='' lineHeight={"normal"} sx={{textDecoration: "none"}} >{item.address.mapSub}</Typography></Box> }
          {item.address.phone && <Box display={"flex"} paddingBottom={"4px"} sx={{}}>
            <LocalPhoneIcon />
            <Typography variant='defaultFont' paddingLeft={"1rem"}>{item.address.phone}</Typography>
          </Box>}
          <Box display={"flex"} paddingTop={item.address.mapSub && "8px"} sx={{}}>
            <EmailIcon />
            <Typography variant='defaultFont' paddingLeft={"1rem"}>{item.address.email}</Typography>
          </Box>
          </Box>
        </Box>
      </Stack>
    )
  }

  const renderStorySections = (item, index) => {
    return(
      <Typography key={index} variant="subtitle1Large" sx={{color: "#6B7280", paddingBottom: "1rem"}}>
        <CheckCircleIcon sx={{ color: "#E1EFFE", marginRight: '8px' }} />
          {item}
      </Typography>
    )
  }

  return (
    <>
      <Stack flexDirection="column" justifyContent="center" alignItems="center" margin="100px 0px">
        <Typography variant="h1" sx={{textAlign: {xs: "center"}}}>{heading}</Typography>
        <Box marginTop="30px" sx={{padding: {xs: "1rem"}, width: {xs: "100%", md: "700px"}}}><img src={bottomImage} style={{borderRadius: "10px"}} alt="bottom" /></Box>
      </Stack>
      <Stack flexDirection="column" sx={{ backgroundColor: '#F9FAFB', padding: {xs: "30px", md: "30px 100px", paddingBottom: {xs: "3rem", md: "100px"}} }}>
        <Typography variant="h4Bold" sx={{lineHeight: {xs: "normal", md: "80px"}, paddingBottom: {xs: "2rem", md: "0"}}}>{professionals.heading}</Typography>
        <Typography variant="subtitle1Large" color="#6B7280">{professionals.subheading}</Typography>
        <Box sx={{ marginTop: '40px', marginBottom: '20px' }}>
          {/* <Typography variant="cardHeader">{professionals.listHeading.heading}</Typography> */}
        </Box>
        {/* <Box>
          {
                professionals.listHeading.list.map((li) => (
                  <Stack flexDirection="row" gap={2} marginBottom="15px" alignItems="center">
                    <Avatar sx={{ width: '20px', height: '20px' }} src={Check} />
                    <Typography variant="body1Normal" color="#111928">{li}</Typography>
                  </Stack>
                ))
              }
        </Box> */}
      </Stack>
      <Stack sx={{padding:{xs: "30px", md: "30px 100px 20px 100px"}}}>
        <Typography variant="h4Bold" sx={{lineHeight: {xs: "normal", md: "80px"}, paddingBottom: {xs: "1rem"}}}>{sectionTitle}</Typography>
        <Typography variant="subtitle1Large" sx={{color: "#6B7280"}}>{sectionSubtitle}</Typography>
      </Stack>
      <Stack gap="40px" justifyContent={"center"} sx={{flexDirection: {xs: "column", md: "row"}, padding: {xs: "20px", md: "60px 100px"}}}>
        {
          sections.map((section, key) => (
            <Stack flexDirection="column" sx={{ paddingRight: key < 2 ? {xs: 0, md: "30px"} : '', paddingBottom: key < 0 ? {xs: "30px", md: 0} : '' }}>
              <Box sx={{ width: '100px', height: '100px', margin: '20px auto' }}>
                <img src={section.img} alt="section" />
              </Box>
              <Typography textAlign="center" variant="h5Bold" sx={{padding: {xs: "1rem", md: "0"}}}>{section.heading}</Typography>
              <Box display={"flex"} flexDirection={"column"} paddingTop={"1rem"}>{section.ourStorysectionPointers.map(renderStorySections)}</Box>
            </Stack>
          ))
        }
      </Stack>
      <Box sx={{padding: {xs: "30px", md: "100px 100px 0 100px"}}}>
        <Typography variant='h1' sx={{fontSize: "30px"}}>What do we do?</Typography>
        <Typography variant="subtitle1Large" sx={{color: "#6B7280"}}>We provide enterprises and vendors with a simplified interface which is user-friendly and intuitive at the same time. At this interface, our customers can manage their entire business and project pipeline from a bird’s eye view.</Typography>
      </Box>
      <Stack flexDirection="column" sx={{padding: {xs: "20px", md: "40px 100px 100px 100px"}}}>
        {/* <Typography variant="h4Bold" sx={{lineHeight: {xs: "normal", md:"80px"}, textAlign:{ xs: "left", md: "center" }}}>{unwavering.heading}</Typography> */}
        <Stack justifyContent="space-between" alignItems="center" marginTop="40px" sx={{flexDirection: {xs: "column", md: "row"}}}>
          {
            unwavering.images.map((unwave) => <Box sx={{ width: {xs: "100%", md: '25%'}, paddingBottom: {xs: "2rem", md: 0} }}><img src={unwave} alt="unwave" /></Box>)
          }
        </Stack>
        {/* <Stack flexDirection="row" flexWrap={"wrap"} justifyContent="center" gap="80px" alignItems="center" marginTop="40px">
          {
            Object.keys(unwavering.details).map((unwa) => (
              <Box display="flex" flexDirection="column" sx={{width:{xs:"100px", md: "unset"}}}>
                <Typography variant="h4Bold">{unwavering.details[unwa]}</Typography>
                <Typography variant="body1Normal">{unwa}</Typography>
              </Box>
            ))
          }
        </Stack> */}
      </Stack>
      <Box textAlign={"center"} sx={{paddingTop: {xs: "2rem", md: 0}}}><Typography variant='h4Bold'>ProLegion Pvt. Ltd</Typography></Box>
      <Stack sx={{flexDirection : {xs: "column", md: "row"}, justifyContent: "center", alignItems: "center", padding: "2rem"}}>
        {addressData.map(renderAddressTemplate)}
      </Stack>
    </>
  );
}
