import { Autocomplete, Chip, createFilterOptions, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { NestedDropdown } from 'Components';
import Button from 'Components/Common/semantic_tags/Button';
import Typography from 'Components/Common/semantic_tags/Typography';
import dayjs from 'dayjs';
import { newAvailabilityOptions } from 'Pages/editProfile/constant';
import { useJobProvider } from 'Providers/Client/Jobs';
import { useCountryStateCityProvider } from 'Providers/Common/CountyStateCity';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { experienceList } from 'Utils/constants';

const ResourceFilterHookForm = ({ fetchProfilesHandler = () => { } }) => {
    const { control, handleSubmit, reset, setValue, watch } = useForm();
    const { skills } = useJobProvider();
    const muiListfilter = createFilterOptions();
    const [date, setDate] = useState(null)
    const { countries, states, cities, getCountries, getStatesByCountry, getCitiesByStateAndCountry } = useCountryStateCityProvider();
    const sortedSkills = useMemo(() => (skills.sort((a, b) => a.name.localeCompare(b.name))), [skills]);
    const onSubmit = (data) => {
        const queryParams = {};
        if (data.skills && data.skills.length > 0) {
            queryParams.skills = data.skills.map(skill => skill.name).join(',');
        }
        if (data.availability) {
            if (data.availability === 'After') {
                queryParams.availability = date;
            } else {
                queryParams.availability = data.availability;
            }
        }
        if (data.city && data.city.name) {
            queryParams.city = data.city.name;
        }
        if (data.state && data.state.iso2) {
            queryParams.state = data.state.iso2;
        }
        if (data.country && data.country.iso2) {
            queryParams.country = data.country.iso2;
        }
        if (data.experience && data.experience.value) {
            queryParams.experience = data.experience.value;
        }
        const queryString = new URLSearchParams(queryParams).toString();
        fetchProfilesHandler(queryString)
    };

    const handleFilterCreateOption = (options, params) => {
        const filtered = muiListfilter(options, params);
        const { inputValue } = params;
        const isExistingValue = options.some((option) => (inputValue === option?.name || inputValue === option));
        if (inputValue !== '' && !isExistingValue) {
            filtered.push({
                inputValue,
                name: `${inputValue}`
            })
        }
        return filtered;
    }


    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        const selectedCountry = watch('country');
        setValue('state', '')
        if (selectedCountry) {
            getStatesByCountry(selectedCountry.iso2 || selectedCountry);
        }
    }, [watch('country')]);

    useEffect(() => {
        const selectedState = watch('state');
        const selectedCountry = watch('country');
        if (selectedState && selectedCountry) {
            getCitiesByStateAndCountry(selectedState?.iso2, selectedCountry.iso2);
            setValue('city', '')
        }
    }, [watch('state')]);

    useEffect(() => {
        return ()=> fetchProfilesHandler()
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="rounded-b-lg mb-8 border-b-[1px] border-x-[0.85px] shadow-prolegion border-[solid] border-[#E4E5E8] px-4 py-2 pt-0 justify-between">
            <div className='flex flex-col md:flex-row items-end w-full gap-4 mb-4'>
                <NestedDropdown selectedOption={watch('availability')} setSelectedOption={(val) => setValue('availability', val)} label={"Availability"} placeholder={"Select an option"} labelClassname={"text-[#00000099]"} baseClassname={"w-full md:w-[30%]"} options={newAvailabilityOptions} />
                {watch('availability') === 'After' && <div className="flex flex-col w-[30%]">
                    <Typography>Available Date</Typography>
                    <DesktopDatePicker
                        className=""
                        format="MM-DD-YYYY"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: 'white',
                            },
                            '& .MuiInputBase-root': {
                                backgroundColor: 'white',
                                marginTop: '5px'
                            },
                            '& .MuiInputAdornment-root': {
                                backgroundColor: 'white'
                            },
                        }}
                        minDate={dayjs()}
                        onChange={(e) => {
                            setDate(dayjs(e).format('DD/MM/YYYY'))
                        }}
                    />
                </div>}
                <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            sx={{ width: { xs: '100%', sm: '33%' } }}
                            options={countries}
                            isOptionEqualToValue={(option, value) => (option.iso2 === value || option.iso2 === value?.iso2)}
                            getOptionLabel={(option) => (option.name ? option.name : option)}
                            onChange={(_, v) => setValue('country', v)}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" variant="outlined" />
                            )}
                        />
                    )}
                />

                <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            sx={{ width: { xs: '100%', sm: '33%' } }}
                            options={states}
                            disabled={!watch('country')}
                            isOptionEqualToValue={(option, value) => (option.iso2 === value || option.iso2 === value?.iso2)}
                            getOptionLabel={(option) => (option.name ? option.name : option)}
                            onChange={(_, v) => setValue('state', v)}
                            renderInput={(params) => (
                                <TextField {...params} label="State" variant="outlined" />
                            )}
                        />
                    )}
                />

                <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            sx={{ width: { xs: '100%', sm: '33%' } }}
                            options={cities}
                            disabled={!watch('state')}
                            isOptionEqualToValue={(option, value) => (option.iso2 === value || option.iso2 === value?.iso2)}
                            getOptionLabel={(option) => (option.name ? option.name : option)}
                            onChange={(_, v) => setValue('city', v)}
                            renderInput={(params) => (
                                <TextField {...params} label="City" variant="outlined" />
                            )}
                        />
                    )}
                />

                <Controller
                    name="experience"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            sx={{ width: { xs: '100%', sm: '33%' } }}
                            options={experienceList}
                            isOptionEqualToValue={(option, value) => (option.value === value)}
                            getOptionLabel={(option) => (option.label ? option.label : option)}
                            onChange={(_, v) => setValue('experience', v)}
                            renderInput={(params) => (
                                <TextField {...params} label="Experience" variant="outlined" />
                            )}
                        />
                    )}
                />
            </div>
            <div className='flex flex-col md:flex-row justify-between items-end w-full gap-4'>
                <Controller
                    name="skills"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <Autocomplete
                            {...field} sx={{
                                width: { xs: '100%', sm: '90%' }
                            }}
                            multiple
                            clearIcon={false}
                            value={watch('skills') || []}
                            filterOptions={handleFilterCreateOption}
                            getOptionLabel={(option) => (option.name ? option.name : option)}
                            options={sortedSkills}
                            onChange={(_, v) => setValue('skills', v)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name ? option.name : option}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Type to add skills'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: <>{params.InputProps.endAdornment}</>,
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Button type="button" className='bg-gray-500 hover:!bg-gray-500 border-[unset]' onClick={() => (reset(), fetchProfilesHandler())} title='Reset' />
                <Button type="submit" title='Apply Filters' />
            </div>
        </form>
    );
};

export default ResourceFilterHookForm;
