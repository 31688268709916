import React, { useEffect } from "react";
import { Box, TextField, Button, TextareaAutosize, Typography, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useForm } from "react-hook-form";
import { Person as ClientIcon, Business as VendorIcon } from '@mui/icons-material';
import { useAlertProvider } from "Providers/util/Alert";

const AddClientForm = ({ submitHandler, backHandler, billToDetails, setBillToDetails }) => {
    const { error } = useAlertProvider()
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: billToDetails
    });
    const companyName = watch('companyName')
    const email = watch('email')
    const address = watch('address')
    const [billTo, setBillTo] = React.useState(billToDetails?.billTo || '');

    const onSubmit = (data) => {
        if (!billTo) return error('Select Bill To')
        submitHandler({ ...data, billTo });
    };

    const handleBillToChange = (event) => {
        setBillTo(event.target.value);
    };

    useEffect(() => {
        setBillToDetails({ ...billToDetails, companyName, email, address, billTo })
    }, [companyName, email, address])

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column' }}
        >
            <Typography variant="body2Bold" className="!mb-2 !mt-6">Bill to</Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="bill-to"
                    name="billTo"
                    value={billTo}
                    onChange={handleBillToChange}
                    row
                >
                    <Box display="flex" flexDirection="row" alignItems="center" p={2} my={1} className='border' borderRadius={4} >
                        <FormControlLabel
                            value="CLIENT"
                            control={<Radio />}
                            label={
                                <Box display="flex" flexDirection="row" alignItems="center" gap='10px'>
                                    <Typography variant="body2Medium">Client</Typography>
                                    <ClientIcon fontSize="large" />
                                </Box>
                            }
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" p={2} my={1} ml={1} className='border' borderRadius={4} >
                        <FormControlLabel
                            value="VENDOR"
                            control={<Radio />}
                            label={
                                <Box display="flex" flexDirection="row" alignItems="center" gap='10px'>
                                    <Typography variant="body2Medium">Partner</Typography>
                                    <VendorIcon fontSize="large" />
                                </Box>
                            }
                        />
                    </Box>
                </RadioGroup>
            </FormControl>
            {billTo && <>
                <Typography className="!text-xs !font-bold !mb-2 !mt-6">Company Name</Typography>
                <TextField
                    variant="outlined"
                    {...register("companyName", { required: "Company name is required" })}
                    error={!!errors.companyName}
                    helperText={errors.companyName ? errors.companyName.message : ''}
                />
                <Typography className="!text-xs !font-bold !mb-2 !mt-6">Email</Typography>
                <TextField
                    variant="outlined"
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                            message: "Enter a valid email address"
                        }
                    })}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                />
                <Typography className="!text-xs !font-bold !mb-2 !mt-6">Billing Address</Typography>
                <TextareaAutosize
                    variant="outlined"
                    style={{ background: '', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '8px', height: '100px', padding: '4px' }}
                    {...register("address", { required: "Billing address is required" })}
                />
                {errors.address && <Typography className="!text-xs !font-bold !text-red-500">{errors.address.message}</Typography>}
            </>}

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: '30px',
                    alignItems: "center",
                }}
            >
                <Button variant="outlined" onClick={backHandler}>
                    Edit
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginLeft: "20px" }}
                    type="submit"
                >
                    Preview
                </Button>
            </Box>
        </Box>
    );
};

export default AddClientForm;
