import React, { useState, createContext, useContext, useEffect } from "react";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import { useAlertProvider } from "../util/Alert";

const apiPath = {
  updateUser: "/user/updateuserdetails",
  changePassword: "/auth/changepassword",
  becomeHybridConsultant: "/user/becomehybridconsultant",
  getApprovalStatus: "/user/gethybriduserapprovalstatus",
  // gethybriduserdetails: "/user/hybriduserdetails",
  gethybriduserdetails: "auth/switchAccount",
  getRoles: "/auth/role",
  getChildUsers: "/user",
  profile: "/user/profile",
  enableDisableUser: "/user/block",
  getBusinessTypes: "/user/getbusinesstypes",
};

const nullFn = () => null;
const paramFn = (param) => null;
const doubleParamFn = (params, param1) => null;
const UserContext = createContext({
  loading: false,
  accessDenied: false,
  setAccessDenied: nullFn,
  updateUser: params => null,
  changePassword: nullFn,
  becomeHybridConsultant: nullFn,
  getApprovalStatus: nullFn,
  getHybridUserDetails: (param) => null,
  getRoles: nullFn,
  roles: [],
  setRoles: nullFn,
  getChildUsers: nullFn,
  childUsers: [],
  userCount: 0,
  enableDisableUser: nullFn,
  addChildUser: paramFn,
  getUserDetails: paramFn,
  updateChildUser: doubleParamFn,
  getUserProfile: nullFn,
  updateUserProfile: nullFn,
  businessTypes: [],
  getBusinessTypes: nullFn,
});

export const useCommonUserProvider = () => useContext(UserContext);
const UserProvider = ({ children }) => {
  const { success, error } = useAlertProvider();

  const [loading, setLoading] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [roles, setRoles] = useState([]);
  const [childUsers, setChildUsers] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [userCount, setUserCount] = useState(0);
  useEffect(() => {
    if (accessDenied) {
      setTimeout(() => {
        if (accessDenied) setAccessDenied(false);
      }, 5000);
    }
  }, [accessDenied]);

  const getBusinessTypes = async () => {
    try {
      const res = await api.get(apiPath.getBusinessTypes);
      if (res.status === 200 && res.data?.success) {
        setBusinessTypes(res.data.business_type.rows);
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.profile);
      if (res.status === 200 && res.data?.success) {
        return res?.data?.result;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateUserProfile = async (body) => {
    setLoading(true);
    try {
      const res = await api.put(apiPath.profile, body);
      if (res.status === 200 && res.data?.success) {
        Swal.fire("", "Profile successfully updated", "success");
        return true;
      } else {
        Swal.fire("", "Failed to update profile", "info");
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateChildUser = async (id, body) => {
    setLoading(true);
    try {
      const res = await api.put(`${apiPath.getChildUsers}/${id}`, body);
      if (res.status === 200 && res.data?.success) {
        Swal.fire("", "User updated successfully", "success");
        return true;
      } else {
        Swal.fire("", "Failed to update user", "info");
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const enableDisableUser = async (id, page, size) => {
    setLoading(true);
    try {
      const res = await api.put(`${apiPath.enableDisableUser}/${id}`);
      if (res.status === 200 && res.data?.success) {
        success("User updated successfully");
        getChildUsers(page, size);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = async (id) => {
    setLoading(true);
    try {
      const res = await api.get(`${apiPath.getChildUsers}/${id}`);
      if (res.status === 200 && res.data?.success) {
        return res.data.result;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getChildUsers = async (page, size) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getChildUsers, {
        params: {
          page: page,
          size: size,
        },
      });
      if (res.status === 200 && res.data?.success) {
        setChildUsers(res.data.result);
        setUserCount(res.data.count);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const addChildUser = async (user) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.getChildUsers, user);
      if (res.status === 200 && res.data?.success) {
        success('User added successfully');
        return true;
      } else {
        error(res?.data?.msg || 'An error has occurred. Please try again later.');
        return false;
      }
    } catch (e) {
      error(e.response?.data?.msg ?? 'An error has occurred. Please try again later.');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getRoles = async (role = null) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getRoles, {
        params: {
          composite: role,
        },
      });
      if (res.status === 200 && res.data?.success) {
        if (role) {
          return res.data.result;
        } else {
          setRoles(res.data.result);
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async () => {
    setLoading(true);
    try {
      const res = await api.put(apiPath.changePassword);
      if (res.status === 200 && res.data?.success) {
        return true;
      } else {
        //throw new Error("Error occurred while sending email");
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const becomeHybridConsultant = async (body) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.becomeHybridConsultant, body);
      if (res.status === 200 && res.data?.success) {
        return res;
      } else {
        throw new Error("Error occurred while updating user");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getHybridUserDetails = async (query) => {
    setLoading(true);
    try {
      const res = await api.get(`${apiPath.gethybriduserdetails}${query}`);
      if (res.status === 200 && res.data?.success) {
        return res;
      } else {
        throw new Error("Error in getting status");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getApprovalStatus = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getApprovalStatus);
      if (res.status === 200 && res.data?.success) {
        return res;
      } else {
        throw new Error("Error in getting status");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (body) => {
    setLoading(true);
    try {
      const res = await api.put(apiPath.updateUser, body);
      if (res.status === 200 && res.data?.success) {
        return res;
      } else {
        throw new Error("Error occurred while updating user");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    changePassword,
    updateUser,
    becomeHybridConsultant,
    getApprovalStatus,
    getHybridUserDetails,
    loading,
    accessDenied,
    setAccessDenied,
    getRoles,
    roles,
    setRoles,
    getChildUsers,
    childUsers,
    userCount,
    enableDisableUser,
    addChildUser,
    getUserDetails,
    updateChildUser,
    getUserProfile,
    updateUserProfile,
    businessTypes,
    getBusinessTypes,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
