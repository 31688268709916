import {
  Avatar, Grid, Stack, Typography,
} from '@mui/material';
import React from 'react';

export function UserTypeTabCard({ card, isSelected }) {
  return (
    <Grid
      key={card.heading}
      item
      xs={12}
      md={4}
      sx={{
        display: !isSelected ? 'flex' : 'flex',
        height: !isSelected ? '0px' : 'auto',
        visibility: !isSelected ? 'hidden' : 'unset'
      }}
      { ...(isSelected && { item: true }) }
    >
      <Stack
        alignItems="center"
        textAlign="center"
        gap={3}
        sx={{
          py: 5,
          px: 3,
          border: '1px solid #F3F4F6',
          borderRadius: '12px',
          flex: 1,
          alignSelf: 'stretch',
          backgroundColor: '#F9FDFF',
        }}
      >
        <Avatar sx={{ width: '100px', height: '100px' }} src={card.img} />
        <Typography variant="h3" sx={{ lineHeight: '32px', fontSize: "24px" ,fontWeight: 700 }} className='font-secondary' >{card.heading}</Typography>
        <Typography variant="body1" className='font-secondary' color="#6B7280">{card.subHeading}</Typography>
      </Stack>
    </Grid>
  );
}
