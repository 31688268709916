import Typography from "Components/Common/semantic_tags/Typography";
import { StatusPill } from "Components/StatusPill";
import moment from "moment";
import React from "react";

const Card = ({ details, description, setDetails, title }) => {
  return (
    <div
      onClick={() => setDetails(details)}
      className="rounded-lg shadow-md border px-2 py-4 mt-2 m-auto bg-white"
      style={{ margin: "10px" }}
    >
      <div
        className="text-md font-bold break-words text-gray-500"
        style={{ inlineSize: "100%" }}
      >
        {title.substring(0, 120) + (title.length > 120 ? "..." : "")}
      </div>
      <div
        className="flex justify-between items-center mt-2 gap-2"
      >
        {description && <div
          className="text-xs font-medium break-words"
          style={{ inlineSize: "100%" }}
          dangerouslySetInnerHTML={{
            __html:
              description?.substring(0, 220) +
              (description?.length > 120 ? "..." : ""),
          }}
        ></div>}
      </div>
      {/* <div className="mt-4">
          <Typography className="text-sm font-bold">Job Details</Typography>
          <div className="w-full flex justify-between items-center">
              <Typography className="text-xs">{details?.jobData?.name}</Typography>
              <Typography className="text-xs">{details?.jobData?.status}</Typography>
          </div>
      </div> */}
      <div
        className="flex justify-end mt-2 w-full"
      >
        {/* <div className="text-xs w-full"><strong>Last updated at: </strong> {moment(details.updatedAt).fromNow()}</div> */}
        <>
        {details?.assign_to?.profilePhoto ? (
          <img
            src={details?.assign_to?.profilePhoto}
            className="w-8 h-8 rounded-full"
            alt={details?.assign_to?.name}
          />
        ) : (
          <div
            className="w-8 h-8 bg-[#3985B6] text-white flex justify-center items-center rounded-full relative"
          >
            <span className="text-xs font-bold absolute">
              {details?.assign_to?.name?.[0]?.toUpperCase()}
            </span>
          </div>
        )}
        </>
      </div>
    </div>
  );
};

export default Card;
