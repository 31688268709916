const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const weekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
// const date = new Date();

export const legalOptionsCategory = [
  'General Terms',
  'Website Terms of use',
  'Cookie Policy',
  'Privacy Policy',
  {
    id: '#indemnification',
    title: 'Indemnification',
  },
  {
    id: '#data-processing-terms',
    title: 'Data Processing Terms',
  },
  {
    id: '#disclaimer-limitations-of-liability',
    title: 'Disclaimers; Limitation of Liability',
  },
  {
    id: '#miscellaneous',
    title: 'Miscellaneous',
  },
  'Appendix 1',
  'Appendix 2',
];

export const appendix1List = [
  {
    id: '#additional-coverage-terms',
    title: 'Additional Coverage Terms',
  },
];

export const appendix2List = [
  {
    id: '#us-govt-customer-additional-terms',
    title: 'U.S. Government Customer Additional Terms',
  },
];

export const websiteTermsofuse = [
  {
    id: '#fees',
    title: 'Fees',
  },
  {
    id: '#invoices',
    title: 'Invoices',
  },
  {
    id: '#terms-and-termination',
    title: 'Term & Termination',
  },
];

export const cookiePolicyArr = [
  {
    id: '#customer-data',
    title: 'Customer Data',
  },
  {
    id: '#product-catalog',
    title: 'Product Catalog',
  },
  {
    id: '#terms-of-use',
    title: 'Terms of use',
  },
  {
    id: '#intellectual-property',
    title: 'Intellectual Property',
  },
  {
    id: '#confidentiality',
    title: 'Confidentially',
  },
];

export const privacyPolicyArr = [
  {
    id: '#collection-of-information',
    title: 'Collection of Information',
  },
  {
    id: '#use-of-personal-data',
    title: 'Use of Personal Data',
  },
  {
    id: '#transferring-personal-data-<Indemnification />intentionally',
    title: 'Transferring Personal Data Internationally',
  },
  {
    id: '#privacy-right-choice-of-users',
    title: 'Privacy rights & Choices of Users',
  },
  {
    id: '#privacy-rights-ca',
    title: 'Privacy Rights (California)',
  },
  {
    id: '#storing-and-securing-personal-data',
    title: 'Storing & Securing Personal Data',
  },
];

export const generalTermsListTitle = [
  {
    id: '#defintions',
    title: 'Definitions',
  },
  {
    id: '#use-of-service',
    title: 'Use of Service',
  },
  {
    id: '#website-terms-of-use',
    title: 'Website Terms of Use',
  },
  {
    id: '#acceptable-use-policy',
    title: 'Acceptable use policy',
  },
  {
    id: '#cookie-policy',
    title: 'Cookie Policy',
  },
  {
    id: '#privacy-policy',
    title: 'Privacy Policy',
  },
  {
    id: '#disclaimer-limitations-of-liability',
    title: 'Disclaimers; Limitation of Liability',
  },
  {
    id: '#miscellaneous1',
    title: 'Miscellaneous',
  },
];

export const termsAndConditionData = {
  title: 'Terms and Conditions',
  // dateUpdated: `Last updated on ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`,
  dateUpdated: 'Last updated on March 2, 2024',
  termsAndConditionList:
    'The Customer Terms of Service is a contractual agreement governing the use of ProLegion Pvt Ltd’s product www.prolegion.com & its services (hereinafter represented as ‘ProLegion’). It includes General Terms (core legal and commercial terms), Jurisdiction Specific Terms (location-specific terms), Data Processing Agreement (explaining data processing), and Acceptable Use Policy (rules for product/service usage). The Order Form details purchases and is accessible in the Accounts and Billing section. Users agree to these terms when using ProLegion & its services , with updates notified via in-app or email for active subscriptions. ',

  termsAndConditionsContent: [
    {
      id: 'defintions',
      title: 'Definitions',
      content: [
        '"Add-Ons" are additional product enhancements available for purchase. "Affiliate" refers to entities with a direct or indirect relationship. The "Agreement" includes General Terms and linked materials. "Authorized Payment Method" is a valid payment accepted by Nice Softech Inc. . "Billing Period" is the prepaid fee period. "Confidential Information" includes private data, excluding certain exceptions. "Contact" is an individual in the Subscription Service. "Consulting Services" are professional services provided by Nice Softech Inc. . "Customer Data" is information submitted via the Subscription Service. "DPA" is the Data Processing Agreement. "Free Services" are unpaid trial services. "Nice Softech Inc. Content" is incorporated information in the Subscription Service. "Jurisdiction-Specific Terms" apply based on location. "Order" is the subscription agreement form. "Personal Data" is information related to an individual. "Privacy Policy" is available on the Nice Softech Inc. website. "Product and Services Catalog" lists offerings. "Sensitive Information" includes confidential data. "Subscription Fee" is the payment for the Subscription Service. "Subscription Service" includes web-based applications and platforms. "Subscription Term" is the subscription duration. "Third-Party Products" are external products and services. "Third-Party Sites" are linked websites. "Total Committed Subscription Value" is the aggregate fee for Subscription Services. "Users" are authorized individuals. "Nice Softech Inc. " refers to the contracting entity. "You" or "Customer" is the entity using the services.',
      ],
    },
    {
      id: 'use-of-service',
      title: 'Use of Service',
      content: [
        'During the Subscription Term, users are granted access to the Subscription Service per the Agreement and Order. Free Services may be activated at any time. Users must comply with the Agreement, and access can be extended to Affiliate\'s Users with the primary user remaining liable. Additional features can be subscribed to through an Order or within the Nice Softech Inc. account. Users must be at least 18 years old. Users should not use the Subscription Service for Sensitive Information or in violation of specific laws. Unauthorized use must be reported promptly. The service is not designed for industry-specific regulations. Users must not collect, manage, or process Sensitive Information. Customer responsibilities include active participation for the full value of the Subscription Service. A free trial is available, and legacy products may be upgraded by executing a new Order.',
      ],
    },
    {
      id: 'website-terms-of-use',
      title: 'Website Terms of Use',
      content: [
        'Users are expected to adhere to these terms when accessing information about Nice Softech Inc.\'s products, services, and opportunities. The document covers various aspects, including permitted and prohibited uses of the site, copyrights and trademarks, user-posted materials, links to third-party websites, downloading files, disclaimers, limitations of liability, and indemnification.',
        'Users are granted limited rights to use the site for non-commercial, personal purposes, with strict prohibitions on unauthorized use, copying, and automated access. The terms emphasize the protection of intellectual property rights, including copyrights and trademarks owned by Nice Softech Inc.. Users posting materials on the site are required to have the necessary rights and permissions for the content.',
        'The document also includes disclaimers about the site\'s suitability, security, and accuracy, and limits Nice Softech Inc.\'s liability for damages. Indemnification clauses hold users responsible for their behavior on the site. Additional terms cover user profiles, age requirements, and adherence to community rules.',
        'Nice Softech Inc. reserves the right to suspend or terminate accounts of users violating intellectual property rights. The document is dated December 2, 2023, and is subject to modification without prior notice.',
      ],
    },
    {
      id: 'fees',
      title: 'Fees',
      content: [
        'Subscription Fees are fixed during the initial term unless certain conditions occur, such as exceeding limits or upgrading products. Fee adjustments may occur at renewal, with notifications sent 30 days in advance. Payment is by credit card, and failed attempts may lead to suspension or termination. Invoice payments are due within 30 days. Users must keep information up to date on the Billing Page. All fees are non-refundable and non-cancelable. Sales tax and withholding tax details are provided, with withholding tax allowed under certain conditions. Non-payment may cause suspension of termination of account.',
      ],
    },
    {
      id: 'invoices',
      title: 'Invoices',
      content: [
        'In the case of invoice payments, we will issue invoices no later than forty-five (45) days before the start of the Subscription Term and each subsequent Billing Period, as well as at other intervals within the Subscription Term when fees are applicable. Payment for all invoiced amounts is required within a period of thirty (30) days from the invoice date, unless otherwise stipulated in the Order Form.',
      ],
    },
    {
      id: 'acceptable-use-policy',
      title: 'Acceptable use Policy',
      content: [
        'Nice Softech Inc.\'s Acceptable Use Policy governs the utilization of its products, services, and websites, requiring compliance with applicable laws and regulations. It aims to safeguard the interests of clients, their customers, and Nice Softech Inc.\'s reputation. Users must agree to these terms to access Nice Softech Inc. services.',
        'The policy encompasses various provisions, including reporting suspected violations, prohibitions on spam, email opt-out requirements, regulations on telephone and SMS marketing, and guidelines for proper usage of the Nice Softech Inc. Service. Restricted industries, such as cryptocurrency and gambling, may face discontinuation of services to protect against abuse.',
        'Users are responsible for reporting violations and must adhere to laws governing telephone marketing and recording. The Acceptable Use Policy emphasizes non-disruption, outlining actions forbidden to maintain the normal operation and security of others\' property. Proper usage standards include refraining from deceptive, false, or fraudulent activities and compliance with intellectual property rights.',
        'The application is explicitly designed for desktop usage and is not intended for utilization on mobile devices or any other portable hardware. While the application may exhibit responsiveness on such portable devices, it is acknowledged that not all features may replicate uniformly. The full range of functionalities is guaranteed exclusively on desktop platforms.',
      ],
    },
    {
      id: 'terms-and-termination',
      title: 'Term And Termination',
      content: [
        'The initial subscription term is specified in the Order and automatically renews unless notice of non-renewal is given. Early cancellation is allowed, but prepaid fees are non-refundable. Termination for cause may occur with notice, and suspension can happen for prohibited acts, non-payment, or present harm. Free Services may be suspended or terminated without notice. After termination or expiration, Free Services may still be available, but the paid Subscription Service and Nice Softech Inc. Content must be stopped.',
      ],
    },
    {
      id: 'cookie-policy',
      title: 'Cookie Policy',
      content: [
        'ProLegion  .\'s Cookie Policy outlines the use of cookies and similar technologies on its websites. Cookies are small files that recognize users when they revisit a site, storing preferences and information.',
        'The reasons for using cookies include essential site functionality, performance enhancement, analytics for understanding user behaviour, and advertising customization. Users are categorized into groups like those who accept or reject cookies, and control options are provided. Essential website cookies, necessary for site delivery, cannot be refused.',
        'The policy also mentions other tracking technologies like clear gifs, which track online movements, and clarifies that ProLegion  . does not use Flash cookies but acknowledges that customers using its software may employ them. Additionally, third parties may serve cookies for targeted advertising, with user information not revealing personal details unless voluntarily provided.',
      ],
    },
    {
      id: 'customer-data',
      title: 'Customer Data',
      content: [
        'Customers own and retain all rights to Customer Materials and Customer Data. ProLegion  . does not have ownership rights but is granted permission to use them to provide the Subscription Service and Consulting Services. The use of Customer Data for machine learning is allowed, but customers can opt-out. The Privacy Policy provides more details on data practices. The Data Processing Agreement applies to Customer Data containing Personal Data, outlining processing methods and security measures. Regional Data Hosting and Customer Data Transfers are subject to policies. Information on retention, deletion, and retrieval of Customer Data is outlined in the Data Processing Agreement. ',
      ],
    },
    {
      id: 'product-catalog',
      title: 'Product Catalogue',
      isProductCatalog: true,
      content: [
        {
          title: 'For IT Consultants:',
          titleContent: [
            'Profile creation and management: Create and maintain personal profiles showcasing skills and experience.',
            'Job search and application: Search for available job opportunities and submit applications.',
            'Timesheet management: Record and manage worked hours for accurate billing and payments.',
            'Profile Builder: Profile builder helps to build your resume , display past projects and achievements to attract potential clients.',
          ],
        },
        {
          title: 'For IT Vendors:',
          titleContent: [
            'Resource management: Efficiently manage and allocate available resources.',
            'Job posting and management: Advertise available job positions and oversee the hiring process.',
            'Client engagement tools: Tools to interact and collaborate with clients effectively.',
            'Timesheet management: Record and manage worked hours for accurate billing and payments.',
            'Invoicing and payment solution: Create invoices and manage payments for services rendered. ProLegion uses Stripe as a partner to process invoice payments.',
          ],
        },
        {
          title: 'For IT Clients:',
          titleContent: [
            'Job posting and management: Post job requirements and oversee the hiring process.',
            'Access to qualified consultants: Connect with skilled professionals suited for specific tasks.',
            'Timesheet management: Record and manage worked hours for accurate billing and payments.',
            'Contract jobs management : Manage the contractual jobs along with vendors and resources.',
            'Vendor evaluation and selection: Evaluate and select suitable vendors based on specific criteria and needs.',
          ],
        },
      ],
    },
    {
      id: 'terms-of-use',
      title: 'Terms Of Use',
      content: [
        'The ProLegion  . Terms of Use (TOU) apply to users, App Partners, Solutions Partners, Template Providers, and clients engaging with the ProLegion  . Solutions Directory, App Marketplace, and Template Marketplace. Users must comply with these TOU. Users will be responsible for any violations, and cannot assist others in violating the TOU. ProLegion  . may enforce compliance through appropriate methods and reserves the right to modify the TOU without notice.',
        'The document defines key terms, including App Partner, Customer, ProLegion  . Marketplace, Service Offerings, Service Providers, Solutions Partner, and Template Provider. It outlines access to the ProLegion  . Marketplace, acceptable conduct, and provisions for customers and service providers. The TOU addresses reviews, proprietary rights, and disclaimers, emphasizing that ProLegion  . products and services are provided "as is." Additionally, it covers termination, user representations, indemnification, limitations of liability, and general provisions.',
        'Users are encouraged to review the TOU regularly, and failure to agree with modifications may result in restricted use of the ProLegion  . Services. The document is governed by Texas law, and certain sections survive termination. It concludes with disclaimers, limitations of liability, and general provisions, emphasizing that the English version prevails in case of discrepancies in translations.',
      ],
    },
    {
      id: 'intellectual-property',
      title: 'Intellectual Property',
      content: [
        'This agreement grants access to the Subscription Service without a software license. ProLegion  . retains all intellectual property rights to its content, services, and products. Users are prohibited from copying, renting, selling, or creating derivative works without written authorization. The use of ProLegion’s. trademarks requires prior written permission. Customers are encouraged to provide feedback, but comments and suggestions become non-confidential, with ProLegion  . owning the rights to use and incorporate them into its services without payment or attribution.',
      ],
    },
    {
      id: 'confidentiality',
      title: 'Confidentiality',
      content: [
        'The Receiving Party must protect the confidentiality of the Disclosing Party\'s information, using a degree of care equal to or greater than its own confidential information. The Receiving Party shall not use the disclosed information for purposes beyond this Agreement, disclose it to third parties (except for necessary service providers or bound ProLegion  . Solutions Partners), and limit access to those who need it for Agreement-related purposes. The Receiving Party may disclose Confidential Information if required by law, provided it gives prompt notice to the Disclosing Party and assists in opposing the disclosure, unless legally prohibited. The Receiving Party must not disclose information to a party other than a government agency without a valid court order.',
      ],
    },
    {
      id: 'privacy-policy',
      title: 'Privacy Policy',
      content: [
        'The ProLegion  . Privacy Policy governs the handling of personal information collected during interactions with ProLegion  . \'s websites, products, and services. It encompasses interactions on websites, product and service usage, and engagement with other linked sites. The policy outlines the collection, use, storage, sharing, and processing of Personal Data, defined as information related to an identified or identifiable individual. Users who disagree with the policy are advised not to use ProLegion  . \'s offerings. The Subscription Service, comprising web-based applications and tools, involves data processing by ProLegion  . as a processor under customer direction. Customers, as data controllers, bear responsibility for compliance when collecting Personal Data through the Subscription Service. The policy undergoes periodic updates, with material changes communicated through prominent notices, emails, or ProLegion  . account notifications. Terms not explicitly defined in the Privacy Policy hold meanings as specified in the ProLegion  . Customer Terms of Service.',
      ],
    },
    {
      id: 'collection-of-information',
      title: 'Collection of Information',
      content: [
        'ProLegion  . collects Personal Data through various interactions, including website use, product and service utilization, and event registrations. Information may include names, email addresses, job titles, and other relevant business details. Users voluntarily provide data through web forms, event registrations, account creation, and payment processes. ProLegion  . processes Customer Data on behalf of customers using the Subscription Service, with customers controlling data access and compliance. Usage Data is collected during Subscription Service interactions, tracking features usage and mobile application details. Data from partners, third-party service providers, and log files are also collected. ',
      ],
    },
    {
      id: 'use-of-personal-data',
      title: 'Use Of Personal Data',
      content: [
        'ProLegion  . utilizes Personal Data for various purposes, including communication about products and services, providing the Subscription Service, improving products and services, ensuring security, and marketing. Communication involves transactional emails, in-app notifications, and customer support. The Subscription Service utilizes account information and Customer Data, while usage data aids product development. Machine learning enhances features, and aggregated data insights may be shared externally. Account information is used for security investigations and compliance. Marketing efforts include promotional content through email, post, and other means. Opt-out options are provided. Third-party information and consent-based data sharing are mentioned. Legal bases for processing in the EEA and UK include consent, contract fulfillment, legitimate interests, and legal obligations.',
      ],
    },
    {
      id: 'transferring-personal-data-intentionally',
      title: 'Transferring Personal Data Internationally',
      isLink: true,
      content: [
        {
          data: 'ProLegion follows the standard Data Privacy Framework. ProLegion  .\'s Privacy Policy covers international data transfers within its group and to third parties. For transfers within the ProLegion  . group, safeguards include the EU-U.S. Data Privacy Framework, UK Extension, Swiss-U.S. Data Privacy Framework, Standard Contractual Clauses, and additional measures like encryption. Third-party transfers involve legal mechanisms such as Standard Contractual Clauses and the UK Addendum. ProLegion  . adheres to the EU-U.S. Data Privacy Framework, UK Extension, and Swiss-U.S. Data Privacy Framework certified by the U.S. Department of Commerce. Individuals in the EU, UK, and Switzerland have rights to access, correct, and ask for the deletion of their data. Complaints related to Data Privacy Framework Principles are addressed by ProLegion  ., and binding arbitration is an option. ProLegion  . is responsible for onward transfers of personal data to third parties. Disclosure may occur under lawful requests from public authorities for national security purposes or law enforcement purposes. For more details, visit the Data Privacy Framework site.',
          link: 'https://www.dataprivacyframework.gov/s/',
        },
      ],
    },
    {
      id: 'privacy-right-choice-of-users',
      title: 'Privacy rights & Choices of Users',
      content: [
        'You have the right to access, correct, and delete your Personal Data, object to processing, and withdraw consent. Contact ProLegion  . or relevant brands through specified channels. No personal information is sold. ProLegion  . responds to requests promptly, verifying your identity. Discrimination-free exercise of rights is ensured. The Anti-Spam Policy prohibits unsolicited commercial emails and mandates opt-out mechanisms. Unsubscribe from marketing communications via provided methods. Customers can\'t opt out of transactional emails related to their accounts.',
      ],
    },
    {
      id: 'privacy-rights-ca',
      title: 'Privacy Rights (California)',
      content: [
        'This section applies to California consumers under the California Consumer Privacy Act (CCPA). ProLegion  . doesn\'t sell Personal Information. Collected categories include identifiers, commercial information, internet history, geolocation, financial info, inferences, and other data. California residents have rights to access, delete, correct, and non-discrimination. ProLegion  . verifies identity for requests. Contact for alternative format, exercising rights, privacy info, or authorizing an agent.',
      ],
    },
    {
      id: 'storing-and-securing-personal-data',
      title: 'Storing & Securing Personal Data',
      content: [
        'ProLegion  . employs security measures to safeguard Personal Data, stored on secure servers in controlled environments. Information retention varies based on type and collection method. Data is deleted, anonymized, or securely stored after a reasonable period, depending on ongoing legitimate business needs, legal obligations, dispute resolution, and agreement enforcement. Marketing preferences are retained for a reasonable duration from the last expression of interest, and data from cookies and tracking technologies is kept for a reasonable time from creation. Customer data processed in the Subscription Service aligns with the ProLegion  . Customer Terms of Service.',
      ],
    },
    {
      id: 'miscellaneous',
      title: 'Miscellaneous',
      isMisc: true,
      content: [
        {
          subtitle: '',
          data: 'We never sell your Personal Data. Our websites and services are not for children under 16. Contact us about data on children. For privacy concerns, use the U.S.-based third-party dispute resolution provider.',
        },
        {
          subtitle: 'Limitation of Performance Warranty:',
          data: 'The Subscriber acknowledges and agrees that the features provided in connection with the subscription are illustrative and serve as a representation of potential outcomes under ideal conditions. The Company does not provide any assurance or guarantee regarding the functionality and associated benefits of the product. In no event shall the Company be held liable for any absence or non-attainment of such functionality and its benefits. The Subscriber expressly understands and accepts the inherent uncertainties and variables that may affect the performance of the product.',
        },
      ],
    },
    {
      id: 'indemnification',
      title: 'Indemnification',
      content: [
        'You are responsible for defending and indemnifying us and our Affiliates against third-party claims arising from unauthorized or illegal use of the Subscription Service, noncompliance with this Agreement, use of Third-Party Products, or unauthorized use by others with your User information. We will promptly inform you about any such claim, let you control the defense, and provide assistance as needed. You cannot accept settlements that impose obligations, require admissions, or impose liability not covered by these indemnifications without our written consent.',
      ],
    },
    {
      id: 'data-processing-terms',
      title: 'Data Processing Terms',
      content: [
        'The ProLegion  . Data Processing Agreement (DPA) outlines the understanding between the user and ProLegion  . regarding the processing of personal data within the framework of the ProLegion  . Subscription Services, as defined in the Customer Terms of Service. The DPA serves as a supplementary and integral component of the overall Agreement, becoming effective upon its inclusion in the Agreement, which can be specified in the Agreement itself, an Order Form, or an executed amendment. In situations of conflict or inconsistency between the DPA and the Agreement terms, the DPA takes precedence.',
        'ProLegion  . reserves the right to update these terms periodically. Subscribers with active ProLegion  . subscriptions will be notified of any changes through email (for those who opted for email notifications) or in-app notifications. Archived versions of the DPA can be accessed for reference. This structure ensures transparency and compliance with data processing regulations, with the DPA acting as a crucial document governing the specificities of personal data processing within the scope of the broader contractual relationship.',
        'The document outlines key definitions and responsibilities related to data processing between a user (referred to as "you") and ProLegion  .. It defines terms like "California Personal Information," "CCPA" (California Consumer Privacy Act), "Controller," and others. The Data Privacy Framework and Data Privacy Framework Principles, as well as European Data Protection Laws, are referenced.',
        'Customer responsibilities include compliance with data protection laws, ensuring accuracy and legality of customer data, obtaining necessary consents, and informing ProLegion  . if unable to meet these obligations. The document emphasizes the importance of user instructions as detailed in the Agreement.',
        'ProLegion  .\'s obligations include processing personal data in line with instructions, notifying users of legal requirements affecting processing, implementing security measures to prevent personal data breaches, and maintaining confidentiality. ProLegion  . commits to notifying users of any personal data breaches promptly.',
        'The document addresses the deletion or return of customer data upon termination, with exceptions for legal requirements. It advises users to retrieve data before the end of the subscription term and offers assistance if needed.',
        'The document covers Sub-Processors, stating that ProLegion  . may engage them to process personal data in three ways: for hosting and infrastructure, supporting product features and integrations, and as ProLegion  . Affiliates for service and support. Some Sub-Processors apply by default, while others require user opt-in.',
        'The document also addresses data transfers, acknowledging global processing of personal data by ProLegion  . to provide services, including transfers to the United States and other jurisdictions. Both parties commit to ensuring transfers comply with Data Protection Laws.',
        '"Demonstration of Compliance," grants users access to information verifying ProLegion  .\'s compliance and allows audits by users or their auditors. ProLegion  . highlights its hosting Sub-Processors\' independently validated security programs and annual audits for SOC 2 compliance.',
        'The document introduces additional provisions for European Data. It clarifies roles as Controller and Processor, offers assistance in impact assessments and consultations, and outlines transfer mechanisms, including the Data Privacy Framework, Standard Contractual Clauses, and the UK Addendum. Users may exercise audit rights annually, and alternative transfer mechanisms can be adopted if required, with users agreeing to comply with legal processes for such changes.',
        '"Additional Provisions for California Personal Information," the document focuses on the processing of California Personal Information under the California Consumer Privacy Act (CCPA). It specifies roles, responsibilities, and commitments, with ProLegion  . designated as a Service Provider and the user as a Business. ProLegion  . commits to processing California Personal Information strictly for the Subscription and Consulting Services\' Business Purpose, avoiding sale or sharing of such information and not processing it beyond the direct business relationship. The user is granted rights for CCPA audits and remediation steps.',
        'ProLegion  . retains the right to update the DPA, with the terms for amendments outlined.',
      ],
    },
    {
      id: 'disclaimer-limitations-of-liability',
      title: 'Disclaimers: Limitation of Liability',
      isDefaultList: true,
      content: [
        {
          heading: 'Performance Warranty:',
          text: 'We warrant that the Subscription Service and Consulting Services will meet industry standards and be free from viruses. If there\'s a non-conformance, we\'ll try to correct it within 60 days; if not, you can terminate the agreement.',
        },
        {
          heading: 'Disclaimer of Warranties:',
          text: 'Except for the performance warranty, we make no warranties about the Subscription Service, ProLegion  . Content, or Consulting Services. They are provided "as is" without any warranty.',
        },
        {
          heading: 'No Indirect Damages:',
          text: 'Neither party will be liable for indirect damages, loss of profits, or business opportunities.',
        },
        {
          heading: 'Limitation of Liability:',
          text: 'Except for payment obligations, intellectual property violations, and indemnification, liability is limited to the total amounts paid in the twelve months preceding the claim.',
        },
        {
          heading: 'Third Party Products:',
          text: 'We and our affiliates disclaim liability for third-party products used by you.',
        },
        {
          heading: 'Agreement to Liability Limit:',
          text: 'You agree that without this liability limit, we wouldn\'t provide the Subscription Service to you.',
        },
        {
          heading: 'Additional Coverage Terms:',
          text: 'For a Total Committed Subscription Value exceeding $35,000, additional coverage terms in Appendix 1 apply.',
        },
      ],
    },
    {
      id: 'miscellaneous1',
      title: 'Miscellaneous',
      isDefaultList: true,
      content: [
        {
          heading: 'Amendment; No Waiver:',
          text: 'We can modify the agreement with notice. If you disagree with changes, notify us within 30 days; otherwise, it applies at renewal. No delay in exercising rights is a waiver.',
        },
        {
          heading: 'Force Majeure:',
          text: 'Neither party is responsible for failure due to uncontrollable events. Reasonable efforts will be made to mitigate the impact.',
        },
        {
          heading: 'Actions Permitted:',
          text: 'Except for nonpayment or proprietary rights breaches, no action related to the agreement may be brought after one year.',
        },
        {
          heading: 'Relationship of the Parties:',
          text: 'No joint venture, partnership, employment, or agency relationship exists between parties.',
        },
        {
          heading: 'Compliance with Laws:',
          text: 'Both parties must comply with applicable laws. ProLegion  . follows a Code of Business Conduct and Ethics. Users must comply with laws, including export laws',
        },
        {
          heading: 'Severability:',
          text: 'If part of the agreement is invalid, it will be replaced with a valid provision; the rest of the agreement remains in effect.',
        },
        {
          heading: 'Notices:',
          text: 'Notices to ProLegion  . are sent to the provided contact address. Notices to you are sent to your address in the account information. Keep account information current.',
        },
        {
          heading: 'Entire Agreement:',
          text: 'The agreement and Privacy Policy supersede all other agreements. Additional terms proposed by you are rejected. English version governs; translations are for convenience.',
        },
        {
          heading: 'Assignment:',
          text: 'You can\'t assign without consent. We may assign to affiliates or in mergers.',
        },
        {
          heading: 'No Third Party Beneficiaries:',
          text: 'The agreement does not confer rights to any third party.',
        },
        {
          heading: 'Contract for Services:',
          text: 'The agreement is for services, not goods. UCC and UCITA do not apply. If outside the U.S., the UN Convention on Contracts for the International Sale of Goods does not apply.',
        },
        {
          heading: 'Authority:',
          text: 'Both parties have the authority to enter into and enforce the agreement.',
        },
        {
          heading: 'Insurance:',
          text: 'ProLegion  . will maintain liability insurance during the agreement.',
        },
        {
          heading: 'U.S. Government Entities:',
          text: 'If you are a U.S. government entity, additional terms apply.',
        },
        {
          heading: 'Survival:',
          text: 'Certain sections survive expiration or termination, including those related to definitions, fees, termination, suspension, intellectual property, confidentiality, indemnification, disclaimers, limitations of liability, and miscellaneous.',
        },
        {
          heading: 'Precedence:',
          text: 'In case of conflict between the agreement and an order, the order controls only for that order.',
        },
      ],
    },
    {
      id: 'additional-coverage-terms',
      title: 'Additional Coverage Terms:',
      isNestedList: true,
      content: [
        {
          heading:
            'Condition: If your Total Committed Subscription Value exceeds $35,000, these terms apply; otherwise, they don\'t. We can update these terms as outlined in the \'Amendment; No Waiver\' section.',
          list: [
            {
              listTitle: 'ProLegion  . Indemnification:',
              listData: [
                'We indemnify you against third-party actions based on Subscription Service infringement or misuse of Customer Data.',
                'You must notify us, provide necessary assistance, and let us control the defense.',
                'Our liability excludes certain claims, and we may resolve claims by modifying the service, providing a non-infringing substitute, or terminating access with a refund.',
                'This section constitutes our entire liability and your exclusive remedy for such claims.',
              ],
            },
            {
              listTitle: 'Limitation of Liability:',
              listData: [
                'In addition to the \'Disclaimers; Limitations of Liability\' section, this part specifies that aggregate liability for both parties is limited to the total amounts paid for the Subscription Service in the twelve months before the claim, except for specific liabilities outlined in the agreement.',
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'us-govt-customer-additional-terms',
      title: 'U.S. Government Customer Additional Terms:',
      isNestedList: true,
      content: [
        {
          heading: 'Condition: If your Total Committed Subscription Value exceeds $35,000, these terms apply; otherwise, they don\'t. We can update these terms as outlined in the \'Amendment; No Waiver\' section.',
          list: [
            {
              listTitle: 'Applicability:',
              listData: [
                'Applies to U.S. local, state, or federal government entities using ProLegion .products.',
                'Subject to updates as outlined in the \'Amendment; No Waiver\' section.',
                'Governs as required by applicable law.',
              ],
            },
            {
              listTitle: 'Government Customer Purpose:',
              listData: [
                'Limits usage to governmental-related purposes.',
                'Not applicable for private, personal, or non-governmental purposes.',
              ],
            },
            {
              listTitle: 'Indemnification:',
              listData: [
                'Government Customer\'s indemnification obligations follow applicable law.',
                'Not applicable for private, personal, or non-governmental purposes.',
              ],
            },
            {
              listTitle: 'Limitation of Liability:',
              listData: [
                'Limits of liability apply per the \'Disclaimers; Limitations of Liability\' section.',
              ],
            },
            {
              listTitle: 'Contracting Entity and Applicable Law:',
              listData: [
                'Contracting with ProLegion.',
                'Governed by applicable laws for Government Customer or, if unspecified, the laws of the state of texas, U.S.A.',
              ],
            },
          ],
        },
      ],
    },
  ],
};
