import themeBase from "../themeBase";

export const MuiInputLabelOverride = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {
      ...themeBase.typography.body2Semibold,
      color: '#111928',
      transform: 'none',

      "&.Mui-focused": {
        color: themeBase.palette.primary.main,
      },
    },
  },
};
