import React from 'react';
import Typography from './Typography';

const CustomInput = ({ id = `${Math.random() * 9999}`, label = '' ,validationType="",
  value,
  onChange,
  type = 'text',
  error,
  required,
  className = '',
  parentClassName = '',
  ...rest }) => {
  return (
    <div className={`${parentClassName}`}>
         {label && <label className='font-semibold' htmlFor={rest.name}>{label} {required && "*"}</label>}
    <input
      {...rest}
      id={id}
      value={value}
      onChange={onChange}
      type={type}
      className={`appearance-none text-sm border focus:border-[unset] focus:outline-none active:outline-none rounded-md w-full h-[42px] px-3 text-gray-700 leading-tight ${className}`}
      />
      {error && <Typography className='text-red-500 text-xs mt-1'>{error}</Typography>}
      </div> 
  );
};

export default CustomInput;
