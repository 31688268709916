import React from 'react';
import Typography from './Typography';

const CustomSelect = ({ label, options = [], error, value, onChange, ...rest }) => {
    return (
      <div>
        {label && <label className="font-semibold mb-1 block">{label}</label>}
        <select
          className="appearance-none text-sm border focus:outline-none rounded-md w-full h-[42px] px-3 text-gray-700 leading-tight"
          value={value}
          onChange={onChange}
          {...rest}
        >
          <option value="">Please Select</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        {error && <Typography className='text-red-500 text-xs mt-1'>{error}</Typography>}
      </div>
    );
};

export default CustomSelect;
