import { createTheme } from '@mui/material/styles';
import paletteOverrides from './paletteOverrides';
import typographyOverrides from './typographyOverrides';

const themeBase = createTheme({
  typography: typographyOverrides,
  palette: paletteOverrides,
});

export default themeBase;
