import React, { useState, useEffect } from 'react';
import TimerIcon from '@mui/icons-material/Timer';
import Typography from 'Components/Common/semantic_tags/Typography';
import Button from 'Components/Common/semantic_tags/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfileDetails } from 'Redux/Feature/profile/profile.selector';
import dayjs from 'dayjs';
import { fetchActiveSubscription } from 'services/subscriptions.service';
import { selectIsUserLoggedIn, setLogout } from 'Redux/Feature/user';
import MuiModal from 'Components/Common/MuiModal';
import PricingCards from 'Pages/Settings/UserManagementTab/PricingPlans.jsx/PricingCards';
import { removeDataFromLocalStorage } from 'Utils/helper';
import api from 'Utils/api';
import { useDispatch } from 'react-redux';
import LogoutIcon from 'Components/Icons/Logout.icon';

const FreeTrialBanner = ({ expanded }) => {
    const { isAccountApprovedDate = null } = useSelector(selectProfileDetails);
    const [daysLeft, setDaysLeft] = useState(null);
    const isLoggedIn = useSelector(selectIsUserLoggedIn)
    const dispatch = useDispatch()
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [alreadySubscribed, setAlreadySubscribed] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) return
        fetchActiveSubscription().then(({ data }) => {
            if (data.subscription) setAlreadySubscribed(data.subscription)
            else setAlreadySubscribed(null)
            setLoading(false)
        });
    }, [isLoggedIn])

    useEffect(() => {
        if (isAccountApprovedDate) {
            const approvedDate = dayjs(isAccountApprovedDate);
            const currentDate = dayjs();
            const trialEndDate = approvedDate.add(15, 'days');
            const daysRemaining = trialEndDate.diff(currentDate, 'day');

            if (daysRemaining > 0) {
                setDaysLeft(daysRemaining);
            } else {
                setDaysLeft(0);
            }
        }
    }, [isAccountApprovedDate]);

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const logout = () => {
        removeDataFromLocalStorage();
        delete api.defaults.headers.Role;
        delete api.defaults.headers.Authorization;
        dispatch(setLogout());
        navigate('/');
      };

    if (loading) return <></>

    if (alreadySubscribed) return null

    if (!loading && (daysLeft === null || daysLeft <= 0) && (!alreadySubscribed)) {
        return <MuiModal className='relative max-w-6xl' open={true}>
            <Typography className='text-center text-gray-600 text-2xl font-bold'>Unlock Your Potential with Prolegion</Typography>
            <div onClick={logout} className='absolute right-4 top-4 flex gap-2 items-center cursor-pointer'><LogoutIcon /> Logout</div>
            <Typography className='text-center text-xs max-w-[60%] mx-auto mt-2'>Upgrade to Prolegion for exclusive access to advanced tools, premium features, and personalized support. Enhance your skills, stay ahead with industry insights, and connect with professionals. Start your journey to excellence today by unlocking the full potential of Prolegion. Your future awaits!</Typography>
            <PricingCards className='!min-h-[unset]' roles={'VENDOR'} activeSubscription={alreadySubscribed} />
        </MuiModal>
    }

    return (
        <div className='relative'>
            <div
                className="flex items-center justify-center text-center m-4 gap-2 cursor-pointer"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {expanded ? (
                    <Typography className='text-[#3985B6] font-bold text-sm'>
                        <TimerIcon /> Free trial {daysLeft} days left
                    </Typography>
                ) : (
                    <Typography className='text-[#3985B6] font-bold text-xs flex items-center'>
                        <TimerIcon /> {daysLeft}
                    </Typography>
                )}
            </div>
            {isTooltipVisible && (
                <div 
                    className="fixed border left-[13rem] ml-4 bottom-10 w-64 bg-white rounded-lg shadow-lg p-4 z-[999]"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <img src='https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/Layer+1+(3).png' />
                    <Typography className="font-bold text-lg mb-2">Subscribe Now</Typography>
                    <Typography className="text-sm mb-4">
                        Access premium features by subscribing before your trial ends.
                    </Typography>
                    <Button title='Subscribe Now' onClick={() => (handleMouseLeave(), navigate('/dashboard/settings?screen=plans'))} />
                </div>
            )}
        </div>
    );
};

export default FreeTrialBanner;
