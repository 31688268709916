import themeBase from "../themeBase";

export const MuiLinkOverride = {
  styleOverrides: {
    root: {
      cursor: "pointer",
      color: themeBase.palette.primary.dark,
      ":hover": {
        // color: "rgb(19, 149, 136)",
        textDecoration: "underline",
      },
    },
  },
  variants: [
    {
      props: { variant: "body1" },
      style: {
        // color: "rgb(19, 149, 136)",
      },
    },
  ],
};
