import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAlertProvider } from "Providers/util/Alert";
import { Box } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from "Components/Common/semantic_tags/Typography";

function AddNewSprint({
  edit = false,
  sprintValue = "add new sprint",
  id = null,
}) {
  const params = useParams();
  const { error } = useAlertProvider();
  const dispatch = useDispatch();
  const [value, setValue] = useState(sprintValue);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setValue(sprintValue);
  }, [sprintValue]);

  const blurHandler = async (e) => {
    if (value === "add new sprint") {
      setIsEdit(false);
      return;
    }
    if (!value || value === sprintValue) {
      setIsEdit(false);
      return;
    }
    // const resp = edit
    //   ? await dispatch(updateSprint({ sprint_name: value }, id))
    //   : await dispatch(
    //       createNewSprint({ project_id: params.id, sprint_name: value })
    //     );
    if (!resp.success) {
      error(`Sprint not ${edit ? "Update" : "Create"}`);
    }
    !edit && setValue("add new sprint");
  };

  const changeHandler = (e) => {
    setValue(e.target.value);
  };

  const editHandler = () => {
    setIsEdit(true);
  };

  return (
    <div className="bg-[#3985B6] text-white px-[20px] py-[10px] rounded-lg">
      <Box textAlign={'center'}>
          <Typography
            className="text-center font-bold text-sm"
            onClick={editHandler}
          >
            {edit ? value : <><AddCircleIcon /> Add new lane</>}
          </Typography>
      </Box>
    </div>
  );
}

export default AddNewSprint;
