import { Box } from "@mui/material"
import TaskWrapper from "Pages/Task"
import { useRouteLoaderData } from "react-router-dom"

const TaskWrapperInJob = () => {
    const data = useRouteLoaderData('jobDetail');
    return <Box>
        <TaskWrapper isDataFromDifferentComponent={false} resource_id={data?.data?.result?.consultant?.id || ''} />
    </Box>
}

export default TaskWrapperInJob