import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HttpStatusCode } from 'axios';
import { 
  createSubscription, 
  createSubscriptionCustomer, 
  fetchCustomerDetails, 
  fetchPlans 
} from 'services/subscriptions.service';
import { 
  selectIsUserLoggedIn, 
  selectUserDetails, 
  selectUserRole
} from 'Redux/Feature/user';
import api from 'Utils/api';
import { useAlertProvider } from 'Providers/util/Alert';

const usePayment = (billingCycle, setCurrency, setPlanDetails, activeSubscription, isPublic, setBillingCycle, roles = '') => {
  const { first_name, last_name, email } = useSelector(selectUserDetails);
  const isLoggedIn = true;
  const { role } = useSelector(selectUserRole)
  const [loading, setLoading] = useState(false);
  const [paymenLoading, setPaymentLoading] = useState(false)
  const navigate = useNavigate();
  const [isStripe, setIsStripe] = useState(false);
  const { success, error } = useAlertProvider()

  const fetchOrCreateCustomer = useCallback(async () => {
    try {
      return await fetchCustomerDetails();
    } catch (error) {
      if (error.response.status === HttpStatusCode.NotFound) {
        const paymentType = isStripe ? "STRIPE" : "RAZORPAY";
        return await createSubscriptionCustomer({}, paymentType);
      }
    }
  }, [isStripe]);

  const handleBillingCycleChange = async () => {
    isLoggedIn && await fetchPlansHandler(billingCycle === 'monthly' ? 'yearly' : 'monthly')
    setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
  }

  const onBuySubscriptionHandler = useCallback(async (details) => {
    if (details?.isFree) {
      if (isLoggedIn) return navigate('/dashboard');
      return navigate('/signin');
    }
    if (isLoggedIn && isPublic) return navigate('/dashboard/settings?screen=plans');
    else if (isPublic) return navigate('/signin');
    if (loading) return
    setLoading(true)
    try {
      let payload = {}
      if (isStripe) {
        payload = {
          success_url: `${window.location.origin}/dashboard/settings?screen=list`,
          cancel_url: `${window.location.origin}/dashboard/settings?screen=list`,
          plans: [{
            name: details.product_name,
            price_id: details.price_id,
            quantity: 1
          }]
        };
      }
      const { data: customerData } = await fetchOrCreateCustomer();
      const { data: subscription } = await createSubscription(customerData.id, isStripe ? payload : { id: details.price_id }, isStripe);
      if (isStripe) {
        window.location.href = subscription.url;
      } else {
          const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              subscription_id: subscription.id,
              name: "ProLegion Pvt. Ltd",
              description: "Monthly Test Plan",
              image: "/prolegion_favicon.ico",
              "handler": async function (response) {
                try {
                  setPaymentLoading(true)
                  await api.post(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/webhooks/razorpay-subscription`, response)
                  success('Payment Successfull')
                  setPaymentLoading(false)
                  window.location.href = '/dashboard/settings?screen=list'
                } catch(err) {
                  setPaymentLoading(false)
                  error('Payment failed')
                }
              },
              prefill: {
                  name: `${first_name} ${last_name}`,
                  email: email,
              },
              theme: {
                  color: "rgb(11, 133, 186)",
              },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
      }
      setLoading(false)
    } catch (error) {
      console.error('Error in subscription: ', error);
      setLoading(false)
    }
  }, [fetchOrCreateCustomer, isLoggedIn, navigate, first_name, last_name, email, isStripe, loading]);

  const fetchPlansHandler = useCallback(async (type = 'monthly') => {
    try {
      if ((roles).toLowerCase() === 'consultant') return
      setLoading(true)
      const { data } = await fetchPlans({ duration: type, role: (roles || role).toLowerCase() });
      if (data && data.items && data.items.length) {
        setCurrency(data.items[0].item.currency);
      }
      if (data.payment_provider === 'STRIPE') {
        setCurrency(data?.plans[0]?.currency || "USD")
        setIsStripe(true)
        setPlanDetails((prev) => {
          return (prev || []).map((val, index) => {
            if (val.name) {
              const details = data.plans.filter(i => i.nickname?.includes(val.name));
              if (details && details.length > 0) {
                return {
                  ...val, 
                  price: details[0].unit_amount / 100, 
                  price_id: details[0].id,
                  product_id: details[0].product,
                  product_name: details[0]?.nickname,
                  remove: index === 0
                    ? false
                    : (!!(activeSubscription?.attributes?.id?.includes(val.name)
                      || activeSubscription?.attributes?.id?.includes('enterprise')))
                };
              }
              return val;
            }
            return val;
          });
        });
      }
      else {
        setIsStripe(false)
        setPlanDetails((prev) => {
          return (prev || []).map((val, index) => {
            if (val.name) {
              const details = data.items.filter(i => i.item.name?.includes(val.name));
              if (details && details.length > 0) {
                return {
                  ...val, 
                  price: details[0].item.amount / 100, 
                  price_id: details[0].id, 
                  remove:  val.name?.includes('basic plan') ? activeSubscription?.attributes?.id?.includes('professional plan') :
                    (!!(activeSubscription?.attributes?.id?.includes(val.name) || activeSubscription?.attributes?.id?.includes('enterprise')))
                };
              }
              return val;
            }
            return val;
          });
        });
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching plans: ', error);
    }
  }, [setCurrency, setPlanDetails, activeSubscription, roles]);

  useEffect(() => {
    fetchPlansHandler();
  }, [roles, fetchPlansHandler]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return {
    onBuySubscriptionHandler,
    handleBillingCycleChange,
    paymenLoading,
    loading
  };
};

export default usePayment;
