import {
  Autocomplete,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useCommonUserProvider } from "../../../Providers/Common/User";
import { useEffect } from "react";
import { companySizeOptions } from "../constant";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileErrors } from "Redux/Feature/profile/profile.selector";
import { isValidUrl } from "Utils/helper";
import { removeErrors, setErrors } from "Redux/Feature/profile/profile.slice";

const style = {
  marginTop: "30px",
  display: "flex",
  gap: "30px",
  alignItems: "center",
};

const CompanyDetails = ({ company, setCompany, setOrganization }) => {
  const errors = useSelector(selectProfileErrors);
  const dispatch = useDispatch()
  const { businessTypes, getBusinessTypes } = useCommonUserProvider();
  useEffect(() => {
    getBusinessTypes();
  }, []);

  const websiteHandler = (e) => {
    setOrganization(e.target.value, "website")
    if (!isValidUrl(e.target.value)) {
      return dispatch(setErrors({ ...errors, website: 'please enter a valid url' }))
    }
    dispatch(removeErrors({ key: 'website' }))
  }

  return (
    <Box sx={{ margin: "30px 0px" }}>
      <Box sx={{ display: "flex", gap: "30px" }}>
        <TextField
          sx={{ width: "30%" }}
          label="Company Name"
          value={company?.company || ""}
          placeholder="Enter your Company Name"
          onChange={(e) => setCompany(e.target.value, "company")}
        />
        <Autocomplete
          id="states"
          value={company?.company_size || ""}
          sx={{ width: "30%" }}
          isOptionEqualToValue={(option, value) =>
            value?.value ? option.value === value.value : option.value === value
          }
          clearIcon={false}
          getOptionLabel={(option) => option?.label || option}
          options={companySizeOptions}
          onChange={(_, v) => setOrganization(v, "company_size")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company Size"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Box>
      <Box sx={style}>
        <Autocomplete
          id="states"
          sx={{ width: "30%" }}
          value={company?.industry_type_id || ""}
          isOptionEqualToValue={(option, value) =>
            value?.id ? option.id === value.id : option.id === value
          }
          clearIcon={false}
          getOptionLabel={(option) =>
            option?.type
              ? option.type
              : businessTypes.filter((i) => i.id == option)[0]?.type || ""
          }
          options={businessTypes}
          onChange={(_, v) => setOrganization(v, "industry_type_id")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Buisness Type"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
        <Box sx={{ width: "30%" }}>
          <Typography variant="body2Semibold">Year Founded</Typography>
          <DesktopDatePicker
            sx={{ width: "100%", overflow: "none" }}
            format="MM-DD-YYYY"
            renderInput={(params) => <TextField {...params} />}
            value={company?.founded_date ? dayjs(company?.founded_date) : null}
            onChange={(e) => setOrganization(e, "founded_date")}
            maxDate={dayjs()}
          />
        </Box>
        <TextField
          sx={{ width: "30%" }}
          label={ errors?.website ? errors?.website : "Website"}
          error={errors?.website}
          value={company?.website || ""}
          placeholder="Enter your Website"
          onChange={websiteHandler}
        />
      </Box>
    </Box>
  );
};

export default CompanyDetails;
