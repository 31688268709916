import Swal from "sweetalert2";
import api, { apiV2 } from "../../Utils/api";
import { roles } from "../../Utils/constants";
import { getUserIdFromLocalStorage, unionBy } from "../../Utils/helper";
import { shareResources } from "../../services/shared-resources";

export const SHARED_RESOURCES_URL = process.env.REACT_APP_SHARED_RESOURCES_URL;

export const resourcesApi = (id) => {
  const userId = id ? id : getUserIdFromLocalStorage();
  return {
    CURRENT: "/user/listvcmusers",
    INVITED_USERS: "/user/invitedusers",
    REQUESTED_USERS: "/user/connectionrequest",
    VENDORS_LIST: "/user/listvvmusers",
    FETCH_VENDORS: "/user/invitesvvm",
    UPDATE_RESOURCES: "/user/updatevcm",
    CLIENT_RESOURCES: "/resources",
    DISCONNECTVCM: "/user/disconnectvcm",
    ACCEPT_INVITEVCM: "/user/acceptinvitevcm",
    REJECT_INVITEVCM: "/user/declineinvitevcm",
    DEFAULT: `${SHARED_RESOURCES_URL}/api/v1/user/${userId}/shared-resources/me`,
    SHARED_BY_ME: `${SHARED_RESOURCES_URL}/api/v1/user/${userId}/shared-resources`,
    UN_SHARED: `${SHARED_RESOURCES_URL}/api/v1/user/${userId}/shared-resources`,
  };
};

export const fetchResourcesApi = async (selectedTab, pagination, setRowCount, sortBy) => {
  const res = await api.get(resourcesApi().DEFAULT, {
    params: {page: pagination.pageIndex + 1, size: pagination.pageSize, ...(!!sortBy && {orderBy:sortBy})}
  });
  if (res.status === 200 && res.data?.data?.result?.length > 0 && selectedTab === 1) {
    setRowCount(10 * res?.data?.data?.meta?.pages)
    return res.data?.data?.result;
  }
  return [];
};

export const fetchPastResource = async (pagination, setRowCount, sortBy) => {
  try {
    //localhost:4001/api/v1/user/listvcmusers?for=VENDOR&page=1&size=100&resourceStatus=PAST
    const res = await api.get(resourcesApi().CURRENT, {
      params: {page: pagination.pageIndex + 1, size: pagination.pageSize, for: "VENDOR", resourceStatus: "PAST", ...(!!sortBy && {orderBy:sortBy})}
    });
    const data = [];
    if(res?.data?.success) {
      setRowCount(10 * res?.data?.result?.meta?.pages);
      const result = res?.data?.result?.result?.length ? res.data?.result?.result : [];
      data.push(
        ...result.map((item) => {
          return({
            id: item?.consultant?.id,
            firstName: item?.consultant?.firstName,
            lastName: item?.consultant?.lastName,
            consultant: {
              profilePhoto: item?.consultant?.profilePhoto,
              ...(item?.consultant || {})
            },
            email: item?.consultant?.email,
            username: item?.consultant?.username
          })
        })
      );
    }
    return unionBy([], data, (item) => item?.id);
    // if(res.data?.)
  } catch (error) {
    console.warn(error);
  }
}

export const disconnectVcm = async(body) => {
  try {
    const res = await api.post(resourcesApi().DISCONNECTVCM, body);
    return res
  } catch (error) {
    console.warn(error);
  }
}

export const fetchResourcesofClient = async ()=> {
  try {
    const res = await apiV2.get(resourcesApi().CLIENT_RESOURCES,  {
      params: {
        size: 100,
      }
    });
    if(res?.data) {
      //v2 api
      return res?.data?.data?.result;
    }
  } catch (error) {
      console.log(error);
  }
}

export const fetchResourcesListofClient = async (pagination, setRowCount) => {
  try {
    const res = await apiV2.get(resourcesApi().CLIENT_RESOURCES,  {
      params: {
        page: pagination.pageIndex + 1,
        size: pagination.pageSize,
      }
    });
    if(res?.data) {
      setRowCount(10 * res?.data?.data?.meta?.pages)
      //v2 api
      return res?.data?.data?.result;
    }
  } catch (error) {
    console.log(error);
  }
}

export const acceptInviteFromConsultant = async (id) => {
  try {
    const res = await api.put(resourcesApi().ACCEPT_INVITEVCM, {
      consultant_id: id
    }, {
      params: {
        for: "vendor"
      }
    });
    if(res?.data?.success) {
      Swal.fire({
        title: "Success",
        text: "Request Accepted",
        icon: "success"
      })
    }
  } catch (error) {
    console.warn(error);
  }
}

export const rejectInviteFromConsultant = async (body) => {
  try {
    const res = await api.delete(`${resourcesApi().REJECT_INVITEVCM}?for=vendor`, {data: body});
    if(res?.data?.success) {
      Swal.fire({
        title: "Success",
        text: "Request Rejected",
        icon: "success"
      })
    }
  } catch (error) {
    console.warn(error);
  }
}

export const fetchConnectionRequestedConsultants = async (pagination, setRowCount, sortBy) => {
  try {
    const res = await apiV2.get(`${resourcesApi().CLIENT_RESOURCES}`, {
      params: {
        for: "requested",
        page: pagination.pageIndex + 1,
        size: pagination.pageSize,
        ...(!!sortBy && {orderBy:sortBy})
      }
    });
    const data = [];
    if(res?.data?.status === "SUCCESS") {
      setRowCount(10 * res?.data?.data?.meta?.pages);
      const result = res?.data?.data?.result?.length ? res.data?.data?.result : [];
      data.push(
        ...result.map((item) => {
          return({
            id: item?.consultant?.id,
            firstName: item?.consultant?.firstName,
            lastName: item?.consultant?.lastName,
            status: item?.requestFrom === "CONSULTANT" ? "Request Received" : "Request Sent",
            requestFrom: item?.requestFrom,
            isApproved: item?.isApproved,
            profilePhoto: item?.consultant?.profilePhoto,
            email: item?.consultant?.email,
            username: item?.consultant?.username,
            consultant: {
              ...item.consultant
            }
          })
        })
      );
    }
    return unionBy([], data, (item) => item?.id);
  } catch (error) {
    console.warn(error);
  }
}

export const fetchConsultantsDisconnectionsRequest = async (selectedTab, id, pagination, sorting, setRowCount, sortBy) => {
  try {
    const connectionRequest = await api.get(resourcesApi().REQUESTED_USERS,  {
      params: {...(!!sortBy && {orderBy:sortBy})}
    });
    const data = [];
    if (connectionRequest?.data?.success && selectedTab !== 3) {
      setRowCount(10 * connectionRequest.data?.invite?.meta?.pages);
      const result = connectionRequest?.data?.result?.result?.length ? connectionRequest.data?.result?.result : [];
      data.push(
        ...result.map((item) => {
          return({
            id: item?.consultant?.id,
            firstName: item?.consultant?.firstName,
            lastName: item?.consultant?.lastName,
            status: "Requested",
            profilePhoto: item?.consultant?.profilePhoto,
            email: item.email,
            username: item?.consultant?.username,
            isDisconnectRequest: item?.isDisconnectRequest,
            consultant: {
              ...item.consultant
            }
          })
        })
      );
    }
    return unionBy([], data, (item) => item.id);
  } catch (e) {
    console.error(e);
  }
}

export const fetchInvitedConsultants = async (selectedTab, id, pagination, sorting, setRowCount, sortBy) => {
  const userId = id ? id : getUserIdFromLocalStorage();
  const invited = await api.get(resourcesApi(userId).INVITED_USERS, {
    params: { userId, userType: roles.consultant, size: pagination.pageSize, page: pagination.pageIndex + 1, ...(!!sortBy && {orderBy:sortBy}) },
  });
  const data = [];
  try {
    if (invited?.data?.success && selectedTab !== 3) {
      setRowCount(10 * invited.data?.invite?.meta?.pages);
      const result = invited.data.invite?.result?.length ? invited.data.invite?.result : [];
      data.push(
        ...result.map((item) => ({
          id: item.id,
          firstName: item.name,
          lastName: "",
          status: "Invited",
          username: "",
          profilePhoto: item?.profilePhoto,
          email: item.email,
        }))
      );
    }
    return unionBy([], data, (item) => item.id);
  } catch (e) {
    console.error(e);
  }
}

export const fetchCurrentResourcesList = async (selectedTab, id, pagination, sorting, setRowCount, sortBy) => {
  // const userId = id ? id : getUserIdFromLocalStorage();
  const params = { for: "current", size: pagination.pageSize, page: pagination.pageIndex + 1, ...(!!sortBy && {orderBy:sortBy})};
  const data = [];
  try {
    const current = await apiV2.get(resourcesApi().CLIENT_RESOURCES, {
      params,
    });

    if (current?.data?.status === "SUCCESS") {
      setRowCount(10 * current.data?.data?.meta?.pages);
      let result = (
        current.data?.data?.result?.length ? current.data?.data?.result : []
      ).map((item) => ({
        id: item.id,
        consultant_id: item.consultant_id,
        isPublishPortfolio: item.isPublishPortfolio,
        isPublishMarketplace: item.isPublishMarketplace,
        isPublishSubscription: item.isPublishSubscription,
        firstName: item?.consultant?.firstName,
        lastName: item?.consultant?.lastName,
        status: item.status === 'PAST' ? 'PAST' : item.isApproved ? "Current" : "Requested",
        isApproved: item?.isApproved,
        requestFrom: item?.requestFrom,
        profilePhoto: item?.consultant?.profilePhoto,
        industryExperience: item.consultant?.industryExperience,
        skills: item.consultant?.skills,
        client: item?.client || {},
        consultant: item?.consultant || {},
        activeJobs: item?.active_jobs || []
      }));
      if (selectedTab == 0) result = result.filter(i => i.status !== 'PAST')
      if (selectedTab == 3) result = result.filter(i => i.status === 'PAST')
      data.push(...result);
    }
    return unionBy([], data, (item) => item.id);
  } catch (e) {
    console.error(e);
  }
};

export const fetchCurrentConsultantList = async (search) => {
  try {
    const isVendor = localStorage.getItem("role") === "VENDOR";
    const params = isVendor ? { type: "list", search } : {}
    const current = await apiV2.get(resourcesApi().CLIENT_RESOURCES, {
      params
    });
    
    if (current?.data?.status === "SUCCESS") {
      const result = current?.data?.data?.result?.length ? current?.data?.data?.result : [];
      if(isVendor) {
        return result;
      } else {
        return result?.map((item)=>({
          id: item?.consultant?.id,
          firstName: item?.consultant?.firstName,
          lastName: item?.consultant?.lastName
        }));
      }
    } else {
      return [];
    }

  } catch (e) {
    console.error(e);
  } 
}

export const fetchCurrentConsultants = async (selectedTab, id, text = '') => {
  const userId = id ? id : getUserIdFromLocalStorage();
  const params = { for: "current", ...(text && { search: text }) };
  const data = [];
  try {
    const current = await apiV2.get(resourcesApi().CLIENT_RESOURCES, {
      params,
    });

    if (current?.data?.status === "SUCCESS") {
      let result = (
        current.data?.data?.result?.length ? current.data?.data?.result : []
      ).map((item) => ({
        id: item.id,
        consultant_id: item.consultant_id,
        isPublishPortfolio: item.isPublishPortfolio,
        isPublishMarketplace: item.isPublishMarketplace,
        isPublishSubscription: item.isPublishSubscription,
        firstName: item?.consultant?.firstName,
        lastName: item?.consultant?.lastName,
        status: item.status === 'PAST' ? 'PAST' : item.status === 'CRNT' ? "Current" : "Requested",
        isApproved: item?.isApproved,
        requestFrom: item?.requestFrom,
        profilePhoto: item?.consultant?.profilePhoto,
        industryExperience: item.consultant?.industryExperience,
        skills: item.consultant?.skills,
        client: item?.client || {},
        consultant: item?.consultant || {},
      }));
      if (selectedTab == 0) result = result.filter(i => i.status !== 'PAST')
      if (selectedTab == 3) result = result.filter(i => i.status === 'PAST')
      data.push(...result);
    }
    return unionBy([], data, (item) => item.id);
  } catch (e) {
    console.error(e);
  }
};

export const fetchSharedResourcesByMe = async (pagination, setRowCount, sortBy) => {
  try{
  const params = {page: pagination.pageIndex + 1, size: pagination.pageSize, ...(!!sortBy && {orderBy:sortBy})};
  const res = await api.get(resourcesApi().SHARED_BY_ME, {
    params
  });
  if (res.status === 200 && res.data?.data?.result?.length > 0) {
    setRowCount(10 * res.data?.data?.meta?.pages);
    return res?.data?.data?.result;
  }
  return [];
  } catch(error) {
    console.warn(error);
  }
};

export const fetchVendors = async () => {
  try {
    const userId = getUserIdFromLocalStorage();
    const params = { id: userId };
    const current = await api.get(resourcesApi().VENDORS_LIST, { params });
    const requests = await api.get(resourcesApi().FETCH_VENDORS, { params });
    const invited = await api.get(resourcesApi().INVITED_USERS, {
      params: { userId, userType: roles.vendor },
    });
    const data = [];

    if (requests?.data?.success) {
      const result = (
        requests.data.result?.length ? requests.data.result : []
      ).map((mapping) => {
        if (mapping.vendor1.id === userId) {
          return {
            id: mapping.id,
            vendor_id: mapping.vendor2.id,
            firstName: mapping.vendor2.firstName,
            lastName: mapping.vendor2.lastName,
            status: "Requested",
            sentByCurrentUser: true,
            companyName: mapping?.vendor2.company,
            email: mapping?.vendor2.email,
            profilePhoto: mapping?.vendor2.profilePhoto,
          };
        }
        return {
          id: mapping.id,
          vendor_id: mapping.vendor1.id,
          firstName: mapping.vendor1.firstName,
          lastName: mapping.vendor1.lastName,
          status: "Requested",
          sentByCurrentUser: false,
          companyName: mapping?.vendor1.company,
          email: mapping?.vendor1.email,
          profilePhoto: mapping?.vendor1.profilePhoto,
        };
      });

      data.push(...result);
    }

    if (current?.data?.success) {
      const result = (
        current.data.result?.length ? current.data.result : []
      ).map((mapping) => {
        if (mapping.vendor1.id === userId) {
          return {
            id: mapping.id,
            vendor_id: mapping.vendor2.id,
            firstName: mapping.vendor2.firstName,
            lastName: mapping.vendor2.lastName,
            status: "Current",
            companyName: mapping?.vendor2.company,
            email: mapping?.vendor2.email,
            profilePhoto: mapping?.vendor2.profilePhoto,
          };
        }
        return {
          id: mapping.id,
          vendor_id: mapping.vendor1.id,
          firstName: mapping.vendor1.firstName,
          lastName: mapping.vendor1.lastName,
          status: "Current",
          companyName: mapping?.vendor1.company,
          email: mapping?.vendor1.email,
          profilePhoto: mapping?.vendor1.profilePhoto,
        };
      });

      data.push(...result);
    }

    if (invited?.data?.success) {
      const result = invited.data.invite?.length ? invited.data.invite : [];
      data.push(
        ...result.map((item) => ({
          id: item.id,
          firstName: item.name,
          lastName: "",
          status: "Invited",
          companyName: item?.company,
          email: item?.email,
          profilePhoto: item?.profilePhoto,
        }))
      );
    }
    return data;
  } catch (e) {
    return [];
  }
};

export const createResources = async (sharedResources, selectedVendors) => {
  try {
    const resources = [];
    const userId = getUserIdFromLocalStorage();

    sharedResources.forEach((consultant) => {
      selectedVendors.forEach((vendor) => {
        const payload = {
          resource_id: consultant.consultant_id,
          shared_by_user: userId,
          shared_to_user: vendor?.vendor_id,
          permission: "read",
          status: "active",
        };

        resources.push(payload);
      });
    });
    const resp = await Promise.all( 
      resources.map(async (resource) => shareResources(userId, resource))
    );
    return resp;
  } catch (e) {
    return {
      success: false,
      message: e?.response?.data?.message || "Something went wrong!!",
    };
  }
};

export const updateResources = async (resources, type = 'status') => {
  await api.post(resourcesApi().UPDATE_RESOURCES, { vcmIds: resources, type });
}

export const addShadowResources = async (details) => {
  try {
    const resp = await api.post('user/createShadowUser', details);
    window.open(window.location.origin + '/' + resp?.data?.log?.username, '__blank')
    return { success: true }
  } catch(e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong' }
  }
}

export const addShadowResourcesFromCSV = async (file) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    const resp = await api.post('user/createShadowUserFromCSV', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
    },
    });
    return { success: true }
  } catch(e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong' }
  }
}

export const handleUnshareClick = async (row) => {
  row.status = true;
  await api.patch(`${resourcesApi().UN_SHARED}/${row.id}`, {
    status: "inactive",
  });
};

export const handleCheckBox = async (name, id, value) => {
  await updateResources([{ id, [name]: value }], 'publish')
}