import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { fetchJobDetails } from './jobapplication.api';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'Redux/Feature/user';
import Button from 'Components/Common/semantic_tags/Button';
import { useNavigate } from 'react-router-dom';
import { appliedJobsHeaders } from './utils';

const AppliedJobs = () => {
    const navigate = useNavigate()
    const userDetails = useSelector(selectUserDetails)
    const [jobs, setJobs] = useState([])

    const fetchDetails = async (id) => {
        const resp = await fetchJobDetails(id)
        setJobs(resp.data)
    }

    useEffect(() => {
        if (userDetails && userDetails?.user_id) {
            fetchDetails(userDetails?.user_id)
        }
    }, [userDetails])

    const renderAppliedJobHeaders = (item, index) => (
      <p className="text-sm w-1/6" key={index}>{item}</p>
    )

  return (
    <div>
      <div className="bg-gray-100 p-2.5 mb-3 rounded-md">
        <div className="flex justify-between items-center">
          {appliedJobsHeaders.map(renderAppliedJobHeaders)}
        </div>
      </div>
      <div className="p-2.5 mb-5 rounded-md">
        {jobs &&
          jobs.map((job) => (
            <div key={job.id} className="border-b border-gray-300 pb-2.5 mb-2.5 w-full">
              <div className="flex justify-between items-center">
                <div className="w-1/6">
                  <span className='text-sm'>{job?.Job?.vendorName}</span>
                </div>
                <div className="w-1/6">
                  <strong className='text-sm pr-4'>{job?.Job?.name}</strong>
                </div>
                <div className="w-1/6">
                  <span className="text-sm">
                    Applied on {moment(job?.created_at).format("DD MMM, YYYY")}
                  </span>
                </div>
                <div className="w-1/6">
                    <div className="font-bold text-sm flex items-center gap-1">
                        {job?.Job?.applicant_count}
                    </div>
                </div>
                <div className="w-1/6">
                    <div className="font-bold text-xs text-purple-700 flex items-center gap-1">
                        {job?.status?.toUpperCase()}
                    </div>
                </div>
                <div className="w-1/6">
                    <Button title='View Job' onClick={() => navigate(`/dashboard/marketplace?id=${job?.job_id}`)} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AppliedJobs;
