import { useEffect, useState } from "react";

import {
  fetchConnectionRequestedConsultants,
  fetchConsultantsDisconnectionsRequest,
  fetchCurrentResourcesList,
  fetchInvitedConsultants,
  fetchPastResource,
  fetchResourcesApi,
  fetchSharedResourcesByMe,
  updateResources,
} from "./api";
import { useAlertProvider } from "../../Providers/util/Alert";

const useResource = ({ searchValue = '', selectedTab, selectedSubTab, pagination, sorting, setRowCount, openModal, isReloadAPI, setPagination, sortBy }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resourcesData, setResourcesData] = useState([]);
  const [resourcesDataWithMe, setResourcesDataWithMe] = useState([]);
  const [resourcesDataByMe, setResourcesDataByMe] = useState([]);
  const [pastResourcesData, setPastResourcesData] = useState([]);
  const [disconnectionRequestedData, setDisconnectionRequestedData] = useState([]);
  const [connectionRequestedData, setConnectionRequestedData] = useState([]);
  const [invited, setInvitedData] = useState([]);
  const { error } = useAlertProvider();

  const fetchDataBasedOnActiveTab = async () => {
    return await (selectedTab === 0)
      ? fetchCurrentResourcesList(selectedTab, undefined, pagination, sorting, setRowCount, sortBy)
      : selectedTab === 1
      ? fetchResourcesApi(selectedTab, pagination, setRowCount, sortBy) 
      : selectedTab === 2 
      ? fetchSharedResourcesByMe(pagination, setRowCount, sortBy)
      : selectedTab === 3 
      ? fetchPastResource(pagination, setRowCount, sortBy)
      : selectedTab === 4 
      && selectedSubTab === 2 ? fetchInvitedConsultants(selectedTab, undefined, pagination, sorting, setRowCount, sortBy) : selectedSubTab === 1 ? fetchConnectionRequestedConsultants(pagination, setRowCount, sortBy) : fetchConsultantsDisconnectionsRequest(selectedTab, undefined, pagination, sorting, setRowCount, sortBy)
  };

  const fetchResourcesDetails = async () => {
    setIsLoading(true);
    try {
      const data = await fetchDataBasedOnActiveTab();
      selectedTab === 0
        ? setResourcesData(data)
        : selectedTab === 1
        ? setResourcesDataWithMe(data) 
        : selectedTab === 3
        ? setPastResourcesData(data)
        : selectedTab === 4 
        ? selectedSubTab === 0 
        ? setDisconnectionRequestedData(data)
        : selectedSubTab === 1 
        ? setConnectionRequestedData(data)
        : selectedSubTab === 2
        && setInvitedData(data)
        : fetchSharedResourcesByMe();
      setResourcesDataByMe(data);
      return data
    } catch (e) {
      error('Error occurred while fetching resources');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setResourcesData([]);
    fetchResourcesDetails();
  }, [selectedTab, pagination, selectedSubTab, isReloadAPI, sortBy]);

  return {
    resourcesData:
      selectedTab === 0
        ? resourcesData
        : selectedTab === 1
        ? resourcesDataWithMe
        : selectedTab === 3
        ? pastResourcesData
        : selectedTab === 4
        ? selectedSubTab === 0
        ? disconnectionRequestedData
        : selectedSubTab === 1
        ? connectionRequestedData
        : selectedSubTab === 2
        && invited
        : resourcesDataByMe,
    isLoading,
    pagination,
    fetchResourcesDetails
  };
};

export default useResource;
