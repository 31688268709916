import themeBase from '../themeBase';

export const MuiButtonOverride = {
  styleOverrides: {
    root: {
      whiteSpace: 'nowrap',
      textTransform: 'none',
      padding: themeBase.spacing(1.25, 2.5),
      borderRadius: '8px',
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'primary' },
      style: {
        color: 'white',
        backgroundColor: themeBase.palette.primary.dark,
      },
    },
    {
      props: { variant: 'outlined', color: 'primary' },
      style: {
        color: themeBase.palette.primary.dark,
        borderColor: themeBase.palette.primary.dark,
      },
    },
    {
      props: { variant: 'contained' },
      style: {
        ...themeBase.typography.captionMedium,
        borderColor: '#0B85BA',
        // padding: themeBase.spacing(1, 1.5),
        borderRadius: '8px',
      },
    },
    {
      props: { variant: 'outlined' },
      style: {
        ...themeBase.typography.captionMedium,
        borderColor: '#0B85BA',
        padding: themeBase.spacing(1, 1.5),
        borderRadius: '8px',
      },
    },
    {
      props: { variant: 'text' },
      style: {
        ...themeBase.typography.body2Medium,
        color: '#182743',
      },
    },
    {
      props: { variant: 'text', color: 'secondary' },
      style: {
        // color: 'white',
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: '16px',
        lineHeight: '26px',
      },
    },
  ],
};
