import { Box, Typography } from '@mui/material';
import { FormTextInput, HookFormUploadFile } from 'Components/HookFormInputs';
import HookFormTextEditor from 'Components/HookFormInputs/HookFormTextEditor';
import api from 'Utils/api';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { contactDescritionValidation, contactEmailValidation, contactNameValidation, contactNumberValidation, contactSubjectValidation, removeHtmlTags } from './validation';
import { UserInfo } from 'Pages/Auth/Helper';
import Button from 'Components/Common/semantic_tags/Button';
function Contactus({ isProfileSupport, modalOpen, handleModalClose }) {
  const [userLoginInfoData, setUserLoginInfoData] = useState({});
  const [files, setFiles] = useState([]);

  const {
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm();

  const { description } = watch();
  const removedTagDescription = removeHtmlTags(description);

  const userInfo = async()=> {
    const user = await UserInfo();
    setUserLoginInfoData(user?.data);
  }

  useEffect(()=>{
    userInfo();
  },[isProfileSupport, modalOpen]);

  useMemo(()=> {
    if(isProfileSupport) {
      setValue("name", `${userLoginInfoData?.first_name} ${userLoginInfoData?.last_name}`)
      setValue("email", userLoginInfoData?.email)
    }
  },[!!userLoginInfoData?.first_name, isProfileSupport])

  // useMemo(() => {
  //   if (removedTagDescription === '') {
  //     return setError("description", { message: "Please Enter some description" }, {shouldFocus: true})
  //   } else {
  //     clearErrors("description")
  //   }
  // }, [description]);

  const sendAttachments = useMemo(()=>{
    return files.map((item)=>(item.url))
  },[files]);

  const handleSubmitContactusForm = async (values) => {
    const { name, email, phone, subject } = values;

    const sendContactusInfo = { contactname: name, email, subject, description: removedTagDescription, ...( !!phone && { phone } ) };

    const sendSupportInfo = { contactname: name, email, subject, description: removedTagDescription, ...( !!phone && { phone } ), ...( sendAttachments.length !== 0 && {attachments: sendAttachments})  };

    try{
    const sendContact = await api.post("/contact-us", isProfileSupport ? sendSupportInfo : sendContactusInfo)
    if (sendContact.status === 200) {
      Swal.fire({
        title: "Success",
        text: sendContact.data?.msg,
        icon: "success"
      })
      {isProfileSupport && handleModalClose()}
    } else {
      Swal.fire({
        title: "Error",
        text: "Can't send Request",
        icon: "error"
      })
    }
  } catch(error) {
    console.warn(error);
    Swal.fire({
      title: "Error",
      text: "Can't send Request",
      icon: "error"
    })
  }
  }

  const responsiveWidth = { 
    md: isProfileSupport ? "100%" : "55%", 
    xs: isProfileSupport ? "100%" : "90%" 
  }

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} padding={`${isProfileSupport ? "0" : "0rem" }`} sx={{ backgroundColor: "#fff", padding: {xs: "2rem 0"} }}>
      <Typography textAlign={"center"} variant='defaultFont' fontWeight={"bolder"} fontSize={isProfileSupport ? '24px' : '40px'}>{isProfileSupport ? "Contact for Support" : "Contact us"}</Typography>
      <form onSubmit={handleSubmit(handleSubmitContactusForm)} style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", paddingTop: "1rem" }}>
        <Box display={"flex"} flexDirection={"column"} sx={{ width: responsiveWidth }} height={"100%"}>
          {!isProfileSupport && 
          <> 
            <FormTextInput required name={"name"} label={`${isProfileSupport ? "Contact Person Name" : "Contact Name" }`} type={"text"} placeholder={"Enter your name"} control={control} validation={contactNameValidation} errors={errors.name} />
            <FormTextInput required name={"email"} label={`${isProfileSupport ? "Contact Person Email" : "Email"}`} type={"text"} placeholder={"Enter your email"} control={control} validation={contactEmailValidation} errors={errors.email} /> 
           </>}
          <FormTextInput required name={"subject"} label={`${isProfileSupport ? "Title" : "Subject"}`} type={"text"} placeholder={`Enter your ${isProfileSupport ? "title": "subject"}`} control={control} validation={contactSubjectValidation} errors={errors.subject} />
          {!isProfileSupport && <FormTextInput name={"phone"} label={"Phone"} type={"text"} placeholder={"Enter your Contact"} control={control} validation={contactNumberValidation} errors={errors.phone} /> }
          <HookFormTextEditor height={`${isProfileSupport ? 150 : 150}`} required name={"description"}  label={`${isProfileSupport ? "Summary" : "Description"}`} control={control} validation={contactDescritionValidation} errors={errors.description} />
          {isProfileSupport && <HookFormUploadFile files={files} name={"attachment"} control={control} setDetails={setFiles} />}
        </Box>
        <Box display={"flex"} justifyContent={isProfileSupport ? 'flex-end' : "center"} paddingTop={`${isProfileSupport ? "2rem" : "1rem"}`}>
          <Button type='submit' disabled={!isValid || removedTagDescription === ''}
            parentClassName={`!w-full justify-end`}
            title='Submit'
            />
        </Box>
      </form>
    </Box>
  )
}

export default Contactus;