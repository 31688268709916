import React from 'react';

export default function LogoutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
      <path d="M7.96212 3.809C7.93662 3.7475 7.90012 3.6925 7.85361 3.646L5.85396 1.6465C5.65844 1.451 5.34242 1.451 5.1469 1.6465C4.95139 1.842 4.95139 2.158 5.1469 2.3535L6.29349 3.5H2.00016C1.72363 3.5 1.50012 3.724 1.50012 4C1.50012 4.276 1.72363 4.5 2.00016 4.5H6.29349L5.1469 5.6465C4.95139 5.842 4.95139 6.158 5.1469 6.3535C5.24441 6.451 5.37242 6.5 5.50043 6.5C5.62844 6.5 5.75645 6.451 5.85396 6.3535L7.85361 4.354C7.90012 4.308 7.93662 4.2525 7.96212 4.191C8.01263 4.069 8.01263 3.931 7.96212 3.809Z" fill="#E02424"/>
      <path d="M3.00023 7H1.50012C1.2241 7 1.00008 6.7755 1.00008 6.5V1.5C1.00008 1.2245 1.2241 1 1.50012 1H3.00023C3.27676 1 3.50027 0.776 3.50027 0.5C3.50027 0.224 3.27676 0 3.00023 0H1.50012C0.673053 0 0 0.673 0 1.5V6.5C0 7.327 0.673053 8 1.50012 8H3.00023C3.27676 8 3.50027 7.776 3.50027 7.5C3.50027 7.224 3.27676 7 3.00023 7Z" fill="#E02424"/>
    </svg>
  );
}
