import React, { useState, createContext, useContext } from "react";
import Swal from "sweetalert2";
import api from "../../Utils/api";

const apiPath = {
  fetchInvoiceForUser: "/invoice/get-invoices-for-user",
  fetchInvoiceDetails: "/invoice/get-invoice-details",
  fetchInvoiceHoursForMonth: "/invoice/get-hours-for-month",
  fetchInvoiceHistory: "/invoice/get-invoices-for-job",
  createInvoice: "/invoice/create",
  createInvoiceWithLink: "/invoice/link",
  sendEmailInvoice: "/invoice/send-link-via-email",
  getInvoicePdfLink: "/invoice/link",
  markInvoiceAsPaid: "/invoice/update-status",
};

const nullFn = () => null;
const InvoiceContext = createContext({
  loading: false,
  invoices: [],
  weeksOfMonth: [],
  invoiceLink: null,
  fetchInvoiceDetails: nullFn,
  fetchInvoiceForUser: nullFn,
  fetchInvoiceHoursForMonth: nullFn,
  createInvoice: nullFn,
  createInvoiceWithLink: nullFn,
  sendEmailInvoice: nullFn,
  fetchInvoiceHistory: nullFn,
  getInvoicePdfLink: nullFn,
  markInvoiceAsPaid: nullFn,
});

export const useInvoiceProvider = () => useContext(InvoiceContext);
const InvoiceProvider = ({ children, userId }) => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [weeksOfMonth, setWeeksOfMonth] = useState([]);
  const [invoiceLink, setInvoiceLink] = useState();

  const fetchInvoiceForUser = async (mode) => {
    setInvoices([]);
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchInvoiceForUser, {
        params: {
          userId: userId,
          mode,
        },
      });
      if (res.data?.success) {
        setInvoices(res.data.result);
      } else {
        throw new Error("Error occurred while fetching Invoices");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchInvoiceHistory = async (mode, jobId) => {
    setInvoices([]);
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchInvoiceHistory, {
        params: {
          userId: userId,
          jobId,
          mode,
        },
      });
      if (res.data?.success) {
        setInvoices(res.data.result);
      } else {
        throw new Error("Error occurred while fetching Invoices");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchInvoiceHoursForMonth = async ({ jobId, start }) => {
    try {
      const res = await api.post(apiPath.fetchInvoiceHoursForMonth, {
        userId,
        jobId,
        start,
      });
      if (res.data?.success) {
        setWeeksOfMonth(res.data.result);
      } else {
        throw new Error("Error occurred while fetching Invoice week");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const createInvoiceWithLink = async ({ invoiceId, invoiceLink }) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.createInvoiceWithLink, {
        invoiceId,
        link: invoiceLink,
      });
      if (res.data?.success) {
        return true;
      } else {
        throw new Error("Error occurred while fetching Invoice week");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const sendEmailInvoice = async (invoiceId, emails) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.sendEmailInvoice, {
        invoiceId,
        emails,
      });
      if (res.data?.success) {
        return true;
      } else {
        throw new Error("Error occurred while fetching Invoice week");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const getInvoicePdfLink = async (invoiceId) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getInvoicePdfLink, {
        params: {
          invoiceId,
        },
      });
      if (res.data?.success) {
        setInvoiceLink(res.data.result);
      } else {
        throw new Error("Error occurred while fetching Invoice week");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const markInvoiceAsPaid = async (invoiceId) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.markInvoiceAsPaid, {
        invoiceId: invoiceId,
        userId: userId,
        status: "PAID",
      });
      if (res.data?.success) {
        Swal.fire({
          icon: "success",
          title: "Invoice Payment",
          text: "Invoice payment successful",
        });
      } else {
        throw new Error("Error occurred while making payment");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const createInvoice = async ({
    month,
    rate,
    comments,
    jobId,
    clientId,
    currencyCode,
    status,
    paymentType
  }) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.createInvoice, {
        userId,
        jobId,
        month,
        rate,
        comments,
        clientId,
        currencyCode: currencyCode ? currencyCode : "USD",
        status: status ? status : "SUBMITTED",
        payment_type: paymentType
      });
      if (res.data?.success) {
        return res.data;
      } else {
        throw new Error("Error occurred while fetching Invoice week");
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: e.response.data.message,
        icon: "error",
      });
      return { exists: true };
    } finally {
      setLoading(false);
    }
  };
  const value = {
    loading,
    invoices,
    weeksOfMonth,
    invoiceLink,
    fetchInvoiceForUser,
    fetchInvoiceHistory,
    fetchInvoiceHoursForMonth,
    createInvoice,
    createInvoiceWithLink,
    sendEmailInvoice,
    getInvoicePdfLink,
    markInvoiceAsPaid,
  };

  return (
    <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>
  );
};

export default InvoiceProvider;
