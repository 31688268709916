import { Chip, Grid, Stack } from "@mui/material"

const Skillslist = ({ skills, clickable = false  }) => {
    return <Grid container spacing={1}>
        <Grid item>
            <Stack direction="row" flexWrap={'wrap'} gap={1} spacing={1}>
                {skills.map((skill, index) => (
                        <Chip
                            key={index}
                            sx={{ borderRadius: "30px", background: '#E8F1FF', color: '#0B85BA', border: 'unset', padding: '0px', fontSize: '10px' }}
                            label={skill}
                            variant="outlined"
                            // className="!text-xs"
                            clickable={clickable}
                        />
                    ))}
            </Stack>
        </Grid>
    </Grid>
}

export default Skillslist