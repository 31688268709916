import React, { useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { getCurrencyIcon, centsToDollars } from "Utils/common.utils";
import { selectUserRole } from "Redux/Feature/user";
import { Button } from "@mui/material";


function ensureTruthyOrZero(value) {
  return value !== undefined ? value : 0;
}

const VendorUsageDetails = ({currentUsage,allocatedUsage}) => {
  return (
    <Stack flexDirection={'row'} gap={'80px'} alignItems={'center'} justifyContent={'center'}>
      {" "}
      <Box>
        <Typography
          className="font-weight-600"
          sx={{ typography: "body1Bold" }}
        >
          Total Users
        </Typography>
        <Stack
          display={"flex"}
          justifyContent={"center"}
          direction={"row"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Typography variant="h4" sx={{ typography: "body2Bold" }}>
            {ensureTruthyOrZero(currentUsage?.users)}
          </Typography>
          <Typography variant="h4" sx={{ typography: "body2Normal" }}>
            /{ensureTruthyOrZero(allocatedUsage?.users)}
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Typography
          variant="h4"
          className="font-weight-600"
          sx={{ typography: "body1Bold" }}
        >
          Total Resources
        </Typography>
        <Stack
          display={"flex"}
          justifyContent={"center"}
          direction={"row"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Typography variant="h4" sx={{ typography: "body2Bold" }}>
            {ensureTruthyOrZero(currentUsage?.resources)}
          </Typography>
          <Typography variant="h4" sx={{ typography: "body2Normal" }}>
            /{ensureTruthyOrZero(allocatedUsage?.resources)}
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Typography
          variant="h4"
          className="font-weight-600"
          sx={{ typography: "body1Bold" }}
        >
          Total Marketplace
        </Typography>
        <Stack
          display={"flex"}
          justifyContent={"center"}
          direction={"row"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Typography variant="h4" sx={{ typography: "body2Bold" }}>
            {ensureTruthyOrZero(currentUsage?.marketplace)}
          </Typography>
          <Typography variant="h4" sx={{ typography: "body2Normal" }}>
            /{ensureTruthyOrZero(allocatedUsage?.marketplace)}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};


const ClientUsageDetails = ({ currentUsage, allocatedUsage }) => {
  return (
    <>
      <Grid lg={6} md={6}>
        <Typography
          className="font-weight-600"
          sx={{ typography: "body1Bold" }}
        >
          Users
        </Typography>
        <Stack
          display={"flex"}
          justifyContent={"center"}
          direction={"row"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Typography variant="h4" sx={{ typography: "body2Bold" }}>
            {ensureTruthyOrZero(currentUsage?.users)}
          </Typography>
          <Typography variant="h4" sx={{ typography: "body2Normal" }}>
            /{ensureTruthyOrZero(allocatedUsage?.client)}
          </Typography>
        </Stack>
      </Grid>
    </>
  );
};


export const SubscriptionDetails = ({
  plan,
  activeSubscription,
  setIsUpgradePlan,
  allocatedUsage,
  currentUsage = {},
}) => {
  const { isConsultant, isVendor, isClient } = useSelector(selectUserRole);
  const formatDate = (date) => {
    return moment.utc(date).format("MMM Do, YYYY");
  };

  const isFreePlan = useMemo(() => {
    return plan?.name === "free";
  }, [activeSubscription]);

  const planName = useMemo(() => {
    if (!activeSubscription?.attributes) return ''
    if (activeSubscription?.payment_provider === 'STRIPE') return activeSubscription?.plan?.name || ''
    const list =  activeSubscription?.attributes?.id?.split(' ')
    return list.slice(1, list.length).join(' ')
  }, [activeSubscription])

  const subscriptionInterval = useMemo(
    () => (isFreePlan ? "month" : activeSubscription?.interval),
    [activeSubscription, isFreePlan]
  );

  return (
    <Grid>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography
        variant="h4"
        gutterBottom
        className="py-4"
        sx={{ typography: "body1Bold" }}
      >
        Subscription details
      </Typography>
      {!activeSubscription?.attributes?.id?.includes('enterprise') && <Button variant="contained" sx={{ padding: '10px', background: '#0B85BA' }} onClick={() => setIsUpgradePlan(true)}>
          Upgrade plan
      </Button>}
      </Stack>
      <Grid className="p-6" sx={{ background: '#F9FAFB' }}>
        <div className="text-center p-8">
          <Typography
            variant="h4"
            className="capitalize"
            gutterBottom
            sx={{ typography: "body1Bold" }}
          >
            {planName}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            className="py-1 capitalize"
            sx={(theme) => ({
              typography: "body2Bold",
              color: theme.palette.success.light,
            })}
          >
            {activeSubscription?.status ? activeSubscription?.status : isFreePlan ? "Free Plan" : " "}
          </Typography>
          {!isFreePlan && <Stack direction={"row"} justifyContent={"center"}>
            <Typography variant="body2Normal" gutterBottom>
              Next Billing :
            </Typography>
            &nbsp;
            <Typography variant="body2Bold" gutterBottom>
              {getCurrencyIcon(activeSubscription?.currency)}
              {centsToDollars(activeSubscription?.invoice?.amount_paid) ?? ""}
            </Typography>
            &nbsp;
            <Typography variant="body2Normal" gutterBottom>
              on
            </Typography>
            &nbsp;
            <Typography variant="body2Bold" gutterBottom>
              {formatDate(activeSubscription?.end_date) ?? ""}
            </Typography>
          </Stack>}

          <Stack
            display={"flex"}
            justifyContent={"center"}
            direction={"row"}
            spacing={4}
            className="pt-4"
          >
            {/* {isVendor && (
              <VendorUsageDetails
                allocatedUsage={allocatedUsage}
                currentUsage={currentUsage}
              />
            )}

            {isClient && (
              <ClientUsageDetails
                allocatedUsage={allocatedUsage}
                currentUsage={currentUsage}
              />
            )} */}

            {}
          </Stack>
        </div>
      </Grid>
    </Grid>
  );
};
