import { Autocomplete, MenuItem, Select } from "@mui/material";
import MuiModal from "Components/Common/MuiModal";
import React, { useEffect, useState } from "react";
import { getAllMarketplaceJobs } from "./marketplace.api";
import { sendJobInvite } from "./jobapplication.api";
import { selectUserDetails, selectUserRole } from "Redux/Feature/user";
import { useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import Button from "Components/Common/semantic_tags/Button";
import { useAlertProvider } from "Providers/util/Alert";

function SendInvitedJobModal({ openModal, setModalOpen, consultantId }) {
  const [jobsData, setJobsData] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [searchedVal, setSearchedVal] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const debounceSearch = useDebounce(searchedVal, 300);
  const { role } = useSelector(selectUserRole);
  const details = useSelector(selectUserDetails);
  const { success, error } = useAlertProvider();

  const fetchProfilesHandler = async () => {
    const resp = await getAllMarketplaceJobs(1, 100, '', '', '', "DESC", `&createdBy=${details?.user_id}&&status=OPEN`)
    if (resp.success) {
      setJobsData(resp.data?.data)
      setTotalCount(resp.data?.meta?.totalItems)
    }
  }

  useEffect(() => {
    fetchProfilesHandler()
  },[debounceSearch, details?.user_id]);  

  const jobsDataMappings = jobsData?.map((item)=> ({label: item?.name, value: item?.id}));
  
  const renderResourcesList = (item, index) => {
    return(
      <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
    )
  }

  const handleSendInviteJob = async () => {
    const payload = {
      job_id: selectedJob,
      consultant_id: consultantId,
      vendor_company: details?.company
    }
    const res = await sendJobInvite(payload);
    
    if(res?.status === 201) {
      success("Job Invited Successfuly")
    } else {
      error(res?.response?.data?.error)
    }
    setModalOpen(false);
  }

  return (
    <MuiModal
      open={openModal}
      onCancel={()=> setModalOpen(false)}
      title="Invite Resource to Job"
      // styling={{height: "40vh"}}
    >
      <div className="flex flex-col pt-4">
        <span className="pb-4">Select Jobs *</span>
        <Select value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)} sx={{ width: '70%', background: 'white' }} className="mb-4" defaultValue="Posted By">
          {jobsDataMappings?.map(renderResourcesList)}
        </Select>
        <Button onClick={handleSendInviteJob} className="border-[#3985B6]" title={"Send Invite"} />
      </div>
    </MuiModal>
  );
}

export default SendInvitedJobModal;
