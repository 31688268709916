import { Link, useNavigate } from "react-router-dom";
import Button from "../Common/semantic_tags/Button";
import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function WelcomeScreenCard({ imgSrc, title, subTitle, buttonText, navigatePath, isGreen, index }) {
  
  const navigate = useNavigate();

  const buttonBackgroundColor = isGreen ? "bg-[#fff] hover:!bg-[#fff]" : "bg-[#3985B6] !hover:bg-[#3985B6]";
  const buttonBorder = isGreen ? "#fff" : "#3985B6";
  
  return (
    <div className="flex flex-col justify-between items-center p-8 bg-[#fff] rounded-xl mx-6 mb-4 md:w-[12rem] lg:w-[14rem] xl:w-[280px]" key={index}>
      <div className="flex flex-col items-center">
        <div style={{ backgroundImage: `url(${imgSrc})` }} className="rounded-xl bg-no-repeat w-20 h-20 bg-center bg-[#00A0C110]"></div>
        <h2 className="font-black xl:text-xl lg:text-lg md:text-base pt-4 text-[#170F49]">{title}</h2>
        <span className="text-[#6F6C90] text-center lg:text-sm xl:text-base font-bold pt-4">{subTitle}</span>
      </div>
      <Button className={`mt-4 py-[12px] xl:w-[200px] lg:w-[160px] md:w-[130px] rounded-3xl lg:rounded-full border-[${buttonBorder}] ${buttonBackgroundColor}`}
        onClick={()=> navigate(navigatePath)}
        title={!isGreen ? <Link to={navigatePath}>{buttonText}</Link> : <div className="text-[#097969]">Done <CheckCircleIcon sx={{color: "green"}} /></div>}
       />
    </div>
  );
}

export default WelcomeScreenCard;
