import React from 'react';
import ClientFirst from '../../Assets/Marketing/EnterpriseIcons/Icon.svg';
import ClientSecond from '../../Assets/Marketing/EnterpriseIcons/Icon1.svg';
import ClientThird from '../../Assets/Marketing/EnterpriseIcons/Icon2.svg';

import feature1 from '../../Assets/feature1.png';
import feature2 from '../../Assets/feature2.png';
import feature3 from '../../Assets/feature3.png';
import feature4 from '../../Assets/feature4.png';
import feature5 from '../../Assets/feature5.png';
import feature6 from '../../Assets/feature6.png';


export const ClientMockData = {
    "designedBuisness": {
        "heading": "Designed for business teams like yours",
        "subheading": "Problems trying to resolve the conflict between the two major realms of Classical physics: Newtonian mechanics",
        "cards": [
            {
                "image": ClientFirst,
                "heading": `Get in touch`,
                "color": "#E1EFFE",
                "content": "Get in touch with us, or initiate the sign-up yourself. Our easy migration process won't take long.",
            },
            {
                "image": ClientSecond,
                "heading": `Create`,
                "color": "#EDEBFE",
                "content": "Create your ProLegion profile & build a portfolio with our comprehensive portfolio builder tool.",
            },
            {
                "image": ClientThird,
                "heading": `Explore`,
                "color": "#D5F5F6",
                "content": "Explore the marketplace and the other useful features that our unified platform has to offer.",
            },
        ]
    },
    "features": {
        "heading": <>What you can do with ProLegion</>,
        "subheading": <>Here at ProLegion we focus on markets where technology, innovation, and<br /> capital can unlock long-term value and drive economic growth.</>,
        "cards": [
            {
                "image": feature1,
                "heading": <>Features 1</>,
                "subheading": <>We keep ProLegion, secure, and free of spam and abuse so that this can be the platform where developers come together to create.</>
            },
            {
                "image": feature2,
                "heading": <>Features 1</>,
                "subheading": <>We keep ProLegion, secure, and free of spam and abuse so that this can be the platform where developers come together to create.</>
            },
            {
                "image": feature3,
                "heading": <>Features 1</>,
                "subheading": <>We keep ProLegion, secure, and free of spam and abuse so that this can be the platform where developers come together to create.</>
            },
            {
                "image": feature4,
                "heading": <>Features 1</>,
                "subheading": <>We keep ProLegion, secure, and free of spam and abuse so that this can be the platform where developers come together to create.</>
            },
            {
                "image": feature5,
                "heading": <>Features 1</>,
                "subheading": <>We keep ProLegion, secure, and free of spam and abuse so that this can be the platform where developers come together to create.</>
            },
            {
                "image": feature6,
                "heading": <>Features 1</>,
                "subheading": <>We keep ProLegion, secure, and free of spam and abuse so that this can be the platform where developers come together to create.</>
            },
        ]
    },
    //The only vendor management system you will ever need!
    "getStarted": {
        "heading": `Manage your resources with the power of cloud-VMS`,
        "subheading": `ProLegion is a next-gen cloud VMS that combines AI & cloud to help businesses organise their team & increase the efficiency of their business. Enterprises can strengthen relationships with their resources & achieve core business management tasks with ProLegion's cloud VMS.`
    }
}