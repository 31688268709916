import { Box, Button, Paper, IconButton, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pdf } from '@react-pdf/renderer';
import TimesheetMonthPdf from 'Components/TimesheetMonthPdf';
import { useTimesheetProvider } from 'Providers/Timesheet.provider';
import { useAlertProvider } from 'Providers/util/Alert';
import { getUserRole } from 'Utils/helper';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import {  useNavigate, useParams, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { CustomTable } from '../../Components';
import CreateTimesheetLink from './CreateTimesheet.link';
import { jobTimesheetColumns, timesheetColumns } from './Timesheets.columns';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from '@mui/icons-material/Download';
import SubmitTimesheetViaLinkModal from './SubmitTimesheetViaLinkModal';
import { useDispatch } from 'react-redux';
import { setSelectedTimesheetDate } from 'Redux/Feature/createTimesheetSlice';
import api from 'Utils/api';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarWeekIcon from '@mui/icons-material/ViewWeek';
import MonthlyViewTimesheet from './MonthlyViewTimesheet';


export default function TimesheetsTable({ isJobDetailsComponent = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams()
  const page = searchParams.get('page') || 1;
  const pageSize = searchParams.get('pageSize') || 10;
  const sortBy = searchParams.get('sortBy') || 'start';
  const [view, setView] = useState('week');

  const handleViewChange = (newView) => {
    setView(newView);
  };
  const orderBy = searchParams.get('orderBy') || 'desc';
  const jobIds = searchParams.get("jobIds");
  const isJobPage = window.location.pathname.includes('/dashboard/jobs/')
  const [timeSheetData, setTimeSheetData] = useState({
    meta: {},
    isJobDetails: false,
    timesheets: [],
  })
  const [combinedTimeSheetDate, setCombinedTimesheetDate] = useState({
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
  })
  const [rowCount, setRowCount] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageSize: parseInt(pageSize),
    pageIndex: parseInt(page) - 1,
  });
  const [sorting, setSorting] = React.useState([{ desc: orderBy === 'desc', id: sortBy }]);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setSelectedTimesheetDate(combinedTimeSheetDate.startDate));
  },[dispatch, combinedTimeSheetDate])

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const data = useRouteLoaderData('jobDetail');

  const jobName = useMemo(()=>{
    return data?.data?.result?.id;
  },[data])

  const { jobId } = useParams();

  const {
    meta,
    isJobDetails,
    timesheets,
  } = timeSheetData;

  const fetchTimesheet = async () => {
    setLoading(true);
    try {
      if (isJobDetailsComponent) {
        const res = await api.get('/timesheet/gettimesheetsforjob', {
          params: {
            job_id: params.jobId,
            page: searchParams.get('page') ? searchParams.get('page') : 1,
            size: searchParams.get('size') || 50,
            sortBy: searchParams.get('sortBy') || 'start',
            orderBy: searchParams.get('orderBy') ? 'desc' : 'asc',
          },
        });
        if (res) {
          setTimeSheetData({
            isJobDetails: true,
            timesheets: res?.data?.result || [],
            totalTimesheets: res?.data?.meta?.total || 0,
          })
          setLoading(false);
          return
        }
      }
      const jobIds = searchParams.get('jobIds');
      const res = await api.get('/timesheet', {
        params: {
          page: searchParams.get('page') || 1,
          size: searchParams.get('pageSize') || 10,
          sortBy: searchParams.get('sortBy') || 'start',
          orderBy: searchParams.get('orderBy') || 'desc',
          ...(!!jobIds && { jobIds: decodeURIComponent(jobIds) })
        },
      });
      setTimeSheetData({
        meta: res?.data?.meta,
        timesheets: res?.data?.result || [],
        totalTimesheets: res?.data?.meta?.total || 0,
      });
    } catch {
      setTimeSheetData({
        timesheets: [],
        totalTimesheets: 0,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTimesheet()
  }, [searchParams])

  useMemo(() => {
    setRowCount(10 * meta?.pages);
    setSearchParams({...(!!jobIds && {jobIds}), page: `${pagination.pageIndex + 1}`, pageSize: pagination.pageSize.toString()})
  }, [meta, pagination, jobIds])

  const role = getUserRole();
  const { error } = useAlertProvider();
  const { fetchTimesheetByWeek, downloadTimesheetasPdf } = useTimesheetProvider();
  const dateHandler = (name, value) => {
    setCombinedTimesheetDate({ ...combinedTimeSheetDate, [name]: value })
  }

  const downloadHandler = async () => {
    const sheet = await fetchTimesheetByWeek(jobId, combinedTimeSheetDate.startDate, combinedTimeSheetDate.endDate)
    if (sheet && sheet.length === 0) {
      return error("No timesheet exists for this month")
    }
    const doc = <TimesheetMonthPdf data={sheet} month={dayjs(combinedTimeSheetDate.startDate).format('MMMM-YYYY')} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    // download the blob
    downloadTimesheetasPdf(
      blob,
      new Date(combinedTimeSheetDate.startDate).toLocaleString('default', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }),
      new Date(combinedTimeSheetDate.startDate).toLocaleString('default', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      })
    );
  }

  const handleNavigateCreateTimesheet = ()=> {
    navigate(`/dashboard/timesheets/create?jobName=${jobName}`);
  }

  const handlePreviousMonth = () => {
    const newStartDate = dayjs(combinedTimeSheetDate.startDate).subtract(1, 'month').startOf('month');
    dateHandler("startDate", newStartDate);
};

const handleNextMonth = () => {
    const newStartDate = dayjs(combinedTimeSheetDate.startDate).add(1, 'month').startOf('month');
    dateHandler("startDate", newStartDate);
};

  return (
    <div>
      {!isJobPage && <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Box display={"flex"} flexDirection={"column"} sx={{ mb: 2.5 }}>
        {!!jobIds && <Button
          onClick={() => {navigate("/dashboard/client-resources")}}
          sx={{marginRight: "1rem", marginBottom: 0, paddingLeft: 0, background: "none", "&:hover": {background: "none"}}}
          startIcon={
            <ArrowBackIcon
              sx={{
                background: "#F3F4F6",
                width: "30px",
                marginRight: "20px",
                height: "30px",
                borderRadius: "8px",
              }}
            />
          }
        >
          Back
        </Button>}
        <Typography
          variant="subtitle1Bold"
          color="#182743"
          component="h3"
        >
          Timesheet
        </Typography>
        </Box>
        <CreateTimesheetLink />
      </Stack>}
      {isJobPage && (
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {role === "CONSULTANT" && (
          <Button variant='contained' onClick={handleNavigateCreateTimesheet}>
            Create Timesheet
          </Button>
        )}
        <Box display={"flex"} alignItems={"center"}>
          <DatePicker
            value={combinedTimeSheetDate.startDate}
            views={['month', 'year']}
            maxDate={dayjs(new Date())}
            onChange={(value) => dateHandler('startDate', value)}
            sx={{ marginBottom: '20px', marginRight: '15px' }}
            label='Select Month'
          />
          {role === 'VENDOR' && <SubmitTimesheetViaLinkModal />}
          <Button
            variant='contained'
            onClick={downloadHandler}
            sx={{ padding: '8px', height: '42px', marginLeft: '10px' }}
          >
            <DownloadIcon sx={{ marginRight: "8px" }} /> Download
          </Button>
        </Box>
          <Box className='flex items-center'>
          <Box>
            <IconButton
              onClick={handlePreviousMonth}
              // style={{ position: 'absolute', left: '-30px', top: '50%', zIndex: 9999, transform: 'translateY(-50%)' }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
                onClick={handleNextMonth}
                // style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
            >
                <ChevronRight />
            </IconButton>
          </Box>
            <Box sx={{
              borderRadius: '10px',
              padding: '5px 10px',
              cursor: 'pointer',
              margin: '5px',
              backgroundColor: view === 'month' ? 'primary.main' : 'transparent',
              color: view === 'month' ? '#fff' : 'text.primary',
              '&:hover': {
                backgroundColor: view === 'month' ? 'primary.dark' : 'action.hover',
              }
            }} onClick={() => handleViewChange('month')}>
              <CalendarMonthIcon />
              Month
            </Box>
            <Box sx={{
              borderRadius: '10px',
              padding: '5px 10px',
              cursor: 'pointer',
              margin: '5px',
              backgroundColor: view === 'week' ? 'primary.main' : 'transparent',
              color: view === 'week' ? '#fff' : 'text.primary',
              '&:hover': {
                backgroundColor: view === 'week' ? 'primary.dark' : 'action.hover',
              }
            }} onClick={() => handleViewChange('week')}>
              <CalendarWeekIcon />
              Week
            </Box>
          </Box>
        </Box>
      )}
        {!isJobDetailsComponent && <CustomTable
          state={{
            isLoading: loading,
            pagination,
            sorting,
          }}
          paginationDisplayMode='pages'
          columns={jobId ? jobTimesheetColumns[role] : timesheetColumns[role]}
          data={isJobDetails ? timesheets?.result : timesheets}
          onPaginationChange={setPagination}
          manualPagination={true}
          rowCount={rowCount}
          manualSorting
          enableColumnActions
          enableColumnFilterModes
          enableColumnFilters
          enableTopToolbar
          onSortingChange={(newPagination) => {
            setSorting(newPagination);
            setSearchParams((params) => {
              params.set('orderBy', newPagination[0]?.desc ? 'desc' : 'asc');
              return params;
            });
            return newPagination;
          }}
          enableSortingRemoval={false}
        />}
        {
          isJobDetailsComponent && <MonthlyViewTimesheet
          jobId={jobId}
          view={view}
          dateHandler={dateHandler}
          startDate={combinedTimeSheetDate.startDate}
          endDate={combinedTimeSheetDate.endDate}
          fetchTimesheetByWeek={fetchTimesheetByWeek}
          loading={loading}
          />
        }
    </div>
  );
}
