import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box, Grid, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import {
  Facebook, LinkedIn, Twitter, YouTube,
} from '@mui/icons-material';
import FullWidthWrapper from './Common/FullWidthWrapper/FullWidth.wrapper';

function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ backgroundColor: '#3985B6' }}>
      <FullWidthWrapper>
        <Grid container spacing={0}>
          <Grid
            item
            xs={16}
            sx={{
              ml: 2, mt: 6, color: 'common.white', textAlign: 'center',
            }}
          >
            <Link
            // onClick={() => navigate('/')}
              to='/'
              typography='body1'
              color='common.white'
              underline='hover'
            >
              Home
            </Link>
            {' '}
            |
            {' '}
            <Link
            // onClick={() => navigate('/enterprises')}
              to='/enterprises'
              typography='body1'
              color='common.white'
              underline='hover'
            >
              For Enterprises
            </Link>
            {' '}
            |
            {' '}
            <Link
            // onClick={() => navigate('/vendor')}
              to='/vendor'
              typography='body1'
              color='common.white'
              underline='hover'
            >
              For Vendors
            </Link>
            {' '}
            |
            {' '}
            <Link
            // onClick={() => navigate('/consultant')}
              to='/consultant'
              typography='body1'
              color='common.white'
              underline='hover'
            >
              For Consultants
            </Link>
            {' '}
            |
            {' '}
            <Link
            // onClick={() => navigate('/aboutus')}
              to='/aboutus'
              typography='body1'
              color='common.white'
              underline='hover'
            >
              About
            </Link>
            {' '}
            |
            {' '}
            <Link
            // onClick={() => navigate('/pricing')}
              to='/pricing'
              typography='body1'
              color='common.white'
              underline='hover'
            >
              Pricing
            </Link>
            {' '}
            |
            {' '}
            <Link
              onClick={() => navigate('/contact')}
              to='/contact'
              typography='body1'
              color='common.white'
              underline='hover'
            >
              Contact us
            </Link>
          </Grid>
          <Grid item xs={16} ml={1} my={3} textAlign='center'>
            <Stack direction='row' gap={2} justifyContent='center'>
              <Link target='_blank' to='https://www.facebook.com/prolegioninc'>
                <Facebook sx={{ color: 'common.white' }} />
              </Link>
              <Link target='_blank' to='https://x.com/tryprolegion'>
                <Twitter sx={{ color: 'common.white' }} />
              </Link>
              <Link target='_blank' to='https://www.linkedin.com/company/prolegion'>
                <LinkedIn sx={{ color: 'common.white' }} />
              </Link>
              <Link target='_blank' to='https://www.youtube.com/@prolegionsolutions'>
                <YouTube sx={{ color: 'common.white' }} />
              </Link>
            </Stack>
          </Grid>
          <Grid item container spacing={1} justifyContent='center' sx={{ typography: 'overline', color: 'common.white', textAlign: 'center' }}>
            <Grid item xs={16}>
              Copyright ©
              {' '}
              {new Date().getFullYear()}
              {' '}
              ProLegion.
            </Grid>
            <Grid item xs={16}>
              All Rights Reserved
              {useMediaQuery(theme.breakpoints.up('md')) ? <>&nbsp;&nbsp;|&nbsp;&nbsp;</> : <br />}
              <Link
              // onClick={() => window.open('/termsandcondition', '_blank')}
                to='/termsandcondition'
                typography='overline'
                color='common.white'
                underline='hover'
              >
                Terms of Use
              </Link>
              {' '}
              |
              {' '}
              <Link
              // onClick={() => window.open('/privacypolicy', '_blank')}
                to='/privacypolicy'
                typography='overline'
                color='common.white'
                underline='hover'
              >
                Privacy Policy
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </FullWidthWrapper>
    </Box>
  );
}

export default Footer;
