export const taskStatueses = {
    open: "OPEN",
    toDo: "TODO",
    inProgress: "IN PROGRESS",
    completed: "COMPLETED",
    closed: "CLOSED"
}

export const applicantStatus = {
    application: "All Application",
    shortlisted: "Shortlisted",
    waitlisted: "Waitlisted",
    submit: "Submit",
    hired: "Hired",
}