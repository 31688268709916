import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from '@mui/icons-material/Done';
import Button from 'Components/Common/semantic_tags/Button';
import { allPlansList } from 'Pages/Subscription/RazorPay/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsUserLoggedIn, selectUserRole } from 'Redux/Feature/user';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import usePayment from './usePayment';
import { Backdrop, CircularProgress } from "@mui/material";
import Typography from "Components/Common/semantic_tags/Typography";

const PricingCards = ({ setIsUpgradePlan, roles, activeSubscription, isPublic = false, className = '' }) => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const { role } = useSelector(selectUserRole);
  const isLoggedIn = true
  const [planDetails, setPlanDetails] = useState(allPlansList[role] || []);
  const [currency, setCurrency] = useState();
  const isSingle = (planDetails || []).length === 1

  const { onBuySubscriptionHandler, handleBillingCycleChange, paymenLoading } = usePayment(billingCycle, setCurrency,setPlanDetails, activeSubscription, isPublic, setBillingCycle, roles)
  useEffect(() => {
    if (roles) {
      setPlanDetails(allPlansList[roles])
      setBillingCycle('monthly')
    }
  }, [roles])

  const returnIcon = (type) => {
    if (type === 'Basic') return 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/free.png'
    else if (type === 'Professional') return 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/pro.png'
    else return 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/enterprise.png'
  }

  return (
    <div className={`${!isPublic ? '' : 'max-w-7xl'} ${isSingle ? 'min-h-[50vh]' : 'min-h-[80vh]'} mx-auto p-2 ${className}`}>
      {paymenLoading && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={paymenLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <div className={`flex ${(!isPublic && setIsUpgradePlan) ? 'justify-between' : 'justify-end'} mb-8`}>
        {!isPublic && setIsUpgradePlan && <ArrowBackIcon
          onClick={() => setIsUpgradePlan(false)}
          className='cursor-pointer'
          sx={{
            background: "#F3F4F6",
            width: "30px",
            marginRight: '20px',
            height: "30px",
            borderRadius: "8px",
          }}
        />}
        {(roles !== 'CONSULTANT' && role !== 'CONSULTANT') && <label className="flex items-center cursor-pointer">
          <span className="mr-2 text-sm">Monthly</span>
          <div className="relative">
            <input type="checkbox" id="toggle" className="sr-only" checked={billingCycle === 'yearly'} onChange={handleBillingCycleChange} />
            <div className={`block ${billingCycle === 'yearly' ? 'bg-[#00A0C1]' : 'bg-gray-600'} w-14 h-8 rounded-full`}></div>
            <div className={`dot absolute top-1 bg-white w-6 h-6 rounded-full transition-transform ${billingCycle === 'yearly' ? 'transform translate-x-[25px]' : 'transform translate-x-[5px]'}`}></div>
          </div>
          <span className="ml-2 text-sm">Yearly</span>
        </label>}
      </div>
      <div className={`${!isSingle && 'grid gap-4'} ${planDetails?.length > 1 ? 'grid-cols-2 md:grid-cols-3' : 'grid-cols-1'}`}>
        {planDetails.map((plan, index) => {
          const isMiddleCard = planDetails.length === 3 && index === 1;
          return (
            <div
              key={index}
              className={`${isSingle ? 'flex w-[50%] mx-[auto]' : 'p-6'} ${isMiddleCard ? 'bg-[#3985B6] text-white py-4' : 'bg-white'} shadow-lg rounded-lg ${isMiddleCard ? 'h-auto' : ''}`}
            >
              <div className={`flex flex-col justify-between h-full gap-4 ${isSingle && 'py-10 px-14'}`}>
                <div className='h-full'>
                  <div className='flex gap-4 items-center'>
                    <div className={`${isMiddleCard ? 'bg-white' :'bg-[#00A0C140]'} w-[60px] h-[60px] flex justify-center items-center rounded-lg w-block`}>
                      <img className='w-[30px] h-[30px]' src={returnIcon(plan.type)} />
                    </div>
                    <div>
                      <h3 className={`text-3xl font-bold mb-2 ${isMiddleCard ? 'text-white' :'text-[#211F54]'}`}>{plan.type}</h3>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className={`text-sm ${isMiddleCard ? 'text-white' :'text-[#211F54]'} tracking-wide`}>{plan.description}</p>
                  </div>
                  <div className="my-4">
                    <span className="text-4xl font-bold">
                      {
                        plan.price !== 'Free'
                          ? currency === 'INR'
                            ? '₹'
                            : currency ? '$' : ''
                          : isLoggedIn
                            ? ''
                            : 'Free'
                      }
                      {
                        plan.price
                      }
                    </span>
                    {plan.price !== 'Free' && billingCycle === 'yearly' && <span className={`text-sm ${isMiddleCard ? 'text-white' :'text-[#211F54]'}`}> / yearly</span>}
                    {plan.price !== 'Free' && billingCycle !== 'yearly' && <span className={`text-sm ${isMiddleCard ? 'text-white' :'text-[#211F54]'}`}> / monthly</span>}
                    {plan.price !== 'Free' && billingCycle === 'yearly' && <span className="ml-4 text-lg">( 33% Off )</span>}
                  </div>
                  {!isSingle && <ul className="">
                    <div className='my-2'><span className="text-lg font-bold">{plan.additional}</span></div>
                    {plan.features.map((feature, idx) => (
                      <li key={idx} className={`${isMiddleCard ? 'text-white' :'text-[#211F54]'} flex items-center text-sm text-gray-600 mb-[10px] gap-2`}>
                        <div className={`${isMiddleCard ? 'bg-white' : 'bg-[#3985B6]'} w-[20px] h-[20px] flex justify-center items-center rounded-full ${isMiddleCard ? 'text-[#3985B6]' : 'text-white'}`}><DoneIcon sx={{ fontSize: '14px' }} /></div>
                        {feature}
                      </li>
                    ))}
                  </ul>}
                </div>
                {
                  plan.name && activeSubscription?.attributes?.id?.includes(plan.name)
                  ? <Typography className="text-center"><FileDownloadDoneIcon /> Current Plan</Typography>
                  : (!plan?.remove || isPublic) &&
                  <Button
                    onClick={() => onBuySubscriptionHandler(plan)}
                    className={`w-full !rounded-full ${isMiddleCard ? 'focus:!bg-white hover:!bg-white bg-white !text-black !font-bold' : ''}`}
                    parentClassName='!w-full !rounded-full'
                    title={isPublic ? plan.freeButtonText : plan.buttonText}
                  />
                }
              </div>
              {isSingle && <ul className={`bg-[#3985B61C] ${isSingle && 'p-6 py-10 w-full rounded-lg'}`}>
                <div className='my-2'><span className="text-lg font-bold">{plan.additional}</span></div>
                {plan.features.map((feature, idx) => (
                  <li key={idx} className={`${isMiddleCard ? 'text-white' :'text-[#211F54]'} flex items-center text-sm text-gray-600 mb-[10px] gap-2`}>
                    <div className='bg-[#3985B6] w-[20px] h-[20px] flex justify-center items-center rounded-full text-white'><DoneIcon sx={{ fontSize: '14px' }} /></div>
                    {feature}
                  </li>
                ))}
              </ul>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingCards;
