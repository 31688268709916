import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../Components/Table";
import moment from "moment";

const headCells = ["Date", "Name", ""].map((label) => ({ label }));
const ListOfVendors = ({ vendors }) => {
  const navigate = useNavigate();
  const CustomRow = ({ row }) => {
    return (
      <TableRow hover tabIndex={-1} key={row?.user?.id}>
        <TableCell component="th">
          {moment.utc(row?.date).format("DD MMM, YYYY")}
        </TableCell>
        <TableCell>
          {row?.user?.firstName} {row?.user?.lastName}
        </TableCell>
        <TableCell>
          <span
            onClick={() => navigate("/vendor/profile/" + row?.user?.id)}
            className="text-blue-400 cursor-pointer"
          >
            View Profile
          </span>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <CustomTable
      title="List of Vendors"
      headCells={headCells}
      rows={Array.isArray(vendors) ? vendors : []}
      CustomRow={CustomRow}
      showHeaderOnEmpty={false}
      showDensePadding={false}
    />
  );
};

export default ListOfVendors;