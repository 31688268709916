import { Box } from '@mui/material';
import React from 'react';
import { statuses, timesheetStatus } from '../../Utils/constants';

export default function StatusPill({ status = '' }) {
  if (!status?.length) return null;

  const statusColorMap = {
    [statuses.job.ongoing]: {
      backgroundColor: '#A0A0D0',
      color: 'white',
    },
    [statuses.job.closed]: {
      backgroundColor: '#D3000080',
      color: 'white',
    },
    [timesheetStatus.approved]: {
      backgroundColor: '#DEF7EC',
      color: '#03543F',
    },
    [timesheetStatus.invoiced]: {
      backgroundColor: '#DEF7EC',
      color: '#03543F',
    },
    [timesheetStatus.none]: {
      backgroundColor: '#E5E7EB',
      color: '#182743',
    },
    [timesheetStatus.rejected]: {
      backgroundColor: '#FDE8E8',
      color: '#9B1C1C',
    },
    [timesheetStatus.submitted]: {
      backgroundColor: '#FDF6B2',
      color: '#723B13',
    },
    [timesheetStatus.saved]: {
      backgroundColor: '#E5E7EB',
      color: '#182743',
    },
    [statuses.resources.current]: {
      backgroundColor: '#F3F4F6',
      color: '#182743',
    },
    [statuses.resources.invited]: {
      backgroundColor: '#FDF6B2',
      color: '#182743',
    },
    [statuses.invoices.draft]: {
      backgroundColor: '#FDF6B2',
      color: '#723B13',
    },
    [statuses.invoices.paid]: {
      backgroundColor: '#DEF7EC',
      color: '#03543F',
    },
    [statuses.job.open]: {
      backgroundColor: '#DEF7EC',
      color: '#03543F',
    },
  };

  return (
    <Box
      sx={{
        ...statusColorMap[status],
        borderRadius: '6px',
        py: 1,
        px: 1,
        textAlign: 'center',
        typography: 'captionMedium',
        fontWeight: 700,
        display: 'inline'
      }}
    >
      {status.slice(0, 1) + status.slice(1).toLowerCase()}
    </Box>
  );
}
