import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { StatusPill } from 'Components/StatusPill';
import { Link as RouterLink } from 'react-router-dom';
import api from 'Utils/api';
import { apiPath } from 'Providers/Timesheet.provider';
import moment from 'moment';
import { getPermissions } from 'Utils/helper';
import { userPermissions } from 'Utils/constants';

const approveTimesheet = async (timesheet_id, comments) => {
  try {
    await api.post(apiPath.approveTimesheet, {
      timesheet_id,
      comments,
      userId: localStorage.getItem('userId'),
    })
  } catch (e) {
    console.error(e);
    return false;
  }
};

const timesheetColumnsDefs = {
  details: {
    accessorKey: 'id',
    header: 'View',
    Cell: ({ row }) => (
      <RouterLink
        to={`/dashboard/timesheets/${row.original.id}`}
      >
        <Typography variant="body2Normal" sx={{ color: '#0B85BA' }}>View Details</Typography>
      </RouterLink>
    ),
    enableSorting: false,
    size: 120,
  },
  approve: {
    accessorKey: 'status',
    header: 'Approve',
    Cell: ({ row }) => {
      if (row.original.status === 'SUBMITTED' && getPermissions().includes(userPermissions.timesheet.approve)) return <Button variant='contained' onClick={async () => {
        await approveTimesheet(row.original.id, '');
        window.location.reload();
      }}>Approve</Button> 
    },
    enableSorting: false,
    size: 120,
  },
  hours: {
    accessorKey: 'hours',
    header: 'Total Hours',
    Cell: ({ cell }) => {
      const value = Number(cell.getValue() || 0).toFixed(1);
      return (
        <Typography variant="body2Normal" sx={{ color: '#182743' }}>
          {value}
        </Typography>
      );
    },
    size: 120,
  },
  job: {
    accessorKey: 'job.name',
    header: 'Job',
    Cell: ({ cell, row }) => (
      <Stack direction="column" gap={0.5} alignItems="flex-start">
        <RouterLink to={`/dashboard/jobs/${row.original.job?.id}/timesheets`} title="View Timesheets for Job">
          <Typography variant="body1Semibold" sx={{ color: '#182743', '&:hover': { textDecoration: 'underline' } }}>{cell.getValue()}</Typography>
        </RouterLink>
        {row.original.client?.company && (
          <Typography variant="captionMedium" sx={{ color: '#6B7280' }}>{row.original.client.company}</Typography>
        )}
      </Stack>
    ),
    size: 180,
  },
  project: {
    accessorKey: 'project.name',
    header: 'Project',
    Cell: ({ cell }) => (
      <Typography variant="body2Normal" sx={{ color: '#182743' }}>{cell.getValue()}</Typography>
    ),
    size: 120,
  },
  resource: {
    accessorKey: 'consultant',
    header: 'Resource Name',
    Cell: ({ cell }) => (
      <Stack direction="row" gap={2} alignItems="center">
        <Typography variant="body2Normal" sx={{ color: '#182743' }}>
          {cell.getValue()?.firstName}
          {' '}
          {cell.getValue()?.lastName}
        </Typography>
      </Stack>
    ),
    size: 120,
  },
  status: {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => <StatusPill status={cell.getValue()} />,
    size: 120,
  },
  updatedAt: {
    accessorKey: 'updatedAt',
    header: 'Last Updated',
    Cell: ({ cell }) => {
      if (!cell.getValue()) return null;

      const date = new Date(cell.getValue());
      return (
        <Typography variant="body2Normal" sx={{ color: '#182743' }}>{moment(date.toLocaleDateString()).format('DD MMM, YYYY')}</Typography>
      );
    },
    size: 120,
  },
  week: {
    accessorKey: 'start',
    header: 'Week',
    Cell: ({ row }) => {
      return (
        <Typography variant="body2Normal" sx={{ color: '#182743' }}>
          {moment.utc(row.original.start).format('DD MMM')}
          {' '}
          -
          {' '}
          {moment.utc(row.original.end).format('DD MMM')}
        </Typography>
      );
    },
    size: 120,
  },
};

export const timesheetColumns = {
  CLIENT: [
    timesheetColumnsDefs.job,
    timesheetColumnsDefs.resource,
    timesheetColumnsDefs.week,
    timesheetColumnsDefs.hours,
    timesheetColumnsDefs.updatedAt,
    timesheetColumnsDefs.status,
    timesheetColumnsDefs.details,
    timesheetColumnsDefs.approve,
  ],
  CONSULTANT: [
    timesheetColumnsDefs.job,
    timesheetColumnsDefs.week,
    timesheetColumnsDefs.hours,
    timesheetColumnsDefs.updatedAt,
    timesheetColumnsDefs.status,
    timesheetColumnsDefs.details,
  ],
  VENDOR: [
    timesheetColumnsDefs.job,
    timesheetColumnsDefs.resource,
    timesheetColumnsDefs.week,
    timesheetColumnsDefs.hours,
    timesheetColumnsDefs.updatedAt,
    timesheetColumnsDefs.status,
    timesheetColumnsDefs.details,
  ],
};

export const jobTimesheetColumns = {
  CLIENT: [
    timesheetColumnsDefs.resource,
    timesheetColumnsDefs.week,
    timesheetColumnsDefs.hours,
    timesheetColumnsDefs.updatedAt,
    timesheetColumnsDefs.status,
    timesheetColumnsDefs.details,
  ],
  CONSULTANT: [
    timesheetColumnsDefs.week,
    timesheetColumnsDefs.hours,
    timesheetColumnsDefs.updatedAt,
    timesheetColumnsDefs.status,
    timesheetColumnsDefs.details,
  ],
  VENDOR: [
    timesheetColumnsDefs.resource,
    timesheetColumnsDefs.week,
    timesheetColumnsDefs.hours,
    timesheetColumnsDefs.updatedAt,
    timesheetColumnsDefs.status,
    timesheetColumnsDefs.details,
  ],
};
