import React, { useRef, useState } from "react";
import Button from "Components/Common/semantic_tags/Button";
import CloseIcon from "@mui/icons-material/Close";
import BackupIcon from "@mui/icons-material/Backup";

function DragDropFileComponent({ selectedfile, setSelectedfile, title }) {
  const [isDragging, setIsDragging] = useState(false);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDropEvent = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'application/pdf') {
      setSelectedfile(droppedFile);
    } 
  };

  const handleInputFiles = (event) => {
    setIsDragging(false);
    const droppedFile = event.target.files[0];
    if (droppedFile && droppedFile.type === 'application/pdf') {
      setSelectedfile(droppedFile);
    }
  };

  const handleBrowseClick = (event) => {
    inputRef.current?.click();
  };

  // const handleDelete = (fileToDeselect) => {
  //   const updateSelect = selectedfile.filter((options) => {
  //     return options.name !== fileToDeselect.name;
  //   });
  //   setSelectedfile(updateSelect);
  // };

  const urlfile = !!selectedfile ? URL.createObjectURL(selectedfile) : "";

  return (
    <div
      className="flex flex-col cursor-pointer"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDropEvent}
      onClick={handleBrowseClick}
    >
      <div className="flex flex-col">
        <span className="text-[#111928] font-bold pb-1 text-sm">{title}</span>
        {selectedfile ? (
          <div className="flex justify-center">
            <div className="flex items-center">
              <a
                className="pr-1"
                href={urlfile}
                rel="noreferrer"
                target="_blank"
              >
                {selectedfile.name}
              </a>
              <Button className={"!px-0 !py-0 !bg-transparent text-[#000] border-transparent hover:!bg-transparent hover:!shadow-none"} title={<CloseIcon sx={{color: "#000"}} />} onClick={() => setSelectedfile(null)} />
            </div>
          </div>
        ) : (
          <div
            className={`flex flex-col justify-center items-center p-4 border-dashed border-2 border-[#cbd5e1] rounded-lg dragdrop-container ${
              isDragging ? "bg-[#e2e8f0]" : "bg-[#f1f1f1]"
            }`}
          >
            <BackupIcon />
            {isDragging ? (
              <span className="text-bold cursor-pointer">Drop Here</span>
            ) : (
              <span>
                Drag & Drop, or
                <span
                  className="font-extrabold cursor-pointer"
                  onClick={handleBrowseClick}
                >
                  {" "}
                  Browse{" "}
                </span>
                your file(s)
              </span>
            )}
            <input
              type="file"
              accept="application/pdf"
              multiple
              onChange={handleInputFiles}
              ref={inputRef}
              style={{ display: "none" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DragDropFileComponent;
