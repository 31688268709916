import { Add } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  IconButton,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { roleHelper } from "../../Components/Helper";
import CreateIcon from "@mui/icons-material/Create";
import ClearIcon from '@mui/icons-material/Clear';
import { UpdateReference } from "Pages/editProfile/api";
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from "react-redux";
import { selectIsUserLoggedIn, selectUserDetails } from "Redux/Feature/user";
import moment from "moment";
import { useAlertProvider } from "Providers/util/Alert";
import { stringAvatar } from "Utils/avatar";

export default function ProfileReferencesTab({ details, setDetails, setReferenceAPI }) {
  const { success, error } = useAlertProvider();
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const userDetails = useSelector(selectUserDetails);
  const { isConsultant } = roleHelper.getRoleType(details?.role);
  const [currentReference, setCurrentReference] = useState(null);
  const [newClientName, setNewClientName] = useState("");

  function updateClientName(reference) {
    setCurrentReference(reference);
  }

  async function updateReferenceDetails() {
    const payload = {
      clientDisplayName: newClientName,
    };
    const resp = await UpdateReference(payload, currentReference.id);
    setCurrentReference(null);
    setNewClientName("");
    setReferenceAPI(true);
    if (!resp.success) {
      return error(resp.message);
    }
    setDetails({
      ...details,
      references: details?.references?.map((i) => {
        if (i.id === resp.data.id) {
          return { ...i, clientDisplayName: resp.data.clientDisplayName };
        }
        return i;
      }),
    });
    success('Updated successfully');
  }

  return !details?.references?.length ? (
    <Typography variant="body2Normal">
      No {isConsultant ? "Experience" : "References"} Added
    </Typography>
  ) : (
    (details?.references || [])?.map((reference) => (
      <Accordion
        key={reference.id}
        elevation={0}
        sx={{ border: "1px solid #E5E7EB", borderRadius: "8px" }}
      >
        <AccordionSummary
          sx={{
            p: 3,
            ".MuiAccordionSummary-content": {
              margin: 0,
              "#expand-button": {
                color: "#0B85BA",
                border: "1px solid #0B85BA",
                backgroundColor: "white",
                transform: "rotate(0deg)",
                transition: (theme) =>
                  theme.transitions.create(["transform", "background-color"], {
                    duration: theme.transitions.duration.standard,
                    easing: theme.transitions.easing.easeInOut,
                  }),
                "&:hover": {
                  color: "white",
                  backgroundColor: "#0B85BA",
                },
                "#expand-button--icon": {
                  color: "#0B85BA",
                },
              },
              "&.Mui-expanded": {
                margin: 0,
                "#expand-button": {
                  backgroundColor: "#0B85BA",
                  transform: "rotate(45deg)",

                  "#expand-button--icon": {
                    color: "white",
                  },
                },
              },
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flex={1}
          >
            <Stack direction="row" gap={1} alignItems={"center"}>
              {reference?.client?.profile_image ? <Avatar
                alt={reference?.clientDisplayName}
                src={reference?.client?.profile_image}
                variant="rounded"
                sx={{ width: "48px", height: "48px" }}
              /> : 
                <Avatar
                  {...stringAvatar(
                    reference?.clientDisplayName
                      ? reference?.clientDisplayName
                      : reference?.client?.company
                  )}
                  
                />
              }
              {currentReference?.id === reference?.id ? (
                <>
                  <TextField
                    id="outlined-basic"
                    label="Client Name"
                    variant="outlined"
                    value={newClientName}
                    onChange={(e) => setNewClientName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        updateReferenceDetails();
                      }
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseEnter={(e) => e.stopPropagation()}
                  />
                  <ClearIcon onClick={() => updateClientName(null)} />
                  <DoneIcon onClick={updateReferenceDetails} />
                </>
              ) : (
                <Typography variant="body1Semibold" color="#182743">
                  {isLoggedIn && details?.user_id === userDetails?.user_id && reference?.client?.company} 
                  {`${reference?.clientDisplayName ? `${isLoggedIn && details?.user_id === userDetails?.user_id ? ` ( ${reference?.clientDisplayName} )`: `${reference?.clientDisplayName}` }` : ''}`}
                  {`${!reference?.clientDisplayName && isLoggedIn && details?.user_id !== userDetails?.user_id ? `${reference?.client?.company}` : "" }`}
                </Typography>
              )}
              {(isLoggedIn && details?.user_id === userDetails?.user_id && currentReference?.id !== reference?.id) && (
                <CreateIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    updateClientName(reference);
                  }}
                />
              )}
            </Stack>
            <Stack direction="row" gap={3} alignItems={"center"}>
              <Typography variant="body2Normal" color="#6B7280">
                {reference?.jobs?.length > 0 && `Completed ${reference?.jobs?.length || 0} Jobs`}
              </Typography>
             {reference?.jobs?.length > 0 && <IconButton id="expand-button">
                <Add
                  id="expand-button--icon"
                  sx={{ color: "white", fontSize: 21 }}
                />
              </IconButton>}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3, pt: 0 }}>
          {(reference?.jobs || []).map((job, index) => (
            <Box
              key={job.id}
              sx={{
                pb: index !== reference?.jobs?.length - 1 ? 4 : 0,
                mb: index !== reference?.jobs?.length - 1 ? 4 : 0,
                borderBottom:
                  index !== reference?.jobs?.length - 1
                    ? "1px solid #E5E7EB"
                    : "none",
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="body1Bold" color={"#182743"}>
                  {job.name}
                </Typography>
                <Typography variant="body2Normal" color="#6B7280">
                  {moment(job?.start_date).format("MMM YYYY")} -{" "}
                  {job?.status === "ONGOING"
                    ? "ONGOING"
                    : moment(job?.end_date).format("MMM YYYY")}
                </Typography>
              </Stack>

              <Box
                sx={{
                  typography: "body2Normal",
                  color: "#6B7280",
                  marginTop: "10px",
                }}
              >
                Skills sets:{" "}
                <span className="ml-4">{(job?.main_skill || []).join(",")}</span>
              </Box>
              {/* <Stack flexDirection={'row'} gap='10px' sx={{ typography: "body2Normal", color: "#6B7280" }}>
                Job Details:
                <Box
                  sx={{ typography: "body2Normal", color: "#6B7280" }}
                  dangerouslySetInnerHTML={{ __html: job.description }}
                />
              </Stack> */}
            </Box>
          ))}
          <div
            dangerouslySetInnerHTML={{ __html: reference?.description }}
          ></div>

          <Grid item xs={15} md={15}></Grid>
        </AccordionDetails>
      </Accordion>
    ))
  );
}
