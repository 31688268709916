import React from 'react';
import { Box, TextField } from '@mui/material';

function ProfileName({ names, setNames }) {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: {xs: "column", md: "row"}, gap: '30px' }}>
      <TextField
        sx={{ width: {xs: "100%", md: '30%'} }}
        label="First Name"
        value={names.first_name}
        onChange={(e) => setNames(e.target.value, 'first_name')}
      />
      <TextField
        sx={{ width: {xs: "100%", md: '30%'} }}
        label="Last Name"
        value={names.last_name}
        onChange={(e) => setNames(e.target.value, 'last_name')}
      />
    </Box>
  );
}

export default ProfileName;
