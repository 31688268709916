import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  NativeSelect,
  TextField,
} from '@mui/material';

import { Modal } from 'antd';
import {
  Link, useNavigate, useParams,
  useSearchParams,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import OTPInput from 'react-otp-input';
import {
  CheckEmailExist,
  CheckUsernameExist,
  InvitedUser,
  RegisterWithInvite,
  ResendOTP,
  SendOTP,
} from './Helper';
import SideImage from '../../Assets/SideImage2.png';
import { validatePassword } from '../../Utils/validation';
import { useCountryStateCityProvider } from '../../Providers/Common/CountyStateCity';
import { fetchShareableLink } from '../../services/vendors';
import { InviteEmailAddress } from './InviteEmailAddress';

function InviteSignup() {
  const { inviteid } = useParams();

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [emailUsed, setEmailUsed] = useState(false);
  const [usernameUsed, setUsernameUsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [otp, setOtp] = useState('');
  const [isCorrectOtp, setIsCorrectOtp] = useState(true);
  const [otpToken, setOtpToken] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [email, setEmail] = useState('');
  const [invitedBy, setInvitedBy] = useState('');
  const [country, setCountry] = useState('US');
  const [state, setState] = useState('TX');
  const [checkmarks, setCheckmarks] = useState(false);
  const [isSharedInvite, setIsSharedInvite] = useState(false);
  const [sharedInvite, setSharedInvite] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  
  const isByLinkInvite = searchParams.get('med');

  const navigate = useNavigate();
  const registerUser = (otpDetails) => {
    let payload = { ...data };
    if (data !== null) {
      if (isSharedInvite) {
      // Sync data for shared invite by URL
        payload = {
          ...payload,
          isSharedUrlInvite: true,
          inviteId: sharedInvite.id,
        };
      } else if(isByLinkInvite) {
        payload = {
          ...payload,
          bycsv: true
        }
      }
    }
    RegisterWithInvite({ ...payload, ...otpDetails })
      .then((res) => {
        setOtp('');
        if (res == null) {
          setIsCorrectOtp(true);
          return;
        }
        if (!res.data.success) {
          setIsCorrectOtp(true);
          Swal.fire(
            'Already Registred !',
            'Account already exists! If you are unable to login, Please wait for you account to be approved.',
            'success',
          );
        } else if (res.data.success) {
          if (currentRole === 'CONSULTANT') {
            setIsCorrectOtp(true);
            Swal.fire({
              title: 'Registered Successfully',
              text: 'Please Login to continue',
              icon: 'success',
              confirmButtonText: 'Login',
              showCloseButton: true,
            }).then((result) => {
              if (result.isConfirmed) navigate('/signin');
            });
          } else navigate('/signedup');
        } else {
          setIsCorrectOtp(true);
          Swal.fire('Not Registered !', res.response.data.msg, 'error');
        }
      })
      .catch((err) => {
        setIsCorrectOtp(false);
        console.warn(err);
        console.error('______');
      });
  };
  const {
    countries,
    states,
    cities,
    getCountries,
    getStatesByCountry,
    getCitiesByStateAndCountry,
  } = useCountryStateCityProvider();

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    getStatesByCountry(country);
  }, [country]);
  useEffect(() => {
    getCitiesByStateAndCountry(state, country);
  }, [state]);

  const onSubmit = (formData) => {
    if (!emailUsed && !usernameUsed) {
      showModal(formData);
      formData.email = email;
      formData.inviteId = invitedBy;
      setData(formData);
    }
  };

  const showModal = (formData) => {
    if(isCorrectOtp) {
      SendOTP({ email })
      .then((res) => {
        setOtpToken(res.data.token);
      })
      .catch((err) => {
        console.error(err);
      });
    }
    setOpen(true);
  };

  const resendOTP = () => {
    if (otpToken === '') {
      SendOTP({ email })
        .then((res) => {
          setOtpToken(res.data.token);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      ResendOTP({ email, OTPToken: otpToken })
        .then((res) => {

        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleOk = () => {
    // setOpen(false);
    registerUser({ OTP: otp, OTPToken: otpToken });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const checkEmailUsed = (email, checkServer = true) => {
    if (process.env.REACT_APP_BASE_URL === 'https://dev.api.prolegion.com') {
      if (
        email.split('@')[1] === 'yopmail.com'
        || email.split('@')[1] === 'test.com'
      ) {
        Swal.fire('Invalid Email!', 'Please enter a correct email...', 'error');
      }
    }
    if (checkServer) {
      CheckEmailExist({ email }).then((res) => {
        setEmailUsed(res?.data?.status);
      });
    }
  };

  const checkUsernameUsed = (username) => {
    CheckUsernameExist({ username }).then((res) => {
      setUsernameUsed(res?.data?.status);
    });
  };

  function checkInviteType() {
    const params = new URLSearchParams(window.location.search);
    const actionType = params.get('action');

    const isActionValid = params.size > 0 && actionType === 'shared_invite';
    setIsSharedInvite(isActionValid);
    return isActionValid;
  }

  useEffect(() => {
    if (checkInviteType()) {
      fetchShareableLink(inviteid)
        .then(({ data }) => {
          setSharedInvite(data.data);
          // @todo urlType chan be changes in future
          setCurrentRole(data.data?.urlType?.toUpperCase());

          setInvitedBy(data.data.shared_by);
          setEmailUsed(false);
        })
        .catch((error) => {
          console.error('fetchShareableLink error :>> ', error);
        });
    } else {
      InvitedUser(inviteid).then((res) => {
        setCurrentRole(res?.data?.result?.role);
        setInvitedBy(res?.data?.result?.id);
        setEmail(res?.data?.result?.email);
        checkEmailUsed(res?.data?.result?.email);
      });
    }
  }, [inviteid, isSharedInvite]);

  return (
    <div>
      <div className="grid lg:grid-cols-2 lg:gap-5">
        <div className="flex justify-center p-8 items-start bg-[#EDFDF9] lg:mr-16">
          <img src={SideImage} style={{ width: '80vh' }} alt="side_image" />
        </div>
        <div className="p-2 md:p-5 lg:p-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2 ml-10 justify-between pt-10 pb-4">
              <div className="grid w-full">
                {currentRole === 'CLIENT' && (
                  <div className="w-[100%]">
                    <span className="font-semibold text-xl">
                      You are being registered as a Client
                    </span>
                  </div>
                )}
                {currentRole === 'VENDOR' && (
                  <div className="w-[100%]">
                    <span className="font-semibold text-xl">
                      You are being registered as a Vendor
                    </span>
                  </div>
                )}
                {currentRole === 'CONSULTANT' && (
                  <div className="w-[100%]">
                    <span className="font-semibold text-xl">
                      You are being registered as a Consultant
                    </span>
                  </div>
                )}
              </div>
            </div>
            {currentRole === 'CLIENT' ? (
              <div className="px-10">
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('firstName', { required: true })}
                  label="First Name"
                  variant="outlined"
                  fullWidth
                />
                {errors.firstName ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *First Name is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  // margin="normal"
                  {...register('lastName', { required: true })}
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                />
                {errors.lastName ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Last Name is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                {/* <Controller
                  control={control}
                  name="username"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      className="pt-2"
                      label="Username"
                      fullWidth
                      {...field}
                      onBlur={(e) => {
                        checkUsernameUsed(e.target.value);
                      }}
                      onChange={(e) =>
                        field.onChange(e.target.value.toLowerCase())
                      }
                    />
                  )}
                />
                {usernameUsed ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-4">
                    Username is already used
                  </h4>
                ) : (
                  " "
                )}
                {errors.username ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Username is required
                  </h4>
                ) : (
                  <div>{usernameUsed ? "" : <br />}</div>
                )} */}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  label={"Email"}
                  value={email}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={!isSharedInvite}
                />
                {emailUsed ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-4">
                    Email is already used
                  </h4>
                ) : (
                  ' '
                )}
                {errors.email ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Email is required
                  </h4>
                ) : (
                  <div>{emailUsed ? '' : <br />}</div>
                )}
                <TextField
                  type="password"
                  className="pt-2"
                  id="outlined-basic"
                  {...register('password', { validate: validatePassword })}
                  error={!!errors?.password}
                  helperText={errors?.password?.message}
                  label="Password"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('companyName', { required: true })}
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                />
                {errors.companyName ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Company Name is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('phone', { required: true })}
                  label="Phone No."
                  inputProps={{ maxLength: 12 }}
                  variant="outlined"
                  fullWidth
                />
                {errors.phone ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Phone is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('website')}
                  label="Website"
                  variant="outlined"
                  fullWidth
                />
                {errors.website ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Website is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('address')}
                  label="Address"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                {/* <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register("country")}
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register("city")}
                  label="City"
                  variant="outlined"
                  fullWidth
                /> */}
                <NativeSelect
                  id="country-select"
                  placeholder="Country"
                  variant="outlined"
                  defaultValue="US"
                  className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                  fullWidth
                  {...register('country', {
                    onChange: (e) => {
                      setCountry(e.target.value);
                      setValue('country', e.target.value);
                    },
                  })}
                >
                  {countries.length > 0
                    && countries.map((country) => (
                      <option key={country.iso2} value={country.iso2}>
                        {country.name}
                      </option>
                    ))}
                </NativeSelect>

                <br />
                <br />
                <NativeSelect
                  showSearch
                  labelId="city-label"
                  id="city-select"
                  placeholder="City"
                  variant="outlined"
                  defaultValue="TX"
                  className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                  fullWidth
                  {...register('state', {
                    onChange: (e) => {
                      setState(e.target.value);
                      setValue('state', e.target.value);
                    },
                  })}
                >
                  {country
                      && states.length > 0
                      && states?.map((state) => (
                        <option key={state.iso2} value={state.iso2}>
                          {state.name}
                        </option>
                      ))}
                </NativeSelect>
                <br />
                <br />
                <NativeSelect
                  className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                  labelId="city-label"
                  id="city-select"
                  placeholder="City"
                  variant="outlined"
                  fullWidth
                  {...register('city')}
                >
                  {state
                    && cities.map((city, idx) => (
                      <option key={idx} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                </NativeSelect>

                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('Zip')}
                  label="Zip"
                  inputProps={{ maxLength: 12 }}
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
              </div>
            ) : null}
            {currentRole === 'VENDOR' ? (
              <div className="px-10">
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('firstName', { required: true })}
                  label="First Name"
                  variant="outlined"
                  fullWidth
                />
                {errors.firstName ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *First Name is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  // margin="normal"
                  {...register('lastName', { required: true })}
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                />
                {errors.lastName ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Last Name is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                {/* <Controller
                  control={control}
                  name="username"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      className="pt-2"
                      label="Username"
                      fullWidth
                      {...field}
                      onBlur={(e) => {
                        checkUsernameUsed(e.target.value);
                      }}
                      onChange={(e) =>
                        field.onChange(e.target.value.toLowerCase())
                      }
                    />
                  )}
                />
                {usernameUsed ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-4">
                    Username is already used
                  </h4>
                ) : (
                  " "
                )}
                {errors.username ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Username is required
                  </h4>
                ) : (
                  <div>{usernameUsed ? "" : <br />}</div>
                )} */}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  label={!isSharedInvite ? email : 'Email *'}
                  value={email}
                  onInput={(event) => {
                    if (isSharedInvite) {
                      setEmail(event.target.value);
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  disabled={!isSharedInvite}
                />
                {emailUsed ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-4">
                    Email is already used
                  </h4>
                ) : (
                  ' '
                )}
                {errors.email ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Email is required
                  </h4>
                ) : (
                  <div>{emailUsed ? '' : <br />}</div>
                )}
                <TextField
                  type="password"
                  className="pt-2"
                  id="outlined-basic"
                  {...register('password', { validate: validatePassword })}
                  error={!!errors?.password}
                  helperText={errors?.password?.message}
                  label="Password"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('companyName')}
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('phone', { required: true })}
                  label="Phone No."
                  inputProps={{ maxLength: 12 }}
                  variant="outlined"
                  fullWidth
                />
                {errors.phone ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Phone is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('website')}
                  label="Website"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('address')}
                  label="Address"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                {/* <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register("country")}
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register("city")}
                  label="City"
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br /> */}
                <NativeSelect
                  id="country-select"
                  placeholder="Country"
                  variant="outlined"
                  defaultValue="US"
                  className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                  fullWidth
                  {...register('country', {
                    onChange: (e) => {
                      setCountry(e.target.value);
                      setValue('country', e.target.value);
                    },
                  })}
                >
                  {countries.length > 0
                    && countries.map((country) => (
                      <option key={country.iso2} value={country.iso2}>
                        {country.name}
                      </option>
                    ))}
                </NativeSelect>

                <br />
                <br />
                <NativeSelect
                  showSearch
                  labelId="city-label"
                  id="city-select"
                  placeholder="City"
                  variant="outlined"
                  defaultValue="TX"
                  className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                  fullWidth
                  {...register('state', {
                    onChange: (e) => {
                      setState(e.target.value);
                      setValue('state', e.target.value);
                    },
                  })}
                >
                  {country
                      && states.length > 0
                      && states?.map((state) => (
                        <option key={state.iso2} value={state.iso2}>
                          {state.name}
                        </option>
                      ))}
                </NativeSelect>
                <br />
                <br />
                <NativeSelect
                  className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                  labelId="city-label"
                  id="city-select"
                  placeholder="City"
                  variant="outlined"
                  fullWidth
                  {...register('city')}
                >
                  {state
                    && cities.map((city, idx) => (
                      <option key={idx} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                </NativeSelect>
                <br />
                <br />
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('Zip')}
                  label="Zip"
                  inputProps={{ maxLength: 12 }}
                  variant="outlined"
                  fullWidth
                />
                <br />
                <br />
              </div>
            ) : null}
            {currentRole === 'CONSULTANT' ? (
              <div className="px-10 pb-6">
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  {...register('firstName', { required: true })}
                  label="First Name"
                  variant="outlined"
                  fullWidth
                />
                {errors.firstName ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *First Name is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                <TextField
                  className="pt-2"
                  id="outlined-basic"
                  // margin="normal"
                  {...register('lastName', { required: true })}
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                />
                {errors.lastName ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Last Name is required
                  </h4>
                ) : (
                  <div>
                    <br />
                  </div>
                )}
                {/* <Controller
                  control={control}
                  name="username"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      className="pt-2"
                      label="Username"
                      fullWidth
                      {...field}
                      onBlur={(e) => {
                        checkUsernameUsed(e.target.value);
                      }}
                      onChange={(e) =>
                        field.onChange(e.target.value.toLowerCase())
                      }
                    />
                  )}
                />
                {usernameUsed ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-4">
                    Username is already used
                  </h4>
                ) : (
                  " "
                )}
                {errors.username ? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    *Username is required
                  </h4>
                ) : (
                  <div>{usernameUsed ? "" : <br />}</div>
                )}
                {usernameUsed ?? (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    Username is already used
                  </h4>
                )} */}

                <InviteEmailAddress
                  email={email}
                  emailUsed={emailUsed}
                  errors={errors}
                  setEmail={setEmail}
                  checkEmailUsed={checkEmailUsed}
                  isSharedInvite={isSharedInvite}
                />

                <TextField
                  type="password"
                  className="py-2"
                  id="outlined-basic"
                  {...register('password', { validate: validatePassword })}
                  error={!!errors?.password}
                  helperText={errors?.password?.message}
                  label="Password"
                  variant="outlined"
                  fullWidth
                />
              </div>
            ) : null}
            <div className="px-10 py-2">
              <div className="flex flex-row items-center -space-x-3">
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      // {...register("checkterms")}
                      defaultChecked={false}
                      value={checkmarks}
                      onChange={() => setCheckmarks(!checkmarks)}
                    />
                  )}
                />
                <p>
                  I have read and understand prolegion's
                  {' '}
                  <Link
                    to="/privacypolicy"
                    className="text-[#2EBAA3] font-bold"
                  >
                    privacy policy
                  </Link>
                  {' '}
                  and
                  {' '}
                  <Link
                    to="/termsandcondition"
                    className="text-[#2EBAA3] font-bold"
                  >
                    Terms & Condtions
                  </Link>
                </p>
              </div>
            </div>
            <div className="px-10">
              <Button
                type="submit"
                variant="contained"
                style={{ color: '#FFFFFF' }}
                disabled={!checkmarks}
                fullWidth
              >
                Signup
              </Button>
              <h4 className="pt-2">
                Already have an account?
                {' '}
                <Link
                  to="/signin"
                  className="font-semibold cursor-pointer underline"
                  style={{ color: '#2EBAA3' }}
                >
                  Login Here
                </Link>
              </h4>
            </div>
          </form>
        </div>
      </div>
      <Modal
        title={(
          <span className="font-medium text-[24px] leading-[36px]">
            Verify Email
          </span>
        )}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: 'font-medium h-auto py-1 text-[17px] px-6 mt-6',
          style: { backgroundColor: '#139588' },
        }}
        okText="Verify"
        cancelButtonProps={{ hidden: true }}
      >
        <h4 className="text-center text-[16px] leading-[24px] mt-7 mb-1.5">
          Enter OTP received on your email
        </h4>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            width: '60px',
            height: '54px',
            borderWidth: 1,
            borderColor: '#139588',
            outline: 'none',
            borderRadius: 5,
          }}
          containerStyle={{
            flex: 1,
            justifyContent: 'center',
            marginBottom: 8,
          }}
          renderSeparator={<span className="w-1.5" />}
          renderInput={(props) => <input {...props} />}
        />
        <span
          className="text-[#2196F3] text-[16px] leading-[24px] cursor-pointer ml-10"
          onClick={resendOTP}
        >
          Resend OTP
        </span>
      </Modal>
    </div>
  );
}

export default InviteSignup;
