import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useCountryStateCityProvider } from '../../../Providers/Common/CountyStateCity';

const style = {
  marginTop: '10px', display: 'flex', flexDirection: {xs: "column", md: "row"}, gap: {xs: "10px", md: '30px'}, alignItems: 'center',
};

function AddressSection({
  address,
  setAddress,
  isStreetRequired = true,
  isValid,
  isError
}) {
  const [zipcodeError, setZipcodeError] = useState('');
  const {
    countries,
    states,
    cities,
    getCountries,
    getStatesByCountry,
    getCitiesByStateAndCountry,
  } = useCountryStateCityProvider();
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (address.country) {
      getStatesByCountry(address.country.iso2 || address.country);
    }
    if (address.state && address.country) {
      getCitiesByStateAndCountry(address.state.iso2 || address.state, address.country.iso2 || address.country);
    }
  }, []);

  const handleZipcodeChange = (e) => {
    const zipcodeValue = e.target.value;
    setAddress(zipcodeValue, 'zipcode');

    const zipcodePattern = /^\d{5,6}$/;

    if (!zipcodePattern.test(zipcodeValue)) {
      setZipcodeError('Enter a valid zipcode.');
    } else {
      setZipcodeError('');
    }
  };

  const changeAddress = (val, name) => {
    if (name == 'country') {
      setAddress(val, 'country');
      getStatesByCountry(val?.iso2 || val?.country);
    }
    if (name == 'state') {
      setAddress(val, 'state');
      getCitiesByStateAndCountry(val?.iso2, address?.country?.iso2 || address.country);
    }
  };

  return (
    <Box>
      <Box sx={style}>
        <Autocomplete
          id="countries"
          sx={{ width: {xs: "100%", md: '30%'} }}
          clearIcon={false}
          value={address?.country}
          isOptionEqualToValue={(option, value) => (option.iso2 === value || option.iso2 === value?.iso2)}
          getOptionLabel={(option) => (option.name ? option.name : option)}
          options={countries}
          clearIcon={false}
          onChange={(_, v) => changeAddress(v, 'country')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                {params.InputProps.endAdornment}
              </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          id="states"
          sx={{ width: {xs: "100%", md: '30%'} }}
          value={address.state}
          clearIcon={false}
          isOptionEqualToValue={(option, value) => (option.iso2 === value || option.iso2 === value?.iso2)}
          getOptionLabel={(option) => (option.name ? option.name : option)}
          options={states}
          clearIcon={false}
          onChange={(_, v) => changeAddress(v, 'state')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                {params.InputProps.endAdornment}
              </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          id="cities"
          sx={{ width: {xs: "100%", md: '30%'} }}
          clearIcon={false}
          value={address.city}
          isOptionEqualToValue={(option, value) => (option.name === value || option.name === value?.name)}
          getOptionLabel={(option) => (option.name ? option.name : option)}
          options={cities}
          clearIcon={false}
          onChange={(_, v) => setAddress(v, 'city')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="City"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                {params.InputProps.endAdornment}
              </>
                ),
              }}
            />
          )}
        />
      </Box>
      {isStreetRequired && (
      <Box sx={style}>
        <TextField
          sx={{ width: {xs: "100%", md: '30%'} }}
          label="Street Address"
          value={address.address}
          placeholder="Enter your street address"
          onChange={(e) => setAddress(e.target.value, 'address')}
        />
        <TextField
          sx={{ width: {xs: "100%", md: '30%'} }}
          label={!zipcodeError ? 'Zip code' : zipcodeError}
          value={address.zipcode}
          placeholder="Enter your zipcode"
          onChange={handleZipcodeChange}
          error={Boolean(zipcodeError)}
          inputProps={{
            pattern: '\\d{5,6}',
            title: 'Enter a valid zipcode',
          }}
        />
      </Box>
      )}
      {isValid?.isNotLocationCountry && isError && <Box paddingTop={"8px"} paddingBottom={"8px"}><Typography color={"red"} variant="defaultFont">Country Required</Typography></Box>}
    </Box>
  );
}

export default AddressSection;
