import { Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  InputAdornment,
  OutlinedInput,
  Checkbox,
  Typography,
} from "@mui/material";
import { resourcesData } from "Pages/Marketplace/marketplace.constant";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce";

const ListOfResources = ({ handleToggleResource, resouceData,selectedResources,setSelectedResources }) => {
  const [searchValue, setSearchValue] = useState('');
  const [resources, setResources] = useState(resouceData);
  const debounceValue = useDebounce(searchValue)

  const handleCheckboxChange = (resource) => {
      const newSelected = selectedResources.includes(resource)
        ? selectedResources.filter((selected) => selected !== resource)
        : [...selectedResources, resource];
      setSelectedResources(newSelected);
  };

  useEffect(() => {
    if (debounceValue) {
      setResources(resouceData.filter(i => i?.firstName?.toLowerCase()?.includes(debounceValue.toLowerCase())))
    } else setResources(resouceData)
  }, [debounceValue,resouceData])
  
  return (
    <Box>
      <OutlinedInput
        fullWidth
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search Consultant Name"
        startAdornment={
          <InputAdornment position="start">
            <Search sx={{ fontSize: 28 }} />
          </InputAdornment>
        }
        sx={{
          pl: 1,
          py: 0.5,
          borderRadius: "8px",
          typography: "body2Medium",
          color: "#6B7280",
          backgroundColor: "#F9FAFB",
        }}
      />
      <Box sx={{ marginTop: '20px' }}>
        {resources.map((resour, id) => {
          return (
            <Box
              key={id}
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Avatar src={resour?.profilePhoto} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                }}
              >
                <Typography variant="body2Semibold">{resour?.firstName}</Typography>
                <Typography variant="captionNormal">
                  {resour?.industry ? resour?.industry : ""}{resour?.industryExperience ? "(" + resour?.industryExperience + ")" : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  border: `1px solid ${selectedResources.includes(resour) ? "#31C48D" : "#E5E7EB"
                    }`,
                  borderRadius: "10px",
                  background: `${selectedResources.includes(resour)
                    ? "rgba(49, 196, 141, 0.2)"
                    : "white"
                    }`,
                  padding: "1px 10px 1px 3px",
                }}
                onClick={() => handleToggleResource(resour.id)}
              >
              {resour?.isSubmit?"Submitted":<>{selectedResources.includes(resour.id) ? (
                <Box sx={{ padding: "4px" }}>
                  <CheckIcon sx={{ fontSize: "20px", color: "#31C48D" }} />
                </Box>
              ) : (
                <Checkbox
                checked={selectedResources.includes(resour.id)}
                onChange={() => handleCheckboxChange(resour.id)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              <Typography variant="body1Normal">
                {selectedResources.includes(resour) ? "Selected" : "Select"}
              </Typography></>}
              </Box>
            </Box>
          );


        })}


      </Box>
    </Box>
  );
};

export default ListOfResources;
