import api from "Utils/api"

export const taskEndpoints = {
    taskList: '/task',
    jobList: '/jobnproject/getjobsList',
}

export const fetchAllTask = async (search = '', selectedJobId = '', selectedResource = '', customQuery = '') => {
    try {
        const resp = await api.get(`${taskEndpoints.taskList}?search=${search}&job_id=${selectedJobId}&resource_id=${selectedResource}&${customQuery}`)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data?.result || [] };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const fetchSingleTask = async (id) => {
    try {
        const resp = await api.get(`${taskEndpoints.taskList}/${id}`)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.result };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const fetchJobs = async (resource_id ='', query) => {
    try {
        const resp = await api.get(`${taskEndpoints.jobList}?${ resource_id ? `resource_id=${resource_id}` : '' }&size=100&${query}`)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.result };
        };
        return { success: false, message: 'No jobs found', data: [] };
    } catch (e) {
        return { success: false, message: 'No jobs found', data: [] };
    }
}

export const addNewTask = async (data) => {
    try {
        const resp = await api.post(`${taskEndpoints.taskList}`, data)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.result, };
        };
        return { success: false, message: 'Error in adding a new task' };
    } catch (e) {
        return { success: false, message: 'Error in adding a new task' };
    }
}

export const updateTask = async (id, data) => {
    try {
        const resp = await api.patch(`${taskEndpoints.taskList}/${id}`, data)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data.data, };
        };
        return { success: false, message: 'Error in updating task' };
    } catch (e) {
        return { success: false, message: 'Error in updating task' };
    }
}