import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { FullWidthWrapper } from '../../Components/Common';
import SettingsTabs from './SettingsTabs/Settings.tabs';

export default function SettingsPage() {
  return (
    <FullWidthWrapper sidebar sx={{ m: 2 }}>
      <Grid container columns={12} spacing={2}>
        <Paper elevation={1} sx={{ p: 3, borderRadius: '8px', width: '100%' }}>
          <Typography variant="subtitle1Bold" color="#182743" component="h3" sx={{ mb: 2.5 }}>Settings</Typography>
          <SettingsTabs />
        </Paper>
      </Grid>
    </FullWidthWrapper>
  );
}
