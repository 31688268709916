import { Avatar, Box, Button, Link, Typography } from '@mui/material';
import { StatusPill } from 'Components/StatusPill';
import { handleCheckBox, handleUnshareClick } from '../api';
import Checkbox from '@mui/material/Checkbox';
import { stringToColor } from 'Utils/avatar';
import { getUserIdFromLocalStorage } from '../../../Utils/helper'

export const resourceColumnDefs = {
  resourceName: {
    accessorFn: (row) => `${row.status} ${row?.firstName} ${row.email}`,
    header: "Resource Name",
    filterSelectOptions: ["Requested", "Invited", "Current"],
    filterFn: "contains",
    filterVariant: "select",
    Cell: ({ row }) => {
      let { firstName } = row.original;
      let { lastName } = row.original;

      if (!row.original.firstName && !row.original.lastName) {
        firstName = row.original.email;
        lastName = "";
      }
      const validateUrl = () => {
        if(row?.original?.username === null) {
          return "URL Not found"
        } if(row?.original?.username === "") {
          return "URL not exist"
        }
      }
      return (
        <Box display="flex" gap="10px" alignItems="center">
          {
            row.original?.consultant?.profilePhoto ?
              <Avatar src={row.original?.consultant?.profilePhoto} />
              : <Box
                bgcolor={stringToColor(`${firstName} ${lastName}`)}
                color={'white'}
                borderRadius="10px"
                width="40px"
                height="40px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {firstName?.slice(0, 1).toUpperCase()}
                {lastName?.slice(0, 1).toUpperCase()}
              </Box>
          }
          <Box sx={{ display: "flex", flexDirection: "column", textDecoration: "none" }}>
            <Link title={validateUrl()} variant="body2Semibold" sx={{textDecoration: "none", color: "#6B7280", "&:hover": {textDecoration: "none"}}} href={`/${row.original?.consultant ? row.original?.consultant?.username : row.original?.username}`} target='_blank' rel='noreferrer'>
              {`${firstName} ${!getUserIdFromLocalStorage() ? `${lastName?.slice(0, 1).toUpperCase()}.` : lastName}`}
            </Link>
            {/* <Typography variant="captionNormal">
              ID: {row.original?.consultant?.username ?? row.original?.username}
            </Typography> */}
            <Typography variant="captionNormal">
              {row.original.status}
            </Typography>
          </Box>
        </Box>
      );
    },
    enableSorting: false,
    size: 0,
  },
  job: {
    accessorKey: "client.company",
    header: "Current Job",
    Cell: ({ cell, row }) => (
      <>
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {row.original?.job?.name}
        </Typography>
        <Typography variant="captionNormal" sx={{ color: "#6B7280" }}>
          {cell.getValue()}
        </Typography>
      </>
    ),
    size: 120,
  },
  vendor: {
    accessorKey: "shared_by",
    header: "Vendor",
    Cell: ({ row }) => {
      if (!row.original.shared_by) return null;
      const { firstName } = row.original.shared_by;
      const { lastName } = row.original.shared_by;
      return (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {`${firstName} ${lastName}`}
        </Typography>
      );
    },
    size: 120,
  },
  to: {
    accessorKey: "shared_to",
    header: "To",
    Cell: ({ row }) => {
      if (!row.original.shared_to) return null;
      const { firstName } = row.original.shared_to;
      const { lastName } = row.original.shared_to;
      return (
        <Typography variant="body2Semibold" sx={{ color: "#182743" }}>
          {`${firstName} ${lastName}`}
        </Typography>
      );
    },
    size: 120,
  },
  types: {
    accessorKey: "role",
    header: "Types",
    Cell: ({ cell }) => (
      <Typography variant="body2Semibold" sx={{ color: "#182743" }}>
        {cell.getValue()}
      </Typography>
    ),
    size: 120,
  },
  action: {
    accessorKey: "action",
    header: "Action",
    Cell: ({ row }) => (
      <Typography
        variant="body2Semibold"
        sx={{ color: "#0B85BA", cursor: "pointer" }}
        onClick={() => handleUnshareClick(row.original)}
      >
        Unshare
      </Typography>
    ),
    size: 120,
  },
  status: {
    accessorKey: "status",
    header: "Status",
    Cell: ({ cell }) => <StatusPill status={cell.getValue()} />,
  },
  statuses: {
    accessorKey: "status",
    header: "Status",
    Cell: ({ cell }) => <StatusPill status={cell.getValue()} />,
  },
  experience: {
    accessorKey: "industry_experience",
    header: "Experience",
    Cell: ({ cell }) => {
      if (!cell.getValue()) return <></>
      return <Typography variant="body2Semibold">{cell.getValue() !== 0 && `${cell.getValue()}`}</Typography>
    },
  },
 
  isPublishMarketplace: {
    accessorKey: "isPublishMarketplace",
    header: "Publish on marketplace",
    enableColumnFilter: false,
    Cell: ({ cell, row }) => {
      return (
        <Checkbox
          disabled={row.original.status !== "Current"}
          defaultChecked={cell.getValue()}
          onClick={(e) =>
            handleCheckBox(
              "isPublishMarketplace",
              row.original.id,
              e.target.checked
            )
          }
        />
      );
    },
    size: 120,
  },
  isPublishPortfolio: {
    accessorKey: "isPublishPortfolio",
    header: "Publish on portfolio",
    enableColumnFilter: false,
    Cell: ({ cell, row }) => {
      return (
        <Checkbox
          disabled={row.original.status !== "Current"}
          defaultChecked={cell.getValue()}
          onClick={(e) =>
            handleCheckBox(
              "isPublishPortfolio",
              row.original.id,
              e.target.checked
            )
          }
        />
      );
    },
    size: 120,
  },
  isPublishSubscription: {
    accessorKey: "isPublishSubscription",
    header: "Subscription",
    enableColumnFilter: false,
    Cell: ({ cell, row }) => {
      return (
        <Checkbox
          disabled={row.original.status !== "Current"}
          defaultChecked={cell.getValue()}
          onClick={(e) =>
            handleCheckBox(
              "isPublishSubscription",
              row.original.id,
              e.target.checked
            )
          }
        />
      );
    },
    size: 120,
  },
  
  requestType: {
    header: "Request Type",
    enableColumnFilter: false,
    Cell: ({cell, row}) => {
      return(
        <Typography variant='defaultFont'>{row?.original?.isDisconnectRequest ? "Disconnection" : "Connection"}</Typography>
      )
    }
  }
};


export const profileTableColumns = [
  resourceColumnDefs.resourceName,
  resourceColumnDefs.experience,
]
