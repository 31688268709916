import { Box, Button, Typography } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal'
import MyEditor from 'Components/TextEditor';
import { countLettersWithoutTags } from 'Pages/Dashboard/components/Jobs/validation';
import { useMemo, useState } from 'react';
import { rejectInviteFromConsultant } from './api';

function ConnectionResourceModal({id, openModal, setOpenModal, setIsReloadAPI, isReloadAPI}) {
  const [commentValue, setCommentValue] = useState("");

  const handleTextFieldChange = (event) => {
    setCommentValue(event);
  }

  const isDescriptionEmpty = useMemo(()=> {
    const count = countLettersWithoutTags(commentValue);
    return count === 0;
  },[commentValue]);

  const handleRejectConnection = async ()=> {
    const removedTagComment = new DOMParser().parseFromString(commentValue, "text/html");
    const data = {
      consultant_id: id,
      reason: removedTagComment.firstChild.textContent 
    }
    await rejectInviteFromConsultant(data);
    setOpenModal(false);
    setIsReloadAPI(!isReloadAPI);
  }
  
  return (
    <MuiModal
      open={openModal}
      onCancel={()=> setOpenModal(false)}
      title='Reject Connection Request'
    >
      <Box paddingBottom={"1rem"}>
        <Typography variant='defaultFont' fontWeight={"bold"}>Do you want to reject connection of this consultant?</Typography>
      </Box>
      <Box>
        <Typography variant='defaultFont'>Comments *</Typography>
        <MyEditor
          height={200}
          html={commentValue}
          updateRaw={handleTextFieldChange}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"} paddingTop={"4rem"}>
        <Button variant='contained' onClick={handleRejectConnection} disabled={isDescriptionEmpty}>Submit</Button>
      </Box>
    </MuiModal>
  )
}

export default ConnectionResourceModal