import { useEffect, useState } from 'react';
import {
  addDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  subDays,
} from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

export default function useCalender() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const getCurrentWeek = () => {
    const currentDate = new Date();
    let startDateOfWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
    let endDateOfWeek = endOfWeek(currentDate, { weekStartsOn: 1 });

    if (startDateOfWeek.getMonth() !== currentDate.getMonth()) {
      startDateOfWeek = startOfMonth(currentDate);
    }

    if (endDateOfWeek.getMonth() !== currentDate.getMonth()) {
      endDateOfWeek = endOfMonth(currentDate);
    }

    setStartDate(startDateOfWeek);
    setEndDate(endDateOfWeek);
  };
  useEffect(() => {
    getCurrentWeek();
  }, []);
  const checkIfStartDateExistInThisMonth = (date, currentMonth) => {
    const startOfMonthDate = startOfMonth(currentMonth);
    if (new Date(date).getMonth() < new Date(startOfMonthDate).getMonth()) {
      return startOfMonthDate;
    }
    return date;
  };
  const checkIfEndDateExistInThisMonth = (date, currentMonth) => {
    const endOfMonthDate = endOfMonth(currentMonth);
    if (new Date(date).getMonth() > new Date(endOfMonthDate).getMonth()) {
      return endOfMonthDate;
    }
    return date;
  };

  const changeWeekHandle = (btnType, needToUpdateState = true) => {
    if (btnType === 'prev') {
      let date = startDate;
      if (new Date(startDate).getDate() === 1) {
        const startOfMonthDate = startOfMonth(date);
        date = subDays(startOfMonthDate, 1);
        const startDateOfWeek = startOfWeek(date, { weekStartsOn: 1 });
        const lastDateOfMonth = endOfMonth(startDateOfWeek);
        if (needToUpdateState) {
          setStartDate(
            checkIfStartDateExistInThisMonth(startDateOfWeek, lastDateOfMonth),
          );
          setEndDate(lastDateOfMonth);
        }

        return { startDate: checkIfStartDateExistInThisMonth(startDateOfWeek, lastDateOfMonth), endDate: lastDateOfMonth };
      }
      const startDateOfWeek = subDays(startDate, 7);
      const lastDateOfWeek = endOfWeek(startDateOfWeek, { weekStartsOn: 1 });
      if (needToUpdateState) {
        setStartDate(
          checkIfStartDateExistInThisMonth(startDateOfWeek, lastDateOfWeek),
        );
        setEndDate(lastDateOfWeek);
      }
      return { startDate: checkIfStartDateExistInThisMonth(startDateOfWeek, lastDateOfWeek), endDate: lastDateOfWeek };
    }
    if (btnType === 'next') {
      let date = endDate;
      if (new Date(endDate).getDate() === endOfMonth(endDate).getDate()) {
        const startOfMonthDate = endOfMonth(date);
        date = addDays(startOfMonthDate, 1);
        const startDateOfWeek = startOfWeek(date, { weekStartsOn: 1 });
        const lastDateOfMonth = endOfWeek(startDateOfWeek, { weekStartsOn: 1 });
        setStartDate(date);
        setEndDate(
          checkIfEndDateExistInThisMonth(lastDateOfMonth, lastDateOfMonth),
        );
        return;
      }
      const startDateOfWeek = addDays(endDate, 1);
      const lastDateOfWeek = endOfWeek(startDateOfWeek, { weekStartsOn: 1 });
      setStartDate(startDateOfWeek);
      setEndDate(
        checkIfEndDateExistInThisMonth(lastDateOfWeek, startDateOfWeek),
      );
    }
  };
  return [changeWeekHandle, startDate, endDate];
}
