import React, { useState, createContext, useContext } from "react";
import api from "../../Utils/api";

const apiPath = {
  getNewNotifications: "/notification/getnewnotifications",
  getAllNotifications: "/notification/getallnotification",
  markAsRead: "/notification/markasread",
};

const nullFn = () => null;
const NotificationContext = createContext({
  loading: false,
  notifications: [],
  setNotifications: nullFn,
  getNewNotifications: nullFn,
  getAllNotifications: nullFn,
  markAsRead: nullFn,
});

export const useCommonNotificationProvider = () =>
  useContext(NotificationContext);
const NotificationProvider = ({ children, userId }) => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);

  const getNewNotifications = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `${apiPath.getNewNotifications}?userId=${userId}`
      );
      if (res.status === 200 && res.data?.success) {
        setNotifications(res.data.notification);
        return res.data.notification;
      } else {
        throw new Error("Error occurred while fetching notifications");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const getAllNotifications = async (page, size) => {
    setLoading(true);
    try {
      const res = await api.get(
        `${apiPath.getAllNotifications}?userId=${userId}&page=${page}&size=${size}`
      );
      if (res.status === 200 && res.data?.success) {
        setAllNotifications(res.data.result);
        return res.data.result;
      } else {
        throw new Error("Error occurred while fetching notifications");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const markAsRead = async (notification_ids) => {
    try {
      const res = await api.put(apiPath.markAsRead, {
        userId,
        notification_ids: notification_ids,
      });
      if (res.status === 200 && res.data?.success) {
        return true;
      } else {
        throw new Error("Error occurred while updating notification");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const value = {
    loading,
    notifications,
    allNotifications,
    getNewNotifications,
    getAllNotifications,
    markAsRead,
    setNotifications
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
