import React, { useState, createContext, useContext, useEffect } from "react";
import api, { refreshAccessToken } from "../../Utils/api";
import { useSelector } from "react-redux";
import { getPermissions } from "Utils/helper";

const apiPath = {
  fetchProjects: "/project/get-projects",
  addProject: "/project/add-project",
  fetchProjectsWithProfiles: "/jobnproject/getallprojectswithprofile",
  getTimeTracked: "/timesheet/gettimetracked",
  getProjectVendors: "/project/get-vendors",
  fetchClientDetails: "/project/get-client-details",
};

const nullFn = () => null;
const ProjectContext = createContext({
  loading: false,
  projects: [],
  fetchProjects: nullFn,
  addProject: nullFn,
  projectsWithProfiles: [],
  fetchAllProjectsWithProfiles: nullFn,
  fetchJobsWithTimeTracked: nullFn,
  hasAccessToDefaultProject: true
});

export const useProjectProvider = () => useContext(ProjectContext);
const ProjectProvider = ({ children, userId }) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [hasAccessToDefaultProject, setHasAccessToDefaultProject] = useState(true);
  const [projectsWithProfiles, setProjectsWithProfiles] = useState([]);
  const permissions = getPermissions();
  // useEffect(() => {
  //   fetchProjects();
  // }, []);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchProjects, {
        params: { userId },
      });

      if (res.status === 200 && res.data?.success) {
        setProjects(res.data.result);
        if(res.data?.hasAccessToDefaultProject !== undefined && res.data?.hasAccessToDefaultProject === false){
          setHasAccessToDefaultProject(false);
        }
      } else {
        throw new Error("Error occurred while fetching projects");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllProjectsWithProfiles = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchProjectsWithProfiles, {
        params: { userId, userType: "VENDOR" },
      });

      if (res.status === 200 && res.data?.success) {
        const x = res.data.result.reduce((acc, cur) => {
          if (cur.project_id) {
            if (acc[cur.project_id]) {
              if (cur.consultant !== null)
                acc[cur.project_id].consultants.add(cur.consultant);

              if (cur.vendor !== null)
                acc[cur.project_id].vendors.add(cur.vendor);

              // Add job
              if (cur.job)
                acc[cur.project_id].jobs[cur.job.id] = {
                  title: cur.job.name,
                  description: cur.job.description,
                  consultant: cur.consultant,
                  vendor: cur.vendor,
                  status: cur.job.status,
                };
            } else {
              // If project is not present in the accumulator
              acc[cur.project_id] = {
                ...cur.project,
                consultants:
                  cur.consultant !== null
                    ? new Set([cur.job_details])
                    : new Set(),
                vendors:
                  cur.vendor !== null ? new Set([cur.vendor]) : new Set(),
              };

              if (cur.job_details) {
                // acc[cur.project_id].jobs = {
                  // [cur.job.id]: {
                  //   title: cur.job.name,
                  //   description: cur.job.description,
                  //   consultant: cur.consultant,
                  //   vendor: cur.vendor,
                  //   status: cur.job.status,
                  // },
                // };
                acc[cur.project_id].jobs = cur.job_details;
              } else {
                acc[cur.project_id].jobs = {};
              }
            }
          }

          return acc;
        }, {});

        const result = Object.values(x);
        result.forEach((row) => {
          row.consultants = Array.from(row.consultants);
          row.vendors = Array.from(row.vendors);
        });

        setProjectsWithProfiles(result);
        return result
      } else {
        throw new Error("Error occurred while fetching projects");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const addProject = async (project) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.addProject, project);
      if (res.status === 200 && res.data?.success) {
        if(!permissions?.includes(`admin-client`)){
          await refreshAccessToken();
        }
        setLoading(false);
        setProjects([...projects, res.data.result]);
        return { id: res.data.result.id, success: true };
      } else {
        throw new Error("Error occurred while adding project");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      return { success: false };
    }
  };

  const fetchJobsWithTimeTracked = async (projectId, profileData) => {
    setLoading(true);
    try {
      const project = profileData.find(({ id }) => id === projectId);
      const jobs = project?.jobs;
      const res = await api.post(apiPath.getTimeTracked, {
        jobs: Object.keys(jobs),
      });

      if (res.status === 200 && res.data?.success) {
        return Object.keys(jobs).map((job_id) => ({
          id: job_id,
          ...jobs[job_id],
          timeTracked: res.data.result[job_id],
          createdAt: project.createdAt,
        }));
      } else {
        throw new Error("Error occurred while fetching projects");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      return [];
    }
  };

  const value = {
    loading,
    projects,
    fetchProjects,
    addProject,
    projectsWithProfiles,
    fetchAllProjectsWithProfiles,
    fetchJobsWithTimeTracked,
    hasAccessToDefaultProject
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

export default ProjectProvider;
