import React from "react";
const Button = ({ title, icon = <></>, parentClassName = '', className = '', disabled = false, type = 'button', ...rest }) => {
    return (
        <div className={`flex items-center w-auto ${parentClassName}`}>
            <button
                type={type}
                disabled={disabled}
                className={`hover:shadow-lg hover:!bg-[#3985B6] flex justify-center flex-row text-white bg-[#3985B6] hover:bg-[#3985B6] focus:bg-[#3985B6] items-center gap-1 border whitespace-nowrap rounded-lg text-sm h-[42px] px-2 py-2 text-center ${className} ${disabled ? 'border-gray-300 bg-gray-300 hover:!bg-gray-300 focus:!bg-gray-300 !cursor-not-allowed hover:shadow-[unset]' : 'border-[#3985B6] cursor-pointer'}`}
                {...rest}>
                {icon}
                {title}
            </button>
        </div>
    );
};

export default Button;
