import { applicantStatus, taskStatueses } from "./constant.task";

export const taskList = [
    {
      id: "open",
      taskName: taskStatueses.open,
      list: []
    },
    {
      id: "todo",
      taskName: taskStatueses.toDo,
      list: []
    },
    {
      id: "in_progress",
      taskName: taskStatueses.inProgress,
      list: []
    },
    {
      id: "in_review",
      taskName: taskStatueses.completed,
      list: []
    },
  ];

  export const applicantList = (job_id) => [
    {
      id: "hold",
      taskName: applicantStatus.application,
      list: []
    },
    {
      id: "reject",
      taskName: applicantStatus.waitlisted,
      list: []
    },
    {
      id: "select",
      taskName: applicantStatus.shortlisted,
      list: []
    },
    {
      ...(
        job_id
          ? {
            id: "submit",
            taskName: applicantStatus.submit,
            list: []
          }
          : {
            id: "hired",
            taskName: applicantStatus.hired,
            list: []
          }
      )
    }
  ];

export const userList = [
  {
    assignee: "Elizabeth",
    img_url: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnN8ZW58MHx8MHx8&auto=format&fit=crop&w=500"
  }, {
    assignee: "Mark Jukarbarg",
    img_url: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHVzZXJzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500",
  }, {
    img_url: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHVzZXJzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500",
    assignee: "Sundar Pichai",
  }, {
    assignee: "John Whey",
    img_url: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  }, {
    assignee: "John Whey",
    img_url: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  }
]
  

export const projectList = [
  {
    name: "Eargo ( current )",
    img: "https://eargodev.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/12213?size=medium"
  },
  {
    name: "GetStatus",
    img: "https://eargodev.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/10503?size=medium"
  },
  {
    name: "CodeUniv",
    img: "https://eargodev.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/12209?size=medium"
  }
]

export const dificultyLevel = [
  {
    name: "Medium",
    img: "https://eargodev.atlassian.net/images/icons/priorities/medium.svg"
  },
  {
    name: "Highest",
    img: "https://eargodev.atlassian.net/images/icons/priorities/major.svg"
  },
  {
    name: "Low",
    img: "https://eargodev.atlassian.net/images/icons/priorities/minor.svg"
  },
  {
    name: "Lowest",
    img: "https://eargodev.atlassian.net/images/icons/priorities/trivial.svg"
  }
]
