/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { JobBadge } from 'Components/JobBadge';
import { statuses } from 'Utils/constants';
import React, { useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';

export function BackButton({ onClick, label }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginBottom: '40px',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <ArrowBackIcon
        sx={{
          background: '#F3F4F6',
          width: '20px',
          height: '20px',
          borderRadius: '8px',
        }}
      />
      <Typography variant="componentsButtonSmall" sx={{ color: '#182743' }}>
        {label || 'Back'}
      </Typography>
    </Box>
  );
}

function JobCard() {
  const location = useLocation();
  const navigate = useNavigate();
  const response = useLoaderData();
  // const userRole = useSelector(selectUserRole);
  // const queryParams = new URLSearchParams(window.location.search);
  // const permissions = getPermissions();
  const jobDetails = response?.data?.result || {};
  const [selectedTab, setSelectedTab] = useState(
    location.pathname.includes('timesheets') 
      ? 'timesheets' 
      : location.pathname.includes('tasklist') 
        ? 'tasklist'
        : location.pathname.includes('closedtask')
        ? 'closedtask'
        : 'details'
    );

  const handleTabChange = (_, value) => {
    setSelectedTab(value);
    navigate(`/dashboard/jobs/${jobDetails?.id}/${value}`, { replace: true });
  };

  // const goBackhandler = () => {
  //   if (queryParams.get("type") === "isMarketplace") navigate("/dashboard/marketplace?tab=My Jobs");
  //   else if (queryParams.get("type") === "dashboard") navigate("/dashboard");
  //   else if (queryParams.get("projectid")) navigate(`/dashboard/project/${queryParams.get("projectid")}`);
  //   else navigate("/dashboard/jobs");
  // }

  return (
    <Grid item xs={16} sx={{wordWrap: "break-word"}}>
      <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
        <JobBadge
          jobDetails={jobDetails}
          goBack={() => navigate(-1)}
        />
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{ mt: 3, mb: 2 }}
        >
          <Tab value="details" label="Details" />
          {/* {[statuses.job.open, statuses.job.hiringHold].includes(
            jobDetails?.status
          ) && <Tab value="applicants" label="Applicants" />} */}
          {[statuses.job.ongoing].includes(jobDetails?.status) && (
            <Tab value="timesheets" label="Timesheets" />
          )}
          {([statuses.job.ongoing, 'CLOSED'].includes(jobDetails?.status)) && (
            <Tab value="tasklist" label="Task List" />
          )}
          {[statuses.job.ongoing, 'CLOSED'].includes(jobDetails?.status) && (
            <Tab value="closedtask" label="Closed Task" />
          )}
        </Tabs>
        <Outlet />
      </Paper>
    </Grid>
  );
}

export default JobCard;
