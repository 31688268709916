import { Avatar, Box, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const ConfirmedScreen = ({ selectedResources, handleToggleResource }) => {
  return (
    <Box sx={{ border: "1px solid #E5E7EB", borderRadius: "8px", padding: "20px" }}>
      <Typography variant="body1Bold">
        Review resources before submitting
      </Typography>
      <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        {selectedResources.map((resource, id) => (
          <Box
            key={id}
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              marginBottom: "20px",
              width: "30%",
            }}
          >
            <Avatar src={resource?.profilePhoto} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <Typography variant="body2Semibold">{resource.firstName}</Typography>
              <Typography variant="captionNormal">
                {resource?.industry ? resource?.industry : ""}{resource?.industryExperience ? `(${resource?.industryExperience})` : ""}
              </Typography>
            </Box>
            <Box sx={{ cursor: "pointer" }} onClick={() => handleToggleResource(resource)}>
              <CancelIcon />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ConfirmedScreen;
