import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  NativeSelect,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { BackButton } from 'Components/JobCard/Job.card';
import ContextProviders from 'Providers/ContextProviders';
import { selectUserDetails } from 'Redux/Feature/user';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import OTPInput from 'react-otp-input';
import { useSelector } from 'react-redux';
import {
  Link, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import Swal from 'sweetalert2';
import { FullWidthWrapper } from '../../Components/Common';
import { useCountryStateCityProvider } from '../../Providers/Common/CountyStateCity';
import { isValidEmail, validateEmail, validatePassword } from '../../Utils/validation';
import {
  CheckEmailExist,
  Register,
  ResendOTP,
  SendOTP,
} from './Helper';
import ProlegionAccount from './ProlegionAccount';
import Button from 'Components/Common/semantic_tags/Button';
import ReactDropzone from 'Components/Common/ReactDropzone';
import { DotLoaders, DragDropFileComponent } from 'Components';

function Signup ({ userTypes, currentScreens = 'select', isPublic, loginHandler, details }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const userDetails = useSelector(selectUserDetails);
  const [userTypeSelect, setUserTypeSelect] = useState(userTypes);
  const [userType, setUserType] = useState(null);
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { email: details?.email || '', companyName: details?.companyName || '' }
  });
  const [loading, setLoading] = useState(false);
  const [emailUsed, setEmailUsed] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [files, setFiles] = useState(null);
  const [isResume, setIsResume] = useState(true);
  const [data, setData] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpToken, setOtpToken] = useState();
  const [country, setCountry] = useState('US');
  const [state, setState] = useState('AL');
  const [checkmarks, setCheckmarks] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [currentScreen, setCurrentScreen] = useState(currentScreens);

  const navigate = useNavigate();
  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length > 0) {
      const query = new URLSearchParams(window.location.search)
      if (query.get('redirecTo')) navigate(query.get('redirecTo'))
      else navigate('/dashboard');
    }
  }, []);

  useEffect(() => {
    const usertype = searchParams.get('user');
    usertype && setUserTypeSelect(usertype);
    usertype && setUserType(usertype);
  }, [searchParams.get('user')]);

  const registerUser = async (otpDetails) => {
    if (data !== null) {
      setLoading(true);
      if(userType === "Consultant") {
        if (data.has("OTP")) {
          data.delete("OTP");
        }
        if (data.has("OTPToken")) {
          data.delete("OTPToken");
        }
        data.append("OTP", otpDetails.OTP)
        data.append("OTPToken", otpDetails.OTPToken)
      }
      const sendSignupBody = userType === "Consultant" ? data : {...data, ...otpDetails};
      
      Register(userType, sendSignupBody, isPublic)
        .then(async (res) => {
          setOtp('');
          if (res == null) {
            setLoading(false);
            return;
          }
          if (!res.data.success) {
            setLoading(false);
            Swal.fire(
              'Already Registered!',
              'This email address is associated with an existing account. If you are unable to login, please wait for your account to be approved.',
              'success',
            );
          } else if (res.data.success) {
            setLoading(false);
            setIsSuccess(true);
            await handleSignUp();
          } else {
            setLoading(false);
            Swal.fire('Not Registered !', res.response.data.msg, 'error');
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };
  const {
    countries,
    states,
    cities,
    getCountries,
    getStatesByCountry,
    getCitiesByStateAndCountry,
  } = useCountryStateCityProvider();

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    getStatesByCountry(country);
  }, [country]);
  useEffect(() => {
    getCitiesByStateAndCountry(state, country);
  }, [state]);

  const onSubmit = (formData) => {
    files?.name ? setIsResume(true) : setIsResume(false);
    if(userType === "Consultant" && !!files) {
      const formFile = new FormData();
      formFile.append("firstName", formData?.firstName);
      formFile.append("lastName", formData?.lastName);
      formFile.append("email", formData?.email);
      formFile.append("password", formData?.password);
      formFile.append("file", files);
      if (!emailUsed && !!files) {
        showModal(formData);
        setData(formFile);
      }  
    } else {
      if (!emailUsed && userType !== "Consultant") {
        showModal(formData);
        setData(formData);
      }
    }
  };

  const location = useLocation();

  async function handleSignUp() {
    const queryParams = new URLSearchParams(location.search);
    const accountsCounter = queryParams.get('accountsCounter');
    const couponCode = queryParams.get('couponCode');
    const timePeriod = queryParams.get('timePeriod');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/payments/link`,
        {
          priceId:
            timePeriod === 'monthly'
              ? 'price_1O4KgQAzOgO1UoUn9L71Tk33'
              : 'price_1O4KgQAzOgO1UoUnGwIdzcV0',
          quantity: accountsCounter,
          email: data?.email,
          coupon: couponCode,
        },
      );

      setPaymentUrl(response.data.data);
      response.data?.data && window.open(response.data.data, '_blank');
    } catch (error) {
      console.error('API call failed', error);
    }
  }

  const showModal = (formData) => {
    SendOTP({ email: formData.email, name: formData.firstName }, isPublic)
      .then((res) => {
        setOtpToken(res.data.token);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const resendOTP = () => {
    const email = userType === "Consultant" ? data?.get("email") : data.email; 
    if (otpToken === '') {
      SendOTP({ email }, isPublic)
        .then((res) => {
          setOtpToken(res.data.token);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      ResendOTP({ email, OTPToken: otpToken }, isPublic)
        .then((res) => {
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleConfirmOTP = () => {
    registerUser({ OTP: otp, OTPToken: otpToken });
  };

  const checkIfAccountExists = (event) => {
    const email = event.target.value;
    if (!email.length) {
      return;
    }
    if (process.env.REACT_APP_BASE_URL === 'https://dev.api.prolegion.com') {
      if (
        email.split('@')[1] === 'yopmail.com'
        || email.split('@')[1] === 'test.com'
      ) {
        return setEmailMessage('Please enter a correct email')
      }
    }
    if (!isValidEmail(email)) {
      return setEmailMessage('Please enter a correct email')
    } else {
      setEmailMessage('')
    }

    CheckEmailExist({ email }).then((res) => {
      setEmailUsed(res?.response?.status);
    });
  };

  useEffect(() => {
    setUserType(userTypeSelect);
  }, [userTypeSelect]);

  return (
    <Box sx={{ ...(!isPublic && { minHeight: {xs: "unset", md: '100vh'} }), p: {xs: "3rem 0", md: "0"}, height: isPublic ? '100%' : 'unset', backgroundColor:  isPublic ? '' : '#cfd8dc60' }}>
      {currentScreen == 'select' && <ProlegionAccount setCurrentScreen={setCurrentScreen} userTypeSelect={userTypeSelect} setUserTypeSelect={setUserTypeSelect} />}
      {currentScreen !== 'select' && (
        <FullWidthWrapper isPublic>
          <Grid container sx={{ py: { xs: 2, md: isPublic ? 2 : 6 }, display: 'flex', justifyContent: 'center' }}>
            <Grid item>
              <Card
                // elevation={3}
                elevation={isPublic ? 0 : 3}
                sx={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', p: {xs: "20px", md: 4},
                  ...(isPublic && { paddingTop: '0px' })
                }}
                className='min-[300px]:mx-[1rem]'
              >
                <Stack direction="column" alignItems="flex-start">
                  {!!userType && !otpToken && (
                  <>
                    <Typography variant="h5Bold">
                      ProLegion for
                      {' '}
                      {userType === 'Client' ? 'Enterprise' : userType}
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body2Medium" sx={{ display: 'flex', gap: '4px' }}>
                        Already have an account?
                        {' '}
                        {
                          !isPublic 
                            ? <Link to="/signin" style={{ color: '#0B85BA' }}>
                              Login Here
                            </Link>
                            : <Box onClick={loginHandler} style={{ color: '#0B85BA', cursor: 'pointer' }}>
                              Login Here
                            </Box>
                        }
                      </Typography>
                    </Box>
                    {!isPublic && <BackButton onClick={() => setCurrentScreen('select')} />}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Stack direction="column" gap={2}>
                        {userType.toLowerCase() === 'client' ? (
                          <>
                            <Box sx={{ display: 'flex', gap: '10px', flexDirection: {xs: "column", md: "row"} }}>
                              <TextField
                                id="outlined-basic"
                                {...register('firstName', { required: true })}
                                label="First Name"
                                variant="outlined"
                                helperText={errors?.firstName?.message}
                                required
                                fullWidth
                              />
                              {!!errors.firstName && (
                              <h4 className="text-red-600 text-xs pt-2 pb-2">
                                *First Name is required
                              </h4>
                              )}
                              <TextField
                                id="outlined-basic"
                                {...register('lastName', { required: true })}
                                label="Last Name"
                                variant="outlined"
                                required
                                fullWidth
                              />
                              {!!errors.lastName && (
                              <h4 className="text-red-600 text-xs pt-2 pb-2">
                                *Last Name is required
                              </h4>
                              )}
                            </Box>
                            <TextField
                              id="outlined-basic"
                              {...register('email', { validate: validateEmail })}
                              label="Email"
                              disabled={isPublic && !!details?.email}
                              error={!!errors?.email}
                              helperText={errors?.email?.message}
                              onBlur={checkIfAccountExists}
                              required
                              variant="outlined"
                              fullWidth
                            />
                            {(emailMessage || !!emailUsed) && (
                            <h4 className="text-red-600 text-xs pt-2 pb-4">
                              {emailMessage ? emailMessage : 'Email is already used'}
                            </h4>
                            )}
                            <TextField
                              type="password"
                              id="outlined-basic"
                              {...register('password', { validate: validatePassword })}
                              error={!!errors?.password}
                              helperText={errors?.password?.message}
                              label="Password*"
                              variant="outlined"
                              fullWidth
                            />
                            <TextField
                              id="outlined-basic"
                              disabled={isPublic && details?.companyName}
                              {...register('companyName', { required: true })}
                              label="Company Name*"
                              variant="outlined"
                              fullWidth
                            />
                            {!!errors.companyName && (
                            <h4 className="text-red-600 text-xs pt-2 pb-2">
                              *Company Name is required
                            </h4>
                            )}
                            <TextField
                              id="outlined-basic"
                              {...register('phone', { required: true })}
                              label="Phone"
                              variant="outlined"
                              inputProps={{ maxLength: 12 }}
                              required
                              fullWidth
                            />
                            {!!errors.phone && (
                            <h4 className="text-red-600 text-xs pt-2 pb-2">
                              *Phone is required
                            </h4>
                            )}
                          </>
                        ) : null}
                        {userType === 'Vendor' ? (
                          <>
                            <Box sx={{ display: 'flex', gap: '10px', flexDirection: {xs: "column", md: "row"} }}>
                              <TextField
                                id="outlined-basic"
                                {...register('firstName', { required: true })}
                                label="First Name"
                                variant="outlined"
                                required
                                fullWidth
                              />
                              {!!errors.firstName && (
                              <h4 className="text-red-600 text-xs pt-2 pb-2">
                                *First Name is required
                              </h4>
                              )}
                              <TextField
                                id="outlined-basic"
                                {...register('lastName', { required: true })}
                                label="Last Name"
                                variant="outlined"
                                required
                                fullWidth
                              />
                              {!!errors.lastName && (
                              <h4 className="text-red-600 text-xs pt-2 pb-2">
                                *Last Name is required
                              </h4>
                              )}
                            </Box>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              required
                              {...register(
                                'email',
                                { required: true },
                                { validate: validateEmail },
                              )}
                              onBlur={checkIfAccountExists}
                              variant="outlined"
                              disabled={isPublic && !!details?.email}
                              fullWidth
                            />
                            {(emailMessage || !!emailUsed) && (
                            <h4 className="text-red-600 text-xs pt-2 pb-4">
                              {emailMessage ? emailMessage : 'Email is already used'}
                            </h4>
                            )}
                            {!!errors.email && (
                            <h4 className="text-red-600 text-xs pt-2 pb-2">
                              *Email is required
                            </h4>
                            )}
                            <TextField
                              type="password"
                              className="pt-2"
                              id="outlined-basic"
                              {...register('password', { validate: validatePassword })}
                              error={!!errors?.password}
                              helperText={errors?.password?.message}
                              label="Password*"
                              variant="outlined"
                              fullWidth
                            />
                            <TextField
                              className="pt-2"
                              id="outlined-basic"
                              {...register('companyName', { required: true })}
                              label="Company Name*"
                              disabled={isPublic && details?.companyName}
                              variant="outlined"
                              fullWidth
                            />
                            {!!errors.companyName && (
                            <h4 className="text-red-600 text-xs pt-2 pb-2">
                              *Company Name is required
                            </h4>
                            )}
                            <TextField
                              className="pt-2"
                              id="outlined-basic"
                              {...register('phone', { required: true })}
                              label="Phone No.*"
                              inputProps={{ maxLength: 12 }}
                              variant="outlined"
                              fullWidth
                            />
                            {!!errors.phone && (
                            <h4 className="text-red-600 text-xs pt-2 pb-2">
                              *Phone is required
                            </h4>
                            )}
                            <TextField
                              className="pt-2"
                              id="outlined-basic"
                              {...register('website')}
                              label="Website"
                              variant="outlined"
                              fullWidth
                            />
                            <TextField
                              className="pt-2"
                              id="outlined-basic"
                              {...register('address')}
                              label="Address"
                              variant="outlined"
                              fullWidth
                            />
                            <NativeSelect
                              id="country-select"
                              placeholder="Country"
                              variant="outlined"
                              defaultValue="US"
                              className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                              fullWidth
                              {...register('country', {
                                onChange: (e) => {
                                  setCountry(e.target.value);
                                  setValue('country', e.target.value);
                                },
                              })}
                            >
                              {countries.length > 0 && countries.map((country) => (
                                <option key={country.iso2} value={country.iso2}>
                                  {country.name}
                                </option>
                              ))}
                            </NativeSelect>
                            <NativeSelect
                              showSearch
                              labelId="city-label"
                              id="city-select"
                              placeholder="City"
                              variant="outlined"
                              defaultValue="TX"
                              className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                              fullWidth
                              {...register('state', {
                                onChange: (e) => {
                                  setState(e.target.value);
                                  setValue('state', e.target.value);
                                },
                              })}
                            >
                              {country && states.length > 0
                                      && states?.map((state) => (
                                        <option key={state.iso2} value={state.iso2}>
                                          {state.name}
                                        </option>
                                      ))}
                            </NativeSelect>

                            <NativeSelect
                              className="border-[1.5px] p-2 border-gray-300 rounded-[3px]"
                              labelId="city-label"
                              id="city-select"
                              placeholder="City"
                              variant="outlined"
                              fullWidth
                              {...register('city')}
                            >
                              {state
                                    && cities.map((city, idx) => (
                                      <option key={idx} value={city.name}>
                                        {city.name}
                                      </option>
                                    ))}
                            </NativeSelect>
                            <TextField
                              id="outlined-basic"
                              {...register('Zip')}
                              label="Zip"
                              inputProps={{ maxLength: 12 }}
                              variant="outlined"
                              fullWidth
                            />
                          </>
                        ) : null}
                        {userType === 'Consultant' ? (
                          <>
                            <Box sx={{ display: 'flex', gap: '10px', flexDirection: {xs: "column", md: "row"} }}>
                              <TextField
                                id="outlined-basic"
                                {...register('firstName', { required: true })}
                                label="First Name*"
                                variant="outlined"
                                fullWidth
                              />
                              {!!errors.firstName && (
                              <h4 className="text-red-600 text-xs pt-2 pb-2">
                                *First Name is required
                              </h4>
                              )}
                              <TextField
                                id="outlined-basic"
                                {...register('lastName', { required: false })}
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                              />
                              {/* {!!errors.lastName && (
                              <h4 className="text-red-600 text-xs pt-2 pb-2">
                                *Last Name is required
                              </h4>
                              )} */}
                            </Box>
                            <TextField
                              id="outlined-basic"
                              {...register(
                                'email',
                                { required: true },
                                { validate: validateEmail },
                              )}
                              label="Email"
                              required
                              onBlur={checkIfAccountExists}
                              variant="outlined"
                              fullWidth
                            />
                            {(emailMessage || !!emailUsed) && (
                            <h4 className="text-red-600 text-xs pt-2 pb-4">
                              {emailMessage ? emailMessage : 'Email is already used'}
                            </h4>
                            )}
                            {!!errors.email && (
                            <h4 className="text-red-600 text-xs pt-2 pb-2">
                              *Email is required
                            </h4>
                            )}
                            <TextField
                              type="password"
                              id="outlined-basic"
                              {...register('password', { validate: validatePassword })}
                              error={!!errors?.password}
                              helperText={errors?.password?.message}
                              label="Password*"
                              variant="outlined"
                              fullWidth
                            />
                            <DragDropFileComponent selectedfile={files} setSelectedfile={setFiles} title={"Upload your Resume *"} />
                            {!isResume && <h4 className="text-red-600 text-xs pt-2 pb-2">
                              *Resume is required
                            </h4>}
                          </>
                        ) : null}
                      </Stack>
                      <div className="py-2">
                        <div className="flex flex-row items-center -space-x-3">
                          <FormControlLabel
                            control={(
                              <Checkbox
                                color="primary"
                                defaultChecked={false}
                                value={checkmarks}
                                checked={checkmarks}
                                onChange={() => setCheckmarks(!checkmarks)}
                              />
                                )}
                          />
                          <Typography className='min-[300px]:py-4 md:py-0'>
                            I have read and understand ProLegion's
                            {' '}
                            <a onClick={() => window.open('/privacypolicy', '__blank')} style={{ color: '#0B85BA', cursor: 'pointer' }}>
                              Privacy Policy
                            </a>
                            {' '}
                            and
                            {' '}
                            <a onClick={() => window.open('/termsandcondition', '__blank')} style={{ color: '#0B85BA', cursor: 'pointer' }}>
                              Terms & Conditions
                            </a>
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Button
                          type="submit"
                          parentClassName='!w-full'
                          className='w-full'
                          disabled={emailMessage || !checkmarks}
                          // onClick={handleSignUp}
                          title='Sign Up'
                        />
                      </div>
                    </form>
                  </>
                  )}
                  {!!otpToken && !isSuccess && (
                  <>
                    <Typography variant="h5Bold" sx={{ color: '#111928' }}>Verify Email</Typography>
                    <Typography variant="body2Medium">Please enter the code sent to the email provided.</Typography>
                    <Stack direction="column" alignItems="center" gap={1} sx={{ my: 4, width: '100%' }}>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputStyle={{
                          width: '40px',
                          padding: theme.spacing(1),
                          borderWidth: 1,
                          borderColor: theme.palette.primary.main,
                          outline: 'none',
                          borderRadius: 5,
                          ...theme.typography.body2,
                        }}
                        containerStyle={{
                          flex: 1,
                          justifyContent: 'center',
                          width: '100%',
                          gap: theme.spacing(1),
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                      <Button title='Resend Code' onClick={resendOTP} />
                    </Stack>
                    <Button parentClassName='!w-full' className='w-full' onClick={handleConfirmOTP} disabled={loading} title={ loading ? <DotLoaders /> : 'Verify' } />
                  </>
                  )}
                  {isSuccess && (
                  <>
                    {userType === 'Consultant' ? (
                      <>
                        <Typography variant="h2" sx={{ fontSize: 36 }}>You're all set!</Typography>
                        <Typography variant="body1">You are now registered and can start using ProLegion.</Typography>
                        <Button className='' onClick={() => navigate('/signin')} title='Proceed To Login' />
                      </>
                    ) : (
                      <>
                        <Typography variant="h2" sx={{ fontSize: 36, mb: 3 }}>Almost there!</Typography>
                        <Typography variant="body1">We have received your registration request. Someone from our staff will be in contact shortly.</Typography>
                      </>
                    )}
                  </>
                  )}
                </Stack>
              </Card>

            </Grid>
          </Grid>
        </FullWidthWrapper>
      )}
    </Box>
  );
}

function SignupPage({ userTypes, currentScreens = 'select', isPublic = false, loginHandler, submitHandler, details }) {
  const url = new URLSearchParams(window.location.search)
  return (
    <ContextProviders>
      <Signup userTypes={url.get('marketPlacetype') || userTypes} currentScreens={url.get('marketPlacetype') ? 'signup' : currentScreens} isPublic={isPublic} loginHandler={loginHandler} submitHandler={submitHandler} details={details} />
    </ContextProviders>
  );
}

export default SignupPage;
