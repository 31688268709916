export const locationOptions = [
  {
    value: "REMOTE",
    label: "REMOTE",
  },
  {
    value: "ONSITE",
    label: "ONSITE",
  },
  {
    value: "HYBRID",
    label: "HYBRID",
  },
];

export const rateTypeOptions = [
  {
    value: "HOURLY",
    label: "HOURLY",
  },
  {
    value: "MONTHLY",
    label: "MONTHLY",
  },
];
export const currencyTypeOptions = [
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "USD",
    label: "USD",
  },
];
