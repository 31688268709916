import { Button } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal'
import { Input } from 'antd';
import React from 'react'
import Swal from 'sweetalert2';

function VerifyPinModal({ open, setOpen, props }) {
  const { pin, setPin, verifyPin, setPinRequired, token, navigate } = props;
  return (
    <MuiModal
      open={open}
      onCancel={() => (setOpen(false), navigate('/'))}
    >
      <div className="px-8 py-4 flex flex-col items-center space-y-8 justify-center bg-white rounded-xl">
        <span className="flex text-justify">
          Enter the secured PIN to access the timesheet
        </span>
        <Input
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          onKeyDown={(e)=>{
            if(e.key === "Enter") {
              verifyPin(pin, token).then((res) => {
                if (res !== null) {
                  if (res === true) setPinRequired(false);
                  else Swal.fire('Error', 'Invalid PIN', 'error');
                } else Swal.fire('Error', 'Something went wrong', 'error');
              })
            }
          }}
          className="min-w-[240px] w-2/3 md:w-1/2 xl:w-2/5 2xl:w-1/3 mx-auto"
          placeholder="Enter Pin"
          maxLength="4"
        />
        <div className="min-w-[240px] w-2/3 md:w-1/2 xl:w-2/5 2xl:w-1/3 px-16">
          <Button
            variant='contained'
            className="w-full text-white rounded-sm"
            // style={{ backgroundColor: '#2EBAA3' }}
            type="primary"
            onClick={(e) => verifyPin(pin, token).then((res) => {
              if (res !== null) {
                if (res === true) setPinRequired(false);
                else Swal.fire('Error', 'Invalid PIN', 'error');
              } else Swal.fire('Error', 'Something went wrong', 'error');
            })}
          >
            Verify
          </Button>
        </div>
      </div>
    </MuiModal>
  )
}

export default VerifyPinModal;