import React from 'react';
import { Link } from 'react-router-dom';
import DocumentWrapper from 'Pages/Document';
import RequireAuth from './RequireAuth';

export const documentRoutes = [
  {
    path: 'document',
    index: true,
    handle: {
      crumb: () => <Link to='/dashboard/document'>Document</Link>,
    },
    element: (
      <RequireAuth>
        <DocumentWrapper />
      </RequireAuth>
    ),
  },
];
