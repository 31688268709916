import axios from "axios";
import { baseURL } from "../../../../Utils/api";
export const getFAQs = async (section) => {
  return axios
    .get(`${baseURL}/faq/getfaqsbysection?section=${section}`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export const getFAQ = async () => {
  return axios
    .get(`${baseURL}/faq/getfaqs?page=0&size=10`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
};
