import React from 'react';
import {
  Tabs,
  Tab,
} from '@mui/material';

export function TabSection({
  selectedTab, setSelectedTab, tabs,
}) {
  return (
    <Tabs
      value={selectedTab}
      onChange={(e, value) => setSelectedTab(value)}
      // variant="stand"
      // centered
      sx={{
        border: '1px solid #F3F4F6',
        borderRadius: '16px',
        mt: 8,
        mb: 6,
      }}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          sx={{
            borderRadius: '16px',
            minWidth: {
              md: '180px !important',
              sm: '180px !important',
              xs: "100px !important"
            },
            width: {
              md: '180px !important',
              sm: '180px !important',
              xs: "100px !important"
            },
            color: '#7D7B99',
            '&.Mui-selected': {
              color: 'white !important',
              backgroundColor: '#0B85BA',
            },
          }}
        />
      ))}
    </Tabs>
  );
}
