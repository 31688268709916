import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PrimaryButton from '../../Components/Shared/PrimaryButton.jsx';
import { useConnectionProvider } from '../../Providers/Vendor/Connections.js';
import { stringAvatar } from '../../Utils/avatar.js';
import { SelectVendorToShareConsultant } from '../DashboardVendor/components/Consultants/SelectVendorToShareConsultant.jsx';

import CurrentConsultants from './stepper.jsx';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Consultant',
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'SKills',
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Experience',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ marginLeft: 3 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity,
          ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}
          {' '}
          selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Consultants
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={props.onDeleteIconClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function ViewSubmittedConsultants({ state }) {
  const [order, setOrder] = React.useState('name');
  const [orderBy, setOrderBy] = React.useState('asc');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [showVendors, setShowVendors] = React.useState(false);
  const [filteredSelectedConsultantList, setfilteredSelectedConsultantList] = React.useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const location = useLocation();
  const navigate = useNavigate();
  const data = state;
  const vendorid = localStorage.getItem('userId');

  const onDeleteIconClick = () => {
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchData = async () => {
    let jobId = data.id;

    if (vendorid === data.created_by) { jobId = data.parent_job_id; }

    const headers = { Authorization: `Bearer ${localStorage.getItem('at')}` };
    const response = await fetch(`${process.env.REACT_APP_MARKETPLACE_URL}/jobapplication/get/job/${jobId}`, { headers });
    const newData = await response.json();

    if (newData?.data?.job_application) {
      const consultants = newData.data.consultant_details;
      setfilteredSelectedConsultantList(consultants);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong',
      });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [data]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredSelectedConsultantList.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, data) => {
    const selectedIndex = selected.findIndex((vendor) => vendor.id === name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.findIndex((vendor) => vendor?.id === name) !== -1;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredSelectedConsultantList.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(filteredSelectedConsultantList, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    ),
    [order, orderBy, page, rowsPerPage, filteredSelectedConsultantList],
  );

  function onNextButton() {
    setShowVendors(true);
    setAreVendorsSelected(true);
  }

  function onCancelButton() {
    setSelected([]);
    setShowVendors(false);
    setAreVendorsSelected(false);
  }

  const isDisabled = (rowId) => {
  };

  return (
    <>
      <div className="rounded-md shadow-md px-6 py-5 my-8 hover:shadow-xl cursor-pointer">
        <div className="flex flex-col">
          <span className="font-bold text-lg">{data?.name}</span>
          <span className="text-base inline-flex items-center">
            {data?.company}
          </span>
        </div>
        {data?.description && (
          <div>
            <p className="py-3">
              {data?.description?.length > 500
                ? `${data?.description.substring(0, 300)}...`
                : data?.description}
            </p>
          </div>
        )}
      </div>
      {!showVendors
        ? (
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                onDeleteIconClick={onDeleteIconClick}
              />
              <TableContainer className="px-4">
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="consultantsTable"
                  padding="none"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={filteredSelectedConsultantList.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `consultants-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer', padding: 16 }}
                          className=" py-4"
                        >
                          <TableCell
                            id={labelId}
                            scope="row"
                            padding="none"
                            style={{ width: '20%' }}
                          >
                            <Link
                              target="_blank"
                              to={`/consultant/profile/${row.id}`}
                            >
                              <div className="flex flex-row items-center">
                                <Avatar
                                  {...stringAvatar(
                                    row.firstName
                                      ? `${row.firstName} ${row.lastName}`.trim()
                                      : row.email,
                                  )}
                                  className="mr-2.5"
                                  size="large"
                                  src={row?.profilePhoto}
                                />
                                {row.firstName
                                  ? `${row.firstName} ${row.lastName}`.trim()
                                  : row.email}
                              </div>
                            </Link>
                          </TableCell>
                          <TableCell align="left" style={{ width: '40%' }}>
                            {(row?.skills)?.toString()}
                          </TableCell>
                          <TableCell align="left" style={{ padding: 30 }}>
                            {row.industryExperience ? `${row.industryExperience} Years` : 'Not Mentioned'}
                          </TableCell>
                          <TableCell align="left" style={{ padding: 30 }}>
                            {isDisabled(row.id) ? 'Submitted' : ''}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 33 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredSelectedConsultantList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <SelectVendorToShareConsultant
            onBack={onCancelButton}
            selected={selected}
          />
        )}
    </>
  );
}
