import jobIcon from "./img/job-icon.png";
import vendorIcon from "./img/vendor-icon.png";
import resourcesIcon from "./img/resources-icon.png";
import clientsIcon from "./img/client-icon.png";

export const welcomeClientScreenStepsCards = [
  {
    imgSrc: jobIcon,
    title: "Post your first Job",
    subTitle: `A new position opened up? Let's add it to the job list. Reach a global audience! just by clicking on "Post in Marketplace".`,
    buttonText: "Add Job",
    navigatePath: "/dashboard/jobs?add-new-job=true",
    accessKey: 'jobs'
  },
  {
    imgSrc: vendorIcon,
    title: "Add your vendor",
    subTitle: "Partnering with a new vendor? Let's get them added to your list",
    buttonText: "Add Vendor",
    navigatePath: "/dashboard/vendors?add-new-vendor=true",
    accessKey: 'vendors'
  },
];

export const welcomeVendorScreenStepsCards = [
  {
    imgSrc: resourcesIcon,
    title: "Add Resources",
    subTitle: "Get started with adding your resources",
    buttonText: "Add Resources",
    navigatePath: "/dashboard/resources?add-new-resource=true",
    accessKey: 'consultants'
  },
  {
    imgSrc: jobIcon,
    title: "Post your first Job",
    subTitle: `A new position opened up? Let's add it to the job list. Reach a global audience! just by clicking on "Post in Marketplace".`,
    buttonText: "Add Job",
    navigatePath: "/dashboard/jobs?add-new-job=true",
    accessKey: 'jobs'
  },
  {
    imgSrc: clientsIcon,
    title: "Add Clients",
    subTitle: "Welcome your latest client! Add them to your list now.",
    buttonText: "Add Clients",
    navigatePath: "/dashboard/clients?add-new-clients=true",
    accessKey: 'clients'
  },
];