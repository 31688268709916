import React, {useState} from "react";
import CurrentConsultants from "./stepper.jsx";

export default function VendorJobConsultant({clicked, setAreVendorsSelected}) {
    const [active, setActive] = useState("");
    return (<CurrentConsultants
    setActive={setActive}
    clicked={clicked}
    setAreVendorsSelected={setAreVendorsSelected}
  />)
  }