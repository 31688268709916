export function removeHtmlTags(htmlString) {
  const removeTagString = htmlString?.replace(/<[^>]*>/g, '');
  return removeTagString;
}

export const contactNameValidation = {
  required: "This field is required"
}

export const contactEmailValidation = {
  required: "This Email field is required",
  pattern: {
    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    message: 'Invalid email address',
  },
}

export const contactSubjectValidation = {
  required: "This Subject field is required"
}

export const contactNumberValidation = {
  pattern: {
    value: /^\+?[0-9]{8,14}$/, 
    message: 'Invalid phone number format'
  }
}

export const contactDescritionValidation = {
  required: "This Description field is required",
}