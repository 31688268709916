import React from 'react';

export default function ClientsIcon({ color, size = '16px' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path d="M11.6 5.90449e-05C11.0252 0.00181624 10.4616 0.158859 9.96872 0.454583C9.47584 0.750307 9.07204 1.17372 8.8 1.68005C9.32739 1.78893 9.82713 2.00396 10.2688 2.31205C10.4147 2.09271 10.6125 1.91277 10.8446 1.78822C11.0767 1.66367 11.336 1.59836 11.5994 1.59808C11.8628 1.59781 12.1222 1.66257 12.3546 1.78664C12.587 1.9107 12.7852 2.09022 12.9315 2.30926C13.0778 2.5283 13.1678 2.78008 13.1935 3.04225C13.2191 3.30442 13.1797 3.56887 13.0786 3.81212C12.9775 4.05537 12.8179 4.2699 12.6139 4.43666C12.41 4.60342 12.1681 4.71725 11.9096 4.76804C12.0251 5.2947 12.0292 5.8397 11.9216 6.36804C12.7398 6.29021 13.4963 5.89974 14.0338 5.27796C14.5712 4.65618 14.848 3.85103 14.8065 3.03023C14.7651 2.20943 14.4086 1.43627 13.8112 0.871797C13.2139 0.307324 12.4218 -0.00493727 11.6 5.90449e-05Z" fill={color || '#9CA3AF'} />
      <path d="M8 8.80003C7.3671 8.80003 6.74841 8.61235 6.22218 8.26073C5.69594 7.90911 5.28579 7.40934 5.04359 6.82462C4.80138 6.2399 4.73801 5.59649 4.86149 4.97575C4.98496 4.35501 5.28973 3.78483 5.73726 3.3373C6.18479 2.88978 6.75497 2.58501 7.37571 2.46154C7.99645 2.33806 8.63986 2.40144 9.22459 2.64363C9.80931 2.88583 10.3091 3.29598 10.6607 3.82222C11.0123 4.34846 11.2 4.96714 11.2 5.60004C11.2 6.44873 10.8629 7.26266 10.2627 7.86277C9.66263 8.46289 8.84869 8.80003 8 8.80003ZM8 4.00004C7.68355 4.00004 7.37421 4.09388 7.11109 4.26969C6.84797 4.4455 6.64289 4.69539 6.52179 4.98775C6.40069 5.28011 6.36901 5.60181 6.43074 5.91218C6.49248 6.22255 6.64487 6.50764 6.86863 6.73141C7.09239 6.95517 7.37749 7.10755 7.68786 7.16929C7.99823 7.23103 8.31993 7.19934 8.61229 7.07824C8.90466 6.95714 9.15454 6.75207 9.33035 6.48895C9.50616 6.22583 9.6 5.91649 9.6 5.60004C9.6 5.17569 9.43143 4.76873 9.13137 4.46867C8.83131 4.16861 8.42435 4.00004 8 4.00004Z" fill={color || '#9CA3AF'} />
      <path d="M12 16H4C3.78783 16 3.58434 15.9157 3.43431 15.7657C3.28429 15.6157 3.2 15.4122 3.2 15.2V13.6C3.20127 12.5395 3.62311 11.5229 4.37298 10.773C5.12285 10.0231 6.13952 9.60129 7.2 9.60002H8.8C9.86048 9.60129 10.8772 10.0231 11.627 10.773C12.3769 11.5229 12.7987 12.5395 12.8 13.6V15.2C12.8 15.4122 12.7157 15.6157 12.5657 15.7657C12.4157 15.9157 12.2122 16 12 16ZM4.8 14.4H11.2V13.6C11.2 12.9635 10.9471 12.353 10.4971 11.903C10.047 11.4529 9.43652 11.2 8.8 11.2H7.2C6.56348 11.2 5.95303 11.4529 5.50294 11.903C5.05286 12.353 4.8 12.9635 4.8 13.6V14.4Z" fill={color || '#9CA3AF'} />
      <path d="M4 5.60004C4.00082 5.32061 4.03085 5.04203 4.0896 4.76884C3.83112 4.71805 3.58918 4.60422 3.38526 4.43746C3.18134 4.2707 3.02174 4.05617 2.92065 3.81292C2.81955 3.56967 2.78008 3.30522 2.80573 3.04305C2.83139 2.78088 2.92138 2.5291 3.06771 2.31006C3.21405 2.09102 3.4122 1.9115 3.64458 1.78744C3.87696 1.66337 4.13638 1.59861 4.3998 1.59888C4.66323 1.59916 4.92251 1.66447 5.15463 1.78902C5.38675 1.91357 5.58452 2.09351 5.7304 2.31285C6.17225 2.00437 6.67227 1.78907 7.2 1.68005C6.93893 1.19496 6.5567 0.785727 6.09052 0.492205C5.62435 0.198683 5.09011 0.0308704 4.53982 0.00510671C3.98954 -0.0206569 3.44196 0.0965059 2.9504 0.345188C2.45883 0.59387 2.04004 0.965597 1.73479 1.42418C1.42954 1.88276 1.24823 2.41257 1.20852 2.96202C1.1688 3.51147 1.27204 4.06184 1.50818 4.55955C1.74431 5.05726 2.1053 5.48535 2.556 5.80213C3.00669 6.11892 3.53173 6.3136 4.08 6.36724C4.02855 6.1147 4.00176 5.85776 4 5.60004Z" fill={color || '#9CA3AF'} />
      <path d="M12 7.20003H11.6568C11.3296 7.93625 10.79 8.558 10.1072 8.98563C10.1408 8.99523 10.1712 9.01202 10.2056 9.02242C10.517 8.87696 10.8563 8.80107 11.2 8.80003H12C12.6365 8.80003 13.247 9.05288 13.6971 9.50297C14.1471 9.95305 14.4 10.5635 14.4 11.2V12H13.3224C13.5056 12.5136 13.5995 13.0547 13.6 13.6H15.2C15.4122 13.6 15.6157 13.5157 15.7657 13.3657C15.9157 13.2157 16 13.0122 16 12.8V11.2C15.9987 10.1395 15.5769 9.12287 14.827 8.373C14.0772 7.62314 13.0605 7.2013 12 7.20003Z" fill={color || '#9CA3AF'} />
      <path d="M2.6776 12H1.6V11.2C1.6 10.5635 1.85286 9.95305 2.30294 9.50297C2.75303 9.05288 3.36348 8.80003 4 8.80003H4.8C5.14394 8.80105 5.48354 8.87694 5.7952 9.02242C5.8288 9.01202 5.8592 8.99523 5.8928 8.98563C5.20999 8.558 4.67041 7.93625 4.3432 7.20003H4C2.93952 7.2013 1.92285 7.62314 1.17298 8.373C0.423106 9.12287 0.00127029 10.1395 0 11.2V12.8C0 13.0122 0.0842854 13.2157 0.234315 13.3657C0.384344 13.5157 0.587827 13.6 0.8 13.6H2.4C2.40052 13.0547 2.49441 12.5136 2.6776 12Z" fill={color || '#9CA3AF'} />
    </svg>
  );
}
