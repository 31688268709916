import moment from 'moment';
import React, { useState, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Avatar } from '@mui/material';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Modal } from 'antd';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { stringAvatar } from '../../../../../../Utils/avatar';
import { useTimesheetProvider } from '../../../../../../Providers/Timesheet.provider';
import Back from '../../../../../../Components/Back';
import CustomTable from '../../../../../../Components/Table';
import {
  timesheetStatus,
  userPermissions,
} from '../../../../../../Utils/constants';
import api from '../../../../../../Utils/api';
import { useJobProvider } from '../../../../../../Providers/Client/Jobs';
import { selectUserRole } from '../../../../../../Redux/Feature/user';
import { getPermissions } from 'Utils/helper';

const apiPath = {
  fetchTimesheetDetails: '/timesheet/gettimesheetdetails',
  fetchTimesheetLinkDetails: '/timesheet/link/gettimesheetid',
};

function Daywise({ showDatePicker = false, setShowTimesheetDetails }) {
  const { role } = useSelector(selectUserRole);
  const navigate = useNavigate();
  const { token, timesheetid, id } = useParams();
  const [job, setJob] = useState();

  const { fetchJobDetails } = useJobProvider();

  // Date Picker
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState({
    date: moment.utc(job?.startDate).startOf('week'),
    fromApi: false,
  });

  // Approve / Reject Modal
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState(timesheetStatus.none);
  const [modal, setModal] = useState({
    open: false,
    type: timesheetStatus.approved,
  });
  const [currentTimesheet, setCurrentTimesheet] = useState({});
  const location = useLocation();
  const from = location.state?.from?.pathname;

  // Timesheet Master
  const [timesheetMaster, setTimesheetMaster] = useState({});
  const [timesheetResource, setTimesheetResource] = useState({});

  const {
    selectedTimesheet,
    timesheetDetails,
    setSelectedTimesheet,
    approveTimesheet,
    rejectTimesheet,
    timesheets,
    fetchTimesheets,
    fetchTimesheetsByDate,
  } = useTimesheetProvider();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchJobDetails(id).then(setJob);
    setSelectedTimesheet(timesheetid);
  }, []);

  const fetchTimesheetDetails = async () => {
    try {
      if (token) {
        const secret = 'rJK7?E@KynQ!5F39C-gy';
        const wordArray = CryptoJS.enc.Base64url.parse(token);
        const string = wordArray.toString(CryptoJS.enc.Base64);
        const bytes = CryptoJS.AES.decrypt(string, secret);
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const linkId = data?.linkId;

        if (linkId) {
          const timesheetLinkDetails = await api.get(
            apiPath.fetchTimesheetLinkDetails,
            { params: { id: linkId } },
          );

          const res = await api.get(apiPath.fetchTimesheetDetails, {
            params: {
              timesheet_id: timesheetLinkDetails.data.result,
              user_id: localStorage.getItem('userId'),
            },
          });

          if (res.data?.success) {
            setTimesheetMaster(res.data.timesheet[0]);
            setTimesheetResource(res.data.resource.resource);
            setStatus(res.data.timesheet[0].status);
            setRows(res?.data?.result ?? []);
          } else {
            throw new Error('Error occurred while fetching timesheet details');
          }
        }
      }
    } catch (error) {
      console.error(error);
      Swal.fire('Error', 'Error occurred while fetching timesheet', 'error');
    }
  };

  useEffect(() => {
    fetchTimesheetDetails();
  }, [token]);

  // useEffect

  useEffect(() => {
    fetchTimesheets(role);
  }, []);

  useEffect(() => {
    if (timesheets.length > 0) {
      const cur = timesheets?.find((t) => t.id === selectedTimesheet);
      setCurrentTimesheet(cur);
      setStatus(cur?.status ?? timesheetStatus.none);
    }
  }, [selectedTimesheet, timesheets]);

  useEffect(() => {
    if (
      timesheetDetails?.[selectedTimesheet]
      && timesheetDetails?.[selectedTimesheet][0]
    ) {
      setDate({
        date: moment
          .utc(timesheetDetails?.[selectedTimesheet][0].date)
          .startOf('week'),
        fromApi: false,
      });
    }
    setRows(timesheetDetails?.[selectedTimesheet] ?? []);
  }, [selectedTimesheet, timesheetDetails]);

  const headCells = ['Date', 'Hours', 'Description'];
  function CustomRow({ row }) {
    return (
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell width="20%" component="th" scope="row">
          {moment.utc(row.date).format('dddd, MMMM DD, YYYY')}
        </TableCell>
        <TableCell width="20%">
          {row.hours.toString().padStart(2, '0')}
          :
          {row.minutes.toString().padStart(2, '0')}
        </TableCell>
        <TableCell className="truncate max-w-[250px] grow">
          {row.description}
        </TableCell>
      </TableRow>
    );
  }

  function StatusButtons() {
    const permissions = getPermissions();
    return (
      <div className="flex items-center flex-row space-x-6 justify-end">
        {status === timesheetStatus.approved
          || status === timesheetStatus.rejected ? (
            <div
              className="px-2 py-1 rounded-[5px] font-[18px] leading-[22px]"
              style={
              status === timesheetStatus.approved
                ? { backgroundColor: '#2EBAA34D', color: '#2EBAA3' }
                : { background: '#FF33334D', color: '#FF3333' }
            }
            >
              {status[0] + status.slice(1).toLowerCase()}
            </div>
          ) : (currentTimesheet || timesheetMaster.job)
          && permissions.includes(userPermissions.timesheet.approve) ? (
            <>
              <button
                className="bg-[#2EBAA3] text-white px-2 py-1.5 rounded-md w-[100px]"
                onClick={() => setModal({ open: true, type: timesheetStatus.rejected })}
              >
                Reject
              </button>
              <button
                className="bg-[#2EBAA3] text-white px-2 py-1.5 rounded-md w-[100px]"
                onClick={() => setModal({ open: true, type: timesheetStatus.approved })}
              >
                Approve
              </button>
            </>
            ) : null}
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        className={`flex flex-row gap-x-3 font-medium text-[18px] items-center ${showDatePicker ? 'mb-6' : 'mb-2'
        }`}
      >
        <Back
          onClick={() => {
            setSelectedTimesheet(null);
            setShowTimesheetDetails?.(false);
            navigate(-1);
          }}
          className=""
        />
        <span>Timesheet</span>
      </div>
      {showDatePicker ? (
        job && (
          <div className="flex mb-4 justify-between">
            <div className="flex items-center select-none">
              {/* Left Arrow */}
              <ChevronLeft
                sx={{ fontSize: 28 }}
                className="border-[1px] rounded-md mr-2 border-black cursor-pointer"
                onClick={() => {
                  const newDate = moment.utc(date.date).subtract(1, 'week');
                  // if (newDate >= moment.utc(job.startDate)) {
                  setDate({ date: newDate, fromApi: false });
                  setStatus(timesheetStatus.none);

                  if (job.id || id) {
                    fetchTimesheetsByDate({
                      jobId: job.id || id,
                      startDate: newDate.startOf('week').toISOString(),
                    });
                  }
                  // }
                }}
              />

              {/* Display Selected Week */}
              <input
                readOnly
                onClick={() => setOpen(!open)}
                className="h-10 w-[350px] text-white bg-[#2EBAA3] p-1 rounded-md border-0 cursor-pointer text-center outline-0"
                value={
                  `${date.date.startOf('week').format('Do MMM, YYYY')
                  } - ${
                    date.date.endOf('week').format('Do MMM, YYYY')}`
                }
              />

              {/* Right Arrow */}
              <ChevronRight
                sx={{ fontSize: 28 }}
                className="border-[1px] rounded-md ml-2 border-black cursor-pointer"
                onClick={() => {
                  const newDate = moment.utc(date.date).add(1, 'week');
                  setDate({ date: newDate, fromApi: false });
                  setStatus(timesheetStatus.none);

                  if (job.id || id) {
                    fetchTimesheetsByDate({
                      jobId: job.id || id,
                      startDate: newDate.startOf('week').toISOString(),
                    });
                  }
                }}
              />
            </div>

            <StatusButtons />
          </div>
        )
      ) : (
        <div className="mb-4">
          <StatusButtons />
        </div>
      )}

      {timesheetMaster?.job || currentTimesheet ? (
        <div className="space-y-4">
          <div
            style={{ border: '1px solid #D9D9D9' }}
            className="flex flex-col bg-white p-4 rounded-md"
          >
            <div className="flex flex-row space-x-4">
              <span style={{ fontWeight: '500' }} className="mr-[39px]">
                Name
              </span>
              <span>
                {timesheetMaster?.job?.name
                  ?? currentTimesheet?.job?.name
                  ?? '-'}
              </span>
            </div>
            <div className="flex flex-row space-x-4">
              <span style={{ fontWeight: '500' }} className="">
                Description
              </span>
              <span>
                {timesheetMaster?.job?.name
                  ? timesheetMaster?.job?.name
                  : currentTimesheet?.job?.description}
              </span>
            </div>
          </div>
          <div className="flex flex-row space-x-16 border border-[#D9D9D9] rounded-[8px] bg-white p-4 mb-8">
            <div className="flex flex-col">
              <span className="font-medium">Vendor</span>
              <div className="flex flex-col bg-white flex-1 rounded-b-lg mt-2">
                <div
                  style={{
                    width: '287px',
                    height: '42px',
                  }}
                  className="flex flex-row text-black items-center space-x-6 bg-[#CFCFCF] rounded-lg"
                >
                  <Link
                    target="_blank"
                    to={`/vendor/profile/${timesheetResource?.id ?? currentTimesheet?.vendor?.id
                    }`}
                  >
                    <Avatar
                      className="ml-2"
                      style={{
                        height: '35px',
                        width: '35px',
                      }}
                      {...stringAvatar(
                        `${timesheetResource?.company
                        ?? currentTimesheet?.vendor?.company
                        ?? ''
                        }`,
                      )}
                      src={
                        timesheetResource?.vendor?.profilePhoto
                        ?? currentTimesheet?.vendor?.profilePhoto
                        ?? ''
                      }
                    />
                  </Link>
                  <span className="">
                    {timesheetResource?.company
                      ?? currentTimesheet?.vendor?.company
                      ?? ''}
                    {' '}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-medium">Consultant</span>
              <div className="flex flex-col bg-white flex-1 rounded-b-lg mt-2">
                <div
                  style={{
                    width: '287px',
                    height: '42px',
                  }}
                  className="flex flex-row items-center space-x-6 bg-[#2EBAA3] rounded-lg"
                >
                  <Link
                    target="_blank"
                    to={`/consultant/profile/${timesheetMaster?.consultant?.id
                      ?? currentTimesheet?.consultant?.id
                    }`}
                  >
                    <Avatar
                      className="ml-2"
                      style={{
                        height: '35px',
                        width: '35px',
                      }}
                      {...stringAvatar(
                        `${`${timesheetMaster?.consultant?.firstName
                          ?? currentTimesheet?.consultant?.firstName
                          ?? ''
                        } ${
                          timesheetMaster?.consultant?.lastName
                          ?? currentTimesheet?.consultant?.lastName
                          ?? ''}`
                        }`,
                      )}
                      src={
                        timesheetMaster?.consultant?.profilePhoto
                        ?? currentTimesheet?.consultant?.profilePhoto
                        ?? ''
                      }
                    />
                  </Link>
                  <span className="text-white">
                    {timesheetMaster?.consultant?.firstName
                      ?? currentTimesheet?.consultant?.firstName
                      ?? ''}
                    {' '}
                    {timesheetMaster?.consultant?.lastName
                      ?? currentTimesheet?.consultant?.lastName
                      ?? ''}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* <div>
        {timesheetMaster?.approvalDate &&
          <div className="flex font-medium mt-4 mb-4 space-x-4">
            <span>Approved By : </span>
            <span className="text-[#139588]">
              {timesheetMaster?.approvedByExternal || timesheetMaster?.approvedBy?.firstName + " " + timesheetMaster?.approvedBy?.lastName}
            </span>
          </div>
        }
        {currentTimesheet?.approvalDate &&
          <div className="flex font-medium mt-4 mb-4 space-x-4">
            <span>Approved By : </span>
            <span className="text-[#139588]">
              {currentTimesheet?.approvedByExternal || currentTimesheet?.approvedBy + " " + currentTimesheet?.approvedBy?.lastName}
            </span>
          </div>
        }
      </div> */}
      <div className="flex flex-row justify-between">
        {timesheetMaster?.approvalDate ? (
          <div className="flex font-medium mt-4 mb-4 space-x-4">
            {timesheetMaster?.approvalDate
              && (timesheetMaster?.status === 'REJECTED'
                || timesheetMaster?.status === 'APPROVED') && (
                <>
                  <span>
                    {timesheetMaster.status === 'REJECTED'
                      ? 'Rejected'
                      : 'Approved'}
                    {' '}
                    On :
                    {' '}
                  </span>
                  <span className="text-[#139588]">
                    {dayjs(timesheetMaster?.approvalDate)
                      .locale('en')
                      .format('DD MMMM YYYY h:mm A')}
                  </span>
                </>
            )}
          </div>
        ) : (
          <div className="flex font-medium mt-4 mb-4 space-x-4">
            {currentTimesheet?.approvalDate
              && (currentTimesheet?.status === 'REJECTED'
                || currentTimesheet?.status === 'APPROVED') && (
                <>
                  <span>
                    {currentTimesheet.status === 'REJECTED'
                      ? 'Rejected'
                      : 'Approved'}
                    {' '}
                    On :
                    {' '}
                  </span>
                  <span className="text-[#139588]">
                    {dayjs(currentTimesheet?.approvalDate)
                      .locale('en')
                      .format('DD MMMM YYYY h:mm A')}
                  </span>
                </>
            )}
          </div>
        )}

        {timesheetMaster?.approvalDate ? (
          <div className="flex font-medium mt-4 mb-4 space-x-4">
            {timesheetMaster?.approvalDate
              && (timesheetMaster?.status === 'REJECTED'
                || timesheetMaster?.status === 'APPROVED') && (
                <>
                  <span>Comments : </span>
                  <span className="text-[#139588]">
                    {timesheetMaster?.clientComments}
                  </span>
                </>
            )}
          </div>
        ) : (
          <div className="flex font-medium mt-4 mb-4 space-x-4">
            {currentTimesheet?.approvalDate
              && (currentTimesheet?.status === 'REJECTED'
                || currentTimesheet?.status === 'APPROVED') && (
                <>
                  <span>Comments : </span>
                  <span className="text-[#139588]">
                    {currentTimesheet?.clientComments}
                  </span>
                </>
            )}
          </div>
        )}

        {timesheetMaster?.hours ? (
          <div className="flex font-medium mt-4 mb-4 space-x-4">
            <span>Total Hours : </span>
            <span className="text-[#139588]">
              {(timesheetMaster?.hours || timesheetMaster?.minutes)
                && timesheetMaster.minutes == 0
                ? `${timesheetMaster.hours}:00`
                : `${timesheetMaster.hours}:${timesheetMaster.minutes}`}
            </span>
          </div>
        ) : (
          <>
            {(currentTimesheet?.hours || currentTimesheet?.minutes) && (
              <div className="flex font-medium mt-4 mb-4 space-x-4">
                <span>Total Hours : </span>
                <span className="text-[#139588]">
                  {(currentTimesheet?.hours || currentTimesheet?.minutes)
                    && currentTimesheet.minutes == 0
                    ? `${currentTimesheet.hours}:00`
                    : `${currentTimesheet.hours}:${currentTimesheet.minutes}`}
                </span>
              </div>
            )}
          </>
        )}
      </div>

      <CustomTable
        headCells={headCells.map((heading) => ({ label: heading }))}
        rows={rows}
        CustomRow={CustomRow}
        showPagination={false}
      />
      <Modal
        open={modal.open}
        onOk={() => {
          if (selectedTimesheet !== null) {
            if (modal.type === timesheetStatus.rejected) {
              if (comment.length === 0) {
                Swal.fire(
                  'Error',
                  'Please provide a reason for rejection',
                  'error',
                );
              } else {
                rejectTimesheet(selectedTimesheet, comment);
                setStatus(timesheetStatus.rejected);
                setModal({ open: false, type: timesheetStatus.approved });
              }
            } else {
              approveTimesheet(selectedTimesheet, comment);
              setStatus(timesheetStatus.approved);
              setModal({ open: false, type: timesheetStatus.approved });
            }
          }
        }}
        onCancel={() => setModal({ open: false, type: timesheetStatus.approved })}
        okButtonProps={{ className: 'bg-[#1677FF]' }}
        okText={modal.type === timesheetStatus.rejected ? 'Reject' : 'Approve'}
        closable={false}
      >
        <p className="font-medium mb-1 ml-0.5">Comments</p>
        <input
          className="w-full text-sm"
          maxLength={250}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default Daywise;
