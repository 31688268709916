import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    role: '',
    permissions: [],
    details: {},
    loading: true,
    openSideNav: true,
    openSideNavHov: false,
    isHybridConsultant: false,
    openPaymentModal: false,
  },
  reducers: {
    setLogin: (state, action) => {
      state.role = action.payload?.role;
      state.permissions = action.payload?.permissions || [];
      state.isLoggedIn = true;
      state.loading = false;
      state.isHybridConsultant = action.payload?.isHybridConsultant;
    },
    setUser: (state, action) => {
      state.isLoggedIn = !!action.payload?.role;
      state.role = action.payload?.role;
      state.details = action.payload;
      state.loading = false
    },
    setLogout: (state) => {
      state.isLoggedIn = false;
      state.role = '';
      state.permissions = [];
      state.details = {};
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setOpenSideNav: (state, action) => {
      state.openSideNav = action.payload.openSideNav;
    },
    setOpenSideNavHov: (state, action) => {
      state.openSideNavHov = action.payload.openSideNavHov;
    },
    setOpenPaymentModal: (state, action) => {
      state.openPaymentModal = action.payload.openPaymentModal;
    },
  },
});

export const { setLogin, setLogout, setUser, setLoading, setOpenSideNav, setOpenSideNavHov,setOpenPaymentModal } = userSlice.actions;

export default userSlice.reducer;
