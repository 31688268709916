import Papaparse from 'papaparse';

`
    INFO: The function is designed to merge multiple arrays into a single array,
    ensuring that the elements are unique based on the result of applying the iteratee function to each element.
`;
export function unionBy(...arrays) {
  const iteratee = arrays.pop();

  if (Array.isArray(iteratee)) {
    return []; // return empty if iteratee is missing
  }

  return [...arrays].flat().filter(
    (
      (set) => (o) => (set.has(iteratee(o)) ? false : set.add(iteratee(o)))
    )(new Set()),
  );
}

export const getUserIdFromLocalStorage = () => {
  const userId = localStorage.getItem('userId');
  return userId;
};

export const generateRandomHexCode = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor.padStart(6, '0')}`;
};

export const parseCSV = (file, onDataParsed) => {
  Papaparse.parse(file, {
    complete: (result) => {
      onDataParsed(result.data);
    },
    header: true,
  });
};

export const isValidFourNumbers = (number) => {
  const isValid = /^[0-9]{4}$/.test(number);
  return isValid;
};

export const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const removeKeysFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
};

export const getPermissions = () => {
  const permission = localStorage.getItem('permission');
  if (permission) return JSON.parse(permission);
  return [];
};

export const isValidUrl = (url) => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlPattern.test(url);
};

export const getUserRole = () => {
  const role = localStorage.getItem('role');
  if (role) return role;
  return '';
};

export function formatTime(timeString) {
  // Parse the input time string as a Date object
  if (!timeString) return '';
  const [hours, minutes, seconds] = timeString.split(':');
  let hour = parseInt(hours, 10);
  const minute = parseInt(minutes, 10);
  const period = hour >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hour = hour % 12 || 12;

  // Format hours and minutes to ensure two digits
  const formattedHour = hour.toString().padStart(2, '0');
  const formattedMinute = minute.toString().padStart(2, '0');

  return `${formattedHour}:${formattedMinute} ${period}`;
}

export function formatDate(dateString) {
  const date = new Date(dateString);

  // Extract the UTC components to avoid timezone conversions
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth(); // Months are zero-based in Date.UTC
  const day = date.getUTCDate();

  // Create a new Date object with the extracted UTC components
  const newDate = new Date(Date.UTC(year, month, day));

  return newDate;
}

export const removeDataFromLocalStorage = () => {
  localStorage.removeItem('persist:root');
  localStorage.removeItem('userId');
  localStorage.removeItem('rft');
  localStorage.removeItem('at');
  localStorage.removeItem('newPassword');
  localStorage.removeItem('email');
  localStorage.removeItem('permission');
  localStorage.removeItem('role');
  localStorage.removeItem('isHybridConsultant');
};

export const truncateText = (html, limit) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  const textContent = tempElement.textContent || tempElement.innerText || '';
  const words = textContent.split(' ');
  let truncatedText;
  if (words.length > limit) {
    truncatedText = words.slice(0, limit).join(' ') + '...';
  } else {
    truncatedText = textContent;
  }

  return truncatedText;
};

