import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import ConsultantPricingTable from "./ConsultantPricingTable.component";
import EnterprisePricingTable from "./EnterprisePricingTable.component";
import businessPlan from 'Assets/business_pan.svg'
import { useNavigate } from "react-router-dom";
import VendorPricingTable from "./VendorPricingTable.component";
import { useSelector } from "react-redux";
import { selectUserDetails } from "Redux/Feature/user";
import Button from "Components/Common/semantic_tags/Button";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  minWidth: "200px",
  textAlign: "center",
  textTransform: "none",
  "&.Mui-selected": {
    backgroundColor: "#0B85BA",
    borderRadius: "8px",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#0B85BA",
      color: theme.palette.common.white,
    },
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#0B85BA",
    color: theme.palette.common.white,
  },
}));

export default function PricingPage() {
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);
  const [country, setCountry] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [selectedUserType, setSelectedUserType] = React.useState("consultant");
  
  const navigationHandler = () => {
    if (userDetails && Object.keys(userDetails).length > 0) navigate('/dashboard/settings')
    else navigate('/signup')
  }

  useEffect(() => {
    const fetchCountryInfo = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setCountry(data.country);
      } catch (error) {
        console.error("Error fetching country information:", error);
      }
    };

    fetchCountryInfo();
  }, []);

  
  return (
    <Stack sx={{ minHeight: '100vh', marginTop: '50px' }} flexDirection={isMobile ? 'column': 'row'} gap={6} justifyContent={'center'} alignItems={isMobile ? 'center' : 'start'}>
      {/* <PageHeader title="Plans And Pricing" /> */}
      <Stack alignItems="center" marginTop={"20px"}>
        <Typography
          textAlign="center"
          variant={'h4Bold'}
          color="#003B54"
        >
          Unlock Your Potential with
          <br />
          ProLegion's Flexible Pricing Plans
        </Typography>
        <Typography
          textAlign="center"
          variant="body1Normal"
          color="#003B54"
          sx={{ pt: 1, pb: 4 }}
        >
          Boost the efficiency of your IT workforce with ProLegion- a simplified
          <br /> and unified platform that enables a seamless experience.
        </Typography>
        <Button
          className="mb-4"
          onClick={navigationHandler}
          title='Get Started'
        />
        <img src={businessPlan} style={{ width: '70%' }} />
      </Stack>
      <Box sx={{ height: "100%", width: isMobile ? '100%' : '70%' }}>
        {/* <FullWidthWrapper> */}
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={8}
          sx={{ my: 4, width: "100%" }}
        >
          <ToggleButtonGroup
            value={selectedUserType}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <StyledToggleButton
              value="consultant"
              onClick={() => setSelectedUserType("consultant")}
            >
              Consultant
            </StyledToggleButton>
            <StyledToggleButton
              value="vendor"
              onClick={() => setSelectedUserType("vendor")}
            >
              Vendor
            </StyledToggleButton>
            <StyledToggleButton
              value="enterprise"
              onClick={() => setSelectedUserType("enterprise")}
            >
              Enterprise
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Box
          sx={{
            width: isMobile ? "100%" : "100%",
            margin: "0px auto",
            marginBottom: "50px",
          }}
        >
          {selectedUserType === "consultant" && <ConsultantPricingTable country={country} />}
          {selectedUserType === "vendor" && <VendorPricingTable country={country} />}
          {selectedUserType === "enterprise" && <EnterprisePricingTable country={country} />}
        </Box>
        {/* </FullWidthWrapper> */}
      </Box>
    </Stack>
  );
}
