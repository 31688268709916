import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MuiModal from "Components/Common/MuiModal";
import ReactDropzone from "Components/Common/ReactDropzone";
import MyEditor from "Components/TextEditor";
import dayjs from "dayjs";
import useDebounce from "hooks/useDebounce";
import { fetchCurrentConsultantList, fetchResourcesofClient } from "Pages/ResourcesPage/api";
import { useAlertProvider } from "Providers/util/Alert";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserDetails, selectUserRole } from "Redux/Feature/user";
import { getPermissions } from "Utils/helper";
import SprintList from "./BoardLanes";
import { taskList } from "./constant";
import { taskStatueses } from "./constant.task";
import FilterSection from "./FilterSection";
import { addNewTask, fetchAllTask, fetchJobs, updateTask } from "./task.api";
import TaskHeader from "./TaskHeader";
import ViewTaskModal from "./ViewTaskModal";
import Button from "Components/Common/semantic_tags/Button";
import CustomInput from "Components/Common/semantic_tags/Input";

const intialValue = {
  name: "",
  resource_id: "",
  job_id: "",
  status: "OPEN",
  files: []
};

const TaskWrapper = ({
  isDataFromDifferentComponent = true,
  resource_id = "",
}) => {
  const role = useSelector(selectUserRole);
  const navigate = useNavigate();
  const permissions = getPermissions()
  const [addNewTaskModal, setAddNewTaskModal] = useState(false);
  const [newTaskDetails, setNewTaskDetails] = useState({
    ...intialValue,
    resource_id,
  });
  const queryParams = new URLSearchParams(window.location.search);
  const [isUploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskLists, setTaskLists] = useState(taskList);
  const [jobs, setJobs] = useState([]);
  const [resourcesJob, setResourcesJob] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState("");
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);
  const { success, error } = useAlertProvider();
  const profile = useSelector(selectUserDetails);
  const [resources, setResources] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [selectedResource, setSelectedResource] = useState(resource_id || '');
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryRes = useDebounce(searchQuery, 2000);
  const [taskId, setTaskId] = useState(null)
  // const muiListfilter = createFilterOptions();

  // const handleFilterCreateOption = (options, params) => {
  //   const filtered = muiListfilter(options, params);
  //   const { inputValue } = params;
  //   const isExistingValue = options.some((option)=> inputValue === option.name);
  //   if(inputValue !== '' && !isExistingValue) {
  //     filtered.push({
  //       inputValue,
  //       name: `${inputValue}`
  //     })
  //   }
  //   return filtered;
  // }
  const closeModal = () => {
    setAddNewTaskModal(false);
    setNewTaskDetails(intialValue);
    setErrorObj({});
  };
  const openModal = () => setAddNewTaskModal(true);

  const setDetails = (value, name) => {
    delete errorObj[name];
    if (name === 'resource_id') {
      return setNewTaskDetails({ ...newTaskDetails, [name]: value, job_id: '' });  
    }
    setNewTaskDetails({ ...newTaskDetails, [name]: value ? value : '' });
  };

  const fetchtask = async () => {
    // setTaskLists([]);
    const resp = await fetchAllTask(
      debounceSearch,
      selectedJobId,
      selectedResource
    );
    if (resp && resp.success) {
      const abc = [
        {
          id: "open",
          taskName: taskStatueses.open,
          list: [],
        },
        {
          id: "todo",
          taskName: taskStatueses.toDo,
          list: [],
        },
        {
          id: "in_progress",
          taskName: taskStatueses.inProgress,
          list: [],
        },
        {
          id: "in_review",
          taskName: taskStatueses.completed,
          list: [],
        },
      ];
      resp.data.forEach((task) => {
        switch (task.status) {
          case taskStatueses.open:
            abc[0].list.push(task);
            break;
          case taskStatueses.toDo:
            abc[1].list.push(task);
            break;
          case taskStatueses.inProgress:
            abc[2].list.push(task);
            break;
          case taskStatueses.completed:
            abc[3].list.push(task);
            break;
          default:
            break;
        }
      });
      setTaskLists([...abc]);
    } else {
      error(resp?.message || "Something went wrong !!!");
    }
  };

  const fetchJobsHandler = async (resource_id = '') => {
    const resp = await fetchJobs(profile?.role === 'CONSULTANT' ? profile?.user_id : resource_id);
    if (resp && resp.success) {
      if (resource_id || profile?.role === 'CONSULTANT') return setResourcesJob(resp.data)
      setJobs(resp.data);
    }
  };

  const validationhandler = (newTaskDetails) => {
    const errObj = {};
    Object.keys(newTaskDetails).forEach((val) => {
      if (!newTaskDetails[val]) {
        errObj[val] = true;
      }
    });
    if (profile?.role === 'CONSULTANT') {
      delete errObj.resource_id
    }
    if (errObj.start_date) {
      delete newTaskDetails.start_date
      delete errorObj.start_date
    }
    if (errObj.end_date) {
      delete newTaskDetails.end_date
      delete errorObj.end_date
    }
    delete errObj.end_date
    if (Object.keys(errObj).length > 0) {
      setErrorObj(errObj);
      return true;
    } else false;
  };

  const addNewTaskHandler = async () => {
    const validate = validationhandler(newTaskDetails);
    if (validate) return;
    let resp = {};
    const ObjDetails = { ...newTaskDetails, file_path: (newTaskDetails?.files || []).map(i => i.url)  }
    delete ObjDetails.files
    if (newTaskDetails?.id) {
      resp = await updateTask(newTaskDetails?.id, {
        status: newTaskDetails.status,
        // start_date: newTaskDetails.start_date,
        // end_date: newTaskDetails.end_date,
        name: newTaskDetails.name,
        description: newTaskDetails.description,
        // job_id: newTaskDetails.job_id,
      });
    } else resp = await addNewTask(ObjDetails);
    if (resp.success) {
      success("Task added successfully");
      fetchtask();
      navigate(`?id=${resp.data?.id || ""}`, { replace: true });
      closeModal();
      setOpenViewModal(true);
      return;
    } else {
      error(resp?.message || "Something went wrong!!!");
    }
  };

  const fetchResources = async () => {
    const resp = await fetchCurrentConsultantList(searchQueryRes);
    if(resp){
      setResources(resp);
    }
  };

  const fetchClientResourceList = async () => {
    const res = await fetchResourcesofClient();
    role.isClient ? setResources(res) : setResources((prev)=> prev);
  }

  useEffect(() => {
    fetchtask();
  }, [debounceSearch, selectedJobId, selectedResource]);

  useEffect(() => {
    if (profile && profile?.user_id) {
      fetchJobsHandler();
      fetchResources();
      fetchClientResourceList();
    }
  }, [profile, searchQueryRes]);

  useEffect(() => {
    newTaskDetails.resource_id && fetchJobsHandler(newTaskDetails.resource_id)
  }, [newTaskDetails.resource_id])

  const editHandler = (details) => {
    setOpenViewModal(false);
    setNewTaskDetails(details);
    openModal();
  };

  useMemo(()=>{
    if(resourcesJob.length === 0) {
      resourcesJob.push({
        job: {
          name: "No Job Found"
        }
      })
    }
  },[newTaskDetails])

  const resourcesDropdownList = useMemo(() => resources.map((item)=> ({label: `${item.firstName || item?.consultant?.firstName} ${item.lastName || item?.consultant?.lastName}`, value: item.id || item?.consultant?.id})),[resources]);
  
  const resourcesDropdownFilterList = useMemo(() => {
    const resourcesMapping = (resources || []).map((item) => ({ label: `${item.firstName || item?.consultant?.firstName} ${item.lastName || item?.consultant?.lastName}`, value: item.id || item?.consultant?.id }));
    resourcesMapping.unshift({ label: "All", value: "" });
    return resourcesMapping;
  }, [resources]);

  const jobList = useMemo(() => {
    const jobsMapping = jobs.map((item)=> ({label: item?.job?.name, value:item?.job?.id}));
    jobsMapping.unshift({label: "All", value: ""});
    return jobsMapping;
  },[jobs])
  
  const loading = useMemo(()=> (open && resources.length === 0),[open, resources]);

  return (
    <Box>
      <Paper sx={{ minHeight: "90vh", padding: "10px" }}>
        <Stack
          flexDirection={"column-reverse"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"10px"}
        >
          <Box sx={{ width: "100%" }}>
            <SprintList
              setTaskId={setTaskId}
              setNewTaskDetails={setNewTaskDetails}
              openModal={openModal}
              taskListArray={taskLists}
              setOpenViewModal={setOpenViewModal}
            />
          </Box>
          {/* <Divider
            sx={{ minWidth: "unset", marginLeft: "10px", marginRight: "10px" }}
            flexItem
          /> */}
          <Box sx={{ width: "100%" }}>
            <ViewTaskModal
              fetchtask={fetchtask}
              open={openViewModal}
              taskId={taskId}
              editHandler={editHandler}
              closeModal={() => {
                setOpenViewModal(false);
                isDataFromDifferentComponent && navigate("/dashboard/task-board", { replace: true });
              }}
            />
            <TaskHeader openModal={openModal} closeModal={closeModal} />
            <FilterSection
              resources={resourcesDropdownFilterList}
              setSelectedResource={setSelectedResource}
              setSelectedJobId={setSelectedJobId}
              setSearch={setSearch}
              isDataFromDifferentComponent={isDataFromDifferentComponent}
              jobs={jobList}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </Box>
        </Stack>
        <MuiModal
          title={newTaskDetails.id ? "Task details" : "Add new task"}
          minWidth={500}
          open={addNewTaskModal}
          onCancel={closeModal}
        >
          <FormControl fullWidth>
            <Stack
              flexDirection={"row"}
              gap={'10px'}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {profile && profile?.role !== 'CONSULTANT' && <Box width={"100%"}>
                {/* <Typography>Select Resource*</Typography> */}
                <Autocomplete
                  fullWidth
                  clearIcon={false}
                  open={open}
                  onOpen={()=> setOpen(true)}
                  onClose={()=> (setOpen(false), setSearchQuery(''))}
                  onChange={(event, value) => (setResourcesJob([]), setDetails(value?.value, "resource_id"))}
                  options={resourcesDropdownList}
                  // value={newTaskDetails.resource_id}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Resource"
                      required
                      onChange={(event)=> setSearchQuery(event.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                        </>,
                      }}
                    />
                  )}
                />
              </Box>}
              <Box width={"100%"}>
                <Typography>Select Job*</Typography>
                <Select
                  value={newTaskDetails.job_id}
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select"
                  required
                  disabled={newTaskDetails.id}
                  error={errorObj["job_id"] || false}
                  sx={{
                    background: "white",
                    "&:selected": { background: "white" },
                  }}
                  label="Select Job"
                  disabled={newTaskDetails.id}
                  onChange={(e) => setDetails(e.target.value, "job_id")}
                >
                  {(profile?.role === 'CONSULTANT' || newTaskDetails?.resource_id) ? resourcesJob.map((i) => {
                    return (
                      <MenuItem value={i?.job?.id}>
                        {i?.job?.name || ''}
                      </MenuItem>
                    );
                  }) : []}
                </Select>
              </Box>
            </Stack>
              <Box width={"100%"} marginTop={'10px'}>
                <Typography>Select Status</Typography>
                <Select
                  value={newTaskDetails.status}
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select"
                  required
                  error={errorObj["status"] || false}
                  sx={{
                    background: "white",
                    "&:selected": { background: "white" },
                  }}
                  label="Select Status"
                  disabled={newTaskDetails.id}
                  onChange={(e) => setDetails(e.target.value, "status")}
                >
                  {Object.keys(taskStatueses).map((i) => {
                    return (
                      <MenuItem value={taskStatueses[i]}>
                        {taskStatueses[i]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            <CustomInput
              fullWidth
              required
              error={errorObj["name"] || false}
              label="Title"
              parentClassName="mt-2"
              // disabled={newTaskDetails.id}
              value={newTaskDetails.name}
              placeholder="Enter the title"
              onChange={(e) => setDetails(e.target.value, "name")}
            />
            <Box marginTop={"10px"}>
              <Typography>Description</Typography>
              <MyEditor
                // label="Description"
                height={200}
                // disabled={newTaskDetails.id}
                html={newTaskDetails.description}
                // placeholder="Enter the description"
                updateRaw={(e) => setDetails(e, "description")}
              />
            </Box>
            {/* <Box marginTop={'60px'}>
            </Box> */}
            <Stack
              flexDirection="row"
              gap={"10px"}
              marginBottom={'10px'}
              marginTop={"50px"}
              justifyContent={'space-between'}
              alignItems={"center"}
            >
              {/* <TextField
                type="date"
                fullWidth
                label="Start date"
                required
                error={errorObj['start_date'] || false}
                disabled={newTaskDetails.id}
                value={newTaskDetails.start_date}
                placeholder="Enter the start date"
                onChange={(e) => setDetails(e.target.value, "start_date")}
              /> */}
                <DesktopDatePicker
                  sx={{ width: "100%", overflow: "none" }}
                  format="MM-DD-YYYY"
                  label="Start date *"
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    newTaskDetails.start_date
                      ? dayjs(newTaskDetails.start_date)
                      : null
                  }
                  onChange={(e) => setDetails(e, "start_date")}
                  minDate={!!newTaskDetails.id ? null : dayjs()}
                />
                <DesktopDatePicker
                  sx={{ width: "100%", overflow: "none" }}
                  format="MM-DD-YYYY"
                  required
                  disabled={!newTaskDetails?.start_date}
                  label="End date"
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    newTaskDetails.end_date
                      ? dayjs(newTaskDetails.end_date)
                      : null
                  }
                  onChange={(e) => setDetails(e, "end_date")}
                  minDate={dayjs(newTaskDetails?.start_date)}
                />
            </Stack>
            <ReactDropzone setIsUploading={setUploading} files={newTaskDetails.files} setDetails={setDetails} />
            {/* <Stack
              flexDirection="row"
              gap={"10px"}
              alignItems={"center"}
              marginTop={"20px"}
            >
              <Box width={"50%"}>
                <Typography>Select Resource*</Typography>
                <Select
                  value={newTaskDetails.resource_id}
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select"
                  required
                  disabled={newTaskDetails.id}
                  error={errorObj["resource_id"] || false}
                  sx={{
                    background: "white",
                    "&:selected": { background: "white" },
                  }}
                  label="Select Job"
                  disabled={newTaskDetails.id}
                  onChange={(e) => setDetails(e.target.value, "resource_id")}
                >
                  {resources.map((i) => {
                    return (
                      <MenuItem value={i?.consultant?.id}>{i?.consultant?.firstName || ''} {i?.consultant?.lastName || ''}</MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box width={"50%"}>
                <Typography>Select Status*</Typography>
                <Select
                  value={newTaskDetails.status}
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select"
                  required
                  error={errorObj["status"] || false}
                  sx={{
                    background: "white",
                    "&:selected": { background: "white" },
                  }}
                  label="Select Status"
                  disabled={newTaskDetails.id}
                  onChange={(e) => setDetails(e.target.value, "status")}
                >
                  {Object.keys(taskStatueses).map((i) => {
                    return (
                      <MenuItem value={taskStatueses[i]}>
                        {taskStatueses[i]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Stack> */}
            {/* {profile?.role !== "CONSULTANT" && ( */}
              <Button
                parentClassName="!w-full justify-end mt-2"
                onClick={addNewTaskHandler}
                disabled={isUploading}
                title={newTaskDetails?.id ? "Update Task" : "Create new task"}
              />
            {/* )} */}
          </FormControl>
        </MuiModal>
      </Paper>
    </Box>
  );
};
export default TaskWrapper;
