import {
  Box,
  Button, Grid, MenuItem, Paper, Select, Stack, Typography,
} from '@mui/material';
import { pdf } from "@react-pdf/renderer";
import { getInvitedId, getInvoiceApi, updateInvoice } from 'Components/InvoiceCreateForm/api';
import { PublicUserInfo } from 'Pages/Auth/Helper';
import ShareLinkModal from 'Pages/DashboardVendor/components/invoices/ShareLinkModal';
import { useInvoiceProvider } from 'Providers/Vendor/invoice';
import { selectUserDetails, setUser } from 'Redux/Feature/user';
import api from 'Utils/api';
import axios from 'axios';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { LocalStorageService } from 'services/localstorage.service';
import { createPaymentAccount, createPaymentAccountLink, createPaymentLink } from 'services/payments';
import Invoice from './Invoice';

import { roleHelper } from 'Components/Helper';
import InvoicePdf from 'Pages/InvoicesPage/JobsBasedInvoice/InvoicePdf';
import { useAlertProvider } from 'Providers/util/Alert';
import { isStatusEqualTo } from 'Utils/common.utils';
import { DEFAULT_PAYMENT_OPTION, INVOICE_PAYMENT_OPTIONS, statuses } from 'Utils/constants';
import OfflineModePayModal from './OfflineModePayModal';
import PublicViewModal from './PublicSignupModal';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const [onlineMode, offlineMode] = INVOICE_PAYMENT_OPTIONS;
const localStorageService = new LocalStorageService();

export const headerKeys = {
  fixed: ['Job', 'Resource', 'Working Days', 'Rate', 'Hours', 'Amount'],
  flexible: ['Project', 'Amount', 'Ongoing Jobs'],
  jobs: ['Job Name', 'Amount'],
}

export default function InvoicePreview({ isPublic = false }) {
  const userDetails = useSelector(selectUserDetails);
  const params = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [localUser, setLocalUser] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [jobsOfInvoice, setJobsOfInvoice] = useState([])
  const [clientDetails, setClientDetails] = useState({})
  const [vendorDetails, setVendorDetails] = useState({})
  const dispatch = useDispatch();
  const { createInvoiceWithLink } = useInvoiceProvider();
  const [selectedMode, setSelectedMode] = useState(DEFAULT_PAYMENT_OPTION.value)
  const [invoiceLink, setInvoiceLink] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const [signUpModal, setSignUpModal] = useState(false);

  const isInvoiceSentTab = searchParams.get('isInvoiceSentTab') === 'true';

  const printDocument = async (type = 'fixed') => {
    const fetchProfilePhotoAsBlob = async (url) => {
      const response = await fetch(url,  { mode: 'cors', cache: 'no-cache' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return blob;
    };
  
    const convertBlobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    let updatedVendorDetails = { ...vendorDetails }
  
    if (vendorDetails?.profilePhoto) {
      const profilePhotoBlob = await fetchProfilePhotoAsBlob(vendorDetails.profilePhoto);
      const profilePhotoBase64 = await convertBlobToBase64(profilePhotoBlob);
    
      updatedVendorDetails = {
        ...vendorDetails,
        profilePhoto: profilePhotoBase64,
      };
    }
  
    const doc = (
      <InvoicePdf
        invoiceDetails={invoiceDetails}
        jobsOfInvoice={jobsOfInvoice}
        clientDetails={clientDetails}
        vendorDetails={updatedVendorDetails}
        type={type}
        headersKeys={headerKeys[type]}
      />
    );
  
    const asPdf = pdf();
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
  
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `INVOICE_${invoiceDetails?.invoiceno || 'invoice'}.pdf`);
    document.body.appendChild(link);
    link.click();  
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  };
  


  const { isClient, isVendor } = roleHelper.getRoleType(userDetails?.role);

  const navigate = useNavigate();
  const { success, error } = useAlertProvider();

  const fetchData = async () => {
    const searchParam = new URLSearchParams(window.location.search)
    if (searchParam.get('invited_id') && isPublic) {
      await getInvitedId(searchParam.get('invited_id'))
    }
    const resp = await getInvoiceApi(params.invoiceId, searchParam.get('type'))
    if (resp && resp.data && resp.data.result && resp.data.result.length > 0) {
      const invoice = resp.data.result[0] || {}
      setInvoiceDetails({ ...invoice, adjustment: JSON.parse(invoice.adjustment) })
      if (resp.data.result[0] && resp.data.result[0]['invoice_type'] === "FLEXIBLE") {
        if (resp.data.result[0]['invoice_category'] === 'JOB') {
          setJobsOfInvoice([resp.data.result[0]['job']] || [])
          setClientDetails(resp.data.result[0]['projects'][0]['client'])
          setVendorDetails(resp.data.result[0]['projects'][0]['vendor'])
        } else {
          setJobsOfInvoice(resp.data.result[0]['projects'] || [])
          setClientDetails(resp.data.result[0]['projects'][0]['client'])
          setVendorDetails(resp.data.result[0]['projects'][0]['vendor'])
        }
        invoice.payment_type && setSelectedMode(invoice.payment_type);
      } else {
        if (resp.data.result[0] && resp.data.result[0]['jobs'].length > 0) {
          setJobsOfInvoice(resp.data.result[0]['jobs'] || [])
          setClientDetails(resp.data.result[0]['jobs'][0]['client'])
          setVendorDetails(resp.data.result[0]['jobs'][0]['vendor'])
          invoice.payment_type && setSelectedMode(invoice.payment_type);
        }
      }
    }
  }

  async function fetchProfileDetails() {
    try {
      const { data } = await PublicUserInfo({
        userId: localStorageService.getUserId(),
      });
      dispatch(setUser(data.result));
      setLocalUser(data.result)
    } catch (error) {
      console.error("fetchProfileDetails error :>>", error);
    }
  }

  async function generatePDF() {
    const element = document.getElementById('divToPrint');
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "JPEG", 0, 0);
    const blob = pdf.output('blob');
    return blob;
  }


  const userStripeAccountDetails = useMemo(() => {
    const { stripe_account_id, stripe_details_submitted } = userDetails;
    return {
      accountExists: !!stripe_account_id,
      isConnected: stripe_details_submitted,
      stripe_account_id,
      isValid: stripe_account_id && stripe_details_submitted,
    };
  }, [userDetails]);

  const openAccountLink = async (account_id) => {
    const { data } = await createPaymentAccountLink(account_id, {
      refresh_url: window.location.href,
      return_url: window.location.origin + '/dashboard/invoices',
    });
    window.location.href = data.url;
  };

  const updateInvoicePaymentType = async (payload) => {
    const resp = await updateInvoice(invoiceDetails?.id, payload);

    const successMessage = `Invoice ${isClient ? "Paid" : "Sent"} successfully`;
    if (resp && resp.data.success) success(successMessage);
    else error("Something went wrong!!!");
    toInvoices()
  };

  const isPaymentModeOffline = useMemo(
    () => selectedMode === offlineMode.value,
    [selectedMode]
  );

  const isInvoicePaid = useMemo(() => isStatusEqualTo(invoiceDetails.status, statuses.invoices.paid), [invoiceDetails])

  const toInvoices = () => {
    setTimeout(() => navigate("/dashboard/invoices"), 200);
  };
  const verifyAndCreateStripeAmount = async () => {
    try {
      setIsLoading(true);

      // Handle offline payment mode
      if (
        (userStripeAccountDetails.isValid || isPaymentModeOffline) &&
        isVendor
      ) {
        await updateInvoicePaymentType({
          status: "SUBMITTED"
        });
        return;
      }

      // Handle online payments
      if (!userStripeAccountDetails.isValid) {
        // Handle the account creation
        const { data: account } = await createAccount();
        await openAccountLink(account?.id);
      }
    } catch (error) {
      console.error("error :>> ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createAccount = () => {
    const payload = { country: "US" };
    return createPaymentAccount(payload);
  };

  const uploadInvoicePdf = async () => {
    const blob = await generatePDF();
    const invoiceFile = new File([blob], "InvoicePdf");

    try {
      const upload = await api.get(
        `/user/getpresigneduploadurl?filename=${`${invoiceDetails?.invoiceno}.pdf`}&filetype=${invoiceFile.type
        }&location=invoices`
      );

      if (upload?.data?.success && upload?.data?.result) {
        const formData = new FormData();
        formData.append("file", invoiceFile);
        const s3_res = await axios.put(upload?.data?.result, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (s3_res.status === 200) {
          createInvoiceWithLink({
            invoiceId: invoiceDetails?.invoiceno,
            invoiceLink: upload?.data?.key,
          })
          setInvoiceLink(upload?.data?.key);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (Object.keys(invoiceDetails)?.length > 0 && !invoiceLink) {
      uploadInvoicePdf()
    }
  }, [invoiceDetails])

  useEffect(() => {
    fetchData();
    // fetchProfileDetails();
  }, [])

  const initiateInvoicePayment = async (account_id) => {
    const { data } = await createPaymentLink(account_id, {
      unit_amount: invoiceDetails.amount,
      invoice_id: invoiceDetails.id,
      cancel_url: `${window.location.origin}/dashboard/invoices`,
      success_url: `${window.location.origin}/dashboard/invoices`,
      currency: invoiceDetails.currency_code?.toLowerCase(),
      // job_id: invoiceDetails.jobId,
      client_id: localUser.id,
      vendor_id: vendorDetails.id,
      payee_account_id: vendorDetails?.stripe_account_id,
    });

    window.location.href = data.url;
  };

  const payInvoiceAmount = async () => {
    try {
      setIsLoading(true);
      // Updated the status of invoice as paid 
      if (isPaymentModeOffline) {
        await updateInvoicePaymentType({ status: statuses.invoices.paid, payment_type: selectedMode});
        return;
      }

      const { stripe_details_submitted, stripe_account_id } = localUser;

      if (!stripe_account_id) {
        const { data: account } = await createAccount();
        await openAccountLink(account?.id);
      } else if (stripe_details_submitted && stripe_account_id) {
        await initiateInvoicePayment(stripe_account_id);
      } else if (stripe_account_id && !stripe_details_submitted) {
        await openAccountLink(stripe_account_id);
      }
    } catch (error) {
      console.error('error :>> ', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid item xs={16}>
      {/* <InvoiceDocument /> */}
      <OfflineModePayModal openModal={openModal} setOpenModal={setOpenModal} handlePayFunction={(isClient || isVendor) && payInvoiceAmount} vendorDetails={vendorDetails} />
      {!signUpModal && searchParams.get('src') === 'sharedInvoice' && !Object.keys(userDetails).length &&
        <Box onClick={() => setSignUpModal(true)} sx={{
          position: 'absolute',
          zIndex: 1200,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }}>

        </Box>
      }
      <PublicViewModal clientDetails={clientDetails} open={signUpModal} setSignUpModal={setSignUpModal} />
      <Paper
        elevation={1}
        sx={{
          p: 3,
          borderRadius: "8px",
          width: "100%",
          paddingBottom: "200px",
          ...(isPublic && { position: 'relative', zIndex: 1201 })
        }}
      >
        <Typography sx={{ cursor: 'pointer', pb: "1rem" }} onClick={()=> navigate("/dashboard/invoices")}>
        <ArrowBackIcon
          sx={{
            background: "#F3F4F6",
            width: "30px",
            marginRight: "20px",
            height: "30px",
            borderRadius: "8px",
          }}
        />
        Back
      </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="cardHeader">Preview invoice</Typography>
          <Button
            variant="outlined"
            sx={{ background: "#0B85BA", color: "#F9FAFB" }}
            onClick={() => printDocument(invoiceDetails?.invoice_type === "NORMAL" ? 'fixed' : invoiceDetails?.invoice_category !== "JOB" ? 'flexbile' : 'jobs')}
          >
            Download PDF
          </Button>
        </Stack>
        {invoiceDetails?.invoice_type && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {invoiceDetails?.invoice_type === "NORMAL" && invoiceDetails?.invoice_category !== "JOB" ? (
              <Invoice
                invoiceDetails={invoiceDetails}
                jobsOfInvoice={jobsOfInvoice}
                clientDetails={clientDetails}
                vendorDetails={vendorDetails}
                headerKeys={headerKeys.fixed}
              />
            ) : invoiceDetails?.invoice_category !== "JOB" ? (
              <Invoice
                invoiceDetails={invoiceDetails}
                jobsOfInvoice={jobsOfInvoice}
                clientDetails={clientDetails}
                vendorDetails={vendorDetails}
                headerKeys={headerKeys.flexible}
                type='flexible'
              />
            ) : (
              <Invoice
                invoiceDetails={invoiceDetails}
                jobsOfInvoice={jobsOfInvoice}
                clientDetails={clientDetails}
                vendorDetails={vendorDetails}
                headerKeys={headerKeys.jobs}
                type='jobs'
              />
            )}
          </Box>
        )}
      </Paper>
      <Box
        sx={{
          width: "100%",
          padding: "30px",
          display: "flex",
          justifyContent: "flex-end",
          background: "white",
          position: "fixed",
          gap: "10px",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {!isInvoicePaid && selectedMode === onlineMode.value && (
          <Typography
            variant="body2Semibold"
            textAlign={"right"}
            display={"flex"}
            alignItems={"end"}
          >
            {!userStripeAccountDetails.isValid && (
              <div className="invoice-payment-message">
                You will be redirected to connect your bank account with
                Prolegion.
              </div>
            )}

            {isClient && userStripeAccountDetails.isValid && (
              <div className="invoice-payment-message">
                You will be redirected to payment page for invoice payment.
              </div>
            )}

            {isVendor && (
              <div className="invoice-payment-message">
                &nbsp; Invoice will be sent to
                <strong style={{ fontWeight: 900 }}>
                  {clientDetails?.email}
                </strong>
              </div>
            )}
          </Typography>
        )}

        {!isInvoicePaid && !isPublic && (
          <>
            <Select
              defaultValue={DEFAULT_PAYMENT_OPTION.value}
              value={selectedMode}
              onChange={(e) => setSelectedMode(e.target.value)}
              sx={{ width: "10%", marginLeft: "20px" }}
            >
              {INVOICE_PAYMENT_OPTIONS.map((mode) => {
                return <MenuItem value={mode.value} disabled={mode.value === "online"}>{mode.label}</MenuItem>;
              })}
            </Select>
            {selectedMode === offlineMode.value && isVendor && (
              <ShareLinkModal
                invoiceId={invoiceDetails?.id}
                setLoading={() => { }}
              />
            )}
            {/* <OfflineModeModal
              invoiceId={invoiceDetails?.id}
              isOpen={isOfflineMode}
              toInvoices={toInvoices}
              isClient={isClient}
              onClose={() => setIsOfflineMode(false)}
            /> */}

            {isVendor && isInvoiceSentTab && (
              <Button
                variant="contained"
                onClick={verifyAndCreateStripeAmount}
                disabled={isLoading}
                sx={{ background: "#0B85BA", marginLeft: "20px" }}
              >
                Send Invoice
              </Button>
            )}

            {isVendor && userDetails?.user_id === clientDetails?.id && (
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => setOpenModal(true)}
                sx={{ background: "#0B85BA", marginLeft: "20px" }}
              >
                Pay
              </Button>
            )}

            {isClient && (
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => setOpenModal(true)}
                sx={{ background: "#0B85BA", marginLeft: "20px" }}
              >
                Pay
              </Button>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
}
