import { Box, Tab, Tabs } from "@mui/material";
import { CustomTable } from "Components";
import { profileTableColumns } from "Pages/ResourcesPage/ResourcesTable/ResourcesTable.columns";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserDetails, selectUserRole } from "Redux/Feature/user";
import { CustomTabPanel, a11yProps } from "../../Components/Common/CustomTabs";
import { roleHelper } from "../../Components/Helper";
import ProfileReferencesTab from "./ProfileReferences.tab";
import ProfileServicesTab from "./ProfileServices.tab";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ResourcesToogle from "./ResourcesSwitch";
import SignInMessageBox from "./SignInMessageBox";
import Typography from "Components/Common/semantic_tags/Typography";
import { Card } from "Pages/editProfile/Experience";
import { EducationCard } from "Pages/editProfile/Education";
import ShowProfileVideo from "Pages/Dashboard/components/ShowProfileVideo";

export default function ProfileTabs({ currentTab, setCurrentTab, details, setDetails, referenceAPI, setReferenceAPI, setPopupMessage }) {
  const { isClient, isConsultant, isVendor } = roleHelper.getRoleType(
    details?.role
  );
  const userDetails = useSelector(selectUserDetails);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const role = useSelector(selectUserRole);
  const [toggleIndex, setToggleIndex] = useState(null);

  useMemo(() => {
    if (isVendor && referenceAPI) {
      setCurrentTab(3);
    }
  }, [isVendor, referenceAPI])

  const handleChangeTab = (event, newValue) => {
    setReferenceAPI(false);
    setCurrentTab(newValue);
  };


  function isTabPubliclyVisible(tabName) {
    if (details) return true;
    else return details[tabName];
  }

  const resources = useMemo(() => {
    return (details?.resources || []).map((resource) => {
      return ({
        firstName: resource?.first_name,
        lastName: resource?.last_name,
        ...resource,
      })
    });

  }, [details]);

  const isLoggedIn = useMemo(()=> {
    return !!userDetails?.user_id;
  },[userDetails]);

  const aboutProfileText = useMemo(() => {
    return new DOMParser().parseFromString(details?.about, "text/html");
  }, [details?.about])

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
        p: {xs: 2, md: 3},
        pt: {xs: 0, md: 1},
        boxShadow: "0px 0px 16px 0px rgba(17, 17, 26, 0.05)",
        height: {xs: "fit-content", md: "100%"},
      }}
    >
      <Box>
        <Tabs
          value={currentTab}
          aria-label="Vendor Profile Sections"
          onChange={handleChangeTab}
        >
          {isClient && <Tab label="About" {...a11yProps(0)} />}
          {isVendor && [
            <Tab label="About" {...a11yProps(0)} />,
            <Tab label="Services & Skills" {...a11yProps(1)} />,
            ...(!isLoggedIn || userDetails?.user_id === details?.user_id || details?.are_resources_public && !role.isConsultant
              ? [<Tab label="Resources" {...a11yProps(2)} />]
              : []),
            ...(!isLoggedIn || userDetails?.user_id === details?.user_id || details?.are_references_public && !role.isConsultant
              ? [<Tab label="References" {...a11yProps(3)} />]
              : []),
          ]}
          {isConsultant && [
            <Tab sx={{minWidth: { xs: "100px !important", md: "140px !important" }}} label="About" {...a11yProps(0)} />,
            <Tab sx={{minWidth: { xs: "100px !important", md: "140px !important" }}} label="Experience" {...a11yProps(1)} />,
          ]}
        </Tabs>
      </Box>
      {isClient && (
        <CustomTabPanel value={currentTab} index={0}>
          {!details?.about && (
            <Typography className="text-sm">
              No Information Provided
            </Typography>
          )}
          <Box
            dangerouslySetInnerHTML={{ __html: details?.about }}
            sx={{ typography: "body1Normal", color: "#6B7280", wordWrap: "break-word" }}
          />
        </CustomTabPanel>
      )}
      {isVendor && [
        <CustomTabPanel value={currentTab} index={0}>
          {!details?.about && (
            <Typography className="text-sm">
              No Information Provided
            </Typography>
          )}
          <Box
            dangerouslySetInnerHTML={{ __html: details?.about }}
            sx={{ display: "flex", flexDirection: "column",typography: "body1Normal", color: "#6B7280", wordWrap: "break-word"}}
          />
        </CustomTabPanel>,
        <CustomTabPanel value={currentTab} index={1}>
          <ProfileServicesTab details={details} />
        </CustomTabPanel>,
        [
              <CustomTabPanel value={currentTab} index={2}>
                {(userDetails?.user_id === details?.user_id) && <ResourcesToogle
                  type="are_resources_public"
                  profile={details}
                />}
                {(userDetails?.user_id === details?.user_id || details?.are_resources_public && !role.isConsultant && isLoggedIn) ? <CustomTable
                  paginationDisplayMode="pages"
                  columns={[
                    ...profileTableColumns,
                    {
                      accessorKey: "consultant",
                      header: "Skills",
                      Cell: ({ cell, row }) => {
                        const toggleShowIndex = (index) => {
                          if (index === toggleIndex) {
                            setToggleIndex(null);
                          } else {
                            setToggleIndex(index);
                          }
                        }
                        const toggle = row.index === toggleIndex;

                        return <Typography variant="body2Semibold">
                          {toggle
                            ? row.original?.skills?.join(", ")
                            : row.original?.skills?.slice(0, 3).join(", ")
                          }
                        {row.original?.skills.length > 3 &&
                          <Typography
                            sx={{color: "#0B85BA", cursor: "pointer", "&:hover": {textDecoration: "underline"}}}
                            onClick={() => toggleShowIndex(row.index)}
                          >
                            {toggle ? " less..." : " more..."}
                          </Typography>}
                        </Typography>
                      },
                    },
                  ]}
                  data={resources}
                /> : <>{!isLoggedIn && <Box sx={{display: "flex", justifyContent: "center"}}><SignInMessageBox message={"resources"}/></Box>}</>}
              </CustomTabPanel>,
            ],
            [
              <CustomTabPanel value={currentTab} index={!details?.are_resources_public && userDetails?.user_id !== details?.user_id && isLoggedIn ? 2 : 3}>
                {(userDetails?.user_id === details?.user_id) && <ResourcesToogle
                  type="are_references_public"
                  profile={details}
                />}
              {(userDetails?.user_id === details?.user_id || details?.are_references_public && !role.isConsultant && isLoggedIn) ? <ProfileReferencesTab setDetails={setDetails} details={details} setReferenceAPI={setReferenceAPI} /> : <>{!isLoggedIn && <Box sx={{display: "flex", justifyContent: "center"}}><SignInMessageBox message={"references"}/></Box>}</>}
            </CustomTabPanel>,
          ]
      ]}
      {isConsultant && [
        <CustomTabPanel value={currentTab} index={0}>
          {!details?.about && (
            <Typography className="text-sm">
              No Information Provided
            </Typography>
          )}
          <div className="min-[300px]:flex min-[300px]:flex-col md:block">
            {details.profile_video_url && <div className='float-left min-[300px]:pb-4 md:pb-0 md:mr-4'>
              <div className="relative min-[300px]:w-full md:w-[50px] md:h-[50px] cursor-pointer  " onClick={handleOpen}>
                <img
                  src={'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/3755670.jpg'}
                  alt="Profile Video Thumbnail"
                  className="w-full h-full object-cover rounded-lg cursor-pointer"
                  onClick={handleOpen}
                />
                <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                <div
                  className="absolute min-[300px]:top-[45%] min-[300px]:left-[45%] md:top-[40%] md:left-[40%] min-[300px]:w-12 min-[300px]:h-12 md:w-4 md:h-4 bg-white rounded-full flex justify-center items-center z-[99] cursor-pointer"
                  onClick={handleOpen}
                >
                  <PlayArrowIcon sx={{fontSize:{xs: "30px", sm: "12px", md: "12px"}}} />
                </div>
              </div>
            </div>}
              <Box
                dangerouslySetInnerHTML={{ __html: details?.about }}
                sx={{ typography: "body1Normal", color: "#6B7280", textAlign: 'justify', fontSize: {xs: "14px", md: "unset"} }}
              />
            </div>
          {details?.profile_video_url && <ShowProfileVideo profile_video_url={details?.profile_video_url} open={open} handleClose={handleClose} />}
          {details?.education?.length > 0 && <Typography variant="h2" className="border-t-[1px] mt-5 pt-4 text-xl text-[#5D5D5D]">Education</Typography>}
          <div className="pl-6 pt-4">
            {(details?.education || []).map((item, index) => (
              <EducationCard
                key={index}
                isLast={index === details?.education?.length -1}
                item={item}
                edit={false}
              />
            ))}
          </div>
        </CustomTabPanel>,
        <CustomTabPanel value={currentTab} index={1}>
          {
            details?.experiences && details?.experiences?.length === 0
            ?  <Typography className="text-sm">
            No {isConsultant ? "Experience" : "References"} Added
          </Typography>
          : details?.experiences?.map((item) => {
            return <div className="border-l-2 ml-4">
              <Card item={item} edit={false} />
              </div>
          })
          }
        </CustomTabPanel>,
      ]}
    </Box>
  );
}
