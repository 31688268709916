import React from 'react';

const MuiModal = ({
  open,
  onCancel,
  zIndex = 999,
  styling = {},
  children,
  maxWidth = 'sm',
  className = 'max-w-xl',
  parentClassName='',
  divClassName = '',
  title = ''
}) => {
  if (!open) return null;
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 a-[${maxWidth}] ${divClassName}`}
      style={{ backdropFilter: 'blur(5px)', zIndex }}
    >
      <div className={`bg-white rounded-lg shadow-lg w-full min-[300px]:m-4 md:m-0 relative ${className}`} style={styling}>
        {onCancel &&
        <button
          onClick={onCancel}
          className="absolute bg-[#E7F0FA] border-2 border-[#FFFFFF] w-[40px] h-[40px] flex justify-center items-center rounded-full top-[-10px] right-[-10px] text-gray-500 hover:text-gray-700"
        >
          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#006A98">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        }
        <div className={`p-6 ${parentClassName}`}>
          {title && (
            <h2 className="text-xl font-bold text-gray-800 mb-6">{title}</h2>
          )}
          <div className='max-h-[80vh] overflow-y-scroll'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MuiModal;
