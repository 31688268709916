import React from 'react'
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedDetails } from "../../Redux/Feature/profile/profile.slice";
import AddTabs from "./common/AddTabs";
import { selectProfileDetails } from "../../Redux/Feature/profile/profile.selector";
import Heading1 from "Components/Common/semantic_tags/Heading1";
import Button from "Components/Common/semantic_tags/Button";
import { useState } from "react";

const max = 16

const ServicesComponent = () => {
    const profile = useSelector(selectProfileDetails);
    const services = profile?.services || [];
    const [tabVal, setTabVal] = useState('');
    const dispatch = useDispatch();

    const addServices = (val) => {
        if (val) {
            dispatch(setUpdatedDetails({ services: [...services, val] }));
        }
    };

    const removeServices = (index) => {
        const updatedServices = [...services];
        updatedServices.splice(index, 1);
        dispatch(setUpdatedDetails({ services: updatedServices }));
    };

    return (
        <Box>
            <Heading1 className="text-lg font-semibold">Services (up to 16)</Heading1>
            <div className="mt-8 flex gap-8">
                <input
                    className="w-2/5 p-2 border border-gray-300 rounded"
                    value={tabVal}
                    onChange={(e) => setTabVal(e.target.value)}
                    placeholder={'Eg: SAP Web development'}
                />
                <Button
                    type="button"
                    disabled={services.length === max}
                    className={`px-4 py-2 rounded text-white ${services.length === max ? 'bg-gray-300' : 'bg-primary-600'
                        }`}
                    onClick={() => {
                        addServices(tabVal);
                        setTabVal('');
                    }}
                    title={'Add Services'}
                />
            </div>
            {["VENDOR", "CLIENT"].includes(profile.role) && (
                <AddTabs
                    style={{ marginBottom: "40px" }}
                    tabs={services}
                    removeTabs={removeServices}
                />
            )}
        </Box>
    );
};

export default ServicesComponent;
