import { Box, Stack, Typography } from "@mui/material"
import Button from "Components/Common/semantic_tags/Button"

const TaskHeader = ({
    openModal
}) => {
    return <Box>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant='cardHeader'>Task List</Typography>
            <Button onClick={openModal} title='Add new task' />
        </Stack>
    </Box>
}

export default TaskHeader