import React from "react";
import { Box, Typography } from "@mui/material";

export default function PrivacyPolicyPage() {
  return (
    <>
      {/* <PageHeader title="Privacy Policy" /> */}
      <Box sx={{ height: "100%", marginTop: "10px", padding: {
        "md": "30px 10rem",
        "xs": "30px 2rem"
      } }}>
        <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
          <Typography
            fontWeight={"bolder"}
            lineHeight={"normal"}
            textAlign={"center"}
            variant="defaultFont"
            sx={{
              color: "#111928",
              fontSize: {
                xs: "30px",
                md: "40px",
              },
            }}
          >
            Privacy Policy
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            flexGrow: 1,
            p: 3,
            typography: "body2",
            textAlign: "justify",
          }}
        >
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            This Privacy Policy governs the manner in which www.prolegion.com
            collects, uses, maintains and discloses information collected from
            users (each, a “User”) of the www.prolegion.com website (“Site.") By
            viewing, accessing, using or browsing the Website, or by registering
            as a member of the Service (as defined herein), you agree to this
            privacy policy and the Terms and Conditions of said web property as
            a binding legal agreement between you and www.prolegion.com, without
            limitation or qualification.
          </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}></Box>
          <Typography
            marginTop={"20px"}
            fontWeight={"bolder"}
            color={"#111928"}
            fontSize={"18px"}
            padding={"1rem 0"}
            variant="defaultFont"
          >
            Personal identification information
          </Typography>
          <br />
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            www.prolegion.com keeps track of non-personally identifiable
            information such as the IP address of the User’s computer, time of
            access, browser type and files downloaded to gather usage statistics
            for its web site. IP addresses are not associated with other
            information about a user, but only indicate general visitor
            navigation patterns. www.prolegion.com website has embedded tags
            that allow some third party entities such as Quantcast, Google
            Analytics, and/or similar services to collect non-personally
            identifiable usage statistics.
          </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}></Box>
          <Typography
            marginTop={"20px"}
            fontWeight={"bolder"}
            color={"#111928"}
            fontSize={"18px"}
            padding={"1rem 0"}
            variant="defaultFont"
          >
            Submission and Processing of Information
          </Typography>
          <br />
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            When you subscribe or sign up to the Website, www.prolegion.com may
            ask you for certain personally identifiable information. This
            personally identifiable information may include information that can
            be used to identify or contact you, such as your name and email
            address. You must agree to the Terms and Conditions of this website
            before making any comments or leaving any reviews on any posting.
          </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}></Box>
          <Typography
            marginTop={"20px"}
            fontWeight={"bolder"}
            color={"#111928"}
            fontSize={"18px"}
            padding={"1rem 0"}
            variant="defaultFont"
          >User Postings</Typography>
          <br />
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            Except as otherwise expressly described in this Privacy Policy, this
            policy only addresses the use and disclosure of Personal Information
            and other information we collect from you. In the course of your use
            of the Website, including when you create a profile on the Website,
            you may elect to post or otherwise disclose information about
            yourself and others, which may include Personal Information or other
            personally identifiable information. You may also elect to
            communicate with others or upload content such as photographs. Such
            postings are governed by our Terms and Conditions. Whenever you
            voluntarily disclose personal information on publicly-viewable web
            pages, that information will be publicly available and can be
            collected or used by others. Such postings may also appear on other
            websites or when searches are executed on the subject of your
            posting. Furthermore, although www.prolegion.com restricts access to
            portions of the Website to members of the Service, since the Service
            is offered through and accessible through a number of other websites
            in addition to the Website, any information that you post to the
            Website for access by members of the Website may be searchable and
            available to users of the Service who access it via different
            websites from the Website. You may also receive unsolicited messages
            from other parties as a result of such disclosure. Such activities
            are beyond the control of www.prolegion.com and this Privacy Policy.
            You should therefore exercise discretion and extreme caution when
            disclosing any Personal Information or other information when using
            the Website or the Service.
          </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}></Box>
          <Typography
            marginTop={"20px"}
            fontWeight={"bolder"}
            color={"#111928"}
            fontSize={"18px"}
            padding={"1rem 0"}
            variant="defaultFont"
          >
            Third Party Websites
          </Typography>
          <br />
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            The Website may contain links to other websites. www.prolegion.com
            is not responsible for the privacy practices of such websites. It is
            the User's responsibility to review the privacy policies at those
            websites to confirm that they are acceptable prior to use. A link
            does not constitute an endorsement of the content, viewpoint or
            accuracy of such websites.
          </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}></Box>
          <Typography
            marginTop={"20px"}
            fontWeight={"bolder"}
            color={"#111928"}
            fontSize={"18px"}
            padding={"1rem 0"}
            variant="defaultFont"
          >
            Marketing Communications
          </Typography>
          <br />
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            If you have used the Website before, it is possible that
            www.prolegion.com has data about you already and it is being used to
            contact you about offers, events, items or related activities. If
            you are an existing customer, www.prolegion.com will continue to
            keep you informed unless you revoke your consent via the procedures
            set forth below. If you are a new user, www.prolegion.com will give
            you the choice (for example, once you have subscribed or signed up)
            whether or not you want to receive emails regarding future offers,
            events and new services or related activities. www.prolegion.com
            will not bombard you with emails, but www.prolegion.com, with your
            consent, would like to keep you informed of any developments on the
            Website.
          </Typography>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            If you have consented to receive such information, you may always
            revoke that consent at any time by deleting your account.
          </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}></Box>
          <Typography
            marginTop={"20px"}
            fontWeight={"bolder"}
            color={"#111928"}
            fontSize={"18px"}
            padding={"1rem 0"}
            variant="defaultFont"
          >
            Confidentiality and Security of Personal Information
          </Typography>
          <br />
          <Box sx={{ marginTop: '10px' }}>
          <Typography variant="defaultFont">
            Transmission of data and information via the Website is not a secure
            or encrypted transmission method for sending your Personal
            Information, unless otherwise indicated on the Website. Accordingly,
            Effective as of January 03, 2018
          </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
