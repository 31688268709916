import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Avatar, Box, Chip, Divider, Grid, Stack, useMediaQuery } from "@mui/material";
import moment from "moment";
import { apiPath } from "Providers/Consultant/Connection";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUserDetails } from "Redux/Feature/user";
import api from "Utils/api";
import MuiModal from "../../Components/Common/MuiModal";
import { useAlertProvider } from '../../Providers/util/Alert';
import { stringAvatar } from "../../Utils/avatar";
import { submitApplication } from "./marketplace.api";
import SubmitProposal from "./SubmitProposal";
import Button from "Components/Common/semantic_tags/Button";
import Share from "Components/Share";
import Typography from "Components/Common/semantic_tags/Typography";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function MarketplaceCard({ updateCount, details: propDetails,  ...props }) {
  const { success, error } = useAlertProvider();
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const navigate = useNavigate()
  const [vendorDetails, setVendorDetails] = useState({});
  const [details, setDetails] = useState({
    profilePhoto: null,
    name: null,
    company: null,
    location: null,
    city: null,
    state: null,
    country: null,
    status: null,
    skills: null,
    description: null,
    firstName: null,
    lastName: null,
    open_for_job: null,
    industryExperience: null,
    createdAt: null,
    id: null,
    parent_job_id: null,
    created_by: null,
    applicant_count: null,
  });

  const closeModal = () => setOpenSubmitModal(false)
  const openModal = () => setOpenSubmitModal(true)

  useEffect(() => {
    if (props.type === "Jobs") {
      setDetails({
        profilePhoto: propDetails?.user?.profilePhoto,
        name: propDetails?.name,
        company: propDetails?.user?.company,
        location: propDetails?.location,
        city: propDetails?.city,
        state: propDetails?.state,
        country: propDetails?.country,
        status: propDetails.status,
        skills: propDetails?.main_skill,
        description: propDetails?.description,
        createdAt: propDetails?.createdAt,
        id: propDetails?.id,
        parent_job_id: propDetails?.parent_job_id,
        created_by: propDetails?.created_by,
        applicant_count: propDetails?.applicant_count,
        ...propDetails
      });
    } else {
      setDetails({
        profilePhoto: propDetails?.user?.profilePhoto,
        firstName: propDetails?.user?.firstName,
        lastName: propDetails?.user?.lastName,
        city: propDetails?.user?.city,
        state: propDetails?.user?.state,
        country: propDetails?.user?.country,
        open_for_job: propDetails?.open_for_job,
        industryExperience: propDetails?.user?.industryExperience,
        skills: propDetails?.user?.skills,
        ...propDetails
      });
    }
  }, [propDetails]);

  const userid = localStorage.getItem("userId");
  const userObj = useSelector(selectUserDetails)
  const getRole = userObj.role;
  const location = useLocation();
  const showAlertData = location?.state?.showAlert;
  const showAlertMessage = location?.state?.message;
  const severity = location?.state?.severity;
  const [showAlert, setShowAlert] = React.useState(showAlertData);

  if (showAlert && severity === 'success') {
    success(showAlertMessage);
  }
  if (showAlert && severity === 'error') {
    error('Something went wrong');
  }

  const fetchVendorDetails = async () => {
    if (userObj && userObj?.role === 'CONSULTANT') {
      const res = await api.get(
        apiPath.getVendorForConsultant + "?id=" + userid
      );
      setVendorDetails(res.data.result)
    }
  }

  useEffect(() => {
    fetchVendorDetails()
  }, [userObj])

  const applyJob = async (details) => {
    const respObj = {
      vendor_id: vendorDetails?.vendor_id,
      created_by: details.createdBy,
      job_id: details.id,
      applicantIds: [userid]
    }
    const resp = await submitApplication(respObj)
    if (resp.success) {
      success(resp.message || 'Job Application submitted Successfully')
      updateCount(details)
    } else error(resp.error || resp.message || 'Something went wrong!!!')
  }

  return (
    <Box
      sx={{
        width: '100%',
        // border: "1px solid #E5E7EB",
        borderRadius: "8px",
        padding: "20px",
        // height: "70vh",
        // overflowY: isMobile ? 'unset' : 'scroll'
      }}
    >
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "flex-start",
              justifyContent: "space-between",
              flexDirection: isMobile ? 'column' : 'row'
            }}
          >
            <Box
              sx={{ display: "flex", gap: "20px", alignItems: "flex-start", flexDirection: isMobile ? 'column' : 'row' }}
            >

              {details.vendorImg ? (
                <Avatar
                  alt="Profile"
                  src={propDetails?.vendorImg}
                  sx={{ width: 50, height: 50, borderRadius: '8px' }}
                />
              ) : (
                <Avatar
                  className="!text-xs"
                  {...stringAvatar(propDetails?.vendorName ?? "ProLegion User", {
                    width: 50,
                    height: 50
                  })}
                />
              )}
              {props.type === "Jobs" ? (
                <Box>
                  <Typography className="text-[#182743] font-bold text-2xl">
                    {details?.name}
                    <br />
                  </Typography>
                </Box>
              ) : (
                <Typography className="text-[#182743] font-bold text-2xl">
                  {`${propDetails?.user?.firstName} ${propDetails?.user?.lastName}`}
                </Typography>
              )}
            </Box>
            <Box>
              {(propDetails && props.type === "Jobs") ? (
                <Box
                  sx={{
                    display: isMobile ? 'unset' : "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {propDetails?.createdBy !== userObj?.user_id && <Box>
                    {getRole === "VENDOR" && (
                      <Box>
                        <Button
                          onClick={openModal}
                          title='Submit Applicant'
                        />
                      </Box>
                    )}
                    {getRole === "CONSULTANT" && (
                        !propDetails?.alreadyApplied
                        ? <Box>
                        <Button
                          onClick={() => applyJob(details)}
                          title='Apply Job'
                        />
                      </Box>
                      :<Typography className="text-xs font-bold text-[#3985B6] mt-3 flex gap-2 items-center"><CheckCircleIcon /> Already Applied</Typography>
                    )}
                    {!userid && (
                      <Box>
                        <Button
                          onClick={() => navigate('/signin')}
                          title='Apply Job'
                        />
                      </Box>
                    )}
                  </Box>}
                  <Typography variant="body2Medium" sx={{ marginTop: "10px" }}>
                    {details?.applicant_count
                      ? `${details?.applicant_count} Applicants`
                      : ``}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", alignItems: "flex-start" }}>
            <Typography className="text-[#6B7280]">
              {details?.vendorName}
            </Typography>
            {props.type === "Jobs" ? (
              <Typography
                variant="body2Normal"
                color="#6B7280"
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                className="min-w-[10%]"
              >
                <LocationOnIcon sx={{ fontSize: "15px" }} color="primary" />
                {details?.location === "REMOTE"
                  ? "Remote"
                  : `${details?.city}, ${details?.state}, ${details?.country}`}
              </Typography>
            ) : (
              <span className="min-w-[10%]">
                <LocationOnIcon sx={{ fontSize: "15px" }} color="primary" />
                {details.city || details.state || details.country ? (
                  <h1 className="inline">{`${details.city ? details.city + "," : ""
                    } ${details.state ? details.state + "," : ""} ${details.country ? details.country : ""
                    }`}</h1>
                ) : (
                  <h1 className="inline">Not disclosed</h1>
                )}
              </span>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
            {/* {props.type === "Jobs" ? (
              <Box>
                <FiberManualRecordIcon color="success" fontSize="10" />
                {details.status === "OPEN"
                  ? "Open"
                  : details.status === "HOLD"
                    ? "Hold"
                    : details.status === "ONGOING"
                      ? "Ongoing"
                      : details.status === "CLOSED"
                        ? "Closed"
                        : ""}
              </Box>
            ) : (
              <span className="min-w-[10%]">
                <FiberManualRecordIcon color="success" fontSize="10" />
                {details.open_for_job ? "Open" : "Closed"}
              </span>
            )} */}
          </Box>
        </Box>
        {details?.skills && (
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "20px", marginTop: "10px" }}
          >
            <Grid item xs={12}>
              <Stack direction="row" flexWrap={'wrap'} gap={'4px'}>
                {details?.skills
                  // .slice(0, Math.min(details?.skills.length, 4))
                  .map((skill, index) => (
                    <Chip
                      key={index}
                      sx={{ borderRadius: '6px', fontWeight: 600, border: "1px solid #9CA3AF", fontSize: '9px', padding: '0px', height: '20px' }}
                      label={skill}
                      variant="outlined"
                      clickable
                    />
                  ))}
              </Stack>
            </Grid>
            {/* {details?.skills.length > 4 && (
              <Grid item xs={12}>
                <Stack direction="row" flexWrap={'wrap'} gap={1} spacing={1}>
                  {details?.skills
                    .slice(4, Math.min(details?.skills.length, 7))
                    .map((skill, index) => (
                      <Chip
                        key={index}
                        sx={{
                          borderRadius: "8px", fontSize: '8px', padding: '2px',
                          border: "1px solid #9CA3AF",
                        }}
                        label={skill}
                        variant="outlined"
                        clickable
                      />
                    ))}
                  {details?.skills.length > 7 && (
                    <Grid item xs={12}>
                      <Stack direction="column" spacing={1}>
                        <Chip
                          sx={{
                            borderRadius: "8px", fontSize: '8px', padding: '2px',
                            border: "1px solid #9CA3AF",
                            width: "70px",
                          }}
                          label={`+${details?.skills.slice(7).length}`}
                          variant="outlined"
                          clickable
                        />
                      </Stack>
                    </Grid>
                  )}
                </Stack>
              </Grid>
            )} */}
          </Grid>
        )}
        <div className="flex justify-between items-center">
          <Share url={window.location.origin + '/marketplace?id=' + propDetails?.id} />
          {props.type === "Jobs" ? (
            <span className="min-w-[10%]">
              {/* <QueryBuilderIcon sx={{ fontSize: '15px' }} color="primary" /> */}
              <Typography className="text-[#6B7280] !text-xs">
                Posted on {moment(details?.created_at).format("DD MMM, YYYY")}
              </Typography>
            </span>
          ) : (
            <Typography
              sx={{ display: "flex", gap: "5px", alignItems: "center" }}
              className="min-w-[10%]"
            >
              <FiberManualRecordIcon color="info" fontSize="10" />
              {details.industryExperience ? (
                <h1 className="inline">
                  {details?.industryExperience} Years of Experience
                </h1>
              ) : (
                <h1 className="inline">Not disclosed</h1>
              )}
            </Typography>
          )}
        </div>
        <Divider />
        <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
          <Typography variant="body1Semibold" color="#182743">
            About the job
          </Typography>
        </Box>
        {details?.description && (
          <Box
            className='text-xs mt-4 uniform-font'
            sx={{
              color: '#6B7280',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
            }}
            dangerouslySetInnerHTML={{ __html: details?.description }}
          ></Box>
        )}
      </Box>
      <MuiModal open={openSubmitModal} onCancel={closeModal} className="max-w-5xl">
        <SubmitProposal details={propDetails} closeModal={closeModal} />
      </MuiModal>
    </Box>
  );
}
