import { InfoOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomTable } from "Components/CustomTable";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";
//   import { InvoiceCreateApi, getJobsWithTimesheets } from "./api";
import MuiModal from "Components/Common/MuiModal";
import Invoice from "Components/InvoicePreview/Invoice";
import { useAlertProvider } from "Providers/util/Alert";
import { selectUserDetails } from "Redux/Feature/user";
import { useSelector } from "react-redux";
import {
  FlexibleInvoiceCreateApi,
  fetchProjectBasedOnClientsAndCurrency,
} from "../FlexibleInvoice/api";
import AddClientForm from "./AddClientForm";
import { fetchJobs } from "Pages/Task/task.api";
import { getJobsWithTimesheets } from "Components/InvoiceCreateForm/api";
import { headerKeys } from "Components/InvoicePreview/InvoicePreview";
import moment from "moment";
import useDebounce from "hooks/useDebounce";
import MyEditor from "Components/TextEditor";

const currencies = [
  { id: "USD", name: "USD" },
  { id: "INR", name: "INR" },
];

const dateRange = [
  { id: "dateRange", name: "Date Range" },
  { id: "month", name: "Month" },
];

const JobsBasedInvoice = () => {
  const [loading, setLoading] = useState(false);
  const { success, error } = useAlertProvider();
  const userDetails = useSelector(selectUserDetails);
  const [amount, setAmount] = useState(0);
  const [dynamicFields, setDynamicFields] = useState([{ key: "", value: "" }]);
  const [finalAmount, setFinalAmount] = useState(0);
  const [searchedJob, setSearchedJob] = useState('')
  const [jobs, setJobs] = useState([])
  const [selectedJob, setSelectedJob] = useState()
  const [currentState, setCurrentState] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewDetails, setPreviewDetails] = useState(null);
  const [clientExist, setClientExist] = useState(false);
  const [showAddDetailsForm, setShowAddDetailsForm] = useState(true);
  const [billToDetails, setBillToDetails]= useState({});
  const navigate = useNavigate();
  const [isTimesheet, setIsTimesheet] = useState(false)
  const debouncedSearch = useDebounce(searchedJob, 500)

  const fetchJobsHandler = async () => {
    setLoading(true);
    try {
      const res = await fetchJobs('', `search=${debouncedSearch}`)
      setJobs(res?.data);
    } catch (error) {
      console.error('Failed to fetch jobs', error);
    } finally {
      setLoading(false);
    }
  };

  const defaultValues = {
    job_id: jobs.length > 1 ? "" : jobs?.[0]?.id,
    startDate: new Date(),
    endDate: undefined,
    dueDate: undefined,
    adjustment_amount: [],
    currency_code: "USD",
    type: "dateRange",
    comments: "",
    jobs: [],
    month: ''
  };

  const {
    handleSubmit,
    reset,
    watch,
    clearErrors,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors, isDirty, isSubmitting, isValidating },
  } = useForm({
    defaultValues,
  });

  const { currency_code, endDate, startDate } = getValues();
  const jobsFromForm = watch("jobs", defaultValues.jobs);
  const currencyFromForm = watch("currency_code", defaultValues.jobs);
  const jobFrom = watch("job_id", defaultValues.job_id);
  const monthFrom = watch("month", defaultValues.month);
  const typeFrom = watch("type", defaultValues.type);
  const startDateWatch = watch("startDate", defaultValues.startDate);
  const endDateWatch = watch("endDate", defaultValues.endDate);

  const onSubmit = async () => {
    // const dataObj = { ...data }
    const dataObj = {
      ...currentState,
      adjustment_amount: dynamicFields.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {}),
    };
    if (
      dataObj &&
      (dataObj["jobs"]?.length == 0 ||
        dataObj["jobs"].filter((i) => i.selected)?.length == 0)
    ) {
      return error("No jobs selected");
    }
    (dataObj?.jobs || [])
      .filter((i) => i.selected)
      .map((jo) => {
        dataObj["job_amount"] = jo.amount;
        return {
          client_project_id: jo.id,
          project_id: jo.project_id,
          amount: jo.amount,
        };
      });
    if (isTimesheet) {
      dataObj["job_details"] = (dataObj?.jobs || [])
      .filter((i) => i.selected)
      .map((jo) => {
        return {
          id: jo.id,
          rate: jo.rate,
          hours: jo.total_hours,
          rate_type: jo.rate_type,
          // amount: jo.amount,
          timesheets: jo.timesheets.map((i) => i.id),
        };
      });
    } else {
      dataObj["job_details"] = []
    }
    dataObj['job_id'] = dataObj['job_id']?.job?.id || ''
    if (billToDetails && Object.keys(billToDetails).length > 0) {
      dataObj['role'] = billToDetails?.billTo
      delete billToDetails?.billTo
      dataObj['client_details'] = billToDetails
    } else dataObj['client_details'] = null
    delete dataObj["jobs"]
    const res = await FlexibleInvoiceCreateApi(dataObj, '?requestType=JOB');
    if (res && res.success) {
      const invoiceId = res?.result?.id;
      success("Invoice generated successfully");
      navigate(`/dashboard/invoices/${invoiceId}?type=${res?.result?.invoice_type || 'FLEXIBLE'}&isInvoiceSentTab=true`);
      return;
    }
    return error(res?.message || res?.error || 'Something went wrong');
  };

  const previewHandler = (data) => {
    setOpenPreview(true);
    setCurrentState(data);
    let clientObj = {};
    jobs.forEach((job) => {
      if (job.id === jobFrom?.id) {
        clientObj = job.client;
      }
    });
    const invoiceDetails = {
      invoiceno: "PREVIEW",
      createdAt: new Date(),
      dueDate: data?.dueDate,
      currency_code: data?.currency_code,
      comments: data?.comments,
      sub_total_amount: amount,
      grand_total_amount: finalAmount,
      adjustment: dynamicFields.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {}),
    };
    setPreviewDetails({
      invoiceDetails,
      clientDetails: clientObj,
      vendorDetails: userDetails,
      jobsOfInvoice: data?.jobs.filter((i) => i.selected),
    });
  };

  const closePreviewHandler = () => {
    setPreviewDetails(false);
    setPreviewDetails(null);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Job Title",
        Cell: ({ cell }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {cell.getValue() || ""}
          </Typography>
        ),
        size: 120,
        enableEditing: false,
      },
      ...(!isTimesheet ? [
        {
          accessorKey: "client",
          header: "Bill To",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {clientExist && cell.getValue()?.company || ""}
            </Typography>
          ),
          size: 120,
          enableEditing: false,
        },
        {
          accessorKey: "amount",
          header: "Amount",
          Cell: ({ cell, row }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell.getValue()}
            </Typography>
          ),
          size: 120,
          enableEditing: true,
          muiEditTextFieldProps: ({ row }) => {
            const projectValue = getValues("jobs");
            const hasError = errors.jobs?.[row.original.id];
            return {
              error: hasError,
              sx: {
                width: "300px",
              },
              InputProps: {
                min: 0,
                max: 24,
                maxLength: 4,
                type: "number",
                disabled: loading,
                value:
                  projectValue.find((pro) => pro.id === row.original.id)
                    ?.amount || "",
                endAdornment: (
                  <Tooltip
                    title={
                      <Stack direction="column">
                        <Typography variant="captionNormal">
                          Amount must be greater than 0
                        </Typography>
                      </Stack>
                    }
                  >
                    <InfoOutlined
                      sx={{
                        fontSize: 18,
                        mr: 1,
                        color: hasError ? "error.main" : "",
                      }}
                    />
                  </Tooltip>
                ),
              },
              variant: "outlined",
              onChange: (e) => {
                const val = e.target.value;
                if (isNaN(val) || val < 1) {
                  setError(
                    `jobs.${row.original.id}`,
                    { type: "manual", message: "Amount must be greater than 0" },
                    { shouldFocus: true }
                  );
                } else {
                  clearErrors(`jobs.${row.original.id}`);
                }
                setValue(
                  "jobs",
                  projectValue.map((pro) => {
                    if (pro.id === row.original.id) {
                      return {
                        ...pro,
                        amount: val,
                      };
                    }
                    return pro;
                  })
                );
              },
            };
          },
        },
      ] : [
        {
          accessorKey: "consultant",
          header: "Resource",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell.getValue()?.firstName} {cell.getValue()?.lastName}
            </Typography>
          ),
          size: 120,
          enableEditing: false,
        },
        {
          accessorKey: "timesheets",
          header: "Timesheet Days",
          Cell: ({ cell }) => {
            let sum = cell.getValue()?.reduce((acc, curr) => {
              return acc + Number(curr?.total_timesheet_days || 0); // If value is undefined or null, default to 0
            }, 0);
            return <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {sum || 0}
            </Typography>
          },
          size: 120,
          enableEditing: false,
        },
        {
          accessorKey: "total_hours",
          header: "Total Hours",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell.getValue()}
            </Typography>
          ),
          size: 120,
          enableEditing: false,
        },
        {
          accessorKey: "rate",
          header: "Rate",
          Cell: ({ cell, row }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell.getValue()}
            </Typography>
          ),
          size: 120,
          enableEditing: true,
          muiEditTextFieldProps: ({ row }) => {
            const jobsValue = getValues("jobs");
            const hasError = errors.jobs?.[row.original.id];
            return {
              error: hasError,
              sx: {
                width: "200px",
              },
              InputProps: {
                min: 0,
                max: 24,
                maxLength: 4,
                disabled: loading,
                value:
                  jobsValue.find((job) => job.id === row.original.id)?.rate || "",
                endAdornment: hasError ? (
                  <Tooltip
                    title={
                      <Stack direction="column">
                        <Typography variant="captionNormal">
                          Rate must be greater than 0
                        </Typography>
                      </Stack>
                    }
                  >
                    <InfoOutlined
                      sx={{
                        fontSize: 18,
                        mr: 1,
                        color: hasError ? "error.main" : "",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Typography className="disabled" variant="captionNormal">
                    {row.original.rate_type === "HOURLY" ? "/Hr" : "/Month"}
                  </Typography>
                ),
              },
              variant: "outlined",
              onChange: (e) => {
                const val = e.target.value;
                if (isNaN(val) || val < 1) {
                  setError(
                    `jobs.${row.original.id}`,
                    { type: "manual", message: "Rate must be greater than 0" },
                    { shouldFocus: true }
                  );
                } else {
                  clearErrors(`jobs.${row.original.id}`);
                }
                setValue(
                  "jobs",
                  jobsValue.map((job) => {
                    if (job.id === row.original.id) {
                      return {
                        ...job,
                        rate: val,
                      };
                    }
                    return job;
                  })
                );
              },
            };
          },
        },
        {
          accessorKey: "total_amount",
          header: "Amount",
          Cell: ({ cell, row }) => {
            const jobsValue = getValues("jobs");
            const hasError = errors.jobs?.[row.original.id];
            const rate =
              Number(jobsValue.find((job) => job.id === row.original.id)?.rate) ||
              0;
            const hours = Number(row.original.total_hours) || 0;
            const total =
              currency_code === "USD"
                ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(row.original.rate_type === 'HOURLY' ? rate * hours : rate)
                : new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(row.original.rate_type === 'HOURLY' ? rate * hours : rate);
            return (
              <Typography
                variant="body2Normal"
                sx={{ color: hasError ? "error.main" : "#182743" }}
              >
                {total}
              </Typography>
            );
          },
          size: 120,
          enableEditing: false,
          muiTableHeadCellProps: {
            align: "right",
          },
          muiTableBodyCellProps: {
            align: "right",
          },
        },
      ])
    ],
    [jobsFromForm, currency_code, isTimesheet]
  );

  useEffect(() => {
    const sum = (dynamicFields || []).reduce((acc, curr) => {
      return acc + Number(curr?.value || 0); // If value is undefined or null, default to 0
    }, 0);
    setFinalAmount(amount + sum);
  }, [dynamicFields, amount]);

  useEffect(() => {
    if(debouncedSearch) fetchJobsHandler()
  }, [debouncedSearch])

  const fetchTimesheet = async (id, fromDate, toDate, currencyFromForm, monthFrom, type) => {
    const data = await getJobsWithTimesheets(
      id,
      fromDate,
      toDate,
      "jobId",
      currencyFromForm,
      monthFrom,
      type,
      true
    );
    if (data && data.data) {
      setValue(
        "jobs",
        (data.data || []).map((i) => {
          return { ...i, selected: true };
        })
      );
      if (data.data?.length > 0) {
        if (data.data[0]?.timesheets?.length > 0) setIsTimesheet(true)
        else setIsTimesheet(false)
      }
    }
  };

  useEffect(() => {
    if (jobFrom && ((startDateWatch && endDateWatch && typeFrom === 'dateRange') || (typeFrom === 'month' && monthFrom))) {
      fetchTimesheet(
        jobFrom?.job?.id || jobFrom,
        startDateWatch,
        endDateWatch,
        currencyFromForm,
        monthFrom,
        typeFrom
      );
    }
  }, [jobFrom, startDateWatch, endDateWatch, currencyFromForm, monthFrom]);

  const handleAddField = () => {
    setDynamicFields([...dynamicFields, { key: "", value: "" }]);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index] = { ...updatedFields[index], [field]: value };
    setDynamicFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...dynamicFields];
    updatedFields.splice(index, 1);
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    let sum = 0;
    jobsFromForm
      .filter((i) => i.selected)
      .map((i) => {
        if (!isTimesheet) {
          sum = sum + Number(i?.amount || 0);
        } else {
          const rate = Number(i?.rate) || 0;
          const hours = Number(i?.total_hours || 0) || 0;
          const total = i.rate_type === 'HOURLY' ? rate * hours : rate;
          sum = sum + total;
        }
      });
    setAmount(sum);
  }, [jobsFromForm, isTimesheet]);

  useEffect(() => {
    let clientObj = {};
    jobs.forEach((job) => {
      if (job.job.id === jobFrom?.job?.id) {
        clientObj = job;
      }
    });
    setClientExist(clientObj?.isClientExist)
    setShowAddDetailsForm(!clientObj?.isClientExist)
  }, [jobFrom])

  return (
    <Grid item xs={16}>
      {previewDetails && (
        <MuiModal
          open={openPreview}
          onCancel={closePreviewHandler}
          maxWith="sm"
        >
          {showAddDetailsForm ? <AddClientForm backHandler={closePreviewHandler} billToDetails={billToDetails} setBillToDetails={setBillToDetails} submitHandler={(data) => (setPreviewDetails({ ...(previewDetails || {}), clientDetails: data }), setBillToDetails(data), setShowAddDetailsForm(false))} /> :
            <Invoice {...previewDetails} addClientHandler={() => setShowAddDetailsForm(true)} headerKeys={isTimesheet ? headerKeys.fixed : headerKeys.jobs} type={isTimesheet ? 'fixed' : 'jobs'} />
          }
          {!showAddDetailsForm && <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: '40px'
            }}
          >
            <Button variant="outlined" onClick={clientExist ? closePreviewHandler : () => setShowAddDetailsForm(true)}>
              { clientExist ? "Edit" : 'Back' }
            </Button>
            <Button
              variant="contained"
              sx={{ marginLeft: "20px" }}
              onClick={onSubmit}
            >
              Generate
            </Button>
          </Box>}
        </MuiModal>
      )}
      <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
        <Typography variant="cardHeader">Create Jobs Based Invoice</Typography>
        <form onSubmit={handleSubmit(previewHandler)}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <Controller
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="job_id" shrink>
                      Jobs
                    </InputLabel>
                    <Autocomplete
                      options={jobs}
                      id="job_id"
                      freeSolo
                      {...field}
                      getOptionLabel={(option) => option?.job?.name || ''}
                      isOptionEqualToValue={(option, value) => option?.job?.id === value || option?.job?.id === value?.name}
                      onChange={(_, value) => {
                        setValue("job_id", value?.job?.id || "");
                        field.onChange(value || "");
                      }}
                      loading={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Type Job Name'}
                          onChange={(e) => setSearchedJob(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
                control={control}
                name="job_id"
                defaultValue={defaultValues.client}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="type" shrink>
                      Select
                    </InputLabel>
                    <Select {...field} required>
                      {dateRange.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                control={control}
                name="type"
                defaultValue={defaultValues.type}
              />
            </Grid>
            {typeFrom === "dateRange" ? (
              <>
                <Grid item xs={2}>
                  <Controller
                    render={({ field }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          ".MuiFormControl-root": {
                            width: "100%",
                          },
                          ".react-datepicker-wrapper": {
                            marginTop: "24px",
                          },
                        }}
                      >
                        <InputLabel htmlFor="startDate" shrink>
                          From
                        </InputLabel>
                        <DatePicker
                          selected={field.value}
                          onChange={field.onChange}
                          selectsStart
                          startDate={field.value}
                          endDate={endDate}
                          customInput={<TextField />}
                          style={{ marginTop: "24px" }}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name="startDate"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    render={({ field }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          ".MuiFormControl-root": {
                            width: "100%",
                          },
                          ".react-datepicker-wrapper": {
                            marginTop: "24px",
                          },
                        }}
                      >
                        <InputLabel htmlFor="endDate" shrink>
                          To
                        </InputLabel>
                        <DatePicker
                          selected={field.value}
                          onChange={field.onChange}
                          selectsEnd
                          startDate={startDate}
                          endDate={field.value}
                          customInput={<TextField />}
                          style={{ marginTop: "24px" }}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name="endDate"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={2}>
                <Controller
                  render={({ field }) => {
                    return (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          ".MuiFormControl-root": {
                            width: "100%",
                          },
                          ".react-datepicker-wrapper": {
                            marginTop: "24px",
                          },
                        }}
                      >
                        <InputLabel htmlFor="endDate" shrink>
                          Select Month
                        </InputLabel>
                        <DatePicker
                          selected={
                            field.value
                              ? moment(field.value, "MMM-YYYY").toDate()
                              : null
                          }
                          onChange={(date) =>
                            field.onChange(
                              date ? moment(date).format("MMM-YYYY") : ""
                            )
                          }
                          showMonthYearPicker
                          customInput={<TextField />}
                          dateFormat="MMM-yyyy"
                          style={{ marginTop: "24px" }}
                        />
                      </FormControl>
                    );
                  }}
                  control={control}
                  name="month"
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <Controller
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{
                      ".MuiFormControl-root": {
                        width: "100%",
                      },
                      ".react-datepicker-wrapper": {
                        marginTop: "24px",
                      },
                    }}
                  >
                    <InputLabel htmlFor="dueDate" shrink>
                      Due Date
                    </InputLabel>
                    <DatePicker
                      selected={field.value}
                      onChange={field.onChange}
                      customInput={<TextField />}
                    />
                  </FormControl>
                )}
                control={control}
                name="dueDate"
              />
            </Grid>
            <Grid item xs={3}>
                <Controller
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel htmlFor="currency_code" shrink>
                        Currency
                      </InputLabel>
                      <Select {...field} required>
                        {currencies.map((option) => (
                          <MenuItem value={option.id} key={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  control={control}
                  name="currency_code"
                  defaultValue={defaultValues.currency_code}
                />
              </Grid>
          </Grid>
          <Typography
            variant="body1Bold"
            sx={{ color: "#182743", mt: 3, mb: 2 }}
            component="h3"
          >
            Select Job(s)
          </Typography>
          <CustomTable
            enableEditing
            editDisplayMode="table"
            initialState={{}}
            enableSorting={false}
            loading={false}
            data={jobsFromForm}
            columns={columns}
            enableBottomToolbar={false}
            enableRowNumbers={true}
            rowNumberDisplayMode={"static"}
            enablePagination={false}
            enableColumnResizing={false}
          />
          {jobsFromForm && jobsFromForm.length > 0 && (
            <Stack
              direction="row"
              // justifyContent="flex-end"
              flexDirection={"column"}
              alignItems={"flex-end"}
              gap={2}
              sx={{ mt: 3 }}
            >
              {dynamicFields.map((field, index) => (
                <Stack
                  direction="row"
                  key={index}
                  width={"40%"}
                  // alignItems="flex-end"
                  spacing={2}
                >
                  <Typography>Adjustment Name</Typography>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name={`adjustment_amount[${index}].key`}
                      control={control}
                      defaultValue={field.key}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={dynamicFields[index].key}
                          onChange={(e) =>
                            handleFieldChange(index, "key", e.target.value)
                          }
                          placeholder="Text Box"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name={`adjustment_amount[${index}].value`}
                      control={control}
                      defaultValue={field.value}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={dynamicFields[index].value}
                          placeholder="Amount"
                          onChange={(e) =>
                            handleFieldChange(index, "value", e.target.value)
                          }
                          variant="outlined"
                          type="number"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                  {index === dynamicFields.length - 1 && (
                    <IconButton onClick={handleAddField} color="primary">
                      <AddIcon />
                    </IconButton>
                  )}
                  {index !== dynamicFields.length - 1 && (
                    <IconButton onClick={() => handleRemoveField(index)}>
                      <Typography variant="body2">Remove</Typography>
                    </IconButton>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
          {jobsFromForm && jobsFromForm.length > 0 && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap={2}
              alignItems={"flex-end"}
              sx={{ mt: 3 }}
            >
              <Box flexDirection={"row"} display={"flex"} width={"20%"}>
                <Typography>Final Amount</Typography>
                <FormControl variant="standard" fullWidth>
                  <TextField value={finalAmount} disabled />
                </FormControl>
              </Box>
            </Stack>
          )}
          {jobsFromForm && jobsFromForm.length > 0 && <Grid item xs={8}>
            <Controller
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="comments" shrink className="!mt-4">
                    Payment Terms and Details
                  </InputLabel>
                  <MyEditor
                    className={'!mt-10'}
                    height={100}
                    html={field.value}
                    updateRaw={field.onChange}
                  />
                </FormControl>
              )}
              control={control}
              name="comments"
              defaultValue={defaultValues.comments}
            />
          </Grid>}
          {jobsFromForm && jobsFromForm.length > 0 && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap={2}
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={() => reset(defaultValues)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting || isValidating}
              >
                {clientExist ? 'Preview Invoice' : 'Bill To'}
              </Button>
              {/* <Button
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting || isValidating}
              >
                {loading ? "Generating..." : "Generate"}
              </Button> */}
            </Stack>
          )}
        </form>
      </Paper>
    </Grid>
  );
};

export default JobsBasedInvoice;
