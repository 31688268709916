import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { formatTime } from 'Utils/helper';
import moment from 'moment';
import BrandImg from '../Assets/prolegionLogoBlue.png';

export default function TimesheetPdf({ data, timesheetSummary = false }) {
  let totalHours = 0;
  let minutes = 0;
  if (timesheetSummary) {
    for (let i = 0; i < data.length; i++) {
      minutes += parseInt(data[i].minutes);
      totalHours += parseInt(data[i].hours);
    }
    if (minutes >= 60) {
      totalHours += parseInt(minutes / 60);
      minutes %= 60;
    }
  }

  // Used to valculate time for the entries falling within the selected date

  function calculateTotalTime(entries) {
    let totalHours = 0;
    let totalMinutes = 0;

    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      totalHours += entry.hours;
      totalMinutes += entry.minutes;
    }

    // Adjust minutes if it exceeds 60
    if (totalMinutes >= 60) {
      const additionalHours = Math.floor(totalMinutes / 60);
      totalHours += additionalHours;
      totalMinutes %= 60;
    }

    // Convert hours and minutes to strings with leading zeros if necessary
    const formattedHours = totalHours.toString().padStart(2, '0');
    const formattedMinutes = totalMinutes.toString().padStart(2, '0');

    return { formattedHours, formattedMinutes };
  }

  return (
    <>
      {timesheetSummary ? (
        <Document>
          <Page size='A4' style={styles.page} key={data[0].id}>
            <View style={styles.header}>
              <Text style={styles.headerText}>Timesheet</Text>
            </View>
            <View style={styles.mainSection}>
              <View style={styles.section}>
                <>
                  <View style={styles.sectionHeaderData}>
                    <View style={styles.contentRowFlex}>
                      <Text style={styles.sectionTitle}>Job: </Text>
                      <Text style={styles.jobTitleText}>
                        {data[0].job.name}
                      </Text>
                    </View>
                    <View style={cardStyles.contentRowFlex}>
                      <Text style={styles.sectionTitle}>Consultant: </Text>
                      <View style={cardStyles.cardHead}>
                        <Text
                          style={cardStyles.cardText}
                        >
                          {`${data[0].consultant.firstName} ${data[0].consultant.lastName}`}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.sectionHeaderData}>
                    <View>
                      <View style={styles.contentRowFlex}>
                        <Text style={styles.sectionTitle}>Description: </Text>
                      </View>
                      <Text style={styles.descriptionText}>
                        {data[0].job.description}
                      </Text>
                    </View>
                  </View>
                </>
              </View>

              <View style={styles.tableContainer}>
                <SummaryTableHeader />
                <SummaryRow data={data} />
              </View>
              <View style={styles.sectionFooterData}>
                <View style={styles.contentRowFlex}>
                  <Text style={styles.sectionTitle}>
                    Total Hours:
                    {' '}
                    {`${totalHours}`}
                  </Text>
                </View>
              </View>
            </View>
            <Footer />
          </Page>
        </Document>
      ) : (
        <Document>
          {data.map((item, idx) => (
            <Page size='A4' style={styles.page} key={item.id}>
              <View style={styles.header}>
                <Text style={styles.headerText}>Timesheet</Text>
              </View>
              <View style={styles.mainSection}>
                <View style={styles.section}>
                  {idx === 0 && (
                  <View style={styles.sectionHeaderData}>
                    <View style={styles.contentRowFlex}>
                      <Text style={styles.sectionTitle}>Job: </Text>
                      <Text style={styles.jobTitleText}>
                        {item?.job?.name}
                      </Text>
                    </View>
                    <View style={cardStyles.contentRowFlex}>
                      <Text style={styles.sectionTitle}>
                        Consultant:
                        {' '}
                      </Text>
                      <View style={cardStyles.cardHead}>
                        <Text
                          style={cardStyles.cardText}
                        >
                          {`${item.consultant.firstName} ${item.consultant.lastName}`}
                        </Text>
                      </View>
                    </View>
                  </View>
                  )}
                  <View style={styles.sectionHeaderData}>
                    <View style={cardStyles.contentRowFlex}>
                      <Text style={styles.sectionTitle}>Week: </Text>
                      <View style={cardStyles.cardHead}>
                        <Text style={cardStyles.cardText}>
                          {moment.utc(item?.days[0]?.date).format('Do MMM, YYYY')}
                          {' '}
                          -
                          {' '}
                          {moment.utc(item?.days[item?.days?.length - 1]?.date).format('Do MMM, YYYY')}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.contentRowFlex}>
                      <Text style={styles.sectionTitle}>
                        Total Hours:
                        {' '}
                        {calculateTotalTime(item?.days).formattedHours}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.tableContainer}>
                  <TableHeader isCheckInOut={data[0]?.job?.isCheckinCheckout} />
                  {item.days?.map((day, idx) => (
                    <TableRow day={day} key={idx} isCheckInOut={data[0]?.job?.isCheckinCheckout} />
                  ))}
                </View>
              </View>
              <Footer />
            </Page>
          ))}
        </Document>
      )}
    </>
  );
}
function TableHeader({ isCheckInOut }) {
  return (
    <View style={tableHeader.container}>
      <Text style={tableHeader.date}>Date</Text>
      {isCheckInOut && <Text style={tableHeader.hours}>Check In</Text>}
      {isCheckInOut && <Text style={tableHeader.hours}>Check Out</Text>}
      <Text style={tableHeader.hours}>Hours</Text>
      <Text style={tableHeader.description}>Description</Text>
    </View>
  );
}

function SummaryTableHeader() {
  return (
    <View style={summaryTableHeader.container}>
      <Text style={summaryTableHeader.date}>Week</Text>
      <Text style={summaryTableHeader.hours}>Hours</Text>
      <Text style={summaryTableHeader.description}>Description</Text>
    </View>
  );
}

function TableRow({ day, isCheckInOut }) {
  return (
    <View style={tableRow.row}>
      <Text style={tableRow.date}>{moment.utc(day.date).format('Do, MMM')}</Text>
      { isCheckInOut && <Text style={tableRow.hour}>{formatTime(day.checkin_time)}</Text>}
      { isCheckInOut && <Text style={tableRow.hour}>{formatTime(day.checkout_time)}</Text>}
      <Text style={tableRow.hour}>
        {`${day?.hours || '0'}`}
        {' '}
        hrs
      </Text>
      <Text style={tableRow.description}>{`${day.description}`}</Text>
    </View>
  );
}

const SummaryRow = ({ data }) => data?.map((item, idx) => (
  <View style={summaryRow.row} key={idx}>
    <Text style={summaryRow.date}>
      {moment.utc(item.start).format('Do, MMM')}
      {' '}
      -
      {' '}
      {moment.utc(item.end).format('Do, MMM')}
    </Text>
    <Text style={summaryRow.hour}>
      {`${item.hours} : ${item.minutes || '00'}`}
      {' '}
      hrs
    </Text>
    <Text style={summaryRow.description} />
  </View>
));

function Footer() {
  return (
    <View style={footerStyle.base}>
      <Image src={BrandImg} style={footerStyle.logoImg} />
    </View>
  );
}
const borderColor = '#BDBDBD';
const styles = StyleSheet.create({
  page: {
    fontSize: '11px',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#0B85BA',
    padding: '14px 0px',
  },
  headerText: {
    textAlign: 'center',
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  headerSmallText: {
    textAlign: 'center',
    color: 'white',
    fontSize: '15px',
  },
  mainSection: {
    padding: '24px',
  },
  section: {
    marginTop: '24px',
  },
  sectionHeaderData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
  sectionFooterData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
    marginTop: '20px',
  },
  sectionTitle: {
    fontSize: '13px',
    fontFamily: 'Helvetica-Bold',
  },
  contentRowFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  descriptionText: {
    marginTop: '12px',
    fontSize: '10px',
    textAlign: 'justify',
    maxLines: 6,
  },
  jobTitleText: {
    fontSize: '12px',
    maxWidth: '200px',
    maxLines: 3,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#BDBDBD',
  },
});

const footerStyle = StyleSheet.create({
  base: {
    marginTop: 'auto',
    backgroundColor: '#414141',
    color: 'white',
    padding: 20,
  },
  logoImg: {
    width: 100,
    height: 30,
  },
});

const cardStyles = StyleSheet.create({
  contentRowFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardHead: {
    // backgroundColor: '#139588',
    // padding: '8px 16px',
    // height: '30px',
    // borderTopLeftRadius: '8',
    // borderTopRightRadius: '8',
    // borderBottomRightRadius: '8',
    // borderBottomLeftRadius: '8',
  },
  cardText: {
    fontSize: '12px',
    textAlign: 'center',
    color: 'black',
  },
});
const tableHeader = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#BDBDBD',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 40,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  date: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: '13px',
  },
  hours: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: '13px',
  },
  description: {
    width: '60%',
    // fontSize: '13px',
  },
});
const tableRow = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#BDBDBD',
    borderBottomWidth: 1,
    alignItems: 'center',
    minHeight: 40,
    fontStyle: 'bold',
  },
  date: {
    width: '20%',
    textAlign: 'center',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  hour: {
    fontSize: 10,
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
  },
  description: {
    width: '60%',
    textAlign: 'left',
    paddingLeft: 8,
    maxLines: 3,
  },
});
const summaryTableHeader = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#BDBDBD',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 40,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  date: {
    width: '25%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: '13px',
  },
  hours: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: '13px',
  },
  description: {
    width: '60%',
    // fontSize: '13px',
  },
});
const summaryRow = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#BDBDBD',
    borderBottomWidth: 1,
    alignItems: 'center',
    minHeight: 40,
    fontStyle: 'bold',
  },
  date: {
    width: '25%',
    textAlign: 'center',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  hour: {
    fontSize: 10,
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
  },
  description: {
    width: '60%',
    textAlign: 'center',
    paddingLeft: 8,
    maxLines: 3,
  },
});
