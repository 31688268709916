import React, { useState, createContext, useContext } from "react";
import api from "../../Utils/api";

const apiPath = {
  getCountries: "/country",
  getStates: "/state",
  getCities: "/city",
};

const nullFn = () => null;
const promiseFn = () => new Promise();
const CountryStateCityContext = createContext({
  loading: false,
  countries: [],
  states: [],
  cities: [],
  country: {},
  state: {},
  getCountries: nullFn,
  getStatesByCountry: param => null,
  getCitiesByStateAndCountry: nullFn,
  getCountryByCode: promiseFn,
  getStateByCodeAndCountry: promiseFn,
});

export const useCountryStateCityProvider = () =>
  useContext(CountryStateCityContext);
const CountryStateCityProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [countries, setCoutries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [state, setState] = useState({});
  const [country, setCountry] = useState({});
  const getCountries = async (countryISOCode) => {
    setLoading(true);
    try {
      let res;
      if (countryISOCode) {
        res = await api.get(
          // apiPath.getCountries + `?countryISOCode=${countryISOCode}`
          apiPath.getCountries
        );
        setCoutries(res.data?.id ? [res.data] : res.data);
        return res.data?.id ? [res.data] : res.data;
      } else {
        res = await api.get(apiPath.getCountries);
        setCoutries(res.data?.id ? [res.data] : res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getStatesByCountry = async (countryISOCode, stateISOCode) => {
    if (!countryISOCode) return;
    setCities([]);
    setLoading(true);
    try {
      let res;
      // if (stateISOCode) {
      //   res = await api.get(
      //     apiPath.getStates +
      //     // `?countryISOCode=${countryISOCode}&stateISOCode=${stateISOCode}`
      //     `?countryISOCode=${countryISOCode}`
      //   );
      //   setStates(res.data?.id ? [res.data] : res.data);
      //   return res.data?.id ? [res.data] : res.data;
      // } else {
        res = await api.get(
          apiPath.getStates + `?countryISOCode=${countryISOCode}`
        );
        setStates(res.data?.id ? [res.data] : res.data);
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCitiesByStateAndCountry = async (stateISOCode, countryISOCode) => {
    if (!stateISOCode || !countryISOCode) return false;
    setLoading(true);
    try {
      const res = await api.get(
        apiPath.getCities +
          `/?countryISOCode=${countryISOCode}&stateISOCode=${stateISOCode}`
      );
      setCities(res.data?.id ? [res.data] : res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    loading,
    countries,
    states,
    cities,
    country,
    state,
    getCountries,
    getStatesByCountry,
    getCitiesByStateAndCountry,
  };
  return (
    <CountryStateCityContext.Provider value={value}>
      {children}
    </CountryStateCityContext.Provider>
  );
};

export default CountryStateCityProvider;
