import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector } from 'react-redux';
import { selectProfileDetails, selectResourceDetails } from 'Redux/Feature/profile/profile.selector';
import { newAvailabilityOptions, newMobileAvailabilityOptions } from '../constant';
import { UpdateUser, uploadFile } from '../api';
import { NestedDropdown } from 'Components';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch } from 'react-redux';
import { setAvailableOptionR } from 'Redux/Feature/profile/profile.slice';
import { isDateCurrentValid } from 'Utils/constants';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function ConsultantAdditionalInformation({ details, setAvailability }) {
  const { availability } = details;
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const profiles = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const dispatch = useDispatch();
  const [avail, setAvail] = useState("");
  const isAvailAfter = avail === "After";

  const resumeHandler = async (e) => {
    const files = e.target.files[0];
    const fileResponse = await uploadFile({
      cur: files,
      user: profiles,
      photo_type: 'resume',
      content: 'pdf',
    });
    if (fileResponse.url) {
      await UpdateUser({ resume: fileResponse.url });
      setAvailability(fileResponse.url, 'resume');
    }
  };

  const viewHandler = () => {
    window.open(profiles?.resume, '__blank');
  };


  React.useEffect(() => {
    if(isAvailAfter && profiles?.availableOption?.date && profiles?.availableOption?.date !== "Invalid Date") {
      setAvailability(`${avail} ${profiles?.availableOption?.date}`, 'availability')
    } else {
      dispatch(setAvailableOptionR({name: avail, date : ""}))
      setAvailability(avail, 'availability')
    }
  }, [avail, profiles?.availableOption?.date])

  return (
    <Stack gap={4} justifyContent={"flex-start"} sx={{ flexDirection: {xs : "column", md: "row"}, alignItems:{xs: "start", md:"end"}, mt: 3 }}>
      {/* <Autocomplete
        id="states"
        sx={{ width: '30%' }}
        isOptionEqualToValue={(option, value) => (option?.value || option) === (value?.value || value)}
        clearIcon={false}
        getOptionLabel={(option) => option.label || option}
        value={availability || ''}
        options={availabilityOptions}
        onChange={(_, v) => setAvailability(v, 'availability')}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Full or Part Time"
            label="Availability"
            InputProps={{
              ...params.InputProps,
              endAdornment: params.InputProps.endAdornment,
            }}
          />
        )}
      /> */}
      <NestedDropdown selectedOption={avail} setSelectedOption={setAvail} label={"Availability"} placeholder={"Select an option"} labelClassname={"text-[#000]"} baseClassname={"min-[300px]:hidden md:block w-[30%]"} options={newAvailabilityOptions} />
      <NestedDropdown selectedOption={avail} setSelectedOption={setAvail} label={"Availability"} placeholder={"Select an option"} labelClassname={"text-[#000]"} baseClassname={"min-[300px]:block md:hidden w-full"} options={newMobileAvailabilityOptions} />
      
      {isAvailAfter && <div className="flex flex-col md:w-[30%] min-[300px]:w-full">
        <Typography>Available Date</Typography>
        <DesktopDatePicker
          className=""
          format="MM-DD-YYYY"
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'white',
            },
            '& .MuiInputBase-root': {
              backgroundColor: 'white',
              marginTop: '5px'
            },
            '& .MuiInputAdornment-root': {
              backgroundColor: 'white'
            },
          }}
          minDate={dayjs()}
          onChange={(e) => {
            if(isAvailAfter && e) {
              dispatch(setAvailableOptionR({ ...profiles?.availableOption,
                date: dayjs(e).format('DD/MM/YYYY')
              }))
            }
          }}
        />
      </div>}

      <div className="flex min-[300px]:flex-col md:flex-row pb-[4px] min-[300px]:w-full md:w-[33%]">
      <Button
        component="label"
        sx={{
          typography: 'captionMedium',
          color: '#F9FAFB',
          backgroundColor: '#0B85BA',
        }}
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        Upload Resume
        <VisuallyHiddenInput
          onChange={resumeHandler}
          type="file"
          accept=".pdf"
        />
      </Button>
      {profiles?.resume && profiles?.resume.includes('https') && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', ml: {xs: 0 ,md:2}, mt: {xs: 1, md: 0} }}
          onClick={viewHandler}
        >
          {/* <DownloadIcon sx={{ml: "8px"}}/> */}
          <Typography className='min-[300px]:block pr-2 md:hidden'>Uploaded File:</Typography>
          <Typography>
            {
              decodeURIComponent(profiles?.resume)?.split('/')[
                (decodeURIComponent(profiles?.resume)?.split('/') || 0).length - 1
              ]
            }
          </Typography>
        </Box>
      )}
      </div>
    </Stack>
  );
}

export default ConsultantAdditionalInformation;
