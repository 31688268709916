import themeBase from "../themeBase";

export const MuiSelectOverride = {
  styleOverrides: {
    root: {
      // borderRadius: '4px',
      // '&::before': {
      //   borderBottom: 'none !important',
      // },
      'label + &': {
        marginTop: themeBase.spacing(3),
      },

      input: {
        padding: '10px 12px',
      },
      '& .MuiInputBase-input': {
        // minWidth: '210px !important',
        color: 'inherit',
        // borderRadius: '8px',
        // position: 'relative',
        // backgroundColor: '#F9FAFB',
        // border: '1px solid',
        // fontSize: '16px',
        // padding: '10px 12px',
        // ...themeBase.typography.body2Normal,
        // borderColor: '#D1D5DB',
        // '&:focus': {
        //   borderRadius: '8px',
        // },
      },
    },
  },
};
