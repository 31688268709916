import CancelIcon from '@mui/icons-material/Cancel';
import VerifiedIcon from '@mui/icons-material/Verified';
import React from 'react';

const AddTabs = ({
  tabs = [],
  removeTabs,
  style = {}
}) => {

  return (
    <div style={style}>
      <div className="mt-8 grid grid-cols-3 gap-5">
        {tabs.map((tab, j) => (
          <div key={`${tab + j}`} className="flex justify-between items-center mb-2.5">
            <div className="flex gap-2.5 items-center">
              <VerifiedIcon className="text-[#006A98]" />
              <span>{tab}</span>
            </div>
            <CancelIcon className="cursor-pointer" onClick={() => removeTabs(j)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddTabs;
