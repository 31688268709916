import React from 'react';
import { useNavigate } from 'react-router-dom';
import prolegionlogo from '../Assets/ProLegionLogo.svg';

function ShareNavbar() {
  const navigate = useNavigate();

  return (
    <div className='font-[Roboto] px-2 md:px-0 shadow-lg top-0 left-0 sticky z-50 bg-white'>
      <div className='flex flex-row sm:px-8 justify-between h-24 items-center'>
        <div>
          <img
            alt='prolegionlogo'
            className='w-[140px] h-[80px] md:w-[169px] md:h-[100px]'
            src={prolegionlogo}
          />
        </div>
        <div className='flex space-x-4'>
          <div
            onClick={() => {
              navigate('/signin');
            }}
            className='bg-[#2EBAA3] text-white py-1.5 cursor-pointer px-7 rounded-md flex flex-row sm:space-x-8 space-x-2 items-center'
          >
            Login
          </div>
          <div
            onClick={() => {
              navigate('/signup');
            }}
            style={{
              border: '1px solid #139588',
              borderRadius: '6px',
            }}
            className=' text-[#139588] py-1.5 cursor-pointer px-4 rounded-md flex flex-row sm:space-x-8 space-x-2 items-center'
          >
            Register
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareNavbar;
