import enterpriseImage1 from "../Assets/Marketing/enterprise-one.svg";
import enterpriseImage2 from "../Assets/Marketing/enterprise-two.svg";
import enterpriseImage3 from "../Assets/Marketing/enterprise-three.svg";
// import enterpriseFeatureImage from "../Assets/Marketing/enterprise-feature.png";

export const roles = {
  hybridConsultant: 'HYBRID_CONSULTANT',
  consultant: 'CONSULTANT',
  vendor: 'VENDOR',
  client: 'CLIENT',
};

export const userPermissions = {
  job: {
    add: 'add-job',
    view: 'view-job',
    edit: 'edit-job',
    vendorAction: 'assign-vendors',
    clientAction: 'change-client',
    consultantAction: 'assign-consultants',
    timesheetAction: 'add-timesheet',
    invoiceAction: 'pay-invoice',
  },
  invoices: {
    add: 'add-invoice',
    view: 'view-invoice',
    invoiceAction: 'discard-invoice',
  },
  timesheet: {
    add: 'add-timesheet',
    view: 'view-timesheet',
    approve: 'approve-reject-timesheet',
    edit: 'edit-timesheet',
    submit: 'submit-timesheet',
  },
  myAccount: {
    view: 'view-account',
    edit: 'edit-account',
  },
  settings: {
    edit: 'edit-settings',
  },
  clientConnection: {
    add: 'add-client-connection',
    view: 'view-client-connection',
  },
  vendorConnection: {
    add: 'add-vendor-connection',
    view: 'view-vendor-connection',
  },
  consultantConnection: {
    add: 'add-consultant-connection',
    view: 'view-consultant-connection',
  },
  document: {
    view: 'view-document',
    add: 'save-document'
  },
  task: {
    view: 'view-task',
    add: 'add-task'
  },
};
export const s3Url = `${process.env.REACT_APP_S3_URL}/`;

export const assetsUrl = `https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/`

export const timesheetStatus = {
  none: 'NONE',
  saved: 'SAVED',
  submitted: 'SUBMITTED',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  current: 'Current',
  Invited: 'Invited',
  invoiced: 'INVOICED',
};

export const InvoiceStatusLabel = {
  DRAFT: { label: 'Saved', color: '#E5C51C', bgColor: '#E5C51C33' },
  SUBMITTED: { label: 'Submitted', color: '#E5C51C', bgColor: '#E5C51C33' },
  APPROVED: { label: 'Approved', color: '#139588', bgColor: '#13958833' },
  PAID: { label: 'Paid', color: '#139588', bgColor: '#13958833' },
};
export const consultantAvailability = [
  {
    value: 'AVAILABLE',
    label: 'Available',
  },
  {
    value: 'PARTIAL',
    label: 'Partialy Available',
  },
  {
    value: 'NOTAVAILABLE',
    label: 'Not Available',
  },
];

export const INVOICE_PAYMENT_OPTIONS = [
  {
    label: 'Online Mode (Coming Soon)',
    value: 'online',
  },
  {
    label: 'Offline Mode',
    value: 'offline',
  },
];

export const [, DEFAULT_PAYMENT_OPTION] = INVOICE_PAYMENT_OPTIONS;

export const MESSAGES = Object.freeze({
  connectionWarning:
    'You will be redirected to connect your bank account with Prolegion upon clicking the submit button.',
});

export const bgColor = {
  GREY200: '#E5E7EB',
  GREY300: '#D1D5DB',
  PRIMARY600: '#0B85BA',
  WHITE: '#fff',
  PRIMARY900: '#003B54',
  BLACK: '#000',
};

export const LOCALSTORAGE_DATA_KEYS = Object.freeze({
  userId: 'userId',
});

export const statuses = {
  invoices: {
    draft: 'DRAFT',
    submitted: 'SUBMITTED',
    approved: 'APPROVED',
    paid: 'PAID',
  },
  job: {
    open: 'OPEN',
    hiringHold: 'HOLD',
    ongoing: 'ONGOING',
    closed: 'CLOSED',
  },
  resources: {
    current: 'Current',
    invited: 'Invited',
  },
};

export const SUBSCRIPTION_TYPES = Object.freeze({
  monthly: 'Month',
  yearly: 'Yearly',
});

export const SUBSCRIPTION_CURRENCIES = {
  usd: 'USD',
  inr: 'INR',
};

export const SUBSCRIPTION_COUNTRIES = {
  IN: {
    currencyCode: 'inr',
    currencyLabel: 'INR',
    currencySymbol: '₹',
  },
  US: {
    currencyCode: 'usd',
    currencyLabel: 'USD',
    currencySymbol: '$',
  },
};

export const CURRENCY_ICONS = {
  usd: '$',
  inr: '₹',
};

export const SUNSCRIPTION_PLANS = [
  {
    id: 'price_1OUr2uAzOgO1UoUnUWNsbG9P',
    name: 'marketplace',
    free_usage: 0,
    unit_amount: 9900,
  },
  {
    id: 'price_1OUrAKAzOgO1UoUnWOZ1M74I',
    name: 'users',
    free_usage: 1,
    unit_amount: 1999,
  },
  {
    id: 'price_1OV52wAzOgO1UoUnyVOifpn1',
    name: 'resources',
    free_usage: 5,
    unit_amount_decimal: 999,
  },
];

export const razorPaySubscriptionRolesType = [
  {
    monthlyName: "Marketplace monthly plan",
    yearlyName: "Marketplace yearly plan",
    mappedName: "Marketplace",
    type: 'marketplace',
    typeKey: 'marketplace'
  },
  {
    monthlyName: "vendor user monthly plan",
    yearlyName: "vendor user yearly plan",
    mappedName: "User",
    type: 'vendor',
    typeKey: 'users'
  }, 
  {
    monthlyName: "resource monthly plan",
    yearlyName: "resource yearly plan",
    mappedName: "Resource",
    type: 'resource',
    typeKey: 'resources'
  }
]

const enterpriseFeatureImage = `${assetsUrl}enterprise-feature.png`;

export const navbarOptions = {
  enterprises: {
    heading: 'Enterprises',
    subheading: 'ProLegion creates an efficient & organized environment for your team to thrive in. You can organize your resources & their tasks in manageable view & manage the associated data easily.',
    videoLink: "https://www.youtube.com/embed/aOISZfARQpc?si=DZVgRfHldsnkrGKm",
    cards: [
      {
        image: enterpriseImage1,
        heading: 'Cloud-Based Flexibility',
        subheading: 'Access real-time information anywhere and anytime with an internet connection.',
      },
      {
        image: enterpriseImage2,
        heading: 'Transparent Operations',
        subheading: `Enjoy unparalleled transparency with centralized data accessible at any time.`,
      },
      {
        image: enterpriseImage3,
        heading: 'User-Friendly Interface',
        subheading: 'Experience a seamless and intuitive interface without needing to be tech-savvy.',
      },
    ],
    profileFeatureImage: enterpriseFeatureImage,
    profileFeatureHeading: "Comprehensive Workforce Management",
    profileFeatureHeadingContent: `Eliminate the costs of recruiting, training, and onboarding while managing employees efficiently. ProLegion optimizes resources, enhances collaborations, and provides better efficiency, leading a movement to provide comprehensive business solutions to improve your workforce experience.`,
    getStartedImage: `${assetsUrl}client-dashboard.png`
  },
  vendor: {
    // topHeading: "ProLegion: The World's First Marketplace for Workforce Vendors to Connect with New Clients",
    heading: 'Vendor',
    "subheading": "ProLegion is the ultimate software solution designed for vendors, enabling seamless resource and job management at your fingertips.",
    videoLink: "https://www.youtube.com/embed/yg7NSgvYd48?si=ETXjQdcfWazs_8jl",
    cards: [
      {
        image: enterpriseImage1,
        heading: 'Hire the Right Minds',
        subheading: 'The marketplace is flooded with talent. Post jobs and get noticed by the best minds.',
      },
      {
        image: enterpriseImage2,
        heading: 'Find New Client and Resources',
        subheading: `Access the ProLegion marketplace- the world's marketplace designed for IT vendors`,
      },
      {
        image: enterpriseImage3,
        heading: 'A New Era of Client Management',
        subheading: 'Less stress means more clients. Interacting with clients has never been easier.',
      },
    ],
    profileFeatureImage: enterpriseFeatureImage,
    profileFeatureHeading: "Expand Your Clientele Across the Globe.",
    profileFeatureHeadingContent: "Work faster with our AI-powered resume builder and access large markets from your desired demographics. From top-notch resource management to job management tools, we help you accelerate your business growth. With an integrated dashboard, real-time collaboration, and data-driven insights, simplifying your business has never been this easy. Save time, money, and streamline your operations like never before.",
    getStartedImage: `${assetsUrl}vendor-dashboard.png`
  },

  consultant: {
    heading: 'Consultant',
    subheading: `Drive your career to the next level with ProLegion's marketplace & task management system. Stay in touch with your deadlines, & submit timesheets easily. Experience ProLegion.`,
    cards: [
      {
        image: enterpriseImage1,
        heading: 'Connect with the Global Market',
        subheading: 'Easily connect with professionals across your zip code for better opportunities.',
      },
      {
        image: enterpriseImage2,
        heading: 'Network Beyond Your Pincode',
        subheading: 'Find other vendors and reliable people ready to lend a hand.',
      },
      {
        image: enterpriseImage3,
        heading: 'Manage Projects Seamlessly',
        subheading: 'ProLegion’s project pipeline ensures you never miss deadlines, with tools to manage and track your projects.',
      },
    ],
    profileFeatureImage: enterpriseFeatureImage,
    profileFeatureHeading: "Unlock Global Opportunities",
    profileFeatureHeadingContent: "Sign up for ProLegion and access a world of high-paying opportunities to enhance your career. Manage projects, stay on deadlines, track progress, and generate invoices all within ProLegion’s centralized dashboard.",
    getStartedImage: `${assetsUrl}consultant-dasboard.png`
  },
};


export const monthNames = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
]

export function getMonthNumber(monthName) {
  const monthIndex = monthNames.indexOf(monthName);
  if (monthIndex === -1) {
    return null;
  }
  const monthNumber = (monthIndex + 1).toString().padStart(2, '0');
  return monthNumber;
}

export function isDateCurrentValid (str) {
  return new Date(str) < new Date();
}

export function getFileNameFromUrl(url) {
  const pathParts = url.split('/');
  const fileName = pathParts[pathParts.length - 1];
  return decodeURIComponent(fileName);
}

export const gTagKey = 'G-FTF8362CPW'

export const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};


export const experienceList = [
  { label: "fresher", value: '0-1' },
{ label: "1-2", value: '1-2' },
{ label: "2-4", value: '2-4' },
{ label: "4-6", value: '4-6' },
{ label: "6-10", value: '6-10' },
{ label: "10+", value: '10+' }
]