import { createSlice } from "@reduxjs/toolkit";

export const jobSlice = createSlice({
  name: "jobsData",
  initialState: {
    jobCategories: []
  },
  reducers: {
    setJobCategories: (state, action) => {
      state.jobCategories = action.payload
    }
  }
});

export const { setJobCategories } = jobSlice.actions;

export default jobSlice.reducer;