import React, { useEffect, useState } from "react";
import moment from "moment";
import { CustomTable } from "Components";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUserRole } from "Redux/Feature/user";
import { jobTimesheetColumns } from "./Timesheets.columns";

const MonthlyViewTimesheet = ({ jobId, fetchTimesheetByWeek, startDate, endDate, view }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const { role } = useSelector(selectUserRole);
    
    const columns = [
        { header: 'Date', Cell: ({ cell }) => (
                <Stack direction="row" gap={2} alignItems="center">
                  <Typography variant="body2Normal" sx={{ color: '#182743' }}>
                    {cell.getValue()}
                  </Typography>
                </Stack>
              ), size: 220, accessorKey: 'dateRange' },
        { header: 'Mon', size: 50, accessorKey: 'day0' },
        { header: 'Tue', size: 50, accessorKey: 'day1' },
        { header: 'Wed', size: 50, accessorKey: 'day2' },
        { header: 'Thu', size: 50, accessorKey: 'day3' },
        { header: 'Fri', size: 50, accessorKey: 'day4' },
        { header: 'Sat', size: 50, accessorKey: 'day5' },
        { header: 'Sun', size: 50, accessorKey: 'day6' },
        { header: 'Hours', size: 50, accessorKey: 'hours' },
    ];

    const formattedData = data.map((weeks) => {
        const weekData = {
            dateRange: `${moment.utc(weeks.start).format("Do, MMM")} - ${moment.utc(weeks.end).format("Do, MMM")}`,
            hours: `${weeks?.hours || "0"} hrs`,
        };
        for (let i = 0; i < 7; i++) {
            weekData[`day${i}`] = '';
            weeks?.days?.forEach((day) => {
                if (moment.utc(new Date(day?.date)).day() === (i === 6 ? 0 : i + 1)) {
                    weekData[`day${i}`] = day?.hours || '0';
                }
            });
        }
        return weekData;
    });

    const fetchData = async () => {
        setLoading(true);
        const sheet = await fetchTimesheetByWeek(jobId, startDate, endDate);
        if (sheet) {
            setData(sheet);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    return (
        <div className="relative">
            <CustomTable
                state={{
                    isLoading: loading
                }}
                columns={view === 'week' ? jobTimesheetColumns[role] : columns}
                data={view === 'week' ? data : formattedData}
            />
        </div>
    );
};

export default MonthlyViewTimesheet;