import { Box, Button, Typography } from "@mui/material"
import React,{ useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {ReactComponent as Error} from "../../Components/Common/Error.svg";

const ErrorScreen = ({
    statusCode,
    errorHeading = "Something's missing.",
    errorDescription = "Sorry, we can't find that page. You'll find lots to explore on the home page.",
    backButtonText = 'Back to Homepage'
}) => {
    const navigate = useNavigate();
    const useQuery = ()=> {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const profileError = useMemo(()=>{
        return query.get("error");
    },[query]);
    return <Box sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center'
    }}>
        {profileError ? <Box sx={{width: "600px", height: "500px"}}><Error /></Box> : <Typography variant="h1" sx={{
            color: '#0B85BA'
        }}>{statusCode}</Typography>}
        <Typography variant="h4Bold">{profileError ? profileError : errorHeading}</Typography>
        <Typography variant="subtitle2Large">{errorDescription}</Typography>
        <Button variant="contained" sx={{ padding: '15px' }} onClick={() => navigate('/dashboard')}>
            {backButtonText}
        </Button>
    </Box>
}

export default ErrorScreen