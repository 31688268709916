import JobConsultants from "./jobConsultants";
import React, { useState } from 'react';
import ViewSubmittedConsultants from "./viewSubmittedConsultants";
import OtherVendorJobConsultants from "./otherVendorJobConsultants";
import { useLocation } from "react-router-dom";

export default function CurrentConsultants({
  setConsultantUnshared,
  setActive,
  clicked,
  setAreVendorsSelected
}) {
  const tabs = [
    { id: "current", value: "My Resources" },
    { id: "applicant", value: "Applicant" },
    { id: "submitted", value: "Submitted" },
  ];
  const location = useLocation();
  const [defaultTab] = tabs;
  const [activeTab, setActiveTab] = useState(defaultTab.id);

  return (
    <div className="mt-8">
      <div className="flex flex-row items-start justify-between">
        <div className="flex flex-row justify-between mb-8 space-x-2 grow mr-8">
          {tabs.map((tab) => {
            return (
              <div
                key={tab.id}
                onClick={() => {
                  setActiveTab(tab.id);
                }}
                style={{
                  color: activeTab === tab.id ? "#1EA596" : "#787878",
                  borderColor: activeTab === tab.id ? "#1EA596" : "#787878",
                  borderBottomWidth: "5px",
                }}
                className="cursor-pointer border-b-4 w-[50%] border-solid flex justify-center"
              >
                <span className="mb-4">{tab.value}</span>
              </div>
            );
          })}
        </div>
      </div>

      {activeTab == "current" && <JobConsultants clicked={(clicked)} setAreVendorsSelected={setAreVendorsSelected} />}

      {activeTab == "applicant" && (
        <OtherVendorJobConsultants state={location?.state?.details} />
      )}
      {activeTab == "submitted" && (
        <ViewSubmittedConsultants state={location?.state?.details} />
      )}
    </div>
  );
}