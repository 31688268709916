import React, { useState, createContext, useContext } from "react";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import axios from "axios";
import { useAlertProvider } from "Providers/util/Alert";

const apiPath = {
  fetchJobsByProject: "/jobnproject/getjobsbyproject",
  fetchJobsByProjectStandAlone: "/jobnproject",
  addJob: "/jobnproject",
  updateJob: "/jobnproject",
  getAllSkills: "/jobnproject/getallskills",
  assignConsultantToJob: "/jobnproject/assignconsultant",
  assignSubVendorToJob: "/jobnproject/assignsubvendor",
  searchJob: "/jobnproject/searchjob",
  fetchJobDetails: "/jobnproject/getjobbyid",
  fetchJobsHavingTimesheet: "/jobnproject/getjobshavingtimesheet",
  fetchPublicJobs: "/jobnproject/getpublicjobs",
  fetchPublicConsultants: "/user/getpublicconsultants",
};

const nullFn = () => null;
const JobContext = createContext({
  loading: false,
  jobs: {},
  fetchJobsByProject: nullFn,
  addJob: nullFn,
  assignSubVendorToJob: nullFn,
  assignConsultantToJob: nullFn,
  allJobs: [],
  updateJob: nullFn,
  searchJob: nullFn,
  getAllSkills: nullFn,
  fetchJobDetails: nullFn,
  fetchJobsHavingTimesheet: nullFn,
  fetchPublicJobs: nullFn,
  fetchPublicConsultants: nullFn
});

export const useJobProvider = () => useContext(JobContext);
const JobProvider = ({ children, userId }) => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [skills, setSkills] = useState([]);
  const { error, success } = useAlertProvider()

  const fetchPublicConsultants = async (page, limit, serachText) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchPublicConsultants, {
        params:
          serachText.length > 0
            ? {
              page: page,
              size: limit,
              search: serachText,
            }
            : { page: page, size: limit },
      });

      if (res.status === 200 && res.data?.success) {
        setLoading(false)
        return res.data?.result;
      } else {
        throw new Error("Error occurred while fetching consultants");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchPublicJobs = async (page, limit, serachText) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchPublicJobs, {
        params:
          serachText.length > 0
            ? {
              page: page,
              size: limit,
              search: serachText,
            }
            : { page: page, size: limit },
      });

      if (res.status === 200 && res.data?.success) {
        setLoading(false)
        return res.data?.result || [];
      } else {
        throw new Error("Error occurred while fetching jobs");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchJobsByProject = async (projectId, searchedVal) => {
    setLoading(true);
    try {
      let res;
      if (searchedVal) {
        res = await api.get(`${apiPath.fetchJobsByProjectStandAlone}?searchBy=searchbox&search=${searchedVal}&size=100`);
      } else if (projectId) {
        res = await api.get(`${apiPath.fetchJobsByProjectStandAlone}?searchBy=projectId&search=${projectId}&size=100`);
      } else {
        res = await api.get(`${apiPath.fetchJobsByProjectStandAlone}?size=100`);
      }

      if (res.status === 200 && res.data?.result) {
        if (searchedVal) {
          let val = []
          res.data.result?.forEach((data) => { 
            if ((data.projectId === projectId) || (!projectId && !data.projectId)) val = data?.jobs
          })
          setJobs({ ...jobs, [projectId]: val });
        } else setJobs({ ...jobs, [projectId]: res.data.result });
      } else {
        throw new Error("Error occurred while fetching jobs");
      }
    } catch (e) {
      setJobs({ ...jobs, [projectId]: [] });
    } finally {
      setLoading(false);
    }
  };

  const addJob = async (job) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.addJob, job);
      if (res.status === 200 && res.data?.success) {
        setJobs({
          ...jobs,
          [job.project_id]: [...(jobs[job.project_id] ?? []), res.data.result],
        });

        setLoading(false);
        success('Job added successfully')
        return res?.data?.result?.id;
      } else {
        throw new Error("Error occurred while creating new job");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      error(e?.response?.data?.error || 'Something went wrong')
    }
  };

  const updateJob = async (job) => {
    setLoading(true);
    try {
      const res = await api.patch(apiPath.updateJob + `/${job.id}`, job);
      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        success('Job updated successfully')
        return res?.data?.result?.id;
      } else {
        throw new Error("Error occurred while updating the job");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      error(e?.response?.data?.error || 'Something went wrong')
    }
  };

  const searchJob = async (query, type) => {
    setLoading(true);
    try {
      return await api.get(`/jobnproject/searchjob`, { params: { query } });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const assignSubVendorToJob = async ({ job_id, vendor_id, type }, isMessage = true) => {
    setLoading(true);
    if (type === "vendor") {
      try {
        const res = await api.post(apiPath.assignSubVendorToJob, {
          job_id,
          user_id: userId,
          vendor_id,
        });

        if (res.data?.success) {
          setLoading(false);
          isMessage && success('Vendor assigned successfully')
        } else {
          throw new Error("Error occurred while creating new job");
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
        error(e?.response?.data?.error || 'Something went wrong')
      }
    } else {
      const headers = { 'Authorization': `Bearer ${localStorage.getItem('at')}` };
      axios.post(`${process.env.REACT_APP_MARKETPLACE_URL}/marketplace/sharedjob`, {
        "job_id": job_id,
        "assign_to": [vendor_id],
        "status": "Active",
        "type": "JOB"
      }, { headers })
        .then((response) => {
          setLoading(false);
          success('Vendor assigned successfully')
        })
        .catch((error) => {
          setLoading(false);
          error(error?.response?.data?.error || 'Something went wrong')
        })
    }
  };

  const assignConsultantToJob = async ({ job_id, consultant_id }, isMessage = true) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.assignConsultantToJob, {
        job_id,
        consultant_id,
        user_id: userId,
      });

      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        isMessage && success('Consultant assigned successfully')
      } else {
        throw new Error("Error occurred while creating new job");
      }
    } catch (e) {
      setLoading(false);
      error(e?.response?.data?.error || 'Something went wrong')
    }
  };

  const getAllSkills = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getAllSkills);
      if (res.status === 200 && res.data?.success) {
        setSkills(res.data.skill.rows);
      } else {
        throw new Error("Error occurred while fetching skills");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchJobsHavingTimesheet = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchJobsHavingTimesheet, {
        params: {
          userId,
        },
      });

      if (res.status === 200 && res.data?.success) {
        setAllJobs(res.data.result);
      } else {
        throw new Error("Error occurred while fetching jobs");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchJobDetails = async (job_id) => {
    setLoading(true);
    try {
      const res = await api.get(`${apiPath.fetchJobDetails}/${job_id}`);

      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        return res.data.result;
      } else {
        throw new Error("Error occurred while fetching job details");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      return null;
    }
  };

  const value = {
    loading,
    jobs,
    fetchJobsByProject,
    addJob,
    assignSubVendorToJob,
    assignConsultantToJob,
    allJobs,
    updateJob,
    searchJob,
    skills,
    getAllSkills,
    fetchJobDetails,
    fetchJobsHavingTimesheet,
    fetchPublicJobs,
    fetchPublicConsultants
  };

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export default JobProvider;
