import { Box, Button, Typography } from "@mui/material";
import MuiModal from "Components/Common/MuiModal";
import { useCommonUserProvider } from "Providers/Common/User";
import ConnectionProvider, {
  useConnectionProvider,
} from "Providers/Consultant/Connection";
import JobProvider from "Providers/Consultant/Jobs";
import { useAlertProvider } from "Providers/util/Alert";
import api, { baseURL } from "Utils/api";
import { getUserIdFromLocalStorage } from "Utils/helper";
import { useEffect, useState } from "react";

const HybridConsultant = ({vendor, setVendor}) => {
  const { success, error } = useAlertProvider();
  const {
    fetchVendor,
    vendorRequest,
    fetchvendorRequests
  } = useConnectionProvider();
  const { getApprovalStatus } = useCommonUserProvider();
  const [isHybridConsultant, setIsHybridConsultant] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [approvedHybridConsultant, setApprovedHybridConsultant] =
    useState(false);

  const fetchVend = async () => {
    const resp = await fetchVendor(getUserIdFromLocalStorage());
    setVendor(resp);
  };

  useEffect(() => {
    getApprovalStatus()
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.status) {
            //Request Approved
            setIsHybridConsultant(true);
            setApprovedHybridConsultant(true);
          } else {
            //Awaiting Approval
            setIsHybridConsultant(true);
            setApprovedHybridConsultant(false);
          }
        } else {
          //Not a hybrid consultant
          setApprovedHybridConsultant(false);
          setIsHybridConsultant(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    fetchVend();
    fetchvendorRequests();
  }, []);

  const acceptVendor = async (vendorId) => {
    const data = {
      vendor_id: vendorId,
      consultant_id: localStorage.getItem("userId"),
    };
    api
      .put(`${baseURL}/user/acceptinvitevcm?for=consultant`, data)
      .then((data) => {
        if (data.data.success) {
          fetchVendor().then((data) => setVendor(data));
          fetchvendorRequests();
          success("Vendor Approved");
        } else {
          error("Some Error occurred");
        }
      })
      .catch((err) => {
        error("Some Error occurred");
        console.error(err);
      });
  };
  const rejectVendor = async (vendorId) => {
    const data = {
      vendor_id: vendorId,
      consultant_id: localStorage.getItem("userId"),
    };
    api
      .delete(`${baseURL}/user/declineinvitevcm?for=consultant`, { data })
      .then((data) => {
        if (data.data.success) {
          fetchVendor().then((data) => setVendor(data));
          fetchvendorRequests();
        } else {
          error("Some Error occurred");
        }
      })
      .catch((err) => {
        error("Some Error occurred");
        console.error(err);
      });
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  }

  const handleRequestDisconnectVendor = async () => {
    try {
      const res = await api.post("/user/disconnectvcm", {
        "consultant_id": localStorage.getItem('userId'),
        "request_type":"REQUEST",
      })
      if(res?.data?.success) {
        success(res?.data?.msg);
        setModalOpen(false);
      } else if(!res?.data?.success) {
        error(res?.data?.msg || res?.data?.error)
      } else {
        error(res?.data?.error)
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
        <Box>
          {approvedHybridConsultant ? (
            vendor ? (
              <Box
                sx={{
                  background: "rgba(19, 149, 136, 0.2)",
                  borderRadius: "16px",
                }}
                className="mt-4 p-4 flex flex-col space-y-2"
              >
                <div className="flex justify-between items-center">
                  <span className="font-bold text-lg">Vendor Details</span>
                  {vendor.isApproved ? (
                    <span className="bg-[#2ebaa34d] p-1 rounded-md text-[#2ebaa3]">
                      Connected
                    </span>
                  ) : (
                    vendor.requestFrom === "CONSULTANT" && (
                      <span className="bg-yellow-200 p-1 rounded-md text-yellow-500">
                        Requested
                      </span>
                    )
                  )}
                </div>
                <div>
                  Vendor Name:{" "}
                  <span className="text-gray-700">
                    {vendor.vendor?.company}
                  </span>
                </div>
                <div>
                  Vendor Email:{" "}
                  <span className="text-gray-700">{vendor.vendor?.email}</span>
                </div>
              </Box>
            ) : null
          ) : (
            <div>
              {vendor ? (
                <Box
                  sx={{
                    background: "rgba(19, 149, 136, 0.2)",
                    borderRadius: "16px",
                  }}
                  className="mt-4 p-4 flex flex-col space-y-2"
                >
                  <div className="flex justify-between items-center">
                    <span className="font-bold text-lg">Vendor Details</span>
                    {vendor.isApproved ? (
                      <span className="bg-[#2ebaa34d] p-1 rounded-md text-[#2ebaa3]">
                        Connected
                      </span>
                    ) : (
                      vendor.requestFrom === "CONSULTANT" && (
                        <span className="bg-yellow-200 p-1 rounded-md text-yellow-500">
                          Requested
                        </span>
                      )
                    )}
                  </div>
                  <div>
                    Vendor Name:{" "}
                    <span className="text-gray-700">
                      {vendor.vendor?.company}
                    </span>
                  </div>
                  <div className="flex flex-wrap justify-between items-center">
                    <div className="flex">
                      <span>Vendor Email:{" "}</span>
                      <span className="text-gray-700 pl-1">
                        {vendor.vendor?.email}
                      </span>
                    </div>
                    <div className="flex min-[300px]:justify-end min-[300px]:pt-4 md:pt-0 min-[300px]:w-full md:w-auto">
                      <div className="flex bg-[#fed8e8] p-1 rounded-md text-[#981c1c] hover:shadow-inner hover:cursor-pointer" onClick={handleModalOpen}>Request Disconnection</div>
                    </div>
                  </div>
                  <MuiModal
                    open={isModalOpen}
                    onCancel={() => setModalOpen(false)}
                  >
                    <Box padding={"1rem"} textAlign={"center"}>
                      <Typography variant="defaultFont" fontSize={"20px"}>Do you want to disconnect from this vendor ?</Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"} padding={"1rem"}>
                      <Button sx={{backgroundColor: "#ff0000", color: "#ffffff", width: "25%", "&:hover": {backgroundColor: "#ff0000", boxShadow: "0 0 4px #ff0000"}, fontSize: "18px"}} onClick={handleRequestDisconnectVendor}>Yes</Button>
                      <Button sx={{backgroundColor: "#2ebaa34d", color: "#2ebaa3", width: "25%", "&:hover": {backgroundColor: "#2ebaa34d", boxShadow: "0 0 4px #2ebaa34d"}, fontSize: "18px"}} onClick={()=> setModalOpen(false)}>No</Button>
                    </Box>
                  </MuiModal>
                </Box>
              ) : (
                <div></div>
              )}

              {isHybridConsultant && !approvedHybridConsultant && (
                <Box
                  className="w-full p-4 rounded-xl text-center mt-5"
                  sx={{ backgroundColor: "rgba(19, 149, 136, 0.2)" }}
                >
                  <h1>
                    Your self employed consultant request has been submitted and
                    the ProLegion staff will be validating and accepting it
                    shortly.
                  </h1>
                </Box>
              )}

              <div className="mt-4 overflow-hidden">
                {vendorRequest.length > 0 && (
                  <>
                    <Box
                      className="text-xl"
                      sx={{
                        color: "#139588",
                      }}
                    >
                      Vendor Requests
                    </Box>

                    {vendorRequest.map((request) => {
                      return (
                        <Box
                          sx={{
                            background: "rgba(19, 149, 136, 0.2)",
                            borderRadius: "16px",
                          }}
                          className="mt-4 p-4 flex flex-col space-y-2"
                        >
                          <div className="flex justify-between items-center">
                            <span className="font-bold text-lg">
                              Vendor Details
                            </span>
                          </div>
                          <div>
                            Vendor Name:{" "}
                            <span className="text-gray-700">
                              {request.vendor?.company}
                            </span>
                          </div>
                          <div>
                            Vendor Email:{" "}
                            <span className="text-gray-700">
                              {request.vendor?.email}
                            </span>
                          </div>
                          <div className="flex flex-row justify-end mt-4 space-x-4">
                            <Button
                              onClick={() => rejectVendor(request.vendor_id)}
                              variant="contained"
                              sx={{
                                height: "35px",
                                borderRadius: "5px",
                              }}
                            >
                              Reject
                            </Button>
                            <Button
                              onClick={() => acceptVendor(request.vendor_id)}
                              variant="contained"
                              sx={{
                                height: "35px",
                                background: "#139588",
                                borderRadius: "5px",
                                color: "white",
                              }}
                            >
                              Approve
                            </Button>
                          </div>
                        </Box>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
        </Box>
  );
};

const HybridConsultantWrapper = ({ vendor, setVendor }) => {
  return (
    <ConnectionProvider>
      <JobProvider>
        <HybridConsultant vendor={vendor} setVendor={setVendor} />
      </JobProvider>
    </ConnectionProvider>
  );
};

export default HybridConsultantWrapper;
