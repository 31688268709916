import { ChevronRight } from '@mui/icons-material';
import {
  Button, Divider, Typography, Paper, Grid, styled, Stack,
} from '@mui/material';
import React from 'react';

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const ContentText = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  flexGrow: 1,
  display: '-webkit-box',
  '-webkit-line-clamp': '6',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const ImageContainer = styled('div')(({ theme }) => ({
  borderRadius: '12px',
  width: '100%',
  height: 'auto',
  overflow: 'hidden',
}));

export default function BlogCardComponent(props) {
  return (
    <Grid item lg={5} md={5}>
      <PaperStyled elevation={2}>
        <Stack direction="column" gap={2}>
          <ImageContainer>
            <img src={props.image} alt={props.title} style={{ width: '100%' }} />
          </ImageContainer>
          <Typography variant="h5" color="common.black">{props.title}</Typography>
          <Divider sx={{ width: 10 }} />
          <ContentText variant="body1" dangerouslySetInnerHTML={{ __html: props.content }} />
        </Stack>
        <Button onClick={props.buttonProps?.onClick} variant="text" color="secondary" size="large" endIcon={<ChevronRight />}>
          {props?.buttonProps.label}
        </Button>

      </PaperStyled>
    </Grid>
  );
}
