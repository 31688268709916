import Swal from 'sweetalert2';
import {
  Input, Modal,
} from 'antd';
import isEmail from 'validator/lib/isEmail';
import { timesheetStatus } from '../../Utils/constants';
import { usePublicTimesheetProvider } from 'Providers/Public/PublicTimesheet.provider';
import React from 'react';

function ApproveRejectModal({ modal, setModal, emailFromToken, timesheetId, setApprovalDetails }) {
  // Modal
  const { approveTimesheet, rejectTimesheet } = usePublicTimesheetProvider();
  const [comments, setComments] = React.useState('');
  const [email, setEmail] = React.useState('');

  return (
    <Modal
      open={modal.open}
      onCancel={() => setModal({ open: false, type: timesheetStatus.approved })}
      okButtonProps={{ className: 'bg-[#1677FF]' }}
      okText={
        modal.type === timesheetStatus.rejected
          ? 'Reject'
          : 'Approve'
      }
      closable={false}
      onOk={() => {
        if (modal.type === timesheetStatus.rejected) {
          if (comments.length === 0) {
            Swal.fire(
              'Error',
              'Please enter a reason for rejection',
              'error',
            );
          } else if (!isEmail(emailFromToken ?? email)) {
            Swal.fire(
              'Error',
              'Please enter a valid email address',
              'error',
            );
          } else if (timesheetId) {
            rejectTimesheet(
              timesheetId,
              comments,
              emailFromToken ?? email,
            ).then(() => {
              setModal({
                open: false,
                type: timesheetStatus.approved,
              });

              setApprovalDetails({
                approvalDate: new Date(),
                approvedByExternal: emailFromToken ?? email,
                approved_by: null,
                clientComments: comments,
              });

              window.location.reload();
            });
          }
        } else if (!isEmail(emailFromToken ?? email)) {
          Swal.fire(
            'Error',
            'Please enter a valid email address',
            'error',
          );
        } else if (timesheetId) {
          approveTimesheet(
            timesheetId,
            comments,
            emailFromToken ?? email,
          ).then(() => {
            setModal({
              open: false,
              type: timesheetStatus.approved,
            });

            setApprovalDetails({
              approvalDate: new Date(),
              approvedByExternal: emailFromToken ?? email,
              approved_by: null,
              clientComments: comments,
            });

            window.location.reload();
          });
        }
      }}
    >
      <p className="font-medium mb-1 ml-0.5">Email</p>
      <input
        className="w-full text-sm mb-3"
        maxLength={100}
        type="email"
        disabled={Boolean(emailFromToken)}
        value={emailFromToken ?? email}
        onChange={
          emailFromToken ? null : (e) => setEmail(e.target.value)
        }
      />

      <p className="font-medium mb-1 ml-0.5">Comments</p>
      <input
        className="w-full text-sm mb-3 border-2 border-black-500"
        maxLength={250}
        onChange={(e) => setComments(e.target.value)}
      />
    </Modal>
  )
}

export default ApproveRejectModal;