import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { Empty } from 'antd';
import { useMediaQuery } from '@mui/material';

const getNestedObject = (obj, keyPath) => {
  const keys = keyPath.split('.');
  let currentObj = obj;
  for (const key of keys) {
    if (!currentObj[key]) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }
  return currentObj;
};

const descendingComparator = (a, b, orderBy) => {
  const value1 = getNestedObject(a, orderBy ?? '');
  const value2 = getNestedObject(b, orderBy ?? '');
  return value2 < value1 ? -1 : value2 > value1 ? 1 : 0;
};

export const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
  headCells,
  hasCustomRow = true,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ background: '#F9FAFB' }}>
        {headCells.map((headCell, index) => {
          const key = headCell.key
            ? headCell.key
            : hasCustomRow
              ? headCell.label !== ''
                ? headCell.label
                : index
              : headCell.id !== ''
                ? headCell.id
                : index;
          return (
            <TableCell
              key={key}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === key ? order : false}
              colSpan={headCell.colSpan ? headCell.colSpan : 1}
            >
              <TableSortLabel
                active={orderBy === key}
                direction={orderBy === key ? order : 'asc'}
                onClick={createSortHandler(key)}
              >
                {headCell.label}
                {orderBy === key ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ title }) {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography sx={{ flex: '1 1 100%' }} variant='h6' component='div'>
        {title}
      </Typography>

      <Tooltip title='Filter list'>
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

function CustomTable({
  title = '',
  headCells = [],
  rows = [],
  pageIndex = 0,
  onClickRow = null,
  CustomRow = null,
  CustomEmpty = null,
  ExtraComponents = null,
  showPagination = true,
  showHeaderOnEmpty = false,
  showToolbar = true,
  showDensePadding = true,
  defaultRowsPerPage = 25,
  rowsPerPageOptions = [5, 10, 25],
  setActive,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();
  const [page, setPage] = useState(pageIndex);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [dense, setDense] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {showToolbar ? <EnhancedTableToolbar title={title} /> : null}
        <TableContainer sx={{ width: isMobile ? '400px' : '100%', overflowX: 'scroll' }} className=''>
          <Table sx={{ minWidth: 650 }} size={dense ? 'small' : 'medium'}>
            {rows.length || showHeaderOnEmpty ? (
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
                hasCustomRow={CustomRow !== null}
              />
            ) : null}
            <TableBody>
              {rows && rows?.length > 0 && rows
                .slice(
                  ...(showPagination
                    ? [page * rowsPerPage, page * rowsPerPage + rowsPerPage]
                    : [0]),
                )
                .map((row, index) => (CustomRow === null ? (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => onClickRow(row)}
                    className={onClickRow === null ? '' : 'cursor-pointer'}
                  >
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {row[headCell.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : (
                  <CustomRow
                    setActive={setActive}
                    row={row}
                    key={row.id}
                    index={index}
                  />
                )))}
            </TableBody>
          </Table>
        </TableContainer>

        {rows && rows?.length === 0 ? (
          CustomEmpty ? (
            <CustomEmpty />
          ) : (
            <Empty className='pt-4 pb-6' />
          )
        ) : showPagination ? (
          <TablePagination
            style={{ zIndex: 0 }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}

        {ExtraComponents ? (
          <div className=''>
            <ExtraComponents />
          </div>
        ) : null}
      </Paper>

      {/* {showDensePadding ? (
        <FormControlLabel
          label='Dense padding'
          control={
            <Switch
              checked={dense}
              onChange={(e) => setDense(e.target.checked)}
            />
          }
        />
      ) : null} */}
    </Box>
  );
}

export default CustomTable;
