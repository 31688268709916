import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Heading1 from 'Components/Common/semantic_tags/Heading1';
import Typography from 'Components/Common/semantic_tags/Typography';

const ProfileHeading = ({ heading = '', subHeading = '', isEdit, profile }) => {
  const [caption, setCaption] = useState('')

  useEffect(() => {
    if (isEdit) setCaption((profile?.first_name || '') + ' ' + (profile?.last_name || ''))
  }, [isEdit])


  const navigate = useNavigate();
  return (
    <div className='flex justify-between items-center'>
      <Heading1 variant='h4' className='flex items-center'>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          className='bg-gray-200 w-8 h-8 mr-5 rounded-md cursor-pointer'
        />
        {heading}
      </Heading1>
      <div className='flex justify-end flex-col'>
        {caption && <Typography className='text-sm'><strong>Resource:</strong> {caption}</Typography>}
        <Typography className='text-sm'>{subHeading}</Typography>
      </div>
    </div>
  );
};

export default memo(ProfileHeading);
