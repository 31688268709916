import React, { useState, useEffect } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Link,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getFAQ } from 'Pages/Faq/components/Helper';
import { FullWidthWrapper } from 'Components/Common';

export function FAQSection({ isSmallScreen }) {
  const [faq, setFaq] = useState([]);

  const fetchFaqs = () => {
    getFAQ()
      .then((res) => {
        setFaq(res.data?.faq?.rows);
      });
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return !!faq?.length && (
    <FullWidthWrapper>
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ my: 10 }}
      >
        <Typography variant={isSmallScreen ? 'h4Bold' : 'h1'} textAlign="center">
          Frequently Asked Questions
        </Typography>
        <Stack width="80%" marginTop="40px">
          {(faq || []).slice(0, 5).map((fa, i) => (
            <Accordion
              key={fa.title}
              sx={{
                padding: '10px',
                boxShadow: 'none',
                marginBottom: '30px',
                border: '1px solid #C2C2C2',
              }}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls={`panel${i}content`}
                id={`pane${i}-header`}
              >
                <Typography variant="subtitle2Large">{fa.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>{fa.description}</AccordionDetails>
            </Accordion>
          ))}
          <Link color="#0B85BA" underline="hover" textAlign="center" href="/faq">
            More FAQs
          </Link>
        </Stack>
      </Stack>
    </FullWidthWrapper>
  );
}
