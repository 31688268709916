import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import paw from '../Assets/paw.png';
import { assetsUrl } from 'Utils/constants';

function RoleCollapseMenu({ details }) {
  // const dashboardForMarketingMobile = `${assetsUrl}dashboard-screen.png`;
  // const dashboardForMarketing = `${assetsUrl}dashboard-for-marketing.png`;
  const collapsebackground = `${assetsUrl}marketing-page-image.png`;
  const mobiledashboardForMarketing = `${assetsUrl}mobile-marketing-page-background.png`;
  const dashboardForMarketing = `${assetsUrl}consultant-dasboard.png`;
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
    display={"flex"}
    flexDirection={"column"}
    sx={{
      padding: '40px 0px',
      backgroundImage: `${isSmallScreen ? `url(${mobiledashboardForMarketing})` : "linear-gradient(0deg, rgba(57,133,182,0.85) 0%, rgba(57,133,182,0.85) 0%)"}, 
      url(${collapsebackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }}
    >
        {/**#1CA2DD0A rgba(28, 162, 221, 0.04) */}
        {details?.topHeading && !isSmallScreen && <div className="flex justify-center">
          <h4 className='text-[#fff] font-bold pl-8 pr-8 sm:text-sm md:text-xl'>{details?.topHeading}</h4>
        </div>}
        <Stack
          marginLeft={isSmallScreen ? '0' : '100px'}
          sx={{justifyContent:{xs: "center", md: "space-between"}, flexDirection: {xs: "column", md: "row"}, paddingTop:{xs: "0", md:'100px'}}}
        >
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} sx={{alignItems: {xs: "center", md: "unset"}, padding: {xs: "1rem",sm: "1rem"}, width: {xs: "100%", md: "50%"}}}>
          <Typography
            sx={{ color: {md:'white', xs: "#3985B6"}, fontSize: {xs: "35px", sm: "55px", md: "65px"}, lineHeight: {xs:"normal", md: "unset"}, paddingBottom: {xs: "10px", md: "0"} }}
            variant="h1"
          >
            {details.heading}
          </Typography>
          <Typography
            sx={{ color: {xs: "#00294370", md: 'white'}, fontSize: {xs: "12px", sm: "16px", md: "25px"}, fontWeight: "lighter", width: {xs: "unset", md: "70%"}, textAlign: {xs: "center", md: "unset"} }}
            variant="body1Normal"
          >
            {details.subheading}
          </Typography>
          <Button
            sx={{
              display: {xs: "none", md: "unset"},
              padding: '10px',
              width: {xs: "120px", md:  '170px'},
              background: 'none',
              border: "1px solid #fff",
              borderRadius: "15px",
              fontSize: {xs: "11px", md: "16px"},
              marginTop: '20px',
              color: "#fff"
            }}
            onClick={() => navigate('/signin')}
          >
            TRY PROLEGION
          </Button>
          </Box>
          <Box sx={{width: {xs: "100%", md: "50%"}, height: {xs: "unset"}, marginTop: {xs: "1rem", md: 0}, padding: {xs: "0 2rem", md: "0"}}}>
          {details?.videoLink ? <iframe width="100%" height={`${ isMobileScreen ? "250px" : isSmallScreen ? "400px" : "500px"}`} style={{border: `${isSmallScreen ? "6px solid #000" : "unset"}`, borderRadius: "12px" }} src={details.videoLink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          :<img src={dashboardForMarketing} style={{width: "100%", height: "100%", border: `${isSmallScreen ? "6px solid #000" : "unset"}`, borderRadius: "12px"}} />}
          </Box>
        </Stack>
        <Box>
          <Divider
            sx={{
              marginTop: '100px',
              background: 'white',
              margin: isSmallScreen ? '60px 0px' : '100px 100px',
              marginBottom: '50px',
            }}
          />
        </Box>
        <Stack
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          gap={4}
          justifyContent="center"
          // sx={{ margin: isSmallScreen ? '0px 20px' : '0px 100px' }}
        >
          <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"} sx={{width: "100%"}}>
          {
            details.cards.map((card) => (
              <Stack
                sx={{ width: {xs: "200px", md: isSmallScreen ? '90%' : '20%'}, backgroundColor: "#fff", padding: {xs: "1rem", md: "2rem"}, margin: {xs: "8px", md: "2rem"}, borderRadius: "12px", boxShadow: {xs: "0 4px 4px #00000025", md: "none"} }}
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Avatar src={card.image} sx={{width:"40px", height:"40px", borderRadius: "0"}} />
                <Typography variant="subtitle2Large" color="#252B42" padding={"8px 0"} sx={{fontSize:{xs: "15px", md:"23px", lineHeight: "normal"}}}>
                  {card.heading}
                </Typography>
                <Typography variant="body2Medium" color="#737373" fontWeight={"bolder"} sx={{fontSize: {xs:"9px", md: "13px"}, lineHeight: {xs :"1.2", md: "normal"}}}>
                  {card.subheading}
                </Typography>
              </Stack>
            ))
          }
          </Box>
        </Stack>
      </Box>
  );
}

export default RoleCollapseMenu;
