import { Box } from '@mui/material';
import { InvoiceCreateForm } from 'Components/InvoiceCreateForm';
import { InvoicePreview } from 'Components/InvoicePreview';
import AppDefaultPage from 'Pages/AppDefaultPage/AppDefault.page';
import FlexibleInvoice from 'Pages/InvoicesPage/FlexibleInvoice';
import InvoicesTabs from 'Pages/InvoicesPage/Invoices.tabs';
import JobsBasedInvoice from 'Pages/InvoicesPage/JobsBasedInvoice/JobsBasedInvoice';
import { fetchJobs } from 'Pages/Task/task.api';
import api from 'Utils/api';
import React from 'react';
import { Link, defer } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';

export const invoicesRoutes = [
  {
    path: 'invoices',
    handle: {
      crumb: () => <Link to='/dashboard/invoices'>Invoices</Link>,
    },
    element: (
      <RequireAuth>
        <AppDefaultPage />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <InvoicesTabs />
          </RequireAuth>
        ),
      },
      {
        path: 'create',
        element: (
          <RequireAuth>
            <InvoiceCreateForm />
          </RequireAuth>
        ),
        loader: async () => {
          const userId = localStorage.getItem('userId');
          const params = { id: userId, for: 'vendor', requestType: 'MULTI_VENDOR' };
          const res = await api.get('/user/listcvmusers', { params });
          if (res.status === 200) {
            return defer({
              clients: res?.data?.result || [],
            });
          }
        },
      },
      {
        path: 'flexible/create',
        element: (
          <RequireAuth>
            <FlexibleInvoice />
          </RequireAuth>
        ),
        loader: async () => {
          const userId = localStorage.getItem('userId');
          const params = { id: userId, for: 'vendor' };
          const res = await api.get('/user/listcvmusers', { params });
          if (res.status === 200) {
            return defer({
              clients: res?.data?.result || [],
            });
          }
        },
      },
      {
        path: 'jobs/create',
        element: (
          <RequireAuth>
            <JobsBasedInvoice />
          </RequireAuth>
        ),
        // loader: async () => {
        //   // const userId = localStorage.getItem('userId');
        //   // const params = { id: userId, for: 'vendor' };
        //   const res = await fetchJobs();
        //   if (res.data) {
        //     return defer({
        //       jobs: res?.data || [],
        //     });
        //   }
        // },
      },
    ],
  },
  {
    path: 'invoices/:invoiceId',
    handle: {
      crumb: () => <Link to='/dashboard/invoices'>Invoices</Link>,
    },
    element: (
      <RequireAuth>
        <Box>
          <div className="pb-4">
          <Breadcrumbs />
          </div>
          {/* <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
          </Stack> */}
          <InvoicePreview />
        </Box>
      </RequireAuth>
    ),
  },
  {
    path: 'invoice/:invoiceId',
    handle: {
      crumb: () => <Link to='/dashboard/invoices'>Invoices</Link>,
    },
    element: (
      <Box>
        {/* <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
          <Breadcrumbs />
        </Stack> */}
        <InvoicePreview isPublic />
      </Box>
    ),
  },
];
