import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SubscriptionsTable } from "./SubscriptionsTable";
import { SubscriptionDetails } from "./SubscriptionDetails";
import { fetchActiveSubscription, fetchCustomerDetails } from "services/subscriptions.service";
import { Plans } from "./Plans";
import PricingCards from "Pages/Settings/UserManagementTab/PricingPlans.jsx/PricingCards";

export const Subscriptions = () => {
  const [activeSubscription, setActiveSubscription] = useState({});
  const [plan, setPlan] = useState({});
  const [allocatedUsage, setAllocatedUsage] = useState({});
  const [isUpgradePlan, setIsUpgradePlan] = useState(false);
  const [currentUsage, setCurrentUsage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(window.location.search)

  const fetchActiveSubscriptionDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchActiveSubscription();

      setPlan(data.plan);
      setCurrentUsage(data.usage);
      setActiveSubscription(data.subscription);
      setAllocatedUsage(data.total_allocated_usage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveSubscriptionDetails();
    if (params.get('screen') === 'plans') {
      setIsUpgradePlan(true)
    }
  }, []);

  return (
    <>
      {isUpgradePlan ?
      <Box>
        {/* <Plans setIsUpgradePlan={setIsUpgradePlan} activeSubscription={activeSubscription}/> */}
        <PricingCards activeSubscription={activeSubscription} setIsUpgradePlan={setIsUpgradePlan} />
      </Box>
      :<Box sx={{ padding: "16px" }}>
        <Grid lg={16}>
          <SubscriptionDetails
            plan={plan}
            setIsUpgradePlan={setIsUpgradePlan}
            currentUsage={currentUsage}
            allocatedUsage={allocatedUsage}
            activeSubscription={activeSubscription}
          />
        </Grid>
        <Grid>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ typography: "body1Bold" }}
            className="py-4"
          >
            Billing history
          </Typography>
          <SubscriptionsTable activeSubscription={activeSubscription} />
        </Grid>
      </Box>}
    </>
  );
};
