function downloadExampleResourceCSV() {
  const csvContent = 'First_Name,Last_Name,Email\nAlex,smith,alex.smith@domain.com';
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'example.csv';
  a.click();
  window.URL.revokeObjectURL(url);
}

// function downloadExampleVendorBulkJobCSV() {
//   const csvContent = `Job Title*,Job Description(Upto 3000 Words) *,Job Category(By Default IT)*,"Skills(Comma Seperated Values - skill1,skill2)*",Currency (INR or USD)*,Rate Type (HOURLY or MONTHLY)*, Rate, "Location(REMOTE, ONSITE or HYBRID)*",Job Start Date (DD MMM, YYYY),Record Timesheet Checkin/Checkout time (Yes/No),Consultant First Name,Consultant Last Name,Consultant Email ID,Client Organization Name,Client First Name,Client Last Name,Client Email\nSoftware Engineer,Detailed Job Description.,IT(Information Techology,"Java,Python,C++,JavaScript,software development,problem-solving,code reviews,version control (Git),agile methodologies,debugging,scalable applications,clean code,cross-functional collaboration,software design,efficient coding",INR,MONTHLY,50000,Remote,01/07/2024,Yes,Alex,Smith,alex.smith@domain.com,Sample Tech Solutions Pvt. Ltd.,Robin,Smith,robin.smith@domain.com`;
//   const blob = new Blob([csvContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement("a");
//   a.href = url;
//   a.download = "example.xlsx";
//   a.click();
//   window.URL.revokeObjectURL(url);
// }

// function downloadExampleClientBulkJobCSV() {
//   const csvContent = `Job Title*,Job Description(Upto 3000 Words)*,Job Category(By Default IT)*,"Skills(Comma Seperated Values - skill1,skill2)*",Currency (INR or USD)*,Rate Type (Hourly or Monthly)*,Rate,"Location(REMOTE, ONSITE or HYBRID)*",Job Start Date (DD MMM, YYYY),Record Timesheet Checkin/Checkout time (Yes/No),Vendor Organization Name, Vendor First Name, Vendor Last Name, Vendor Email, Consultant First Name, Consultant Last Name, Consultant Email ID\nSoftware Engineer,Detailed Job Description.,IT(Information Techology,"Java,Python,C++,JavaScript,software development,problem-solving,code reviews,version control (Git),agile methodologies,debugging,scalable applications,clean code,cross-functional collaboration,software design,efficient coding",INR,MONTHLY,50000,Remote,01/07/2024,Yes,Sample Tech Solutions Pvt. Ltd.,Robin,Smith,robin.smith@domain.com,Alex,Smith,alex.smith@domain.com`;
//   const blob = new Blob([csvContent], { type: "text/csv" });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement("a");
//   a.href = url;
//   a.download = "exampleclient.xlsx";
//   a.click();
//   window.URL.revokeObjectURL(url);
// }

function downloadExampleCSV() {
  const csvContent = 'CompanyName,ContactFirstName,ContactLastName,Email,PhoneNo.\nBCG,Alex,smith,alex.smith@domain.com,9876543210';
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'example.csv';
  a.click();
  window.URL.revokeObjectURL(url);
}

function downloadExampleResourcesCSV() {
  const csvContent = 'firstName,lastName';
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'example.csv';
  a.click();
  window.URL.revokeObjectURL(url);
}

export { downloadExampleCSV, downloadExampleResourceCSV, downloadExampleResourcesCSV };
