const paletteOverrides = {
  primary: {
    light: '#0B85BA',
    main: '#0B85BA',
    dark: '#0B85BA',
  },
  secondary: {
    light: '#0B85BA',
    main: '#0B85BA',
    dark: '#003B54',
  },
  error: {
    light: '#EF5350',
    main: '#D32F2F',
    dark: '#B37500',
  },
  warning: {
    light: '#FF9800',
    main: '#EF6C00',
    dark: '#C62828',
  },
  info: {
    light: '#03A9F4',
    main: '#0288D1',
    dark: '#01579B',
  },
  success: {
    light: '#4CAF50',
    main: '#2E7D32',
    dark: '#1B5E20',
  },
  text: {
    primary: '#00000099',
    secondary: '#666666',
    disabled: '#999999',
    hint: '#B2B2B2',
  },
  grey: {
    main: '#6B7280',
    light: '#4CAF50',
    dark: '#1B5E20',
  },
  mode: 'light',
};

export default paletteOverrides;
