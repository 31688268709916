import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Stack } from "@mui/material";
import {
  FullWidthWrapper,
  MarketingSiteWrapper,
} from "../../Components/Common";
import { PageHeader } from "../../Components/ContentBlocks/PageHeader";
import { getFAQs } from "./components/Helper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="w-full"
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const categories = ["General", "Client", "Vendor", "Consultant"];

export default function FaqPage() {
  const [value, setValue] = React.useState(0);
  const [qa, setQa] = React.useState({
    General: [],
    Client: [],
    Vendor: [],
    Consultant: [],
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectIndex, setSelectedIndex] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchFaqs = async () => {
    setIsLoading(true);
    const updatedQa = {};
    for (const category of categories) {
      const res = await getFAQs(category);
      updatedQa[category] = res.data?.faq;
    }
    setQa(updatedQa);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  React.useEffect(() => {
    fetchFaqs();
  }, []);

  function QAList({ faqs }) {
    return (
      <Grid item container>
        {faqs?.map((faq, i) => (
          <Accordion
            key={faq.title}
            sx={{
              padding: "10px",
              width: '100%',
              boxShadow: "none",
              marginBottom: "30px",
              border: "1px solid #C2C2C2",
            }}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls={`panel${i}content`}
              id={`pane${i}-header`}
            >
              <Typography variant="subtitle2Large">{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{faq.description}</AccordionDetails>
          </Accordion>
          // <Grid item key={faq?.id} mb={3} xs={12}>
          //   <Typography variant="body1" fontWeight={500} mb={1}>{faq?.title}</Typography>
          //   <Typography variant="body1">
          //     {faq?.description}
          //   </Typography>
          // </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      <PageHeader title="FAQs" content="Frequently Asked Questions" />
      <FullWidthWrapper>
        <Grid container mt={0}>
          <Grid item container md={3} justifyContent="flex-end">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              TabIndicatorProps={{
                style: { width: "5px", borderRadius: "10px" },
              }}
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Enterprise" {...a11yProps(1)} />
              <Tab label="Vendor" {...a11yProps(2)} />
              <Tab label="Consultant" {...a11yProps(3)} />
            </Tabs>
          </Grid>
          <Grid item container md={12} sx={{ marginLeft: '10px', marginTop: '20px' }}>
            {isLoading ? (
              <Stack justifyContent="center" alignItems="center" width="100%">
                <CircularProgress sx={{ color: "primary.main" }} />
              </Stack>
            ) : (
              <>
                <TabPanel value={value} index={0}>
                  <QAList faqs={qa?.General} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <QAList faqs={qa?.Client} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <QAList faqs={qa?.Vendor} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <QAList faqs={qa?.Consultant} />
                </TabPanel>
              </>
            )}
          </Grid>
        </Grid>
      </FullWidthWrapper>
    </>
  );
}
