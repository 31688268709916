import React from 'react';

const CustomTextArea = ({ id, label,validationType="",value,onChange,type,className,...rest }) => {
  return (
    <div>
         {label && <label className='font-semibold' htmlFor={rest.name}>{label}</label>}
    <textarea
      {...rest}
      value={value}
      onChange={onChange}
      className={`appearance-none border focus:ring-2 focus:outline-none  rounded-xl w-full py-3 px-3 text-gray-700 leading-tight focus:shadow-outline ${className}`}
      />
      </div> 
  );
};

export default CustomTextArea;
