import themeBase from '../themeBase';

export const MuiTabsOverride = {
  styleOverrides: {
    root: {
      borderBottom: '1px solid #E5E7EB',
      '.MuiTab-root': {
        ...themeBase.typography.body1Normal,
        textTransform: 'none',
        minWidth: '140px',

        '&.Mui-selected': {
          color: '#0B85BA',
        },
      },
      '.MuiTabs-indicator': {
        height: '1px',
        backgroundColor: '#0B85BA',
      },
    },
  },
};
