import themeBase from "../themeBase";

export const MuiAutocompleteOverride = {
  styleOverrides: {
    root: {
      'label + &': {
        marginTop: themeBase.spacing(3),
        backgroundColor: 'white'
      },
      'label': {
        color: '#00000099',
        fontWeight: 700
      },
      '.MuiInput-root' : {
          backgroundColor: 'white'
      },
      '.MuiOutlinedInput-root': {
        padding: '5px 12px !important',
        Height: '42px',
        backgroundColor: 'white',
        '.MuiInputLabel-formControl' : {
          color: 'blue'
        }
      },
      '.MuiAutocomplete-input': {
        padding: '1.5px 4px 7.5px 5px !important',
        backgroundColor: 'white'
      },

      'fieldset > legend > span': {
        display: 'none',
      },
    },
  },
};
