import React from "react";
import { VerifiedOutlined } from "@mui/icons-material";
import { Box, Divider, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';

export default function ProfileServicesTab({ details }) {
  return (
    <div className="flex !justify-between gap-10">
      <Grid item sm={8}>
          <Box>
          <Typography
            variant="body2Semibold"
            component="h3"
            color="#6B7280"
            sx={{ mb: 1 }}
          >
            Services
          </Typography>
          {!(details?.userStrengths?.services || details?.services || details?.organisation_details?.services_skills || [])?.length && <Typography variant="body2Normal">No Services Added</Typography>}
            <div className='grid grid-cols-3 gap-4'>
              {(details?.userStrengths?.services || details?.services || details?.organisation_details?.services_skills || []).map((service, index) => (
                <div className='flex items-center gap-2'>
                  <VerifiedIcon className="text-[#006A98]" />
                  <Typography variant="captionNormal" component="p" sx={{ opacity: 0.8 }}>{service}</Typography>
                </div>
              ))}
            </div>
          </Box>
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ border: 'none', mt: 3, minWidth: '1px', backgroundColor: 'rgba(76, 78, 100, 0.12)' }} />
      <Grid item sm={8}>
        <Box sx={{ marginLeft: '20px' }}>
          <Typography
            variant="body2Semibold"
            component="h3"
            color="#6B7280"
            sx={{ mb: 1 }}
          >
            Skills
          </Typography>
          {!details?.skills?.length && (
            <Typography
              variant="captionNormal"
              component="p"
              color="#6B7280"
            >
              No Skills Added
            </Typography>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {(details?.skills || []).map((skill, index) => (
              <div key={index} className="flex items-center gap-2">
                <VerifiedIcon className="text-[#006A98]" />
                <p className="text-xs font-normal opacity-80 line-clamp-2" title={skill}>
                  {skill}
                </p>
              </div>
            ))}
          </div>
        </Box>
      </Grid>
    </div>
  )
}