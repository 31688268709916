import React from 'react';
import ClientFirst from '../../Assets/Marketing/EnterpriseIcons/Icon.svg';
import ClientSecond from '../../Assets/Marketing/EnterpriseIcons/Icon1.svg';
import ClientThird from '../../Assets/Marketing/EnterpriseIcons/Icon2.svg';

export const vendorData = {
  "designedBuisness": {
    "heading": "Designed with Your Business in Mind",
    "subheading": <>ProLegion's management solutions are so powerful <br/> that you'll witness immediate growth and lasting value.</>,
    "cards": [
        {
          "image": ClientFirst,
          "heading": 'Seamless Resource Management',
          "color": "#E1EFFE",
          "content": "Centralize all your tasks and resources for easy access, monitoring, and performance tracking.",
        },
        {
          "image": ClientSecond,
          "heading": 'Elevated Client Relationships',
          "color": "#EDEBFE",
          "content": 'Experience effortless client retention, mutual rewards, enhanced trust, and transparent communication for long-term growth.',
        },
        {
          "image": ClientThird,
          "heading": 'Comprehensive Business Management Tools',
          "color": "#D5F5F6",
          "content": "Our centralized dashboard has everything you need to enhance the working experience for both clients and employees",
        },
    ]
  },
  "getStarted": {
    "heading": `Build Your Portfolio and Gain New Business`,
    "subheading": `Use your data to create a compelling portfolio that attracts new clients and opportunities. Collaborate with your partners to offer efficient services to your clients. Try ProLegion risk-free with a 15-day trial and ensure you never miss the opportunity to reach new clients and achieve new heights.`
  }
}

export const consultantData = {
  "designedBuisness": {
    "heading": "Designed with Your Career in Mind",
    "subheading": <>ProLegion's management solutions are so powerful <br/> that you'll witness immediate growth and lasting value.</>,
    "cards": [
        {
          "image": ClientFirst,
          "heading": 'Seamless Profile Management',
          "color": "#E1EFFE",
          "content": "Create an AI-powered portfolio that efficiently displays your skillset and takes your career to the next level.",
        },
        {
          "image": ClientSecond,
          "heading": 'Efficient time sheet management',
          "color": "#EDEBFE",
          "content": 'Submit accurate timesheets to your employer and forget your worries about the back & forth for approvals. ProLegion has it all figured out.',
        },
        {
          "image": ClientThird,
          "heading": 'New Opportunities',
          "color": "#D5F5F6",
          "content": "Find new opportunities to work with Global clients on the ProLegion marketplace- the next-gen marketplace we have all been waiting for.",
        },
    ]
  },
  "getStarted": {
    "heading": `Build your portfolio & find new projects`,
    "subheading": `ProLegion's marketplace brings global opportunities to your fingertips. Network with vendors, apply to projects, & manage these jobs on a cloud-powered platform with the help of ProLegion.`
  },
}