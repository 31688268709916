import React from 'react';
import { Link, createBrowserRouter } from 'react-router-dom';
import VendorJobConsultant from 'Pages/JobConsultant/vendorJobConsultant';
import OtherVendorJobConsultants from 'Pages/JobConsultant/otherVendorJobConsultants';
import { ProfilePage } from 'Pages/ProfilePage';
import ErrorScreen from 'Components/Common/ErrorScreen';
import ProjectInformationV from 'Pages/DashboardVendor/components/Dashboard/ProjectInformation';
import { SiteWrapper } from '../Components/Layout';
import DashboardPage from '../Pages/Dashboard/components/Dashboard.page';
import RequireAuth from './RequireAuth';
import { marketingRoutes } from './marketing.routes';
import { timesheetRoutes } from './timesheets.routes';
import { RootErrorBoundary } from './RootErrorBoundary';
import { marketplaceRoutes } from './marketplace.routes';
import { settingsRoutes } from './settings.routes';
import { invoicesRoutes } from './invoices.routes';
import { jobsRoutes } from './jobs.routes';
import { vendorsRoutes } from './vendors.routes';
import { resourcesRoutes } from './resources.routes';
import { clientsRoutes } from './clients.routes';
import { taskRoutes } from './task.router';
import { documentRoutes } from './document.router';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';

const appRouter = [
  ...marketingRoutes,
  {
    path: '/',
    element: (
      <SiteWrapper marketing />
    ),
    children: [
      {
        path: '/:profileId',
        index: true,
        element: (
          <ProfilePage />
        ),
      },
    ],
  },
  {
    path: '/dashboard',
    element: <SiteWrapper marketing={false} />,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        index: true,
        handle: () => <Link to='/dashboard'>Dashboard</Link>,
        element: (
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        ),
      },
      ...clientsRoutes,
      ...jobsRoutes,
      ...marketplaceRoutes,
      ...settingsRoutes,
      ...taskRoutes,
      ...timesheetRoutes,
      ...invoicesRoutes,
      ...resourcesRoutes,
      ...vendorsRoutes,
      ...documentRoutes,
      {
        path: 'JobConsultants',
        element: (
          <RequireAuth>
            <VendorJobConsultant />
          </RequireAuth>
        ),
      },
      {
        path: 'project/:projectid',
        handle: {
          crumb: () => <Link to={"/dashboard"}>Dashboard</Link>
        },
        element: (
          <RequireAuth>
            <div className="pb-4">
              <Breadcrumbs />
            </div>
            <ProjectInformationV />
          </RequireAuth>
        ),
      },
      {
        path: 'OtherVendorJobConsultants',
        element: (
          <RequireAuth>
            <OtherVendorJobConsultants />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '*',
    element: (
      <RequireAuth>
        <ErrorScreen statusCode={404} />
      </RequireAuth>
    ),
  },
];

export default createBrowserRouter(appRouter);
