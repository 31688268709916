import React, { useState, createContext, useContext } from 'react';
import api from '../Utils/api';

const apiPath = {
  getJobsForConsultant: '/jobnproject/getjobsforconsultant',
  fetchJobDetails: '/jobnproject/getjobbyid',
  getAllSkills: '/jobnproject/getallskills',

  fetchJobsHavingTimesheet: '/jobnproject/getjobshavingtimesheet',
};

const nullFn = () => null;
const JobContext = createContext({
  loading: false,
  jobs: [],
  jobDetails: {},
  fetchJobsForConsultant: nullFn,
  fetchJobDetails: nullFn,
  skills: [],
  getAllSkills: nullFn,

  fetchJobsHavingTimesheet: nullFn,
});

export const useJobProvider = () => useContext(JobContext);
function JobProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [skills, setSkills] = useState([]);

  const fetchJobsHavingTimesheet = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchJobsHavingTimesheet, {
        params: {
          userId: localStorage.getItem('userId'),
        },
      });

      if (res.status === 200 && res.data?.success) {
        setJobs(res.data.result);
      } else {
        throw new Error('Error occurred while fetching jobs');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchJobsForConsultant = async () => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.getJobsForConsultant, {
        user_id: localStorage.getItem('userId'),
      });

      if (res.status === 200 && res.data?.success) {
        setJobs(res.data.result);
      } else {
        throw new Error('Error occurred while fetching jobs');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchJobDetails = async (job_id) => {
    setLoading(true);
    try {
      const res = await api.get(`${apiPath.fetchJobDetails}/${job_id}`);
      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        setJobDetails(res.data.result);
        return res.data.result;
      }
      throw new Error('Error occurred while fetching job details');
    } catch (e) {
      console.error(e);
      setLoading(false);
      return null;
    }
  };

  const getAllSkills = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getAllSkills);
      if (res.status === 200 && res.data?.success) {
        setSkills(res.data.skill.rows);
      } else {
        throw new Error('Error occurred while fetching skills');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    loading,
    jobDetails,
    jobs,
    fetchJobsForConsultant,
    fetchJobDetails,
    fetchJobsHavingTimesheet,
    skills,
    getAllSkills,
  };

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
}

export default JobProvider;
