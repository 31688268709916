import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "Utils/api";
import { CustomTable } from "../../../Components/CustomTable";
import { Typography } from "@mui/material";
import { StatusPill } from "Components/StatusPill";
import moment from "moment";

function InvoicesTable({ outgoing, isInvoiceSentTab }) {
  const { jobId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [invoices, setInvoices] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const history = useNavigate();
  const [sorting, setSorting] = React.useState([
    { id: "createdAt", desc: true },
  ]);

  const goToInvoiceDetails = (invoiceId, type) => {
    history(`/dashboard/invoices/${invoiceId}${type ? `?type=${type}` : ''}&${isInvoiceSentTab ? `isInvoiceSentTab=true` : `isInvoiceSentTab=false`}`);
  };

  const invoiceColumnDefs = {
    invoiceNumber: {
      accessorKey: "invoiceno",
      header: "Invoice Number",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()}
        </Typography>
      ),
      size: 120,
    },
    client: {
      accessorKey: "jobs",
      header: "Client",
      Cell: ({ cell, row }) => {
        const clientCompanyName = row?.original?.client || cell.getValue()[0]?.client;
        if (!clientCompanyName) return <></>;
        return (
          <Typography variant="body2Semibold">
            {clientCompanyName?.company}
          </Typography>
        );
      },
      size: 120,
    },
    vendor: {
      accessorKey: "jobs",
      header: "Vendor",
      Cell: ({ cell }) => {
        const vendorName = cell.getValue()[0]?.vendor;
        return (
          <Typography variant="body2Semibold">
            {vendorName?.company}
          </Typography>
        );
      },
      size: 120,
    },
    hours: {
      accessorKey: "hours",
      header: "Total Hours",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()}
        </Typography>
      ),
      size: 120,
    },
    invoiceDate: {
      accessorKey: "createdAt",
      header: "Invoice Date",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {moment(new Date(cell.getValue())).format('DD MMM, YYYY')}
        </Typography>
      ),
      size: 120,
    },
    invoiceStatus: {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => <StatusPill status={cell.getValue()} />,
      size: 120,
    },
    invoiceAmount: {
      accessorKey: "grand_total_amount",
      header: "Amount",
      Cell: ({ cell, row }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {row.original.currency_code === "USD"
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(cell.getValue())
            : new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(cell.getValue())}
        </Typography>
      ),
      size: 120,
    },
    invoiceView: {
      accessorKey: "amount",
      header: "Actions",
      Cell: ({ row }) => {
        return (
          <Typography
            variant="body2Normal"
            sx={{ color: "#0B85BA", cursor: "pointer" }}
            onClick={() => goToInvoiceDetails(row.original.id, row.original.invoice_type)}
          >
            View
          </Typography>
        );
      },
      size: 120,
    },
  };

  const invoicesTableColumns = [
    [
      invoiceColumnDefs.invoiceNumber,
      invoiceColumnDefs.client,
      invoiceColumnDefs.hours,
      invoiceColumnDefs.invoiceDate,
      invoiceColumnDefs.invoiceStatus,
      invoiceColumnDefs.invoiceAmount,
      invoiceColumnDefs.invoiceView,
    ],
    [
      invoiceColumnDefs.invoiceNumber,
      invoiceColumnDefs.vendor,
      invoiceColumnDefs.hours,
      invoiceColumnDefs.invoiceDate,
      invoiceColumnDefs.invoiceStatus,
      invoiceColumnDefs.invoiceAmount,
      invoiceColumnDefs.invoiceView,
    ],
  ];

  const isDesc = useMemo(()=> {
    return !!sorting[0]?.desc ? "desc" : "asc"
  },[sorting])

  const fetchInvoices = async () => {
    setInvoices([]);
    setLoading(true);
    try {
      const res = await api.get(jobId ? `/invoice/${jobId}` : "/invoice", {
        params: {
          type: outgoing ? "outgoing" : "incoming",
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          sortBy: sorting[0].id,
          sortOrder: isDesc,
        },
      });
      if (res.data?.result) {
        setInvoices(res.data.result);
        setRowCount(10 * res.data.meta.pages);
      } else {
        throw new Error("Error occurred while fetching Invoices");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchInvoices();
  }, [pagination.pageIndex, isDesc]);

  return (
    <CustomTable
      state={{
        isLoading: loading,
        sorting,
        pagination,
      }}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      manualPagination={true}
      rowCount={rowCount}
      columns={invoicesTableColumns[!outgoing ? 1 : 0]}
      data={invoices}
      enableColumnActions
      enableColumnFilterModes
      enableColumnFilters
      enableTopToolbar
    />
  );
}

export default InvoicesTable;
