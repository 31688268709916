import React from 'react';
import { ProfilePage } from 'Pages/ProfilePage';
import EditProfile from 'Pages/editProfile';
import { SettingsPage } from 'Pages/Settings';
import { Plans } from 'Pages/Subscription/Plans';
import { Subscriptions } from 'Pages/Subscription/Subscriptions';
import Contactus from 'Pages/Contactus/Contactus';
import { useCommonUserProvider } from 'Providers/Common/User';
import RequireAuth from './RequireAuth';
import { publicProfileDetails } from 'Pages/editProfile/api';
import { defer } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';

export const settingsRoutes = [
  {
    path: 'settings',
    element: (
      <RequireAuth>
        <SettingsPage />
      </RequireAuth>
    ),
  },
  {
    path: 'myaccount',
    element: (
      <RequireAuth>
        <ProfilePage />
      </RequireAuth>
    ),
  },
  {
    path: 'plans',
    element: (
      <RequireAuth>
        <Plans />
      </RequireAuth>
    ),
  },
  {
    path: 'subscriptions',
    element: (
      <RequireAuth>
        <Subscriptions />
      </RequireAuth>
    ),
  },
  {
    path: 'myaccount/edit',
    handle: {
      crumb: () => <Link to={"/dashboard/myaccount"}>Profile Page</Link>
    },
    element: (
      <RequireAuth>
        <div className="pb-4">
          <Breadcrumbs />
        </div>
        <EditProfile />
      </RequireAuth>
    ),
  },
  {
    path: 'resources/:profileId/edit',
    loader: async ({ params }) => {
      const data = await publicProfileDetails(params.profileId);
      if (!data?.data?.result?.isVendorConnected) {
        return window.location.href = '/dashboard/myaccount/edit'
      }
      return defer(data);
    },
    element: (
      <RequireAuth>
        <EditProfile isEdit={true} />
      </RequireAuth>
    ),
  },
];
