import React, { useState, useEffect } from 'react';
import {
  Box,
  styled,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const WrapperStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    margin: '0 20px 0 130px',
  },
  [theme.breakpoints.down('lg')]: {
    margin: '0 20px 0 50px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0 20px 0 20px',
    padding: theme.spacing(0, 2),
    flexDirection: 'column',
  },
  minWidth: '300px',
  margin: '0 20px 0 auto',

  display: 'flex',
  justifyContent: 'space-between',
}));

function PricingDetails() {
  const [currency, setCurrency] = React.useState('USD');
  const navigate = useNavigate();
  const handleChange = (event) => {
    setCurrency(event.target.value);
  };
  const [coupon, setCoupon] = useState('');
  const [percentageOff, setPercentageOff] = useState(0);
  const [accountsCounter, setAccountCounter] = useState(1);
  const [plan, setPlan] = useState(0);
  const [subscription, setSubscription] = useState(true);
  const [proUsers, setProUsers] = useState(19.99);
  const [proConsultants, setProConsultants] = useState(9.99);
  const [marketplaceUsers, setMarketplaceUsers] = useState(99);
  const [marketplaceConsultants, setMarketplaceConsultants] = useState(49.99);
  const [freeModel, setFreeModel] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [proAnnualPrice, setProAnnualPrice] = useState(59.99);
  const [marketAnnualPrice, setMarketAnnualPrice] = useState(89.99);

  const handleChangeSubsription = () => {
    setSubscription(!subscription);
  };
  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
  };
  function getNextDate(type) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const currentDate = new Date();

    if (type === 'month') {
      // Calculate the next month's date
      const nextMonthDate = new Date(currentDate);
      nextMonthDate.setMonth(currentDate.getMonth() + 1);

      const monthAbbreviation = months[nextMonthDate.getMonth()];
      const day = nextMonthDate.getDate();
      const year = nextMonthDate.getFullYear();

      // Format the date in the desired format
      return `${monthAbbreviation} ${day}, ${year}`;
    } if (type === 'year') {
      // Calculate the next year's date
      const nextYearDate = new Date(currentDate);
      nextYearDate.setFullYear(currentDate.getFullYear() + 1);

      const monthAbbreviation = months[nextYearDate.getMonth()];
      const day = nextYearDate.getDate();
      const year = nextYearDate.getFullYear();

      // Format the date in the desired format
      return `${monthAbbreviation} ${day}, ${year}`;
    }
    throw new Error('Invalid type. Use "month" or "year".');
  }
  useEffect(() => {
    if (accountsCounter > 5) {
      setFreeModel(false);
    } else {
      setFreeModel(true);
    }
  }, [accountsCounter]);

  async function validateCoupon() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/coupons/promotional_codes/${coupon}`,
      );

      setPercentageOff(response.data.data.percent_off);
      const percentage = response.data.data.percent_off;
      setMessage(`${percentage}% Discount Applied Successfully!`);
      setMessageType('success');
      // setCoupon("");
    } catch (e) {
      setMessage('Invalid Coupon Code');
      setMessageType('error');
      // setCoupon("");
    }
  }

  function getRawPrice(subscription, plan) {
    return subscription
      ? (
        (plan === 0
          ? accountsCounter * proConsultants * 100
          : accountsCounter
              * (marketplaceUsers + marketplaceConsultants)
              * 100) / 100
      ).toFixed(2)
      : (plan === 0
        ? accountsCounter * proAnnualPrice
        : accountsCounter * marketAnnualPrice
      ).toFixed(2);
  }

  function getPriceAfterFreeResouces(subscription, plan) {
    return subscription
      ? (
        (plan === 0
          ? (accountsCounter <= 5 ? accountsCounter : 5)
              * proConsultants
              * 100
          : (accountsCounter <= 5 ? accountsCounter : 5)
              * (marketplaceUsers + marketplaceConsultants)
              * 100) / 100
      ).toFixed(2)
      : (plan === 0
        ? (accountsCounter <= 5 ? accountsCounter : 5) * proAnnualPrice
        : (accountsCounter <= 5 ? accountsCounter : 5) * marketAnnualPrice
      ).toFixed(2);
  }

  function getAdditionalResoucesPrice(subscription, plan, freeModel) {
    return (
      subscription
        ? (
          (plan === 0
            ? freeModel
              ? 0
              : (accountsCounter - 5) * proConsultants * 100
            : freeModel
              ? 0
              : (accountsCounter - 5)
                * (marketplaceUsers + marketplaceConsultants)
                * 100) / 100
        ).toFixed(2)
        : (plan === 0
          ? freeModel
            ? 0
            : (accountsCounter - 5) * proAnnualPrice
          : freeModel
            ? 0
            : (accountsCounter - 5) * marketAnnualPrice
        ).toFixed(2)
    ).toString();
  }

  function getFinalDiscountedPrice(
    subscription,
    plan,
    freeModel,
    accountsCounter,
    percentageOff,
  ) {
    return (
      subscription
        ? (
          (
            (plan === 0
              ? freeModel
                ? 0
                : (accountsCounter - 5) * proConsultants * 100
              : freeModel
                ? 0
                : (accountsCounter - 5)
                  * (marketplaceUsers + marketplaceConsultants)
                  * 100) / 100
          ).toFixed(2)
            - ((
              (plan === 0
                ? freeModel
                  ? 0
                  : (accountsCounter - 5) * proConsultants * 100
                : freeModel
                  ? 0
                  : (accountsCounter - 5)
                  * (marketplaceUsers + marketplaceConsultants)
                  * 100) / 100
            ).toFixed(2)
              * percentageOff)
              / 100
        ).toFixed(2)
        : (
          (plan === 0
            ? freeModel
              ? 0
              : (accountsCounter - 5) * proAnnualPrice
            : freeModel
              ? 0
              : (accountsCounter - 5) * marketAnnualPrice
          ).toFixed(2)
            - ((plan === 0
              ? freeModel
                ? 0
                : (accountsCounter - 5) * proAnnualPrice
              : freeModel
                ? 0
                : (accountsCounter - 5) * marketAnnualPrice
            ).toFixed(2)
              * percentageOff)
              / 100
        ).toFixed(2)
    ).toString();
  }

  return (
    <WrapperStyled>
      <Box sx={{
        height: '100%', my: 4, mr: 2, width: '100%',
      }}
      >
        <Box sx={{ width: '100%', mb: 4 }}>
          <Box sx={{ mb: 2 }}>ProLegion for Vendors</Box>
          <hr />
        </Box>
        <Box>
          <Box className="row-component mb-4">
            <Box
              sx={{
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className=" text-center w-6 h-6 bg-[#efeff4] rounded-[5px] leading-[24px] text-lg font-bold text-[rgba(4,4,19,.56)] mr-4">
                1
              </div>
              <div className="text-[#222230] text-xl font-[600]">
                How many more resources do you want?
              </div>
            </Box>
            <Box
              sx={{
                padding: '16px 42px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="flex justify-center items-center border rounded pb-1 mr-2">
                <div
                  className=" mx-4 text-xl font-semibold cursor-pointer "
                  onClick={
                      accountsCounter <= 1
                        ? () => {}
                        : () => {
                          setAccountCounter(accountsCounter - 1);
                        }
                    }
                >
                  -
                </div>
                <div className="mx-2 text-xl font-semibold">
                  {accountsCounter}
                </div>
                <div
                  className=" mx-4 text-xl font-semibold cursor-pointer"
                  onClick={() => {
                    setAccountCounter(accountsCounter + 1);
                  }}
                >
                  +
                </div>
              </div>
              {' '}
              <span className="text-[rgba(4,4,19,.56)] text-sm ">
                Resource(s)
              </span>
              {/* <Tooltip title={"10 Free + " + accountsCounter + " Resouces"}>
                  <InfoIcon className="ml-2 text-xs" />
                </Tooltip> */}
            </Box>
          </Box>
          <Box className="row-component mb-4">
            <Box
              sx={{
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className=" text-center w-6 h-6 bg-[#efeff4] rounded-[5px] leading-[24px] text-lg font-bold text-[rgba(4,4,19,.56)] mr-4">
                2
              </div>
              <div className="text-[#222230] text-xl font-[600]">
                Choose your plan
              </div>
            </Box>
            <Box
              sx={{
                padding: '16px 42px',
                width: 'fit-content',
                //   display: "flex",
                alignItems: 'center',
              }}
            >
              <div className="plan-selctor flex mt-4">
                <Box
                  className="px-[22px] py-[14px] mr-6 rounded-[16px] min-w-[300px]"
                  onClick={() => {
                    setPlan(0);
                  }}
                  sx={
                      plan === 0
                        ? {
                          border: '2px solid #0956b5',
                        }
                        : {
                          background: '#EFEFF4',
                          border: '2px solid transparent',
                        }
                    }
                >
                  <div className="mb-2">
                    <span className="text-xl font-semibold">Pro</span>
                  </div>
                  <div className="mb-2 text-[rgba(4,4,19,.56)] text-sm flex flex-col">
                    <span className="font-medium">
                      Resource -
                      {' '}
                      <span className="font-[400]">
                        $
                          {proConsultants}
                        / month
                        </span>
                    </span>
                    <span className="font-medium">
                      Additional user -
                      {' '}
                      <span className="font-[400]">
                        $
                          {proUsers}
                        / month
                        </span>
                    </span>
                    {/* <div className="w-[1px] bg-[rgba(4,4,19,.56)] h-[20px] mx-2"></div>{" "} */}
                  </div>
                </Box>
                <Box
                  className="px-[22px] py-[14px] mr-6 rounded-[16px] min-w-[300px]"
                  onClick={(e) => {
                    setPlan(1);
                    e.preventDefault();
                  }}
                  sx={
                      plan === 1
                        ? {
                          border: '2px solid #0956b5',
                        }
                        : {
                          background: '#EFEFF4',
                          border: '2px solid transparent',
                        }
                    }
                  style={{ cursor: 'not-allowed' }}
                >
                  <div className="mb-2">
                    <span className="text-xl font-semibold">
                      Pro + Marketplace
                    </span>
                  </div>
                  <div className="mb-2 text-[rgba(4,4,19,.56)] text-sm flex flex-col">
                    <span className="font-medium">
                      Resource -
                      {' '}
                      <span className="font-[400]">
                        $
                          {marketplaceConsultants}
                        / month
                        </span>
                    </span>
                    <span className="font-medium">
                      Additional user -
                      {' '}
                      <span className="font-[400]">
                        $
                          {marketplaceUsers}
                        / month
                        </span>
                    </span>
                    {/* <div className="w-[1px] bg-[rgba(4,4,19,.56)] h-[20px] mx-2"></div>{" "} */}
                  </div>
                </Box>
                {/* <Box
                    className="px-[22px] py-[14px] mr-6 rounded-[16px] min-w-[420px]"
                    onClick={(e) => {
                      setPlan(1);
                      e.preventDefault();
                    }}
                    sx={
                      plan === 1
                        ? {
                            border: "2px solid #0956b5",
                          }
                        : {
                            background: "#EFEFF4",
                            border: "2px solid transparent",
                          }
                    }
                    style={{ cursor: "not-allowed" }}
                  >
                    <div className="mb-2">
                      <span className="text-xl font-semibold">
                        Pro + Marketplace
                      </span>
                    </div>
                    <div className="mb-2 text-[rgba(4,4,19,.56)] text-sm flex items-center">
                      Additional user - ${marketplaceUsers}/mo/resource{" "}
                      <div className="w-[1px] bg-[rgba(4,4,19,.56)] h-[20px] mx-2"></div>{" "}
                      Consultant - ${marketplaceConsultants}/mo/resource{" "}
                    </div>
                  </Box> */}
              </div>
            </Box>
          </Box>
          <Box className="row-component mb-4">
            <Box
              sx={{
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className=" text-center w-6 h-6 bg-[#efeff4] rounded-[5px] leading-[24px] text-lg font-bold text-[rgba(4,4,19,.56)] mr-4">
                3
              </div>
              <div className="text-[#222230] text-xl font-[600]">
                Choose your subscription cycle
              </div>
            </Box>
            <Box
              sx={{
                padding: '2px 42px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <div className="mt-4">
                  <Box
                    className="px-[22px] py-[14px] mr-6 rounded-[16px] min-w-[200px]"
                    sx={
                      freeModel
                        ? {
                            border: "2px solid #0956b5",
                          }
                        : {
                            background: "#EFEFF4",
                            border: "2px solid transparent",
                          }
                    }
                  >
                    <div className="mb-2">
                      <span className="font-semibold">Free</span>
                    </div>
                    <div className="mb-2 text-[rgba(4,4,19,.56)] flex items-center text-xl font-semibold">
                      <span className="text-black font-bold">$0.00</span>
                    </div>
                    <div className="mb-2 text-[rgba(4,4,19,.56)] text-sm flex items-center">
                      Build monthly
                    </div>
                  </Box>
                </div>
                <div className="w-[1px] h-[120px] mr-6 bg-black mt-4"></div> */}
              <div className="subscription-selctor">
                <Box className="flex items-center">
                  <span className="font-semibold mr-2">Monthly</span>
                  <Switch
                    inputProps={{ 'aria-label': 'ant design' }}
                    onChange={handleChangeSubsription}
                    checked={!subscription}
                  />
                  <span className="font-semibold ml-2">Yearly</span>
                </Box>
                <Box sx={{ display: 'flex', mt: 2 }}>
                  <Box
                    className="px-[22px] py-[14px] mr-6 rounded-[16px] min-w-[200px]"
                      // onClick={() => {
                      //   setSubscription(0);
                      // }}
                    sx={
                        subscription
                          ? {
                            border: '2px solid #0956b5',
                          }
                          : {
                            display: 'none',
                          }
                      }
                  >
                    <div className="mb-2">
                      <span className="font-semibold">Monthly</span>
                    </div>
                    <div className="mb-2 text-[rgba(4,4,19,.56)] flex items-center text-xl font-semibold">
                      <span className="text-black font-bold">
                        $
                          {(
                            (plan === 0
                              ? accountsCounter * proConsultants * 100
                              : accountsCounter
                                * (marketplaceUsers + marketplaceConsultants)
                                * 100) / 100
                          ).toFixed(2)}
                      </span>
                    </div>
                    <div className="mb-2 text-[rgba(4,4,19,.56)] text-sm flex items-center">
                      Billed monthly
                    </div>
                  </Box>
                  <Box
                    className="px-[22px] py-[14px] mr-6 rounded-[16px] min-w-[200px]"
                    onClick={() => {
                      setSubscription(false);
                    }}
                    sx={
                        !subscription
                          ? {
                            border: '2px solid #0956b5',
                          }
                          : {
                            display: 'none',
                          }
                      }
                  >
                    <div className="mb-2">
                      <span className="font-semibold">Yearly</span>
                    </div>
                    <div className="mb-2 text-[rgba(4,4,19,.56)] flex items-center text-xl font-semibold">
                      <span className="text-black font-bold">
                        $
                          {plan === 0
                            ? accountsCounter * proAnnualPrice
                            : accountsCounter * marketAnnualPrice}
                      </span>
                    </div>
                    <div className="mb-2 text-[rgba(4,4,19,.56)] text-sm flex items-center">
                      Billed yearly
                    </div>
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          minHeight: '650px',
          // width: "100%",
          maxWidth: '40%',
          minWidth: '500px',
          my: 4,
          px: 4,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <div className="text-2xl font-medium">Order Summary</div>
          <div className="text-2xl font-medium">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Currency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currency}
                label="Currency"
                onChange={handleChange}
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
        <Box>
          <Box sx={{ width: '100%', mb: 2 }}>
            <Box sx={{ mb: 2 }}>1 item(s)</Box>
            <hr />
          </Box>
          <Box sx={{ width: '100%', mb: 3 }}>
            <div className="flex w-full justify-between">
              <div className="text-lg font-bold">
                {plan === 0 ? 'Pro' : 'Pro + Marketplace'}
                {' '}
                <span>
                  {subscription
                    ? '( Monthly Subsciption )'
                    : '( Annual Subscription )'}
                </span>
              </div>
              <div className="text-lg 	">
                $
                {getRawPrice(subscription, plan)}
              </div>
            </div>
            <div className="flex w-full justify-between mb-3">
              {accountsCounter <= 5 ? accountsCounter : 5}
              {' '}
              Free Resource(s)
              <div className="text-lg 	">
                - $
                {getPriceAfterFreeResouces(subscription, plan)}
              </div>
            </div>

            <div className="flex w-full justify-between ">
              {accountsCounter <= 5
                ? 'Additional 0 Resource(s)'
                : `Additional ${
                  (accountsCounter - 5).toString()
                } Resource(s)`}
              <div className="text-lg ">
                {accountsCounter <= 5
                  ? '$0'
                  : `$${
                    getAdditionalResoucesPrice(subscription, plan, freeModel)}`}
              </div>
            </div>
            {accountsCounter > 5 && (
            <>
              <div className="flex w-full justify-between items-center mt-2 mb-5">
                <input
                  key="coupon input"
                  size="small"
                  placeholder="Coupon Code"
                  id="outlined-basic"
                  variant="outlined"
                  label="Coupon Code"
                  type="text"
                  fullWidth
                  sx={{ mr: 10 }}
                  value={coupon}
                  onChange={(e) => {
                    handleCouponChange(e);
                  }}
                />
                <div
                  className="bg-[#F1F4F6] border border-[#dfe3e8] px-6 py-1 rounded-full cursor-pointer font-semibold"
                  onClick={() => {
                    coupon && validateCoupon();
                  }}
                >
                  Add
                </div>
              </div>
              {message && (
              <div
                className={`message ${messageType}`}
                style={
                        messageType === 'success'
                          ? { color: 'green' }
                          : { color: 'red' }
                      }
              >
                {message}
              </div>
              )}
            </>
            )}
            <div className="flex w-full justify-between mb-5">
              <div className="text-lg font-bold">Discounted price</div>
              <div className="text-lg">
                {accountsCounter <= 5
                  ? '$0'
                  : `$${
                    getFinalDiscountedPrice(
                      subscription,
                      plan,
                      freeModel,
                      accountsCounter,
                      percentageOff,
                    )}`}
              </div>
            </div>
            <hr />
          </Box>
          <Box sx={{ width: '100%', mb: 4 }}>
            <div className="flex w-full justify-between">
              <div className=" font-bold flex items-center">
                Total Bill
                {' '}
                {subscription ? 'Monthly' : 'Annualy'}
                {' '}
                {/* {
                    <Tooltip
                      title={"5 Free + " + (accountsCounter - 5) + " Resouces"}
                    >
                      <InfoIcon className="ml-2 text-xs" />
                    </Tooltip>
                  } */}
              </div>
              <div className="">
                {accountsCounter <= 5
                  ? '$0'
                  : `$${
                    getFinalDiscountedPrice(
                      subscription,
                      plan,
                      freeModel,
                      accountsCounter,
                      percentageOff,
                    )}`}
              </div>
            </div>
            <div className="flex w-full justify-between mb-5">
              Next charge date
              {' '}
              {subscription ? getNextDate('month') : getNextDate('year')}
            </div>
            <div className="flex w-full justify-between">
              Based on the billing information you have provided, your
              purchase may be subject to local taxes. The final charge may be
              different than the amount shown here and will be displayed on
              your invoice.
            </div>
            <div className="mt-20">
              <hr />
              <div className="mt-4 flex justify-between mb-4">
                <div>Grand total</div>

                <div className="">
                  {accountsCounter <= 5
                    ? '$0'
                    : `$${
                      getFinalDiscountedPrice(
                        subscription,
                        plan,
                        freeModel,
                        accountsCounter,
                        percentageOff,
                      )}`}
                </div>
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  navigate(
                    `/signup?accountsCounter=${accountsCounter}&couponCode=${coupon}&user=Vendor${
                      subscription
                        ? '&timePeriod=monthly'
                        : '&timePeriod=annualy'}`,
                  );
                  // dispatch(setUserType("Vendor"));
                }}
                className=" bg-[#0B5CFF] rounded-full w-full text-white py-2 px-4 text-center font-semibold text-xl cursor-pointer"
              >
                Continue
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </WrapperStyled>
  );
}

export default PricingDetails;
