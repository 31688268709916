import React, { useState, createContext, useContext } from "react";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import { roles } from "../../Utils/constants";
import { set } from "date-fns";

const apiPath = {
  fetchCurrentVendors: "/user/listvvmusers",
  fetchRequestedVendors: "/user/invitescvm",
  fetchInvitedVendors: "/user/invitedusers",
  requestNewVendor: "/user/requestcvm",
  requestVendorToNewVendor: "/user/requestvvm",
  inviteNewVendor: "/invite/createinvite",
  acceptRequest: "/user/acceptinvite",
  declineRequest: "/user/declineinvite",
  fetchVendorsWithConsultantCount: "/user/getvendorswithconsultantcount",
  searchUser: "/user/searchuser",
  fetchConsultants: "/user/getconsultants",
};

const nullFn = () => null;
const VendorContext = createContext({
  loading: false,
  vendors: [],
  vendorsWithCount: [],
  fetchVendors: nullFn,
  sendRequestToVendor: nullFn,
  sendRequestVendorToNewVendor: nullFn,
  sendInvite: nullFn,
  acceptRequest: param => null,
  declineRequest: param => null,
  fetchVendorsWithConsultantCount: nullFn,
  searchUser: nullFn,
  fetchConsultants: nullFn,
});

export const useVendorProvider = () => useContext(VendorContext);
const VendorProvider = ({ children, userId }) => {
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [vendorsWithCount, setVendorsWithCount] = useState([]);

  const fetchVendors = async () => {
    setLoading(true);
    const params = { id: userId, for: "client" };

    try {
      const data = [];
      const current = await api.get(apiPath.fetchCurrentVendors, { params });
      const requests = await api.get(apiPath.fetchRequestedVendors, { params });
      const invited = await api.get(apiPath.fetchInvitedVendors, {
        params: { userId, userType: roles.vendor },
      });

      if (current?.data?.success) {
        const result = current.data.result?.length ? current.data.result : [];
        data.push(
          ...result.map((item) => {
            if(item.vendor1_id === userId) {
              return {
                id: item.id,
                vendorUserId: item.vendor2.id,
                firstName: item.vendor2.firstName,
                lastName: item.vendor2.lastName,
                status: "Current",
                companyName: item?.vendor2.company,
                username: item?.vendor2.username,
                profilePhoto: item?.vendor2.profilePhoto,
              };
            } else {
              return {
                id: item.id,
                vendorUserId: item.vendor1.id,
                firstName: item.vendor1.firstName,
                lastName: item.vendor1.lastName,
                status: "Current",
                companyName: item?.vendor1.company,
                username: item?.vendor1.username,
                profilePhoto: item?.vendor1.profilePhoto,
              };
            }
          })
        );
      }

      if (requests?.data?.success) {
        const { request, user } = requests.data.result?.request?.length
          ? requests.data.result
          : { request: [], user: [] };

        data.push(
          ...request.map((item, index) => ({
            id: item.id,
            vendorId: user[index].id,
            firstName: user[index].firstName,
            lastName: user[index].lastName,
            status: "Requested",
            from: item.requestFrom,
            fromid: user[index].id,
            companyName: user[index]?.company,
            profilePhoto: user[index].profilePhoto,
          }))
        );
      }

      if (invited?.data?.success) {
        const result = invited.data.invite?.length ? invited.data.invite : [];
        data.push(
          ...result.map((item) => ({
            id: item.id,
            firstName: item.name,
            lastName: "",
            status: "Invited",
            companyName: item?.company,
            email: item.email,
            profilePhoto: item?.profilePhoto,
          }))
        );
      }
      setVendors(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const sendRequestToVendor = async ({ username, email, requestFrom = 'CLIENT' }) => {
    const body = { id: userId, username, email, requestFrom };

    try {
      const res = await api.post(apiPath.requestNewVendor, body);
      if (res?.data?.success) {
        const { CVM, user } = res.data;
        setVendors([
          ...vendors,
          {
            id: CVM.id,
            vendorId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            status: "Requested",
            from: CVM.requestFrom,
          },
        ]);

        Swal.fire("Success", "Request sent successfully", "success");
      }
    } catch (e) {
      console.error(e);
      if (e?.response?.status >= 400 && e?.response?.status <= 500) {
        Swal.fire({
          title: "Error",
          text: e.response.data.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
    }
  };

  const sendRequestVendorToNewVendor = async ({ email }) => {
    const body = { email };
    try {
      const res = await api.post(apiPath.requestVendorToNewVendor, body);
      if (res?.data?.success) {
        const { VVM, user } = res.data;
        setVendors([
          ...vendors,
          {
            id: VVM.id,
            vendorId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            status: "Requested",
            // from: VVM.requestFrom,
          },
        ]);

        Swal.fire("Success", "Request sent successfully", "success");
      }
    } catch (e) {
      console.error(e);
      if (e?.response?.status >= 400 && e?.response?.status <= 500) {
        Swal.fire({
          title: "Error",
          text: e.response.data.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
    }
  };

  const sendInvite = async (user, showBanner = true) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.inviteNewVendor, user);
      if (res?.data?.success && showBanner) {
        setLoading(false);
        Swal.fire({
          title: "Success",
          text: "Invitation sent",
          icon: "success",
        });
        return true;
      } else {
        // throw new Error("Error occurred while inviting new user");
        setLoading(false);
        return false;
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      if (
        e?.response?.status >= 400 &&
        e?.response?.status <= 500 &&
        showBanner
      ) {
        Swal.fire({
          title: "Error",
          text: e.response.data?.msg,
          icon: "error",
        });
      } else if (showBanner) {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
      setLoading(false);
      return false;
    }
  };

  const acceptRequest = async (id) => {
    try {
      const res = await api.put(apiPath.acceptRequest, { id });
      if (res.data && res.data.success) {
        const temp = [...vendors];
        const index = temp.findIndex((item) => item.id === id);
        temp[index]['status'] = "Current";
        setVendors(temp);
        Swal.fire("Success", "Request accepted successfully", "success");
      }
    } catch (e) {
      console.error(e);
      Swal.fire("Error", "Something went wrong", "error");
    }
  };

  const declineRequest = async (id) => {
    try {
      const res = await api.delete(apiPath.declineRequest, { params: { id } });
      if (res?.data) {
        const temp = [...vendors];
        const index = temp.findIndex((item) => item.id === id);
        temp.splice(index, 1);
        setVendors(temp);
        Swal.fire("Success", "Request declined successfully", "success");
      }
    } catch (e) {
      console.error(e);
      Swal.fire("Error", "Something went wrong", "error");
    }
  };

  const fetchVendorsWithConsultantCount = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchVendorsWithConsultantCount, {
        params: { userId, for: "CLIENT" },
      });

      if (res?.data?.success) {
        setVendorsWithCount(Object.values(res.data.result));
      } else {
        throw new Error(
          "Error occurred while fetching vendors with consultant count"
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const searchUser = async (query) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.searchUser, {
        params: { query, type: ["VENDOR", "CONSULTANT"] },
      });

      if (res?.data?.success) {
        setLoading(false);
        return res.data.result;
      } else {
        throw new Error("Error occurred while searching user");
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      return [];
    }
  };

  const fetchConsultants = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchConsultants, {
        params: { userId, for: "client" },
      });

      if (res?.data?.success) {
        const data = res.data.consultant;
        const temp = data.reduce((acc, curr) => {
          if (acc[curr.consultant_id]) {
            acc[curr.consultant_id].jobs.push(curr.job);
          } else {
            acc[curr.consultant_id] = {
              consultant: curr.consultant,
              vendor: curr.vendor,
              jobs: [curr.job],
            };
          }

          return acc;
        }, {});

        setLoading(false);
        return Object.values(temp);
      } else {
        throw new Error("Error occurred while fetching consultants");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      return [];
    }
  };

  const value = {
    loading,
    vendors,
    vendorsWithCount,
    fetchVendors,
    sendRequestToVendor,
    sendRequestVendorToNewVendor,
    sendInvite,
    acceptRequest,
    declineRequest,
    fetchVendorsWithConsultantCount,
    searchUser,
    fetchConsultants,
  };

  return (
    <VendorContext.Provider value={value}>{children}</VendorContext.Provider>
  );
};

export default VendorProvider;
