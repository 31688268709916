import { Box, Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import prolegionlogo from "../../Assets/prolegionLogoBlue.png";
import UserMenu from "../../Components/UserMenu";
import Notifications from "./Notifications";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import { setOpenSideNav } from "Redux/Feature/user";

const ManageHeader = ({expanded}) => {
  const dispatch = useDispatch();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
      sx={{
        py: 1.5,
        px: {xs: 1, md: 3},
        background: '#F9FAFB',
        // borderBottom: "1px solid #E5E7EB",
        position: "fixed",
        width: "100vw",
        zIndex: 99,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <div className="min-[300px]:flex md:hidden">
          <div className="flex pr-2" onClick={() => {dispatch(setOpenSideNav({ openSideNav: !expanded }))}}>
            <MenuIcon sx={{fontSize: "30px"}} />
          </div>
          <Link to="/dashboard">
            <img style={{ height: "32px" }} alt="ProLegion" src={prolegionlogo} />
          </Link>
        </div>
      </Box>
      <Stack direction="row" alignItems={"center"} gap={0.5}>
        <Notifications />
        <UserMenu />
      </Stack>
    </Stack>
  );
};

export default ManageHeader;
