import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { SUBSCRIPTION_TYPES } from "Utils/constants";
import { PriceCounter } from "./shared/PriceCounter";
import { Box, Button, Tooltip, styled } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const AntSubscriptionSwitch = styled(Switch)(({ theme }) => ({
  width: 90,
  height: 44,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 40,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(0px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(46px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 40,
    height: 40,
    borderRadius: "50%",
    transition: theme.transitions.create(['width'], {
      duration: 100,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16/2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const PlanCountSelector = ({
  isSubscriptionYealry,
  setSubscriptionType,
  subscriptionDetails,
  setSubscriptionDetails,
  isSubscriptionActive,
  isClient,
  isVendor,
  setSubscriptionSize,
  handleSubscriptionYearlyChange,
  // handleResetTeamSize
  controlledCountTeamSize,
  isStripe
}) => {

  useMemo(()=> (
    isClient && subscriptionDetails.isSubscriptionToggled ? setSubscriptionSize(1, 1) : setSubscriptionSize(1, 0)
  ),[subscriptionDetails.isSubscriptionToggled]);


  const handleSubscriptionToggle = () => {
    return setSubscriptionDetails((prev )=>{
      return {...prev, isSubscriptionToggled: !subscriptionDetails.isSubscriptionToggled}
    });    
  }
  const controlledCountTeamSizeFiltered = useMemo(()=> {
    return controlledCountTeamSize.filter((item)=> item.disabled === true)
  },[controlledCountTeamSize]);

  return (
    <div id="subscription-plan-calculator">
      <Grid xs={16} md={16} lg={16} textAlign={"center"} className="pt-10">
        <Typography
          variant="h5"
          gutterBottom
          className="font-secondary font-weight-700"
        >
          Select your team size
        </Typography>

        <Grid
          xs={16}
          md={16}
          lg={16}
          className="py-8"
          display={"flex"}
          justifyContent={"center"}
        >
          {isClient && !isSubscriptionActive &&
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <Box display={"flex"} paddingBottom={"5px"}>
                <Typography align="center capitalize" sx={{ typography: "body1Bold" }} className="pb-1">Upgrade to PRO</Typography>
                <Tooltip title={`Toggle to upgrade PRO to unlock addons`} placement="top" arrow>
                  <InfoOutlinedIcon sx={{ fontSize: 20 }} className="ml-1" />
                </Tooltip>
              </Box>
              <AntSubscriptionSwitch
                sx={{ marginLeft: '5px', marginRight: '25px' }}
                inputProps={{ "aria-label": "controlled" }}
                checked={subscriptionDetails.isSubscriptionToggled}
                onChange={()=> handleSubscriptionToggle()}
              />
            </Box>}
          {isClient && subscriptionDetails.teamSize.map((team, id) => {
            return id === 0 && (
              <div className="px-3" key={team.type} style={{}}>
                <PriceCounter
                  setCount={(count) => setSubscriptionSize(id, count)}
                  count={team.size}
                  title={team.label}
                  {...team}
                />
              </div>
            )
            })}
          {isVendor && !isStripe ? controlledCountTeamSize.map((team, id)=>{
            return (
              <div className="px-3" key={team.type} style={{}}>
                <PriceCounter
                  setCount={(count) => setSubscriptionSize(id, count)}
                  controlledCountTeamSize={controlledCountTeamSizeFiltered}
                  isStripe={isStripe}
                  count={team.size}
                  title={team.label}
                  {...team}
                />
              </div>
            )
          }) : subscriptionDetails.teamSize.map((team, id) => {
            return (
              <div className="px-3" key={team.type} style={{}}>
                <PriceCounter
                  setCount={(count) => setSubscriptionSize(id, count)}
                  isStripe={isStripe}
                  count={team.size}
                  title={team.label}
                  {...team}
                />
              </div>
            )
          })}
        </Grid>
        {!isSubscriptionActive && !subscriptionDetails.isSubscriptionToggled && isClient && <Typography variant="defaultFont">Click on upgrade to PRO to add users</Typography>}

        <Grid
          xs={16}
          md={16}
          lg={16}
          className="pt-3"
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid
            xs={9}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" className="font-secondary">
              {SUBSCRIPTION_TYPES.monthly}
            </Typography>
            <AntSwitch
              sx={{ marginLeft: '5px', marginRight: '5px' }}
              inputProps={{ "aria-label": "controlled" }}
              checked={isSubscriptionYealry}
              onChange={handleSubscriptionYearlyChange}
            />
            <Typography variant="subtitle1" className="font-secondary">
              {SUBSCRIPTION_TYPES.yearly}
            </Typography>
            <Typography color={'#6C2BD9'} variant="subtitle2" className="font-secondary">
              &nbsp;(Save 42%)
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
