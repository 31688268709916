import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';
import { useLocation, useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { useAlertProvider } from '../../../Providers/util/Alert';
import { useTimesheetProvider } from '../../../Providers/Timesheet.provider';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'Redux/Feature/user';
import { isValidFourNumbers } from 'Utils/helper';
import OTPInput from 'react-otp-input';
import Button from 'Components/Common/semantic_tags/Button';

export default function SubmitTimesheetViaLink() {
  const theme = useTheme();
  const { timesheetId } = useParams();
  const userDetails = useSelector(selectUserDetails);
  const { success } = useAlertProvider();
  const { generateSecureLink, loading } = useTimesheetProvider();

  const [open, setOpen] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const [secure, setSecure] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const {} = useLocation();

  const emailsValid = emails.length > 0 && emails.every((email) => isEmail(email));
  const pinValid = secure ? isInt(pin, { min: 0, max: 9999 }) : true;
  const isValid = emailsValid && pinValid;
  const isValidPINDigits = isValidFourNumbers(pin); 

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setEmails([]);
    setSecure(false);
    setPin('');
  };

  const submitTimesheetViaLink = ({ shouldCopy = false }) => {
    generateSecureLink({
      timesheetId,
      numberOfWeeks: 1,
      emails: shouldCopy ? [] : emails,
      pin: parseInt(pin),
    }).then((linkId) => {
      if (linkId !== null) {
        if (shouldCopy) {
          navigator.clipboard
            .writeText(`${window.location.protocol}//${window.location.hostname}/timesheet/${linkId}`)
            .then(() => success('Timesheet Link Copied to Clipboard'));
        } else {
          success('Timesheet submitted successfully');
        }

        setOpen(false);
        setEmails([]);
        setSecure(false);
        setPin('');
      }
    });
  };

  return (
    <>
      {userDetails.role === 'VENDOR' && <Button
        className=''
        onClick={handleOpen}
        title='Submit via Link'
      />}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Paper
          sx={{
            width: '600px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 4,
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1Bold" color="#182743">Submit Timesheet via Link</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>

          <Stack gap={2} alignItems="center" mt={3}>
            <Button
              disabled={loading}
              onClick={() => submitTimesheetViaLink({ shouldCopy: true })}
              title='Copy Link to Clipboard'
            />

            <Typography variant="body2Normal" sx={{ color: '#6B7280', mb: 2 }}>Or</Typography>
          </Stack>

          <Stack gap={3}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="email"
              >
                Send Link To Email
              </InputLabel>
              <Autocomplete
                id="email"
                multiple
                freeSolo
                clearIcon={null}
                clearOnBlur
                onChange={(e, value) => setEmails(value.filter((email) => isEmail(email)))}
                value={emails}
                itemType=""
                options={[]}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText="To add one or more recipients, press enter after each address"
                    onBlur={(e) => {
                      if (isEmail(e.target.value)) {
                        if (!emails.includes(e.target.value)) setEmails([...emails, e.target.value]);
                      }
                    }}
                  />
                )}
              />
              {!!emails.length && (
                <Stack direction="row" gap={1} my={2}>
                  {emails.map((email) => (
                    <Chip
                      key={email}
                      label={email}
                      onDelete={() => setEmails(emails.filter((e) => e !== email))}
                    />
                  ))}
                </Stack>
              )}
            </FormControl>

            <FormControl fullWidth>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={secure}
                    onChange={(_, val) => setSecure(val)}
                    disableRipple
                  />
              )}
                label="Secure with a PIN"
              />
              <FormHelperText sx={{ m: 0 }}>
                This will prevent anyone without the PIN from accessing the timesheet.
                {' '}
                Simply communicate the selected PIN with the Client.
              </FormHelperText>
            </FormControl>

            {secure ? (
              <FormControl sx={{display: "flex", flexDirection: "column"}}>
                <Box sx={{display: "flex", height: "30px"}}><InputLabel for="pin">PIN</InputLabel></Box>
                <OTPInput
                  value={pin}
                  onChange={(e) => {
                    const str = e;
                    if (str.length === 0) {
                      setPin('');
                    } else if (isInt(str, { min: 0, max: 9999 })) {
                      setPin(str);
                    }
                  }}
                  numInputs={4}
                  inputStyle={{
                    width: '40px',
                    padding: theme.spacing(1),
                    borderWidth: 1,
                    borderColor: theme.palette.primary.main,
                    outline: 'none',
                    borderRadius: 5,
                    ...theme.typography.body2,
                  }}
                  containerStyle={{
                    flex: 1,
                    justifyContent: 'center',
                    width: 'fit-content',
                    gap: theme.spacing(1),
                  }}
                  renderInput={(props) => <input {...props} />}
                />
                {/* <TextField
                  sx={{ width: '150px' }}
                  placeholder="Enter 4-digit PIN"
                  maxLength="4"
                  value={pin}
                  onChange={(e) => {
                    const str = e.target.value;
                    if (str.length === 0) {
                      setPin('');
                    } else if (isInt(str, { min: 0, max: 9999 })) {
                      setPin(str);
                    }
                  }}
                /> */}
                {!isValidPINDigits && <Typography variant='defaultFont' sx={{color: "red", fontSize: "13px", paddingTop: "8px"}}>Please Enter 4 digits</Typography>}
              </FormControl>
            ) : null}
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              onClick={() => submitTimesheetViaLink({ shouldCopy: false })}
              disabled={loading || !isValid || secure && !isValidPINDigits}
              title='Submit'
            />
          </Stack>
        </Paper>
      </Modal>
    </>
  );
}
