import React, { useState, createContext, useContext } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../Utils/api";
import { useAlertProvider } from "Providers/util/Alert";

export const apiPath = {
  fetchJobsByProject: "/jobnproject/getjobsbyproject",
  fetchJobsByProjectStandAlone: "/jobnproject",
  addJob: "/jobnproject",
  updateJob: "/jobnproject",
  assignVendorToJob: "/jobnproject/assignvendor",
  getAllSkills: "/jobnproject/getallskills",
  getJobCategory: "/jobnproject/getjobcategory",
  fetchJobDetails: "/jobnproject/getjobbyid",
  bulkJobUpload: 'jobnproject/bulkJobUpload',
};

const nullFn = () => null;
const JobContext = createContext({
  loading: false,
  jobs: {},
  fetchJobsByProject: nullFn,
  assignVendorToJob: nullFn,
  getSubmitConsultant: nullFn,
  fetchAllJobs: nullFn,
  addJob: (params)=> null,
  updateJob: (params)=> null,
  allJobs: [],
  skills: [],
  getAllSkills: nullFn,
  getAllJobCategories: nullFn,
  fetchJobDetails: param => null,
  uploadBulkJob: param => null
});

export const useJobProvider = () => useContext(JobContext);
const JobProvider = ({ children, userId }) => {
  const { success, error } = useAlertProvider()
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [skills, setSkills, submitedConsultant] = useState([]);

  const fetchJobsByProject = async (projectId) => {
    setLoading(true);
    try {
      let res;
      if (projectId) {
        res = await api.post(apiPath.fetchJobsByProject, {
          projectId,
          userId,
        });
      } else {
        res = await api.post(apiPath.fetchJobsByProjectStandAlone, {
          projectId,
          userId,
        });
      }

      if (res.status === 200 && res.data?.success) {
        setJobs({ ...jobs, [projectId]: res.data.result });
      } else {
        throw new Error("Error occurred while fetching jobs");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const addJob = async (job) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.addJob, job);
      if (res?.status === 200 && res.data?.result) {
        setJobs({
          ...jobs,
          [job.project_id]: [...(jobs[job.project_id] ?? []), res.data.result],
        });

        setLoading(false);
        success('Job added successfully')
        // Swal.fire({
        //   title: "Success",
        //   text: "Job added successfully",
        //   timer: 1500,
        // });
        return res?.data?.result?.id;
      } else {
        error("Error occurred while creating new job");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      error(e?.response?.data?.error || 'Something went wrong')
    }
  };

  const updateJob = async (job) => {
    setLoading(true);
    try {
      const res = await api.patch(apiPath.updateJob + `/${job.id}`, job);
      if (res.data && res.data.result) {
        setLoading(false);
        success('Job updated successfully')
        return job.id;
      } else {
        error("Error occurred while updating the job");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      error(e?.response?.data?.error || 'Something went wrong')
    }
  };

  const getSubmitConsultant = async ({ job_id }) => {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_MARKETPLACE_URL}/v1/jobapplication/get/job/${job_id.job_id}`, { headers })
      .then((response) => {
        setLoading(false);        
        Swal.fire({
          title: "Success",
          text: "Vendor assigned successfully",
          icon: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      })
  }

  const assignVendorToJob = async ({ job_id, vendor_id, marketplace }) => {
    setLoading(true);
    if (marketplace) {
      const headers = { 'Authorization': `Bearer ${localStorage.getItem('at')}` };
      axios.post(`${process.env.REACT_APP_MARKETPLACE_URL}/marketplace/sharedjob`, {
        "job_id": job_id,
        "assign_to": [vendor_id],
        "status": "Active",
        "type": "JOB"
      }, { headers })
        .then((response) => {
          setLoading(false);
          success('Vendor assigned successfully')
        })
        .catch((error) => {
          setLoading(false);
          error(error?.response?.data?.error || 'Something went wrong')
        })
    } else {
      try {
        const res = await api.post(apiPath.assignVendorToJob, {
          job_id,
          vendor_id,
        });

        if (res.status === 200 && res.data?.success) {
          success('Vendor assigned successfully')
        } else {
          error("Error occurred while creating new job");
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
        error(e?.response?.data?.error || 'Something went wrong')
      }
    }
  };

  const getAllSkills = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getAllSkills);
      if (res?.status === 200 && res.data?.success) {
        setSkills((res?.data?.skill?.rows || []).sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        error("Error occurred while fetching skills");
      }
    } catch (e) {
      console.error(e);
      error(e?.response?.data?.error || 'Something went wrong')
    } finally {
      setLoading(false);
    }
  };

  const getAllJobCategories = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getJobCategory);
      if (res?.status === 200) {
        return res?.data?.rows;
      } else {
        error("Error occurred while fetching Job Categories");
      }
    } catch (e) {
      console.error(e);
      error(e?.response?.data?.error || 'Something went wrong')
    } finally {
      setLoading(false);
    }
  }

  const fetchJobDetails = async (job_id) => {
    setLoading(true);
    try {
      const res = await api.get(`${apiPath.fetchJobDetails}/${job_id}`);

      if (res?.status === 200 && res.data?.success) {
        setLoading(false);
        return res.data.result;
      } else {
        error("Error occurred while fetching job details");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      error(e?.response?.data?.error || 'Something went wrong')
      return null;
    }
  };

  const uploadBulkJob = async(body) => {
    try {
      const res = await api.post('jobnproject/bulkJobUpload', body, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      if(res?.data?.success) {
        success(res?.data?.message)
      } else if(!res?.data?.success) {
        error(res?.data?.message)
      }
    } catch (error) {
      console.warn(error);
      error(error?.response?.data?.message)
    }
  };

  const value = {
    loading,
    jobs,
    fetchJobsByProject,
    assignVendorToJob,
    addJob,
    updateJob,
    skills,
    getAllSkills,
    getAllJobCategories,
    fetchJobDetails,
    uploadBulkJob
  };

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export default JobProvider;
