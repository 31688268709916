import themeBase from "../themeBase";

export const MuiListItemTextOverride = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: themeBase.spacing(2),
    }
  }
};
