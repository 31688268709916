import React from 'react';
import { AboutPage } from 'Pages/About';
import ForgotPassword from 'Pages/Auth/ForgotPassword';
import InviteSignup from 'Pages/Auth/InviteSignup';
import SignInPage from 'Pages/Auth/SignIn.page';
import SignupPage from 'Pages/Auth/Signup.page';
import SignupSuccessfull from 'Pages/Auth/SignupSuccessfull';
import { MarketingPage } from 'Pages/MarketingPage';
import { FaqPage } from 'Pages/Faq';
import { PricingPage } from 'Pages/PricingPage';
import PricingDetails from 'Pages/PricingPage/PricingDetails';
import { PrivacyPolicyPage } from 'Pages/PrivacyPolicyPage';
import ShareTimesheetWrapper from 'Pages/ShareTimesheet/ShareTimesheet';
import TermsAndConditions from 'Pages/TermsConditionPage/TermsAndCondition.page';
import { ProfilePage } from 'Pages/ProfilePage';
import { HomePage } from 'Pages/Marketing/HomePage';
import { navbarOptions } from 'Utils/constants';
import { ClientMockData } from 'Pages/MarketingPage/client.data';
import Contactus from 'Pages/Contactus/Contactus';
import { consultantData, vendorData } from 'Pages/MarketingPage/utils';
import ErrorScreen from 'Components/Common/ErrorScreen';
import { Link, defer } from 'react-router-dom';
import { TimesheetDetailTable } from 'Pages/TimesheetDetailPage/TimesheetDetailTable';
import api from 'Utils/api';
import { Marketplace } from 'Pages/Marketplace';
import ContextProviders from 'Providers/ContextProviders';
import AlertToast from 'Components/AlertToast';
import { Box } from '@mui/material';
import { SiteWrapper } from '../Components/Layout';
import PricingPageNew from 'Pages/PricingPage/PricingPageNew';
import ExperienceVerification from 'Pages/editProfile/ExperienceVerification';

export const marketingRoutes = [
  {
    path: '/',
    element: <SiteWrapper marketing />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'marketplace',
        handle: () => <Link to='/marketplace'>Marketplace</Link>,
        element: (
          <ContextProviders>
            <AlertToast />
            <Box sx={{ padding: '0px' }}><Marketplace marketing={true} /></Box>
          </ContextProviders>
        ),
      },
      {
        path: 'aboutus',
        element: <AboutPage />,
      },
      {
        path: 'pricing',
        element: <PricingPageNew />,
      },
      {
        path: 'pricing/buy',
        element: <PricingDetails />,
      },
      {
        path: 'enterprises',
        element: <MarketingPage navbarOptions={navbarOptions.enterprises} rolesData={ClientMockData} />,
      },
      {
        path: 'vendor',
        element: <MarketingPage navbarOptions={navbarOptions.vendor} rolesData={vendorData} />,
      },
      {
        path: 'consultant',
        element: <MarketingPage navbarOptions={navbarOptions.consultant} rolesData={consultantData} />,
      },
      {
        path: 'privacypolicy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'termsandcondition',
        element: <TermsAndConditions />,
      },
      {
        path: 'contact',
        element: <Contactus />,
      },
      {
        path: 'faq',
        element: <FaqPage />,
      },
      {
        path: 'signin',
        element: <SignInPage />,
      },
      {
        path: 'signup',
        element: <SignupPage />,
      },
      {
        path: 'signup/:inviteid',
        element: <InviteSignup />,
      },
      {
        path: 'signedup',
        element: <SignupSuccessfull />,
      },
      {
        path: 'resetpassword',
        element: <ForgotPassword />,
      },
      {
        path: 'timesheet/:token',
        element: <ShareTimesheetWrapper />,
      },
      {
        path: 'timesheet/timesheet-details/view-details/:timesheetid',
        loader: async ({ params }) => {
          console.warn(params);
          let loading = false;
          try {
            loading = true;
            const res = await api.get('/timesheet/link/timesheet', {
              params: {
                id: JSON.stringify([`${params.timesheetid}`])
              }
            });
            if (res.status === 200) {
              // let hours = 0;
              // let minutes = 0;
              // res.data.timesheet?.pagination?.items.forEach((t) => {
              //   hours += t.hours;
              //   minutes += t.minutes;
              // });

              // if (minutes >= 60) {
              //   hours += Math.floor(minutes / 60);
              //   minutes %= 60;
              // }
              return defer({timesheet:res?.data?.result?.[0], jobs: []})
            
            } else {
              throw new Error('Error occurred while fetching timesheet details');
            }
            throw new Error('Error occurred while fetching timesheet details');
          } catch (error) {
            console.error(error);
            // Swal.fire('Error', 'Error occurred while fetching timesheet', 'error');
          }
        },
        element: (
          <Box sx={{ width: '100%', p: 2 }}>
            <TimesheetDetailTable create={false} isPublic />
          </Box>
        ),
      },
      {
        path: 'changepassword/:id',
        element: <ForgotPassword />,
      },
      {
        path: '/vendor/profile/:userid',
        element: <ProfilePage />,
      },
      {
        path: '/client/profile/:userid',
        element: <ProfilePage />,
      },
      {
        path: '/consultant/profile/:userid',
        element: <ProfilePage />,
      },
      {
        path: '/get-verify/:id',
        element: <ExperienceVerification />,
      },
      {
        path: '/profileError',
        element: (
          <ErrorScreen statusCode={404} />
        ),
      },
    ],
  },
];
