import api, { marketPlaceUrl, resourceUrl } from "Utils/api"

export const marketPlaceEndpoints = {
    addNewJob: 'api/market-place-jobs',
    addNewJobV2: 'api/market-place-jobs/v2',
    applicationJob: 'api/job-application',
    applyJob: 'api/job-application/submitApplicants',
    getProfile: '/api/v1/profiles/marketPlaceUsers/all'
}

export const addNewJobInMarketplace = async (data) => {
    try {
        const resp = await api.post(`${marketPlaceUrl}${marketPlaceEndpoints.addNewJobV2}`, data)
        if (resp && resp.data) {
            return { success: true, data: resp.data?.result || [] };
        };
        return { success: false, message: 'Something went wrong' };
    } catch (e) {
        return { success: false, message: e?.response?.data?.error || 'Something went wrong' };
    }
}

export const updateJobInMarketplace = async (id, data) => {
    try {
        const resp = await api.patch(`${marketPlaceUrl}${marketPlaceEndpoints.addNewJob}/${id}`, data)
        if (resp && resp.data && resp.data?.status === 'CLOSED') {
            return { success: true, message: 'successfully updated' };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: e?.response?.data?.error || 'Something went wrong'};
    }
}

export const getAllMarketplaceJobs = async (page, limit, search, currencyType, categoryId, sortOrder, query = '') => {
    try {
        const resp = await api.get(`${marketPlaceUrl}${marketPlaceEndpoints.addNewJob}?limit=${limit}&page=${page}&search=${search}${currencyType && currencyType!=='Select' ? `&currency_type=${currencyType}` : ''}${categoryId && categoryId!=='Select' ? `&category_id=${categoryId}` : ''}${`&sortOrder=${sortOrder}`}${query}`)
        if (resp && resp.data && resp.data?.data?.length > 0) {
            return { success: true, data: resp.data || [] };
        };
        return { success: true, data: [] };
    } catch (e) {
        return { success: true, data: [] };
    }
}

export const getSingleMarketPlaceJob = async (jobId, query) => {
    try {
        const resp = await api.get(`${marketPlaceUrl}${marketPlaceEndpoints.addNewJob}/${jobId}?${query}`)
        if (resp && resp.data) {
            return { success: true, data: resp.data || {} };
        };
        return { success: true, data: {} };
    } catch (e) {
        return { success: true, data: {} };
    }
}

export const getSingleJobApplication = async (jobId, search) => {
    try {
        const resp = await api.get(`${marketPlaceUrl}${marketPlaceEndpoints.applicationJob}/${jobId}${search ? `?search=${search}` : ''}`)
        if (resp && resp.data) {
            return { success: true, data: resp.data || [] };
        };
        return { success: true, data: [] };
    } catch (e) {
        return { success: true, data: [] };
    }
}

export const fetchProfiles = async (search, role, page, query) => {
    try {
        const resp = await api.get(`${resourceUrl}${marketPlaceEndpoints.getProfile}/?role=${role}${search ? `&search=${search}` : ''}${query ? `&${query}` : ''}&page=${page}`)
        if (resp && resp.data) {
            return { success: true, data: resp.data || [] };
        };
        return { success: true, data: [] };
    } catch (e) {
        return { success: true, data: [] };
    }
}