export const subRolesArr = [
  "add-job",
  "view-job",
  "edit-job",
  "add-invoice",
  "view-invoice",
  "pay-invoice",
  "discard-invoice",
  "add-timesheet",
  "view-timesheet",
  "edit-timesheet",
  "submit-timesheet",
  "approve-reject-timesheet",
  "add-client-connection",
  "view-client-connection",
  "change-client",
  "add-vendor-connection",
  "view-vendor-connection",
  "assign-vendors",
  "add-consultant-connection",
  "view-consultant-connection",
  "assign-consultants",
  "view-account",
  "edit-account",
  "edit-settings",

  "add-task",
  "plans-billings",
  "view-task",
  "send-document",
  "save-document",
  "view-document",
  "create-project"
];


