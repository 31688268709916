import React, { useCallback, useEffect } from "react";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DroggableList from "./DraggableList";
import AddNewSprint from "./AddNewLane";
import { Box } from "@mui/material";
import { taskStatueses } from "./constant.task";
import { updateTask } from "./task.api";
import { useNavigate } from "react-router-dom";

export default function SprintList({
  taskListArray,
  setOpenViewModal,
  setTaskId
}) {
  const navigate = useNavigate()
  const onBeforeCapture = useCallback(() => {
  }, []);
  const onBeforeDragStart = useCallback(() => {
  }, []);
  const onDragStart = useCallback(() => {
  }, []);
  const onDragUpdate = useCallback(() => {
  }, []);
  const updateTaskHandler = async (id, status) => {
    await updateTask(id, { status })
  }
  const onDragEnd = useCallback((result) => {
    const sourceArr = result.source.droppableId.split('_');
    const sourceId = parseInt(sourceArr[sourceArr.length - 1]);
    const sourceIndex = result.source.index;
    const sourceData = taskListArray[sourceId].list[sourceIndex];
    const destinationArr = result.destination.droppableId.split('_');
    const destinationId = parseInt(destinationArr[destinationArr.length - 1]);
    const destinationIndex = result.destination.index;
    if (sourceId !== destinationId) {;
      const newArr = taskListArray[destinationId].list;
      const leftArr = newArr.splice(0, destinationIndex);
      taskListArray[destinationId].list = [...leftArr, sourceData, ...newArr];
      taskListArray[sourceId].list.splice(sourceIndex, 1);
      updateTaskHandler(sourceData.id, taskStatueses[Object.keys(taskStatueses)[destinationId]]);
    } else {
      const temp = taskListArray[sourceId].list[sourceIndex];
      taskListArray[sourceId].list[sourceIndex] = taskListArray[destinationId].list[destinationIndex];
      taskListArray[destinationId].list[destinationIndex] = temp;
    }

  }, [taskListArray]);

  const setDetails = (details) => {
    navigate(`?id=${details?.id}`, { replace: true });
    setOpenViewModal(true)
    setTaskId(details?.id)
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('id')) {
      setDetails({ id: queryParams.get('id') })
    }
  }, [window.location.search])

  return (
      <DragDropContext
        onBeforeCapture={onBeforeCapture}
        onBeforeDragStart={onBeforeDragStart}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        <Box className="mt-4 overflow-x-scroll" sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr));' }}>
          {taskListArray.map((lists, j) => {
            return (
              <Droppable droppableId={`${lists.id}_${j}`} key={lists.id}>
                {(provided) => (
                  <Box
                    key={lists.id}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='shadow-lg border-x'
                    sx={{ minHeight: '80vh', marginRight: '10px' }}
                  >
                    <AddNewSprint edit={true} sprintValue={lists.taskName} id={lists.id} />
                    <div className="border-b pt-4"></div>
                    {lists.list && lists.list.map((l, k) => {
                      return (
                        <DroggableList
                        key={j+k}
                        details={l}
                        title={l.name}
                        setDetails={setDetails}
                        description={l.description}
                        task={l.task}
                        assignee={l?.user?.assignee}
                        number={l.id}
                        id={j+k}
                        index={k}
                        user={l.user || {}}
                        label={l.label ? l.label : null}
                      />
                      )
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            );
          })}
          {/* <AddNewSprint /> */}
        </Box>
      </DragDropContext >
  );
}
