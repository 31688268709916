import React from "react";
import { Draggable } from "react-beautiful-dnd";

function ApplicantsLaneCard({
    number,
    index,
    children
}) {
    return (
        <Draggable draggableId={number} index={index} key={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    snapshot={snapshot}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {children}
                    {provided.placeholder}
                </div>
            )}
        </Draggable>
    );
}

export default ApplicantsLaneCard;
