import { Box, Button, TextField, Typography } from '@mui/material'
import MuiModal from 'Components/Common/MuiModal'
import MyEditor from 'Components/TextEditor';
import React, { useMemo, useState } from 'react'
import { disconnectVcm } from './api';
import { useAlertProvider } from 'Providers/util/Alert';
import { countLettersWithoutTags } from 'Pages/Dashboard/components/Jobs/validation';

function DisconnectionResourceModal({ id, isAcceptRequest, openDisconnectionModal, setOpenDisconnectionModal }) {

  const {success, error} = useAlertProvider();
  const [value, setValue] = useState("");
  const handleTextFieldChange = (event) => {
    setValue(event)
  }

  const handleAcceptDisconnectConsultant = async () => {
    disconnectVcm({
      "consultant_id": id,
      "request_type": "ACCEPTED",
      "comment": value
    }).then((res) => {
      if(res?.data?.success) {
        success(res?.data?.msg);
        setValue("");
        setOpenDisconnectionModal(false);
      } else if(!res?.data?.success) {
        error(res?.data?.msg);
        setValue("");
        setOpenDisconnectionModal(false);
      }
    })
  };

  const handleRejectDisconnectConsultant = async () => {
    disconnectVcm({
      "consultant_id": id,
      "request_type": "REJECTED",
      "comment": value
    }).then((res) => {
      if(res?.data?.success) {
        success(res?.data?.msg);
        setValue("");
        setOpenDisconnectionModal(false);
      } else if(!res?.data?.success) {
        error(res?.data?.msg);
        setValue("");
        setOpenDisconnectionModal(false);
      }
    })
  };

  const isDescriptionEmpty = useMemo(()=> {
    if(!isAcceptRequest) {
      const count = countLettersWithoutTags(value);
      return count === 0;
    } else {
      return false; 
    }
  },[value, !isAcceptRequest]);

  return (
    <MuiModal
      open={openDisconnectionModal}
      onCancel={() => setOpenDisconnectionModal(false)}
      title={isAcceptRequest ? "Accept Disconnection" : "Reject Disconnection"}
    >
      <Box paddingBottom={"1rem"}>
        <Typography variant='defaultFont' fontWeight={"bold"}>{isAcceptRequest ? "Do you want to accept disconnection of this consultant?" : "Do you want to reject disconnection of this consultant?"}</Typography>
      </Box>
      <Box>
        <Typography variant='defaultFont'>Comments {isAcceptRequest ? "(Optional)" : "*"}</Typography>
        <MyEditor
          height={200}
          html={value}
          updateRaw={handleTextFieldChange}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"} paddingTop={"4rem"}>
        <Button variant='contained' onClick={isAcceptRequest ? handleAcceptDisconnectConsultant : handleRejectDisconnectConsultant} disabled={isDescriptionEmpty}>Submit</Button>
      </Box>
    </MuiModal>
  )
}

export default DisconnectionResourceModal