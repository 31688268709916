import Typography from "Components/Common/semantic_tags/Typography";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from "moment";

const { Avatar, Box, Button, Chip } = require("@mui/material");
const { stringAvatar } = require("Utils/avatar");
const { truncateText } = require("Utils/helper");

const JobCard = ({ jobTitle, company, location, updated_at, isGrid, description, profilePhoto, skills, alreadyApplied, setSelectedDetail, isDrawer = false, type }) => (
    <div className={`w-full bg-white ${isGrid ? 'min-h-[200px]' : 'min-h-[100px]'} p-4 rounded-lg mb-2 ${isDrawer ? 'border-[1px] border-solid border-[#E5E7EB] pb-6' : 'border-[1px] border-solid border-[#E5E7EB]'} overflow-hidden flex justify-between flex-col cursor-pointer pb-6`} onClick={setSelectedDetail}>
        <div className="">
            <div className="flex justify-between items-start">
                <div className='flex gap-4'>
                    <div>
                        {profilePhoto ? (
                            <Avatar
                                variant="square"
                                alt="Profile"
                                className="!rounded-lg !text-xs"
                                src={profilePhoto}
                                sx={{ width: 50, height: 50 }}
                            />
                        ) : (
                            <Avatar
                                variant="square"
                                className="!rounded-lg !text-xs"
                                {...stringAvatar(company ?? "ProLegion User", {
                                    width: 50,
                                    height: 50,
                                })}
                            />
                        )}
                    </div>
                    <div className={`flex items-start justify-between w-full ${type === 'similar' ? 'flex-col' : ''}`}>
                        <div>
                            <h3 className={`text-lg font-bold line-clamp-1 text-[#3985B6]`}>{jobTitle}</h3>
                            <p className="text-gray-500 text-sm">
                                {company || 'Prolegion'} •
                                {location}
                            </p>
                        </div>
                    </div>
                </div>
                <Typography className="text-sm">{moment(updated_at).fromNow()}</Typography>
            </div>
            {type !== 'similar' && description && (
                <Box
                    className="text-sm mt-4 uniform-font max-h-[50px] overflow-hidden text-ellipsis"
                    sx={{
                        color: '#6B7280',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        display: '-webkit-box',
                        WebkitLineClamp: isDrawer ? 2 : 3,
                        WebkitBoxOrient: 'vertical',
                    }}
                    dangerouslySetInnerHTML={{ __html: truncateText(description, isGrid ? 20 : 60) }}
                ></Box>
            )}
            <div className="flex gap-2 items-center line-clamp-1 text-sm mt-4">
                {
                    skills?.length > 0 && skills.map((skill, index) => {
                        return <Chip
                            key={index}
                            sx={{ borderRadius: '6px', fontWeight: 600, background: '#F9FAFB', border: "1px solid #F9FAFB", fontSize: '12px', padding: '1px' }}
                            label={skill}
                            variant="outlined"
                            clickable
                        />
                    })
                }
                {/* <strong>Required Skills:-</strong> {skills?.length > 10 ? skills.splice(10).join(', ') + '...' : skills.join(', ')} */}
            </div>
            {alreadyApplied && <Typography className="text-[10px] font-bold text-[#3985B6] mt-3 flex gap-2 items-center"><CheckCircleIcon sx={{ fontSize: '14px' }} /> Already Applied</Typography>}
        </div>
        {/* {!isDrawer && <div className='flex justify-between mt-4 items-center'>
            <div className='flex items-center'>
                {applicant_count >= 1 && <h4 className='text-xs font-normal'>{applicant_count} Resources</h4>}
                &nbsp;{" • "}&nbsp;
                <h4 className='text-xs font-normal'>{moment(new Date(createdAt)).fromNow()}</h4>
            </div>
            <Button variant='contained' sx={{ padding: '6px 10px', fontSize: '10px' }}>Apply Now</Button>
        </div>} */}
    </div>
);

export default JobCard