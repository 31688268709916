import { CURRENCY_ICONS } from './constants';

export function getLocalStorageKeyValue(key) {
  return localStorage.getItem(key);
}

export const getCurrencyIcon = (currency) => CURRENCY_ICONS[currency];

export const centsToDollars = (price) => {
  if (!price) return 0;
  return price / 100;
};

export const isStatusEqualTo = (status, toBeChecked) => status === toBeChecked;
