import styled from "@emotion/styled";
import { Autocomplete, Box, TextField } from "@mui/material";
import MuiModal from "Components/Common/MuiModal";
import { addNewCertificate, uploadFile } from "../api";
import { useDispatch, useSelector } from "react-redux";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { selectProfileDetails, selectResourceDetails } from "Redux/Feature/profile/profile.selector";
import { useEffect, useState } from "react";
import { setResourceUpdatedDetails, setUpdatedDetails } from "Redux/Feature/profile/profile.slice";
import Typography from "Components/Common/semantic_tags/Typography";
import CustomInput from "Components/Common/semantic_tags/Input";
import Button from "Components/Common/semantic_tags/Button";
import { useAlertProvider } from "Providers/util/Alert";
import ReactDropzone from "Components/Common/ReactDropzone";
import { getMonthNumber, monthNames } from "Utils/constants";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Certificate = ({ open, onClose }) => {
  const [certificateDetails, setCertificateDetails] = useState({
    title: "",
    description: "",
    organization: "",
    url: "",
    issue_month: "",
    issue_year: dayjs(),
    credentialId: "",
    files: []
  });
  const [selectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch()
  const { error, success } = useAlertProvider()
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const profile = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const [loading, setLoading] = useState(false)
  
  const handleChange = (name, value) =>
    setCertificateDetails({ ...certificateDetails, [name]: value });

  const certificateHandler = async (e) => {
    setLoading(true)
    const files = e.target.files[0];
    setSelectedFile(files)
    const details = await uploadFile({
      cur: files,
      user: profile,
      photo_type: "profile_photo",
      content: "pdf",
    });
    if (details.url) {
      setCertificateDetails({ ...certificateDetails, url: details.url });
    }
    if (!details?.success) error(details.error)
    setLoading(false)
  };

  const submitHandler = async () => {
    try {
      const resp = await addNewCertificate({
        title: certificateDetails.title,
        description: certificateDetails.title,
        url: certificateDetails.files?.[0]?.url,
        organization: certificateDetails.organization,
        issue_date: `${getMonthNumber(certificateDetails.issue_month)}-${certificateDetails.issue_year?.$y}`,
        credential_id: certificateDetails.credentialId
      }, isResourceEditing, profile?.user_id);
      success("Certificate Saved Successfully")
      dispatch(isResourceEditing ? setResourceUpdatedDetails({ certificates: [...profile.certificates, resp.data] }) : setUpdatedDetails({ certificates: [...profile.certificates, resp.data] }))
      onClose()
    } catch (e) {
      error(e?.response?.data?.error)
      console.warn(e);
    }
  };

  useEffect(() => {
    if (!open) {
      setCertificateDetails({
        title: "",
        description: "",
        organization: "",
        url: "",
        issue_month: "",
        issue_year: dayjs(),
        credentialId: "",
        files: []
      });
      setSelectedFile(null);
    }
  }, [!open])

  const inputHandler = () => {
    document.querySelector('#certificate-hidden-input').click()
  }
  const buttonDisable = !certificateDetails?.organization || !certificateDetails?.title || !certificateDetails?.issue_month || !certificateDetails?.issue_year || loading;

  return (
    <MuiModal open={open} onCancel={onClose} minWidth={500}>
      <h1 className="font-bold text-2xl">Add License or Certificate</h1>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '10px', pr:"1rem" }}>
        <CustomInput
          label="Name *"
          type="text"
          placeholder="AWS"
          value={certificateDetails.title}
          onChange={(e) => handleChange("title", e.target.value)}
        />
        <CustomInput
          label="Issuing organization *"
          type="text"
          placeholder="Amazon"
          value={certificateDetails.organization}
          onChange={(e) => handleChange("organization", e.target.value)}
        />
        <div className="flex min-[300px]:flex-col md:flex-row justify-between items-center">
        {/* <CustomInput
          label="Issue Month"
          type="date"
          placeholder="Issue Month"
          value={certificateDetails.issue_month}
          onChange={(e) => handleChange("issue_month", e.target.value)}
        /> */}
          <Autocomplete
            className="min-[300px]:w-full md:w-[45%]"
            clearIcon={false}
            onChange={(_, value) => handleChange("issue_month", value)}
            options={monthNames}
            getOptionLabel={(option) => (option?.label ? option.label : option)}
            value={certificateDetails.issue_month}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Issue Month"
                placeholder="January"
                className="font-semibold"
                required
                InputLabelProps={{
                  style: {
                    fontSize: "15px"
                  }
                }}
                InputProps={{
                  style: {
                    height: "47px"
                  },
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
          <div className="flex flex-col min-[300px]:w-full min-[300px]:pt-4 md:pt-0 md:w-[45%]">
            <span className="font-semibold text-[15px]">Issue Year *</span>
            <DesktopDatePicker
              views={["year"]}
              format="YYYY"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
                '& .MuiInputBase-root': {
                  backgroundColor: 'white',
                  marginTop: '5px'
                },
                '& .MuiInputAdornment-root': {
                  backgroundColor: 'white'
                },
              }}
              value={certificateDetails.issue_year}
              onChange={(e) => handleChange("issue_year", e)}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  sx={{outline: "none"}} 
                  className=""
                  value={certificateDetails.issue_year}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </div>
        </div>
        <CustomInput
          label="Credential ID (if any)"
          type="text"
          placeholder="Credential ID"
          value={certificateDetails.credentialId}
          onChange={(e) => handleChange("credentialId", e.target.value)}
        />
        <label className="font-semibold">Upload Certificate</label>
        <ReactDropzone multiple={false} setIsUploading={setLoading} files={certificateDetails.files} setDetails={(value, name)=> handleChange(name, value)}  />
        <VisuallyHiddenInput
          onChange={certificateHandler}
          type="file"
          id="certificate-hidden-input"
          accept=".pdf"
        />
        <Button disabled={buttonDisable} variant="contained" className="w-[200px]" parentClassName="!w-full justify-center" onClick={submitHandler} title={loading ? 'Uploading...' : 'Save' } />
      </Box>
    </MuiModal>
  );
};

export default Certificate;
