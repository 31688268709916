import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react';
import { stringAvatar } from '../Utils/avatar';

export default function UserAvatar({ user, company }) {
  if (!user) return null;

  return (
    <Stack direction="row" gap={0.5} alignItems="center">
      <Avatar
        variant="rounded"
        {...stringAvatar(
          company ? user.company : `${user.firstName} ${user.lastName}`,
        )}
        src={user.profilePhoto}
        sx={{
          width: '24px', height: '24px', fontSize: '12px', borderRadius: '8px',
        }}
      />
      <Typography variant="body2Normal" sx={{ color: '#182743' }}>
        {company ? user.company : `${user.firstName} ${user.lastName}`}
      </Typography>
    </Stack>
  );
}
