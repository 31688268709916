import React, { useEffect, useState } from 'react';
import {
  EditorState, ContentState, convertToRaw, convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box } from '@mui/material';
import draftToHtml from 'draftjs-to-html';

export default function MyEditor({
  html, updateRaw, height = 300, className  ='',
}) {
  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(html || ''),
    ),
  ));

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    updateRaw(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
  };

  const toolbarOptions = {
    options: ['inline', 'list', 'textAlign'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    list: {
      options: ['unordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right', 'justify'],
    },
  };

  return (
    <Box
      className={className}
      sx={{
        borderRadius: '10px',
        height,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        '& .ck-editor__editable_inline': {
          padding: '30px',
          height,
          overflow: 'scroll',
        },
        '& .rdw-editor-wrapper .rdw-editor-main': {
          height: `${height}px !important`,
        },
      }}
    >
      <Editor
        editorState={editorState}
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        onEditorStateChange={handleChange}
        placeholder='description...'
        toolbar={toolbarOptions}
      />
    </Box>
  );
}
