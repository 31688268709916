import { Box, Button, Typography } from "@mui/material";

import React, { useState } from "react";
import MuiModal from "../../Components/Common/MuiModal";
import { useAlertProvider } from "../../Providers/util/Alert";
import { updateResources } from "./api";

function DeleteResourceModal({ selectedTabs = [], fetchResourcesDetails, setSelectedTabs, setRowSelection }) {
  const [openDeleteResourceModal, setOpenDeleteResourceModal] = useState(false);
  const { success } = useAlertProvider();

  const openModal = () => setOpenDeleteResourceModal(true);
  const closeModal = () => setOpenDeleteResourceModal(false);

  const DeletedInviteHandler = async () => {
    await updateResources(selectedTabs.map(i => i.id))
    fetchResourcesDetails()
    setSelectedTabs([])
    setRowSelection([])
    closeModal();
    success("Invite Deleted Successfully");
  };

  return (
    <Box>
      <Button
        onClick={openModal}
        style={{
          border: "1px solid #0B85BA",
          color: "#0B85BA",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        Disconnect
      </Button>
      <MuiModal
        open={openDeleteResourceModal}
        title=""
        onCancel={closeModal}
        styling={{ padding: "20px 50px" }}
      >
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1Bold">
              Disconnect {selectedTabs.length} Resources?
            </Typography>
            <Typography variant="subtitle1Normal">
              Disconnecting the selected resources from the list, Please confirm your
              choice by clicking one of the buttons below:
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Button
              onClick={closeModal}
              fullWidth
              disabled={selectedTabs.length === 0}
              variant="contained"
              sx={{
                typography: "captionMedium",
                color: "#F9FAFB",
                backgroundColor: "#0B85BA",
                py: 1,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              No, Don’t Disconnect
            </Button>
            <Button
              onClick={DeletedInviteHandler}
              fullWidth
              disabled={selectedTabs.length === 0}
              variant="contained"
              sx={{
                typography: "captionMedium",
                color: "#F9FAFB",
                backgroundColor: "#C81E1E",
                py: 1,
                px: 1.5,
                borderRadius: "8px",
              }}
            >
              Yes, Disconnect Resources
            </Button>
          </Box>
        </Box>
      </MuiModal>
    </Box>
  );
}

export default DeleteResourceModal;
