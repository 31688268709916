import {
  Box,
  Card,
  Grid,
  Skeleton,
  Stack, Typography,
} from '@mui/material';
import WelcomeScreenCard from 'Components/WelcomeScreenCard/WelcomeScreenCard';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPermissions } from 'Utils/helper';
import { FullWidthWrapper } from '../../../Components/Common';
import DashboardWidget from '../../../Components/DashboardWidget';
import ClientsIcon from '../../../Components/Icons/Clients.icon';
import ConsultantsIcon from '../../../Components/Icons/Consultants.icon';
import InvoicesIcon from '../../../Components/Icons/Invoices.icon';
import JobsIcon from '../../../Components/Icons/Jobs.icon';
import TimesheetsIcon from '../../../Components/Icons/Timesheets.icon';
import VendorsIcon from '../../../Components/Icons/Vendors.icon';
import { useCountProvider } from '../../../Providers/Common/Count';
import { selectUserDetails, selectUserRole } from '../../../Redux/Feature/user';
import { assetsUrl, userPermissions } from '../../../Utils/constants';
import DashboardJobsTable from './Dashboard/DashboardJobs.table';
import ProjectsTable from './Dashboard/Projects.table';
import { welcomeClientScreenStepsCards, welcomeVendorScreenStepsCards } from './utils';

export default function DashboardPage() {
  const { counts, fetchCounts } = useCountProvider();
  const permissions = getPermissions();
  const { isVendor, isClient, isConsultant, role } = useSelector(selectUserRole);
  const details = useSelector(selectUserDetails);
  const [loading, setLoading] = useState(true);
  const welcomeScreenBackground = `${assetsUrl}welcome-screen-background.png`;
  const navigate = useNavigate();

  useEffect(() => {
    if (role) {
      fetchCounts(role)
        .finally(() => setLoading(false));
    }
  }, [role]);

  const renderSkeleton = () => (
    <Stack width="100%" gap={1}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack direction="column">
          <Typography variant="body2Normal" color="#6B7280">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="h4Bold" color="#182743">
            <Skeleton width={50} />
          </Typography>
        </Stack>
        <Skeleton
          sx={{
            mt: 0.5,
            borderRadius: '8px',
            width: '55px',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
        </Skeleton>
      </Stack>
    </Stack>
  );

  const isWelcomeScreen = useMemo(() => {
    return (isVendor && counts.jobs === 0 && counts.consultants === 0) || (isClient && counts.jobs === 0) || (isVendor && counts.jobs === 0) || (isVendor && counts.consultants === 0)
  }, [isVendor, isClient, isConsultant, counts]);

  const welcomeScreenData = useMemo(() => {
    return isClient ? welcomeClientScreenStepsCards : isVendor && welcomeVendorScreenStepsCards
  }, [isClient, isVendor])

  const renderwelcomeCardSteps = (item, index) => {
    const isGreen = counts[item.accessKey] >= 1;
    return (
      <WelcomeScreenCard {...item} index={index} isGreen={isGreen} />
    )
  }

  return (
    <FullWidthWrapper sidebar>
      {!loading && isWelcomeScreen ?
        <div className={`flex flex-col bg-cover w-full p-[100px]`} style={{ backgroundImage: `url(${welcomeScreenBackground})` }}>
          <div className="flex flex-col items-center font-black text-4xl text-bold text-[#170F49] pb-8">
            <h1>Hello {details?.first_name} {details?.last_name}</h1>
            <h1 className='text-5xl pt-3'>Welcome to ProLegion!</h1>
          </div>
          <span className='text-center text-xl text-[#6F6C90]'>Here are few steps to get started!!</span>
          <div className="flex flex-wrap justify-center pt-[100px]">
            {welcomeScreenData?.map(renderwelcomeCardSteps)}
          </div>
        </div> :
        <>
              {/* {details?.role === 'CONSULTANT' ? <MainCard counts={{ timesheet: counts.timesheets || 0, jobs: counts.jobs || 0 }} /> */}
              <Grid container columns={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1Bold" color="#182743">Overview</Typography>
                </Grid>
                {/* {(role === 'CONSULTANT' && details?.profileStrength < 60) && (
                    <DashboardWidget onClick={() => navigate('/dashboard/myaccount/edit')}>
                      {loading ? renderSkeleton() : (
                        <Stack width="100%" gap={1}>
                          <Stack direction="row" justifyContent="space-between" width="100%">
                            <Stack direction="column">
                              <Typography className='!font-bold !text-xl' >Hi {details?.first_name || ''},</Typography>
                              <Typography className='text-xs'>Start with completing your profile to be eligible for jobs. Click here to go to profile section.</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </DashboardWidget>
                  )} */}
                {(details?.role === 'CONSULTANT' || permissions?.includes(userPermissions.timesheet.view)) && (
                  <DashboardWidget onClick={() => navigate('/dashboard/timesheets')}>
                    {loading ? renderSkeleton() : (
                      <Stack width="100%" gap={1}>
                        <Stack direction="row" justifyContent="space-between" width="100%">
                          <Stack direction="column">
                            <Typography variant="body2Normal" color="#6B7280">Timesheets</Typography>
                            <Typography variant="h4Bold" color="#182743">{counts.timesheets || 0}</Typography>
                          </Stack>
                          <Box
                            sx={{
                              mt: 0.5,
                              borderRadius: '8px',
                              backgroundColor: '#F6F5FF',
                              width: '48px',
                              height: '48px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <TimesheetsIcon color="#AC94FA" size="24px" />
                          </Box>
                        </Stack>
                      </Stack>
                    )}
                  </DashboardWidget>
                )}
                {role !== 'CONSULTANT' && permissions?.includes(userPermissions.job.invoiceAction) && (
                  <DashboardWidget onClick={() => navigate('/dashboard/invoices')}>
                    {loading ? renderSkeleton() : (
                      <Stack width="100%" gap={1}>
                        <Stack direction="row" justifyContent="space-between" width="100%">
                          <Stack direction="column">
                            <Typography variant="body2Normal" color="#6B7280">Invoices</Typography>
                            <Typography variant="h4Bold" color="#182743">{counts.invoices || 0}</Typography>
                          </Stack>
                          <Box
                            sx={{
                              mt: 0.5,
                              borderRadius: '8px',
                              backgroundColor: '#FDFDEA',
                              width: '48px',
                              height: '48px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <InvoicesIcon color="#E3A008" size="24px" />
                          </Box>
                        </Stack>
                      </Stack>
                    )}
                  </DashboardWidget>
                )}
                {role !== 'CONSULTANT' && [
                  userPermissions.consultantConnection.view,
                  userPermissions.consultantConnection.add,
                ].some((Permission) => permissions.includes(Permission)) && (
                    <DashboardWidget onClick={() => role === "CLIENT" ? navigate('/dashboard/client-resources') : navigate('/dashboard/resources')}>
                      {loading ? renderSkeleton() : (
                        <Stack width="100%" gap={1}>
                          <Stack direction="row" justifyContent="space-between" width="100%">
                            <Stack direction="column">
                              <Typography variant="body2Normal" color="#6B7280">Resources</Typography>
                              <Typography variant="h4Bold" color="#182743">{counts.consultants || 0}</Typography>
                            </Stack>
                            <Stack
                              sx={{
                                mt: 0.5,
                                borderRadius: '8px',
                                backgroundColor: '#F0F5FF',
                                width: '48px',
                                height: '48px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <ConsultantsIcon color="#AC94FA" size="24px" />
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </DashboardWidget>
                  )}
                {(details?.role === 'CONSULTANT' || [userPermissions.job.view, userPermissions.job.add].some(
                  (Permission) => permissions.includes(Permission),
                )) && (
                    <DashboardWidget onClick={() => navigate('/dashboard/jobs')}>
                      {loading ? renderSkeleton() : (
                        <Stack width="100%" gap={1}>
                          <Stack direction="row" justifyContent="space-between" width="100%">
                            <Stack direction="column">
                              <Typography variant="body2Normal" color="#6B7280">Jobs</Typography>
                              <Typography variant="h4Bold" color="#182743">{counts.jobs || 0}</Typography>
                            </Stack>
                            <Stack
                              sx={{
                                mt: 0.5,
                                borderRadius: '8px',
                                backgroundColor: '#FDF2F8',
                                width: '48px',
                                height: '48px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <JobsIcon color="#F17EB8" size="24px" />
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </DashboardWidget>
                  )}
                {role !== 'CONSULTANT' && [
                  userPermissions.clientConnection.view,
                  userPermissions.clientConnection.add,
                ].some((Permission) => permissions.includes(Permission)) && (
                    <DashboardWidget onClick={() => navigate('/dashboard/clients')}>
                      {loading ? renderSkeleton() : (
                        <Stack width="100%" gap={1}>
                          <Stack direction="row" justifyContent="space-between" width="100%">
                            <Stack direction="column">
                              <Typography variant="body2Normal" color="#6B7280">Clients</Typography>
                              <Typography variant="h4Bold" color="#182743">{counts.clients || 0}</Typography>
                            </Stack>
                            <Box
                              sx={{
                                mt: 0.5,
                                borderRadius: '8px',
                                backgroundColor: '#F0F5FF',
                                width: '48px',
                                height: '48px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <ClientsIcon color="#8DA2FB" size="24px" />
                            </Box>
                          </Stack>
                        </Stack>
                      )}
                    </DashboardWidget>
                  )}
                {role !== 'CONSULTANT' && [
                  userPermissions.vendorConnection.view,
                  userPermissions.vendorConnection.add,
                ].some((Permission) => permissions.includes(Permission)) && (
                    <DashboardWidget onClick={() => navigate('/dashboard/vendors')}>
                      {loading ? renderSkeleton() : (
                        <Stack direction="row" justifyContent="space-between" width="100%">
                          <Stack direction="column">
                            <Typography variant="body2Normal" color="#6B7280">{role === 'VENDOR' ? 'Partners' : 'Vendors'}</Typography>
                            <Typography variant="h4Bold" color="#182743">{counts.vendors || 0}</Typography>
                          </Stack>
                          <Box
                            sx={{
                              mt: 0.5,
                              borderRadius: '8px',
                              backgroundColor: '#F2FBFF',
                              width: '48px',
                              height: '48px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <VendorsIcon color="#2FC0FE" size="24px" />
                          </Box>
                        </Stack>
                      )}
                    </DashboardWidget>
                  )}

            <Grid item xs={12}>
              {role === 'CONSULTANT' ? (
                <DashboardJobsTable />
              ) : <ProjectsTable />}
            </Grid>
              </Grid>
        </>}
    </FullWidthWrapper>
  );
}
