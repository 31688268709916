import SignInPage from "Pages/Auth/SignIn.page";
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

const { default: MuiModal } = require("Components/Common/MuiModal")
const { default: SignupPage } = require("Pages/Auth/Signup.page")

const PublicViewModal = ({
    open,
    setSignUpModal,
    clientDetails
}) => {
    const navigate = useNavigate()
    const params = useParams()
    const searchParams = new URLSearchParams(window.location.search)
    const [loginShow, setLoginShow] = useState(false);
    const submitHandler = () => {
        if (searchParams.get('type') && params.invoiceId) {
            navigate(`/dashboard/invoices/${params.invoiceId}?type=${searchParams.get('type')}`)
        }
    }
    return <MuiModal open={open} onCancel={() => (setSignUpModal(false), setLoginShow(false))}>
        {
            loginShow
                ? <SignInPage submitHandler={submitHandler} signupHandler={() => setLoginShow(false)} isPublic={true} />
                : <SignupPage details={{ email: clientDetails?.email, companyName: clientDetails?.company }} submitHandler={submitHandler} loginHandler={() => setLoginShow(true)} currentScreens="form" userTypes={searchParams.get('role') === 'VENDOR' ? 'Vendor' : 'Client'} isPublic={true} />
        }
    </MuiModal>
}

export default PublicViewModal