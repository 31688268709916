import React, { useMemo, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, DialogTitle, Link, List, ListItem, Typography } from '@mui/material';
import { legalOptionsCategory, generalTermsListTitle, termsAndConditionData, appendix1List, appendix2List, websiteTermsofuse, cookiePolicyArr, privacyPolicyArr } from 'Utils/termsandConditions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TermsAndConditions() {
  const {
    title,
    dateUpdated,
    termsAndConditionList,
    termsAndConditionsContent
  } = termsAndConditionData;

  const ref = useRef(null);
  const [toggleIndex, setToggleIndex] = useState(null);

  const handleScrollTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }

  const TermsAndConditionContentArea = ({ id, title, content, isProductCatalog, isDefaultList, isMisc, isLink, isNestedList, key }) => {
    const renderTermsandConditionText = (item, index) => {

      if (isProductCatalog) {
        const { title, titleContent } = item;

        const renderTitleContentText = (item, index) => {
          return (
            <List sx={{
              listStyleType: 'disc',
              pl: 4,
              pt: 0,
              pb: 0,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }} key={index}>
              <ListItem sx={{ display: 'list-item' }}>{item}</ListItem>
            </List>
          )
        }

        return (
          <>
            <Typography variant={"defaultFont"}>{title}</Typography>
            {titleContent.map(renderTitleContentText)}
          </>
        )
      }

      if (isMisc) {
        const { subtitle, data } = item;
        return (
          <>
            {subtitle !== "" && <Typography fontWeight={"bolder"} color={"#111928"} fontSize={"18px"} padding={"1rem 0"} variant='defaultFont'>{subtitle}</Typography>}
            <Typography variant='defaultFont'>{data}</Typography>
          </>
        )
      }

      if (isLink) {
        const { data, link } = item;
        return (
          <Typography variant='defaultFont'>
            {data}
            <Link href={link} target='_blank' sx={{ textDecoration: "none", color: "#0B85BA" }} paddingLeft={"8px"}>{link}</Link>
          </Typography>
        )
      }

      if (isDefaultList) {
        const { heading, text } = item;
        return (
          <List sx={{
            listStyleType: 'disc',
            pl: 4,
            pt: 0,
            pb: 0,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }} key={index}>
            <ListItem sx={{ display: 'list-item' }}>{heading}</ListItem>
            <Typography>{text}</Typography>
          </List>
        )
      }

      if (isNestedList) {
        const { heading, list } = item;

        const renderNestedList = (item, index) => {
          const { listTitle, listData } = item;

          const renderListData = (item, index) => {
            return (
              <ListItem key={index}>{item}</ListItem>
            )
          }
          return (
            <>
              <ListItem sx={{ display: 'list-item' }}>{listTitle}</ListItem>
              <List sx={{
                listStyleType: 'disc',
                pl: 4,
                pt: 0,
                pb: 0,
                '& .MuiListItem-root': {
                  display: 'list-item',
                },
              }} key={index}>{listData.map(renderListData)}</List>
            </>
          )
        }

        return (
          <>
            <Typography variant='defaultFont'>{heading}</Typography>
            <List sx={{
              listStyleType: 'decimal',
              pl: 4,
              pt: 0,
              pb: 0,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }} key={index}>
              {list.map(renderNestedList)}
            </List>
          </>
        )
      }

      return (
        <Typography variant={"defaultFont"}>{item}</Typography>
      )
    }

    return (
      <Box id={id} display={"flex"} flexDirection={"column"} key={key}>
        <Typography
          sx={{
            fontSize: {
              md: "25px",
              xs: "20px"
            },
            padding: "8px 0",
            color: "#111928"
          }}
          variant={"defaultFont"}
          fontWeight={"bolder"}
        >
          {title}
        </Typography>
        {content.map(renderTermsandConditionText)}
      </Box>
    )
  }

  const renderGeneralTerms = (item, index) => {

    const handleToggleGeneralList = () => {
      setToggleIndex(index);
    }

    return (
      <Typography
        key={`renderLegalList-index-${index}`}
        onClick={handleToggleGeneralList}
        sx={{ cursor: "pointer" }}
      >
        <Link
          href={item.id}
          variant='defaultFont'
          sx={{
            textDecoration: "none",
            color: `${toggleIndex === index ? "#0B85BA" : "#6B7280"}`
          }}
        >
          {item.title}
        </Link>
      </Typography>
    )
  }

  const renderAppendix1Data = (item, index) => {
    const toggledLegalLink = {
      cursor: "pointer",
      textDecoration: "none",
      color: `${toggleIndex === index ? "#0B85BA" : "#6B7280"}`
    }

    return (
      <Typography
        key={`renderLegalList-index-${index}`}
        style={toggledLegalLink}
        variant='defaultFont'
      >
        <Link href={item.id} sx={toggledLegalLink}>{item.title}</Link>
      </Typography>
    )
  }

  const renderAppendix2Data = (item, index) => {
    const toggledLegalLink = {
      cursor: "pointer",
      textDecoration: "none",
      color: `${toggleIndex === index ? "#0B85BA" : "#6B7280"}`
    }

    return (
      <Typography
        key={`renderLegalList-index-${index}`}
        variant='defaultFont'
      >
        <Link href={item.id} sx={toggledLegalLink}>{item.title}</Link>
      </Typography>
    )
  }

  const renderLegalList = (item, index) => {

    const isGeneralList = item === "General Terms";
    const isWebsiteTermsofuse = item === "Website Terms of use";
    const isCookiePolicy = item === "Cookie Policy";
    const isPrivacyPolicy = item === "Privacy Policy";
    const isAppendix1List = item === "Appendix 1";
    const isAppendix2List = item === "Appendix 2";

    if(typeof(item) === "object") {
      const {id, title} = item;
      return(
        <Box padding={"1rem"}>
          <Link href={id} sx={{color: "#6B7280", textDecoration: "none"}}>{title}</Link>
        </Box>
      )
    }

    return (
      <Accordion
        key={`renderLegalList-index-${index}`}
        style={{ background: "none", boxShadow: "none", borderBottom: "none" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ background: "none" }}
        >
          {item}
        </AccordionSummary>
        <AccordionDetails
          style={{ maxHeight: "15rem", overflow: "auto" }}
        >
          {isGeneralList && generalTermsListTitle.map(renderGeneralTerms)}
          {isWebsiteTermsofuse && websiteTermsofuse.map(renderGeneralTerms)}
          {isCookiePolicy && cookiePolicyArr.map(renderGeneralTerms)}
          {isPrivacyPolicy && privacyPolicyArr.map(renderGeneralTerms)}
          {isAppendix1List && appendix1List.map(renderAppendix1Data)}
          {isAppendix2List && appendix2List.map(renderAppendix2Data)}
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderGeneralData = (item, index) => {
    return (
      <TermsAndConditionContentArea
        key={index}
        {...item}
      />
    )
  }

  const LegalListOptions = () => (
    <Box
      borderRadius={"10px"}
      border={"1px solid #E5E7EB"}
      padding={"1rem"}
      height={"fit-content"}
      sx={{
        position: "sticky",
        color: "#6B7280",
        width: {
          xs: "fit-content",
          md: "20%",
        },
        marginBottom: {
          xs: "2rem",
          md: "0"
        }
      }}
    >
      <DialogTitle
        onClick={() => setToggleIndex(null)}
        padding={"0 0 0 1rem !important"}
        style={{ cursor: "pointer", color: "#111928" }}
        fontSize={"20px"}
        fontWeight={"bolder"}
        variant='defaultFont'
      >
        LEGAL
      </DialogTitle>
      {legalOptionsCategory.map(renderLegalList)}
    </Box>
  )

  const TermsAndConditionTextContainer = () => (
    <Box
      sx={{
        overflow: {
          xs: "unset",
          md: "auto"
        },
        width: {
          xs: "100%",
          md: "78%",
        },
        height: {
          xs: "unset",
          md: "800px"
        },
      }}
    >
      <Box display={"flex"} flexDirection={"column"} paddingBottom={"1rem"}>
        <Typography lineHeight={"normal"} paddingBottom={"8px"} fontWeight={"bolder"} variant='defaultFont' sx={{ fontSize: { md: "25px", xs: "20px" } }}>The Customer Terms and Conditions</Typography>
        <span id='terms-and-condition-definition' style={{ color: "#6B7280" }}>{termsAndConditionList}</span>
      </Box>
      <Box
        sx={{
          color: "#6B7280"
        }}
      >{termsAndConditionsContent.map(renderGeneralData)}</Box>
    </Box>
  )

  return (
    <Box
      ref={ref}
      display={"flex"}
      flexDirection={"column"}
      sx={{
        overflow: {
          sx: "scroll",
          md: "hidden"
        },
        backgroundColor: "#F8F9FA",
        padding: {
          xs: "1rem",
          md: "4rem"
        },
        fontFamily: 'Plus Jakarta Sans',
      }}
    >
      <Typography
        fontWeight={"bolder"}
        lineHeight={"normal"}
        textAlign={"center"}
        variant='defaultFont'
        sx={{
          color: "#111928",
          fontSize: {
            xs: "30px",
            md: "40px",
          }
        }}
      >
        {title}
      </Typography>
      <Typography
        fontWeight={"lighter"}
        textAlign={"center"}
        variant='defaultFont'
        sx={{
          color: "#6B7280"
        }}
      >
        {dateUpdated}
      </Typography>

      <Box
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: {
            xs: "center",
            md: "unset",
          }
        }}
        display={"flex"}
        justifyContent={"space-between"}
        paddingTop={"3rem"}
        overflow={"hidden"}
      >
        <LegalListOptions />
        <TermsAndConditionTextContainer />
        <Button
          variant='contained'
          onClick={handleScrollTop}
          sx={{
            marginTop: "3rem",
            display: {
              sx: "flex",
              md: "none"
            }
          }
          }>Click to scroll to top</Button>
      </Box>
    </Box>
  )
}

export default TermsAndConditions;