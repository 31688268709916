// import 
import Button from 'Components/Common/semantic_tags/Button';
import Typography from 'Components/Common/semantic_tags/Typography';
import React from 'react';

function NestedDropdown({label, labelClassname, placeholder, baseClassname, options, selectedOption, setSelectedOption}) {
  
  const [open, setOpen] = React.useState(false);
  const [hoveredOption, setHoveredOption] = React.useState(null);
  const renderDropdownOptions = (item, index) => {
    
    const handleSelectedOption = () => {
      if(!item.subOptions) {
        setSelectedOption(item?.value);
        setOpen(false);
      }
    }

    const renderSubOptions = (subItem, subIndex) => {

      const handleSelectSubOption = ()=> {
        setSelectedOption(`${item.value} ${subItem.value}`);
        setOpen(false);
      }

      return (
        <li key={subIndex} className='px-2 py-3 cursor-pointer hover:underline hover:bg-gray-200 rounded-lg' onClick={handleSelectSubOption}>{subItem?.label}</li>
      )
    }

    return(
      <li key={index} className='relative px-2 py-3 cursor-pointer hover:underline hover:bg-gray-200 rounded-lg' onClick={handleSelectedOption} onMouseLeave={() => setHoveredOption(null)} onMouseEnter={() => setHoveredOption(item)}>
        {item?.label}
        {item.subOptions && hoveredOption === item && (
          <ul className='absolute top-0 left-[98%] mt-0 ml-2 bg-white border-gray-300 border-[1px] z-20 w-full rounded-lg'>
            {item.subOptions.map(renderSubOptions)}
          </ul>
        )}
      </li>
    )
  }

  return (
    <div className={`flex flex-col ${baseClassname}`}>
      <Typography className={`font-bold text-sm ${labelClassname}`}>{label}</Typography>
      <div className="flex flex-col relative">
        <Button title={selectedOption ? selectedOption : placeholder} className={`!justify-start pl-2 !bg-[#fff] !text-[#6B7280] hover:!bg-[#fff] hover:!shadow-none w-full ${open ? "border-[#3985B6]" : ""}`} onClick={() => setOpen(!open)} onMouseLeave={() => setOpen(false)} />
        {open && <ul className={'flex flex-col absolute top-[40px] bg-[#fff] border-gray-300 border-[1px] z-10 w-full rounded-lg'} onMouseEnter={()=> setOpen(true)} onMouseLeave={()=> setOpen(false)}>{options?.map(renderDropdownOptions)}</ul>}
      </div>
    </div>
  )
}

export default NestedDropdown;
