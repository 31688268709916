import React from 'react';
import "./AiLoader.scss";

function AiLoader({baseClassname, color, big}) {
  
  const themeColor = color ? color : "#3985B6"; 

  return (
    <div className={`${baseClassname}`}>
      <div className="relative inline-block">
        <div className={`${big ? "star-blink-big" : "star-blink"} text-[${themeColor}]`}>★</div>
        <div className={`${big ? "mini-star-big" : "mini-star"} text-[${themeColor}]`} id={`${big ? "star1-big" : "star1"}`}>✦</div>
        <div className={`${big ? "mini-star-big" : "mini-star"} text-[${themeColor}]`} id={`${big ? "star2-big" : "star2"}`}>✦</div>
        <div className={`${big ? "mini-star-big" : "mini-star"} text-[${themeColor}]`} id={`${big ? "star3-big" : "star3"}`}>✦</div>
        <div className={`${big ? "mini-star-big" : "mini-star"} text-[${themeColor}]`} id={`${big ? "star4-big" : "star4"}`}>✦</div>
        <div className={`${big ? "mini-star-big" : "mini-star"} text-[${themeColor}]`} id={`${big ? "star5-big" : "star5"}`}>✦</div>
      </div>
    </div>
  )
}

export default AiLoader
