import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DocumentModal from "./DocumentModal";
import DocumentTable from "./DocumentTable";
import { useSelector } from "react-redux";
import { selectUserDetails } from "Redux/Feature/user";
import { getAllDocuments } from "./document.api";
import Button from "Components/Common/semantic_tags/Button";

const DocumentWrapper = () => {
  const profile = useSelector(selectUserDetails);
  const [selectedTab, setSelectedTab] = useState(0);
  const [addNewModal, setAddNewModal] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [documentList, setDocumentList] = useState([]);
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (profile && profile?.role) {
      let option = []
      option.push({ label: 'Vendor', value: 'vendor' })
      option.push({ label: 'Outside Prolegion', value: 'outside-prolegion' })
      if (profile?.role === 'VENDOR') {
        option = []
        option.push({ label: 'Partner', value: 'vendor' })
        option.push({ label: 'Client', value: 'client' })
        option.push({ label: 'Consultant', value: 'resources' })
        option.push({ label: 'Outside Prolegion', value: 'outside-prolegion' })
      }
      setOptions(option)
    }
  }, [profile])

  const getDocuments = async () => {
    setDocumentList([])
    const result = await getAllDocuments(selectedTab === 0 ? 'INCOMING' : selectedTab === 1 ? 'OUTGOING' : "DRAFT");
    setDocumentList([...(result?.data?.result || [])] || [])
  }

  useEffect(() => {
    getDocuments()
  }, [selectedTab]);

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%", minHeight: '80vh' }}>
      <Box paddingBottom={"1rem"}>
        <Typography variant='h1' sx={{ fontSize: "20px", color: "#182743", lineHeight: "normal" }}>Document</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          // sx={{ borderRadius: "30px", border: "1px solid black", width: "40%" }}
          value={selectedTab}
          variant="fullWidth"
          onChange={(e, value) => setSelectedTab(value)}
        >
          <Tab
            sx={{ background: selectedTab == 0 ? "#F2FBFF" : "" }}
            value={0}
            label="Incoming"
          />
          <Tab
            sx={{ background: selectedTab == 1 ? "#F2FBFF" : "" }}
            value={1}
            label="Outgoing"
          />
          <Tab
            sx={{ background: selectedTab == 2 ? "#F2FBFF" : "" }}
            value={2}
            label="Drive"
          />
        </Tabs>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: '10px'
        }}>
          <Button title='Save in drive' onClick={() => (setIsDraft(true), setAddNewModal(true))} />
            
          <Button variant="contained" onClick={() => setAddNewModal(true)} title='Send New Document' />
        </Box>
      </Box>
      <Box>
        <DocumentTable getDocuments={getDocuments} documentList={documentList} type={selectedTab} />
      </Box>
      <DocumentModal getDocuments={getDocuments} isDraft={isDraft} options={options} openModal={addNewModal} onClose={() => (setAddNewModal(false), setIsDraft(false))} />
    </Paper>
  );
};

export default DocumentWrapper;
