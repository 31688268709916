import React from 'react';
import {
  Box, InputLabel, Stack, TextField,
} from '@mui/material';
import MyEditor from '../../../Components/TextEditor';
import { Button } from '@mui/material';
import { CopyOutlined } from '@ant-design/icons';

function AdditionalInformation({
  additional,
  about_me,
  setAddtional,
  heading = 'About Company',
}) {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(additional.profileLink);
    additional.success("Copied to clipboard")
  }
  return (
    <Stack gap={3}>
      <Stack flexDirection={"row"} gap={3} sx={{ mt: {xs : 1, md: 3} }}>
          {/* <Box display={"flex"} flexDirection={"column"} sx={{ width: '30%', position: 'relative' }}>
            <TextField
              label="Portfolio link"
              value={additional.profileLink}
              placeholder="Enter your portfolio link"
              onChange={(e) => setAddtional(e.target.value, 'public_profile_id')}
            />
            <Button
              onClick={handleCopyLink}
              variant="contained"
              sx={{
                position: 'absolute',
                right: '6px',
                top: '30px',
                padding: '9px',
              }}
            >
              <CopyOutlined width={"fit-content"} />
            </Button>
          </Box> */}
          {/* {additional.role === "CONSULTANT" &&
            <TextField
              sx={{
                width: '30%', marginLeft: "1rem",
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${additional.isError ? 'red' : "auto"}`,
                  color: `${additional.isError ? 'red' : "auto"}`
                },
              }}
              InputLabelProps={{
                sx: {
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    color: `${additional.isError ? 'red !important' : "auto"}`
                  },
                  color: `${additional.isError ? 'red !important' : "auto"}`
                }
              }}
              label="Your Experience"
              value={additional.industry_experience}
              placeholder="Enter your experience"
              onChange={(e) => setAddtional(e.target.value, 'industry_experience')}
            />} */}
        <TextField
          sx={{ width: {xs: "100%", md: '30%'} }}
          label="Linkedin"
          value={additional.linkdin}
          placeholder="eg : Linkedin.com/profile"
          onChange={(e) => setAddtional(e.target.value, 'linkdin')}
        />
      </Stack>
      <Box sx={{ height: '370px' }}>
        <InputLabel>{heading}</InputLabel>
        <MyEditor updateRaw={(data) => setAddtional(data, 'about_me')} html={about_me} />
      </Box>
    </Stack>
  );
}

export default AdditionalInformation;
