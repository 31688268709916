import React from "react";
import { Button } from "@mui/material";

export default function PrimaryButton({ label, outlined, classes, onClick, disabled }) {
  const config = {
    primary: {
      background: "#139588",
      borderRadius: "5px",
      color: "white",
    },
    secondary: {
      borderRadius: "5px",
    },
  };

  // Outlined button
  if (outlined) {
    return (
      <Button
        disabled={disabled}
        variant="outlined"
        size="medium"
        style={config.secondary}
        onClick={onClick}
        className={classes ? classes : "mx-2"}
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      size="medium"
      onClick={onClick}
      style={config.primary}
      className={classes ? classes : "mx-2"}
    >
      {label}
    </Button>
  );
}
