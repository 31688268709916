import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Card from "./Card";

function DroggableList({
  description,
  assignee,
  number,
  index,
  user,
  details,
  title,
  setDetails,
}) {
  return (
    <Draggable draggableId={number} index={index} key={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          snapshot={snapshot}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card
            description={description}
            details={details}
            setDetails={setDetails}
            assignee={assignee}
            title={title}
            user={user}
          />
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

export default DroggableList;
