import { createSelector } from '@reduxjs/toolkit';
import { roleHelper } from '../../../Components/Helper';
import { getUserRole } from 'Utils/helper';

const userSelector = (state) => state.user;

export const selectIsUserLoggedIn = createSelector(userSelector, (user) => user.isLoggedIn);
export const selectUserRole = createSelector(userSelector, (user) => ({
  role: getUserRole(),
  ...roleHelper.getRoleType(getUserRole())
}));
export const selectUserPermissions = createSelector(userSelector, (user) => user.permissions || []);
export const selectUserDetails = createSelector(userSelector, (user) => user.details || {});
export const checkHybridConsultant = createSelector(userSelector, (user) => user.isHybridConsultant || false);
