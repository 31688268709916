import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Button from "Components/Common/semantic_tags/Button";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MuiModal from "../../Components/Common/MuiModal";
import ShareableInviteField from "../../Components/Shared/ShareableInviteField";
import ConnectionProvider, { useConnectionProvider as useConnectionProviderConsultant } from "../../Providers/Consultant/Connection";
import { useConnectionProvider } from "../../Providers/Vendor/Connections";
import { roles } from "../../Utils/constants";
import { isEmailValid } from "../../Utils/helper";
import { CheckEmailExist } from "../Auth/Helper";

const AddResourceModal = () => {
  const [openAddResourceModal, setOpenAddResourceModal] = useState(false);
  const { inviteNewUser } = useConnectionProvider();
  const { requestVendor } = useConnectionProviderConsultant();
  const [inputedValue, setInputedValue] = useState("");
  const [inviteUser, setInviteUser] = useState({
    invitedBy: localStorage.getItem("userId"),
    emails: [],
    role: roles.consultant,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const getAddNewResourceQuery = searchParams.get('add-new-resource');

  useEffect(() => {
    if(getAddNewResourceQuery === 'true') {
      setOpenAddResourceModal(true);
    }
  },[getAddNewResourceQuery])

  const emptyObj = () => setInviteUser((curr) => ({ ...curr, emails: [] }));

  const openModal = () => setOpenAddResourceModal(true);
  const closeModal = () => setOpenAddResourceModal(false);

  const checkEmailUsed = (email) => {
    return new Promise((resolve) => {
      CheckEmailExist({ email }).then((res) => {
        resolve(res?.data?.status);
      });
    })
  };

  const inviteConsultant = async () => {
    const nonExistedUser = [];
    await Promise.all(
      inviteUser.emails.map((email) => {
        return new Promise(async (resolve) => {
          const exist = await checkEmailUsed(email);
          if (exist) {
            await requestVendor({ email: email, requestFrom: 'VENDOR' })
          }
          else nonExistedUser.push(email);
          resolve(true);
        });
      })
    );
    if (nonExistedUser.length > 0) {
      inviteNewUser({ ...inviteUser, emails: nonExistedUser });
    }
    emptyObj();
    closeModal();
  };

  const handleSendInvite = () => {
    inviteConsultant();
  };

  const handleEmailChange = (_, value) => {
    const validEmails = value.filter((details) => isEmailValid(details));
    setInviteUser({ ...inviteUser, emails: validEmails });
  };

  return (
    <Box>
      <Button
        onClick={openModal}
        title='Invite Resources'
      />
      {/* <Tooltip title="Invite Resources" arrow>
        <img
          onClick={openModal}
          className="w-8 h-8 cursor-pointer"
          src="https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/icons8-invite-64.png"
          alt="Invite"
        />
      </Tooltip> */}
      <MuiModal
        open={openAddResourceModal}
        title="Add Resources"
        onCancel={closeModal}
      >
        <>
          <Autocomplete
            multiple
            options={[inputedValue]}
            getOptionLabel={(option) => option}
            value={inviteUser.emails}
            onChange={handleEmailChange}
            freeSolo
            clearIcon={false}
            renderTags={(value, getTagProps) =>
              inviteUser.emails.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                value={inputedValue}
                onChange={(e) => setInputedValue(e.target.value)}
                {...params}
                label="Email Address"
                placeholder="Enter receipt email"
              />
            )}
          />
          <ShareableInviteField />
          <Button
            parentClassName="!w-full justify-end mt-4"
            onClick={handleSendInvite}
            disabled={!(inviteUser.emails.length > 0)}
            title='Send Invite'
          />
        </>
      </MuiModal>
    </Box>
  );
};

const AddResourceModalProvider = () => {
  return <ConnectionProvider>
    <AddResourceModal />
  </ConnectionProvider>
}

export default AddResourceModalProvider;
