import React from 'react';
import {
  Box, Button, Divider, FormControl, Grid, InputBase, InputLabel, MenuItem, Select, Stack, Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import { useDispatch, useSelector } from 'react-redux';
import { SendEmailOTP, UserInfo } from '../../Auth/Helper';
import { useCommonUserProvider } from '../../../Providers/Common/User';
import { selectUserDetails, setUser } from '../../../Redux/Feature/user';
import { useAlertProvider } from '../../../Providers/util/Alert';
import OtpModal from './OtpModal';
import { useNavigate } from 'react-router-dom';

export default function AccountSettingsTab() {
  const { success, error } = useAlertProvider();
  const dispatch = useDispatch();
  const {
    options: timezoneOptions,
    parseTimezone,
  } = useTimezoneSelect({ labelStyle: 'abbrev', timezones: allTimezones });
  const {
    changePassword,
    updateUser,
  } = useCommonUserProvider();

  const [loading, setLoading] = React.useState(true);
  const userDetails = useSelector(selectUserDetails);
  const [otpToken, setOtpToken] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const navigate = useNavigate();

  const {
    control,
    getValues,
    reset,
    watch,
    formState: { isDirty },
    trigger,
  } = useForm({
    defaultValues: {
      email: '',
      timezone: '',
    },
  });

  const {email, timezone} = watch();

  const handleSaveChanges = () => {
    const { email, timezone } = getValues();
    if(email !== userDetails?.email) {
      setOpenModal(true);
      SendEmailOTP({ email })
        .then((res) => {
          success(res?.data?.msg)
          setOtpToken(res.data.token);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
    updateUser({
      email,
      timezone,
    })
      .then((res) => {
        if (res && res?.data?.success) {
          success(res?.data?.msg);
        } else {
          error(res?.data?.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        error('An error has occurred. Please try again later.');
      });
    }
  };

  async function fetchData() {
    UserInfo()
      .then((res) => {
        if (res && res.data.id) {
          dispatch(setUser({ ...res.data }));
          reset({email: res?.data?.email, timezone: parseTimezone(res?.data?.timezone).value});
          trigger();
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const sendUpdatePasswordEmail = () => {
    changePassword()
      .then((res) => {
        if (res) {
          success('Email sent successfully');
        } else {
          error('An error has occurred. Please try again later.');
        }
      })
      .catch((err) => {
        console.error(err);
        error('An error has occurred. Please try again later.');
      });
  };

  const checkEmailUsed = () => {
    // const email = getValues('email');

    // if (process.env.REACT_APP_BASE_URL === 'https://dev.api.prolegion.com') {
    //   if (
    //     email.split('@')[1] === 'yopmail.com'
    //     || email.split('@')[1] === 'test.com'
    //   ) {
    //     setError('email', {
    //       type: 'manual',
    //       message: 'Please enter a valid email',
    //     });
    //   }
    // }
    // CheckEmailExist({ email }).then((res) => {
    //   if (userDetails.email === email) {
    //     setError('email', {
    //       type: 'manual',
    //       message: 'Email already in use',
    //     });
    //   } else {
    //     setError('email', {
    //       type: 'manual',
    //       message: 'Please enter a valid email',
    //     });
    //   }
    // });
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <OtpModal openModal={openModal} setOpenModal={setOpenModal} otp={otp} setOtp={setOtp} otpToken={otpToken} setOtpToken={setOtpToken} data={{email, timezone}} updateUser={updateUser} success={success} error={error} dispatch={dispatch} navigate={navigate}/>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
          name='email'
          control={control}
          render={({field})=> (
          <FormControl variant="standard" fullWidth>
            <InputLabel
              htmlFor="email"
              shrink
              sx={{
                typography: 'body2Semibold',
                color: '#111928',
                transform: 'none',
              }}
            >
              Email
            </InputLabel>
            <InputBase
              id="email"
              type="email"
              {...field}
              onBlur={checkEmailUsed}
              fullWidth
              sx={{
                'label + &': {
                  mt: 3,
                },
                '& .MuiInputBase-input': {
                  color: '#6B7280',
                  borderRadius: '8px',
                  position: 'relative',
                  backgroundColor: '#F9FAFB',
                  border: '1px solid',
                  fontSize: 16,
                  // width: 'auto',
                  padding: '10px 12px',
                  typography: 'body2Normal',
                  borderColor: '#D1D5DB',
                },
              }}
            />
          </FormControl>
          )} />
        </Grid>
      </Grid>

      <Divider sx={{ backgroundColor: '#E5E7EB', my: 4 }} />

      <Stack direction="column" alignItems="flex-start" gap={2}>
        <Typography variant="body1Semibold" component="h3" color="#111928">Reset Password</Typography>
        <Button
          variant="contained"
          color="info"
          onClick={sendUpdatePasswordEmail}
          sx={{
            typography: 'captionMedium',
            color: '#F9FAFB',
            backgroundColor: '#0B85BA',
            py: 1.5,
            px: 1.5,
            borderRadius: '8px',
          }}
        >
          Request Email with Reset Link
        </Button>
      </Stack>

      <Divider sx={{ backgroundColor: '#E5E7EB', my: 4 }} />

      <Stack direction="row" flexWrap="wrap" gap={2}>
        <Controller
          render={({ field }) => (
            <FormControl variant="standard">
              <InputLabel
                htmlFor="timezone"
                shrink
                sx={{
                  typography: 'body2Semibold',
                  color: '#111928',
                  transform: 'none',
                }}
              >
                Timezone
              </InputLabel>
              <Select
                id="timezone"
                sx={{
                  '&::before': {
                    borderBottom: 'none !important',
                  },
                  'label + &': {
                    mt: 3,
                  },
                  '& .MuiInputBase-input': {
                    minWidth: '210px !important',
                    color: '#6B7280',
                    borderRadius: '8px !important',
                    position: 'relative',
                    backgroundColor: '#F9FAFB',
                    border: '1px solid',
                    fontSize: 16,
                    padding: '10px 12px',
                    typography: 'body2Normal',
                    borderColor: '#D1D5DB',
                  },
                }}
                MenuProps={{
                  style: {height: "300px"}
                }}
                {...field}
              >
                {timezoneOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value} sx={{ typography: 'body2Normal' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          control={control}
          name="timezone"
        />
      </Stack>

      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          color="info"
          disabled={!isDirty || loading}
          onClick={handleSaveChanges}
          sx={{
            typography: 'captionMedium',
            color: '#F9FAFB',
            backgroundColor: '#0B85BA',
            py: 1,
            px: 1.5,
            borderRadius: '8px',
          }}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );
}
