import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Empty, Modal, Select } from 'antd';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useVendorProvider } from '../../../../Providers/Client/Vendor';
import { useJobProvider } from '../../../../Providers/Vendor/Jobs';
import CustomTable from '../../../../Components/Table';
import { userPermissions } from '../../../../Utils/constants';
import { selectUserPermissions, selectUserRole } from '../../../../Redux/Feature/user';
import { useConnectionProvider } from "../../../../Providers/Vendor/Connections";
import { getPermissions } from 'Utils/helper';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import useDebounce from 'hooks/useDebounce';

let marketplace = false;

export default function JobsTable2({
  projectId, setJobId, setPostJob, setJobType, searchedVal, addUpdateMarketplace
}) {
  const [currentJob, setCurrentJob] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const { vendors, fetchVendors } = useVendorProvider();
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryRes = useDebounce(searchQuery, 2000);
  //const permissions = useSelector(selectUserPermissions);
  const { consultants, fetchClients, fetchCurrentConsultantsListforJob, clients, loading } = useConnectionProvider();  
  const role = useSelector(selectUserRole);
  const {
    jobs,
    fetchJobsByProject,
    assignSubVendorToJob,
    assignConsultantToJob,
  } = useJobProvider();

  const sortedJobsByDate = useMemo(()=> {
    // if(typeof(jobs[projectId]) === "undefined") {
    //   return [];
    // } else {
      return jobs[projectId]?.sort((start, end)=>{
        const keyA = new Date(start.createdAt),
        keyB = new Date(end.createdAt);
        // Compare the 2 dates 
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      })
    // }
  },[jobs, projectId]);

  const permissions = getPermissions();

  const currentVendorsInClient = useMemo(()=>{
    return clients.filter((item)=> item.status === "Current");
  },[clients]);

  useEffect(() => {
    fetchClients(role.isClient ? "client" : "vendor")
    role.isVendor && fetchVendors();
    fetchCurrentConsultantsListforJob(!role.isClient && searchQueryRes);
  }, [searchQueryRes])
  useEffect(() => {
    fetchJobsByProject(projectId, searchedVal);
  }, [projectId, searchedVal]);

  const navigate = useNavigate();

  const location = useLocation();
  const headCells = [
    { label: 'Sr no' },
    { label: 'Job Name' },
    { label: 'Resource Name' },
    { label: 'Created By' },
    // { label: 'Job Start Date' },
    { label: 'Status' },
    { label: !role.isConsultant ? <Typography paddingLeft={"20px"} variant='defaultFont' fontWeight={"bolder"}>Actions</Typography> : 'Actions' },
  ];

  const CustomRow = useCallback(
    memo(({ row, index }) => (
      <TableRow
        hover
        tabIndex={-1}
        key={row?.job?.id}
        className="cursor-pointer"
      >
        <TableCell
          onClick={() => {
            navigate(`/dashboard/jobs/${row?.job?.id}`, { state: { from: location } });
          }}
          component="th"
          scope="row"
          sx={{ fontWeight: 900 }}
        >
          {index + 1}
        </TableCell>
        <TableCell
          onClick={() => {
            navigate(`/dashboard/jobs/${row?.job?.id}`, { state: { from: location } });
          }}
          sx={{ color: '#0B85BA' }}
          align="left"
        >
          {row?.job?.name}
        </TableCell>
        <TableCell
          onClick={() => {
            navigate(`/dashboard/jobs/${row?.job?.id}`, { state: { from: location } });
          }}
          sx={{ color: row?.consultant ? '#0B85BA' : '' }}
          align="left"
        >
          {row?.consultant ? `${row?.consultant?.firstName || ''} ${row?.consultant?.lastName || ''}` : 'Not Assigned'}
        </TableCell>
        <TableCell
          onClick={() => {
            navigate(`/dashboard/jobs/${row?.job?.id}`, { state: { from: location } });
          }}
          align="left"
        >
          {/* {moment(row?.createdAt).format('DD MMM, YYYY')} */}
          {row?.client ? `${row?.client?.company || ''}` : ''}
        </TableCell>
        {/* <TableCell
          onClick={() => {
            navigate(`/dashboard/jobs/${row?.job?.id}`, { state: { from: location } });
          }}
          align="left"
        >
          {moment(row?.job?.start_date).format('DD MMM, YYYY')}
        </TableCell> */}
        <TableCell
          onClick={() => {
            navigate(`/dashboard/jobs/${row?.job?.id}`, { state: { from: location } });
          }}
          align="left"
        >
          <span
            className="px-2 py-1 text-sm rounded-md"
            style={{
              backgroundColor:
                row?.job?.status === 'ONGOING'
                  ? '#EDEBFE'
                  : row?.job?.status === 'CLOSED'
                  ? '#D3000080'
                  : '#13958833',
              color:
                row?.job?.status === 'ONGOING'
                  ? '#5521B5'
                  : row?.job?.status === 'CLOSED'
                  ? 'white'
                  : '#139588',
            }}
          >
            {row?.job?.status}
          </span>
        </TableCell>
        <TableCell>
          {!role.isConsultant && <Button
            onClick={(e) => {
              setCurrentJob({
                id: row?.job?.id,
                vendorExists: !!row?.vendor_id,
                status: row?.job?.status,
                client_id: row?.client_id,
                vendor_id: row?.vendor_id,
                chain: row?.chain,
                child: row?.child,
                consultantExists: row?.consultant_id !== null,
                owner: row?.client,
                ...row?.job
              });
              setAnchor(e.currentTarget);
            }}
            sx={{ color: '#0B85BA' }}
          >
            Action <KeyboardArrowDownIcon />

          </Button>}
          <DriveFileRenameOutlineIcon sx={{ color: '#0B85BA' }} onClick={() => {
            setJobId(row?.job?.id);
            setPostJob(true);
            setJobType('Hourly/ Contract');
            // navigate(`/dashboard/jobs/${row?.job?.id}`, { state: { from: location } });
          }} />
        </TableCell>
      </TableRow>
    )),
    [],
  );

  function ExtraComponents() {
    return (
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        {!currentJob?.isPublicalyVisible && !currentJob?.child && (currentJob?.status !== 'ONGOING' && permissions?.includes('admin-client') || permissions?.includes(userPermissions.job.vendorAction)) ? (
          <MenuItem
            onClick={() => {
              marketplace = false;
              setModalOpen({ open: true, type: "vendor" });
              setAnchor(null);
            }}
          >
            Assign to Vendor
          </MenuItem>
        ) : null}
        {!currentJob?.isPublicalyVisible && !currentJob?.child && (currentJob?.status !== 'ONGOING' && permissions?.includes('admin-client') || permissions?.includes(userPermissions.job.vendorAction)) ? (
          <MenuItem
            onClick={() => {
              addUpdateMarketplace(currentJob)
            }}
          >
            Post in Marketplace
          </MenuItem>
        ) : null}
        {currentJob?.status !== 'ONGOING' && (permissions?.includes(`admin-vendor`) || permissions?.includes(userPermissions.job.consultantAction) || permissions?.includes(`Client`)) ? (
          <MenuItem
            onClick={() => {
              if (currentJob.consultantExists) {
                Swal.fire(
                  "Error",
                  "This job already has been assigned to a consultant.",
                  "error"
                );
              } else {
                const userId = localStorage.getItem("userId");
                const { chain, vendor_id, client_id } = currentJob;
                if (client_id === userId) {
                  setModalOpen({ open: true, type: "consultant" });
                } else if (vendor_id === userId) {
                  if (chain !== null && chain.length !== 0) {
                    Swal.fire(
                      "Error",
                      "This job has already been assigned to a vendor.",
                      "error"
                    );
                  } else {
                    setModalOpen({ open: true, type: "consultant" });
                  }
                } else {
                  const index =
                    chain?.findIndex((item) => item === userId) ?? -1;

                  if (index !== -1 && index === chain.length - 1) {
                    setModalOpen({ open: true, type: "consultant" });
                  } else {
                    Swal.fire(
                      "Error",
                      "This job has already been assigned to a vendor.",
                      "error"
                    );
                  }
                }
              }
              setAnchor(null);
            }}
          >
            Assign to Consultant
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/jobs/${currentJob?.id}`);
          }}
        >
          View Details
        </MenuItem>
        {/* {currentJob?.status !== 'ONGOING' && (permissions?.includes('admin-client') || permissions?.includes('admin-vendor') || permissions?.includes(`Client`)) && <MenuItem
          onClick={() => {
            marketplace = true;
            setModalOpen({ open: true, type: "marketplace" });
          }}
        >
          Assign to Vendor(Marketplace)
        </MenuItem>} */}
        {permissions?.includes('admin-client')
          || permissions?.includes(userPermissions.job.edit) ? (
          <MenuItem
            onClick={() => {
              setJobId(currentJob?.id);
              setPostJob(true);
              setJobType('Hourly/ Contract');
            }}
          >
            Edit Job
          </MenuItem>
        ) : null}
        {/* <MenuItem
          onClick={() => {
            navigate('/dashboard/view-consultants', { state: { job_id: currentJob?.id } });
          }}
        >
          View Consultant
        </MenuItem> */}
      </Menu>
    );
  }

  function CustomEmpty() {
    return (
      <div className="py-4">
        <Empty />

        {jobs[projectId] ? (
          <div className="flex flex-col text-center text-green-700">
            <span>No Job exist for this project</span>
          </div>
        ) : (
          <div className="flex justify-center text-green-700">
            <span>Please select a project to list the jobs</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="grow">
      <div className="w-[100%]">
        <CustomTable
          headCells={headCells}
          rows={sortedJobsByDate}
          CustomRow={CustomRow}
          defaultRowsPerPage={10}
          CustomEmpty={CustomEmpty}
          showHeaderOnEmpty
          showToolbar={false}
          ExtraComponents={ExtraComponents}
        />
      </div>
      <Modal
        open={modalOpen?.open ?? false}
        title={`Assign ${modalOpen.type?.[0]?.toUpperCase() + modalOpen.type?.slice(1)
          }`}
        onOk={async () => {
          if (modalOpen.type === "vendor") {
            assignSubVendorToJob({
              job_id: currentJob.id,
              vendor_id: selectedVendor,
              type: modalOpen.type
            });
          } else if (modalOpen.type === "marketplace") {
            assignSubVendorToJob({
              job_id: currentJob.id,
              vendor_id: selectedVendor,
              type: modalOpen.type
            });
          } else {
            assignConsultantToJob({
              job_id: currentJob.id,
              consultant_id: selectedConsultant,
            });
          }
          setSelectedConsultant(null);
          setSelectedVendor(null);
          setModalOpen({ open: false, type: "" });
          setAnchor(null);
        }}
        onCancel={() => {
          setSelectedConsultant(null);
          setSelectedVendor(null);
          setModalOpen({ open: false, type: "" });
          setAnchor(null);
        }}
        okButtonProps={{
          className: "bg-[#1677ff]",
          disabled:
            (modalOpen.type === "vendor" || modalOpen.type === "marketplace")
              ? selectedVendor === null
              : selectedConsultant === null,
        }}
      >
        {(modalOpen.type === "vendor" || modalOpen.type === "marketplace") ? (
          <Select
            className="w-full mt-4 mb-2"
            placeholder="Select Vendor"
            value={selectedVendor}
            onChange={(id) => {
              if (id === -1) {
                navigate("/dashboard/vendors")
                navigate(0)
              } else setSelectedVendor(id);
            }}
            options={role.isClient ? currentVendorsInClient.map((item) => {
              return {
                value: item.id,
                label: `${item.companyName}`.trim(),
              };
            })
            .concat([{ value: -1, label: "Add New Vendor" }]) : vendors
              .filter(
                (item) =>
                  item?.status === "Current"
              )
              .map((item) => {
                return {
                  value: item?.vendorUserId, // don't change this ever
                  label: `${item.companyName}`.trim(),
                };
              })
              .concat([{ value: -1, label: "Add New Vendor" }]) }
          />
        ) : (
          <Select
            className="w-full mt-4 mb-2"
            placeholder="Select Consultant"
            value={selectedConsultant}
            loading={loading}
            showSearch
            onSearch={(value)=> {setSearchQuery(value)}}
            filterOption={(input, option) => {
              setSearchQuery(input)
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
            onChange={(id) => {
              if (id === -1) {
                navigate("/dashboard/resources")
                navigate(0)
              } else setSelectedConsultant(id);
            }}
            options={consultants
              .map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`.trim(),
              }))
              .concat([{ value: -1, label: "Add New Consultant" }])}
          />
        )}
      </Modal>
    </div>
  );
}
