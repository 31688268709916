import React, { useEffect, useState } from 'react';

import { CustomTable } from '../../../Components/CustomTable';
import { resourceColumnDefs } from './ResourcesTable.columns';
import { Box, Typography } from '@mui/material';
import DisconnectionResourceModal from '../DisconnectionResourceModal';
import { acceptInviteFromConsultant, rejectInviteFromConsultant } from '../api';
import ConnectionResourceModal from '../ConnectionResourceModal';

function ResourceTable({
  tabs, subActiveTab, setSelectedTabs, setSubActiveTab, isLoading, resourcesData, isRowSelection = true,
  rowSelection, setRowSelection, setPagination, setSorting, pagination, sorting, rowCount, setRowCount, openModal, setOpenModal, isReloadAPI, setIsReloadAPI
}) {
  const [toggleIndex, setToggleIndex] = useState(null);
  const [toggleJobIndex, setToggleJobIndex] = useState(null);
  const [isAcceptRequest, setisAcceptRequest] = useState(false);
  const [openRejectConnModal, setOpenRejectConnModal] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if ([0].includes(tabs)) {
        setSelectedTabs(
          Object.keys(rowSelection).map((i) => resourcesData[Number(i)]),
        );
      } else { setSelectedTabs([]); setRowSelection([]); }
    }
  }, [rowSelection]);

  const handleAcceptDisconnectionRequest = () => {
    setOpenModal(true);
    setisAcceptRequest(true);
  }

  const handleRejectDisconnectionRequest = ()=> {
    setOpenModal(true);
    setisAcceptRequest(false);
  }

  const handleAcceptConnectionRequest = async (id)=> {
    await acceptInviteFromConsultant(id);
    setIsReloadAPI(!isReloadAPI);
  }

  const handleRejectConnectionRequest = ()=> {
    setOpenRejectConnModal(true);
  }

  const requestsResourcesColumns = [
    [
      resourceColumnDefs.resourceName,
      resourceColumnDefs.requestType,
      {
        accessorKey: "isDisconnectRequest",
        header: <span className='pl-6'>Actions</span>,
        enableColumnFilter: false,
        Cell: ({cell, row}) => { 
          return(
            <Box display={"flex"}>
              <button className='bg-green-400 text-white px-2 py-1 mr-4 rounded-md transition duration-300 ease-in hover:shadow-[0_0_4px_#00b400]' onClick={handleAcceptDisconnectionRequest}>Accept</button>
              <button className='bg-red-400 text-white px-2 py-1 rounded-md transition duration-300 ease-in hover:shadow-[0_0_4px_#ff0000]' onClick={handleRejectDisconnectionRequest}>Reject</button>
              <DisconnectionResourceModal id={row?.original?.id} isAcceptRequest={isAcceptRequest} openDisconnectionModal={openModal} setOpenDisconnectionModal={setOpenModal} />
            </Box>
          )
        }
      },
    ],
    [
      resourceColumnDefs.resourceName,
      {
        accessorKey: "consultant",
        header: "Skills",
        Cell: ({ cell, row }) => {
          const toggleShowIndex = (index) => {
            if (index === toggleIndex) {
              setToggleIndex(null);
            } else {
              setToggleIndex(index);
            }
          }

          const isToggle = row.index === toggleIndex;

          return (
          <Typography variant="body2Semibold">
            {isToggle
              ? row?.original?.consultant?.skills.join(", ")
              : row?.original?.consultant?.skills.slice(0, 3).join(", ")
            }
            {row?.original?.consultant?.skills.length > 3 &&
              <Typography
                sx={{ color: "#0B85BA", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
                onClick={() => toggleShowIndex(row.index)}
              >
                {isToggle ? " less..." : " more..."}
              </Typography>}
          </Typography>
          )
        },
      },
      resourceColumnDefs.experience,
      {
        accessorKey: "isDisconnectRequest",
        header: <span className='pl-6'>Actions</span>,
        enableColumnFilter: false,
        Cell: ({cell, row}) => { 
          return(
            <>
            {row?.original?.requestFrom === "CONSULTANT" && !row?.original?.isApproved && <Box display={"flex"}>
              <button className='bg-green-400 text-white px-2 py-1 mr-4 rounded-md transition duration-300 ease-in hover:shadow-[0_0_4px_#00b400]' onClick={()=>handleAcceptConnectionRequest(row?.original?.id)}>Accept</button>
              <button className='bg-red-400 text-white px-2 py-1 rounded-md transition duration-300 ease-in hover:shadow-[0_0_4px_#ff0000]' onClick={handleRejectConnectionRequest}>Reject</button>
              <ConnectionResourceModal id={row?.original?.id} openModal={openRejectConnModal} setOpenModal={setOpenRejectConnModal} setIsReloadAPI={setIsReloadAPI} isReloadAPI={isReloadAPI}/>
            </Box>}
            </>
          )
        }
      },
      // resourceColumnDefs.requestType,
    ],
    [
      resourceColumnDefs.resourceName,
      // resourceColumnDefs.requestType,
    ],
  ];

  const resourcesTableColumns = [
    [
      resourceColumnDefs.resourceName,
      {
        accessorKey: "client.company",
        header: "Current Job",
        Cell: ({ cell, row }) => {
          const toggleShowIndex = (index) => {
            if (index === toggleJobIndex) {
              setToggleJobIndex(null);
            } else {
              setToggleJobIndex(index);
            }
          }

          const isToggle = row.index === toggleJobIndex;

          return(
            <>
              <Typography variant="body2Semibold" sx={{ color: "#182743" }}>
                {/* {row.original?.job?.name} */}
                {isToggle ? row?.original?.activeJobs?.map((item) => item?.name)?.join(", ") : row?.original?.activeJobs?.map((item) => item?.name)?.slice(0, 3)?.join(", ")}
                {row?.original?.activeJobs?.length > 3 &&
                  <Typography
                    sx={{ color: "#0B85BA", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
                    onClick={() => toggleShowIndex(row.index)}
                  >
                    {isToggle ? " less..." : " more..."}
                  </Typography>}
              </Typography>
              <Typography variant="captionNormal" sx={{ color: "#6B7280" }}>
                {cell.getValue()}
              </Typography>
            </>
          )
        },
        size: 120,
      },
      resourceColumnDefs.isPublishMarketplace,
      resourceColumnDefs.isPublishPortfolio,
      resourceColumnDefs.isPublishSubscription,
      {
        accessorKey: "consultant",
        header: "Skills",
        Cell: ({ cell, row }) => {
          const toggleShowIndex = (index) => {
            if (index === toggleIndex) {
              setToggleIndex(null);
            } else {
              setToggleIndex(index);
            }
          }

          const isToggle = row.index === toggleIndex;

          return (
          <Typography variant="body2Semibold">
            {isToggle
              ? row?.original?.consultant?.skills?.join(", ")
              : row?.original?.consultant?.skills?.slice(0, 3).join(", ")
            }
            {row?.original?.consultant?.skills?.length > 3 &&
              <Typography
                sx={{ color: "#0B85BA", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
                onClick={() => toggleShowIndex(row.index)}
              >
                {isToggle ? " less..." : " more..."}
              </Typography>}
          </Typography>
          )
        },
      },
    ],
    [
      resourceColumnDefs.resourceName,
      resourceColumnDefs.vendor,
      resourceColumnDefs.experience,
      // resourceColumnDefs.skills
    ],
    [
      resourceColumnDefs.resourceName,
      resourceColumnDefs.to,
      resourceColumnDefs.experience,
      // resourceColumnDefs.skills,
      resourceColumnDefs.action,
    ],
    [
      resourceColumnDefs.resourceName,
      resourceColumnDefs.to,
      resourceColumnDefs.experience,
      // resourceColumnDefs.skills
    ],
    [
      resourceColumnDefs.resourceName,
      // resourceColumnDefs.job,
      // resourceColumnDefs.isPublishMarketplace,
      // resourceColumnDefs.isPublishPortfolio,
      // resourceColumnDefs.isPublishSubscription,
    ],
  ];

  return (
    <CustomTable
      state={{ sorting, pagination, isLoading, ...((tabs === 0) && { rowSelection }) }}
      paginationDisplayMode="pages"
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      manualPagination={true}
      rowCount={rowCount}
      columns={[
        ...(tabs === 4 ? requestsResourcesColumns[subActiveTab] : resourcesTableColumns[tabs]),
      ]}
      onRowSelectionChange={setRowSelection}
      data={resourcesData}
      {...((tabs === 0) && isRowSelection && { enableRowSelection: true })}
    />
  );
}

export default ResourceTable;
