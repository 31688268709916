import React, {
  useState, createContext, useContext,
} from 'react';
import Swal from 'sweetalert2';
import api from '../../Utils/api';
import { timesheetStatus } from '../../Utils/constants';
import { weekNames } from 'Utils/termsandConditions';

const apiPath = {
  checkPinRequired: '/timesheet/link/ispinrequired',
  verifyPin: '/timesheet/link/verify',
  approveTimesheet: '/timesheet/link/approvetimesheet',
  rejectTimesheet: '/timesheet/link/rejecttimesheet',
};

const nullFn = () => null;
const PublicTimesheetContext = createContext({
  loading: true,
  timesheets: null,
  timesheetMaster: null,
  days: [],
  approveTimesheet: (param1, param2, param3) => null,
  rejectTimesheet: (param1, param2, param3) => null,
  isPinRequired: nullFn,
  fetchTimesheetDetails: param =>  null,
  verifyPin: nullFn,
});

export const usePublicTimesheetProvider = () => useContext(PublicTimesheetContext);

function PublicTimesheetProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [timesheets, setTimesheets] = useState(null);
  const [days, setDays] = useState([]);
  const [timesheetMaster, setTimesheetMaster] = useState(null);

  const approveTimesheet = async (timesheet_ids, comments, email) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.approveTimesheet, {
        timesheet_ids,
        comments,
        email,
      });

      if (res.status === 200 && res.data?.success) {
        const temp = timesheets.map((t) => ({
          ...t,
          status: timesheetStatus.approved,
        }));

        setTimesheets(temp);
      } else {
        throw new Error('Error occurred while approving timesheet');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const rejectTimesheet = async (timesheet_ids, comments, email) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.rejectTimesheet, {
        timesheet_ids,
        comments,
        email,
      });

      if (res.status === 200 && res.data?.success) {
        const temp = timesheets.map((t) => ({
          ...t,
          status: timesheetStatus.rejected,
        }));

        setTimesheets(temp);
      } else {
        throw new Error('Error occurred while approving timesheet');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isPinRequired = async (linkId) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.checkPinRequired, {
        params: { id: JSON.stringify(linkId) },
      });

      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        const timesheetMapping = res?.data?.results?.map((item)=> {
          return {
            ...item?.timesheet?.[0]
          }
        });

        // Array of timesheets, length depends on the number of weeks
        if (res.data?.results) {
          setTimesheets(timesheetMapping);
          // setDays(timesheetMapping);
        };
        if (res.data?.results?.[0]?.timesheet_id) setTimesheetMaster({ id: res.data?.results?.[0]?.timesheet_id });

        return res.data.results;
      }
      throw new Error('Error occurred while approving timesheet');
    } catch (error) {
      setLoading(false);
      console.error(error);
      return null;
    }
  };

  const verifyPin = async (pin, token) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.verifyPin, { pin: parseInt(pin) }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.status === 200 && res.data?.success) {
        setLoading(false);

        // Array of timesheets, length depends on the number of weeks
        if (res.data.timesheet?.length) setTimesheets(res.data.timesheet);
        if (res.data.timesheet_id) setTimesheetMaster({ id: res.data.timesheet_id });

        return res.data.result;
      }
      throw new Error('Error occurred while approving timesheet');
    } catch (error) {
      setLoading(false);
      console.error(error);
      return null;
    }
  };

  const fetchTimesheetDetails = async (timesheetId) => {
    setLoading(true);
    try {
      const res = await api.get(`/timesheet/link/timesheet`, {
        params: {
          id: JSON.stringify(timesheetId)
        }
      });

      if (res.status === 200) {
        const getDayName = (dateString) => {
          const date = new Date(dateString);
          const dayNames = weekNames.map((item)=> item.slice(0.3).toLowerCase());
          return dayNames[date.getUTCDay()];
        };
        const resultMapping = res?.data?.result?.map(timesheet => {
          const week = {
            ...timesheet,
            job_name: timesheet.job.name,
            consultant_name: `${timesheet.consultant.firstName} ${timesheet.consultant.lastName}`,
            days: {}
          };
  
          timesheet.days.forEach((day, index) => {
            const dayName = getDayName(day.date);
            week.days[dayName] = day.hours;
          });
  
          return week;
        });
        // let hours = 0;
        // let minutes = 0;
        // res.data.timesheet?.pagination?.items.forEach((t) => {
        //   hours += t.hours;
        //   minutes += t.minutes;
        // });

        // if (minutes >= 60) {
        //   hours += Math.floor(minutes / 60);
        //   minutes %= 60;
        // }

        // setTimesheetMaster((prev) =>({
        //   ...timesheetMaster,
        //   hours: res?.data?.result?.hours,
        //   minutes: res?.data?.result?.minutes,
        //   consultant: res.data.timesheet?.pagination?.items?.[0]?.consultant,
        //   job: res.data.timesheet?.pagination?.items?.[0]?.job,
        // }));

        setDays(resultMapping || []);
        setLoading(false);
      } else {
        throw new Error('Error occurred while fetching timesheet details');
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Swal.fire('Error', 'Error occurred while fetching timesheet', 'error');
    }
  };


  const value = {
    loading,
    timesheets,
    timesheetMaster,
    days,
    approveTimesheet,
    rejectTimesheet,
    isPinRequired,
    fetchTimesheetDetails,
    verifyPin,
  };

  return (
    <PublicTimesheetContext.Provider value={value}>
      {children}
    </PublicTimesheetContext.Provider>
  );
}

export default PublicTimesheetProvider;
