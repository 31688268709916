import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const profileDetails = {
  first_name: '',
  last_name: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zipcode: '',
  linkdin: '',
  portfolio_link: '',
  resume: '',
  availability: '',
  availableOption: {
    name: "",
    date: ""
  },
  employment_details: {},
  profile_image: '',
  experiences: [],
  education: [],
  about_me: '',
  company: '',
  company_size: '',
  certificates: [],
  business_type: '',
  public_profile_id: '',
  year_founded: '',
  website: '',
  services: [],
  skills: [],
  skills_with_rating: [],
  organisation_details: {
    name: '',
    company_size: '',
    services_skills: [],
    founded_date: '',
    industry_type_id: '',
    website: '',
  },
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    details: profileDetails,
    editResourceDetails: profileDetails,
    isResourceEditing: false,
    errors: {}
  },
  reducers: {
    setUpdatedDetails: (state, action) => {
      state.details = { ...state.details, ...action.payload };
    },
    setResourceUpdatedDetails: (state, action) => {
      state.editResourceDetails = { ...state.editResourceDetails, ...action.payload };
    },
    updateResourceEditing: (state, action) => {
      state.isResourceEditing = action.payload
    },
    setErrors: (state, action) => {
      state.errors = { ...action.payload };
    },
    removeErrors: (state, action) => {
      delete state.errors[action.payload.key]
      state.errors = { ...state.errors };
    },
    addEducationData: (state, action) => {
      const newEducation = action.payload;
      const updatedEducationData = [...state[state.isResourceEditing ? 'editResourceDetails' : 'details'].education, newEducation];
      
      updatedEducationData.sort((a, b) => {
        const aStart = dayjs(`${a.start_year}-01-01`);
        const bStart = dayjs(`${b.start_year}-01-01`);

        if (aStart.isBefore(bStart)) return 1;
        if (aStart.isAfter(bStart)) return -1;

        const aEnd = a.end_year ? dayjs(`${a.end_year}-01-01`) : dayjs();
        const bEnd = b.end_year ? dayjs(`${b.end_year}-01-01`) : dayjs();

        if (aEnd.isBefore(bEnd)) return 1;
        if (aEnd.isAfter(bEnd)) return -1;

        return 0;
      });      
      state[state.isResourceEditing ? 'editResourceDetails' : 'details'].education = updatedEducationData;
    },
    updateEducationData: (state, action) => {
      state[state.isResourceEditing ? 'editResourceDetails' : 'details']['education'] = state[state.isResourceEditing ? 'editResourceDetails' : 'details']['education'].map((i) => {
        if (action.payload.id === i.id) {
          return { ...i, ...action.payload }
        }
        return i
      });
    },
    deleteEducationData: (state, action) => {
      state[state.isResourceEditing ? 'editResourceDetails' : 'details']['education'] = state[state.isResourceEditing ? 'editResourceDetails' : 'details']['education'].filter(i => i.id !== action.payload.id);
    },
    addExperiences: (state, action) => {
      const newExperience = action.payload;
      const updatedExperiences = [...state[state.isResourceEditing ? 'editResourceDetails' : 'details'].experiences, newExperience];
      updatedExperiences.sort((a, b) => {
        const aStart = dayjs(a.start_date);
        const bStart = dayjs(b.start_date);

        if (aStart.isBefore(bStart)) return 1;
        if (aStart.isAfter(bStart)) return -1;

        const aEnd = a.end_date ? dayjs(a.end_date) : dayjs();
        const bEnd = b.end_date ? dayjs(b.end_date) : dayjs();

        if (aEnd.isBefore(bEnd)) return 1;
        if (aEnd.isAfter(bEnd)) return -1;

        return 0;
      });
      state[state.isResourceEditing ? 'editResourceDetails' : 'details'].experiences = updatedExperiences;
    },
    updateExperiences: (state, action) => {
      state[state.isResourceEditing ? 'editResourceDetails' : 'details']['experiences'] = state[state.isResourceEditing ? 'editResourceDetails' : 'details']['experiences'].map((i) => {
        if (action.payload.id === i.id) {
          return { ...i, ...action.payload }
        }
        return i
      });
    },
    deleteExperiences: (state, action) => {
      state[state.isResourceEditing ? 'editResourceDetails' : 'details']['experiences'] = state[state.isResourceEditing ? 'editResourceDetails' : 'details']['experiences'].filter(i => i.id !== action.payload.id);
    },
    setAvailableOptionR : (state, action) => {
      state[state.isResourceEditing ? 'editResourceDetails' : 'details'].availableOption = action.payload;
    }
  },
});

export const { setUpdatedDetails, setErrors, removeErrors,updateResourceEditing, setResourceUpdatedDetails, addEducationData, updateEducationData, deleteEducationData, addExperiences, updateExperiences, deleteExperiences, setAvailableOptionR } = profileSlice.actions;

export default profileSlice.reducer;
