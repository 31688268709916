import BottomImage from './bottomImage.jpg'

import first from './first.png'
import second from './second.png'
import third from './third.png'

import unwavering1 from './unwavering1.png'
import unwavering2 from './unwavering2.png'
import unwavering3 from './unwavering3.png'

import {ReactComponent as IndiaIcon} from "../../Assets/About/indiaIcon.svg";
import {ReactComponent as UsaIcon} from "../../Assets/About/usaIcon.svg";

export const AboutUsData = {
    "heading": "About us",
    "subheading": <>We are strategists, designers and developers. Innovators and problem<br /> solvers. Small enough to be simple, quick and creative. </>,
    "bottomImage": BottomImage,
    sectionTitle: "Our Story",
    sectionSubtitle: `ProLegion is a brand that began with the vision to streamline contingent workforce management for enterprises. We noticed how businesses struggle to streamline their contractual workforce & the projects they work on. We are here to fill that gap.`,
    "sections": [
        {
            "img": first,
            "heading": <>Vision & Mission</>,
            "subheading": <>Enterprise tools cost more, are difficult to use, and take longer to implement. According to G2, ProLegion is the easiest-to-use tool, with the fastest time to ROI.</>,
            ourStorysectionPointers: [
                "Leveraging data to improve efficiency",
                "Creating a community of professionals",
                "Streamline project management",
                "Helping consulting firms grow",
                "Making contingent workforce hiring easy",
                "Simplifying business management"
            ],
        },
        // {
        //     "img": second,
        //     "heading": <>A better platform and partner</>,
        //     "subheading": <>Third party research shows ProLegion has a net promoter score of 31, while our competitors’ score is 2. The market has spoken—more customers are satisfied with our platform and service.</>
        // },
        // {
        //     "img": third,
        //     "heading": <>A better platform and partner</>,
        //     "subheading": <>Third party research shows ProLegion has a net promoter score of 31, while our competitors’ score is 2. The market has spoken—more customers are satisfied with our platform and service.</>
        // },
    ],
    "professionals": {
        "heading": "By professionals, for professionals",
        "subheading": <>Track work across the enterprise through an open, collaborative platform. Link issues across Jira and ingest data from other software development tools, so your IT support and operations teams have richer contextual information to rapidly respond to requests, incidents, and changes.<br /><br /> Deliver great service experiences fast - without the complexity of traditional ITSM solutions.Accelerate critical development work, eliminate toil, and deploy changes with ease.</>,
        "listHeading": {
            "heading": <>Want to see more?</>,
            "list": [
                "Explore ProLegion and take your marketplace to the next level.",
                "Explore ProLegion and take your marketplace to the next level.",
                "Explore ProLegion and take your marketplace to the next level.",
                "Explore ProLegion and take your marketplace to the next level.",
                "Explore ProLegion and take your marketplace to the next level."
            ]
        }
    },
    "unwavering": {
        "heading": "Unwavering in our commitment to trust",
        "images": [
            unwavering1,
            unwavering2,
            unwavering3
        ],
        "details": {
            "Developers": "73M+",
            "Repositories": "100M+",
            "Open source projects": "1000s",
            "Companies": "90+"
        }
    },
    addressData: [
        {
            icon: <IndiaIcon />,
            country: "INDIA",
            address: {
                map: "Bajuri Hamirpur(HP) , Himachal Pradesh",
                phone: "7018401613",
                email: "contactus@prolegion.com"
            }
        },
        {
            icon: <UsaIcon />,
            country: "UNITED STATES",
            address: {
                map: "Austin, Texas, ",
                mapSub: "United States of America",
                email: "contactus@prolegion.com"
            }
        },
    ]
}