/* eslint-disable import/no-unresolved */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/800.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ThemeProvider } from '@mui/material';
import AlertProvider from 'Providers/util/Alert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { store } from './Redux/Store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import "react-datepicker/dist/react-datepicker.css";
import { theme } from './theme';

dayjs.extend(isoWeek);
createRoot(document.getElementById('root')).render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <App />
        </AlertProvider>
      </ThemeProvider>
    </Provider>
  </LocalizationProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
