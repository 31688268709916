import React, { useMemo, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { FullWidthWrapper } from "../../Components/Common";
import { resourcesSubTab, resourcesTab } from "./Resources.constants";
import ResourceTable from "./ResourcesTable/Resources.table";
import AddResourceModal from "./AddResourceModal";
import ImportResources from "./ImportResources";
import ShareResourcesModal from "./ShareResourcesModal";
import DeleteResourceModal from "./DeleteResourceModal";
import useResource from "./useResource";
import AddShadowResource from "./AddShadowModal";
import { useSelector } from "react-redux";
import { selectProfileDetails } from "Redux/Feature/profile/profile.selector";

export default function ResourcesPage() {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [subActiveTab, setSubActiveTab] = useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const profile = useSelector(selectProfileDetails)
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [sorting, setSorting] = React.useState([
    { id: "createdAt", desc: undefined },
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [isReloadAPI, setIsReloadAPI] = useState(false);

  const isDesc = useMemo(() => {
    return sorting[0]?.desc ? "desc" : "asc";
  }, [sorting]);

  useMemo(() => {
    setPagination({
      pageSize: 10,
      pageIndex: 0,
    })
  }, [activeTab, subActiveTab])

  const { isLoading, resourcesData, fetchResourcesDetails } = useResource({
    searchValue: "",
    selectedTab: activeTab,
    selectedSubTab: subActiveTab,
    pagination,
    sorting,
    openModal,
    isReloadAPI,
    setRowCount,
    setPagination,
    sortBy: isDesc
  });
  const [rowSelection, setRowSelection] = useState([]);
  const handleTabChange = (_, value) => setActiveTab(value);
  const handleSubTabChange = (_, value) => setSubActiveTab(value);
  return (
    <FullWidthWrapper sidebar sx={{ m: 2 }}>
      <Grid container columns={12} spacing={2}>
      <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="subtitle1Bold"
              color="#182743"
              component="h3"
              sx={{ mb: 2.5 }}
            >
              Resources
            </Typography>
            <Box display="flex" gap="10px" alignItems={'center'}>
              {selectedTabs.length > 0 &&
                selectedTabs.every((i) =>
                  ["Current", "CRNT"].includes(i.status)
                ) && <ShareResourcesModal selectedTabs={selectedTabs} />}
              {selectedTabs.length > 0 &&
                selectedTabs.every((i) =>
                  ["Current", "CRNT"].includes(i.status)
                ) && (
                  <DeleteResourceModal
                    setSelectedTabs={setSelectedTabs}
                    fetchResourcesDetails={fetchResourcesDetails}
                    selectedTabs={selectedTabs}
                    setRowSelection={setRowSelection}
                  />
                )}
              <AddResourceModal />
              <ImportResources />
              {profile?.selfManagedResources && <AddShadowResource fetchResourcesDetails={fetchResourcesDetails} />}
            </Box>
          </Box>
          <Box
            marginTop="20px"
            marginBottom="8px"
            display="flex"
            justifyContent="space-between"
          >
            <Tabs value={activeTab} onChange={handleTabChange}>
              {resourcesTab.map((tab) => (
                <Tab label={tab.name} />
              ))}
            </Tabs>
            {/* <OutlinedInput
              placeholder="Search Client or job"
              startAdornment={
                <InputAdornment position="start">
                  <Search sx={{ fontSize: 28 }} />
                </InputAdornment>
              }
              sx={{
                pl: 1,
                py: 0.5,
                borderRadius: "8px",
                typography: "body2Medium",
                color: "#6B7280",
                backgroundColor: "#F9FAFB",
              }}
            /> */}
          </Box>
          {activeTab === 4 && <Box
            marginBottom={"20px"}
            width={"fit-content"}
          >
            <Tabs value={subActiveTab} onChange={handleSubTabChange}>
              {resourcesSubTab.map((item, index) => (
                <Tab key={index} label={item} />
              ))}
            </Tabs>
          </Box>}
          <ResourceTable
            sorting={sorting}
            pagination={pagination}
            rowCount={rowCount}
            setPagination={setPagination}
            setSorting={setSorting}
            setRowCount={setRowCount}
            isLoading={isLoading}
            setSelectedTabs={setSelectedTabs}
            setSubActiveTab={setSubActiveTab}
            tabs={activeTab}
            subActiveTab={subActiveTab}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            resourcesData={resourcesData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            isReloadAPI={isReloadAPI}
            setIsReloadAPI={setIsReloadAPI}
            tableHeading={resourcesTab[activeTab].name}
          />
        </Paper>
      </Grid>
    </FullWidthWrapper>
  );
}
