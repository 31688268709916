import { createSlice } from "@reduxjs/toolkit";

export const sharedConsultantDataSlice = createSlice({
    name: "sharedConsultantData",
    initialState: {
        data: [],
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setData } = sharedConsultantDataSlice.actions;

export default sharedConsultantDataSlice.reducer;
