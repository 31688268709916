import { Box, Typography } from '@mui/material'
import MyEditor from 'Components/TextEditor';
import React from 'react'
import { Controller } from 'react-hook-form';

function HookFormTextEditor({
  name,
  control,
  label,
  validation,
  errors,
  required,
  height
}) {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography sx={{display: "flex"}} variant='defaultFont' fontWeight={"600"} fontSize={"14px"} color={"#111928"}>{label} {required && <Typography paddingLeft={"4px"}>*</Typography>}</Typography>
      {control &&
        <Controller
          name={name}
          control={control}
          rules={validation || {}}
          render={({ field: { onChange, value, onBlur } }) => (
            <>
              <MyEditor
                html={value}
                updateRaw={onChange} 
                height={height}
              />
            </>
          )}
        />}
      {errors && <Typography paddingLeft={"1rem"} paddingBottom={"1rem"} color={"red"}>{errors?.message}</Typography>}
    </Box>
  )
}

export default HookFormTextEditor;