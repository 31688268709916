import React from 'react'

function ProgressBar({baseClassname, progress, steps, border}) {
  
  const progressPercentage = steps ? `w-[calc(100%/${steps})]` : progress ? `w-[${progress}]` : "w-[0%]";
  const borderWidth = border ? `border-t-[${border}]` : "border-4";

  return (
    <div className={`${progressPercentage} ${borderWidth} ${baseClassname}`}></div>
  )
}

export default ProgressBar;
