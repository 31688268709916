const { sharedResources } = require('Utils/api');

export const fetchPlans = (params) => sharedResources.get('plans', { params });

export function createSubscription(id, payload, isStripe) {
  const paymentRoute = isStripe ? 'subscribe-by-stripe' : 'subscribe-by-razorpay';
  return sharedResources.post(`customers/${id}/${paymentRoute}`, payload);
}

export function fetchCustomerDetails() {
  return sharedResources.get('customers/me');
}

export function createSubscriptionCustomer(payload, paymentType) {
  return sharedResources.post(`customers?type=${paymentType}`, payload);
}

export function fetchActiveSubscription(id, config) {
  return sharedResources.get(`customers/${id}/subscriptions/active`, config);
}

export function fetchSubscriptionsList(id, config = {}) {
  return sharedResources.get(`customers/${id}/subscriptions`, config);
}
