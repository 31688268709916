/* eslint-disable max-len */
import React from 'react';
const buildingImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/building.svg';
const effectiveImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/effective.png';
const communicationImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/communication.png';
const clientDashboard = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/dashboard2.png';
const client1Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/client1.png';
const client2Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/client2.png';
const client3Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/client3.png';

const vendor1Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/vendor1.png';
const vendor2Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/vendor2.png';
const vendor3Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/vendor3.png';

const consultant1Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/consultant1.png';
const consultant2Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/consultant2.png';
const consultant3Img = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/consultant3.png';

const effectivelyImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/endtoend.svg';
const endtoendImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/effectively.svg';
const venCommunicationImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/vencommunication.svg';
const vendorDashboardImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/vendorDashboard.png';
const consultantDashImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/consultantDash.png';
const effecientImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/timesheet.svg';
const timeSheetImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/effecient.svg';
const visibilityImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/visibility.svg';

export const userTypeCardsContent = {
  client: {
    cardData: [
      {
        img: buildingImg,
        heading: 'Robust Vendor Management',
        subHeading: 'Our cloud-based vendor management system helps you oversee and collaborate with your network for vendors seamlessly.',
      },
      {
        img: effectiveImg,
        heading: 'Remote Workforce Management',
        subHeading: 'No matter where your resources are located, you can manage and track their performance seamlessly with ProLegion.',
      },
      {
        img: communicationImg,
        heading: 'Enhanced Team Collaboration',
        subHeading: `Create a collaborative environment for your team to work in. Utilise ProLegion's advanced & easy communication tools.`,
      },
    ],
    fullCard: {
      heading: 'Monitor real-time data and empower your business with proactive decision-making',
      subHeading: `ProLegion's real-time performance tracking and analysis features provide you with just the right amount of data required to make informed decisions. Resolve bottlenecks on the go with enhanced clarity. `,
      banner: clientDashboard,
    },
    featureTitle: {
      title1: "Simplified Vendor Management",
      title2: "System Features",
    },
    features: [
      {
        id: 1,
        heading: 'Expenditure Analysis',
        subHeading: "The viability of a business hinges on efficient budgeting. With the clarity and data-driven insights from ProLegion, know exactly what you are spending on your projects & why.",
        img: client1Img,
      },
      {
        id: 2,
        heading: 'Resource Management',
        subHeading: 'Our vendor management system stands out from the rest of them because we bring all resource management tools to one place.',
        img: client2Img,
      },
      {
        id: 3,
        heading: 'Invoices and Timesheets',
        subHeading: 'Our invoice management and timesheet management tools track data with accuracy and ensure that you are paying for the value you get.',
        img: client3Img,
      },
    ],
  },
  vendor: {
    cardData: [
      {
        img: endtoendImg,
        heading: 'Streamlined Operations',
        subHeading: 'ProLegion offers vendors a centralized resource management platform to manage the workforce, reducing administrative load and enhancing efficiency.',
      },
      {
        img: effectivelyImg,
        heading: 'Enhanced Visibility and Opportunities',
        subHeading: 'Vendors can showcase their expertise, access a marketplace for new projects,  and find the best at-home job opportunities.',
      },
      {
        img: venCommunicationImg,
        heading: 'AI-driven Matching',
        subHeading: 'Leveraging advanced AI algorithms, ProLegion matches vendors with best at-home job opportunities based on their skills and past successes.',
      },
    ],
    fullCard: {
      heading: 'Empower your business growth with our Portfolio Builder tool',
      subHeading: `Increase your business's visibility and credibility within the ProLegion ecosystem by showcasing your expertise, past projects, and achievements using our Portfolio Builder tool. `,
      banner: vendorDashboardImg,
    },
    featureTitle: {
      title1: "Manage Resources &",
      title2: "Improve Client Relationships",
    },
    features: [
      {
        id: 4,
        heading: `Seamless Collaboration Platform`,
        subHeading: `ProLegion provides a collaborative resource management platform where vendors can easily communicate with team members and partners, ensuring efficient project delivery.`,
        img: vendor1Img,
      },
      {
        id: 5,
        heading: <>Task Assignment and <br/> Tracking</>,
        subHeading: `Vendors can streamline resource management by assigning tasks to resources, tracking their progress, and ensuring timely completion of projects.`,
        img: vendor2Img,
      },
      {
        id: 6,
        heading: `Automated Timesheet and Invoice Management`,
        subHeading: `ProLegion automates timesheet tracking and invoice generation processes, ensuring accurate billing, thus improving cash flow management for vendors.`,
        img: vendor3Img,
      },
    ],
  },
  consultant: {
    cardData: [
      {
        img: visibilityImg,
        heading: 'Advanced Job Matching',
        subHeading: `ProLegion utilizes AI algorithms to match consultants with online remote jobs based on their skills and preferences, ensuring efficient and tailored job searches for consultants.`,
      },
      {
        img: effecientImg,
        heading: 'Portfolio Showcase',
        subHeading: 'Consultants can effectively showcase their skills, experiences, and past projects on their ProLegion profile, attracting potential clients.',
      },
      {
        img: timeSheetImg,
        heading: 'Networking Opportunities',
        subHeading: 'ProLegion fosters a community of consultants, clients, and industry professionals, providing networking opportunities for consultants.',
      },
    ],
    fullCard: {
      heading: 'Profile management using ProLegion Profile Builder',
      subHeading: `Utilize the ProLegion Profile Builder to create and maintain a comprehensive profile, showcasing your expertise, qualifications, and achievements effortlessly.`,
      banner: consultantDashImg,
    },
    featureTitle: {
      title1: "Features to help you",
      title2: "System Features",
    },
    features: [
      {
        id: 7,
        heading: 'Effortless Profile Management',
        subHeading: `ProLegion enables consultants to easily manage their profiles ensuring accurate and up-to-date information for potential clients.`,
        img: consultant2Img,
      },
      {
        id: 8,
        heading: 'Real-Time Project Insights',
        subHeading: `Consultants can stay informed about project updates, client feedback, and industry trends in real-time, empowering them to make informed decisions.`,
        img: consultant1Img,
      },
      {
        id: 9,
        heading: 'Direct Client Engagement',
        subHeading: `ProLegion provides tools for consultants to engage directly with clients increasing the chances for consultants to secure the online remote jobs that they are interested in.`,
        img: consultant3Img,
      },
    ],
  },
};
