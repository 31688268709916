import { ChevronLeft, ChevronRight, InfoOutlined } from "@mui/icons-material";
import DownloadingIcon from '@mui/icons-material/Downloading';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import {
  getJobsWithTimesheetsByJob,
} from "Components/InvoiceCreateForm/api";
import { JobBadge } from "Components/JobBadge";
import { useAlertProvider } from "Providers/util/Alert";
import api from "Utils/api";
import { formatTime } from "Utils/helper";
import dayjs from "dayjs";
import useCalender from "hooks/useCalender";
import useDebounce from "hooks/useDebounce";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CustomTable } from "../../../Components";
import TimesheetPdf from "../../../Components/TimesheetPdf";
import { useTimesheetProvider } from "../../../Providers/Timesheet.provider";
import { timesheetStatus } from "../../../Utils/constants";
import hoursToDecimal from "../../../Utils/hoursToDecimal";
import { ShareTimesheet } from "../ShareTimesheet";
import { useSelector } from "react-redux";
import { timesheetSelector } from "Redux/Feature/timesheet";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TimesheetApproveReject } from "../TimesheetApproveReject";

function formDataToStructuredObject(formData, status) {
  const structuredObject = {
    job_id: null,
    weekDetails: {
      start: null,
      end: null,
      status,
    },
    dayDetails: [],
  };

  let currentDayDetail = {};
  
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of formData.entries()) {
    if (key === "jobId") {
      structuredObject.job_id = value;
    } else if (key === "start") {
      structuredObject.weekDetails.start = value;
    } else if (key === "end") {
      structuredObject.weekDetails.end = value;
    } else if (key === "date" || key === "hours" || key === "description" || key == "checkin_time" || key == "checkout_time") {
      if (key === "date" && currentDayDetail.date) {
        // When a new 'date' is encountered, push the current day detail and start a new one
        structuredObject.dayDetails.push({
          ...currentDayDetail,
          date: currentDayDetail.date,
        });
        currentDayDetail = {};
      }
      if (key === "hours") currentDayDetail[key] = Number(value).toFixed(2);
      else currentDayDetail[key] = value;
    }
  }
  // Push the last day detail if it's not empty
  if (currentDayDetail.date) {
    structuredObject.dayDetails.push({
      ...currentDayDetail,
      date: currentDayDetail.date,
    });
  }

  return structuredObject;
}

export default function TimesheetDetailTable({ create, loading, isPublic }) {
  const [isLoading, setIsLoading] = useState(loading);
  const [jobId, setJobId] = React.useState("");
  const navigate = useNavigate();
  const [changeWeekHandle, startDate, endDate] = useCalender();
  const { error, success } = useAlertProvider();
  const changedStartDate = useDebounce(startDate, 1000);
  const {timesheetToken} = useSelector(timesheetSelector);

  const { timesheet = {}, jobs = [] } = useLoaderData();
  useEffect(()=> {
    const isCheckinOutNull = timesheet?.days?.find((item)=> item);
    if(isPublic && !!isCheckinOutNull.checkin_time && !!isCheckinOutNull.checkout_time) {
      setShowCheckInCheckOut(true);
    }
  },[isPublic])

  React.useEffect(() => {
    if (jobs.length > 0) {
      setJobId(jobs[0]?.job?.id);
    }
  }, [jobs]);

  useEffect(() => {
    setIsLoading(false);
    if (timesheet && Object.keys(timesheet).length > 0) {
      setIsLoading(true);
      if (timesheet?.job?.isCheckinCheckout) setShowCheckInCheckOut(true);
      return setDaysForm(timesheet.days.map((i, index) => { return { ...i, key: index }; }));
    }
    const datesArray = [];
    const dates = [];
    let currentDate = moment(startDate);
    const endDateMoment = moment(endDate);
    while (currentDate <= endDateMoment) {
      if (currentDate.isSame(startDate, 'month')) {
        const formattedDate = currentDate.format("YYYY-MM-DDT00:00:00.000[Z]");
        datesArray.push(formattedDate);
        dates.push({
          date: formattedDate,
          hours: null,
          description: "",
        });
      }
      currentDate = currentDate.clone().add(1, "days");
    }
  
    const obj = {
      dates: datesArray,
      period: `${moment(startDate).format("dddd, MMMM D, YYYY")} - ${moment(
        endDate
      ).format("dddd, MMMM D, YYYY")}`,
      startDate: moment(startDate).format("YYYY-MM-DDT00:00:00.000[Z]"),
      endDate: moment(endDate).format("YYYY-MM-DDT00:00:00.000[Z]"),
    };
    setTimeSheetDetails(obj);
    setDaysForm(
      dates.map((item, index) => ({
        ...item,
        id: index,
        key: index,
        hours: null,
        description: "",
        checkin_time: "",
        checkout_time: "",
      }))
    );
  }, [startDate, endDate]);
    

  const { downloadTimesheetasPdf } =
    useTimesheetProvider();
  const edit = [timesheetStatus.saved, timesheetStatus.rejected].includes(
    timesheet.status
  );
  const [validationErrors, setValidationErrors] = React.useState({});
  const [daysForm, setDaysForm] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const [timesheetDetails, setTimeSheetDetails] = useState();
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [alreadySavedForLater, setAlreadySavedForLater] = useState(false);
  const [gettingTimesheetDetails, setGettingTimesheetDetails] = useState(false);
  const [savedItLaterDetails, setSaveItLaterDetails] = useState(null);
  const [showCheckInCheckOut, setShowCheckInCheckOut] = useState(false);
  const [currentTimeSheetStatus, setCurrentTimeSheetStatus] = useState('');
  const [timesheetId, setTimesheetId] = useState(null)

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };
  function isNextWeekAfterEndDate(endDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDateObj = new Date(endDate);
    endDateObj.setHours(0, 0, 0, 0);
    const nextWeekStartDate = new Date(endDateObj);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() - nextWeekStartDate.getDay() + 1);
    nextWeekStartDate.setHours(0, 0, 0, 0);
    const nextWeekEndDate = new Date(nextWeekStartDate);
    nextWeekEndDate.setDate(nextWeekEndDate.getDate() + 6);
    const isSameMonth = nextWeekStartDate.getMonth() === endDateObj.getMonth();
    const startsAfterToday = nextWeekStartDate > today;
    const endsInSameMonth = nextWeekEndDate.getMonth() === endDateObj.getMonth();

    return startsAfterToday && isSameMonth && endsInSameMonth;
  }

  const handleUpdateCell = (key, id, value) => {
    const updatedTimesheet = daysForm.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    setDaysForm(updatedTimesheet);
  };
  const handleUpdateCells = (data, id) => {
    const updatedTimesheet = daysForm.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...data
        };
      }
      return item;
    });
    setDaysForm(updatedTimesheet);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Get form data
    const formData = new FormData(event.currentTarget);
    const formattedData = formDataToStructuredObject(
      formData,
      selectedStatus
    );
    const mappedDayDetails = formattedData.dayDetails.map((dayItem, index) => {
      const mapId = daysForm[index].id;
      return {
        id: mapId,
        hours: daysForm[index].hours ? Number(daysForm[index].hours).toFixed(2) : '',
        description: dayItem.description,
        ...(
          showCheckInCheckOut && {
            checkin_time: dayItem.checkin_time,
            checkout_time: dayItem.checkout_time
          }
        )
      };
    });

    if (showCheckInCheckOut) formattedData.dayDetails = formattedData.dayDetails.map((i, index) => { return { ...i, hours: daysForm[index].hours } })

    // formattedData.dayDetails = [...mappedDayDetails].map(i => {
    //   delete i.id
    //   return i
    // });

    try {
      const updateTimesheet = async () =>
        api.put("/timesheet/updatetimesheet", {
          id: alreadySavedForLater
            ? savedItLaterDetails?.timesheet?.id
            : timesheet.id,
          status: formattedData.weekDetails.status,
          dayData: mappedDayDetails,
        });
      const createTimesheet = async () =>
        api.post("/timesheet/addtimesheet", formattedData);
      const response =
        create && !alreadySavedForLater
          ? await createTimesheet()
          : await updateTimesheet();
      if (response.data.success) {
        success(
          `Timesheet ${
            selectedStatus === "SAVED" ? "saved" : "submitted"
          } successfully`
        );
        if (create) {
          if (!isNextWeekAfterEndDate(startDate) && selectedStatus === 'SUBMITTED') {
            return changeWeekHandle("next");
          }
        }
        if (selectedStatus === 'SUBMITTED') {
          navigate(`/dashboard/timesheets/${response.data?.timesheet?.id}`, {
            replace: true,
          });
        }
          
      }
    } catch (e) {
      error(e?.response?.data?.msg || e?.response?.data?.error || "Something went wrong");
    }
  };

  const downloadTimesheetPdf = async () => {
    const doc = <TimesheetPdf data={[timesheet]} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    // download the blob
    downloadTimesheetasPdf(
      blob,
      new Date(timesheet.start).toLocaleString("default", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      }),
      new Date(timesheet.end).toLocaleString("default", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      })
    );
  };

  const totalHours =
    edit || create
      ? daysForm
          .reduce(
            (acc, curr) =>
              acc +
              Number(
                isNaN(curr?.hours) || curr?.hours > 24 ? 0 : curr?.hours || 0
              ),
            0
          )
          .toFixed(2)
      : hoursToDecimal(timesheet.hours, timesheet.minutes);

  // newTimesheet should have at least one item with hours
  const timesheetHasValues = daysForm.some(
    (item) => !!item.hours && !isNaN(item.hours)
  );
  const noValidationErrors = !Object.keys(validationErrors).length;
  const isValid = timesheetHasValues && noValidationErrors;
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        Cell: ({ cell }) => (
          <>
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {new Date(cell.getValue()).toLocaleString("default", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC"
              })}
            </Typography>
            <input type="hidden" name="date" value={cell.getValue()} />
          </>
        ),
        // size: 120,
        enableEditing: false,
      },
      {
        accessorKey: "checkin_time",
        header: "Check in",
        Cell: ({ cell, row }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {formatTime(cell.getValue())}
          </Typography>
        ),
        muiEditTextFieldProps: ({ row }) => ({
          variant: "outlined",
          label: "Check in",
          type: 'time',
          value: row.original.checkin_time ? dayjs(`${dayjs(row.original.date).format("YYYY-MM-DD")}T${row.original.checkin_time}`).format("HH:mm") : "",
          onChange: (e) => {
            const date = e.target.value ? dayjs(`${dayjs(row.original.date).format("YYYY-MM-DD")}T${e.target.value}`).toDate() : null;
            // handleUpdateCell("checkin_time", row.original.id, date);
            const diffInHours =  Number(dayjs(`${dayjs(row.original.date).format("YYYY-MM-DD")}T${row.original.checkout_time}`).diff(dayjs(date), "minute")/60).toFixed(1);
            if (date) {
              if (isNaN(diffInHours) || diffInHours > 24 || diffInHours < 0) {
                setValidationErrors((curr) => ({
                  ...curr,
                  hours: {
                    ...curr.hours,
                    [row.original.id.toString()]:
                      "Must be a number between 0 and 24",
                  },
                }));
              } else {
                if (validationErrors.hours?.[row.original.id.toString()]) delete validationErrors.hours?.[row.original.id.toString()];
                if (Object.keys(validationErrors?.hours || {}).length === 0) delete validationErrors.hours;
              }
            }
            handleUpdateCells({ hours: diffInHours, checkin_time: e.target.value }, row.original.id);
          },
        }),
        // size: 120,
      },
      {
        accessorKey: "checkout_time",
        header: "Check out",
        Cell: ({ cell, row }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {formatTime(cell.getValue())}
          </Typography>
        ),
        muiEditTextFieldProps: ({ row }) => ({
          variant: "outlined",
          label: "Check out",
          type: "time",
          // value: dayjs(row.checkout_time.checkout_time).format("YYYY-MM-DDTHH:mm") || "",
          value: row.original.checkin_time ? dayjs(`${dayjs(row.original.date).format("YYYY-MM-DD")}T${row.original.checkout_time}`).format("HH:mm") : "",
          onChange: (e) => {
            // const date = e.target.value ? dayjs(e.target.value).toDate() : null;
            const date = e.target.value ? dayjs(`${dayjs(row.original.date).format("YYYY-MM-DD")}T${e.target.value}`).toDate() : null;
            // handleUpdateCell("checkout_time", row.original.id, date);
            if (row.original.checkin_time && date) {
              const diffInHours = Number(dayjs(date).diff(dayjs(`${dayjs(row.original.date).format("YYYY-MM-DD")}T${row.original.checkin_time}`), "minute")/60).toFixed(1);
              if (isNaN(diffInHours) || diffInHours > 24 || diffInHours < 0) {
                setValidationErrors((curr) => ({
                  ...curr,
                  hours: {
                    ...curr.hours,
                    [row.original.id.toString()]:
                      "Must be a number between 0 and 24",
                  },
                }));
              } else {
                if (validationErrors.hours?.[row.original.id.toString()]) delete validationErrors.hours?.[row.original.id.toString()];
                if (Object.keys(validationErrors?.hours || {}).length === 0) delete validationErrors.hours;
              }
              // handleUpdateCell("hours", row.original.id, diffInHours);
              handleUpdateCells({ hours: diffInHours, checkout_time: e.target.value }, row.original.id);
            }
          },
          // disabled: !row.original.checkin_time,
        }),
        // size: 120,
      },
      {
        accessorKey: "hours",
        header: "Hours",
        Cell: ({ cell }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {cell.getValue() || "0"}
          </Typography>
        ),
        muiEditTextFieldProps: ({ row }) => ({
          error: !!validationErrors.hours?.[row.original.id.toString()],
          label: "Hours",
          sx: {
            // display: new Date(row.original.date) > new Date() && "none",
            label: {
              display: "none",
            },
            mt: -3,
            width: '200px'
          },
          InputProps: {
            min: 0,
            max: 24,
            maxLength: 4,
            disabled:
              loading ||
              alreadySubmitted || showCheckInCheckOut,
            endAdornment: (
              <Tooltip
                title={
                  <Stack direction="column">
                    <Typography variant="captionNormal">
                      Hours must be decimal
                    </Typography>
                    <Typography variant="captionNormal">
                      e.g. Enter 1.5 for 1 hour and 30 minutes
                    </Typography>
                  </Stack>
                }
              >
                <InfoOutlined
                  sx={{
                    fontSize: 18,
                    color: validationErrors.hours?.[row.original.id.toString()]
                      ? "error.main"
                      : "",
                  }}
                />
              </Tooltip>
            ),
          },
          value: daysForm[row.original.key]?.hours || "",
          variant: "outlined",
          onChange: (e) => {
            const val = e.target.value;
            if (isNaN(val) || val > 24) {
              setValidationErrors((curr) => ({
                ...curr,
                hours: {
                  ...curr.hours,
                  [row.original.id.toString()]:
                    "Must be a number between 0 and 24",
                },
              }));
            } else {
              if (validationErrors.hours?.[row.original.id.toString()]) delete validationErrors.hours?.[row.original.id.toString()];
              if (Object.keys(validationErrors?.hours || {}).length === 0) delete validationErrors.hours;
            }
            handleUpdateCell("hours", row.original.id, val);
          },
        }),
        size: 80,
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ cell }) => (
          <Typography variant="body2Normal" sx={{ color: "#182743" }}>
            {cell.getValue()}
          </Typography>
        ),
        size: 250,
        muiEditTextFieldProps: ({ row }) => ({
          disabled: loading || alreadySubmitted,
          variant: "outlined",
          label: "Description",
          sx: {
            // display: new Date(row.original.date) > new Date() && "none",
            label: {
              display: "none",
            },
            mt: -3,
          },
          InputProps: {
            maxLength: 255,
          },
          value: daysForm[row.original.key]?.description || "",
          onChange: (e) => {
            const val = e.target.value;
            handleUpdateCell("description", row.original.id, val);
          },
        }),
      },
    ],
    [validationErrors, daysForm, alreadySubmitted, alreadySavedForLater, showCheckInCheckOut]
  );

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const param = useQuery();

  const jobName = useMemo(() => {
    return param.get("jobName");
  }, [param]);

  const getjobIdByName = useMemo(() => {
    return jobs?.find((item) => {
      return item.job.id === jobName;
    });
  }, [jobs, jobName]);

  useEffect(() => {
    if (param.get("jobName")) setJobId(getjobIdByName?.job.id);
  }, [jobName, getjobIdByName]);

  const copyPrevWeekData = async () => {
    const data = changeWeekHandle('prev', false)
    if (jobId) {
      const resp = await getJobsWithTimesheetsByJob(
        jobId,
        new Date(data.startDate),
        new Date(data.endDate)
      );
      if (resp?.data?.isCheckinCheckout && resp?.data?.status !== 'SUBMITTED') {
        setShowCheckInCheckOut(resp?.data?.isCheckinCheckout)
      }
      if (resp?.data?.result && resp?.data?.result.length === daysForm.length) {
        const updatedTimesheet = daysForm.map((item, index) => {
          if (item.key === index) {
            return {
              ...item,
              hours: resp?.data?.result[index]?.hours ? Number(resp?.data?.result[index]?.hours).toFixed(2) : "",
              description: resp?.data?.result[index]?.description || "",
              checkin_time: resp?.data?.result[index]?.checkin_time || "",
              checkout_time: resp?.data?.result[index]?.checkout_time || "",
            };
          }
          return item;
        });
        setDaysForm(updatedTimesheet);
        setTimeSheetDetails({ ...timesheetDetails, dates: updatedTimesheet });
      }
    }
  }

  const fetchCurrentTimesheethandler = async () => {
    setAlreadySubmitted(false);
    setCurrentTimeSheetStatus('')
    setGettingTimesheetDetails(true);
    setAlreadySavedForLater(false);
    if (jobId) {
      const resp = await getJobsWithTimesheetsByJob(
        jobId,
        new Date(startDate),
        new Date(endDate)
      );
      setShowCheckInCheckOut(false);
      if (resp?.data?.isCheckinCheckout && resp?.data?.status !== 'SUBMITTED') {
        setShowCheckInCheckOut(resp?.data?.isCheckinCheckout)
      }
      if (resp?.data?.result) {
        const updatedTimesheet = daysForm.map((item, index) => {
          if (item.key === index) {
            return {
              ...item,
              hours: resp?.data?.result[index]?.hours ? Number(resp?.data?.result[index]?.hours).toFixed(2) : "",
              id: resp?.data?.result[index]?.id || index,
              description: resp?.data?.result[index]?.description || "",
              checkin_time: resp?.data?.result[index]?.checkin_time || "",
              checkout_time: resp?.data?.result[index]?.checkout_time || "",
            };
          }
          return item;
        });
        setCurrentTimeSheetStatus(resp?.data?.timesheet?.status || "")
        setDaysForm(updatedTimesheet);
        setTimeSheetDetails({ ...timesheetDetails, dates: updatedTimesheet });
        setTimesheetId(resp?.data?.timesheet?.id)
        if (resp?.data?.result && resp?.data?.timesheet?.status !== "SAVED") {
          setAlreadySubmitted(true);
          setShowCheckInCheckOut(false)
        } else {
          setAlreadySavedForLater(true);
          setSaveItLaterDetails(resp?.data);
        }
      }
    }
    setGettingTimesheetDetails(false);
  };
  useEffect(() => {
    fetchCurrentTimesheethandler();
  }, [changedStartDate, jobId]);

  return (
    <Form onSubmit={handleSubmit}>
      <input type="hidden" name="start" value={daysForm?.[0]?.date} />
      <input
        type="hidden"
        name="end"
        value={daysForm?.[daysForm.length - 1]?.date}
      />
      <input type="hidden" name="jobId" value={jobId} />
      <Stack direction="column" gap={2}>
        <Stack direction="column" gap={3}>
          {create ? (
            <Stack direction="row" gap={2} alignItems="flex-end">
              <FormControl variant="standard" sx={{ width: "300px" }}>
                <InputLabel htmlFor="job" shrink>
                  Job
                </InputLabel>
                <Select
                  id="job"
                  disabled={!!jobName}
                  value={jobId}
                  required
                  onChange={(e) => setJobId(e.target.value)}
                >
                  {jobs.map(({ job }) => (
                    <MenuItem value={job.id} key={job.id}>
                      {job.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ButtonGroup
                variant="contained"
                aria-label="select week for timesheet"
                sx={{ borderRadius: "8px" }}
              >
                <Button
                  onClick={() => (
                    changeWeekHandle("prev"), setShowCheckInCheckOut(false)
                  )}
                  disabled={gettingTimesheetDetails}
                  sx={{ backgroundColor: "#0B85BA" }}
                >
                  <ChevronLeft />
                </Button>
                <Button
                  disabled
                  sx={{
                    typography: "body2Normal",
                    backgroundColor: "#F9FAFB !important",
                    color: "#6B7280 !important",
                  }}
                >
                  {timesheetDetails?.period || ""}
                </Button>
                <Button
                  onClick={() => (
                    changeWeekHandle("next"), setShowCheckInCheckOut(false)
                  )}
                  disabled={
                    gettingTimesheetDetails || isNextWeekAfterEndDate(endDate)
                  }
                  sx={{ backgroundColor: "#0B85BA" }}
                >
                  <ChevronRight />
                </Button>
              </ButtonGroup>
              {!alreadySubmitted && jobId && <Button onClick={copyPrevWeekData} variant="contained">Copy Prev Timesheet</Button>}
            </Stack>
          ) : (
            <>
              <Paper
                elevation={1}
                sx={{ p: 3, borderRadius: "8px", width: "100%" }}
              >
                <JobBadge
                  goBack={() => {
                    isPublic ? navigate(`/timesheet/${timesheetToken}`) : navigate(-1)
                  }}
                  timesheet={timesheet}
                  jobDetails={timesheet.job}
                />
              </Paper>
            </>
          )}
        </Stack>
        <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            marginBottom={"20px"}
            alignItems={"center"}
          >
            {!create && (
              <Typography variant="cardHeader">
                {new Date(timesheet.start).toLocaleString("default", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC"
              })} - {new Date(timesheet.end).toLocaleString("default", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC"
              })}
              </Typography>
            )}
            <Stack direction="column" gap={1}>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                gap={1}
                width={"100%"}
              >
                <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                gap={1}
              >
                  <Typography variant="subtitle1Bold" sx={{ color: "#182743" }}>
                    Total Hours:
                  </Typography>
                  <Typography
                    variant="subtitle1Bold"
                    sx={{ color: "#057A55", display: "inline" }}
                  >
                    {totalHours}
                  </Typography>
                </Stack>
                {currentTimeSheetStatus && (
                  <Chip color="success" label={currentTimeSheetStatus || ""} />
                )}
                {currentTimeSheetStatus === 'REJECTED' && (
                  <Box className='text-xs text-[#0B85BA] ml-2' onClick={() => navigate(`/dashboard/timesheets/${timesheetId}`)}>Edit</Box>
                )}
              </Stack>
              <div className="flex gap-2 items-center gap-4">
                {timesheet.status === timesheetStatus.submitted && (
                  <ShareTimesheet />
                )}
                {!create && !edit && !isPublic && (
                  <DownloadingIcon onClick={downloadTimesheetPdf} className="cursor-pointer !text-3xl" />
                )}
              </div>
            </Stack>
          </Stack>
          <CustomTable
            enableEditing={create || edit}
            editDisplayMode="table"
            initialState={{
              sorting: [{ id: "updatedAt", desc: true }],
              pagination: { pageSize: 50, pageIndex: 0 },
              columnVisibility: { checkout_time: false, checkin_time: false },
            }}
            state={{
              columnVisibility: {
                checkout_time: showCheckInCheckOut,
                checkin_time: showCheckInCheckOut,
              },
            }}
            enableHiding={true}
            enableSorting={false}
            loading={isLoading}
            data={daysForm}
            columns={columns}
            enableBottomToolbar={false}
            enableColumnResizing={false}
          />
        </Paper>
        <TimesheetApproveReject />
        {(edit || create) && !alreadySubmitted && (
          <FormControl>
            <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
              <Button
                type="submit"
                name="status"
                value="SAVED"
                variant="outlined"
                onClick={() => handleStatusChange("SAVED")}
                disabled={loading || !isValid}
              >
                Save for later
              </Button>
              <Button
                type="submit"
                name="status"
                value="SUBMITTED"
                variant="contained"
                onClick={() => handleStatusChange("SUBMITTED")}
                disabled={loading || !isValid}
              >
                Submit Timesheet
              </Button>
            </Stack>
          </FormControl>
        )}
        {!create && !isPublic &&  (
          <Paper
            elevation={1}
            sx={{ p: 3, borderRadius: "8px", width: "100%" }}
          >
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {loading ? (
                  <Skeleton variant="rounded" width="300px" />
                ) : (
                  <Stack direction="column" gap={2}>
                    <Typography variant="cardHeader">Comments</Typography>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography fontWeight={900}>Client:</Typography>
                      <Avatar
                        sx={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "8px",
                        }}
                        src={timesheet?.client?.profilePhoto}
                      />
                      <Typography>
                        {timesheet?.client?.firstName}{" "}
                        {timesheet?.client?.lastName}
                      </Typography>
                    </Stack>
                    {timesheet.clientComments &&
                      [
                        timesheetStatus.approved,
                        timesheetStatus.rejected,
                      ].includes(timesheet.status) && (
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body2Normal"
                            sx={{ color: "#6B7280" }}
                          >
                            {timesheet.clientComments}
                          </Typography>
                        </Stack>
                      )}
                  </Stack>
                )}
              </Stack>
            </Box>
          </Paper>
        )}
      </Stack>
    </Form>
  );
}
