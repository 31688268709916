/* eslint-disable max-len */
import { Grid } from '@mui/material';
import React from 'react';
import { BlogCard } from '../BlogCard';

function removeTags(str) {
  if ((str === null) || (str === '')) return false;

  return str.toString().replace(/(<([^>]+)>)/ig, '');
}

function HomePageBlogPosts() {
  const [posts, setPosts] = React.useState([]);

  const fetchPosts = async () => {
    const response = await fetch('https://www.blog.prolegion.com/wp-json/wp/v2/posts?orderby=date&order=desc&per_page=3&context=embed');
    const data = await response.json();
    setPosts(data);
  };

  React.useEffect(() => {
    fetchPosts();
  }, []);

  // eslint-disable-next-line no-return-assign, react/jsx-no-useless-fragment
  return posts.length ? <Grid container spacing={2}>{posts.map((post) => <BlogCard key={post.id} image={post.jetpack_featured_media_url} title={removeTags(post.title?.rendered)} content={removeTags(post.excerpt?.rendered)} buttonProps={{ label: 'Read More', onClick: () => window.location = post.link }} />)}</Grid> : <></>;
}

export default HomePageBlogPosts;
