import React from 'react';
import {
  Badge, CircularProgress, Drawer, IconButton, Stack, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ClearAllRounded, Close } from '@mui/icons-material';
import NotificationIcon from '../../Components/Icons/Notification.icon';
import { useCommonNotificationProvider } from '../../Providers/Common/Notification';
import { roles } from '../../Utils/constants';
import api from '../../Utils/api';
import { selectUserRole } from '../../Redux/Feature/user';
import { useNavigate } from "react-router-dom";
import { getPermissions, isValidUrl } from 'Utils/helper';

export default function Notifications({ isDrawerRequired = true }) {
  const [notificationVisible, setNotificationVisible] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const navigate = useNavigate();

  const {
    getNewNotifications, loading, notifications, markAsRead, setNotifications,
  } = useCommonNotificationProvider();
  const notificationCount = notifications?.length || 0;

  const permissions = getPermissions()
  const { role } = useSelector(selectUserRole);

  const accessToken = useSelector((state) => state.token.value);

  const fetchNotification = () => {
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
    if (permissions.includes(`admin-${role?.toLowerCase()}`)) {
      getNewNotifications();
    }
  }

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
    if (permissions.includes(`admin-${role?.toLowerCase()}`)) {
      getNewNotifications();
    }
    const interval = setInterval(() => getNewNotifications(), 100000);
    return () => {
      clearInterval(interval);
    };
  }, [refresh]);

  const markNotificationAsRead = (id) => {
    setNotifications(notifications.filter((notification) => !id.includes(notification.id)));
    markAsRead(id);
  };

  const action = (notification) => {
    markAsRead([notification?.id]);
    setRefresh(!refresh);
    setNotificationVisible(!notificationVisible);
    if (notification.action_link.split(' ')[0] === 'ViewTimesheet') {
      window.location.href = '/dashboard/timesheetscard';
      setNotificationVisible(!notificationVisible);
    } else if (
      notification.action_link.split(' ')[0] === 'ViewConnectionsClient'
    ) {
      window.location.href = '/dashboard/clients';
    } else if (
      notification.action_link.split(' ')[0] === 'ViewConnectionsVendor'
    ) {
      window.location.href = '/dashboard/vendors';
    } else if (
      notification.action_link.split(' ')[0] === 'ViewConnectionsConsultant'
    ) {
      window.location.href = '/dashboard/resources';
    } else if (notification.action_link.split(' ')[0] === 'ViewInvoice') {
      const [, invoiceId] = notification.action_link.split(" ");
      navigate(`/dashboard/invoices/${invoiceId}`);
    } else if (notification.action_link.split(' ')[0] === 'ViewJob') {
      if (role === roles.client) {
        window.location.href = '/dashboard/jobs';
      } else if (role === roles.vendor) {
        window.location.href = '/dashboard/jobs';
      } else if (role === roles.consultant) {
        window.location.href = '/dashboard/myjobs';
      }
    } else if (notification.action_link.split(' ')[0] === 'EditProfile') {
      window.location.href = '/dashboard/myaccount/edit';
    }
  };
  const Wrapper = React.useMemo(() => {
    return ({ children }) => (
      isDrawerRequired ? (
        <Drawer
          open={notificationVisible}
          anchor="right"
          onClose={() => setNotificationVisible(false)}
          sx={{
            '.MuiPaper-root': {
              width: '350px',
              p: 2,
              borderRadius: 0,
            },
          }}>
          {children}
        </Drawer>
      ) : <div>{children}</div>
    );
  }, [notificationVisible, isDrawerRequired]);

  return (
    <>
      {isDrawerRequired && <IconButton
        onClick={() => (setNotificationVisible(!notificationVisible), fetchNotification())}
      >
        <Badge badgeContent={notificationCount} sx={{ '.MuiBadge-badge': { backgroundColor: '#006A98', color: 'common.white' } }}>
          <NotificationIcon />
        </Badge>
      </IconButton>}

      <Wrapper>
        <Stack direction="column" height="100%">
          {isDrawerRequired && <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1Bold" color="#182743">Notifications</Typography>
            <IconButton
              aria-label="close notifications drawer"
              onClick={(e) => setNotificationVisible(!notificationVisible)}
            >
              <Close />
            </IconButton>
          </Stack>}

          {(isDrawerRequired && loading) || (!loading && !notifications.length)
            ? (
              <Stack sx={{
                height: '100%', flexGrow: 1, justifyContent: 'center', alignItems: 'center',
              }}
              >
                {loading ? <CircularProgress /> : <Typography variant="body2Normal">No new notifications</Typography>}
              </Stack>
            )
            : notifications.map((notification, index) => {
              const actionLink = notification?.action_link?.split(" ");
              const isValidLink = isValidUrl(actionLink?.[1]);
              return (
                <div key={`${notification?.id}-index`} className="p-4  bg-white rounded flex">
                  <div
                    key={notification?.id}
                    tabIndex="0"
                    aria-label="group icon"
                    role="img"
                    className="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex flex-shrink-0 items-center justify-center"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.33325 14.6667C1.33325 13.2522 1.89516 11.8956 2.89535 10.8954C3.89554 9.89523 5.2521 9.33333 6.66659 9.33333C8.08107 9.33333 9.43763 9.89523 10.4378 10.8954C11.438 11.8956 11.9999 13.2522 11.9999 14.6667H1.33325ZM6.66659 8.66666C4.45659 8.66666 2.66659 6.87666 2.66659 4.66666C2.66659 2.45666 4.45659 0.666664 6.66659 0.666664C8.87659 0.666664 10.6666 2.45666 10.6666 4.66666C10.6666 6.87666 8.87659 8.66666 6.66659 8.66666ZM11.5753 10.1553C12.595 10.4174 13.5061 10.9946 14.1788 11.8046C14.8515 12.6145 15.2515 13.6161 15.3219 14.6667H13.3333C13.3333 12.9267 12.6666 11.3427 11.5753 10.1553ZM10.2266 8.638C10.7852 8.13831 11.232 7.52622 11.5376 6.84183C11.8432 6.15743 12.0008 5.41619 11.9999 4.66666C12.0013 3.75564 11.7683 2.85958 11.3233 2.06466C12.0783 2.21639 12.7576 2.62491 13.2456 3.2208C13.7335 3.81668 14.0001 4.56315 13.9999 5.33333C14.0001 5.80831 13.8987 6.27784 13.7027 6.71045C13.5066 7.14306 13.2203 7.52876 12.863 7.84169C12.5056 8.15463 12.0856 8.38757 11.6309 8.52491C11.1762 8.66224 10.6974 8.7008 10.2266 8.638Z"
                        fill="#047857"
                      />
                    </svg>
                  </div>
                  <div className="pl-3 w-full">
                    <div className="flex items-center justify-between w-full">
                      <p
                        tabIndex="0"
                        className="flex flex-col focus:outline-none text-xs leading-none cursor-pointer"
                        onClick={(e) => {
                          action(notification);
                        }}
                      >
                        {notification?.notification_message}
                        {isValidLink && <a className='text-[#0B85BA] pt-1' href={actionLink[1]}>Download File</a>}
                      </p>
                      <div
                        tabIndex="0"
                        aria-label="close icon"
                        role="button"
                        className="focus:outline-none cursor-pointer pl-2 text-right"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={(e) => {
                            markNotificationAsRead([notification?.id]);
                          }}
                        >
                          <path
                            d="M10.5 3.5L3.5 10.5"
                            stroke="#4B5563"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.5 3.5L10.5 10.5"
                            stroke="#4B5563"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p
                      tabIndex="0"
                      className="focus:outline-none text-xs leading-3 pt-2 text-gray-500"
                    >
                      {moment(notification?.createdAt).fromNow()}
                    </p>
                  </div>
                </div>
              )
            })}
          {(!loading && notifications.length > 0) && (
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
              <IconButton
                aria-label="clear all notifications"
                onClick={(e) => {
                  markNotificationAsRead(
                    notifications.map((notification) => notification?.id),
                  );
                }}
              >
                <ClearAllRounded
                  fontSize="medium"
                  sx={{ color: '#4B5563' }}
                />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Wrapper>
    </>
  );
}
