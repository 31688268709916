import { Box, Button } from "@mui/material";
import MuiModal from "Components/Common/MuiModal";
import Invoice from "Components/InvoicePreview/Invoice";
import { headerKeys } from "Components/InvoicePreview/InvoicePreview";

const PreviewInvoice = ({
  isOpen,
  onClose,
  previewDetails,
  generateInvoice
}) => {
    const { invoiceDetails,
        clientDetails,
        vendorDetails,
        jobsOfInvoice } = previewDetails
  return (
    <MuiModal open={isOpen} onCancel={onClose} maxWith="sm">
      <Invoice
        jobsOfInvoice={jobsOfInvoice}
        vendorDetails={vendorDetails}
        clientDetails={clientDetails}
        invoiceDetails={invoiceDetails}
        headerKeys={headerKeys.fixed}
        type="fixed"
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button variant="outlined" onClick={onClose}>Edit</Button>
        <Button variant="contained" sx={{ marginLeft: '20px' }} onClick={generateInvoice}>Generate</Button>
      </Box>
    </MuiModal>
  );
};

export default PreviewInvoice;
