/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  Grid, Paper, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import api from 'Utils/api';
import { StatusPill } from 'Components/StatusPill';
import { CustomTable } from 'Components';
import moment from 'moment';

export default function DashboardJobsTable() {
  const [loading, setLoading] = React.useState(false);
  const [jobs, setJobs] = React.useState([]);

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const res = await api.post('/jobnproject/getjobsforconsultant', {
        user_id: localStorage.getItem('userId'),
      });

      if (res.status === 200 && res.data?.success) {
        setJobs(res.data.result);
      } else {
        throw new Error('Error occurred while fetching jobs');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchJobs();
  }, []);

  const columns = React.useMemo(() => [
    {
      accessorKey: 'job.name',
      header: 'Name',
      Cell: ({ cell, row }) => {
        return <Link to={`jobs/${row.original?.job.id}?type=dashboard`} style={{ textDecoration: 'none' }}>
          <Typography
            variant="body2Normal"
            color="#182743"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontSize: '12px',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {cell.getValue()}
          </Typography>
        </Link>
      },
    },
    {
      accessorKey: 'job.start_date',
      header: 'Start Date',
      Cell: ({ cell }) => (
        <Typography
          variant="body2Normal"
          color="#182743"
          sx={{ fontSize: '12px', }}
        >
          {moment(new Date(cell.getValue())).format('DD MMM, YYYY')}
        </Typography>
      ),
    },
    {
      accessorKey: 'job.status',
      header: 'Status',
      Cell: ({ cell }) => <StatusPill status={cell.getValue()} />,
    },
  ], []);

  React.useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div className='mt-4'>
      <Paper elevation={1} sx={{ p: 3, borderRadius: '8px' }}>
        {/* <Typography variant="subtitle1Bold" color="#182743" component="h3" sx={{ mt: 1, mb:1 }}>Jobs</Typography> */}
        <CustomTable
          initialState={{
            sorting: [{ id: 'job.name', desc: false }],
          }}
          state={{
            isLoading: loading,
          }}
          paginationDisplayMode="pages"
          columns={columns}
          data={jobs}
        />
      </Paper>
    </div>
  );
}
