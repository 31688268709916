import React, { useState, createContext, useContext } from 'react';
import Swal from 'sweetalert2';
import api from '../../Utils/api';
import { roles } from '../../Utils/constants';

const apiPath = {
  fetchInvoiceForUser: '/invoice/get-invoices-for-user',
  fetchInvoiceDetails: '/invoice/get-invoice-details',
  fetchInvoiceHoursForMonth: '/invoice/get-hours-for-month',
  fetchInvoiceHistory: '/invoice/get-invoices-for-job',
  sendEmailInvoice: '/invoice/send-link-via-email',
  getInvoicePdfLink: '/invoice/link',
  markInvoiceAsPaid: '/invoice/update-status',
};

const nullFn = () => null;
const InvoiceContext = createContext({
  loading: false,
  invoices: [],
  weeksOfMonth: [],
  invoiceLink: null,
  fetchInvoiceDetails: nullFn,
  fetchInvoiceForUser: nullFn,
  fetchInvoiceHoursForMonth: nullFn,
  sendEmailInvoice: nullFn,
  fetchInvoiceHistory: nullFn,
  getInvoicePdfLink: nullFn,
  markInvoiceAsPaid: nullFn,
});

export const useInvoiceProvider = () => useContext(InvoiceContext);
function InvoiceProvider({ children, userId }) {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [weeksOfMonth, setWeeksOfMonth] = useState([]);
  const [invoiceLink, setInvoiceLink] = useState();

  const fetchInvoices = async (outgoing) => {
    setInvoices([]);
    setLoading(true);
    try {
      const res = await api.get(`/invoice?type=${outgoing ? 'outgoing' : 'incoming'}`);
      if (res.data?.result) {
        setInvoices(res.data.result);
      } else {
        throw new Error('Error occurred while fetching Invoices');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoiceForUser = async (mode) => {
    setInvoices([]);
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchInvoiceForUser, {
        params: {
          userId,
          mode,
        },
      });
      if (res.data?.success) {
        setInvoices(res.data.result);
      } else {
        throw new Error('Error occurred while fetching Invoices');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchInvoiceHistory = async (mode, jobId) => {
    setInvoices([]);
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchInvoiceHistory, {
        params: {
          userId,
          jobId,
          mode,
        },
      });
      if (res.data?.success) {
        setInvoices(res.data.result);
      } else {
        throw new Error('Error occurred while fetching Invoices');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchInvoiceHoursForMonth = async ({ jobId, start }) => {
    try {
      const res = await api.post(apiPath.fetchInvoiceHoursForMonth, {
        userId,
        jobId,
        start,
      });
      if (res.data?.success) {
        setWeeksOfMonth(res.data.result);
      } else {
        throw new Error('Error occurred while fetching Invoice week');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const sendEmailInvoice = async (invoiceId, emails) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.sendEmailInvoice, {
        invoiceId,
        emails,
      });
      if (res.data?.success) {
        return true;
      }
      throw new Error('Error occurred while fetching Invoice week');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const getInvoicePdfLink = async (invoiceId) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getInvoicePdfLink, {
        params: {
          invoiceId,
        },
      });
      if (res.data?.success) {
        setInvoiceLink(res.data.result);
      } else {
        throw new Error('Error occurred while fetching Invoice week');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const markInvoiceAsPaid = async (invoiceId) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.markInvoiceAsPaid, {
        invoiceId,
        userId,
        status: 'PAID',
      });
      if (res.data?.success) {
        Swal.fire({
          icon: 'success',
          title: 'Invoice Payment',
          text: 'Invoice payment successful',
        });
      } else {
        throw new Error('Error occurred while making payment');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    loading,
    invoices,
    weeksOfMonth,
    invoiceLink,
    fetchInvoices,
    fetchInvoiceForUser,
    fetchInvoiceHistory,
    fetchInvoiceHoursForMonth,
    sendEmailInvoice,
    getInvoicePdfLink,
    markInvoiceAsPaid,
  };

  return (
    <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>
  );
}

export default InvoiceProvider;
