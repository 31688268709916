import { MuiAutocompleteOverride as MuiAutocomplete } from './MuiAutocomplete.override';
import { MuiButtonOverride as MuiButton } from './MuiButton.override';
import { MuiDividerOverride as MuiDivider } from './MuiDivider.override';
import { MuiFormControlLabelOverride as MuiFormControlLabel } from './MuiFormControlLabel.override';
import { MuiGridOverride as MuiGrid } from './MuiGrid.override';
import { MuiInputBaseOverride as MuiInputBase } from './MuiInputBase.override';
import { MuiInputLabelOverride as MuiInputLabel } from './MuiInputLabel.override';
import { MuiLinkOverride as MuiLink } from './MuiLink.override';
import { MuiListOverride as MuiList } from './MuiList.override';
import { MuiListItemOverride as MuiListItem } from './MuiListItem.override';
import { MuiListItemTextOverride as MuiListItemText } from './MuiListItemText.override';
import { MuiModalOverride as MuiModal } from './MuiModal.override';
import { MuiOutlinedInputOverride as MuiOutlinedInput } from './MuiOutlinedInput.override';
import { MuiPaperOverride as MuiPaper } from './MuiPaper.override';
import { MuiSelectOverride as MuiSelect } from './MuiSelect.override';
import { MuiTabsOverride as MuiTabs } from './MuiTabs.override';
import { MuiTableCellOverride as MuiTableCell } from './MuiTableCell.override';
import { MuiTextFieldOverride as MuiTextField } from './MuiTextField.override';

const componentOverrides = {
  MuiAutocomplete,
  MuiButton,
  MuiDivider,
  MuiFormControlLabel,
  MuiGrid,
  MuiInputBase,
  MuiInputLabel,
  MuiLink,
  MuiList,
  MuiListItem,
  MuiListItemText,
  MuiModal,
  MuiOutlinedInput,
  MuiPaper,
  MuiSelect,
  MuiTabs,
  MuiTableCell,
  MuiTextField,
};

export default componentOverrides;
