import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileName from '../common/ProfileName';
import AddressSection from '../common/AddressSection';
import AdditionalInformation from '../common/AdditionalInformation';
import CompanyDetails from '../common/CompanyDetails';
import { selectProfileDetails, selectResourceDetails } from '../../../Redux/Feature/profile/profile.selector';
import { setResourceUpdatedDetails, setUpdatedDetails } from '../../../Redux/Feature/profile/profile.slice';
import ContactExecutive from '../common/ContactExecutive';
import { OrgInfo, UserInfo } from '../api';
import ConsultantAdditionalInformation from './ConsultantAdditionalInformation';
import UploadProfile from '../common/UploadProfile';
import { useAlertProvider } from 'Providers/util/Alert';

function UserProfileForm({  }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const profile = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const {success} = useAlertProvider();
  
  const {
    first_name, last_name, company, username, country, state, city, linkdin, portfolio_link, industry_experience, role, public_profile_id, about_me, address,
    zipcode, availability, organisation_details: {
      name, company_size, founded_date, industry_type_id, website,
    },
  } = profile;

  useMemo(()=>{
    if(industry_experience === "0") {
      setIsError(true);
    } else if(industry_experience > "0") {
      setIsError(false);
    }
  },[industry_experience])

  const profileLink = useMemo(()=> {
    return `${window.location.protocol}//${window.location.hostname}/${username}`
  },[portfolio_link])

  const updateDetails = (value, name) => dispatch(isResourceEditing ? setResourceUpdatedDetails({ [name]: value }) : setUpdatedDetails({ [name]: value }));
  const updateOrgDetails = (value, name) => dispatch(isResourceEditing ? setResourceUpdatedDetails({ ...profile, organisation_details: { ...profile.organisation_details, [name]: value } }) : setUpdatedDetails({ ...profile, organisation_details: { ...profile.organisation_details, [name]: value } }));

  // const prepopulateUserData = async () => {
  //   setLoader(true);
  //   const details = await UserInfo();
  //   if (details && details.data?.result?.organisation) {
  //     const org = details.data?.result?.organisation ?? {};
  //     dispatch(
  //       setUpdatedDetails({
  //         ...details.data?.result,
  //         organisation_details: { ...org },
  //       })
  //       );
  //     }
  //     dispatch(setUpdatedDetails({ ...details.data?.result }));
  //   setLoader(false);
  // };

  // useEffect(() => {
  //   prepopulateUserData();
  // }, []);

  if (loader) return <CircularProgress />;

  return (
    <Box>
      <UploadProfile />
      {['VENDOR', 'CLIENT'].includes(profile.role) && (
        <CompanyDetails
          company={{
            company, company_size, founded_date, website, industry_type_id,
          }}
          setOrganization={updateOrgDetails}
          setCompany={updateDetails}
        />
      )}
      {!['VENDOR', 'CLIENT'].includes(profile.role) && <ProfileName names={{ first_name, last_name }} setNames={updateDetails} />}
      {!['VENDOR', 'CLIENT'].includes(profile.role) && <ConsultantAdditionalInformation details={{ availability }} setAvailability={updateDetails} />}
      <AddressSection
        address={{
          country, state, city, address, zipcode,
        }}
        setAddress={updateDetails}
      />
      <AdditionalInformation heading={profile.role === 'CONSULTANT' ? 'About Yourself' : 'About Company'} additional={{ linkdin, public_profile_id, industry_experience, role, isError, profileLink, success }} about_me={about_me} setAddtional={updateDetails} />
      {['VENDOR', 'CLIENT'].includes(profile.role) && <ContactExecutive />}
    </Box>
  );
}

export default UserProfileForm;
