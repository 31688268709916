import Typography from 'Components/Common/semantic_tags/Typography'
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiModal from 'Components/Common/MuiModal';
import CustomInput from 'Components/Common/semantic_tags/Input';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from 'Components/Common/semantic_tags/CustomSelect';
import CustomDateInput from 'Components/Common/semantic_tags/CustomDateInput';
import Button from 'Components/Common/semantic_tags/Button';
import MyEditor from 'Components/TextEditor';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { addExperience, deleteExperience, updateExperience, verifyExperience } from './api';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectProfileDetails, selectResourceDetails } from 'Redux/Feature/profile/profile.selector';
import { useDispatch } from 'react-redux';
import { addExperiences, deleteExperiences, updateExperiences } from 'Redux/Feature/profile/profile.slice';
import dayjs from 'dayjs';
import { useAlertProvider } from 'Providers/util/Alert';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DoneIcon from '@mui/icons-material/Done';
import VerifiedIcon from '@mui/icons-material/Verified';
import DeleteIcon from '@mui/icons-material/Delete';
import { truncateText } from 'Utils/helper';
import { CompanyDetailsSchema, ExperienceSchema } from './ExperienceSchema';
import { useJobProvider } from 'Providers/Job.provider';
import { Autocomplete, Chip, TextField } from '@mui/material';

const Experience = () => {
    const defaultVal = {
        title: '',
        employment_type: '',
        company_name: '',
        location: '',
        description: '',
        skills: [],
        start_date: '',
        end_date: '',
        currently_working: false
    }
    const { error, success } = useAlertProvider()
    const [addModal, setAddModal] = useState(false)
    const [verifiedModal, setVerifiedModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const dispatch = useDispatch()
    const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
    const profile = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
    const [isEdit, setIsEdit] = useState(false)
    const [editDetails, setEditDetails] = useState(defaultVal)

    const editHandler = (details) => {
        setIsEdit(true)
        setEditDetails({ ...details, start_date: dayjs(details?.start_date), end_date: dayjs(details?.end_date) })
        setAddModal(true)
    }

    const handleVerified = (item) => {
        setSelectedItem(item)
        setVerifiedModal(true)
    }

    const deleteHandler = (item) => {
        setSelectedItem(item)
        setDeleteModal(true)
    }

    const onDelete = async () => {
        if (selectedItem) {
            const resp = await deleteExperience(selectedItem.id, isResourceEditing, profile?.user_id)
            if (resp.success) {
                setDeleteModal(false)
                dispatch(deleteExperiences({ id: selectedItem.id }))
                success('Deleted Successfully')
            }
            else error(resp?.message || 'Something went wrong')
        } else {
            error('No Experience found')
        }
    }

    return <div>
        <>
            {
                profile?.experiences?.length === 0
                    ? <div className='flex min-[300px]:flex-col md:flex-row justify-center items-center min-h-[50vh]'>
                        <img src='https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/past+exp+1.svg' />
                        <div className='min-[300px]:w-full md:w-[35%] min-[300px]:pt-4'>
                            <Typography className='font-bold'>Work Experience</Typography>
                            <Typography className='text-xs'>Adding your work experience to Prolegion helps showcase your professional journey and skills. Whether you've held multiple roles in different organizations or have been committed to a single position for a long duration, detailing your work history provides valuable insights into your expertise and growth. This section allows you to highlight key responsibilities, achievements, and the impact you've had in your previous positions. It's an opportunity to present a comprehensive picture of your career, which can aid in better project alignments and career advancements within Prolegion. Ensure to include relevant details such as job titles, company names, employment durations, and descriptions of your roles and contributions.</Typography>
                            <Typography onClick={() => setAddModal(true)} className='text-xs text-[#3985B6] font-bold cursor-pointer mt-8'><AddCircleOutlineIcon /> Add Experience</Typography>
                        </div>
                    </div>
                    : <>
                        <div className='flex w-full justify-between items-center'>
                            <Typography className='font-bold text-xl'>Work Experience</Typography>
                            <Typography onClick={() => setAddModal(true)} className='text-xs text-[#3985B6] font-bold cursor-pointer'><AddCircleOutlineIcon /> Add New</Typography>
                        </div>
                        {profile?.experiences?.length > 0 && <div className='border-l-2 ml-4'>
                            {(profile?.experiences || []).map((item, index) => (
                                <Card
                                    handleVerified={handleVerified}
                                    key={index}
                                    deleteHandler={deleteHandler}
                                    item={item}
                                    setEditDetails={editHandler}
                                    edit={true}
                                />
                            ))}
                        </div>}
                    </>
            }
            {addModal && <AddExperienceModal isResourceEditing={isResourceEditing} defaultValues={editDetails} isEdit={!!isEdit} profile={profile} open={addModal} isClose={() => (setAddModal(false), setIsEdit(false), (setEditDetails(defaultVal)))} />}
            {verifiedModal && <AddCompanyDetailsModal isResourceEditing={isResourceEditing} profile={profile} open={verifiedModal} isClose={() => (setVerifiedModal(false), setSelectedItem(null))} item={selectedItem} />}
            {deleteModal && <DeleteModal onDelete={onDelete} open={deleteModal} isClose={() => (setDeleteModal(false), setSelectedItem(null))} />}
        </>
    </div>
}

export const Card = ({ item, edit = false, setEditDetails = () => {}, handleVerified = (data) => { }, deleteHandler = (data) => { }, isPublic = false }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const displayedText = (isExpanded || isPublic) ? item.description : truncateText(item.description, 35);

    return (
        <div className='flex items-start mt-4'>
            <div className={`h-[50px] w-[50px] rounded-full bg-[#3985B6] flex justify-center items-center ${isPublic ? '' : 'ml-[-25px]'}`}>
                <ApartmentIcon className='' sx={{ fontSize: '30px', color: 'white' }} />
            </div>
            <div className="bg-white p-4 pt-0 min-[300px]:pr-0 md:pr-4 rounded-lg mb-4 border-t-1 w-full">
                <div className="flex items-center justify-between mb-2 w-full">
                    <div className="">
                        <h2 className="font-semibold flex items-center min-[300px]:text-sm md:text-lg">
                            {item.title}
                            {item.IsVerified && 
                                <div className='flex gap-1 items-center'>
                                    <VerifiedIcon className='text-[#3985B6] ml-4' />
                                    <Typography className='text-[#3985B6] min-[300px]:text-xs md:text-sm'>Verified</Typography>
                                </div>
                            }
                            {!item.IsVerified && edit && <ModeEditOutlineIcon onClick={() => setEditDetails(item)} className='text-[#3985B6] cursor-pointer ml-2' sx={{ fontSize: '16px' }} />}
                            {!item.IsVerified && edit && <DeleteIcon onClick={() => deleteHandler(item)} className='text-red-500 cursor-pointer ml-2' sx={{ fontSize: '16px' }} />}
                        </h2>
                        <div className="flex items-center text-sm text-gray-500 text-xs">
                            {dayjs(item.start_date).format('DD MMM, YYYY')} — {item.end_date ? dayjs(item.end_date).format('DD MMM, YYYY') : 'Present'}
                            <ExperienceDuration startDate={item.start_date} endDate={item.end_date} />
                            {edit && <Typography className='ml-4 text-[#3985B6]'>{item?.approval === 'SENT' ? '( yet to be verified )' : ''}</Typography>}
                        </div>
                        <div className="text-gray-800 text-xs">{item.company_name} {item.location && <span>• {item.location}</span>}{item.employment_type && <span>• {item.employment_type}</span>}</div>
                    </div>
                    {(!item.IsVerified && item?.approval !== 'SENT' && edit) && <div className='flex justify-end'>
                        <Typography className='text-xs font-bold text-[#3985B6] cursor-pointer' onClick={() => handleVerified(item)}><VerifiedIcon /> Get Verified</Typography>
                    </div>}
                </div>
                {item?.skills?.length > 0 && <Typography className='text-xs'><strong>Skills:</strong>{item?.skills?.join(', ')}</Typography>}
                <div className="uniform-font !w-full" style={{ inlineSize: "100%", color: '#6B7280 !important', wordWrap: 'break-word', overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: displayedText }}></div>
                {!isPublic && item.description.split(' ').length > 35 && (
                    <button className="text-[#3985B6] mt-2 text-sm" onClick={toggleExpanded}>
                        {isExpanded ? 'Show Less' : 'Show More'}
                    </button>
                )}
            </div>
        </div>
    );
};

const AddCompanyDetailsModal = ({ open, isClose, item, profile, isResourceEditing }) => {
    const { error } = useAlertProvider()
    const [successModal, setSuccessModal] = useState(false)
    const dispatch = useDispatch()
    const { control, handleSubmit, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            resourceName: (profile?.first_name || '') + ' ' + (profile?.last_name || ''),
            resourcePosition: item.title,
            startDate: dayjs(item.start_date).format('MMM DD, YYYY'),
            "endDate": dayjs(item?.end_date || new Date()).format('MMM DD, YYYY'),
            companyName: item?.company_name,
            auditConsent: false,
            "workDescription": item.description,
            "verificationLink": encodeURI(window.location.origin + '/get-verify/' + item.id + `?name=${(profile?.first_name || '') + ' ' + (profile?.last_name || '')}&email=${profile?.email}`),
            "recipientEmail": "",
            "managerName": ""
        },
        resolver: yupResolver(CompanyDetailsSchema)
    });

    const onSubmit = async (data) => {
        delete data.auditConsent
        const resp = await verifyExperience(data, isResourceEditing, profile.user_id)
        if (resp.success) {
            setSuccessModal(true)
            updateExperience(item.id, { approval: 'SENT' })
            dispatch(updateExperiences({ id: item.id, approval: 'SENT' }))
        }
        else error(resp?.message || 'Something went wrong')
    }

    useEffect(() => { return () => reset() }, [])

    return <MuiModal open={open} onCancel={isClose} title={successModal ? '' : 'Company Details'}>
        {
            successModal
                ? <div className='flex flex-col justify-center items-center'>
                    <div className='h-[36px] w-[36px] rounded-md flex justify-center items-center bg-[#006A98] text-white'>
                        <DoneIcon />
                    </div>
                    <Typography className='text-xl text-center mt-2 font-bold'>The information provided has been sent for verification.</Typography>
                    <div className='flex justify-between w-[70%] items-center mt-14 border-t'>
                        <div className='mt-[-15px] flex flex-col justify-center items-center'>
                            <div className='h-[30px] w-[30px] flex rounded-full justify-center items-center bg-[#006A98] text-white'><DoneIcon /></div>
                            <Typography className='mt-2 text-xs'>Add Experience</Typography>
                        </div>
                        <div className='mt-[-15px] flex flex-col justify-center items-center'>
                            <div className='h-[30px] w-[30px] flex rounded-full justify-center  items-center bg-[#006A98] text-white'><DoneIcon /></div>
                            <Typography className='mt-2 text-xs'>Verify Experience</Typography>
                        </div>
                        <div className='mt-[-15px] flex flex-col justify-center items-center'>
                            <div className='h-[30px] w-[30px] flex rounded-full justify-center items-center bg-[white] border'>
                                <div className='h-[8px] w-[8px] flex rounded-full justify-center items-center bg-[#006A98] text-white'></div>
                            </div>
                            <Typography className='mt-2 text-xs'>Experience Verified </Typography>
                        </div>
                    </div>
                </div>
                : <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <Controller
                            name="companyName"
                            control={control}
                            render={({ field }) => <CustomInput {...field} label="Company Name*" error={errors.companyName?.message} />}
                        />
                    </div>
                    <div className="mb-4">
                        <Controller
                            name="recipientEmail"
                            control={control}
                            render={({ field }) => <CustomInput {...field} label="Recipient Email*" error={errors.recipientEmail?.message} />}
                        />
                    </div>
                    <div className="mb-4">
                        <Controller
                            name="managerName"
                            control={control}
                            render={({ field }) => <CustomInput {...field} label="Manager Name*" error={errors.managerName?.message} />}
                        />
                    </div>
                    <div className="mb-4">
                        <Controller
                            name="auditConsent"
                            control={control}
                            render={({ field }) => (
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="auditConsent"
                                        name="auditConsent"
                                        {...field}
                                        className="mr-2"
                                    />
                                    <label htmlFor="auditConsent" className="text-gray-600 text-xs">
                                        The information provided above, can be audited by Prolegion based on requirement.*
                                    </label>
                                </div>
                            )}
                        />
                        {errors.auditConsent?.message && <Typography className='text-red-500 text-xs'>{errors.auditConsent?.message}</Typography>}
                    </div>
                    <Button type="submit" parentClassName='w-full mt-8' className='w-full' title={'Send'} />
                </form>
        }
    </MuiModal>
}


const ExperienceDuration = ({ startDate, endDate }) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : dayjs();

    const diffYears = end.diff(start, 'year');
    const diffMonths = end.diff(start.add(diffYears, 'year'), 'month');

    return (
        <span className="text-[#3985B6] ml-2">
            {diffYears ? `${diffYears} year${diffYears > 1 ? 's' : ''} ` : ''}
            {diffMonths ? `${diffMonths} month${diffMonths > 1 ? 's' : ''}` : ''}
        </span>
    );
};

const DeleteModal = ({ open, isClose, onDelete }) => {
    return <MuiModal open={open} onCancel={isClose}>
        <div className='flex flex-col'>
            <Typography className='text-xl font-bold'>Are you sure you want to delete the Experience</Typography>
            <div className='flex justify-end mt-8'>
                <Button title='Cancel' onClick={isClose} />
                <Button title='Delete' className='hover:!bg-red-500 !bg-red-500 ml-4' onClick={onDelete} />
            </div>
        </div>
    </MuiModal>
}

const AddExperienceModal = ({ open, isClose, defaultValues, isEdit, profile, isResourceEditing }) => {
    const dispatch = useDispatch();
    const { success, error } = useAlertProvider();
    const { getAllSkills, skills } = useJobProvider()
    const { control, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(ExperienceSchema)
    });

    const description = watch('description');
    const start_date = watch('start_date');
    const skillsVal = watch('skills');
    const currently_working = watch('currently_working');
    const onSubmit = async (data) => {
        const { start_date, end_date, ...details } = data;
        details['start_date'] = dayjs(start_date).format('YYYY-MM-DD');

        if (!data.currently_working) {
            details['end_date'] = dayjs(end_date).format('YYYY-MM-DD');
        }
        details['skills'] = details['skills'].map(i => i?.name || i)
        let resp = { success: false };
        if (isEdit) {
            resp = await updateExperience(details.id, details, isResourceEditing,profile?.user_id );
        } else {
            resp = await addExperience(details, isResourceEditing,profile?.user_id);
        }
        if (resp.success) {
            success(isEdit ? 'Experience updated successfully' : 'Experience added successfully');
            isClose();
            isEdit ? dispatch(updateExperiences(details)) : dispatch(addExperiences(resp.data));
        } else {
            error(resp?.message || 'Something went wrong');
        }
    };

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
        getAllSkills()
    }, [])

    return (
        <MuiModal open={open} onCancel={isClose} title='Add Experience'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                            <CustomInput {...field} label="Title*" error={errors.title?.message} />
                        )}
                    />
                </div>
                <div className="mb-4">
                    <Controller
                        name="employment_type"
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                {...field}
                                error={errors.employment_type?.message}
                                label="Employment Type"
                                options={['Part-time', 'Full-Time', 'Self Employed', 'Freelance', 'Trainee']}
                            />
                        )}
                    />
                </div>
                <div className="mb-4">
                    <Controller
                        name="company_name"
                        control={control}
                        render={({ field }) => (
                            <CustomInput {...field} label="Company Name*" error={errors.company_name?.message} />
                        )}
                    />
                </div>
                <div className="mb-4">
                    <Controller
                        name="location"
                        control={control}
                        render={({ field }) => (
                            <CustomInput {...field} label="Location*" error={errors.location?.message} />
                        )}
                    />
                </div>
                <div className='mb-4'>
                    <Controller
                        name='skills'
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                multiple
                                clearIcon={false}
                                className="w-full"
                                value={skillsVal || []}
                                getOptionLabel={(option) => option.name || option}
                                onChange={(event, value) => {
                                    field.onChange(value);
                                    setValue('skills', value);
                                }}
                                options={skills}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option.name || option}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Skills"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                </div>
                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={currently_working}
                            onChange={(e) => setValue('currently_working', e.target.checked)}
                            className="form-checkbox"
                        />
                        <span className="ml-2">I am currently working in this role</span>
                    </label>
                </div>
                <div className="flex space-x-4 mb-4">
                    <div className="flex-1">
                        <Controller
                            name="start_date"
                            control={control}
                            render={({ field }) => (
                                <CustomDateInput {...field} label="Start Date*" error={errors.start_date?.message} />
                            )}
                        />
                    </div>
                    <div className="flex-1">
                        <Controller
                            name="end_date"
                            control={control}
                            render={({ field }) => (
                                <CustomDateInput
                                    {...field}
                                    disabled={!!currently_working}
                                    minDate={dayjs(start_date)}
                                    label="End Date"
                                    error={errors.end_date?.message}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <MyEditor
                        height={200}
                        html={description}
                        updateRaw={(data) => setValue('description', data)}
                    />
                </div>
                <Button type="submit" parentClassName='w-full mt-12' className='w-full' title={isEdit ? 'Update' : 'Save'} />
            </form>
        </MuiModal>
    );
};

export default Experience