import {
  Box,
  Stack,
  TextField,
  FormControl,
  Autocomplete,
  CircularProgress,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import CustomInput from "Components/Common/semantic_tags/Input";
import { selectUserDetails } from "Redux/Feature/user";
import { useState } from "react";
import { useSelector } from "react-redux";

const FilterSection = ({ jobs, setSearch, setSelectedJobId, resources, setSelectedResource, isDataFromDifferentComponent, searchQuery, setSearchQuery, loading }) => {
  const [open, setOpen] = useState(false);
  const profile = useSelector(selectUserDetails);

  return (
    <Box sx={{ marginTop: "10px" }}>
      <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={'flex-end'}>
        {/* <Stack flexDirection={"row"} gap={"10px"}> */}
          <CustomInput
            parentClassName="!w-[40%]"
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            placeholder="Search task here..."
          />
        {/* </Stack> */}
        {isDataFromDifferentComponent && <Stack flexDirection={'row'} gap={'10px'}>
          {profile && profile?.role !== 'CONSULTANT' && <FormControl width={'100%'} >
            <Autocomplete
              sx={{
                background: "white",
                "&:selected": { background: "white" },
                width: '250px',
              }}
              fullWidth
              clearIcon={false}
              open={open}
              onOpen={()=> setOpen(true)}
              onClose={()=> (setOpen(false), setSearchQuery(''))}
              onChange={(e, value) => setSelectedResource(value?.value)}
              options={resources}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Resource"
                  onChange={(event)=> setSearchQuery(event.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      marginTop: "25px",
                      padding: "10px 12px"
                    },
                    endAdornment: <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                    </>,
                  }}
                />
              )}
            />
          </FormControl>}
          <FormControl width={'100%'} >
            <Autocomplete
              sx={{
                width: '250px',
              }}
              fullWidth
              clearIcon={false}
              // onClose={()=> setSearchQuery('')}
              onChange={(e, value) => setSelectedJobId(value?.value)}
              options={jobs}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Job"
                  onChange={(event)=> setSearchQuery(event.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      marginTop: "25px",
                      padding: "10px 12px"
                    },
                    endAdornment: <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                    </>,
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>}
      </Stack>
    </Box>
  );
};

export default FilterSection;
