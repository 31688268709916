export default () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="100%" height="100%" viewBox="0 0 3094 936" enable-background="new 0 0 3094 936" xmlSpace="preserve">
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M1.000000,806.000000 
	C1.000000,537.703857 1.000000,269.407776 1.000000,1.000000 
	C740.360168,1.000000 1479.720337,1.000000 2219.371582,1.357318 
	C2218.199951,1.921754 2216.685547,1.952538 2215.281982,2.362292 
	C2191.963135,9.170366 2179.668945,31.305952 2184.203125,54.165539 
	C2189.129639,79.003036 2215.723145,93.942001 2240.239258,86.349342 
	C2256.905518,81.187759 2270.521484,63.172401 2270.244385,45.782001 
	C2269.982178,29.320154 2263.585693,16.869822 2250.263672,8.025807 
	C2245.421631,4.811322 2239.454102,3.292489 2234.000000,0.999997 
	C2520.931152,1.000000 2807.862305,1.000000 3095.000000,1.000000 
	C3095.000000,296.026825 3095.000000,591.053711 3094.653564,886.390015 
	C3091.786133,875.248535 3083.711914,869.099915 3073.525635,865.010986 
	C3062.987061,860.780640 3052.338379,856.810242 3041.907227,852.331848 
	C3036.193604,849.878723 3033.770752,844.788391 3034.059082,838.717407 
	C3034.352051,832.543640 3036.657471,827.181946 3042.493408,824.231628 
	C3054.127197,818.349915 3065.320068,820.025024 3076.116455,826.630005 
	C3077.783447,827.649841 3079.402100,829.272156 3081.169189,829.465515 
	C3083.164795,829.683838 3086.135742,829.277893 3087.210938,827.951111 
	C3089.690674,824.890930 3091.280029,821.109314 3093.119629,817.818848 
	C3081.301270,807.147949 3067.760254,803.140259 3052.779297,804.082764 
	C3043.332031,804.677246 3034.573975,807.583618 3027.318848,814.149353 
	C3018.790283,821.867493 3015.966064,831.402100 3015.760498,842.805969 
	C3015.511963,856.591858 3026.383301,868.620544 3036.671143,872.036133 
	C3045.460449,874.954346 3054.221924,877.962585 3062.948730,881.062744 
	C3072.242676,884.364319 3077.265137,890.664185 3076.950439,899.008362 
	C3076.616943,907.843628 3071.876465,916.009888 3062.253662,918.815430 
	C3049.085938,922.654541 3037.162842,919.425049 3026.657227,911.012085 
	C3023.693848,908.638855 3020.132324,906.379578 3017.408447,908.590698 
	C3014.169434,911.220032 3012.376953,915.661682 3010.109375,919.409668 
	C3009.885742,919.779297 3010.529297,921.051697 3011.092773,921.459412 
	C3015.315186,924.513489 3019.319336,928.095215 3023.971436,930.273499 
	C3030.034912,933.112488 3036.632324,934.810791 3043.000000,937.000000 
	C2887.306396,937.000000 2731.613037,937.000000 2575.640381,936.633423 
	C2594.222412,934.277527 2609.555420,925.890442 2619.895508,909.932129 
	C2631.246826,892.412720 2633.501465,872.966736 2628.919678,852.849243 
	C2624.592773,833.851379 2614.325684,819.174622 2596.221680,810.615356 
	C2581.406738,803.611328 2566.150146,802.362549 2550.246338,805.946045 
	C2530.661133,810.359131 2516.475586,821.650330 2508.269531,839.725342 
	C2502.356201,852.750549 2500.929932,866.603699 2502.852051,881.043701 
	C2506.467285,908.207214 2520.841309,925.994629 2546.858887,934.367554 
	C2550.479980,935.532959 2554.281738,936.136414 2558.000000,937.000000 
	C2503.306396,937.000000 2448.613037,937.000000 2393.635742,936.636536 
	C2401.951660,935.578552 2409.386963,932.222229 2415.909668,926.617859 
	C2427.238037,916.884033 2430.120361,903.975281 2428.810547,890.033813 
	C2428.121094,882.696289 2425.126709,875.580933 2418.130859,871.692688 
	C2411.083984,867.776062 2403.752686,864.331177 2396.379639,861.053894 
	C2390.598877,858.484375 2384.180908,857.149414 2378.766602,854.019348 
	C2374.606201,851.614197 2369.773682,847.859497 2368.390869,843.665955 
	C2365.169922,833.897583 2370.857178,824.825684 2381.049805,822.038025 
	C2391.477539,819.186096 2401.500000,820.686340 2410.591797,827.015808 
	C2412.400879,828.275208 2414.522949,829.733459 2416.581299,829.868164 
	C2423.526855,830.322449 2423.111328,823.506348 2425.322510,819.635803 
	C2425.935547,818.563110 2423.419922,814.624084 2421.461182,813.336060 
	C2408.755615,804.980408 2394.802246,802.161743 2379.772461,805.003662 
	C2356.794922,809.348267 2344.111572,830.729858 2350.919922,852.314209 
	C2354.736816,864.415344 2364.024658,869.970032 2374.940186,873.942810 
	C2383.183350,876.942932 2391.624023,879.494995 2399.627930,883.035034 
	C2412.279785,888.630676 2413.243652,899.884949 2407.065674,910.262268 
	C2400.646973,921.044922 2387.685059,922.005737 2377.018555,919.181152 
	C2370.320068,917.407349 2364.288574,912.964905 2358.099609,909.468689 
	C2353.997070,907.151062 2350.898438,907.470947 2348.395020,911.231689 
	C2347.134521,913.125732 2345.714355,915.062805 2345.106934,917.191711 
	C2344.677979,918.694702 2344.887695,921.455261 2345.853760,922.104370 
	C2351.639404,925.992371 2357.544189,929.800903 2363.779053,932.888916 
	C2367.862793,934.911682 2372.571289,935.673462 2377.000000,937.000000 
	C2348.973145,937.000000 2320.946289,937.000000 2292.630371,936.639893 
	C2299.973389,935.451965 2306.630859,932.406433 2312.961670,928.081421 
	C2325.195801,919.723328 2331.182129,897.781738 2326.126953,884.300354 
	C2322.077393,873.501343 2313.586914,867.918213 2303.511475,864.015442 
	C2294.414551,860.491699 2285.082275,857.500854 2276.223633,853.463806 
	C2270.381348,850.801392 2265.601562,845.868042 2266.173828,838.917480 
	C2267.074463,827.976318 2275.114258,821.493652 2285.392578,820.938843 
	C2294.958740,820.422485 2303.500732,822.457153 2311.165039,828.500122 
	C2315.161377,831.650879 2318.473633,830.518921 2320.989502,825.915466 
	C2322.459961,823.225098 2323.737793,820.429443 2325.038574,817.809448 
	C2315.099121,806.251648 2290.327881,800.467651 2272.266846,806.731628 
	C2256.893555,812.063416 2248.655518,825.677612 2248.042725,838.125854 
	C2247.026611,858.768982 2257.280518,867.663940 2271.502441,872.978149 
	C2280.325684,876.275146 2289.415527,878.931458 2298.037354,882.676819 
	C2307.346680,886.720764 2310.806396,893.984436 2308.931885,903.000793 
	C2307.041016,912.096069 2298.029541,919.254822 2289.143066,920.127747 
	C2276.569824,921.363037 2266.269531,916.931946 2256.805420,909.331848 
	C2254.208252,907.246155 2251.219238,906.885376 2248.913330,909.681091 
	C2246.375244,912.758301 2244.241211,916.168579 2241.060791,920.663818 
	C2248.095947,924.839539 2254.281250,929.053284 2260.944580,932.281128 
	C2265.626465,934.549133 2270.958252,935.474854 2276.000000,937.000000 
	C2170.306396,937.000000 2064.613037,937.000000 1958.640381,936.633423 
	C1983.440063,933.216248 2000.662842,920.036377 2009.292603,896.211975 
	C2013.227661,885.348511 2014.384155,874.158630 2013.164062,862.473572 
	C2011.597168,847.466980 2006.820679,833.952209 1996.432739,823.106628 
	C1979.503174,805.430969 1957.797363,800.629089 1934.872925,805.417236 
	C1904.595947,811.741150 1888.460571,832.598572 1885.174438,862.417542 
	C1883.420654,878.332825 1885.901978,894.002075 1894.549683,907.952332 
	C1900.893677,918.186279 1909.295288,926.524719 1920.743286,930.924438 
	C1927.304810,933.446045 1934.235474,935.006714 1941.000000,937.000000 
	C1702.306519,937.000000 1463.612915,937.000000 1224.644531,936.630981 
	C1230.379395,934.843750 1236.642334,934.081665 1242.349609,931.879333 
	C1257.180176,926.156128 1267.589844,915.619080 1274.091797,901.046021 
	C1280.408813,886.887512 1281.276978,872.232666 1279.016846,857.164307 
	C1275.821533,835.860168 1264.741211,819.873596 1245.513916,810.416809 
	C1233.140869,804.331238 1219.739746,802.900208 1205.754272,804.834595 
	C1184.698975,807.746765 1169.265503,818.344177 1159.181274,836.661987 
	C1150.441284,852.538025 1149.997314,869.966370 1153.232056,887.102478 
	C1157.236084,908.314880 1169.131958,924.162781 1189.979492,931.967773 
	C1195.798706,934.146362 1201.983154,935.349365 1208.000000,937.000000 
	C1030.306519,937.000000 852.612976,937.000000 674.644714,936.630859 
	C697.262268,934.102234 714.032410,922.773254 723.883972,902.051208 
	C731.023438,887.033630 731.889709,870.860779 728.848267,854.789673 
	C725.136597,835.177246 714.843201,819.754944 696.400269,810.871216 
	C683.566772,804.689575 670.087097,802.875366 655.785828,804.858643 
	C634.586060,807.798584 618.760132,818.351746 609.134705,837.145264 
	C600.172302,854.644409 599.614563,873.397766 604.693115,892.238586 
	C609.984802,911.870117 622.342468,925.396057 641.319214,932.592346 
	C646.667542,934.620544 652.428345,935.560791 658.000000,937.000000 
	C439.119049,937.000000 220.238113,937.000000 1.000000,937.000000 
	C1.000000,898.973877 1.000000,860.946655 1.468657,822.459717 
	C15.191000,822.000000 28.444685,822.000000 42.184128,822.000000 
	C42.184128,860.336060 42.184128,898.035645 42.184128,935.608887 
	C48.740665,935.608887 54.794357,935.608887 61.150360,935.608887 
	C61.150360,897.638733 61.150360,860.058655 61.150360,821.962402 
	C75.178688,821.962402 88.899406,821.962402 102.669724,821.962402 
	C102.669724,816.387512 102.669724,811.336121 102.669724,806.000000 
	C68.560165,806.000000 34.780083,806.000000 1.000000,806.000000 
M1988.500000,733.000000 
	C1997.625977,731.980652 2006.864380,731.548828 2015.856323,729.829041 
	C2040.483032,725.119019 2064.070557,717.372864 2085.889893,704.640198 
	C2103.723877,694.233398 2119.283203,681.184021 2132.201172,665.165894 
	C2146.519043,647.411316 2156.624023,627.230713 2162.686523,605.339172 
	C2169.491455,580.766174 2171.396973,555.363098 2172.940918,530.004211 
	C2173.625244,518.759583 2173.948486,507.476044 2173.956299,496.209869 
	C2174.029541,388.725861 2174.000000,281.241760 2174.000000,173.757675 
	C2174.000000,171.977356 2174.000000,170.197021 2174.000000,168.320648 
	C2155.749512,168.320648 2138.030762,168.320648 2119.917480,168.320648 
	C2119.917480,191.179214 2119.917480,213.720749 2119.917480,235.776962 
	C2111.022217,227.095215 2102.156738,218.297531 2093.132324,209.665909 
	C2076.141846,193.415009 2056.634277,181.041794 2034.959351,171.959778 
	C2016.108398,164.061035 1996.369873,159.651031 1976.143555,158.226471 
	C1963.630859,157.345169 1950.881592,157.895035 1938.388428,159.218079 
	C1926.288574,160.499451 1914.216431,162.960892 1902.422485,166.022446 
	C1878.482666,172.236908 1856.874146,183.588715 1836.705688,197.788971 
	C1821.769165,208.305496 1808.370605,220.570129 1796.959106,234.751419 
	C1785.280151,249.265213 1775.507935,265.021667 1767.818848,282.160034 
	C1758.154663,303.700897 1751.824829,326.156006 1750.313843,349.507141 
	C1749.058594,368.905121 1749.165283,388.485626 1752.879883,407.882080 
	C1757.821533,433.686127 1767.122925,457.625275 1780.955078,479.911377 
	C1793.447021,500.038116 1809.441162,517.024841 1828.089600,531.362976 
	C1842.991943,542.821045 1859.520264,551.741882 1876.914429,559.012024 
	C1900.080811,568.694885 1924.243530,573.899170 1949.255371,574.887329 
	C1958.493774,575.252319 1967.821167,574.748169 1977.038208,573.890625 
	C1987.282959,572.937561 1997.599243,571.802734 2007.617065,569.555359 
	C2029.865601,564.564453 2050.831787,555.915344 2070.134277,543.830566 
	C2085.305908,534.331970 2099.502930,523.405273 2111.090576,509.478363 
	C2113.726562,506.310150 2116.747314,503.461975 2119.938721,500.100464 
	C2120.076660,526.260376 2121.051270,551.807495 2115.889404,576.940796 
	C2113.575195,588.208191 2111.118408,599.697876 2106.686768,610.228210 
	C2096.391602,634.690247 2077.056396,651.131531 2054.432129,663.734009 
	C2036.764038,673.575806 2017.738647,679.662537 1997.515015,682.122192 
	C1980.635254,684.175110 1963.890259,685.579834 1946.795288,684.235840 
	C1930.390381,682.946045 1914.340820,680.667358 1898.537231,676.080627 
	C1878.079346,670.143188 1860.008179,660.033813 1844.330200,645.744080 
	C1832.724854,635.166321 1822.544556,623.331055 1814.857788,609.509094 
	C1813.425171,606.932983 1811.456665,605.735840 1808.135498,605.884460 
	C1800.152710,606.241577 1792.142944,606.000000 1784.144165,606.000000 
	C1774.535889,606.000000 1764.927490,606.000000 1753.618896,606.000000 
	C1760.802856,619.021301 1767.066650,631.156860 1774.051636,642.861938 
	C1786.136963,663.113953 1801.327515,680.726013 1820.825439,694.240967 
	C1838.645508,706.592896 1858.186646,715.483887 1878.858643,721.928345 
	C1904.059570,729.784729 1929.973877,733.243958 1956.259766,733.902527 
	C1966.677490,734.163391 1977.122437,733.333313 1988.500000,733.000000 
M600.075073,288.745728 
	C597.862305,295.198853 595.023315,301.513977 593.564697,308.133301 
	C590.341370,322.761169 587.072449,337.451294 585.251343,352.291443 
	C583.957947,362.831604 584.351868,373.707611 585.161316,384.344788 
	C586.203186,398.035248 587.230957,411.913696 590.346924,425.223724 
	C599.069397,462.482513 617.889648,494.553314 643.947083,522.339233 
	C662.308655,541.918823 683.592773,557.493652 708.420410,568.235291 
	C730.553528,577.811157 753.626404,583.647949 777.524109,584.712708 
	C795.319824,585.505615 813.272278,584.201904 831.082642,582.868164 
	C851.089355,581.370056 870.050171,575.150513 888.208191,566.875549 
	C918.453064,553.092285 942.888977,531.818909 963.335938,505.974548 
	C975.730652,490.307861 985.525391,472.974487 993.247437,454.461670 
	C1003.390381,430.144928 1008.187073,404.685486 1008.769714,378.568634 
	C1009.076782,364.803986 1007.355591,350.950256 1005.927124,337.201508 
	C1004.027832,318.920776 998.655701,301.461426 991.219788,284.729156 
	C977.969666,254.913666 959.111084,229.236771 934.399841,207.719330 
	C917.686462,193.166016 899.342285,181.552505 878.973816,172.986786 
	C857.993713,164.163818 835.982849,159.346451 813.412048,158.218430 
	C794.962708,157.296402 776.454346,157.571915 758.020813,160.815735 
	C739.085938,164.147781 720.966248,169.616745 703.666931,177.804642 
	C690.633911,183.973236 678.696777,191.897842 667.303101,200.812683 
	C653.846924,211.341293 641.953918,223.391815 631.289917,236.568008 
	C618.621582,252.220566 608.129089,269.338531 600.075073,288.745728 
M1657.505737,544.000854 
	C1658.458740,543.373108 1659.489868,542.837463 1660.352905,542.103760 
	C1673.045898,531.312866 1685.386108,520.076782 1694.952637,506.367676 
	C1704.727295,492.360504 1713.706665,477.798340 1723.171143,463.268799 
	C1707.087769,454.789886 1692.255249,446.970337 1677.153442,439.008881 
	C1676.102051,440.586365 1675.247437,441.961395 1674.302734,443.271545 
	C1663.988281,457.575836 1654.456055,472.539520 1643.136719,485.999969 
	C1628.519897,503.381561 1609.222534,514.707153 1588.309814,523.327332 
	C1571.575439,530.225220 1554.161865,534.530396 1536.205444,535.799683 
	C1525.516846,536.555237 1514.678955,535.529419 1503.926025,534.965393 
	C1487.722412,534.115601 1472.504272,529.207153 1457.964966,522.382935 
	C1440.889771,514.368530 1426.446045,502.754486 1413.440308,489.079315 
	C1398.275391,473.133789 1386.837769,454.944794 1380.164551,434.124023 
	C1376.108032,421.467285 1373.678223,408.248596 1371.151733,395.159943 
	C1369.961304,388.992889 1370.262939,382.537750 1369.865356,375.821014 
	C1492.053833,375.821014 1613.314819,375.821014 1735.000000,375.821014 
	C1735.000000,374.025330 1735.119995,372.548126 1734.981323,371.095612 
	C1733.686279,357.532745 1732.818359,343.907562 1730.915894,330.429016 
	C1727.631470,307.161316 1720.233643,285.137878 1708.854248,264.532776 
	C1697.172363,243.379822 1682.385742,224.790176 1664.309204,208.596588 
	C1647.387817,193.437790 1628.581055,181.662231 1607.717041,172.923813 
	C1586.553467,164.059891 1564.315674,159.645477 1541.612183,158.215088 
	C1528.253662,157.373459 1514.671997,157.824249 1501.352661,159.229645 
	C1487.454346,160.696121 1473.429932,162.888458 1460.024658,166.731262 
	C1426.376709,176.377075 1398.017700,194.795364 1374.496338,220.878464 
	C1357.803955,239.388657 1344.006104,259.605621 1333.805786,282.394348 
	C1324.036011,304.221191 1318.164429,327.033020 1316.176636,350.730225 
	C1315.174316,362.680115 1315.471069,374.813263 1316.150879,386.808594 
	C1316.798462,398.235657 1317.781738,409.794708 1320.230713,420.941284 
	C1328.641968,459.224915 1346.748657,492.756256 1372.623657,522.007324 
	C1391.512817,543.360962 1414.139648,559.706909 1440.755981,570.372498 
	C1461.874390,578.835022 1483.894287,583.546814 1506.450317,584.771362 
	C1522.151367,585.623718 1537.995239,584.779053 1553.724243,583.838440 
	C1563.470825,583.255493 1573.275391,581.726074 1582.786499,579.486267 
	C1609.938232,573.092407 1634.793823,561.554443 1657.505737,544.000854 
M204.985352,317.513885 
	C215.911850,316.347015 226.839218,315.188049 237.764679,314.011597 
	C259.424683,311.679199 279.728119,305.191711 298.843384,294.680023 
	C314.837402,285.884705 328.453369,274.245270 339.399323,259.873901 
	C357.214691,236.483322 364.860840,209.290619 365.830872,180.265762 
	C366.203918,169.104309 364.873199,157.862442 363.953339,146.687302 
	C362.859131,133.394531 358.728790,120.889091 353.345581,108.745514 
	C344.817169,89.506866 331.434265,74.182404 315.416626,60.859470 
	C300.599304,48.534946 283.646240,40.389053 265.311371,35.059643 
	C241.465790,28.128414 216.735336,27.098829 192.222809,26.251551 
	C154.827988,24.958992 117.375893,25.285503 79.947441,25.021141 
	C62.451107,24.897560 44.953175,25.000004 27.455963,25.000006 
	C25.704538,25.000008 23.953114,25.000006 22.395765,25.000006 
	C22.395765,208.773743 22.395765,391.807556 22.395765,574.727173 
	C41.061085,574.727173 59.329472,574.727173 78.112610,574.727173 
	C78.112610,489.743317 78.112610,405.030548 78.112610,320.805542 
	C120.472443,319.835175 162.301636,318.876953 204.985352,317.513885 
M2483.258789,584.014404 
	C2484.858643,584.342529 2486.459229,584.960022 2488.058105,584.955322 
	C2510.350342,584.889526 2532.642822,585.819641 2554.743652,581.126587 
	C2579.104248,575.953735 2601.923828,567.363403 2622.802490,553.722656 
	C2639.185303,543.019104 2653.549072,530.118286 2666.353760,515.293152 
	C2677.214355,502.718475 2686.773926,489.359131 2694.693359,474.760529 
	C2712.693848,441.578339 2720.654053,405.930573 2719.784180,368.442383 
	C2719.451172,354.090942 2717.177246,339.716827 2714.906738,325.493683 
	C2711.345703,303.186462 2702.713135,282.515930 2691.406738,263.168365 
	C2679.075928,242.067474 2664.114502,222.803741 2644.817871,207.500290 
	C2633.836670,198.791443 2622.556152,190.250961 2610.538574,183.100281 
	C2584.376465,167.533279 2555.406738,160.070770 2525.275391,158.250687 
	C2506.689209,157.128021 2487.996826,157.547607 2469.378418,160.780640 
	C2449.427734,164.245056 2430.197266,169.673187 2412.414062,179.127106 
	C2400.655029,185.378555 2389.633789,193.127136 2378.710205,200.813629 
	C2364.736328,210.646545 2353.180908,223.235260 2342.477783,236.368469 
	C2330.186035,251.451324 2320.000488,267.997070 2312.396240,286.064514 
	C2303.456787,307.303894 2297.948486,329.385071 2296.138184,352.285492 
	C2295.323975,362.587646 2295.611572,373.026367 2296.111572,383.368652 
	C2296.586426,393.185364 2297.656982,403.004974 2299.051758,412.739288 
	C2302.715576,438.308960 2312.184570,461.925415 2325.562012,483.761566 
	C2339.189453,506.006531 2355.838623,525.848816 2376.525635,542.168823 
	C2392.829102,555.030457 2410.376221,565.439636 2429.924561,572.467346 
	C2446.899414,578.569824 2464.272461,582.827820 2483.258789,584.014404 
M2739.000000,501.500000 
	C2739.000000,525.919250 2739.000000,550.338501 2739.000000,574.766235 
	C2756.976074,574.766235 2774.244873,574.766235 2792.000000,574.766235 
	C2792.000000,573.419434 2791.994629,572.300842 2792.000732,571.182251 
	C2792.333252,510.751709 2792.635498,450.320953 2793.038330,389.890869 
	C2793.090088,382.121918 2793.490479,374.344299 2794.034424,366.592102 
	C2794.885986,354.451324 2795.726318,342.298584 2797.073242,330.206787 
	C2798.506348,317.344086 2802.172363,305.005310 2807.398438,293.155762 
	C2818.047607,269.009094 2834.194336,249.232239 2855.173096,233.342529 
	C2875.185791,218.184418 2897.756592,209.201355 2922.719971,207.157486 
	C2937.686035,205.932129 2952.713379,207.016891 2967.350098,211.014786 
	C2992.174072,217.795410 3010.847168,232.246109 3023.075195,255.092224 
	C3033.079834,273.784668 3037.287842,293.969421 3038.896484,314.766663 
	C3040.082275,330.094086 3040.862793,345.492310 3040.908936,360.861725 
	C3041.117432,430.190094 3041.000000,499.519501 3041.000000,568.848511 
	C3041.000000,570.785583 3041.000000,572.722717 3041.000000,574.639954 
	C3058.995117,574.639954 3076.382324,574.639954 3094.000000,574.639954 
	C3094.000000,572.295837 3094.000000,570.310608 3094.000000,568.325317 
	C3094.000000,506.995789 3094.176025,445.665527 3093.908447,384.337158 
	C3093.809326,361.634491 3093.223633,338.901520 3091.801514,316.248779 
	C3090.892578,301.765961 3088.492432,287.326355 3085.978271,273.004761 
	C3079.887695,238.310852 3062.646484,209.929901 3035.298828,187.965851 
	C3012.930176,170.000534 2986.882324,160.254684 2958.412354,158.213715 
	C2940.162109,156.905380 2921.765381,157.532440 2903.768066,161.996155 
	C2887.711914,165.978317 2872.060059,171.141708 2858.062012,180.095322 
	C2847.897949,186.596542 2837.832275,193.431564 2828.613281,201.177505 
	C2819.790283,208.590576 2812.024902,217.282898 2803.975830,225.588348 
	C2799.962158,229.729797 2796.352783,234.263214 2791.836426,239.451279 
	C2791.836426,214.988449 2791.836426,191.721466 2791.836426,168.305420 
	C2774.115967,168.305420 2756.729492,168.305420 2739.000000,168.305420 
	C2739.000000,279.240326 2739.000000,389.870148 2739.000000,501.500000 
M1108.000000,50.500004 
	C1108.000000,42.076298 1108.000000,33.652588 1108.000000,25.407398 
	C1088.905884,25.407398 1070.522217,25.407398 1052.407104,25.407398 
	C1052.407104,208.827988 1052.407104,391.841003 1052.407104,574.740479 
	C1141.397583,574.740479 1229.980835,574.740479 1318.687744,574.740479 
	C1318.687744,556.762817 1318.687744,539.046204 1318.687744,520.817505 
	C1248.336792,520.817505 1178.296387,520.817505 1108.000000,520.817505 
	C1108.000000,364.035858 1108.000000,207.767929 1108.000000,50.500004 
M470.000000,173.617584 
	C470.000000,171.850876 470.000000,170.084167 470.000000,168.364105 
	C451.654633,168.364105 433.936096,168.364105 416.383606,168.364105 
	C416.383606,304.091400 416.383606,439.454315 416.383606,574.741150 
	C434.368713,574.741150 451.969055,574.741150 470.000000,574.741150 
	C470.000000,572.695923 470.000000,570.903564 470.000000,569.111267 
	C470.000000,530.121460 469.891937,491.131165 470.044647,452.141937 
	C470.123383,432.045563 470.400818,411.942108 471.074982,391.858215 
	C471.723053,372.550903 472.816162,353.252625 474.080597,333.974487 
	C474.658630,325.161530 475.604370,316.306824 477.285034,307.645996 
	C480.657654,290.266327 486.838135,273.783936 495.365692,258.270386 
	C507.860657,235.539215 525.728821,218.929092 551.064758,211.609589 
	C556.548523,210.025330 562.693481,209.225143 568.302490,209.923157 
	C577.220764,211.032959 585.944519,213.706100 595.040466,215.784225 
	C603.818970,201.610901 613.047485,186.711121 622.235962,171.875946 
	C602.694275,160.485825 582.540833,155.434326 560.419739,158.903931 
	C537.412170,162.512558 518.102173,173.352814 501.681458,189.125641 
	C490.387451,199.974060 480.488007,212.274384 470.000000,223.887329 
	C470.000000,207.760880 470.000000,191.175339 470.000000,173.617584 
M2200.000000,552.500000 
	C2200.000000,559.925110 2200.000000,567.350159 2200.000000,574.766602 
	C2217.978516,574.766602 2235.245850,574.766602 2252.733398,574.766602 
	C2252.733398,439.162781 2252.733398,303.792480 2252.733398,168.321777 
	C2235.079590,168.321777 2217.694580,168.321777 2200.000000,168.321777 
	C2200.000000,296.262207 2200.000000,423.881104 2200.000000,552.500000 
M482.085388,886.695129 
	C487.878906,869.597229 493.672424,852.499329 499.580902,835.062134 
	C502.066742,842.753601 504.411133,850.175964 506.863159,857.562683 
	C514.194824,879.649292 521.579224,901.718445 528.921570,923.801575 
	C533.006958,936.088928 532.991333,935.893188 545.657043,936.122803 
	C549.478271,936.192078 550.856262,934.638306 551.833984,931.463257 
	C562.074158,898.209106 572.372009,864.972778 582.660034,831.733398 
	C585.290222,823.235718 587.942261,814.744812 590.662109,806.000366 
	C585.298401,806.000366 580.304321,806.038086 575.311035,805.987488 
	C571.806946,805.951965 569.488281,807.126587 568.498596,810.870911 
	C566.921936,816.836304 564.871826,822.674744 563.142090,828.601868 
	C558.040833,846.082336 553.037048,863.591187 547.914734,881.065430 
	C545.765503,888.397217 543.413940,895.669678 541.074707,903.226562 
	C540.522095,902.597778 540.211060,902.394409 540.119263,902.118225 
	C530.729126,873.854919 521.387268,845.575562 511.967438,817.322144 
	C507.329742,803.412048 508.719879,806.430786 496.929260,805.925293 
	C493.245331,805.767395 491.361206,807.463928 490.244690,810.497986 
	C488.728912,814.617126 487.351959,818.788208 485.956207,822.950623 
	C476.748566,850.409851 467.551910,877.872864 458.349701,905.340454 
	C457.974457,903.714539 457.669037,902.115723 457.234222,900.552856 
	C454.837769,891.939209 452.495392,883.309204 449.975677,874.731384 
	C443.700012,853.367065 437.410706,832.005798 430.884766,810.717590 
	C430.318909,808.871704 428.071411,806.382996 426.421844,806.235107 
	C420.403931,805.695251 414.308350,806.021667 407.835876,806.021667 
	C421.490265,849.811157 434.924561,892.894897 448.365509,935.999878 
	C453.314240,935.999878 457.812714,935.845276 462.294006,936.056152 
	C465.114197,936.188843 466.227509,935.088379 467.080444,932.445740 
	C471.928925,917.423584 477.002747,902.474121 482.085388,886.695129 
M986.501648,806.000000 
	C981.074890,806.000000 975.648193,806.000000 970.396362,806.000000 
	C970.396362,849.752197 970.396362,892.798584 970.396362,936.938416 
	C992.173096,935.944031 1013.599243,935.852173 1034.812378,933.679993 
	C1048.569458,932.271301 1060.215454,924.961060 1069.109741,913.748169 
	C1079.745605,900.339417 1083.481934,885.087463 1083.073608,868.274658 
	C1082.600708,848.799866 1076.590942,832.043152 1061.016357,819.533325 
	C1050.646484,811.204102 1038.852295,806.335632 1025.489258,806.056763 
	C1012.831726,805.792603 1000.164368,806.000000 986.501648,806.000000 
M2664.489258,806.000000 
	C2662.401611,806.000000 2660.313965,806.000000 2658.385742,806.000000 
	C2658.385742,849.751648 2658.385742,892.788330 2658.385742,935.737427 
	C2664.044922,935.737427 2669.314453,935.737427 2675.125000,935.737427 
	C2675.125000,903.838501 2675.125000,872.197205 2675.125000,839.480347 
	C2683.453125,850.011414 2691.134277,859.539551 2698.614990,869.222351 
	C2714.007080,889.145264 2729.645508,908.893921 2744.437744,929.255371 
	C2749.941406,936.830566 2756.507568,936.037781 2763.764648,935.769104 
	C2763.764648,892.313049 2763.764648,849.395996 2763.764648,806.288574 
	C2758.141357,806.288574 2752.756348,806.288574 2746.814941,806.288574 
	C2746.814941,838.198364 2746.814941,869.823364 2746.814941,902.403625 
	C2737.741699,890.498535 2729.375977,879.442505 2720.925537,868.451782 
	C2707.570801,851.082947 2694.312012,833.636536 2680.676025,816.490784 
	C2676.803223,811.621399 2674.363037,803.849548 2664.489258,806.000000 
M757.000000,835.500000 
	C757.000000,868.914917 757.000000,902.329834 757.000000,935.599426 
	C764.088074,935.599426 770.479187,935.599426 777.200012,935.599426 
	C777.200012,917.597229 777.200012,899.993713 777.200012,882.005493 
	C781.337646,882.005493 785.225220,881.507080 788.905151,882.166687 
	C791.734314,882.673767 795.244812,883.937500 796.831116,886.070496 
	C806.175049,898.634277 814.832581,911.708862 824.183472,924.267151 
	C827.615112,928.875854 829.673828,935.618408 837.052185,935.902344 
	C842.620422,936.116516 848.203552,935.944641 855.445862,935.944641 
	C841.044739,916.152832 827.492065,897.527039 813.893616,878.838379 
	C831.562195,872.541504 839.589905,863.944580 842.581787,847.826660 
	C844.574280,837.092773 840.842407,820.958923 829.528503,813.987305 
	C821.400330,808.978699 812.644958,806.164185 803.141357,806.041260 
	C790.313477,805.875305 777.481873,806.000000 764.651855,806.000000 
	C762.214355,806.000000 759.776855,806.000000 757.000000,806.000000 
	C757.000000,815.898071 757.000000,825.199036 757.000000,835.500000 
M1778.000000,868.500000 
	C1778.000000,890.915894 1778.000000,913.331787 1778.000000,935.599731 
	C1784.755737,935.599731 1790.812256,935.599731 1797.197266,935.599731 
	C1797.197266,917.599670 1797.197266,899.997986 1797.197266,882.000183 
	C1801.165283,882.000183 1804.796265,881.959290 1808.425903,882.010864 
	C1812.586060,882.070007 1815.631226,883.582214 1818.193726,887.305054 
	C1828.097046,901.692993 1838.254028,915.914246 1848.674194,929.931702 
	C1850.629028,932.561279 1854.031128,935.190430 1857.105835,935.679626 
	C1862.858032,936.595032 1868.859619,935.942993 1875.867920,935.942993 
	C1861.619263,916.382690 1848.047607,897.751770 1834.343262,878.938782 
	C1835.153442,878.600403 1835.878052,878.294434 1836.605103,877.994568 
	C1843.551758,875.129456 1850.110107,871.878418 1855.117065,865.837646 
	C1862.139893,857.364685 1863.662231,847.599426 1862.967041,837.177612 
	C1862.509521,830.318787 1860.076538,824.158569 1855.466797,818.967102 
	C1847.260498,809.725098 1836.243042,806.855469 1824.613892,806.108948 
	C1815.985962,805.555176 1807.293945,806.000000 1798.630493,806.000000 
	C1791.861450,806.000000 1785.092407,806.000000 1778.000000,806.000000 
	C1778.000000,826.906616 1778.000000,847.203308 1778.000000,868.500000 
M230.000000,925.474426 
	C230.000000,885.739807 230.000000,846.005188 230.000000,806.391235 
	C223.260727,806.391235 217.205429,806.391235 210.825073,806.391235 
	C210.825073,825.378296 210.825073,843.976013 210.825073,862.697876 
	C188.435242,862.697876 166.390289,862.697876 143.826569,862.697876 
	C143.826569,843.682861 143.826569,824.963684 143.826569,806.399658 
	C136.910294,806.399658 130.521667,806.399658 124.252289,806.399658 
	C124.252289,849.718201 124.252289,892.636597 124.252289,935.694946 
	C130.893158,935.694946 137.277664,935.694946 144.179016,935.694946 
	C144.179016,916.010498 144.179016,896.625183 144.179016,877.392212 
	C166.747116,877.392212 188.793152,877.392212 211.176651,877.392212 
	C211.176651,897.046692 211.176651,916.310852 211.176651,935.687073 
	C217.576080,935.687073 223.627167,935.687073 230.000000,935.687073 
	C230.000000,932.409363 230.000000,929.435852 230.000000,925.474426 
M2893.078857,903.386780 
	C2892.041504,900.650330 2891.045654,897.897339 2889.960449,895.179932 
	C2878.504395,866.490112 2867.071533,837.790771 2855.454834,809.166016 
	C2854.887939,807.769287 2852.724854,806.240234 2851.217773,806.144165 
	C2845.604004,805.786377 2839.953613,806.001404 2834.162354,806.001404 
	C2816.906738,849.252380 2799.752686,892.249573 2782.310059,935.969543 
	C2788.028564,935.969543 2792.825195,936.194519 2797.589111,935.894653 
	C2801.060303,935.676147 2803.631592,933.764832 2804.928467,930.282715 
	C2808.217773,921.448975 2811.542480,912.626465 2815.051270,903.879028 
	C2815.529053,902.687927 2817.133301,901.136475 2818.228516,901.126160 
	C2836.355957,900.955322 2854.485840,901.004639 2872.274902,901.004639 
	C2876.231445,911.092163 2879.772217,920.882202 2884.000977,930.365417 
	C2885.061279,932.742615 2888.131836,935.302917 2890.629395,935.730530 
	C2895.545898,936.572205 2900.708008,935.978394 2906.127686,935.978394 
	C2901.744141,924.862854 2897.637207,914.448364 2893.078857,903.386780 
M294.537598,822.000000 
	C311.615570,822.000000 328.693542,822.000000 345.767883,822.000000 
	C345.767883,816.351257 345.767883,811.415344 345.767883,806.270508 
	C318.826874,806.270508 292.118317,806.270508 265.297241,806.270508 
	C265.297241,849.575745 265.297241,892.609985 265.297241,935.664795 
	C292.304810,935.664795 319.013794,935.664795 345.611572,935.664795 
	C345.611572,929.933044 345.611572,924.543091 345.611572,918.832886 
	C325.295532,918.832886 305.366150,918.832886 285.301941,918.832886 
	C285.301941,905.107788 285.301941,891.726257 285.301941,877.826477 
	C301.651154,877.826477 317.704956,877.826477 333.599182,877.826477 
	C333.599182,872.240662 333.599182,867.182495 333.599182,861.805542 
	C317.268341,861.805542 301.336334,861.805542 285.317322,861.805542 
	C285.317322,848.410278 285.317322,835.361633 285.317322,822.000000 
	C288.268097,822.000000 290.909119,822.000000 294.537598,822.000000 
M2176.500000,806.000000 
	C2166.410156,806.000000 2156.320068,806.000000 2146.398926,806.000000 
	C2146.398926,849.753784 2146.398926,892.802185 2146.398926,935.769897 
	C2173.088867,935.769897 2199.378906,935.769897 2226.333496,935.769897 
	C2226.333496,930.083435 2226.333496,924.626892 2226.333496,918.795227 
	C2205.925537,918.795227 2185.656738,918.795227 2165.332031,918.795227 
	C2165.332031,905.054993 2165.332031,891.668762 2165.332031,877.759033 
	C2182.051025,877.759033 2198.443359,877.759033 2214.736328,877.759033 
	C2214.736328,872.288940 2214.736328,867.350525 2214.736328,861.894897 
	C2198.089111,861.894897 2181.696777,861.894897 2165.370605,861.894897 
	C2165.370605,848.309570 2165.370605,835.255737 2165.370605,821.859131 
	C2185.674805,821.859131 2205.610840,821.859131 2225.698730,821.859131 
	C2225.698730,816.451538 2225.698730,811.398315 2225.698730,806.000000 
	C2209.433105,806.000000 2193.466553,806.000000 2176.500000,806.000000 
M1711.375000,806.000000 
	C1696.296997,806.000000 1681.218994,806.000000 1666.275635,806.000000 
	C1666.275635,849.731628 1666.275635,892.666931 1666.275635,935.667725 
	C1672.611938,935.667725 1678.666504,935.667725 1685.000000,935.667725 
	C1685.000000,927.526428 1685.000000,919.714966 1685.000000,911.903564 
	C1685.000000,903.985596 1685.000000,896.067627 1685.000000,888.000000 
	C1688.768433,888.000000 1691.762573,887.966553 1694.755615,888.006042 
	C1706.130005,888.156006 1717.390991,887.979614 1728.368042,884.073792 
	C1753.444580,875.150879 1759.730347,845.427368 1748.740967,825.328430 
	C1740.947388,811.074219 1727.244385,807.228760 1711.375000,806.000000 
M2060.000000,859.381958 
	C2060.000000,846.969910 2060.000000,834.557861 2060.000000,821.810181 
	C2080.723877,821.810181 2100.661133,821.810181 2120.672852,821.810181 
	C2120.672852,816.399475 2120.672852,811.345581 2120.672852,806.379761 
	C2093.625244,806.379761 2066.906006,806.379761 2040.253174,806.379761 
	C2040.253174,849.692139 2040.253174,892.624329 2040.253174,935.691040 
	C2046.897217,935.691040 2053.284180,935.691040 2060.196533,935.691040 
	C2060.196533,917.329346 2060.196533,899.270386 2060.196533,880.872314 
	C2077.660889,880.872314 2094.598877,880.872314 2111.703857,880.872314 
	C2111.703857,875.462219 2111.703857,870.408752 2111.703857,865.005920 
	C2094.475098,865.005920 2077.537354,865.005920 2060.283203,865.005920 
	C2060.175537,863.236084 2060.087646,861.794128 2060.000000,859.381958 
M1376.481445,822.000000 
	C1380.241699,822.000000 1384.001953,822.000000 1387.596924,822.000000 
	C1387.596924,816.239380 1387.596924,811.179443 1387.596924,806.277466 
	C1360.593262,806.277466 1333.993286,806.277466 1307.325317,806.277466 
	C1307.325317,849.604126 1307.325317,892.652588 1307.325317,935.619141 
	C1314.046875,935.619141 1320.436768,935.619141 1327.162598,935.619141 
	C1327.162598,917.303711 1327.162598,899.366821 1327.162598,880.908020 
	C1344.565063,880.908020 1361.624268,880.908020 1378.633667,880.908020 
	C1378.633667,875.315674 1378.633667,870.261536 1378.633667,864.866577 
	C1361.333130,864.866577 1344.395996,864.866577 1327.296875,864.866577 
	C1327.296875,850.460693 1327.296875,836.408264 1327.296875,822.000000 
	C1343.556641,822.000000 1359.522339,822.000000 1376.481445,822.000000 
M1540.000000,866.500000 
	C1540.000000,889.578064 1540.000000,912.656128 1540.000000,935.607910 
	C1546.740234,935.607910 1552.795166,935.607910 1559.163574,935.607910 
	C1559.163574,897.629211 1559.163574,860.041565 1559.163574,821.933594 
	C1572.874512,821.933594 1586.263062,821.933594 1599.651611,821.933594 
	C1599.651611,816.351624 1599.651611,811.297974 1599.651611,806.382324 
	C1565.594604,806.382324 1531.887451,806.382324 1498.259277,806.382324 
	C1498.259277,811.704773 1498.259277,816.641418 1498.259277,822.091309 
	C1512.231445,822.091309 1525.953979,822.091309 1540.000000,822.091309 
	C1540.000000,836.907898 1540.000000,851.203918 1540.000000,866.500000 
M893.000000,899.499634 
	C893.000000,868.437378 893.000000,837.375122 893.000000,806.366211 
	C886.308472,806.366211 880.256653,806.366211 874.370605,806.366211 
	C874.370605,849.753235 874.370605,892.773132 874.370605,935.603027 
	C899.445312,935.603027 924.147766,935.603027 948.729248,935.603027 
	C948.729248,929.934509 948.729248,924.666931 948.729248,918.899536 
	C930.087097,918.899536 911.708618,918.899536 893.000000,918.899536 
	C893.000000,912.429321 893.000000,906.464355 893.000000,899.499634 
M2944.000000,912.410278 
	C2944.000000,877.025208 2944.000000,841.640137 2944.000000,806.395386 
	C2937.248779,806.395386 2931.195557,806.395386 2925.402832,806.395386 
	C2925.402832,849.813843 2925.402832,892.836243 2925.402832,935.745117 
	C2950.058105,935.745117 2974.310059,935.745117 2998.694824,935.745117 
	C2998.694824,930.103516 2998.694824,924.720215 2998.694824,918.828308 
	C2980.349365,918.828308 2962.303467,918.828308 2944.000000,918.828308 
	C2944.000000,916.672485 2944.000000,915.027222 2944.000000,912.410278 
M1464.557373,806.000000 
	C1462.128906,806.000000 1459.700317,806.000000 1457.371948,806.000000 
	C1457.371948,849.710266 1457.371948,892.752930 1457.371948,935.750610 
	C1464.015991,935.750610 1470.285156,935.750610 1476.711914,935.750610 
	C1476.711914,892.458557 1476.711914,849.416321 1476.711914,806.000000 
	C1472.796265,806.000000 1469.163818,806.000000 1464.557373,806.000000 
M2475.000000,857.500000 
	C2475.000000,840.416687 2475.000000,823.333435 2475.000000,806.399292 
	C2468.245117,806.399292 2462.188965,806.399292 2456.257324,806.399292 
	C2456.257324,849.721130 2456.257324,892.643127 2456.257324,935.689148 
	C2462.570312,935.689148 2468.623291,935.689148 2475.000000,935.689148 
	C2475.000000,909.753113 2475.000000,884.126526 2475.000000,857.500000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M658.466858,937.000000 
	C652.428345,935.560791 646.667542,934.620544 641.319214,932.592346 
	C622.342468,925.396057 609.984802,911.870117 604.693115,892.238586 
	C599.614563,873.397766 600.172302,854.644409 609.134705,837.145264 
	C618.760132,818.351746 634.586060,807.798584 655.785828,804.858643 
	C670.087097,802.875366 683.566772,804.689575 696.400269,810.871216 
	C714.843201,819.754944 725.136597,835.177246 728.848267,854.789673 
	C731.889709,870.860779 731.023438,887.033630 723.883972,902.051208 
	C714.032410,922.773254 697.262268,934.102234 674.184998,936.630859 
	C668.977905,937.000000 663.955811,937.000000 658.466858,937.000000 
M676.097839,822.065918 
	C670.241577,822.043152 664.342285,821.554871 658.537598,822.090698 
	C644.748718,823.363159 634.780701,830.792175 628.108704,842.672119 
	C620.344482,856.496887 620.477295,871.490967 623.378540,886.326904 
	C625.424011,896.786743 630.998901,905.780579 640.144470,912.032837 
	C651.176025,919.574341 663.384460,921.382568 676.209717,918.804565 
	C686.511475,916.733765 695.014404,911.502014 701.093079,902.640076 
	C711.312561,887.741333 712.040894,871.441650 708.186829,854.540649 
	C704.310120,837.540283 694.024353,826.588928 676.097839,822.065918 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2558.467773,937.000000 
	C2554.281738,936.136414 2550.479980,935.532959 2546.858887,934.367554 
	C2520.841309,925.994629 2506.467285,908.207214 2502.852051,881.043701 
	C2500.929932,866.603699 2502.356201,852.750549 2508.269531,839.725342 
	C2516.475586,821.650330 2530.661133,810.359131 2550.246338,805.946045 
	C2566.150146,802.362549 2581.406738,803.611328 2596.221680,810.615356 
	C2614.325684,819.174622 2624.592773,833.851379 2628.919678,852.849243 
	C2633.501465,872.966736 2631.246826,892.412720 2619.895508,909.932129 
	C2609.555420,925.890442 2594.222412,934.277527 2575.180664,936.633423 
	C2569.645020,937.000000 2564.290283,937.000000 2558.467773,937.000000 
M2604.088623,898.564514 
	C2611.733887,883.766113 2612.879883,868.424683 2608.199951,852.490051 
	C2605.750244,844.148926 2602.200684,836.416931 2595.157471,831.113647 
	C2584.439453,823.043518 2572.535156,819.451721 2558.854248,821.888367 
	C2543.714844,824.584717 2532.933838,832.096191 2526.719238,846.466492 
	C2521.413574,858.735046 2520.887695,871.321777 2523.205078,884.049500 
	C2524.725098,892.397339 2528.020020,900.390564 2534.166992,906.574341 
	C2545.045410,917.518005 2558.185303,921.507996 2573.526367,919.096252 
	C2586.753906,917.016907 2597.084961,910.948792 2604.088623,898.564514 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1208.466797,937.000000 
	C1201.983154,935.349365 1195.798706,934.146362 1189.979492,931.967773 
	C1169.131958,924.162781 1157.236084,908.314880 1153.232056,887.102478 
	C1149.997314,869.966370 1150.441284,852.538025 1159.181274,836.661987 
	C1169.265503,818.344177 1184.698975,807.746765 1205.754272,804.834595 
	C1219.739746,802.900208 1233.140869,804.331238 1245.513916,810.416809 
	C1264.741211,819.873596 1275.821533,835.860168 1279.016846,857.164307 
	C1281.276978,872.232666 1280.408813,886.887512 1274.091797,901.046021 
	C1267.589844,915.619080 1257.180176,926.156128 1242.349609,931.879333 
	C1236.642334,934.081665 1230.379395,934.843750 1224.184814,936.630981 
	C1218.977905,937.000000 1213.955811,937.000000 1208.466797,937.000000 
M1206.769165,822.009949 
	C1205.678101,822.334534 1204.597412,822.700562 1203.494385,822.977417 
	C1190.140259,826.330017 1181.145264,834.564087 1175.890625,847.212463 
	C1171.646118,857.429077 1170.716797,867.928101 1171.943726,878.846436 
	C1173.463867,892.376282 1178.630493,903.956482 1189.912720,911.878723 
	C1200.073608,919.013672 1211.541626,921.130371 1223.954712,919.090088 
	C1235.244141,917.234558 1244.471680,912.212830 1250.962036,902.879028 
	C1261.218628,888.129700 1261.807373,871.697815 1258.347656,854.758728 
	C1255.965088,843.093323 1249.832397,833.629639 1239.743774,827.526917 
	C1229.987061,821.624817 1219.081665,819.570679 1206.769165,822.009949 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1941.467529,937.000000 
	C1934.235474,935.006714 1927.304810,933.446045 1920.743286,930.924438 
	C1909.295288,926.524719 1900.893677,918.186279 1894.549683,907.952332 
	C1885.901978,894.002075 1883.420654,878.332825 1885.174438,862.417542 
	C1888.460571,832.598572 1904.595947,811.741150 1934.872925,805.417236 
	C1957.797363,800.629089 1979.503174,805.430969 1996.432739,823.106628 
	C2006.820679,833.952209 2011.597168,847.466980 2013.164062,862.473572 
	C2014.384155,874.158630 2013.227661,885.348511 2009.292603,896.211975 
	C2000.662842,920.036377 1983.440063,933.216248 1958.180664,936.633423 
	C1952.645020,937.000000 1947.290161,937.000000 1941.467529,937.000000 
M1984.937012,902.315857 
	C1985.939575,900.638855 1987.109497,899.038513 1987.918457,897.272766 
	C1994.968506,881.887024 1995.581421,866.035828 1990.475830,850.073303 
	C1986.666382,838.162537 1978.975464,829.316589 1967.151489,824.685547 
	C1959.003662,821.494446 1950.675415,820.316895 1941.742798,821.847595 
	C1927.877930,824.223511 1917.447998,830.911743 1910.947266,843.390564 
	C1904.383301,855.991028 1903.609863,869.415894 1906.014038,883.137817 
	C1910.130981,906.634460 1926.312622,919.068787 1948.279297,919.857361 
	C1962.909058,920.382568 1975.382446,914.858215 1984.937012,902.315857 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M2233.535400,0.999997 
	C2239.454102,3.292489 2245.421631,4.811322 2250.263672,8.025807 
	C2263.585693,16.869822 2269.982178,29.320154 2270.244385,45.782001 
	C2270.521484,63.172401 2256.905518,81.187759 2240.239258,86.349342 
	C2215.723145,93.942001 2189.129639,79.003036 2184.203125,54.165539 
	C2179.668945,31.305952 2191.963135,9.170366 2215.281982,2.362292 
	C2216.685547,1.952538 2218.199951,1.921754 2219.831299,1.357318 
	C2224.356934,1.000000 2228.713867,1.000000 2233.535400,0.999997 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2276.466797,937.000000 
	C2270.958252,935.474854 2265.626465,934.549133 2260.944580,932.281128 
	C2254.281250,929.053284 2248.095947,924.839539 2241.060791,920.663818 
	C2244.241211,916.168579 2246.375244,912.758301 2248.913330,909.681091 
	C2251.219238,906.885376 2254.208252,907.246155 2256.805420,909.331848 
	C2266.269531,916.931946 2276.569824,921.363037 2289.143066,920.127747 
	C2298.029541,919.254822 2307.041016,912.096069 2308.931885,903.000793 
	C2310.806396,893.984436 2307.346680,886.720764 2298.037354,882.676819 
	C2289.415527,878.931458 2280.325684,876.275146 2271.502441,872.978149 
	C2257.280518,867.663940 2247.026611,858.768982 2248.042725,838.125854 
	C2248.655518,825.677612 2256.893555,812.063416 2272.266846,806.731628 
	C2290.327881,800.467651 2315.099121,806.251648 2325.038574,817.809448 
	C2323.737793,820.429443 2322.459961,823.225098 2320.989502,825.915466 
	C2318.473633,830.518921 2315.161377,831.650879 2311.165039,828.500122 
	C2303.500732,822.457153 2294.958740,820.422485 2285.392578,820.938843 
	C2275.114258,821.493652 2267.074463,827.976318 2266.173828,838.917480 
	C2265.601562,845.868042 2270.381348,850.801392 2276.223633,853.463806 
	C2285.082275,857.500854 2294.414551,860.491699 2303.511475,864.015442 
	C2313.586914,867.918213 2322.077393,873.501343 2326.126953,884.300354 
	C2331.182129,897.781738 2325.195801,919.723328 2312.961670,928.081421 
	C2306.630859,932.406433 2299.973389,935.451965 2292.170410,936.639893 
	C2286.977783,937.000000 2281.955811,937.000000 2276.466797,937.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2377.466797,937.000000 
	C2372.571289,935.673462 2367.862793,934.911682 2363.779053,932.888916 
	C2357.544189,929.800903 2351.639404,925.992371 2345.853760,922.104370 
	C2344.887695,921.455261 2344.677979,918.694702 2345.106934,917.191711 
	C2345.714355,915.062805 2347.134521,913.125732 2348.395020,911.231689 
	C2350.898438,907.470947 2353.997070,907.151062 2358.099609,909.468689 
	C2364.288574,912.964905 2370.320068,917.407349 2377.018555,919.181152 
	C2387.685059,922.005737 2400.646973,921.044922 2407.065674,910.262268 
	C2413.243652,899.884949 2412.279785,888.630676 2399.627930,883.035034 
	C2391.624023,879.494995 2383.183350,876.942932 2374.940186,873.942810 
	C2364.024658,869.970032 2354.736816,864.415344 2350.919922,852.314209 
	C2344.111572,830.729858 2356.794922,809.348267 2379.772461,805.003662 
	C2394.802246,802.161743 2408.755615,804.980408 2421.461182,813.336060 
	C2423.419922,814.624084 2425.935547,818.563110 2425.322510,819.635803 
	C2423.111328,823.506348 2423.526855,830.322449 2416.581299,829.868164 
	C2414.522949,829.733459 2412.400879,828.275208 2410.591797,827.015808 
	C2401.500000,820.686340 2391.477539,819.186096 2381.049805,822.038025 
	C2370.857178,824.825684 2365.169922,833.897583 2368.390869,843.665955 
	C2369.773682,847.859497 2374.606201,851.614197 2378.766602,854.019348 
	C2384.180908,857.149414 2390.598877,858.484375 2396.379639,861.053894 
	C2403.752686,864.331177 2411.083984,867.776062 2418.130859,871.692688 
	C2425.126709,875.580933 2428.121094,882.696289 2428.810547,890.033813 
	C2430.120361,903.975281 2427.238037,916.884033 2415.909668,926.617859 
	C2409.386963,932.222229 2401.951660,935.578552 2393.176025,936.636536 
	C2387.977783,937.000000 2382.955811,937.000000 2377.466797,937.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M3043.466797,937.000000 
	C3036.632324,934.810791 3030.034912,933.112488 3023.971436,930.273499 
	C3019.319336,928.095215 3015.315186,924.513489 3011.092773,921.459412 
	C3010.529297,921.051697 3009.885742,919.779297 3010.109375,919.409668 
	C3012.376953,915.661682 3014.169434,911.220032 3017.408447,908.590698 
	C3020.132324,906.379578 3023.693848,908.638855 3026.657227,911.012085 
	C3037.162842,919.425049 3049.085938,922.654541 3062.253662,918.815430 
	C3071.876465,916.009888 3076.616943,907.843628 3076.950439,899.008362 
	C3077.265137,890.664185 3072.242676,884.364319 3062.948730,881.062744 
	C3054.221924,877.962585 3045.460449,874.954346 3036.671143,872.036133 
	C3026.383301,868.620544 3015.511963,856.591858 3015.760498,842.805969 
	C3015.966064,831.402100 3018.790283,821.867493 3027.318848,814.149353 
	C3034.573975,807.583618 3043.332031,804.677246 3052.779297,804.082764 
	C3067.760254,803.140259 3081.301270,807.147949 3093.119629,817.818848 
	C3091.280029,821.109314 3089.690674,824.890930 3087.210938,827.951111 
	C3086.135742,829.277893 3083.164795,829.683838 3081.169189,829.465515 
	C3079.402100,829.272156 3077.783447,827.649841 3076.116455,826.630005 
	C3065.320068,820.025024 3054.127197,818.349915 3042.493408,824.231628 
	C3036.657471,827.181946 3034.352051,832.543640 3034.059082,838.717407 
	C3033.770752,844.788391 3036.193604,849.878723 3041.907227,852.331848 
	C3052.338379,856.810242 3062.987061,860.780640 3073.525635,865.010986 
	C3083.711914,869.099915 3091.786133,875.248535 3094.653564,886.849731 
	C3095.000000,892.688049 3095.000000,898.376038 3094.648926,904.688965 
	C3091.875977,916.894226 3085.938477,926.046326 3075.139404,931.266907 
	C3070.029785,933.736877 3064.396240,935.122803 3059.000000,937.000000 
	C3053.977783,937.000000 3048.955811,937.000000 3043.466797,937.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1.000000,806.466858 
	C34.780083,806.000000 68.560165,806.000000 102.669724,806.000000 
	C102.669724,811.336121 102.669724,816.387512 102.669724,821.962402 
	C88.899406,821.962402 75.178688,821.962402 61.150360,821.962402 
	C61.150360,860.058655 61.150360,897.638733 61.150360,935.608887 
	C54.794357,935.608887 48.740665,935.608887 42.184128,935.608887 
	C42.184128,898.035645 42.184128,860.336060 42.184128,822.000000 
	C28.444685,822.000000 15.191000,822.000000 1.468657,822.000000 
	C1.000000,816.977905 1.000000,811.955811 1.000000,806.466858 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M3059.468750,937.000000 
	C3064.396240,935.122803 3070.029785,933.736877 3075.139404,931.266907 
	C3085.938477,926.046326 3091.875977,916.894226 3094.648926,905.156921 
	C3095.000000,915.597534 3095.000000,926.195068 3095.000000,937.000000 
	C3083.313232,937.000000 3071.625488,937.000000 3059.468750,937.000000 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M1988.027588,733.000000 
	C1977.122437,733.333313 1966.677490,734.163391 1956.259766,733.902527 
	C1929.973877,733.243958 1904.059570,729.784729 1878.858643,721.928345 
	C1858.186646,715.483887 1838.645508,706.592896 1820.825439,694.240967 
	C1801.327515,680.726013 1786.136963,663.113953 1774.051636,642.861938 
	C1767.066650,631.156860 1760.802856,619.021301 1753.618896,606.000000 
	C1764.927490,606.000000 1774.535889,606.000000 1784.144165,606.000000 
	C1792.142944,606.000000 1800.152710,606.241577 1808.135498,605.884460 
	C1811.456665,605.735840 1813.425171,606.932983 1814.857788,609.509094 
	C1822.544556,623.331055 1832.724854,635.166321 1844.330200,645.744080 
	C1860.008179,660.033813 1878.079346,670.143188 1898.537231,676.080627 
	C1914.340820,680.667358 1930.390381,682.946045 1946.795288,684.235840 
	C1963.890259,685.579834 1980.635254,684.175110 1997.515015,682.122192 
	C2017.738647,679.662537 2036.764038,673.575806 2054.432129,663.734009 
	C2077.056396,651.131531 2096.391602,634.690247 2106.686768,610.228210 
	C2111.118408,599.697876 2113.575195,588.208191 2115.889404,576.940796 
	C2121.051270,551.807495 2120.076660,526.260376 2119.938721,500.100464 
	C2116.747314,503.461975 2113.726562,506.310150 2111.090576,509.478363 
	C2099.502930,523.405273 2085.305908,534.331970 2070.134277,543.830566 
	C2050.831787,555.915344 2029.865601,564.564453 2007.617065,569.555359 
	C1997.599243,571.802734 1987.282959,572.937561 1977.038208,573.890625 
	C1967.821167,574.748169 1958.493774,575.252319 1949.255371,574.887329 
	C1924.243530,573.899170 1900.080811,568.694885 1876.914429,559.012024 
	C1859.520264,551.741882 1842.991943,542.821045 1828.089600,531.362976 
	C1809.441162,517.024841 1793.447021,500.038116 1780.955078,479.911377 
	C1767.122925,457.625275 1757.821533,433.686127 1752.879883,407.882080 
	C1749.165283,388.485626 1749.058594,368.905121 1750.313843,349.507141 
	C1751.824829,326.156006 1758.154663,303.700897 1767.818848,282.160034 
	C1775.507935,265.021667 1785.280151,249.265213 1796.959106,234.751419 
	C1808.370605,220.570129 1821.769165,208.305496 1836.705688,197.788971 
	C1856.874146,183.588715 1878.482666,172.236908 1902.422485,166.022446 
	C1914.216431,162.960892 1926.288574,160.499451 1938.388428,159.218079 
	C1950.881592,157.895035 1963.630859,157.345169 1976.143555,158.226471 
	C1996.369873,159.651031 2016.108398,164.061035 2034.959351,171.959778 
	C2056.634277,181.041794 2076.141846,193.415009 2093.132324,209.665909 
	C2102.156738,218.297531 2111.022217,227.095215 2119.917480,235.776962 
	C2119.917480,213.720749 2119.917480,191.179214 2119.917480,168.320648 
	C2138.030762,168.320648 2155.749512,168.320648 2174.000000,168.320648 
	C2174.000000,170.197021 2174.000000,171.977356 2174.000000,173.757675 
	C2174.000000,281.241760 2174.029541,388.725861 2173.956299,496.209869 
	C2173.948486,507.476044 2173.625244,518.759583 2172.940918,530.004211 
	C2171.396973,555.363098 2169.491455,580.766174 2162.686523,605.339172 
	C2156.624023,627.230713 2146.519043,647.411316 2132.201172,665.165894 
	C2119.283203,681.184021 2103.723877,694.233398 2085.889893,704.640198 
	C2064.070557,717.372864 2040.483032,725.119019 2015.856323,729.829041 
	C2006.864380,731.548828 1997.625977,731.980652 1988.027588,733.000000 
M2081.002441,257.503510 
	C2070.343506,245.876968 2057.891113,236.532364 2044.072876,229.051605 
	C2024.022095,218.196793 2002.789185,211.678238 1979.858765,209.706314 
	C1966.018555,208.516098 1952.421021,209.176392 1938.986572,211.290161 
	C1920.756714,214.158386 1903.409546,220.231155 1887.136108,229.312195 
	C1868.434937,239.747879 1852.069580,252.793152 1838.799194,269.674042 
	C1819.014282,294.841949 1807.011353,323.414276 1804.355347,355.163727 
	C1803.038574,370.904449 1803.931885,387.177277 1806.389526,402.806122 
	C1810.193726,426.998718 1820.405884,448.850037 1836.283813,467.855530 
	C1849.293335,483.427673 1864.346069,496.404327 1882.256226,506.087097 
	C1905.347656,518.570862 1930.389282,523.602966 1956.189819,524.782715 
	C1968.661499,525.352966 1981.359619,524.496033 1993.739624,522.754089 
	C2014.197510,519.875549 2033.696289,513.550598 2051.581055,502.809021 
	C2074.505371,489.040741 2092.639893,470.551270 2104.909424,446.854370 
	C2120.149170,417.421295 2125.023193,385.930267 2122.152100,352.946655 
	C2120.837646,337.847321 2117.988770,323.216339 2112.902100,309.030731 
	C2106.025879,289.854645 2095.649170,272.749969 2081.002441,257.503510 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M600.261047,288.402039 
	C608.129089,269.338531 618.621582,252.220566 631.289917,236.568008 
	C641.953918,223.391815 653.846924,211.341293 667.303101,200.812683 
	C678.696777,191.897842 690.633911,183.973236 703.666931,177.804642 
	C720.966248,169.616745 739.085938,164.147781 758.020813,160.815735 
	C776.454346,157.571915 794.962708,157.296402 813.412048,158.218430 
	C835.982849,159.346451 857.993713,164.163818 878.973816,172.986786 
	C899.342285,181.552505 917.686462,193.166016 934.399841,207.719330 
	C959.111084,229.236771 977.969666,254.913666 991.219788,284.729156 
	C998.655701,301.461426 1004.027832,318.920776 1005.927124,337.201508 
	C1007.355591,350.950256 1009.076782,364.803986 1008.769714,378.568634 
	C1008.187073,404.685486 1003.390381,430.144928 993.247437,454.461670 
	C985.525391,472.974487 975.730652,490.307861 963.335938,505.974548 
	C942.888977,531.818909 918.453064,553.092285 888.208191,566.875549 
	C870.050171,575.150513 851.089355,581.370056 831.082642,582.868164 
	C813.272278,584.201904 795.319824,585.505615 777.524109,584.712708 
	C753.626404,583.647949 730.553528,577.811157 708.420410,568.235291 
	C683.592773,557.493652 662.308655,541.918823 643.947083,522.339233 
	C617.889648,494.553314 599.069397,462.482513 590.346924,425.223724 
	C587.230957,411.913696 586.203186,398.035248 585.161316,384.344788 
	C584.351868,373.707611 583.957947,362.831604 585.251343,352.291443 
	C587.072449,337.451294 590.341370,322.761169 593.564697,308.133301 
	C595.023315,301.513977 597.862305,295.198853 600.261047,288.402039 
M837.221558,401.274994 
	C845.898315,384.742004 860.184082,376.513702 878.243591,376.253174 
	C893.372803,376.034943 905.743713,382.787506 915.080933,395.384918 
	C926.646301,410.988464 925.556396,437.023590 911.784241,450.641571 
	C907.861816,454.520081 903.508789,457.963104 899.819641,461.196930 
	C906.248535,466.007507 912.767090,470.885132 919.898926,476.221680 
	C920.407288,475.507294 921.159668,474.612976 921.734924,473.616364 
	C928.159790,462.485535 935.193237,451.649567 940.821594,440.127899 
	C950.660278,419.987183 954.298035,398.200897 954.855835,375.978729 
	C955.073608,367.303894 953.878113,358.566833 952.964905,349.896149 
	C951.224854,333.374359 946.423157,317.682495 939.281738,302.716766 
	C933.268555,290.115356 925.740173,278.546265 916.576965,267.913666 
	C902.696838,251.807785 887.170776,237.842514 868.352234,227.742630 
	C850.886780,218.368912 832.442383,212.642914 812.564026,210.789581 
	C797.453064,209.380722 782.510193,209.728592 767.707886,212.283356 
	C750.055359,215.330048 733.725647,222.229385 718.218018,231.262772 
	C702.136963,240.630219 688.936768,253.368469 676.782043,267.041779 
	C665.364990,279.885223 656.506592,294.669586 650.073547,310.731567 
	C644.157043,325.504181 640.348267,340.861755 639.130615,356.699127 
	C638.414734,366.009827 638.574280,375.445648 639.124451,384.778931 
	C639.633667,393.416962 640.545044,402.134796 642.402039,410.570312 
	C647.880066,435.454010 659.274658,457.650055 674.478333,477.977264 
	C676.574768,480.780212 678.322754,480.785492 681.152832,478.501160 
	C688.638489,472.459015 696.467651,466.842468 704.082703,461.114746 
	C703.075439,460.457153 701.450439,459.478210 699.911438,458.378906 
	C683.525330,446.674896 676.519714,430.434967 681.109070,410.706421 
	C687.070312,385.080322 711.289185,372.273804 735.126221,377.584778 
	C749.295471,380.741730 759.520813,389.013062 766.013428,402.649994 
	C764.634583,404.630981 763.567139,406.954742 761.825806,408.536743 
	C751.381470,418.025452 744.195435,429.428864 739.437500,442.757629 
	C735.731384,453.140106 737.063293,463.813019 736.308838,474.285156 
	C735.198853,489.691193 735.944763,505.236298 736.110046,520.719910 
	C736.125305,522.146301 737.336243,524.392456 738.529175,524.846436 
	C752.186157,530.043762 766.282715,534.059387 780.865540,534.798706 
	C793.259827,535.427063 805.823669,535.104675 818.163818,533.798279 
	C833.505249,532.174133 848.356812,527.984863 862.435425,521.353638 
	C865.326050,519.992126 866.122559,518.230652 866.101013,515.194397 
	C865.971130,496.879120 866.215393,478.559448 865.915039,460.248169 
	C865.826721,454.861206 864.813843,449.384064 863.466187,444.143280 
	C859.800049,429.886505 851.770630,418.271332 841.008667,408.356445 
	C839.202759,406.692627 838.267944,404.083160 837.221558,401.274994 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M1657.248291,544.256592 
	C1634.793823,561.554443 1609.938232,573.092407 1582.786499,579.486267 
	C1573.275391,581.726074 1563.470825,583.255493 1553.724243,583.838440 
	C1537.995239,584.779053 1522.151367,585.623718 1506.450317,584.771362 
	C1483.894287,583.546814 1461.874390,578.835022 1440.755981,570.372498 
	C1414.139648,559.706909 1391.512817,543.360962 1372.623657,522.007324 
	C1346.748657,492.756256 1328.641968,459.224915 1320.230713,420.941284 
	C1317.781738,409.794708 1316.798462,398.235657 1316.150879,386.808594 
	C1315.471069,374.813263 1315.174316,362.680115 1316.176636,350.730225 
	C1318.164429,327.033020 1324.036011,304.221191 1333.805786,282.394348 
	C1344.006104,259.605621 1357.803955,239.388657 1374.496338,220.878464 
	C1398.017700,194.795364 1426.376709,176.377075 1460.024658,166.731262 
	C1473.429932,162.888458 1487.454346,160.696121 1501.352661,159.229645 
	C1514.671997,157.824249 1528.253662,157.373459 1541.612183,158.215088 
	C1564.315674,159.645477 1586.553467,164.059891 1607.717041,172.923813 
	C1628.581055,181.662231 1647.387817,193.437790 1664.309204,208.596588 
	C1682.385742,224.790176 1697.172363,243.379822 1708.854248,264.532776 
	C1720.233643,285.137878 1727.631470,307.161316 1730.915894,330.429016 
	C1732.818359,343.907562 1733.686279,357.532745 1734.981323,371.095612 
	C1735.119995,372.548126 1735.000000,374.025330 1735.000000,375.821014 
	C1613.314819,375.821014 1492.053833,375.821014 1369.865356,375.821014 
	C1370.262939,382.537750 1369.961304,388.992889 1371.151733,395.159943 
	C1373.678223,408.248596 1376.108032,421.467285 1380.164551,434.124023 
	C1386.837769,454.944794 1398.275391,473.133789 1413.440308,489.079315 
	C1426.446045,502.754486 1440.889771,514.368530 1457.964966,522.382935 
	C1472.504272,529.207153 1487.722412,534.115601 1503.926025,534.965393 
	C1514.678955,535.529419 1525.516846,536.555237 1536.205444,535.799683 
	C1554.161865,534.530396 1571.575439,530.225220 1588.309814,523.327332 
	C1609.222534,514.707153 1628.519897,503.381561 1643.136719,485.999969 
	C1654.456055,472.539520 1663.988281,457.575836 1674.302734,443.271545 
	C1675.247437,441.961395 1676.102051,440.586365 1677.153442,439.008881 
	C1692.255249,446.970337 1707.087769,454.789886 1723.171143,463.268799 
	C1713.706665,477.798340 1704.727295,492.360504 1694.952637,506.367676 
	C1685.386108,520.076782 1673.045898,531.312866 1660.352905,542.103760 
	C1659.489868,542.837463 1658.458740,543.373108 1657.248291,544.256592 
M1554.164307,211.078583 
	C1548.803345,210.386154 1543.449829,209.178192 1538.079834,209.099152 
	C1522.734741,208.873322 1507.432129,208.693283 1492.193359,211.870499 
	C1465.250000,217.488068 1441.855103,229.765686 1421.557251,248.035950 
	C1404.374878,263.501740 1393.165527,283.109131 1384.448242,304.211487 
	C1381.060425,312.412903 1378.396729,320.913513 1375.217896,329.795776 
	C1476.061035,329.795776 1575.815308,329.795776 1676.066528,329.795776 
	C1675.830811,328.327362 1675.788574,327.055267 1675.413330,325.890320 
	C1672.971436,318.307159 1670.728516,310.644867 1667.916138,303.198883 
	C1659.320679,280.441010 1646.470581,260.465424 1627.430908,245.064713 
	C1606.144409,227.846619 1581.807373,216.852280 1554.164307,211.078583 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M204.558075,317.716309 
	C162.301636,318.876953 120.472443,319.835175 78.112610,320.805542 
	C78.112610,405.030548 78.112610,489.743317 78.112610,574.727173 
	C59.329472,574.727173 41.061085,574.727173 22.395765,574.727173 
	C22.395765,391.807556 22.395765,208.773743 22.395765,25.000006 
	C23.953114,25.000006 25.704538,25.000008 27.455963,25.000006 
	C44.953175,25.000004 62.451107,24.897560 79.947441,25.021141 
	C117.375893,25.285503 154.827988,24.958992 192.222809,26.251551 
	C216.735336,27.098829 241.465790,28.128414 265.311371,35.059643 
	C283.646240,40.389053 300.599304,48.534946 315.416626,60.859470 
	C331.434265,74.182404 344.817169,89.506866 353.345581,108.745514 
	C358.728790,120.889091 362.859131,133.394531 363.953339,146.687302 
	C364.873199,157.862442 366.203918,169.104309 365.830872,180.265762 
	C364.860840,209.290619 357.214691,236.483322 339.399323,259.873901 
	C328.453369,274.245270 314.837402,285.884705 298.843384,294.680023 
	C279.728119,305.191711 259.424683,311.679199 237.764679,314.011597 
	C226.839218,315.188049 215.911850,316.347015 204.558075,317.716309 
M78.000000,155.500000 
	C78.000000,191.905396 78.000000,228.310791 78.000000,264.490723 
	C89.407990,265.014343 100.144646,265.948303 110.878357,265.915710 
	C140.961761,265.824371 171.058121,265.769775 201.119873,264.792572 
	C213.632950,264.385773 226.214813,262.383301 238.516861,259.883423 
	C277.465424,251.968765 303.756561,220.854462 307.794708,181.959595 
	C310.088898,159.862442 305.173767,138.982727 292.005096,120.437782 
	C280.076111,103.638657 264.650360,92.170685 244.587799,86.754280 
	C227.173981,82.052979 209.343506,80.529160 191.546967,80.198967 
	C155.580505,79.531654 119.592995,80.000000 83.614166,80.000000 
	C81.847961,80.000000 80.081757,80.000000 78.000000,80.000000 
	C78.000000,105.249870 78.000000,129.874939 78.000000,155.500000 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M2482.793457,584.008179 
	C2464.272461,582.827820 2446.899414,578.569824 2429.924561,572.467346 
	C2410.376221,565.439636 2392.829102,555.030457 2376.525635,542.168823 
	C2355.838623,525.848816 2339.189453,506.006531 2325.562012,483.761566 
	C2312.184570,461.925415 2302.715576,438.308960 2299.051758,412.739288 
	C2297.656982,403.004974 2296.586426,393.185364 2296.111572,383.368652 
	C2295.611572,373.026367 2295.323975,362.587646 2296.138184,352.285492 
	C2297.948486,329.385071 2303.456787,307.303894 2312.396240,286.064514 
	C2320.000488,267.997070 2330.186035,251.451324 2342.477783,236.368469 
	C2353.180908,223.235260 2364.736328,210.646545 2378.710205,200.813629 
	C2389.633789,193.127136 2400.655029,185.378555 2412.414062,179.127106 
	C2430.197266,169.673187 2449.427734,164.245056 2469.378418,160.780640 
	C2487.996826,157.547607 2506.689209,157.128021 2525.275391,158.250687 
	C2555.406738,160.070770 2584.376465,167.533279 2610.538574,183.100281 
	C2622.556152,190.250961 2633.836670,198.791443 2644.817871,207.500290 
	C2664.114502,222.803741 2679.075928,242.067474 2691.406738,263.168365 
	C2702.713135,282.515930 2711.345703,303.186462 2714.906738,325.493683 
	C2717.177246,339.716827 2719.451172,354.090942 2719.784180,368.442383 
	C2720.654053,405.930573 2712.693848,441.578339 2694.693359,474.760529 
	C2686.773926,489.359131 2677.214355,502.718475 2666.353760,515.293152 
	C2653.549072,530.118286 2639.185303,543.019104 2622.802490,553.722656 
	C2601.923828,567.363403 2579.104248,575.953735 2554.743652,581.126587 
	C2532.642822,585.819641 2510.350342,584.889526 2488.058105,584.955322 
	C2486.459229,584.960022 2484.858643,584.342529 2482.793457,584.008179 
M2606.938232,246.458176 
	C2602.215332,242.956284 2597.562744,239.354095 2592.757324,235.969635 
	C2570.855225,220.544601 2546.299561,212.103027 2519.781006,210.181870 
	C2500.182373,208.762024 2480.730225,210.634506 2461.724365,216.573029 
	C2429.640625,226.597778 2404.417236,246.327469 2383.747070,271.987823 
	C2360.112061,301.328613 2349.608643,335.711456 2349.224609,373.049713 
	C2349.105957,384.592072 2350.974365,396.290527 2353.119629,407.694244 
	C2358.853271,438.172455 2373.095215,464.547546 2393.938232,487.315125 
	C2403.183350,497.413513 2414.170166,505.458923 2425.931152,512.568787 
	C2449.291260,526.690674 2474.719971,533.641418 2501.740479,534.903320 
	C2517.066650,535.619141 2532.249512,533.777649 2547.183594,530.017395 
	C2573.752441,523.327637 2597.065430,510.719238 2616.763916,491.676056 
	C2632.029785,476.917725 2643.512939,459.549255 2652.061768,440.082550 
	C2660.939941,419.865570 2665.521484,398.719971 2665.801758,376.807831 
	C2665.943604,365.693298 2664.440918,354.493958 2662.929932,343.435638 
	C2659.897217,321.234650 2651.038818,301.201660 2638.980713,282.479645 
	C2630.304688,269.008087 2618.713867,258.132294 2606.938232,246.458176 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M2739.000000,501.000000 
	C2739.000000,389.870148 2739.000000,279.240326 2739.000000,168.305420 
	C2756.729492,168.305420 2774.115967,168.305420 2791.836426,168.305420 
	C2791.836426,191.721466 2791.836426,214.988449 2791.836426,239.451279 
	C2796.352783,234.263214 2799.962158,229.729797 2803.975830,225.588348 
	C2812.024902,217.282898 2819.790283,208.590576 2828.613281,201.177505 
	C2837.832275,193.431564 2847.897949,186.596542 2858.062012,180.095322 
	C2872.060059,171.141708 2887.711914,165.978317 2903.768066,161.996155 
	C2921.765381,157.532440 2940.162109,156.905380 2958.412354,158.213715 
	C2986.882324,160.254684 3012.930176,170.000534 3035.298828,187.965851 
	C3062.646484,209.929901 3079.887695,238.310852 3085.978271,273.004761 
	C3088.492432,287.326355 3090.892578,301.765961 3091.801514,316.248779 
	C3093.223633,338.901520 3093.809326,361.634491 3093.908447,384.337158 
	C3094.176025,445.665527 3094.000000,506.995789 3094.000000,568.325317 
	C3094.000000,570.310608 3094.000000,572.295837 3094.000000,574.639954 
	C3076.382324,574.639954 3058.995117,574.639954 3041.000000,574.639954 
	C3041.000000,572.722717 3041.000000,570.785583 3041.000000,568.848511 
	C3041.000000,499.519501 3041.117432,430.190094 3040.908936,360.861725 
	C3040.862793,345.492310 3040.082275,330.094086 3038.896484,314.766663 
	C3037.287842,293.969421 3033.079834,273.784668 3023.075195,255.092224 
	C3010.847168,232.246109 2992.174072,217.795410 2967.350098,211.014786 
	C2952.713379,207.016891 2937.686035,205.932129 2922.719971,207.157486 
	C2897.756592,209.201355 2875.185791,218.184418 2855.173096,233.342529 
	C2834.194336,249.232239 2818.047607,269.009094 2807.398438,293.155762 
	C2802.172363,305.005310 2798.506348,317.344086 2797.073242,330.206787 
	C2795.726318,342.298584 2794.885986,354.451324 2794.034424,366.592102 
	C2793.490479,374.344299 2793.090088,382.121918 2793.038330,389.890869 
	C2792.635498,450.320953 2792.333252,510.751709 2792.000732,571.182251 
	C2791.994629,572.300842 2792.000000,573.419434 2792.000000,574.766235 
	C2774.244873,574.766235 2756.976074,574.766235 2739.000000,574.766235 
	C2739.000000,550.338501 2739.000000,525.919250 2739.000000,501.000000 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M1108.000000,51.000000 
	C1108.000000,207.767929 1108.000000,364.035858 1108.000000,520.817505 
	C1178.296387,520.817505 1248.336792,520.817505 1318.687744,520.817505 
	C1318.687744,539.046204 1318.687744,556.762817 1318.687744,574.740479 
	C1229.980835,574.740479 1141.397583,574.740479 1052.407104,574.740479 
	C1052.407104,391.841003 1052.407104,208.827988 1052.407104,25.407398 
	C1070.522217,25.407398 1088.905884,25.407398 1108.000000,25.407398 
	C1108.000000,33.652588 1108.000000,42.076298 1108.000000,51.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M470.000000,174.103683 
	C470.000000,191.175339 470.000000,207.760880 470.000000,223.887329 
	C480.488007,212.274384 490.387451,199.974060 501.681458,189.125641 
	C518.102173,173.352814 537.412170,162.512558 560.419739,158.903931 
	C582.540833,155.434326 602.694275,160.485825 622.235962,171.875946 
	C613.047485,186.711121 603.818970,201.610901 595.040466,215.784225 
	C585.944519,213.706100 577.220764,211.032959 568.302490,209.923157 
	C562.693481,209.225143 556.548523,210.025330 551.064758,211.609589 
	C525.728821,218.929092 507.860657,235.539215 495.365692,258.270386 
	C486.838135,273.783936 480.657654,290.266327 477.285034,307.645996 
	C475.604370,316.306824 474.658630,325.161530 474.080597,333.974487 
	C472.816162,353.252625 471.723053,372.550903 471.074982,391.858215 
	C470.400818,411.942108 470.123383,432.045563 470.044647,452.141937 
	C469.891937,491.131165 470.000000,530.121460 470.000000,569.111267 
	C470.000000,570.903564 470.000000,572.695923 470.000000,574.741150 
	C451.969055,574.741150 434.368713,574.741150 416.383606,574.741150 
	C416.383606,439.454315 416.383606,304.091400 416.383606,168.364105 
	C433.936096,168.364105 451.654633,168.364105 470.000000,168.364105 
	C470.000000,170.084167 470.000000,171.850876 470.000000,174.103683 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M2200.000000,552.000000 
	C2200.000000,423.881104 2200.000000,296.262207 2200.000000,168.321777 
	C2217.694580,168.321777 2235.079590,168.321777 2252.733398,168.321777 
	C2252.733398,303.792480 2252.733398,439.162781 2252.733398,574.766602 
	C2235.245850,574.766602 2217.978516,574.766602 2200.000000,574.766602 
	C2200.000000,567.350159 2200.000000,559.925110 2200.000000,552.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M482.042664,887.097534 
	C477.002747,902.474121 471.928925,917.423584 467.080444,932.445740 
	C466.227509,935.088379 465.114197,936.188843 462.294006,936.056152 
	C457.812714,935.845276 453.314240,935.999878 448.365509,935.999878 
	C434.924561,892.894897 421.490265,849.811157 407.835876,806.021667 
	C414.308350,806.021667 420.403931,805.695251 426.421844,806.235107 
	C428.071411,806.382996 430.318909,808.871704 430.884766,810.717590 
	C437.410706,832.005798 443.700012,853.367065 449.975677,874.731384 
	C452.495392,883.309204 454.837769,891.939209 457.234222,900.552856 
	C457.669037,902.115723 457.974457,903.714539 458.349701,905.340454 
	C467.551910,877.872864 476.748566,850.409851 485.956207,822.950623 
	C487.351959,818.788208 488.728912,814.617126 490.244690,810.497986 
	C491.361206,807.463928 493.245331,805.767395 496.929260,805.925293 
	C508.719879,806.430786 507.329742,803.412048 511.967438,817.322144 
	C521.387268,845.575562 530.729126,873.854919 540.119263,902.118225 
	C540.211060,902.394409 540.522095,902.597778 541.074707,903.226562 
	C543.413940,895.669678 545.765503,888.397217 547.914734,881.065430 
	C553.037048,863.591187 558.040833,846.082336 563.142090,828.601868 
	C564.871826,822.674744 566.921936,816.836304 568.498596,810.870911 
	C569.488281,807.126587 571.806946,805.951965 575.311035,805.987488 
	C580.304321,806.038086 585.298401,806.000366 590.662109,806.000366 
	C587.942261,814.744812 585.290222,823.235718 582.660034,831.733398 
	C572.372009,864.972778 562.074158,898.209106 551.833984,931.463257 
	C550.856262,934.638306 549.478271,936.192078 545.657043,936.122803 
	C532.991333,935.893188 533.006958,936.088928 528.921570,923.801575 
	C521.579224,901.718445 514.194824,879.649292 506.863159,857.562683 
	C504.411133,850.175964 502.066742,842.753601 499.580902,835.062134 
	C493.672424,852.499329 487.878906,869.597229 482.042664,887.097534 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M987.001465,806.000000 
	C1000.164368,806.000000 1012.831726,805.792603 1025.489258,806.056763 
	C1038.852295,806.335632 1050.646484,811.204102 1061.016357,819.533325 
	C1076.590942,832.043152 1082.600708,848.799866 1083.073608,868.274658 
	C1083.481934,885.087463 1079.745605,900.339417 1069.109741,913.748169 
	C1060.215454,924.961060 1048.569458,932.271301 1034.812378,933.679993 
	C1013.599243,935.852173 992.173096,935.944031 970.396362,936.938416 
	C970.396362,892.798584 970.396362,849.752197 970.396362,806.000000 
	C975.648193,806.000000 981.074890,806.000000 987.001465,806.000000 
M993.684814,822.000244 
	C989.729797,821.242249 989.974426,823.825134 989.977600,826.469910 
	C990.013123,856.279419 989.996338,886.088928 990.013306,915.898438 
	C990.014038,917.170166 990.206116,918.441711 990.251282,919.000000 
	C1001.870056,919.000000 1013.120361,919.436646 1024.321655,918.882202 
	C1036.542358,918.277283 1046.763306,912.896545 1053.965942,902.871826 
	C1064.166870,888.674133 1064.858887,872.449524 1061.798096,856.155212 
	C1057.955322,835.698608 1041.521362,821.348572 1020.126343,821.960205 
	C1011.640076,822.202820 1003.141113,822.000000 993.684814,822.000244 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2664.961426,806.000000 
	C2674.363037,803.849548 2676.803223,811.621399 2680.676025,816.490784 
	C2694.312012,833.636536 2707.570801,851.082947 2720.925537,868.451782 
	C2729.375977,879.442505 2737.741699,890.498535 2746.814941,902.403625 
	C2746.814941,869.823364 2746.814941,838.198364 2746.814941,806.288574 
	C2752.756348,806.288574 2758.141357,806.288574 2763.764648,806.288574 
	C2763.764648,849.395996 2763.764648,892.313049 2763.764648,935.769104 
	C2756.507568,936.037781 2749.941406,936.830566 2744.437744,929.255371 
	C2729.645508,908.893921 2714.007080,889.145264 2698.614990,869.222351 
	C2691.134277,859.539551 2683.453125,850.011414 2675.125000,839.480347 
	C2675.125000,872.197205 2675.125000,903.838501 2675.125000,935.737427 
	C2669.314453,935.737427 2664.044922,935.737427 2658.385742,935.737427 
	C2658.385742,892.788330 2658.385742,849.751648 2658.385742,806.000000 
	C2660.313965,806.000000 2662.401611,806.000000 2664.961426,806.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M757.000000,835.000000 
	C757.000000,825.199036 757.000000,815.898071 757.000000,806.000000 
	C759.776855,806.000000 762.214355,806.000000 764.651855,806.000000 
	C777.481873,806.000000 790.313477,805.875305 803.141357,806.041260 
	C812.644958,806.164185 821.400330,808.978699 829.528503,813.987305 
	C840.842407,820.958923 844.574280,837.092773 842.581787,847.826660 
	C839.589905,863.944580 831.562195,872.541504 813.893616,878.838379 
	C827.492065,897.527039 841.044739,916.152832 855.445862,935.944641 
	C848.203552,935.944641 842.620422,936.116516 837.052185,935.902344 
	C829.673828,935.618408 827.615112,928.875854 824.183472,924.267151 
	C814.832581,911.708862 806.175049,898.634277 796.831116,886.070496 
	C795.244812,883.937500 791.734314,882.673767 788.905151,882.166687 
	C785.225220,881.507080 781.337646,882.005493 777.200012,882.005493 
	C777.200012,899.993713 777.200012,917.597229 777.200012,935.599426 
	C770.479187,935.599426 764.088074,935.599426 757.000000,935.599426 
	C757.000000,902.329834 757.000000,868.914917 757.000000,835.000000 
M777.000000,860.430786 
	C777.000000,862.859009 777.000000,865.287231 777.000000,867.995605 
	C783.954407,867.995605 790.295410,868.430969 796.551514,867.888062 
	C804.801147,867.172180 812.483643,864.869873 818.379211,858.222107 
	C826.838745,848.683044 825.348022,830.081299 812.272156,824.533875 
	C800.785583,819.660767 789.078796,821.483643 777.000000,821.027100 
	C777.000000,834.208618 777.000000,846.829224 777.000000,860.430786 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1778.000000,868.000000 
	C1778.000000,847.203308 1778.000000,826.906616 1778.000000,806.000000 
	C1785.092407,806.000000 1791.861450,806.000000 1798.630493,806.000000 
	C1807.293945,806.000000 1815.985962,805.555176 1824.613892,806.108948 
	C1836.243042,806.855469 1847.260498,809.725098 1855.466797,818.967102 
	C1860.076538,824.158569 1862.509521,830.318787 1862.967041,837.177612 
	C1863.662231,847.599426 1862.139893,857.364685 1855.117065,865.837646 
	C1850.110107,871.878418 1843.551758,875.129456 1836.605103,877.994568 
	C1835.878052,878.294434 1835.153442,878.600403 1834.343262,878.938782 
	C1848.047607,897.751770 1861.619263,916.382690 1875.867920,935.942993 
	C1868.859619,935.942993 1862.858032,936.595032 1857.105835,935.679626 
	C1854.031128,935.190430 1850.629028,932.561279 1848.674194,929.931702 
	C1838.254028,915.914246 1828.097046,901.692993 1818.193726,887.305054 
	C1815.631226,883.582214 1812.586060,882.070007 1808.425903,882.010864 
	C1804.796265,881.959290 1801.165283,882.000183 1797.197266,882.000183 
	C1797.197266,899.997986 1797.197266,917.599670 1797.197266,935.599731 
	C1790.812256,935.599731 1784.755737,935.599731 1778.000000,935.599731 
	C1778.000000,913.331787 1778.000000,890.915894 1778.000000,868.000000 
M1825.501221,866.123535 
	C1833.680054,864.513062 1839.423218,859.970398 1842.418213,852.100281 
	C1847.013184,840.025330 1842.476074,826.621704 1830.122559,823.460449 
	C1819.644775,820.779236 1808.392578,821.124329 1797.338379,820.117126 
	C1797.338379,836.963684 1797.338379,852.346863 1797.338379,868.586060 
	C1806.762451,867.919678 1815.798096,867.280762 1825.501221,866.123535 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M230.000000,925.968384 
	C230.000000,929.435852 230.000000,932.409363 230.000000,935.687073 
	C223.627167,935.687073 217.576080,935.687073 211.176651,935.687073 
	C211.176651,916.310852 211.176651,897.046692 211.176651,877.392212 
	C188.793152,877.392212 166.747116,877.392212 144.179016,877.392212 
	C144.179016,896.625183 144.179016,916.010498 144.179016,935.694946 
	C137.277664,935.694946 130.893158,935.694946 124.252289,935.694946 
	C124.252289,892.636597 124.252289,849.718201 124.252289,806.399658 
	C130.521667,806.399658 136.910294,806.399658 143.826569,806.399658 
	C143.826569,824.963684 143.826569,843.682861 143.826569,862.697876 
	C166.390289,862.697876 188.435242,862.697876 210.825073,862.697876 
	C210.825073,843.976013 210.825073,825.378296 210.825073,806.391235 
	C217.205429,806.391235 223.260727,806.391235 230.000000,806.391235 
	C230.000000,846.005188 230.000000,885.739807 230.000000,925.968384 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2893.304688,903.710327 
	C2897.637207,914.448364 2901.744141,924.862854 2906.127686,935.978394 
	C2900.708008,935.978394 2895.545898,936.572205 2890.629395,935.730530 
	C2888.131836,935.302917 2885.061279,932.742615 2884.000977,930.365417 
	C2879.772217,920.882202 2876.231445,911.092163 2872.274902,901.004639 
	C2854.485840,901.004639 2836.355957,900.955322 2818.228516,901.126160 
	C2817.133301,901.136475 2815.529053,902.687927 2815.051270,903.879028 
	C2811.542480,912.626465 2808.217773,921.448975 2804.928467,930.282715 
	C2803.631592,933.764832 2801.060303,935.676147 2797.589111,935.894653 
	C2792.825195,936.194519 2788.028564,935.969543 2782.310059,935.969543 
	C2799.752686,892.249573 2816.906738,849.252380 2834.162354,806.001404 
	C2839.953613,806.001404 2845.604004,805.786377 2851.217773,806.144165 
	C2852.724854,806.240234 2854.887939,807.769287 2855.454834,809.166016 
	C2867.071533,837.790771 2878.504395,866.490112 2889.960449,895.179932 
	C2891.045654,897.897339 2892.041504,900.650330 2893.304688,903.710327 
M2840.079834,837.731445 
	C2833.995850,853.953125 2827.912109,870.174744 2821.684570,886.779785 
	C2836.989990,886.779785 2851.705811,886.779785 2866.905273,886.779785 
	C2859.275879,866.850403 2851.741699,847.169006 2844.137207,827.304626 
	C2842.932373,830.499573 2841.699707,833.767761 2840.079834,837.731445 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M294.043854,822.000000 
	C290.909119,822.000000 288.268097,822.000000 285.317322,822.000000 
	C285.317322,835.361633 285.317322,848.410278 285.317322,861.805542 
	C301.336334,861.805542 317.268341,861.805542 333.599182,861.805542 
	C333.599182,867.182495 333.599182,872.240662 333.599182,877.826477 
	C317.704956,877.826477 301.651154,877.826477 285.301941,877.826477 
	C285.301941,891.726257 285.301941,905.107788 285.301941,918.832886 
	C305.366150,918.832886 325.295532,918.832886 345.611572,918.832886 
	C345.611572,924.543091 345.611572,929.933044 345.611572,935.664795 
	C319.013794,935.664795 292.304810,935.664795 265.297241,935.664795 
	C265.297241,892.609985 265.297241,849.575745 265.297241,806.270508 
	C292.118317,806.270508 318.826874,806.270508 345.767883,806.270508 
	C345.767883,811.415344 345.767883,816.351257 345.767883,822.000000 
	C328.693542,822.000000 311.615570,822.000000 294.043854,822.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2177.000000,806.000000 
	C2193.466553,806.000000 2209.433105,806.000000 2225.698730,806.000000 
	C2225.698730,811.398315 2225.698730,816.451538 2225.698730,821.859131 
	C2205.610840,821.859131 2185.674805,821.859131 2165.370605,821.859131 
	C2165.370605,835.255737 2165.370605,848.309570 2165.370605,861.894897 
	C2181.696777,861.894897 2198.089111,861.894897 2214.736328,861.894897 
	C2214.736328,867.350525 2214.736328,872.288940 2214.736328,877.759033 
	C2198.443359,877.759033 2182.051025,877.759033 2165.332031,877.759033 
	C2165.332031,891.668762 2165.332031,905.054993 2165.332031,918.795227 
	C2185.656738,918.795227 2205.925537,918.795227 2226.333496,918.795227 
	C2226.333496,924.626892 2226.333496,930.083435 2226.333496,935.769897 
	C2199.378906,935.769897 2173.088867,935.769897 2146.398926,935.769897 
	C2146.398926,892.802185 2146.398926,849.753784 2146.398926,806.000000 
	C2156.320068,806.000000 2166.410156,806.000000 2177.000000,806.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1711.855469,806.000000 
	C1727.244385,807.228760 1740.947388,811.074219 1748.740967,825.328430 
	C1759.730347,845.427368 1753.444580,875.150879 1728.368042,884.073792 
	C1717.390991,887.979614 1706.130005,888.156006 1694.755615,888.006042 
	C1691.762573,887.966553 1688.768433,888.000000 1685.000000,888.000000 
	C1685.000000,896.067627 1685.000000,903.985596 1685.000000,911.903564 
	C1685.000000,919.714966 1685.000000,927.526428 1685.000000,935.667725 
	C1678.666504,935.667725 1672.611938,935.667725 1666.275635,935.667725 
	C1666.275635,892.666931 1666.275635,849.731628 1666.275635,806.000000 
	C1681.218994,806.000000 1696.296997,806.000000 1711.855469,806.000000 
M1686.762329,871.979736 
	C1696.012329,871.645752 1705.477051,872.429565 1714.459595,870.703430 
	C1726.512695,868.387451 1731.987183,861.512756 1733.894775,850.218933 
	C1735.766724,839.136292 1731.333862,829.345825 1722.022461,824.720459 
	C1710.308472,818.901611 1697.612915,821.363525 1685.018921,821.057312 
	C1685.018921,837.867004 1685.010742,854.009460 1685.052368,870.151917 
	C1685.053833,870.722412 1685.550293,871.291626 1686.762329,871.979736 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2060.000000,859.867065 
	C2060.087646,861.794128 2060.175537,863.236084 2060.283203,865.005920 
	C2077.537354,865.005920 2094.475098,865.005920 2111.703857,865.005920 
	C2111.703857,870.408752 2111.703857,875.462219 2111.703857,880.872314 
	C2094.598877,880.872314 2077.660889,880.872314 2060.196533,880.872314 
	C2060.196533,899.270386 2060.196533,917.329346 2060.196533,935.691040 
	C2053.284180,935.691040 2046.897217,935.691040 2040.253174,935.691040 
	C2040.253174,892.624329 2040.253174,849.692139 2040.253174,806.379761 
	C2066.906006,806.379761 2093.625244,806.379761 2120.672852,806.379761 
	C2120.672852,811.345581 2120.672852,816.399475 2120.672852,821.810181 
	C2100.661133,821.810181 2080.723877,821.810181 2060.000000,821.810181 
	C2060.000000,834.557861 2060.000000,846.969910 2060.000000,859.867065 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1375.984741,822.000000 
	C1359.522339,822.000000 1343.556641,822.000000 1327.296875,822.000000 
	C1327.296875,836.408264 1327.296875,850.460693 1327.296875,864.866577 
	C1344.395996,864.866577 1361.333130,864.866577 1378.633667,864.866577 
	C1378.633667,870.261536 1378.633667,875.315674 1378.633667,880.908020 
	C1361.624268,880.908020 1344.565063,880.908020 1327.162598,880.908020 
	C1327.162598,899.366821 1327.162598,917.303711 1327.162598,935.619141 
	C1320.436768,935.619141 1314.046875,935.619141 1307.325317,935.619141 
	C1307.325317,892.652588 1307.325317,849.604126 1307.325317,806.277466 
	C1333.993286,806.277466 1360.593262,806.277466 1387.596924,806.277466 
	C1387.596924,811.179443 1387.596924,816.239380 1387.596924,822.000000 
	C1384.001953,822.000000 1380.241699,822.000000 1375.984741,822.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1540.000000,866.000000 
	C1540.000000,851.203918 1540.000000,836.907898 1540.000000,822.091309 
	C1525.953979,822.091309 1512.231445,822.091309 1498.259277,822.091309 
	C1498.259277,816.641418 1498.259277,811.704773 1498.259277,806.382324 
	C1531.887451,806.382324 1565.594604,806.382324 1599.651611,806.382324 
	C1599.651611,811.297974 1599.651611,816.351624 1599.651611,821.933594 
	C1586.263062,821.933594 1572.874512,821.933594 1559.163574,821.933594 
	C1559.163574,860.041565 1559.163574,897.629211 1559.163574,935.607910 
	C1552.795166,935.607910 1546.740234,935.607910 1540.000000,935.607910 
	C1540.000000,912.656128 1540.000000,889.578064 1540.000000,866.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M893.000000,899.999512 
	C893.000000,906.464355 893.000000,912.429321 893.000000,918.899536 
	C911.708618,918.899536 930.087097,918.899536 948.729248,918.899536 
	C948.729248,924.666931 948.729248,929.934509 948.729248,935.603027 
	C924.147766,935.603027 899.445312,935.603027 874.370605,935.603027 
	C874.370605,892.773132 874.370605,849.753235 874.370605,806.366211 
	C880.256653,806.366211 886.308472,806.366211 893.000000,806.366211 
	C893.000000,837.375122 893.000000,868.437378 893.000000,899.999512 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2944.000000,912.896118 
	C2944.000000,915.027222 2944.000000,916.672485 2944.000000,918.828308 
	C2962.303467,918.828308 2980.349365,918.828308 2998.694824,918.828308 
	C2998.694824,924.720215 2998.694824,930.103516 2998.694824,935.745117 
	C2974.310059,935.745117 2950.058105,935.745117 2925.402832,935.745117 
	C2925.402832,892.836243 2925.402832,849.813843 2925.402832,806.395386 
	C2931.195557,806.395386 2937.248779,806.395386 2944.000000,806.395386 
	C2944.000000,841.640137 2944.000000,877.025208 2944.000000,912.896118 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M1465.044434,806.000000 
	C1469.163818,806.000000 1472.796265,806.000000 1476.711914,806.000000 
	C1476.711914,849.416321 1476.711914,892.458557 1476.711914,935.750610 
	C1470.285156,935.750610 1464.015991,935.750610 1457.371948,935.750610 
	C1457.371948,892.752930 1457.371948,849.710266 1457.371948,806.000000 
	C1459.700317,806.000000 1462.128906,806.000000 1465.044434,806.000000 
z"/>
      <path fill="#307ABB" opacity="1.000000" stroke="none"
        d="
M2475.000000,858.000000 
	C2475.000000,884.126526 2475.000000,909.753113 2475.000000,935.689148 
	C2468.623291,935.689148 2462.570312,935.689148 2456.257324,935.689148 
	C2456.257324,892.643127 2456.257324,849.721130 2456.257324,806.399292 
	C2462.188965,806.399292 2468.245117,806.399292 2475.000000,806.399292 
	C2475.000000,823.333435 2475.000000,840.416687 2475.000000,858.000000 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M676.493896,822.234863 
	C694.024353,826.588928 704.310120,837.540283 708.186829,854.540649 
	C712.040894,871.441650 711.312561,887.741333 701.093079,902.640076 
	C695.014404,911.502014 686.511475,916.733765 676.209717,918.804565 
	C663.384460,921.382568 651.176025,919.574341 640.144470,912.032837 
	C630.998901,905.780579 625.424011,896.786743 623.378540,886.326904 
	C620.477295,871.490967 620.344482,856.496887 628.108704,842.672119 
	C634.780701,830.792175 644.748718,823.363159 658.537598,822.090698 
	C664.342285,821.554871 670.241577,822.043152 676.493896,822.234863 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M2604.014404,898.934692 
	C2597.084961,910.948792 2586.753906,917.016907 2573.526367,919.096252 
	C2558.185303,921.507996 2545.045410,917.518005 2534.166992,906.574341 
	C2528.020020,900.390564 2524.725098,892.397339 2523.205078,884.049500 
	C2520.887695,871.321777 2521.413574,858.735046 2526.719238,846.466492 
	C2532.933838,832.096191 2543.714844,824.584717 2558.854248,821.888367 
	C2572.535156,819.451721 2584.439453,823.043518 2595.157471,831.113647 
	C2602.200684,836.416931 2605.750244,844.148926 2608.199951,852.490051 
	C2612.879883,868.424683 2611.733887,883.766113 2604.014404,898.934692 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M1207.208252,822.005493 
	C1219.081665,819.570679 1229.987061,821.624817 1239.743774,827.526917 
	C1249.832397,833.629639 1255.965088,843.093323 1258.347656,854.758728 
	C1261.807373,871.697815 1261.218628,888.129700 1250.962036,902.879028 
	C1244.471680,912.212830 1235.244141,917.234558 1223.954712,919.090088 
	C1211.541626,921.130371 1200.073608,919.013672 1189.912720,911.878723 
	C1178.630493,903.956482 1173.463867,892.376282 1171.943726,878.846436 
	C1170.716797,867.928101 1171.646118,857.429077 1175.890625,847.212463 
	C1181.145264,834.564087 1190.140259,826.330017 1203.494385,822.977417 
	C1204.597412,822.700562 1205.678101,822.334534 1207.208252,822.005493 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M1984.738037,902.627808 
	C1975.382446,914.858215 1962.909058,920.382568 1948.279297,919.857361 
	C1926.312622,919.068787 1910.130981,906.634460 1906.014038,883.137817 
	C1903.609863,869.415894 1904.383301,855.991028 1910.947266,843.390564 
	C1917.447998,830.911743 1927.877930,824.223511 1941.742798,821.847595 
	C1950.675415,820.316895 1959.003662,821.494446 1967.151489,824.685547 
	C1978.975464,829.316589 1986.666382,838.162537 1990.475830,850.073303 
	C1995.581421,866.035828 1994.968506,881.887024 1987.918457,897.272766 
	C1987.109497,899.038513 1985.939575,900.638855 1984.738037,902.627808 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M2081.253906,257.753998 
	C2095.649170,272.749969 2106.025879,289.854645 2112.902100,309.030731 
	C2117.988770,323.216339 2120.837646,337.847321 2122.152100,352.946655 
	C2125.023193,385.930267 2120.149170,417.421295 2104.909424,446.854370 
	C2092.639893,470.551270 2074.505371,489.040741 2051.581055,502.809021 
	C2033.696289,513.550598 2014.197510,519.875549 1993.739624,522.754089 
	C1981.359619,524.496033 1968.661499,525.352966 1956.189819,524.782715 
	C1930.389282,523.602966 1905.347656,518.570862 1882.256226,506.087097 
	C1864.346069,496.404327 1849.293335,483.427673 1836.283813,467.855530 
	C1820.405884,448.850037 1810.193726,426.998718 1806.389526,402.806122 
	C1803.931885,387.177277 1803.038574,370.904449 1804.355347,355.163727 
	C1807.011353,323.414276 1819.014282,294.841949 1838.799194,269.674042 
	C1852.069580,252.793152 1868.434937,239.747879 1887.136108,229.312195 
	C1903.409546,220.231155 1920.756714,214.158386 1938.986572,211.290161 
	C1952.421021,209.176392 1966.018555,208.516098 1979.858765,209.706314 
	C2002.789185,211.678238 2024.022095,218.196793 2044.072876,229.051605 
	C2057.891113,236.532364 2070.343506,245.876968 2081.253906,257.753998 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M766.070251,401.911560 
	C759.520813,389.013062 749.295471,380.741730 735.126221,377.584778 
	C711.289185,372.273804 687.070312,385.080322 681.109070,410.706421 
	C676.519714,430.434967 683.525330,446.674896 699.911438,458.378906 
	C701.450439,459.478210 703.075439,460.457153 704.082703,461.114746 
	C696.467651,466.842468 688.638489,472.459015 681.152832,478.501160 
	C678.322754,480.785492 676.574768,480.780212 674.478333,477.977264 
	C659.274658,457.650055 647.880066,435.454010 642.402039,410.570312 
	C640.545044,402.134796 639.633667,393.416962 639.124451,384.778931 
	C638.574280,375.445648 638.414734,366.009827 639.130615,356.699127 
	C640.348267,340.861755 644.157043,325.504181 650.073547,310.731567 
	C656.506592,294.669586 665.364990,279.885223 676.782043,267.041779 
	C688.936768,253.368469 702.136963,240.630219 718.218018,231.262772 
	C733.725647,222.229385 750.055359,215.330048 767.707886,212.283356 
	C782.510193,209.728592 797.453064,209.380722 812.564026,210.789581 
	C832.442383,212.642914 850.886780,218.368912 868.352234,227.742630 
	C887.170776,237.842514 902.696838,251.807785 916.576965,267.913666 
	C925.740173,278.546265 933.268555,290.115356 939.281738,302.716766 
	C946.423157,317.682495 951.224854,333.374359 952.964905,349.896149 
	C953.878113,358.566833 955.073608,367.303894 954.855835,375.978729 
	C954.298035,398.200897 950.660278,419.987183 940.821594,440.127899 
	C935.193237,451.649567 928.159790,462.485535 921.734924,473.616364 
	C921.159668,474.612976 920.407288,475.507294 919.898926,476.221680 
	C912.767090,470.885132 906.248535,466.007507 899.819641,461.196930 
	C903.508789,457.963104 907.861816,454.520081 911.784241,450.641571 
	C925.556396,437.023590 926.646301,410.988464 915.080933,395.384918 
	C905.743713,382.787506 893.372803,376.034943 878.243591,376.253174 
	C860.184082,376.513702 845.898315,384.742004 836.743042,401.506775 
	C833.331299,399.984894 830.398010,398.231232 827.331848,396.398132 
	C859.522217,380.411469 867.244751,342.806946 851.440735,316.207672 
	C833.955383,286.778656 793.997375,277.860321 764.416748,301.342926 
	C752.498596,310.804199 745.317993,323.668732 744.185913,338.800751 
	C742.273254,364.366272 752.784851,384.063599 775.795349,397.101776 
	C771.910645,399.023071 768.990417,400.467316 766.070251,401.911560 
z"/>
      <path fill="#18A1DB" opacity="1.000000" stroke="none"
        d="
M766.041870,402.280792 
	C768.990417,400.467316 771.910645,399.023071 775.795349,397.101776 
	C752.784851,384.063599 742.273254,364.366272 744.185913,338.800751 
	C745.317993,323.668732 752.498596,310.804199 764.416748,301.342926 
	C793.997375,277.860321 833.955383,286.778656 851.440735,316.207672 
	C867.244751,342.806946 859.522217,380.411469 827.331848,396.398132 
	C830.398010,398.231232 833.331299,399.984894 836.597534,401.824615 
	C838.267944,404.083160 839.202759,406.692627 841.008667,408.356445 
	C851.770630,418.271332 859.800049,429.886505 863.466187,444.143280 
	C864.813843,449.384064 865.826721,454.861206 865.915039,460.248169 
	C866.215393,478.559448 865.971130,496.879120 866.101013,515.194397 
	C866.122559,518.230652 865.326050,519.992126 862.435425,521.353638 
	C848.356812,527.984863 833.505249,532.174133 818.163818,533.798279 
	C805.823669,535.104675 793.259827,535.427063 780.865540,534.798706 
	C766.282715,534.059387 752.186157,530.043762 738.529175,524.846436 
	C737.336243,524.392456 736.125305,522.146301 736.110046,520.719910 
	C735.944763,505.236298 735.198853,489.691193 736.308838,474.285156 
	C737.063293,463.813019 735.731384,453.140106 739.437500,442.757629 
	C744.195435,429.428864 751.381470,418.025452 761.825806,408.536743 
	C763.567139,406.954742 764.634583,404.630981 766.041870,402.280792 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M1554.559570,211.269165 
	C1581.807373,216.852280 1606.144409,227.846619 1627.430908,245.064713 
	C1646.470581,260.465424 1659.320679,280.441010 1667.916138,303.198883 
	C1670.728516,310.644867 1672.971436,318.307159 1675.413330,325.890320 
	C1675.788574,327.055267 1675.830811,328.327362 1676.066528,329.795776 
	C1575.815308,329.795776 1476.061035,329.795776 1375.217896,329.795776 
	C1378.396729,320.913513 1381.060425,312.412903 1384.448242,304.211487 
	C1393.165527,283.109131 1404.374878,263.501740 1421.557251,248.035950 
	C1441.855103,229.765686 1465.250000,217.488068 1492.193359,211.870499 
	C1507.432129,208.693283 1522.734741,208.873322 1538.079834,209.099152 
	C1543.449829,209.178192 1548.803345,210.386154 1554.559570,211.269165 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M78.000000,155.000000 
	C78.000000,129.874939 78.000000,105.249870 78.000000,80.000000 
	C80.081757,80.000000 81.847961,80.000000 83.614166,80.000000 
	C119.592995,80.000000 155.580505,79.531654 191.546967,80.198967 
	C209.343506,80.529160 227.173981,82.052979 244.587799,86.754280 
	C264.650360,92.170685 280.076111,103.638657 292.005096,120.437782 
	C305.173767,138.982727 310.088898,159.862442 307.794708,181.959595 
	C303.756561,220.854462 277.465424,251.968765 238.516861,259.883423 
	C226.214813,262.383301 213.632950,264.385773 201.119873,264.792572 
	C171.058121,265.769775 140.961761,265.824371 110.878357,265.915710 
	C100.144646,265.948303 89.407990,265.014343 78.000000,264.490723 
	C78.000000,228.310791 78.000000,191.905396 78.000000,155.000000 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M2607.192383,246.711761 
	C2618.713867,258.132294 2630.304688,269.008087 2638.980713,282.479645 
	C2651.038818,301.201660 2659.897217,321.234650 2662.929932,343.435638 
	C2664.440918,354.493958 2665.943604,365.693298 2665.801758,376.807831 
	C2665.521484,398.719971 2660.939941,419.865570 2652.061768,440.082550 
	C2643.512939,459.549255 2632.029785,476.917725 2616.763916,491.676056 
	C2597.065430,510.719238 2573.752441,523.327637 2547.183594,530.017395 
	C2532.249512,533.777649 2517.066650,535.619141 2501.740479,534.903320 
	C2474.719971,533.641418 2449.291260,526.690674 2425.931152,512.568787 
	C2414.170166,505.458923 2403.183350,497.413513 2393.938232,487.315125 
	C2373.095215,464.547546 2358.853271,438.172455 2353.119629,407.694244 
	C2350.974365,396.290527 2349.105957,384.592072 2349.224609,373.049713 
	C2349.608643,335.711456 2360.112061,301.328613 2383.747070,271.987823 
	C2404.417236,246.327469 2429.640625,226.597778 2461.724365,216.573029 
	C2480.730225,210.634506 2500.182373,208.762024 2519.781006,210.181870 
	C2546.299561,212.103027 2570.855225,220.544601 2592.757324,235.969635 
	C2597.562744,239.354095 2602.215332,242.956284 2607.192383,246.711761 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M994.166382,822.000122 
	C1003.141113,822.000000 1011.640076,822.202820 1020.126343,821.960205 
	C1041.521362,821.348572 1057.955322,835.698608 1061.798096,856.155212 
	C1064.858887,872.449524 1064.166870,888.674133 1053.965942,902.871826 
	C1046.763306,912.896545 1036.542358,918.277283 1024.321655,918.882202 
	C1013.120361,919.436646 1001.870056,919.000000 990.251282,919.000000 
	C990.206116,918.441711 990.014038,917.170166 990.013306,915.898438 
	C989.996338,886.088928 990.013123,856.279419 989.977600,826.469910 
	C989.974426,823.825134 989.729797,821.242249 994.166382,822.000122 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M777.000000,859.940308 
	C777.000000,846.829224 777.000000,834.208618 777.000000,821.027100 
	C789.078796,821.483643 800.785583,819.660767 812.272156,824.533875 
	C825.348022,830.081299 826.838745,848.683044 818.379211,858.222107 
	C812.483643,864.869873 804.801147,867.172180 796.551514,867.888062 
	C790.295410,868.430969 783.954407,867.995605 777.000000,867.995605 
	C777.000000,865.287231 777.000000,862.859009 777.000000,859.940308 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M1825.167480,866.382690 
	C1815.798096,867.280762 1806.762451,867.919678 1797.338379,868.586060 
	C1797.338379,852.346863 1797.338379,836.963684 1797.338379,820.117126 
	C1808.392578,821.124329 1819.644775,820.779236 1830.122559,823.460449 
	C1842.476074,826.621704 1847.013184,840.025330 1842.418213,852.100281 
	C1839.423218,859.970398 1833.680054,864.513062 1825.167480,866.382690 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M2840.273438,837.383728 
	C2841.699707,833.767761 2842.932373,830.499573 2844.137207,827.304626 
	C2851.741699,847.169006 2859.275879,866.850403 2866.905273,886.779785 
	C2851.705811,886.779785 2836.989990,886.779785 2821.684570,886.779785 
	C2827.912109,870.174744 2833.995850,853.953125 2840.273438,837.383728 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M1686.289185,871.920593 
	C1685.550293,871.291626 1685.053833,870.722412 1685.052368,870.151917 
	C1685.010742,854.009460 1685.018921,837.867004 1685.018921,821.057312 
	C1697.612915,821.363525 1710.308472,818.901611 1722.022461,824.720459 
	C1731.333862,829.345825 1735.766724,839.136292 1733.894775,850.218933 
	C1731.987183,861.512756 1726.512695,868.387451 1714.459595,870.703430 
	C1705.477051,872.429565 1696.012329,871.645752 1686.289185,871.920593 
z"/>
    </svg>
  )
}