import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import { StatusPill } from "Components/StatusPill";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useMemo, useState } from "react";
import moment from "moment";

export function JobBadge({ jobDetails, timesheet = {}, goBack }) {
  const [toggleDescription, setToggleDescription] = useState(false);
  
  // const descriptionText = useMemo(() => {
  //   return new DOMParser().parseFromString(jobDetails?.description, "text/html");
  // }, [jobDetails?.description]);

  const descriptionText = useMemo(() => {
    // return new DOMParser().parseFromString(jobDetails?.description, "text/html");
    const descriptionLines = jobDetails?.description?.split('</p>');
    return toggleDescription ? jobDetails?.description : descriptionLines?.slice(0, 1)?.join('</p>');
  }, [toggleDescription, jobDetails?.description])

  const handleToggleDescriptionText = ()=> {
    setToggleDescription(!toggleDescription);
  }

  return (
    <Box>
      <Typography marginBottom={"40px"} sx={{ cursor: 'pointer' }} onClick={goBack}>
        <ArrowBackIcon
          sx={{
            background: "#F3F4F6",
            width: "30px",
            marginRight: "20px",
            height: "30px",
            borderRadius: "8px",
          }}
        />
        Back
      </Typography>
      <Stack direction="row" gap={1} alignItems="center">
        <Avatar
          sx={{ width: "50px", height: "50px", mr: 2 }}
          src={jobDetails?.owner?.profilePhoto}
        />
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
        >
          <Typography variant="h5SemiBold" sx={{ color: "#182743" }}>
            {jobDetails?.name}
          </Typography>
          {timesheet && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              gap={1}
            >
              <StatusPill status={timesheet?.status} />
              {/* <Typography variant="body2Normal" color="#6B7280">
              Last updated on{" "}
              {new Date(timesheet?.updatedAt).toLocaleDateString()}
              {(timesheet?.approvedBy || timesheet?.approvedByExternal) && (
                <>
                  {" "}
                  by{" "}
                  {timesheet.approvedByExternal ||
                    `${timesheet.approvedBy?.firstName} ${timesheet.approvedBy?.lastName}`}
                </>
              )}
            </Typography> */}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction="row" gap={1} alignItems="center" marginLeft={"75px"}>
          {(jobDetails?.owner?.company || jobDetails?.owner?.first_name) && (
            <>
              <Typography variant="body2Normal" sx={{ color: "#6B7280" }}>
                {jobDetails?.owner?.company || jobDetails?.owner?.first_name}
              </Typography>
              {`\u2022`}
              <Typography variant="body2Normal">
                {jobDetails?.location?.charAt(0)?.toUpperCase()}
                {jobDetails?.location?.slice(1)?.toLowerCase()}
              </Typography>
            </>
          )}
        </Stack>
        <Typography variant="body2Normal">
          Posted on{" "} {moment(new Date(jobDetails?.createdAt)).format('DD MMM, YYYY')}
        </Typography>
      </Stack>
      {Object.keys(timesheet).length > 0 && (
        <Box
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {jobDetails?.description && (
            <Box>
              <div
                dangerouslySetInnerHTML={{__html: descriptionText}}
              />
              {/* <div>{toggleDescription ? descriptionText.firstChild.textContent : descriptionText.firstChild.textContent.substring(0,300)}</div> */}
              <Box sx={{cursor: "pointer", width: "fit-content", "&:hover": {textDecoration: "underline"}}} onClick={handleToggleDescriptionText}>
                {descriptionText.length > 300 && <Typography variant='defaultFont' color={"#0B85BA"}>{toggleDescription ? "Show less" : "Show more" }</Typography>}
              </Box>
            </Box>
          )}
        </Box>
      )}
      {Object.keys(timesheet).length > 0 && <Divider />}
      {Object.keys(timesheet).length > 0 && (
        <Stack marginTop={"10px"} direction="row" gap={1} alignItems="center">
          <Stack direction="row" gap={1} alignItems="center">
            <Typography fontWeight={900}>Resource:</Typography>
            <Avatar
              sx={{ width: "24px", height: "24px", borderRadius: "8px" }}
              src={timesheet?.resource?.profilePhoto}
            />
            <Typography>
              {timesheet?.consultant?.firstName}{" "}
              {timesheet?.consultant?.lastName}
            </Typography>
          </Stack>
          {!!timesheet?.vendor && <Stack direction="row" gap={1} alignItems="center">
            <Typography fontWeight={900}>Vendor:</Typography>
            <Avatar
              sx={{ width: "24px", height: "24px", borderRadius: "8px" }}
              src={timesheet?.vendor?.profilePhoto}
            />
            <Typography>
              {timesheet?.vendor?.company}
            </Typography>
          </Stack>}
        </Stack>
      )}
    </Box>
  );
}
