import React from "react";
import { TextField } from "@mui/material";

export const InviteEmailAddress = ({
  isSharedInvite,
  email,
  emailUsed,
  errors,
  setEmail,
  checkEmailUsed,
}) => {
  function onEmailAddress(event) {
    const { value } = event.target;

    setEmail(value);
    checkEmailUsed(value);
  }
  return (
    <>
      {isSharedInvite ? (
        <div className="enter-email-address-input-field">
          <TextField
            className="pt-2"
            id="outlined-basic"
            label="Email Address"
            value={email}
            onInput={onEmailAddress}
            variant="outlined"
            fullWidth
          />
          {emailUsed ? (
            <h4 className="text-red-600 text-xs pt-2 pb-4">
              Email is already used
            </h4>
          ) : (
            " "
          )}
          {errors.email ? (
            <h4 className="text-red-600 text-xs pt-2 pb-2">
              *Email is required
            </h4>
          ) : (
            <div>{emailUsed ? "" : <br />}</div>
          )}
        </div>
      ) : (
        <div className="user-email-address-input-field">
          {/* -----------  Email input field ENDS ----------- */}

          <TextField
            className="pt-2"
            id="outlined-basic"
            label={`Email`}
            value={email}
            variant="outlined"
            fullWidth
            disabled
          />
          {emailUsed ? (
            <h4 className="text-red-600 text-xs pt-2 pb-4">
              Email is already used
            </h4>
          ) : (
            " "
          )}
          {errors.email ? (
            <h4 className="text-red-600 text-xs pt-2 pb-2">
              *Email is required
            </h4>
          ) : (
            <div>{emailUsed ? "" : <br />}</div>
          )}
        </div>
      )}
    </>
  );
};
