import {
  Box, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { getUserIdFromLocalStorage, removeKeysFromObject } from 'Utils/helper';
import { setUpdatedDetails } from 'Redux/Feature/profile/profile.slice';
import { employmentOptions } from './constant';
import { selectProfileDetails } from '../../Redux/Feature/profile/profile.selector';
import { bgColor } from '../../Utils/constants';
import { addEmploymentDetails, requestVendor, sendInvite, updateEmploymentDetails } from './api';
import HybridConsultantWrapper from './common/HybridConsultant';
import { selectUserDetails, selectUserRole } from 'Redux/Feature/user';
import { useAlertProvider } from 'Providers/util/Alert';
import { CheckEmailExist } from 'Pages/Auth/Helper';
import { useVendorProvider } from 'Providers/Client/Vendor';
import { useConnectionProvider } from 'Providers/Consultant/Connection';

const intitalState = {
  company_name: '',
  company_email: '',
  website: '',
};

function Employment() {
  const profileDetails = useSelector(selectProfileDetails);
  const [selectedEmployment, setSelectedEmployment] = useState(profileDetails?.employments[profileDetails?.employments?.length - 1]?.type || '');
  const [employementDetails, setEmployementDetails] = useState({ ...profileDetails?.employments[profileDetails?.employments?.length - 1] } || intitalState);
  const {success} = useAlertProvider();
  const {isConsultant} = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const [vendor, setVendor] = useState(null);

  const emptyDetails = () => setEmployementDetails({ ...intitalState, ...profileDetails.employments });

  const changeEmployeHandler = (name, value) => {
    setEmployementDetails({ ...employementDetails, [name]: value });
  };

  const changeHandler = async () => {
    if (!employementDetails?.id && selectedEmployment === "selfEmployed") {
      // const resp = await addEmploymentDetails(profileDetails.id, { ...removeKeysFromObject(employementDetails), type: selectedEmployment });
      const resp = await addEmploymentDetails(profileDetails.id, { company_name: employementDetails.company_name, company_email: profileDetails.email,  });
      resp.data?.msg && success(resp.data.msg);
      dispatch(setUpdatedDetails({ employment_details: resp.data }));
    } if(selectedEmployment === "employed") {
      try {
        const isEmailExist = await CheckEmailExist({email: employementDetails.company_email});
        if(!isEmailExist?.data?.status) {
          return await sendInvite({
            email: employementDetails?.company_email,
            role: "VENDOR",
            company_name: employementDetails?.company_name
          });
        } else {
            return await requestVendor({
              email: employementDetails?.company_email,
              requestFrom: "CONSULTANT"
            });
          }
      } catch (error) {
        console.warn(error);
      }
    } else {
      await updateEmploymentDetails(profileDetails.id, employementDetails?.id, { ...removeKeysFromObject(employementDetails), type: selectedEmployment });
      dispatch(setUpdatedDetails({ employment_details: { ...employementDetails, type: selectedEmployment } }));
    }
  };
  
  const isConnectedToVendor = useMemo(()=> {
    return vendor?.isApproved;
  },[vendor])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!isConnectedToVendor && <><Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle1Bold" className='!text-base'>Employment details</Typography> 
        {selectedEmployment !== "" && <Typography variant="" padding={"8px"} borderRadius={"10px"} sx={{ color: `${selectedEmployment === "selfEmployed" ? bgColor.PRIMARY600: "#03543F"}`, cursor: 'pointer', backgroundColor: `${selectedEmployment === "selfEmployed" ? "none" : "#DEF5EC"}` }} className='!text-sm' onClick={changeHandler}>
          {!employementDetails?.id
            ? selectedEmployment === "selfEmployed" ? 'Save Details' : "Request Connection"
            : (
              <>
                <ModeEditIcon sx={{fontSize: "15px"}} />
                {' '}
                Edit details
              </>
            )}
        </Typography>}
      </Box>
      <Select
        sx={{ marginTop: '40px', width: {xs: "100%", md: '30%'} }}
        value={selectedEmployment}
        onChange={(e) => { setSelectedEmployment(e.target.value); emptyDetails(); }}
        placeholder="Select Business Type"
      >
        {
                employmentOptions.map((option) => <MenuItem value={option.value}>{option.name}</MenuItem>)
            }
      </Select>
      <Box sx={{ marginTop: '50px' }}>
        {['selfEmployed', 'employed'].includes(selectedEmployment) && <Typography variant="subtitle1Bold" className='!text-base'>Please provide company details</Typography>}
        {
                selectedEmployment === 'employed' && (
                <Box sx={{
                  display: 'flex', flexDirection: {xs: "column", md: "row"}, gap: '20px', alignItems: 'center', marginTop: '30px',
                }}
                >
                  <TextField
                    sx={{ width: {xs: "100%", md: '30%'} }}
                    label="Company Name"
                    required
                    value={employementDetails.company_name}
                    placeholder="TATA Consultants Services"
                    onChange={(e) => changeEmployeHandler('company_name', e.target.value)}
                  />
                  <TextField
                    sx={{ width: {xs: "100%", md: '30%'} }}
                    label='Company Email'
                    required
                    value={employementDetails.company_email}
                    placeholder="example@example.com"
                    onChange={(e) => changeEmployeHandler('company_email', e.target.value)}
                />
                </Box>
                )
            }
        {
                selectedEmployment === 'selfEmployed' && (
                <Box sx={{
                  display: 'flex', flexDirection: {xs: "column", md: "row"}, gap: '20px', alignItems: {md:'center'}, marginTop: '30px',
                }}
                >
                  <TextField
                    sx={{ width: {xs: "100%", md: '30%'} }}
                    label="Company Name"
                    value={employementDetails.company_name}
                    placeholder="TATA Consultants Services"
                    onChange={(e) => changeEmployeHandler('company_name', e.target.value)}
                  />
                  <TextField
                    sx={{ width: {xs: "100%", md: '30%'} }}
                    label="Company Email"
                    value={isConsultant ? profileDetails?.email : employementDetails.company_email}
                    disabled={isConsultant}
                    placeholder="Eg : name@company.com"
                    onChange={(e) => changeEmployeHandler('company_email', e.target.value)}
                  />
                </Box>
                )
            }
      </Box>
      </>}
      <HybridConsultantWrapper vendor={vendor} setVendor={setVendor} />
    </Box>
  );
}

export default Employment;
