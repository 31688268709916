import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PlansTable from "./PlansTable";
import Typography from "@mui/material/Typography";
import { PlanCountSelector } from "./PlanCountSelector";
import {
  CURRENCY_ICONS,
  SUBSCRIPTION_COUNTRIES,
  SUBSCRIPTION_TYPES,
  SUNSCRIPTION_PLANS,
  razorPaySubscriptionRolesType,
} from "Utils/constants";
import { PlanPricingDetails } from "./PlanPricingDetails";
import moment from "moment";
import {
  createSubscription,
  createSubscriptionCustomer,
  fetchCustomerDetails,
  fetchPlans,
} from "services/subscriptions.service";
import { myIpInfo } from "services/common.services";
import { HttpStatusCode } from "axios";
import "./subscription.scss";
import { useNavigate } from "react-router-dom";
import { selectUserDetails, selectUserRole } from "Redux/Feature/user";
import { useAlertProvider } from "Providers/util/Alert";
import { checkIncreasedSizeAndDisable, handleRazorPaySubscriptionRedirect, razorPayItemAmount } from "./RazorPay/utils";
import PricingCards from "Pages/Settings/UserManagementTab/PricingPlans.jsx/PricingCards";

export const Plans = ({activeSubscription, setIsUpgradePlan}) => {
  const navigate = useNavigate();
  const [plansList, setPlansList] = React.useState([]);
  const [razorPayPlansList, setRazorPayPlansList] = React.useState([]);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { isConsultant, isVendor, isClient } = useSelector(selectUserRole);
  const [isSubscriptionYealry, setIsSubscriptionYealry] = React.useState(false);
  const [isStripe, setIsStripe] = React.useState(false);
  const { error } = useAlertProvider();
  const {first_name, last_name, email} = useSelector(selectUserDetails);

  if (isConsultant) {
    navigate(-1);
    return;
  }

  /**
  |--------------------------------------------------
  | Country specific config
  |--------------------------------------------------
  */
  const [userCountryDetails, setUserCountryDetails] = React.useState(null);
  const [applicablePlansByCountry, setApplicablePlansByCountry] =
    React.useState({});

  const [subscriptionDetails, setSubscriptionDetails] = React.useState({
    isYearly: isSubscriptionYealry,
    subscriptionStatus: activeSubscription,
    isSubscriptionToggled: false,
    teamSize: [
      {
        label: "Resources",
        type: "resources",
        size: 0,
        price: 0,
        freeUsage: 5,
        unitAmount: 0,
        id: "",
      },
      {
        label: "Account Users",
        type: "users",
        size: 0,
        price: 0,
        freeUsage: 1,
        unitAmount: 0,
        id: "",
      },
      {
        id: "",
        label: "Marketplace Users",
        type: "marketplace",
        size: 0,
        freeUsage: 0,
        price: 0,
        unitAmount: 0,
      },
    ],
    totalPrice: 0.00,
    coupon: "",
    currency: "usd",
    nextCharge: moment(),
  });

  const isSubscriptionActive = React.useMemo(()=>{
    return subscriptionDetails.subscriptionStatus?.status === "active" 
  },[subscriptionDetails.subscriptionStatus?.status]);

  React.useMemo(()=>(
    isSubscriptionActive && !isVendor && setSubscriptionDetails((prev)=>({...prev, isSubscriptionToggled: true}))
  ),[isSubscriptionActive])

  React.useMemo(()=>{
    isSubscriptionYealry ? setSubscriptionDetails((prev)=>({
      ...prev, 
      nextCharge: moment(prev.nextCharge).subtract(1, "month").add(1, "year")
    })) : setSubscriptionDetails((prev)=> ({
      ...prev,
      nextCharge: moment().add(1, "month")
    }))
  },[isSubscriptionYealry])

  React.useMemo(()=>{
    isVendor ? setSubscriptionDetails((prev)=>({...prev, isSubscriptionToggled: true})) : setSubscriptionDetails((prev)=>({...prev, isSubscriptionToggled: false}))
    isSubscriptionActive ?  setSubscriptionDetails((prev)=>({...prev, isSubscriptionToggled: true})) : setSubscriptionDetails((prev)=>({...prev, isSubscriptionToggled: false}))
  },[isVendor, isSubscriptionActive]);

  React.useMemo(()=> {
    isVendor && setSubscriptionDetails((prev)=>({...prev, isSubscriptionToggled: true}));
  },[isVendor, !isSubscriptionActive])
  
  React.useMemo(()=>{
    if(!isStripe) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
    }
  },[!isStripe]);

  const fetchIdandSizefromSubscription = (index)=> {
    return subscriptionDetails.items.filter((i) => i.item.name.includes(index))[0]
  }

  async function fetchOrCreateCustomer() {
    try {
      return await fetchCustomerDetails();
    } catch (error) {
      if (error.response.status === HttpStatusCode.NotFound) {
        const paymentType = isStripe ? "STRIPE" : "RAZORPAY";
        return await createSubscriptionCustomer({}, paymentType);
      }
    }
  }

  const handleSubscriptionYearlyChange = () => {
    setIsSubscriptionYealry(!isSubscriptionYealry);
  }

  const onBuySubscriptionHandler = async (details) => {
    try {
      setIsProcessing(true);
      const payload = {
        success_url: `${window.location.origin}/dashboard/subscriptions`,
        cancel_url: `${window.location.origin}/dashboard/subscriptions`,
        plans: (subscriptionDetails?.teamSize || [])
          .filter(({ size }) => size)
          .map((usageDetails) => ({
            name: usageDetails.type,
            price_id: usageDetails.id,
            quantity: usageDetails.size,
          })),
        coupon: subscriptionDetails?.coupon,
      };
      const { id } = fetchIdandSizefromSubscription(details.name);
      const razorPayPayload = {
        id: id
      }

      const { data } = await fetchOrCreateCustomer();
      const { data: subscription } = await createSubscription(data.id, razorPayPayload, isStripe);
      if(!isStripe) {
        handleRazorPaySubscriptionRedirect(subscription, {first_name, last_name, email});
      } else {
        window.location.href = subscription.url;
      }

      setIsProcessing(false);
    } catch (error) {
      console.error("error :>> ", error);
      setIsProcessing(false);
    }
  };

  const tierAmount = ({ tiers, unit_amount }) => {
    if (unit_amount) return unit_amount;
    return tiers?.[1]?.unit_amount ?? tiers?.[0]?.unit_amount;
  };

  const plansPrices = React.useMemo(() => {
    return plansList?.reduce((prev, curr) => {
      prev[curr.metadata.type] = {
        id: curr.id,
        currency: curr.currency,
        currencySymbol: CURRENCY_ICONS[curr.currency],
        type: curr.metadata.type,
        unitAmount: tierAmount(curr),
        flatUnitAmount: tierAmount(curr) / 100,
      };
      return prev;
    }, {});
  }, [plansList]);

  //for RazorPay Table Data
  const planPricesForRazorPay = React.useMemo(()=> {
    return razorPayPlansList?.reduce((prev, plans)=> {
      prev[plans?.typeKey] = {
        id: plans.id,
        currencySymbol: CURRENCY_ICONS[plans?.item?.currency?.toLowerCase()],
        currency: plans?.item?.currency?.toLowerCase(),
        type: plans?.typeKey,
        unitAmount: plans?.item?.amount,
        flatUnitAmount: plans?.item?.amount / 100
      };
      return prev
    }, {})
  },[razorPayPlansList]);

  const calculatePrice = (idx, size) => {
    if (!size) return 0;

    const unitDetails = subscriptionDetails.teamSize[idx];
    const unitAmountInCents = unitDetails.unitAmount;

    const updatedPriceInDollars = (unitAmountInCents * size) / 100;
    return updatedPriceInDollars;
  };

  const calculateSubscriptionTotalPrice = (teamSizes = subscriptionDetails.teamSize) => {
    return teamSizes.reduce(
      (prev, curr) => prev + curr.price,
      0
    );
  };

  const setSubscriptionSize = (teamIdx, size) => {
    if(subscriptionDetails.teamSize.length > 0) {
    const unitDetails = subscriptionDetails.teamSize[teamIdx];
    unitDetails.size = size;
    unitDetails.price = calculatePrice(teamIdx, size);
    const totalPrice = parseInt(calculateSubscriptionTotalPrice().toFixed(2));
    setSubscriptionDetails((prev)=> ({ ...prev, totalPrice }));
    }
  };

  const updatePriceBasedOnPrevData = (type, unitPrice) => {
    const filteredData = subscriptionDetails.teamSize.filter(i => i.type === type)
    if (filteredData && filteredData.length) {
      const price = (unitPrice * filteredData[0].size) / 100;
      return { size: filteredData[0].size, price }
    }
    return { size: 0, price: 0 }
  }

  //RazorPay Retain Price on Toggle
  const updateRazorPayPriceOnPreviousData = (name, unitPrice) => {
    const filteredData = subscriptionDetails.teamSize.filter(i => i.label === name);
    if (filteredData && filteredData.length) {
      const price = (unitPrice * filteredData[0].size) / 100;
      return { size: filteredData[0].size, price }
    }
    return { size: 0, price: 0 }
  }

  const fetchPlansList = async () => {
    try {
      const duration = isSubscriptionYealry ? "yearly" : "monthly";
      const { data } = await fetchPlans({ duration });
      const { meta, plans } = data;
      if(data?.payment_provider === "STRIPE") {
      setIsStripe(true);
      setPlansList(plans);
      const teamSize = data.plans.map((plan) => {
        const { size, price } = updatePriceBasedOnPrevData(plan.metadata.type, tierAmount(plan));
        return {
        id: plan.id,
        label:
          plan.metadata?.label ??
          `${
            plan.metadata.type !== "users"
              ? `${plan.metadata.type ?? ""}`
              : "account users"
          }`,
        type: plan.metadata.type,
        size: size,
        freeUsage: plan.metadata?.free_usage ?? 0,
        price: price,
        unitAmount: tierAmount(plan),
      }});

      setSubscriptionDetails({
        ...subscriptionDetails,
        teamSize: isSubscriptionYealry ? teamSize.reverse() : teamSize,
        currency: meta.currency,
        totalPrice: parseInt(calculateSubscriptionTotalPrice(teamSize).toFixed(2)),
      });
      } else if(data?.payment_provider === "RAZORPAY") {
        setIsStripe(false);
        setSubscriptionDetails({ ...data });
      }
    } catch (error) {
      console.error("error :>> ", error);
    }
  };

  React.useEffect(() => {
    fetchPlansList();
  }, [isSubscriptionYealry]);
  
  const { role } = useSelector(selectUserRole);

  return (
    <Box sx={{ flexGrow: 1 }} justifyContent={"center"}>
      {/* <PricingCards setIsUpgradePlan={setIsUpgradePlan} /> */}
      {/* <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"center"}
        flexGrow={1}
        marginTop={2}
      >
        <Grid xs={12} md={12} lg={12} textAlign={"center"} paddingTop={"14px"}>
          <Typography
            variant="h4Bold"
          >
            Upgrade plan for &nbsp;
            <span className="capitalize">{role?.toLowerCase() ?? ""}</span>
          </Typography>

          <Box display={"flex"} justifyContent={"center"}>
            <Grid
              xs={12}
              md={6}
              lg={5}
              display={"flex"}
              justifyContent={"center"}
            >
              <Typography variant="subtitle1Large" color={'#6B7280'}>
                Our plans are tailored for you and your business
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={15} md={15}>
          <PlansTable
            userCountryDetails={userCountryDetails}
            subscriptionDetails={subscriptionDetails}
            plansPrices={isStripe ? plansPrices : planPricesForRazorPay}
            isSubscriptionYealry={isSubscriptionYealry}
          />
            <PlanCountSelector
              isSubscriptionYealry={isSubscriptionYealry}
              setSubscriptionType={setIsSubscriptionYealry}
              subscriptionDetails={subscriptionDetails}
              setSubscriptionDetails={setSubscriptionDetails}
              isSubscriptionActive={isSubscriptionActive}
              isClient={isClient}
              isVendor={isVendor}
              setSubscriptionSize={setSubscriptionSize}
              handleSubscriptionYearlyChange={handleSubscriptionYearlyChange}
              isStripe={isStripe}
              controlledCountTeamSize={controlledCountTeamSize}
            />
            <PlanPricingDetails
              isSubscriptionYealry={isSubscriptionYealry}
              subscriptionDetails={subscriptionDetails}
              setSubscriptionDetails={setSubscriptionDetails}
              onBuySubscriptionHandler={onBuySubscriptionHandler}
              isProcessing={isProcessing}
              isSubscriptionActive={isSubscriptionActive}
              isVendor={isVendor}
              isClient={isClient}
              isStripe={isStripe}
            />
        </Grid>
      </Grid> */}
    </Box>
  );
};
