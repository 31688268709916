import React, { useState, createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import api from '../../Utils/api';

import { userPermissions } from '../../Utils/constants';
import { getPermissions } from 'Utils/helper';

const apiPath = {
  timesheets: '/timesheet/getTimesheetCount',
  jobs: '/jobnproject/getJobsCount',
  users: '/user/getUsersCount',
  invoices: '/invoice/get-invoices-count-for-user',
};

const nullFn = () => null;
const CountContext = createContext({
  loading: false,
  counts: {
    clients: undefined,
    consultants: undefined,
    invoices: undefined,
    jobs: undefined,
    timesheets: undefined,
    vendors: undefined,
  },
  fetchCounts: nullFn,
});

export const useCountProvider = () => useContext(CountContext);

function CountProvider({ children, userId }) {
  const permissions = getPermissions();

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(0);
  const [consultants, setConsultants] = useState(0);
  const [invoices, setInvoices] = useState(0);
  const [jobs, setJobs] = useState(0);
  const [timesheets, setTimesheets] = useState(0);
  const [vendors, setVendors] = useState(0);

  const fetchCounts = async (role) => {
    setLoading(true);
    try {
      const params = {
        userId,
        userType: role,
      };
      if (permissions.includes(userPermissions.timesheet.approve) || permissions.includes(userPermissions.timesheet.view)) {
        const timesheetRes = await api.get(apiPath.timesheets, { params });
        if (timesheetRes.data?.success) {
          setTimesheets(
            timesheetRes.data.result.approved + timesheetRes.data.result.pending + timesheetRes.data.result.paid + timesheetRes.data.result.invoiced,
          );
        }
      }

      if (permissions.includes(userPermissions.job.view)) {
        const jobRes = await api.get(apiPath.jobs, { params });
        if (jobRes.data?.success) setJobs(jobRes.data.result);
      }

      if (role !== 'CONSULTANT') {
        const userRes = await api.get(apiPath.users, { params });
        if (userRes.data?.success) {
          setClients(userRes.data.result.clients);
          setVendors(userRes.data.result.vendors);
          setConsultants(userRes.data.result.consultants);
        }

        const { userType, ...restParams } = params;
        const invoiceRes = await api.get(apiPath.invoices, {
          params: { ...restParams, mode: role },
        });

        if (invoiceRes.data?.success) {
          setInvoices(invoiceRes.data.result);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const value = React.useMemo(() => ({
    loading,
    counts: {
      clients,
      consultants,
      invoices,
      jobs,
      timesheets,
      vendors,
    },
    fetchCounts,
  }), [fetchCounts]);

  return (
    <CountContext.Provider value={value}>
      {children}
    </CountContext.Provider>
  );
}

export default CountProvider;
