import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { Outlet, useNavigation } from 'react-router-dom';
import { FullWidthWrapper } from 'Components/Common';

export default function AppDefaultPage() {
  const navigation = useNavigation();
  return (
    <FullWidthWrapper sidebar>
      {navigation.state === 'loading' ? (
        <Box sx={{ marginTop: '200px', flex: 1, textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container columns={16} spacing={2}>
          <Outlet />
        </Grid>
      )}
    </FullWidthWrapper>
  );
}

