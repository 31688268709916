const typographyOverrides = {
  fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
  h1: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '55px',
    fontWeight: 800,
    lineHeight: '72px',
  },
  h1Second: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '50px',
    fontWeight: 800,
    lineHeight: '72px',
  },
  h2: {
    fontFamily: 'Roboto',
    fontSize: '60px',
    fontWeight: 300,
    lineHeight: '72px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontFamily: 'Roboto',
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: '56px',
    letterSpacing: '0px',
  },
  h4: {
    fontFamily: 'Roboto',
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '42px',
    letterSpacing: '0.25px',
  },
  h4Bold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
  h5: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0px',
  },
  h6: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0.15px',
  },
  subtitle1Bold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
    color: '#182743',
  },
  subtitle2: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  body1: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  button: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  caption: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  captionNormal: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  captionMedium: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
  },
  captionSemibold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
  },
  overline: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '1px',
  },
  body1Normal: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
  },
  body1Medium: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
  },
  body1Semibold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
  },
  body1Bold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
  body2Medium: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '160%',
  },
  body2Normal: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: '0.14px',
  },
  body2Semibold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
  },
  body2Bold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '160%',
  },
  subtitle2Large: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '27px',
    letterSpacing: '0.1px',
  },
  subtitle1Large: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '27px',
    letterSpacing: '0.1px',
  },
  h5Bold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%', /* 36px */
  },
  h5SemiBold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%', /* 36px */
  },
  cardHeader: {
    // subtitle1Bold
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
    color: '#182743',
  },
  componentsButtonSmall: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
  },
  componentsButtonSmallBold: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
  defaultFont: {
    fontFamily: 'Plus Jakarta Sans',
  },
  grayColor: {
    color: '#6B7280',
  },
};

export default typographyOverrides;
