import React from 'react';
import {
  Avatar, Box, Card, Divider, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CommonSwiper from 'Components/Common/Swiper';
import { SwiperSlide } from 'swiper/react';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Link, useNavigate } from 'react-router-dom';
import clientImg from './client.png';
import vendorImg from './vendor.png';
import consultantImg from './consultant.png';
import Button from 'Components/Common/semantic_tags/Button';

const cardList = [
  {
    name: 'Consultant',
    key: 'CONSULTANT',
    img: consultantImg,
  },
  {
    name: 'Vendor',
    key: 'VENDOR',
    img: vendorImg,
  },
  {
    name: 'Enterprise',
    key: 'CLIENT',
    img: clientImg,
  },
];

function ProlegionCard({
  name,
  imgUrl,
  activeCard,
  setSelectedTab,
  cardKey,
}) {
  return (
    <Box
      sx={{
        width: '33%',
        padding: '30px',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 0px 7.388888835906982px 0px #11111A0D',
        borderRadius: '8px',
        ...(activeCard === cardKey && { border: '2px solid #0B85BA' }),
      }}
      onClick={() => setSelectedTab(cardKey)}
    >
      <Avatar sx={{ width: '52px', height: '52px' }} src={imgUrl} />
      <Typography variant="body2Bold">{name}</Typography>
    </Box>
  );
}

const slidesData = [
  {
    key: 'Consultant',
    value: [
      'Find Jobs on ProLegion Marketplace',
      'Expand your network',
      'Create your own ProLegion profile to quickly apply for jobs',
      'All for free!',
    ],
  },
  {
    key: 'Vendor',
    value: [
      'Get Hired by Enterprises',
      'Manage Project Resources and Timesheets',
      'Get Real-time Insights into Project Performance',
      'Submit and Track Invoices and Payments',
    ],
  },
  {
    key: 'Enterprise',
    value: [
      'Quickly Find and Hire Vendors and Consultants',
      'Manage Project Resources and Timesheets',
      'Get Real-time Insights into Project Performance',
      'Submit and Track Invoices and Payments',
    ],
  },
];

function ProlegionAccount({ setUserTypeSelect, setCurrentScreen }) {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setUserTypeSelect(
      selectedTab === 0 ? 'Consultant' : selectedTab === 1 ? 'Vendor' : 'Client',
    );
  }, [selectedTab]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: {xs: 5, md: 20},
      }}
    >
      <Card sx={{ padding: '32px', margin: {xs: "0 1rem", md: "0"}, width: {xs: "100%", md: "unset"} }}>
        <Box sx={{ display: 'flex', flexDirection: {xs: "column", md: "row"} }}>
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5Bold">
                Create your ProLegion account
              </Typography>
              <Typography variant="body2Medium">
                Already have an account?
                {' '}
                <Link
                  to="/signin"
                  style={{ color: '#0B85BA' }}
                >
                  Login Here
                </Link>
              </Typography>
            </Box>
            <Box sx={{ marginTop: '50px' }}>
              <Typography variant="cardHeader">Select user role</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '10px',
                  marginTop: '10px',
                }}
              >
                {cardList.map((card, i) => (
                  <ProlegionCard
                    cardKey={i}
                    activeCard={selectedTab}
                    setSelectedTab={setSelectedTab}
                    name={card.name}
                    imgUrl={card.img}
                    key={card.key}
                  />
                ))}
              </Box>
            </Box>
            <Box>
              <Button
                parentClassName='!w-full'
                className='w-full mt-4'
                onClick={() => setCurrentScreen('form')}
                title='Continue'
              />
            </Box>
          </Box>
          <Divider
            sx={{ minWidth: {xs: "100%", md: 'unset'}, height: {xs:"2px", md: "unset"}, mt: {xs: "1rem", md: "0"}, marginLeft: {md: '30px'}, marginRight: {md: '30px'} }}
            orientation="vertical"
            variant="fullWidth"
            flexItem
          />
          <Box sx={{ width: {sm: "100%", md: '25vw'}, pt: {xs: "2rem", md: "0"} }}>
            <CommonSwiper selectedTab={selectedTab}>
              {slidesData.map((slid) => (
                <SwiperSlide>
                  <Box sx={{ paddingBottom: {xs: "0", md: '150px'} }}>
                    <Box sx={{ marginBottom: '20px' }}>
                      <Typography variant="h5Bold">
                        ProLegion for
                        {' '}
                        {slid.key}
                      </Typography>
                    </Box>
                    {slid.value.map((data) => (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '20px',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                          <VerifiedIcon sx={{ color: '#0E9F6E' }} />
                          <Typography>{data}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </SwiperSlide>
              ))}
            </CommonSwiper>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default ProlegionAccount;
