const breakpointsOverrides = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1440,
    xl: 1920,
  },
};

export default breakpointsOverrides;
