  import {
  Autocomplete,
  Box,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useProjectProvider } from "Providers/Vendor/Project";
import React, { useEffect } from "react";
import { roles } from "Utils/constants";
import { selectUserRole } from "Redux/Feature/user";
import { useSelector } from "react-redux";
import JobDetails from "./JobDetails";
import Button from "Components/Common/semantic_tags/Button";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import MyEditor from "Components/TextEditor";
import { AiLoader } from "Components";
import AiLoaderBox from "Components/AiLoader/AiLoaderBox";
// import { ProgressBar } from "Components";

const CreateJobs = ({
  wordCount,
  jobDetails,
  updateHandler,
  loading,
  isValid,
  jobId,
  isError,
  setOpen,
  handleOpenAITextGenerator,
  jobPage, 
  setJobPage
}) => {
  const { fetchProjects, projects } = useProjectProvider();
  const { role } = useSelector(selectUserRole)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography variant="cardHeader">Create Job</Typography>
      </Box>
      {/* <ProgressBar progress={jobPage === 0 ? "50%" : "100%"} baseClassname={"border-[#0B85BA99] rounded-xl my-4"}/> */}
      {jobPage === 0 && <>
      <Box>
        <Typography variant="body1Medium">Project details</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? 'column' : 'row',
            gap: "20px",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Autocomplete
            sx={{ width: isMobile ? '100%' : "30%" }}
            value={jobDetails.projectId}
            clearIcon={false}
            isOptionEqualToValue={(option, value) =>
              option.value === value || option.value === value?.value
            }
            getOptionLabel={(option) => (option.label ? option.label : option.title ? option.title : option)}
            onChange={(_, v) => updateHandler(v, 'projectId')}
            options={projects
              .map((project) => ({
                value: project.id,
                label: project.title,
              }))
              .concat([
                {
                  value: "",
                  label: "Default Project",
                },
              ])}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Select project"
                placeholder="Choose your project"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
          <Typography>Or</Typography>
          <Button onClick={() => setOpen(true)} title='Create new project' />
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <Divider />
        </Box>
      </Box>
      <JobDetails loading={loading} isValid={isValid} isError={isError} wordCount={wordCount} jobDetails={jobDetails} updateHandler={updateHandler} handleOpenAITextGenerator={handleOpenAITextGenerator} jobPage={jobPage} setJobPage={setJobPage}/> </> }
      {jobPage === 1 && <>
          <div className="flex items-center py-4">
            <Typography className="pr-2">Job Title:- </Typography>
            {jobDetails?.name && <Typography className="px-2 !text-[12px] border-[1px] border-[#D1D5DB] bg-[#F9FAFB] text-[#959595] rounded-[8px]">{jobDetails?.name}</Typography>}
          </div>
          <div className="flex justify-between pb-2">
            <div className="flex gap-2">
              <Typography className="font-semibold">Job description *</Typography>
              <Typography className={`${wordCount > 3000 ? "red" : "unset"}`}>
                ( {wordCount}/3000 )
              </Typography>
            </div>
          <div className="flex gap-2 cursor-pointer" onClick={handleOpenAITextGenerator}>
            {/* {loading ? 
              <div className="flex items-center">
                <Typography className="font-semibold">Generating AI Response</Typography>
                <AiLoader baseClassname={"px-4"} />
              </div>
            : */}{!loading && <> 
                <Typography className="font-semibold">Generate with AI</Typography>
                <AutoAwesomeIcon sx={{color: "#3985B6"}}/>
              </>}
            {/* } */}
          </div>
          </div>
            {isValid?.isNotValidDescription && isError && !loading && <div className="py-2 text-red-500"><Typography className="text-[10px]">Description Required</Typography></div>}
            {loading ?
              <AiLoaderBox big /> 
            : 
            <MyEditor
              html={jobDetails.description}
              updateRaw={(content) => updateHandler(content, "description")}
              label="Job Description"
              height={450}
            />}
            <div className="flex pt-12">
            <FormControlLabel
              className=""
              control={
                <Checkbox
                  checked={jobDetails.isCheckinCheckout}
                  onChange={(e) =>
                    updateHandler(e.target.checked, "isCheckinCheckout")
                  }
                />
              }
              label="Record Check in/Check out time"
            />
            {/* {[roles.client, roles.vendor].includes(role) && !jobId && (
              <FormControlLabel
                className=""
                control={
                  <Checkbox
                    checked={jobDetails.isPublicalyVisible}
                    onChange={(e) =>
                      updateHandler(e.target.checked, "isPublicalyVisible")
                    }
                  />
                }
                label="Post in Marketplace"
              />
            )} */}
            </div>
          </>}
    </Box>
  );
};

export default CreateJobs;
