import React, {
  useState, createContext, useContext, useEffect,
} from 'react';
import dayjs from 'dayjs';
import api from '../Utils/api';
import { monthNames, timesheetStatus } from '../Utils/constants';
import { useAlertProvider } from './util/Alert';

export const apiPath = {
  approveTimesheet: '/timesheet/approvetimesheet',
  rejectTimesheet: '/timesheet/rejecttimesheet',

  fetchTimesheets: '/timesheet/gettimesheets',
  fetchTimesheetsForJob: '/timesheet/gettimesheetsforjob',
  addNewTimesheet: '/timesheet/addtimesheet',
  updateTimesheet: '/timesheet/updatetimesheet',
  fetchTimesheetsByDate: '/timesheet/gettimesheetbydate',
  generateSecureLink: '/timesheet/link/update',

  getJobsForConsultant: '/jobnproject/getjobsforconsultant',
  fetchJobDetails: '/jobnproject/getjobbyid',
  getAllSkills: '/jobnproject/getallskills',
  getTimesheetByWeek: '/timesheet/gettimesheetbymonth',
};

const nullFn = () => null;
const promiseFn = () => new Promise();
const TimesheetContext = createContext({
  approveTimesheet: nullFn,
  rejectTimesheet: nullFn,
  loading: false,
  timesheetMaster: {},
  timesheetDetails: {},
  selectedTimesheet: null,
  setSelectedTimesheet: nullFn,
  selectedJobDetails: {},
  setSelectedJobDetails: nullFn,
  addNewTimesheet: nullFn,
  updateTimesheet: nullFn,
  timesheets: [],
  fetchTimesheets: nullFn,
  fetchTimesheetsByDate: nullFn,
  fetchTimesheetsForJob: nullFn,
  generateSecureLink: nullFn,
  timesheetOtherDetails: {},

  fetchTimesheetByWeek: promiseFn,
  downloadTimesheetasPdf: nullFn,

  setCanApproveTimesheet: nullFn,

  getTimesheetIdFromLink: nullFn,
  resourceVendor: null,
  canApproveTimesheet: false,
  setResourceVendor: nullFn,
  generateSecureLinkForMonthlyTimesheet: params => null,
  totalTimesheets: 0,
});

export const useTimesheetProvider = () => useContext(TimesheetContext);
function TimesheetProvider({ children, userId }) {
  const { success, error } = useAlertProvider();
  const [loading, setLoading] = useState(false);
  const [timesheet, setTimesheet] = useState({});
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetMaster, setTimesheetMaster] = useState({});
  const [timesheetDetails, setTimesheetDetails] = useState({});
  const [selectedJobDetails, setSelectedJobDetails] = useState({});
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [canApproveTimesheet, setCanApproveTimesheet] = useState(false);
  const [resourceVendor, setResourceVendor] = useState(null);
  const [totalTimesheets, setTotalTimesheets] = useState(0);

  const approveTimesheet = async (timesheet_id, comments) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.approveTimesheet, {
        timesheet_id,
        comments,
        userId,
      });

      if (res.status === 200 && res.data?.success) {
        const temp = [...timesheets];
        const index = temp.findIndex((item) => item.id === timesheet_id);
        if (index > -1) {
          temp[index].status = timesheetStatus.approved;
          setTimesheets(temp);
        }

        setLoading(false);
        success('Timesheet approved successfully');

        return true;
      }
      error('Error occurred while approving timesheet');
      return false;
    } catch (e) {
      console.error(e);
      error('Error occurred while approving timesheet');

      return false;
    } finally {
      setLoading(false);
    }
  };

  const rejectTimesheet = async (timesheet_id, comments) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.rejectTimesheet, {
        timesheet_id,
        comments,
        userId: localStorage.getItem('userId'),
      });

      if (res.status === 200 && res.data?.success) {
        const temp = [...timesheets];
        const index = temp.findIndex((item) => item.id === timesheet_id);
        if (index > -1) {
          temp[index].status = timesheetStatus.rejected;
          setTimesheets(temp);
        }
        success('Timesheet rejected successfully');
        return true;
      }
      error('Error occurred while rejecting timesheet');
      return false;
    } catch (e) {
      console.error(e);
      error('Error occurred while rejecting timesheet');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchTimesheets = async ({
    page = 0, size = 50, sortBy = 'start', orderBy = 'desc',
  }) => {
    setLoading(true);
    try {
      const res = await api.get('/timesheet', {
        params: {
          page: page + 1,
          size,
          sortBy,
          orderBy,
        },
      });

      if (res.status === 200 && res.data?.success) {
        setTimesheets(res.data.result?.pagination?.items || []);
        setTotalTimesheets(res.data.result?.meta.total);
        return res.data.result;
      }

      error('Error occurred while fetching timesheets');
      return false;
    } catch (e) {
      error('Error occurred while fetching timesheets');
      console.error(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchTimesheetsForJob = async ({
    jobId, page = 0, size = 50, sortBy = 'start', orderBy = 'desc',
  }) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchTimesheetsForJob, {
        params: {
          job_id: jobId,
          page: page + 1,
          size,
          sortBy,
          orderBy,
        },
      });

      if (res.status === 200 && res.data?.success) {
        setTimesheets(res.data.result?.pagination?.items || []);
        setTimesheetMaster({ ...timesheetMaster, [jobId]: res.data.result });
        return res.data.result?.pagination?.items;
      }

      error('Error occurred while fetching timesheets');
      return false;
    } catch (e) {
      error('Error occurred while fetching timesheets');
      console.error(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchTimesheetsByDate = async ({ jobId, startDate }) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.fetchTimesheetsByDate, {
        jobId,
        startDate,
        userId: localStorage.getItem('userId'),
        userType: 'CONSULTANT',
      });

      if (res.status === 200 && res.data?.success) {
        if (res.data.msg !== 'No timesheet found') {
          const cur = timesheetMaster[jobId];
          if (cur.findIndex((t) => t.id === res.data.timesheet.id) === -1) {
            setTimesheetMaster({
              ...timesheetMaster,
              [jobId]: [
                ...(Array.isArray(timesheetMaster[jobId])
                  ? timesheetMaster[jobId]
                  : []),
                res.data.timesheet,
              ],
            });
          } else {
            const newTimesheetMaster = timesheetMaster[jobId].map((t) => (t.id === res.data.timesheet.id ? res.data.timesheet : t));

            setTimesheetMaster({
              ...timesheetMaster,
              [jobId]: newTimesheetMaster,
            });
          }

          setTimesheetDetails({
            ...timesheetDetails,
            [res.data.timesheet.id]: res.data.result,
          });

          setSelectedTimesheet(res.data.timesheet.id);
        } else {
          setSelectedTimesheet(null);
        }
      } else {
        error('Error occurred while fetching timesheets');
        return false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchTimesheetDetails = async (timesheetId) => {
    setLoading(true);
    try {
      const res = await api.get(`/timesheet/${timesheetId}`);

      if (res.status === 200 && res.data?.result) {
        setTimesheet([res.data?.result?.timesheet] || []);
        setTimesheetDetails(res.data?.result?.timesheet || {});
        setCanApproveTimesheet(res.data?.result?.timesheet.canApprove || false);
        return ({
          timesheet: res.data.result || [],
          timesheetDetails: res.data?.timesheet?.[0] || {},
        });
      }

      error('Error occurred while fetching timesheet');
      return false;
    } catch (e) {
      error('Error occurred while fetching timesheet');
      console.error(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addNewTimesheet = async ({
    jobId,
    start,
    end,
    dayDetails,
    submit = false,
  }) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.addNewTimesheet, {
        job_id: jobId,
        created_by: localStorage.getItem('userId'),
        weekDetails: {
          start,
          end,
          status: submit ? timesheetStatus.submitted : timesheetStatus.saved,
        },
        dayDetails,
      });

      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        fetchTimesheetDetails(res.data?.timesheet?.id);
        success(`Timesheet ${timesheetStatus.saved ? 'saved' : 'submitted'}`);
        return res.data.timesheet?.id;
      }
      setLoading(false);
      error(res?.data?.msg || 'Error occurred while creating timesheet');
      return false;
    } catch (e) {
      console.error(e);
      setLoading(false);
      error(e?.response?.data?.msg || 'Error occurred while creating timesheet');
      return false;
    }
  };

  const updateTimesheet = async ({ dayData, submit, id }) => {
    setLoading(true);
    try {
      const res = await api.put(apiPath.updateTimesheet, {
        id,
        status: submit ? timesheetStatus.submitted : timesheetStatus.saved,
        dayData,
      });

      if (res.status === 200 && res.data?.success) {
        fetchTimesheetDetails(id);
        success(`Timesheet ${submit ? 'submitted' : 'updated'} successfully`);
      } else {
        error('Error occurred while updating timesheet');
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      error('Error occurred while updating timesheet');
    }
  };

  const generateSecureLink = async ({
    timesheetId,
    emails,
    pin,
    numberOfWeeks,
  }) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.generateSecureLink, {
        timesheetId,
        emails,
        pin,
        numberOfWeeks,
      });

      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        return res.data.result;
      }
      error('Error occurred while generating secure link');
      return false;
    } catch (e) {
      setLoading(false);
      console.error(e);
      error('Something went wrong');

      return null;
    }
  };

  const generateSecureLinkForMonthlyTimesheet = async ({
    job_id,
    emails,
    pin,
    year,
    month
  }) => {
    setLoading(true);
    try {

      const res = await api.post(apiPath.generateSecureLink, {
        job_id,
        emails,
        pin,
        year,
        month
      });

      if (res.status === 200 && res.data?.success) {
        setLoading(false);
        return res.data.result;
      }

      error('Error occurred while generating secure link');
      return false;
    
    } catch (e) {

      setLoading(false);
      console.error(e?.response?.data);
      error(e?.response?.data?.message);
      return null;
      
    }
  };

  const fetchTimesheetByWeek = async (jobId, startDate, endDate) => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.getTimesheetByWeek, {
        params: {
          jobId,
          // userId: localStorage.getItem('userId'),
          month: monthNames[dayjs(startDate).month()],
          year: dayjs(startDate).year(),
        },
      });
      if (res.status === 200 && res.data?.success) {
        return res.data.result;
      }
      error('Error occurred while fetching skills');
      return false;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const downloadTimesheetasPdf = (blob, startDate, endDate) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${startDate}_${endDate}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  const getTimesheetIdFromLink = async (id) => {
    try {
      const res = await api.get(apiPath.getTimesheetIdFromLink, {
        params: { id },
      });

      if (res.status === 200 && res.data?.success) {
        if (res.data.result?.length > 0) {
          setSelectedTimesheet(res.data.result);
          return res.data.result;
        }
      } else {
        error('Error occurred while fetching timesheets');
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (selectedJobDetails?.job?.id) fetchTimesheetsForJob(selectedJobDetails?.job?.id);
  }, [selectedJobDetails?.job?.id]);

  useEffect(() => {
    if (selectedTimesheet) fetchTimesheetDetails(selectedTimesheet);
  }, [selectedTimesheet]);

  const value = React.useMemo(() => ({
    approveTimesheet,
    rejectTimesheet,
    loading,
    timesheetMaster,
    timesheetDetails,
    selectedTimesheet,
    setSelectedTimesheet,
    selectedJobDetails,
    setSelectedJobDetails,
    addNewTimesheet,
    updateTimesheet,
    timesheet,
    timesheets,
    fetchTimesheets,
    fetchTimesheetsByDate,
    generateSecureLink,
    fetchTimesheetsForJob,
    fetchTimesheetByWeek,
    fetchTimesheetDetails,
    downloadTimesheetasPdf,
    getTimesheetIdFromLink,
    resourceVendor,
    setResourceVendor,
    canApproveTimesheet,
    setCanApproveTimesheet,
    generateSecureLinkForMonthlyTimesheet,
    totalTimesheets,
  }), [
    loading,
    timesheetMaster,
    timesheetDetails,
    selectedTimesheet,
    selectedJobDetails,
    timesheet,
    timesheets,
    resourceVendor,
    canApproveTimesheet,
  ]);

  return (
    <TimesheetContext.Provider value={value}>
      {children}
    </TimesheetContext.Provider>
  );
}

export default TimesheetProvider;
