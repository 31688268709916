import React from 'react';

export default function SignupSuccessfull() {
  return (
    <div className="px-20 py-24">
      <div
        className="flex text-white justify-center items-center"
        style={{
          height: '102px',
          background: '#2EBAA3',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '32px',
        }}
      >
        We are almost there!
      </div>
      <div
        className="flex flex-col justify-center items-center space-y-16"
        style={{
          height: '418px',
          background: '#EDFDF9',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '27px',
        }}
      >
        <div className="flex flex-col items-center">
          <span>
            We have received your registration request. We are verifying the
            details and will contact you shortly.
          </span>
        </div>
        <div
          className="flex justify-center items-center"
          style={{
            width: '363px',
            height: '72px',
            left: '543px',
            top: '567px',
            border: '1px solid #139588',
            borderRadius: '10px',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '24px',
          }}
        >
          Pending Approval
        </div>
      </div>
    </div>
  );
}
