import { Swiper, useSwiper } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useEffect } from "react";

const CommonSwiper = ({ modules = [], children, selectedTab, ...props }) => {
  const ChangeSlide = ({ position }) => {
    const swiper = useSwiper();

    useEffect(() => {
      if (swiper) {
        swiper.slideTo(position);
      }
    }, [swiper, position]);
    return null;
  };
  return (
    <Swiper
      modules={[Pagination, ...modules]}
      spaceBetween={30}
      slidesPerView={1}
      pagination={{ clickable: true }}
      {...props}
    >
      <ChangeSlide position={selectedTab} />
      {children}
    </Swiper>
  );
};

export default CommonSwiper;
