import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import MarketplaceCard from 'Pages/Marketplace/MarketplaceCard';
import { useJobProvider } from 'Providers/Client/Jobs';
import { setJobCategories } from 'Redux/Feature/jobs';
import { selectIsUserLoggedIn, selectUserDetails } from 'Redux/Feature/user';
import { roles } from 'Utils/constants';
import useDebounce from 'hooks/useDebounce';
import useQuery from 'hooks/useQuery';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JobCard from './JobCard';
import SearchBar from './SearchBar';
import { getAllMarketplaceJobs, getSingleMarketPlaceJob } from './marketplace.api';

const MarketplaceNew = ({ loading, setLoading = () => { } }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const isLoggedIn = useSelector(selectIsUserLoggedIn);
    const userDetails = useSelector(selectUserDetails);
    const [limit, setLimit] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [total, setTotal] = useState(1);
    const navigate = useNavigate()
    const [currencyType, setCurrencyType] = useState('Select');
    const [categoryId, setCategoryId] = useState('Select');
    const [mainSkills, setMainSkills] = useState('Select Skills');
    const [sortOrder, setSortOrder] = useState('DESC');
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const debounceSearchText = useDebounce(searchText, 500);
    const [selectLayout, setSelectedLayout] = useState('row');
    const { getAllJobCategories, getAllSkills, skills } = useJobProvider();
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [similarJobs, setSimilarJobs] = useState();
    const search = useQuery()

    const fetchData = async (page, limit, appendData = false) => {
        let result;
        let query = '';
        if (selectedCategory.length > 0) {
            query += `&main_skill=${selectedCategory.join(',')}`;
        }
        if (isLoggedIn && userDetails?.role === roles.consultant) {
            query += `&applicant_id=${userDetails?.user_id}`
        }
        { !appendData && setData([]) };
        !appendData && setLoading(true);
        result = await getAllMarketplaceJobs(page, limit, debounceSearchText, currencyType, categoryId, sortOrder, query);
        if (result) {
            setData((prevData) => (appendData ? [...prevData, ...(result.data?.data || [])] : result.data?.data || []));
            setTotal(result.total);
            setTotalPages(Math.ceil(result?.total / (page * limit)));
        }
        setLoading(false);
    };

    const addOrRemoveCategory = (details) => {
        setMainSkills('Select Skills');
        if (details?.value) {
            setSelectedCategory((prevCategories) => {
                if (details?.value.includes('Select Category')) return prevCategories;
                if (prevCategories.includes(details?.value)) {
                    return prevCategories.filter(category => category !== details?.value);
                } else {
                    return [...prevCategories, details?.value];
                }
            });
        }
    };

    const deleteCategory = (value) => {
        setSelectedCategory((prevCategories) => {
            return prevCategories.filter(category => category !== value);
        });
    };

    useEffect(() => {
        setSearchText('');
        setData([]);
        setSelectedDetail(null);
        setTotal(1);
        setTotalPages(1);
        setLimit(20);
        setCurrentPage(1);
        getAllJobCategories().then((res) => {
            dispatch(setJobCategories(res));
        });
        (async () => {
            await fetchData(1, 20);
            getAllSkills();
        })();
    }, [isLoggedIn]);

    const fetchHandler = (e) => {
        e?.preventDefault();
        setCurrentPage(1);
        fetchData(1, limit, false);
    };

    const handleScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
            setCurrentPage((prevPage) => prevPage + 1);
            await fetchData(currentPage + 1, limit, true);
        }
    };

    const closeDrawer = () => {
        setSelectedDetail(null);
    };

    useEffect(() => {
        fetchHandler();
    }, [sortOrder]);

    const fetchSimilarJobs = async (category) => {
        let result;
        let query = '';
        if (category.length > 0) {
            query += `&main_skill=${category.join(',')}`;
        }
        result = await getAllMarketplaceJobs(1, 4, '', '', '', 'DESC', query);
        if (result) {
            setSimilarJobs((prevData) => result.data?.data);
        }
    };

    useEffect(() => {
        if (selectedDetail) {
            fetchSimilarJobs(selectedDetail?.main_skill || [])
        }
    }, [selectedDetail]);

    useEffect(() => {
        if (search.get('id')) {
            getSingleMarketPlaceJob(search.get('id'), isLoggedIn ? `applicant_id=${userDetails?.user_id}` : '').then((res) => {
                if (res.success) {
                    setSelectedDetail(res.data)
                }
            })
        }
    }, [search, isLoggedIn])

    const renderSkeletons = () => (
        [...Array(10)].map((_, index) => (
            <div key={index} className="p-4 border">
                <div className='flex gap-4'>
                    <Skeleton variant="circular" sx={{ borderRadius: '8px' }} width={40} height={40} />
                    <div className='w-[70%]'>
                        <Skeleton variant="rectangle" sx={{ borderRadius: '8px' }} width={'100%'} height={20} />
                        <Skeleton variant="rectangle" sx={{ borderRadius: '8px', marginTop: '8px' }} width={'100%'} height={10} />
                    </div>
                </div>
                <div className='mt-4'>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="60%" />
                </div>
            </div>
        ))
    );

    const updateCount = useCallback((detail) => {
        if (userDetails?.role === roles.consultant) {
            setData(data.map((i) => {
                if (i.id === detail.id) {
                    return { ...i, alreadyApplied: true, applicant_count: (detail?.applicant_count || 0) + 1 }
                }
                return i
            }))
            setSelectedDetail({ ...selectedDetail, alreadyApplied: true, applicant_count: (detail?.applicant_count || 0) + 1 })
        }
    }, [isLoggedIn, data, selectedDetail])

    return (
        <div className="min-h-screen mb-10 bg-[white] p-8" style={{ padding: isSmallScreen ? '0px 10px' : '10px 0px' }}>
            <div className="flex">
                <main className="w-full">
                    <SearchBar sortOrder={sortOrder} setSortOrder={setSortOrder} mainSkills={mainSkills} skills={skills} fetchHandler={fetchHandler} categoryId={categoryId} setCategoryId={setCategoryId} setCurrencyType={setCurrencyType} currencyType={currencyType} selectedCategory={selectedCategory} addOrRemoveCategory={addOrRemoveCategory} deleteCategory={deleteCategory} setSelectedLayout={setSelectedLayout} selectLayout={selectLayout} searchText={searchText} setSearchText={setSearchText} />
                    <div className='flex gap-2'>
                        {(!selectedDetail) && <div className={`w-full ${selectLayout === 'grid' ? selectedDetail ? 'grid-cols-1 w-full lg:w-[40%]' : 'w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : ''} ${isSmallScreen ? '' : 'max-h-[100vh] overflow-y-scroll'} gap-4`} onScroll={handleScroll}>
                            {loading ? renderSkeletons() : data.map((details, key) => (
                                <JobCard
                                    isGrid={selectLayout === 'grid'}
                                    key={key}
                                    setSelectedDetail={() => (navigate(`?id=${details.id}`, { replace: true }), setSelectedDetail(details))}
                                    createdAt={details?.createdAt}
                                    applicant_count={details?.applicant_count}
                                    profilePhoto={details?.vendorImg}
                                    jobTitle={details?.name}
                                    company={details?.vendorName}
                                    location={details?.location}
                                    skills={details?.main_skill}
                                    updated_at={details?.updated_at}
                                    description={details?.description}
                                    alreadyApplied={details?.alreadyApplied}
                                />
                            ))}
                        </div>}
                        {/* <Drawer anchor={'bottom'} open={!!selectedDetail} onClose={closeDrawer}> */}
                        {selectedDetail && (
                            <div className='w-full flex gap-8 items-start flex-col lg:flex-row justify-between'>
                                <div className='w-full border rounded-md relative w-full h-full max-h-[100vh] overflow-y-scroll'>
                                    <IconButton onClick={closeDrawer} className="absolute top-2 left-0 right-[2px]">
                                        <CloseIcon />
                                    </IconButton>
                                    {/* <div className='flex flex-col justify-between items-start p-2'> */}
                                        {/* <div className='w-[100%]'> */}
                                            <MarketplaceCard
                                                updateCount={updateCount}
                                                setSelectedDetail={setSelectedDetail}
                                                details={selectedDetail}
                                                type={'Jobs'}
                                            />
                                        {/* </div> */}
                                    {/* </div> */}
                                </div>
                                {(similarJobs || []).filter(i => i?.id !== selectedDetail?.id)?.length > 0 && <div className='w-full lg:w-[30%]'>
                                    <div className='mr-4'>
                                        {(similarJobs || []).filter(i => i?.id !== selectedDetail?.id)?.length > 0 && <h5 className='font-bold mt-2 text-sm mb-2'>Similar Jobs</h5>}
                                        <div className="grid grid-cols-1 gap-1 overflow-y-scroll" onScroll={handleScroll}>
                                            {(similarJobs || []).filter(i => i?.id !== selectedDetail?.id).map((details, key) => (
                                                <JobCard
                                                    key={key}
                                                    setSelectedDetail={() => setSelectedDetail(details)}
                                                    createdAt={details?.createdAt}
                                                    applicant_count={details?.applicant_count}
                                                    profilePhoto={details?.vendorImg}
                                                    jobTitle={details?.name}
                                                    company={details?.vendorName}
                                                    location={details?.location}
                                                    skills={details?.main_skill}
                                                    updated_at={details?.updated_at}
                                                    alreadyApplied={details?.alreadyApplied}
                                                    description={details?.description}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        )}
                        {/* </Drawer> */}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default MarketplaceNew;
