import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Paper, Stack,
} from '@mui/material';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import AppDefaultPage from 'Pages/AppDefaultPage/AppDefault.page';
import Daywise from 'Pages/Dashboard/components/Dashboard/DashboardCards/Timesheet/Daywise';
import { TimesheetDetailTable } from 'Pages/TimesheetDetailPage/TimesheetDetailTable';
import TimesheetsTable from 'Pages/TimesheetsPage/Timesheets.table';
import api from 'Utils/api';
import { timesheetStatus } from 'Utils/constants';
import moment from 'moment';
import React from 'react';
import { Link, defer, redirect } from 'react-router-dom';
import RequireAuth from './RequireAuth';

export const timesheetRoutes = [
  {
    path: 'timesheets',
    handle: {
      crumb: () => <Link to='/dashboard/timesheets'>Timesheets</Link>,
    },
    element: (
      <RequireAuth>
        <AppDefaultPage />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        handle: {
          crumb: () => null,
        },
        element: (
          <RequireAuth>
            <Box sx={{ width: '100%', p: 2 }}>
              <TimesheetsTable />
            </Box>
          </RequireAuth>
        ),
      },
      {
        path: 'create',
        loader: async () => {
          const res = await api.post('/jobnproject/getjobsforconsultant', {
            user_id: localStorage.getItem('userId'),
          });
          if (res.status === 200 && res.data?.success) {
            return defer({
              jobs: res.data.result,
              timesheet: {},
            });
          }
          return [];
        },
        element: (
          <RequireAuth>
            <Box sx={{ width: '100%', p: 2 }}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
                <Breadcrumbs />
              </Stack>
              <Paper elevation={1} sx={{ p: 3, borderRadius: '8px', width: '100%' }}>
                <TimesheetDetailTable create />
              </Paper>
            </Box>
          </RequireAuth>
        ),
        handle: {
          crumb: () => <>Create Timesheet</>,
        },
      },
      {
        path: ':timesheetId',
        handle: {
          crumb: ({ timesheet }) => (
            <>
              <Link to={`/dashboard/jobs/${timesheet?.job?.id}/timesheets`}>{timesheet?.job?.name}</Link>
              <ChevronRight sx={{ mx: 1 }} />
              <Link to={`/dashboard/timesheets/${timesheet?.id}`} replace>
                {timesheet?.start && moment.utc(new Date(timesheet?.start)).format('dddd DD MMM YYYY')}
                {' - '}
                {timesheet?.end && moment.utc(new Date(timesheet?.end)).format('dddd DD MMM YYYY')}
              </Link>
            </>
          ),
        },
        element: (
          <RequireAuth>
            <Box sx={{ width: '100%', p: 2 }}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
                <Breadcrumbs />
              </Stack>
              <TimesheetDetailTable create={false} />
            </Box>
          </RequireAuth>
        ),
        loader: async ({ params }) => {
          const res = await api.get(`/timesheet/${params.timesheetId}`);
          if (res.status === 200) {
            return defer({ timesheet: res?.data?.result?.timesheet, jobs: [] });
          }
          throw new Response('Error', { status: 500 });
        },
        action: async ({ params, request }) => {
          try {
            const formData = await request.formData();
            const res = await api.post('/timesheet/updatetimesheet', {
              id: formData.get('id'),
              status: formData.get('submit') ? timesheetStatus.submitted : timesheetStatus.saved,
              dayData: formData.get('dayData'),
            });
            if (res.status === 200) {
              return redirect(`/dashboard/timesheets/${res.data?.timesheet?.id}`);
            }
            throw new Response('Error', { status: 500 });
          } catch (error) {
            throw new Response('Error', { status: 500 });
          }
        },
      },
    ],
  },
  {
    path: 'timesheetscard/timesheet/:token',
    element: (
      <RequireAuth>
        <Daywise />
      </RequireAuth>
    ),
  },
  {
    path: 'timesheetscard/:timesheetid',
    element: (
      <RequireAuth>
        <Daywise />
      </RequireAuth>
    ),
  },
];
