/* eslint-disable react/no-unstable-nested-components */
import {
  Box, Grid, Paper,
  Skeleton,
  Stack, Tooltip, Typography
} from '@mui/material';
import moment from 'moment/moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../../Components';
import { useProjectProvider } from '../../../../Providers/Client/Project';
import { selectUserRole } from '../../../../Redux/Feature/user';
import { StatusPill } from 'Components/StatusPill';

export default function ProjectsTable() {
  const [toggleIndex, setToggleIndex] = useState(null);
  const { projectsWithProfiles, fetchAllProjectsWithProfilesData, loading } = useProjectProvider();
  const { role, isClient } = useSelector(selectUserRole);
  const [rowCount, setRowCount] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    pageIndex: 0,
  });
  
  const [sorting, setSorting] = React.useState([
    { id: "createdAt", desc: false },
  ]);

  React.useEffect(() => {
    fetchAllProjectsWithProfilesData(role, pagination, setRowCount);
  }, [pagination]);

  const renderSkeleton = () => (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Skeleton variant="text" width="40%" />
      <Skeleton variant="text" width="20%" />
    </Box>
  );

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'title',
        header: 'Project Name',
        Cell: ({ cell, row }) => {
          return loading ? renderSkeleton() : (
            <Stack direction="column" gap={0.5}>
              <Link to={`project/${row.original.id}`} style={{ textDecoration: 'none' }}>
                <Typography
                  variant="body1Semibold"
                  color="#182743"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {cell.getValue()}
                </Typography>
              </Link>
              <Typography variant="captionNormal" color="#6B7280">
                {Object.keys(row.original.jobs || {})?.length || 0}
                {' '}
                Jobs
              </Typography>
            </Stack>
          )
        },
      },
      {
        accessorKey: 'consultants',
        header: 'Assigned',
        size: 220,
        Cell: ({ row }) => {
          const toggleShowIndex = (index) => {
            if (index === toggleIndex) {
              setToggleIndex(null);
            } else {
              setToggleIndex(index);
            }
          }

          const isToggle = row.index === toggleIndex;

          return loading ? <Skeleton variant="text" width="60%" /> : (
            <Tooltip title={row.original.consultants[0]?.filter(i => !!i?.consultant)?.map((consultant) => { return `${!!consultant?.consultant?.firstName ? consultant?.consultant?.firstName : ''} ${!!consultant?.consultant?.lastName ? consultant?.consultant?.lastName : ''}`; }).join(", ")}>
              <Typography>
                {isToggle
                  ? row.original?.consultants[0]?.filter(i => !!i?.consultant?.firstName)?.map((consultant) => { return consultant?.consultant?.firstName }).join(", ")
                  : row.original?.consultants[0]?.filter(i => !!i?.consultant?.firstName)?.slice(0, 3)?.map((consultant) => consultant?.consultant?.firstName).join(", ")
                }
                {row.original?.consultants[0] && row.original?.consultants[0]?.filter(i => !!i?.consultant)?.length > 3 &&
                  <Typography
                    sx={{ color: "#0B85BA", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
                    onClick={() => toggleShowIndex(row.index)}
                  >
                    {isToggle ? " less..." : " more..."}
                  </Typography>}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: isClient ? 'vendors' : 'client',
        header: isClient ? 'Vendors' : 'Client',
        Cell: ({ cell }) => loading ? renderSkeleton() : (
          <Stack direction="column" gap={0.5}>
            <Typography variant="body1Semibold" color="#182743">
              {isClient ? cell.getValue()?.[0]?.name : cell.getValue()?.name}
            </Typography>
          </Stack>
        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'Published On',
        Cell: ({ cell }) => loading ? <Skeleton variant="text" width="30%" /> : moment.utc(cell.getValue()).format('DD MMM YYYY'),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => loading ? <Skeleton variant="text" width="20%" /> : (
          <Stack alignItems="flex-start">
            {(() => {
                const jobs = row.original?.jobs || [];
                if (jobs?.length === 0) return <StatusPill status='OPEN' />;
                if ((jobs || []).every(job => job?.job?.status === 'CLOSED')) {
                  return <StatusPill status='CLOSED' />;
                } else if ((jobs || []).some(job => job?.job?.status === 'ONGOING')) {
                  return <StatusPill status='ONGOING' />;
                } else {
                  return <StatusPill status='OPEN' />;
                }
              })()}
          </Stack>
        ),
      },
    ]
  }, [toggleIndex, pagination.pageIndex, loading]);

  return (
    <Grid item xs={12}>
      <Paper elevation={1} sx={{ p: 3, borderRadius: '8px' }}>
        <Typography variant="subtitle1Bold" color="#182743" component="h3" sx={{ mb: 2.5 }}>
          {loading ? <Skeleton variant="text" width="30%" /> : "Projects"}
        </Typography>
        <CustomTable
          state={{
            isLoading: loading,
            sorting,
            pagination,
          }}
          paginationDisplayMode="pages"
          manualPagination
          rowCount={rowCount}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          columns={columns}
          data={projectsWithProfiles}
        />
      </Paper>
    </Grid>
  );
}
