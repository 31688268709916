import React, { Suspense } from 'react';
import { RouterProvider, useNavigate } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import dayjs_plugin_updateLocale from 'dayjs/plugin/updateLocale';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserLoggedIn, setLoading, setUser } from 'Redux/Feature/user';
import { UserInfo } from 'Pages/Auth/Helper';
import appRouter from './Routes/app.router';
import useGtag from 'hooks/useGtag';
import { gTagKey } from 'Utils/constants';
import { setUpdatedDetails } from 'Redux/Feature/profile/profile.slice';

function App() {
  const dispatch = useDispatch();
  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });

  dayjs.extend(dayjs_plugin_updateLocale);
  dayjs.updateLocale('en', {
    weekStart: 1,
  });

  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const userId = localStorage.getItem('userId');

  
  if (process.env.NODE_ENV === 'production') useGtag(gTagKey);
  React.useEffect(() => {
    dispatch(setLoading({ loading: true }));
    if (userId || isLoggedIn) {
      UserInfo()
        .then((res) => {
          if (res && res.data && res.data) {
            dispatch(setUser(res.data));
            dispatch(setUpdatedDetails({ ...res.data }))
            dispatch(setLoading({ loading: false }));
          }
          if (res && res.response && res.response?.data?.error) {
            dispatch(setLoading({ loading: false }));
            localStorage.removeItem('userId')
            window.location.pathname = '/signin'
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(setLoading({ loading: false }));
        })
        .finally(() => {
        });
    } else { dispatch(setLoading({ loading: false })); }
  }, [location.pathname]);

  return (
    <Suspense fallback={<>Loading...</>}>
      <RouterProvider router={appRouter} />
    </Suspense>
  );
}

export default App;
