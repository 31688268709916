import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Button from 'Components/Common/semantic_tags/Button';
import MuiModal from 'Components/Common/MuiModal';
import CustomInput from 'Components/Common/semantic_tags/Input';
import { addShadowResources, addShadowResourcesFromCSV } from './api';
import { useAlertProvider } from 'Providers/util/Alert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Box, Typography } from '@mui/material';
import { downloadExampleResourcesCSV } from '../../Utils/downloadCSV';

const AddShadowResource = ({ fetchResourcesDetails }) => {
    const [openAddImportModal, setOpenAddImportModal] = useState(false);
    const [file, setFile] = useState(null);
    const { success, error } = useAlertProvider();
    const { control, handleSubmit, watch } = useForm();
    const ref = useRef(null);

    const openModal = () => setOpenAddImportModal(true);
    const closeModal = () => setOpenAddImportModal(false);
    const firstName = watch('firstName')
    const lastName = watch('lastName')

    const onSubmit = async (data) => {
        const resp = await addShadowResources(data);
        if (!resp.success) {
            return error(resp.message);
        }
        success('Resource added successfully');
        closeModal();
        fetchResourcesDetails();
    };

    const handleFileUploadRef = () => {
        ref.current?.click();
    };

    const handleFileChange = (event) => setFile(event.target.files[0]);

    const removeSelectedFile = () => setFile(null);

    const uploadHandler = async () => {
        const resp = await addShadowResourcesFromCSV(file);
        if (!resp.success) {
            return error(resp.message);
        }
        success('Resources added successfully');
        closeModal();
        fetchResourcesDetails();
        setFile(null);
    };

    return (
        <>
            <Button onClick={openModal} title='Add Resource' />
            <MuiModal
                open={openAddImportModal}
                title="Add Resource"
                onCancel={closeModal}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    placeholder="First Name"
                                    className="input-class"
                                />
                            )}
                        />
                    </div>
                    <div className="mb-4">
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    placeholder="Last Name"
                                    className="input-class"
                                />
                            )}
                        />
                    </div>
                    {!file && <Button parentClassName='flex justify-end mt-4' disabled={!firstName?.trim() || !lastName?.trim()} type="submit" title='Submit' />}
                    <Typography className='text-center !text-2xl !my-6'>OR</Typography>
                    <Typography className="text-sm mb-4">
                        you can upload resources via a CSV file. Please download the template, edit it, and then upload your CSV file.
                    </Typography>
                    <div className="flex justify-start items-center mb-4">
                        <span onClick={downloadExampleResourcesCSV} className="py-2 text-[#0B85BA] flex items-center cursor-pointer">
                            <DownloadForOfflineIcon className="mr-2" /> Download Template
                        </span>
                    </div>

                    <Button
                        onClick={handleFileUploadRef}
                        title={<>Upload CSV <CloudUploadIcon /></>}
                    />
                    <input
                        ref={ref}
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    {file && (
                        <Box display="flex" alignItems="center" mt={2}>
                            <Typography variant="body2">{file.name}</Typography>
                            <CancelIcon onClick={removeSelectedFile} sx={{ cursor: 'pointer', ml: 2 }} />
                        </Box>
                    )}
                    {file && (
                        <Button
                            parentClassName='flex justify-end mt-4'
                            onClick={uploadHandler}
                            title="Upload CSV"
                        />
                    )}
                </form>
            </MuiModal>
        </>
    );
};

export default AddShadowResource;
