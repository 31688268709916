import api from '../Utils/api';

function computeEndpoint(endpoint) {
  const BASE_URL = process.env.REACT_APP_SHARED_RESOURCES_URL;
  return `${BASE_URL}/api/v1/${endpoint}`;
}

export const shareResources = (id, payload) => {
  try {
    return api.post(computeEndpoint(`user/${id}/shared-resources`), payload);
  } catch (e) {
    return false;
  }
};
