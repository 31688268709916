import React, { useEffect, useState } from 'react';
import { useProjectProvider } from '../../../../Providers/Client/Project';
import JobsTable from './JobsTable';
import ProjectCard from './ProjectCard';
import { Box, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CustomInput from 'Components/Common/semantic_tags/Input';

export default function JobsHome({
  setActive, setPostJob, setJobType, setJobId, projectId, setProjectId, searchedVal,
  valSearch, setSearchedVal,
  addUpdateMarketplace
}) {
  const { projects, fetchProjects, hasAccessToDefaultProject } = useProjectProvider();
  const [projectDetails, setProjectDetails] = useState(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [isExpand, setIsExpand] = useState(!isMobile);
  const [isExpandOnHover, setIsExpandOnHover] = useState(false);

  const handleHoverExpand = (value) => {
    if (!isExpand) {
        setIsExpandOnHover(value);
    }
} ;

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (!hasAccessToDefaultProject) {
      setProjectId(projects[0].id);
    }
  }, [hasAccessToDefaultProject]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        gap: "20px",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {(!projectDetails || !isMobile) && (
        <Box
          className={`${`
            duration-300 ease-in-out md:translate-x-0
              z-[9] bg-slate-20 border rounded-lg border-slate-100 shadow-sm
            ${isExpand
              ? "bg-white-20 w-[25%]"
              : "bg-white-20 w-[5%]"
            }
                        `
            } relative`}
          sx={{ border: "1px solid #E5E7EB", borderRadius: "8px" }}
        >
          <button
            className="absolute z-[99] top-16 -right-3 bg-white hover:bg-slate-100 text-slate-500 p-0.5 rounded-full border border-slate-200"
            onClick={() => {
              setIsExpand(!isExpand);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${isExpand ? "rotate-0" : "rotate-180"
                } transform duration-500 h-4 w-4`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <Box
            sx={{
              background: "#F9FAFB",
              height: "50px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <Typography variant="captionSemibold">
              {isExpand ? 'Projects' : <AccountTreeIcon />}
              </Typography>
          </Box>
          <Box
            style={{
              backgroundColor: projects.length === 0 && "white"
            }}
            className={`flex md:flex-col overflow-y-auto overflow-x-hidden`}
          >
            <div className="">
              {hasAccessToDefaultProject ? (
                <ProjectCard
                  isActive={isMobile ? projectDetails?.title === 'Default Project' : !projectId}
                  setProjectId={setProjectId}
                  isExpand={isExpand}
                  setProjectDetails={setProjectDetails}
                  project={{
                    id: undefined,
                    title: "Default Project",
                    createdAt: null,
                  }}
                  key=""
                />
              ) : null}
              {projects.map((project) => (
                <ProjectCard
                  isExpand={isExpand}
                  isActive={project.id == projectId}
                  setProjectId={setProjectId}
                  setProjectDetails={setProjectDetails}
                  project={project}
                  key={project.id}
                />
              ))}
            </div>
          </Box>
        </Box>
      )}
      {(!isMobile || projectDetails) && (
        <>
          {isMobile && <Typography
            marginBottom={"20px"}
            sx={{ cursor: "pointer" }}
            onClick={() => setProjectDetails(null)}
          >
            <ArrowBackIcon
              sx={{
                background: "#F3F4F6",
                width: "30px",
                marginRight: "20px",
                height: "30px",
                borderRadius: "8px",
              }}
            />
            Back
          </Typography>}
          <Box className="flex flex-col grow">
          <CustomInput
              parentClassName='!w-full mb-4'
              fullWidth
              value={valSearch}
              onChange={(e) => setSearchedVal(e.target.value)}
              placeholder="Search Resource or Job"
            />
            <div className="flex flex-1">
              <JobsTable
                setActive={setActive}
                setJobId={setJobId}
                projectId={projectId}
                addUpdateMarketplace={addUpdateMarketplace}
                searchedVal={searchedVal}
                setPostJob={setPostJob}
                setJobType={setJobType}
              />
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}
