import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useAlertProvider } from '../Providers/util/Alert';

export default function AlertToast() {
  const {
    open, handleClose, text, type,
  } = useAlertProvider();
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ zIndex: 99999 }}
    >
      <Alert severity={type} onClose={handleClose}>
        {text}
      </Alert>
    </Snackbar>
  );
}
