import React, { useState } from 'react';
import MuiModal from 'Components/Common/MuiModal';

const ShowProfileVideo = ({ profile_video_url, handleClose, open, isDashboard = false }) => {
    if (isDashboard) {
        return <div className="video-container mb-4 w-full h-full"><video
            controls
            className="w-full h-full rounded-lg"
            src={profile_video_url}
        >
            Your browser does not support the video tag.
        </video>
        </div>
    }
    return (
        <div className="video-container mb-4 w-full h-full">
            <MuiModal open={open} onCancel={handleClose} title='Profile Video'>
                <div className="flex justify-center items-center">
                    <video
                        controls
                        autoPlay
                        className="w-full max-w-3xl rounded-lg"
                        src={profile_video_url}
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>
            </MuiModal>
        </div>
    );
};

export default ShowProfileVideo;
