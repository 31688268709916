import api, { marketPlaceUrl } from "Utils/api"

export const feedbackApi = {
    getAll: 'api/feedback-type',
    feedback: 'api/feedback'
}

export const getFeedBackTypes = async () => {
    try {
        const resp = await api.get(`${marketPlaceUrl}${feedbackApi.getAll}`)
        if (resp && resp.data && resp.data) {
            return { success: true, data: resp.data || [] };
        };
        return { success: false, message: 'NO data found', data: [] };
    } catch (e) {
        return { success: false, message: 'NO data found', data: [] };
    }
}

export const createFeedBack = async (data) => {
    try {
        const resp = await api.post(`${marketPlaceUrl}${feedbackApi.feedback}`, data)
        if (resp && resp.data) {
            return { success: true, data: [resp.data] || [] };
        };
        return { success: false, message: 'Something went wrong', data: [] };
    } catch (e) {
        return { success: false, message: e?.response?.data?.error || 'Something went wrong', data: [] };
    }
}

export const getFeedBack = async (Job_id, applicant_id, isParentJobPresent) => {
    try {
        const resp = await api.get(`${marketPlaceUrl}${feedbackApi.feedback}?${isParentJobPresent ? 'parent_job_id' : 'Job_id'}=${Job_id}&givenFor=${applicant_id}`)
        if (resp && resp.data && resp.data) {
            return { success: true, data: resp.data || [] };
        };
        return { success: false, message: 'Something went wrong', data: [] };
    } catch (e) {
        return { success: false, message: e?.response?.data?.error || 'Something went wrong', data: [] };
    }
}