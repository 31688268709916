import { Button, Stack, Typography, useTheme } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal'
import { ResendOTP, SendEmailOTP } from 'Pages/Auth/Helper';
import { setLogout } from 'Redux/Feature/user';
import api from 'Utils/api';
import { removeDataFromLocalStorage } from 'Utils/helper';
import React, { useState } from 'react'
import OTPInput from 'react-otp-input';

function OtpModal({ openModal, setOpenModal, otp, setOtp, otpToken, setOtpToken, data, updateUser, success, error, dispatch, navigate}) {
  const theme = useTheme();

  const resendOTP = () => {
    SendEmailOTP({ email: data.email })
      .then((res) => {
        success(res?.data?.msg)
        setOtpToken(res.data.token);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleConfirmOTP = () => {
    updateUser({
      email: data.email,
      OTP: otp,
      OTPToken: otpToken,
      timezone: data.timezone
    })
      .then((res) => {
        if (res && res?.data?.success) {
          success('Email updated successfully, please login back again');
          removeDataFromLocalStorage()
          delete api.defaults.headers.Role;
          delete api.defaults.headers.Authorization;
          dispatch(setLogout());
          setTimeout(()=> {
            navigate('/signin');
          },2000)
        } else {
          error('An error has occurred. Please try again later.');
        }
      })
      .catch((err) => {
        console.error(err);
        error('An error has occurred. Please try again later.');
      });
  }

  return (
    <MuiModal
      open={openModal}
      onCancel={() => setOpenModal(false)}
      title='Verify OTP'
    >
      <Typography variant="body2Medium">Please enter the code sent to the email provided.</Typography>
      <Stack direction="column" alignItems="center" gap={1} sx={{ my: 4, width: '100%' }}>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            width: '40px',
            padding: theme.spacing(1),
            borderWidth: 1,
            borderColor: theme.palette.primary.main,
            outline: 'none',
            borderRadius: 5,
            ...theme.typography.body2,
          }}
          containerStyle={{
            flex: 1,
            justifyContent: 'center',
            width: '100%',
            gap: theme.spacing(1),
          }}
          renderInput={(props) => <input {...props} />}
        />
        <Button variant="text" onClick={resendOTP}>
          Resend Code
        </Button>
      </Stack>
      <Button variant="contained" fullWidth onClick={handleConfirmOTP}>Verify</Button>
    </MuiModal>
  )

}

export default OtpModal;