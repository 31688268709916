import api from "Utils/api";

export const fetchJobsApi = async (id) => {
  return api.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/jobnproject/getstandalonejobs`,
    { userId: id }
  );
};

export const fetchApplicant = async (id) => {
  return api.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/jobnproject/getstandalonejobs`,
    { userId: id }
  );
};

export const applyHandler = (data) => {
  return api.post(
    `${process.env.REACT_APP_MARKETPLACE_URL}/jobapplication/apply`,
    data
  )
}