export default function MarketplaceIcon({ color, size = '16px' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_581_4327)">
        <path d="M6.40008 12.8C5.13426 12.8 3.89687 12.4246 2.84439 11.7214C1.7919 11.0182 0.971586 10.0186 0.48718 8.84917C0.00277303 7.67973 -0.12397 6.3929 0.122979 5.15142C0.369927 3.90994 0.979475 2.76957 1.87454 1.87452C2.76961 0.979463 3.90999 0.369922 5.15148 0.122977C6.39298 -0.123968 7.67982 0.002773 8.84928 0.487174C10.0187 0.971574 11.0183 1.79188 11.7215 2.84435C12.4248 3.89683 12.8002 5.1342 12.8002 6.4C12.7982 8.0968 12.1233 9.72356 10.9235 10.9234C9.72367 12.1232 8.0969 12.7981 6.40008 12.8ZM6.40008 1.6C5.45072 1.6 4.52267 1.88152 3.73331 2.40895C2.94394 2.93638 2.32871 3.68604 1.9654 4.56312C1.6021 5.4402 1.50704 6.40532 1.69225 7.33643C1.87746 8.26754 2.33463 9.12282 3.00593 9.79411C3.67723 10.4654 4.53251 10.9226 5.46363 11.1078C6.39475 11.293 7.35988 11.1979 8.23698 10.8346C9.11407 10.4713 9.86374 9.85609 10.3912 9.06674C10.9186 8.27738 11.2001 7.34935 11.2001 6.4C11.1989 5.12735 10.6927 3.90719 9.79283 3.00729C8.89292 2.10739 7.67274 1.60127 6.40008 1.6Z" fill={color || '#9CA3AF'}/>
        <path d="M15.2002 16C14.988 16 14.7846 15.9156 14.6346 15.7656L11.4345 12.5656C11.2888 12.4147 11.2082 12.2126 11.21 12.0029C11.2118 11.7931 11.2959 11.5925 11.4443 11.4441C11.5926 11.2958 11.7932 11.2117 12.003 11.2099C12.2128 11.208 12.4148 11.2887 12.5657 11.4344L15.7658 14.6344C15.8776 14.7463 15.9538 14.8888 15.9846 15.044C16.0155 15.1991 15.9996 15.36 15.9391 15.5061C15.8786 15.6523 15.7761 15.7772 15.6445 15.8651C15.513 15.953 15.3584 16 15.2002 16Z" fill={color || '#9CA3AF'} />
      </g>
      <defs>
        <clipPath id="clip0_581_4327">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}