import {
  Avatar, Box, Button, MenuItem, OutlinedInput, Select, Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiModal from '../../Components/Common/MuiModal';
import { bgColor } from '../../Utils/constants';
import { createResources, fetchVendors } from './api';
import { useAlertProvider } from '../../Providers/util/Alert';

function ShareResourcesModal({ selectedTabs = [] }) {
  const [openShareResourceModal, setOpenShareResourceModal] = useState(false);
  const { success } = useAlertProvider();
  const [vendorList, setVendorList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (details) => {
    if (selectedItems.filter((i) => i.id === details.id).length > 0) {
      setSelectedItems((prevSelected) => prevSelected.filter((item) => item.id !== details.id));
    } else {
      setSelectedItems((prevSelected) => [...prevSelected, details]);
    }
  };

  const fetchVendorsList = async () => {
    const data = await fetchVendors();
    setVendorList(data);
  };

  useEffect(() => {
    fetchVendorsList();
  }, []);

  const openModal = () => setOpenShareResourceModal(true);
  const closeModal = () => setOpenShareResourceModal(false);

  const sharedInviteHandler = async () => {
    await createResources(selectedTabs, selectedItems);
    closeModal();
    success('Invite Shared Successfully');
  };

  return (
    <Box>
      <Button
        onClick={openModal}
        style={{
          border: '1px solid #0B85BA',
          color: '#0B85BA',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        Share
      </Button>
      <MuiModal
        open={openShareResourceModal}
        title="Share resource to"
        onCancel={closeModal}
        styling={{ padding: '20px 50px' }}
      >
        <Box>
          <Typography variant="captionSemibold" color="#182743" component="h3">Select Vendors</Typography>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            fullWidth
            value={selectedItems}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  width: 250,
                },
              },
            }}
          >
            {vendorList.map((name) => (
              <MenuItem key={name} value={name?.firstName} onClick={() => handleSelect(name)}>
                <input
                  type="checkbox"
                  checked={selectedItems.filter((i) => i.id === name.id).length > 0}
                  style={{ marginRight: 8 }}
                />
                {name?.profilePhoto ? (
                  <Avatar
                    sx={{
                      width: '30px', height: '30px', borderRadius: '5px', marginRight: '10px',
                    }}
                    alt="img"
                    src={name?.profilePhoto}
                  />
                ) : (
                  <Box
                    sx={{
                      borderRadius: '5px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '10px',
                      backgroundColor: bgColor.GREY200,
                    }}
                  >
                    {name?.firstName?.slice(0, 1)}
                  </Box>
                )}
                {/* {name.firstName
                  ? `${name?.firstName} ${name?.lastName}`
                  : name.email} */}
                {name.companyName
                  ? `${name?.companyName}`
                  : name.email}
              </MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              height: '300px',
              overflowY: 'scroll',
              marginTop: '10px',
            }}
          >
            <Box sx={{ width: '50%' }}>
              <Typography variant="captionSemibold" color="#182743" component="h3" marginTop="20px" marginBottom="10px">
                {selectedTabs.length}
                {' '}
                Resources Selected
              </Typography>
              {
                selectedTabs.map(({ firstName, lastName }) => (
                  <Box display="flex" gap="10px" alignItems="center" marginBottom="10px">
                    <Box
                      bgcolor={bgColor.GREY200}
                      color="white"
                      borderRadius="4px"
                      width="30px"
                      height="30px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {firstName?.slice(0, 1).toUpperCase()}
                      {lastName?.slice(0, 1).toUpperCase()}
                    </Box>
                    <Typography variant="body2Normal" sx={{ color: '#182743' }}>
                      {`${firstName} ${lastName}`}
                    </Typography>
                  </Box>
                ))
            }
            </Box>
            <Box
              sx={{
                borderRadius: '3px',
                borderLeft: `5px solid ${bgColor.GREY200}`,
                height: '80%',
                width: '10%',
                marginTop: '30px',
              }}
            />
            <Box sx={{ width: '50%' }}>
              <Typography variant="captionSemibold" color="#182743" component="h3" marginTop="20px" marginBottom="10px">
                {selectedItems.length}
                {' '}
                Vendors Selected
              </Typography>
              {
                selectedItems.map((details) => {
                  const { profilePhoto, firstName, lastName } = details;
                  return (
                    <Box display="flex" gap="10px" alignItems="center" marginBottom="30px">
                      {profilePhoto ? (
                        <Avatar
                          sx={{
                            width: '30px', height: '30px', borderRadius: '5px', marginRight: '10px',
                          }}
                          alt="img"
                          src={profilePhoto}
                        />
                      ) : (
                        <Box
                          sx={{
                            borderRadius: '5px',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '10px',
                            backgroundColor: bgColor.GREY200,
                          }}
                        >
                          {details?.firstName ? firstName?.slice(0, 1) : details?.email?.slice(0, 1)}
                        </Box>
                      )}
                      <Typography
                        variant="body2Normal"
                        sx={{
                          color: '#182743',
                          width: '50%',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {details?.firstName ? `${firstName} ${lastName}` : details?.email}
                      </Typography>
                      <CancelIcon onClick={() => handleSelect(details)} />
                    </Box>
                  );
                })
              }
            </Box>
          </Box>
          <Button
            onClick={sharedInviteHandler}
            fullWidth
            disabled={selectedItems.length === 0 || selectedTabs.length === 0}
            variant="contained"
            sx={{
              typography: 'captionMedium',
              color: '#F9FAFB',
              backgroundColor: '#0B85BA',
              py: 1,
              px: 1.5,
              borderRadius: '8px',
            }}
          >
            Share
          </Button>
        </Box>
      </MuiModal>
    </Box>
  );
}

export default ShareResourcesModal;
