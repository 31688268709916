import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RoleCollapseMenu from 'Components/RoleCollapseMenu';
// import clientDashboard from '../../Assets/Marketing/dashboard-screen.png';
// import getStartedWithProlegion from "../../Assets/Marketing/getStartedImage.png";
import { assetsUrl } from 'Utils/constants';

export default function MarketingPage({ navbarOptions, rolesData }) {
  
  const ref = useRef(null);
  const navigate = useNavigate();
  const clientDashboard = `${assetsUrl}dashboard-screen.png`;
  const getStartedWithProlegion = `${assetsUrl}getStartedImage.png`;

  const { designedBuisness, features, getStarted } = rolesData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=> {
    ref.current?.scrollIntoView({block: "start"})
  },[])

  return (
    <Box ref={ref} display={"flex"} flexDirection={"column"}>
      <RoleCollapseMenu
        details={navbarOptions}
      />
      <Box sx={{
        width: '100%', padding: isSmallScreen ? '0px 30px' : '0px 100px', marginBottom: '20px', paddingBottom: '80px',
      }}
      >
        <Stack
          sx={{ paddingTop: '100px' }}
          display="flex"
          flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          gap="50px"
        >
          <Stack display="flex" flexDirection="column" sx={{width: {xs: "100%", md: isSmallScreen ? '100%' : '40%'}}}>
            <Typography variant="h4Bold" sx={{color: "#050038", fontSize: {xs: "20px", sm: "30px", md: "40px"}}}>{navbarOptions.profileFeatureHeading}</Typography>
            <Typography variant="body1Normal" color="#050038CC" sx={{fontSize: {xs: "14px", md: "18px"}, paddingBottom: {xs: "20px", md:"3rem"}, paddingTop: {xs: "20px", md:"3rem"}}}>
              {navbarOptions.profileFeatureHeadingContent}
            </Typography>
            {/* <Typography variant="body1Normal" color="#6B7280" marginTop="30px">
              We are strategists, designers and developers. Innovators and problem
              solvers. Small enough to be simple and quick.
            </Typography> */}
            <Button
              onClick={() => navigate('/signin')}
              sx={{
                background: '#0B85BA', padding: '10px', marginTop: '30px', width: {xs: "130px", md: '150px'}, fontSize: "11px",
              }}
              variant="contained"
            >
              TRY PROLEGION
            </Button>
          </Stack>
          <Box 
            sx={{ borderRadius: "8px", display:{xs: "none", md: "flex"}, width: "40%" }}
          >
            <img src={navbarOptions.profileFeatureImage} alt="client dashboard" style={{borderRadius: "10px"}}/>
          </Box>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{paddingTop:{md:"180px"}, height: "100%"}}> 
        <Box sx={{ display: {xs: "none", md: "block"}, width: "40%", paddingRight: "2rem"}}>
          <img src={getStartedWithProlegion} style={{width: "85%", borderRadius: "10px"}} />
        </Box>
        <Stack flexDirection="column" justifyContent={"center"} sx={{width:{xs: "100%", md:"50%"}, paddingTop:{xs: "100px", md: "0"}}}>
          <Typography variant="h4Bold" color={"#0F0049"} lineHeight={"normal"} sx={{fontSize:{xs: "20px",sm:"40px",md:"40px"}}}>{designedBuisness.heading}</Typography>
          <Box padding={"1rem 0"} borderBottom={"1px solid #0000001A"}>
            {/* <Typography variant="defaultFont" color={"#11182780"} sx={{fontSize: {xs : "15px", md: "unset"}}}>{designedBuisness.subheading}</Typography> */}
          </Box>
          {/* <Typography variant="body1Normal" color="#6B7280">{designedBuisness.subheading}</Typography> */}
          <Stack flexDirection={'column'} sx={{ width: '100%' }}>
            {
              designedBuisness.cards?.map((card, index) => (
                <Stack flexDirection="column" key={card.heading + index}>
                  <Box display={"flex"} alignItems={"start"} padding={"40px 0"} borderBottom={"1px solid #00000010"} sx={{}}>
                  <Avatar sx={{ width: '40px', height: '40px', borderRadius: '8px' }} src={card.image} />
                  <Box display={"flex"} flexDirection={"column"}paddingLeft={"1rem"}>
                  <Typography variant="cardHeader" color={"#0F0049"} sx={{fontSize: {xs : "18px", md: "unset"}}}>{card.heading}</Typography>
                  <Typography variant="body1Normal" color="#0F004980" sx={{ fontSize: {xs : "14px", md: "unset"} ,lineHeight: "normal"}}>{card.content}</Typography>
                  </Box>
                  </Box>
                  {/* {
                    card.list.map((li) => (
                      <Typography variant="body1Normal" color="#6B7280">
                        <CheckCircleIcon sx={{ color: card.color, marginRight: '8px' }} />
                        {li}
                      </Typography>
                    ))
                  } */}
                </Stack>
              ))
            }
          </Stack>
        </Stack>
        </Stack>
      </Box>
      {/* <Stack flexDirection="column" sx={{ background: '#F9FAFB', height: isSmallScreen ? 'auto' : '120vh', padding: isSmallScreen ? '70px 10px' : '80px 100px' }}>
        <Typography variant="h4Bold" textAlign="center">{features.heading}</Typography>
        <Typography variant="body1Normal" textAlign="center" color="#6B7280">{features.subheading}</Typography>
        <Grid container spacing={6} justifyContent="center" marginTop="60px">
          {features.cards.map((card) => (
            <Grid key={card.heading} item xs={14} md={4} lg={5}>
              <Stack flexDirection="column" sx={{ padding: '20px', background: 'white', borderRadius: '8px' }}>
                <Avatar src={card.image} />
                <Typography variant="subtitle2Large">{card.heading}</Typography>
                <Typography variant="body1Normal" color="#6B7280">{card.subheading}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack> */}
      <Stack sx={{ flexDirection: { xs: "column", md: "row" }, background: '#BBEAFF26', minHeight: {xs : "unset", md: '90vh'}, padding: { xs : "3rem 1rem", md: "3rem 100px"} }} justifyContent="space-between" alignItems="center">
        <Stack flexDirection={"column"} sx={{width:{ xs: "100%", md:"42%"}}}>
          <div className='flex flex-col pb-4'>
            <Typography variant="h1" sx={{fontSize: {xs: "17px", md: "40px"}, lineHeight: "normal", paddingBottom: "2rem"}} color="#0F0049">{getStarted.heading}</Typography>
            <Typography variant="body1Normal" color="#0F0049" paddingTop={"8px"} sx={{fontSize:{xs : "12px",md:"20px"}, width: {xs : "unset", md: "100%"}}}>{getStarted.subheading}</Typography>
          </div>
        <Button
          onClick={() => navigate('/signin')}
          variant="contained"
          sx={{
            marginTop: {xs : "20px", md: '40px'}, padding: '10px', fontWeight: "bolder", fontSize: "11px", borderRadius: "8px", width: {xs : "120px", md: "140px"}
          }}
        >
          Try ProLegion
        </Button>
        </Stack>
        <Box sx={{width:{xs: "100%", sm: "60%", md:"50%"}, padding: {xs : "2rem 0", md: "1rem"}}}>
          <img src={navbarOptions.getStartedImage} style={{width: "100%", height: "100%", borderRadius: "12px"}}/>
        </Box>
      </Stack>
    </Box>
  );
}
