import { Box, Button, Typography } from '@mui/material';
import MuiModal from 'Components/Common/MuiModal'
import React from 'react'

function OfflineModePayModal({ openModal, setOpenModal, handlePayFunction, vendorDetails }) {

  return (
    <MuiModal
      open={openModal}
      onCancel={() => setOpenModal(false)}
      title='Pay Offline'
    >
      <Typography>Mark the Invoice Paid only after {vendorDetails?.firstName} {vendorDetails?.lastName} has payment for this invoice</Typography>
      <Box display={"flex"} justifyContent={"space-around"} paddingTop={"1rem"}>
        <Button variant='contained' onClick={handlePayFunction}>Mark Paid</Button>
        <Button onClick={() => setOpenModal(false)} sx={{backgroundColor: "#ff0000", color: "#fff", "&:hover": {backgroundColor: "#ff0000", boxShadow: "0 0 6px #ff0000"}}}>Cancel</Button>
      </Box>
    </MuiModal>
  )
}

export default OfflineModePayModal;