import { Autocomplete, Avatar, Box, Button, Stack, TextField, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { selectProfileDetails } from "../../../Redux/Feature/profile/profile.selector"
import MuiModal from "../../../Components/Common/MuiModal"
import { useEffect, useMemo, useState } from "react"
import { useCommonUserProvider } from "../../../Providers/Common/User"
import { OrgInfo, UpdateOrg } from "../api"
import { useAlertProvider } from "Providers/util/Alert"
import { setUpdatedDetails } from "Redux/Feature/profile/profile.slice"
import { useDispatch } from "react-redux"

const ContactExecutive = () => {
    const profileDetails = useSelector(selectProfileDetails)
    const [openChangeExecutiveModal, setOpenChangeExecutiveModal] = useState(false)
    const { getChildUsers, childUsers } = useCommonUserProvider();
    const dispatch = useDispatch()
    const [selectedExecutiveContact, setSelectedExecutiveContact] = useState({id: "" , contact_person_name: "", contact_person_email: ""});
    const {success, error} = useAlertProvider();

    const closeModal = () => setOpenChangeExecutiveModal(false)
    const openModal = () => setOpenChangeExecutiveModal(true)

    useEffect(() => {
        getChildUsers(0, 100)
    }, [])

    const mappedChildUsers = useMemo(()=>{
        return childUsers.map((item, index)=> {
            const mappedNameofFirstIndex = `${item.firstName} ${item.lastName}`
            if(index === 0) {
                return{
                    ...item,
                    name: mappedNameofFirstIndex
                }
            }
            return {
                ...item
            }
        })
    },[childUsers]);

    const handleUpdateOrganization = async () => {
        await UpdateOrg(selectedExecutiveContact).then(async (res) => {
            success("Contact Executive updated")
        }).catch((err) => {
            error("Contact Profile isn't updated")
        });
        const resp = OrgInfo(profileDetails?.organisation_details?.id)
        dispatch(setUpdatedDetails({ ...profileDetails, organisation_details: { ...resp } }))
        setOpenChangeExecutiveModal(false);
    }

    return <Box sx={{ marginTop: '40px' }}>
        <Typography variant="subtitle1Bold">Contact Executive</Typography>
        <Stack direction="row" gap={2} sx={{ marginTop: '20px', alignItems: 'center' }}>
            <Stack direction="column" gap={0.5}>
                <Avatar src={profileDetails?.profilePhoto} sx={{ borderRadius: '10px', width: '60px', height: '60px' }} />
            </Stack>
            <Stack direction="column" gap={0.5}>
                <Typography variant="body2Semibold">
                    {!profileDetails?.organisation?.contact_person_name ? `${profileDetails?.first_name} ${profileDetails.last_name}` : profileDetails?.organisation?.contact_person_name}
                </Typography> 
                <Typography variant="body2Normal" color="#6B7280">{!profileDetails?.organisation?.contact_person_email ? profileDetails?.email : profileDetails?.organisation?.contact_person_email}</Typography>
            </Stack>
            <Stack direction="column" gap={0.5}>
                <Button
                    onClick={openModal}
                    sx={{
                        borderRadius: "5px",
                        padding: '10px',
                        border: '1px solid #0B85BA',
                        color: '#0B85BA'
                    }}
                >
                    Change Contact
                </Button>
            </Stack>
        </Stack>
        <MuiModal
            open={openChangeExecutiveModal}
            onCancel={closeModal}
            minWidth={500}
        >
            <Typography variant="subtitle1Bold">Choose contact executive</Typography>
            <Autocomplete
                id="contactexecutives"
                clearIcon={false}
                sx={{ marginTop: '20px' }}
                isOptionEqualToValue={(option, value) => (option.name === value || option.name === value?.name)}
                getOptionLabel={(option) => option.name ? option.name : option}
                options={mappedChildUsers}
                onChange={(_, v) => setSelectedExecutiveContact({ id: profileDetails?.organisation?.id, contact_person_name: v.name, contact_person_email: v.email})}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Enter a name"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <Button
                onClick={handleUpdateOrganization}
                fullWidth
                sx={{
                    borderRadius: "5px",
                    padding: '10px',
                    marginTop: '300px',
                    border: '1px solid #0B85BA',
                    color: '#0B85BA'
                }}
            >
                Done
            </Button>
        </MuiModal>
    </Box>
}

export default ContactExecutive