import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CustomTable } from "Components/CustomTable";
import { fetchAllTask } from "Pages/Task/task.api";
import { useRouteLoaderData } from "react-router-dom";
import moment from "moment";

const ClosedTask = () => {
  const jobDetails = useRouteLoaderData("jobDetail");
  const [loading, setLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    pageIndex: 0,
  });

  const [sorting, setSorting] = React.useState([
    { id: "createdAt", desc: true },
  ]);

  const fetchClosedTask = async () => {
    const resp = await fetchAllTask(
      "",
      jobDetails?.data?.result?.id,
      "",
      "isclosed=CLOSED"
    );
    if (resp.success) {
      setTaskList(resp.data);
    }
    setLoading(false);
  };

  const columnsDefs = {
    title: {
      accessorKey: "name",
      header: "Title",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()}
        </Typography>
      ),
      size: 120,
    },
    assignee_details: {
      accessorKey: "assignee_details",
      header: "Assignee Name",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()?.firstName} {cell.getValue()?.lastName}
        </Typography>
      ),
      size: 120,
    },
    job_details: {
      accessorKey: "jobData",
      header: "Job Name",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()?.name || ""}
        </Typography>
      ),
      size: 120,
    },
    created_at: {
      accessorKey: "createdAt",
      header: "Job Name",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {moment(cell.getValue()).format("DD MMM, YYYY")}
        </Typography>
      ),
      size: 120,
    },
    status: {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <Typography variant="body2Normal" sx={{ color: "#182743" }}>
          {cell.getValue()}
        </Typography>
      ),
      size: 120,
    },
  };

  const columns = [
    columnsDefs.title,
    columnsDefs.assignee_details,
    columnsDefs.job_details,
    columnsDefs.created_at,
    columnsDefs.status,
  ];

  useEffect(() => {
    fetchClosedTask();
  }, []);

  return (
    <Box>
      <CustomTable
        state={{
          isLoading: loading,
          sorting,
          pagination,
        }}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        manualPagination={true}
        rowCount={rowCount}
        columns={columns}
        data={taskList}
        enableColumnActions
        enableColumnFilterModes
        enableColumnFilters
        enableTopToolbar
      />
    </Box>
  );
};

export default ClosedTask;
