import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getExperience, updateVerification } from './api'
import { styled } from '@mui/material';
import Typography from 'Components/Common/semantic_tags/Typography';
import { Card } from './Experience';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { StarRating } from 'Pages/MarketplaceNew/feedback/FeedbackModal';
import Button from 'Components/Common/semantic_tags/Button';
import MuiModal from 'Components/Common/MuiModal';
import { useAlertProvider } from 'Providers/util/Alert';
import MyEditor from 'Components/TextEditor';

const WrapperStyled = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        width: 'calc(1280px - 32px)',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: 'calc(960px - 32px)',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2),
    },
    margin: '0 auto'
}));

const ExperienceVerification = () => {
    const [details, setDetails] = useState(null)
    const { success } = useAlertProvider()
    const searchParams = new URLSearchParams(window.location.search)
    const [consent, setConsent] = useState(false)
    const [rating, setRating] = useState(0)
    const [feedbackDescription, setFeedbackDescription] = useState('')
    const params = useParams()

    const fetchHandler = async () => {
        const resp = await getExperience(params.id)
        if (resp.success) setDetails(resp.data)
    }

    const approveRejectHandler = async (action) => {
        const resp = await updateVerification(details?.id, {
            overall_rating: rating,
            feedback_description: feedbackDescription,
            approval: action === 'reject' ? 'REJECTED' : 'APPROVED',
            IsVerified: !(action === 'reject')
        })
        if (resp.success) {
            success(action === 'reject' ? 'Rejected' : 'Approved' + 'Successfully')
            setDetails({ ...details, overall_rating: rating, approval: action === 'reject' ? 'REJECTED' : 'APPROVED', IsVerified: !(action === 'reject') })
        }
    }

    useEffect(() => {
        fetchHandler()
    }, [])

    // Check if all conditions are met to enable the buttons
    const isActionEnabled = rating > 0 && feedbackDescription && consent;

    return <div className='bg-white min-w-screen min-h-screen'>
        <WrapperStyled>
            <div className='flex items-start justify-between pt-20 gap-10'>
                <div className='lg:w-[50%]'>
                    <Typography className='text-xl font-bold mb-4'>Verification Process</Typography>
                    <Typography className='mb-10 text-xs'>
                        At Prolegion, we prioritize accurate and thorough background verification to ensure that all candidates meet the highest standards. As a manager or designated representative, you have access to comprehensive details of the candidate's background experience. Review the information provided, which includes past job roles, durations, and performance summaries. Once satisfied, you can either approve or reject the experience details to move forward in the verification process.
                    </Typography>

                    <div className='space-y-4'>
                        <div className='flex items-center'>
                            <CheckCircleIcon className='text-[#3985B6] mr-2' />
                            <Typography className='text-sm fot-semibold'>Carefully examine the provided job roles, durations, and performance summaries.</Typography>
                        </div>
                        <div className='flex items-center'>
                            <AssessmentIcon className='text-[#3985B6] mr-2' />
                            <Typography className='text-sm fot-semibold'>Consider the candidate's overall rating and relevance of experience.</Typography>
                        </div>
                        <div className='flex items-center'>
                            <ThumbUpIcon className='text-[#3985B6] mr-2' />
                            <Typography className='text-sm fot-semibold'>If the details are satisfactory, click 'Approve.' Otherwise, select 'Reject.'</Typography>
                        </div>
                    </div>
                    {details?.approval === 'SENT' && <div className='border rounded-lg mt-10 p-4'>
                        {details?.approval === 'SENT' && <div className='flex flex-col'>
                            <div className=''>
                                <Typography className='font-semibold text-xs'>Rate working with {searchParams.get('name') || ''} out of 5 stars</Typography>
                                <div className='flex gap-1 items-center'>
                                    <StarRating value={details?.approval !== 'SENT' ? details?.overall_rating : rating} onChange={(details?.approval === 'SENT') ? setRating : () => { }} />
                                </div>
                            </div>
                            <div>
                                <Typography className='font-semibold text-xs mt-4 mb-2'>Give a good reference for your colleague</Typography>
                                <MyEditor
                                    html={feedbackDescription}
                                    updateRaw={setFeedbackDescription}
                                    height={150}
                                />
                            </div>
                            <div className="flex items-center mt-14">
                                <input
                                    type="checkbox"
                                    id="auditConsent"
                                    checked={consent}
                                    onChange={(e) => setConsent(e.target.checked)}
                                    className="mr-2"
                                />
                                <label htmlFor="auditConsent" className="text-gray-600 text-xs">
                                    The information provided above, can be audited by Prolegion based on requirement.*
                                </label>
                            </div>
                        </div>}
                        {(details?.approval === 'SENT') && <div className='flex justify-end gap-4 items-center mt-6'>
                            <Button
                                onClick={() => (approveRejectHandler('reject'))}
                                className={`${!isActionEnabled ? '' : 'bg-red-500 hover:!bg-red-500'}`}
                                title='Reject'
                                disabled={!isActionEnabled}
                            />
                            <Button
                                onClick={() => (approveRejectHandler('approve'))}
                                className=''
                                title='Approve'
                                disabled={!isActionEnabled}
                            />
                        </div>}
                    </div>}
                    <div className="mb-4">
                    </div>
                    {
                        details?.approval !== 'SENT' && <Typography className='text-[#3985B6] rounded-lg font-bold cursor-pointer text-xs'>
                            {details?.approval === 'APPROVED' ? 'Already Approved' : 'Already Rejected'}
                        </Typography>
                    }
                </div>
                <div className='border-2 rounded-md p-4 lg:w-[50%]'>
                    <Typography className='font-semibold'>Employee Details</Typography>
                    <div className='my-4 flex items-center justify-between'>
                        <Typography className='text-sm'><strong>Name:</strong> {searchParams.get('name')}</Typography>
                        <Typography className='text-sm'><strong>Email:</strong> {searchParams.get('email')}</Typography>
                    </div>
                    <div className='w-full'>
                        {details && <Card item={details} isPublic={true} />}
                    </div>
                </div>
            </div>
        </WrapperStyled>
    </div>
}

export default ExperienceVerification
