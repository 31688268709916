export const MuiOutlinedInputOverride = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      input: {
        height: 'unset',
      },
    },
  },
};
