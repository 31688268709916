import { createTheme } from '@mui/material';
import typographyOverrides from './typographyOverrides';
import paletteOverrides from './paletteOverrides';
import breakpointsOverrides from './breakpointsOverrides';
import componentOverrides from './componentOverrides';

const theme = createTheme({
  breakpoints: breakpointsOverrides,
  palette: paletteOverrides,
  typography: typographyOverrides,
  components: componentOverrides,
});

export default theme;
