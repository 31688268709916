import { Button, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useTimesheetProvider } from '../../../Providers/Timesheet.provider';
import { timesheetStatus } from '../../../Utils/constants';
import { useParams,useNavigate } from 'react-router-dom';

export default function TimesheetApproveReject() {
  const [comments, setComments] = React.useState('');
  const params = useParams()
  const navigate = useNavigate()
  const {
    approveTimesheet, canApproveTimesheet, fetchTimesheetDetails, rejectTimesheet, timesheetDetails,
  } = useTimesheetProvider();

  useEffect(() => {
    if (params.timesheetId) {
      fetchTimesheetDetails(params.timesheetId);
    }
  }, []);

  const handleApprove = () => approveTimesheet(timesheetDetails.id, comments).then((success) => {
    if (success) {
      fetchTimesheetDetails(timesheetDetails.id);
      navigate("/dashboard/timesheets")
    }
  });

  const handleReject = () => rejectTimesheet(timesheetDetails.id, comments).then((success) => {
    if (success) {
      fetchTimesheetDetails(timesheetDetails.id);
    }
  });

  return canApproveTimesheet && timesheetDetails?.status === timesheetStatus.submitted && (
    <>
      <TextField
        multiline
        rows={4}
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        label="Comments"
      />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" mt={2} gap={2}>
        <Button
          variant="contained"
          color="error"
          onClick={handleReject}
          sx={{
            typography: 'captionMedium',
            borderColor: '#0B85BA',
            py: 1,
            px: 1.5,
            borderRadius: '8px',
          }}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleApprove}
          sx={{
            typography: 'captionMedium',
            borderColor: '#0B85BA',
            py: 1,
            px: 1.5,
            borderRadius: '8px',
          }}
        >
          Approve
        </Button>
      </Stack>
    </>
  );
}
