import * as yup from 'yup';

export const ExperienceSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    company_name: yup.string().required('Company name is required'),
    employment_type: yup.string().required('Employment Type is required'),
    location: yup.string().required('Location is required'),
    start_date: yup.string().required('Start date is required').nullable(),
    description: yup.string(),
    currently_working: yup.boolean(),
    end_date: yup.string()
        .when('currently_working', (currently_working, schema) => {
            if (!currently_working) {
                return schema.required('End date is required');
            }
            return schema;
        }),
});


const emailValidator = (email) => {
    const disallowedDomains = ['gmail.com', 'yopmail.com', 'tempmail.com'];
    const domain = email.split('@')[1];
    return !disallowedDomains.includes(domain);
};

export const CompanyDetailsSchema = yup.object().shape({
    companyName: yup.string().required('Company name is required'),
    recipientEmail: yup.string()
        .email('Invalid email address')
        .test('not-temporary', 'Temporary email addresses are not allowed', emailValidator)
        .required('Recipient email is required'),
    managerName: yup.string().required('Manager name is required'),
    auditConsent: yup.boolean().oneOf([true], 'Audit consent is required'),
});