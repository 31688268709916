import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { baseURL } from '../../Utils/api';
import { BallTriangle } from 'react-loader-spinner';
import {
  Alert, Card, Stack, TextField, Typography, useTheme,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePassword } from '../../Utils/validation';
import { InvitedUser } from './Helper';
import Button from 'Components/Common/semantic_tags/Button';
import OTPInput from 'react-otp-input';

function ForgotPassword() {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [otpToken, setOtpToken] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const [inValidEmailAlert, setInValidEmailAlert] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const emailValue = watch('email');

  useEffect(() => {
    if (id) {
      InvitedUser(id).then((res) => {
        if (!res.data?.result?.isInvite) {
          setEmailVerified(true);
          setEmail(res.data?.result?.email);
        } else {
          Swal.fire('', 'Invalid Password Reset Link', 'info');
          navigate('/signin');
        }
      });
    }
  }, [id]);

  useEffect(() => {

  }, [emailVerified]);

  function requestOTP(data) {
    axios
      .post(`${baseURL}/auth/resetpasswordotp`, { email: data.email })
      .then((response) => {
        setEmail(data.email);
        setOtpToken(response.data.token);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setInValidEmailAlert(error.response.data.msg);
        } else {
          setInValidEmailAlert('Something went wrong');
        }
        console.error(error.message);
      });
  }

  function verifyOTP(e) {
    e.preventDefault();
    if (!otpToken || !code) {
      return;
    }
    axios
      .post(`${baseURL}/auth/verifyotp`, { OTPToken: otpToken, OTP: code })
      .then(() => {
        setEmailVerified(true);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: error.response?.data?.msg,
          icon: 'error',
        });
      });
  }

  async function setPasswordSubmit(e) {
    e.preventDefault();
    if (!emailVerified || !CheckPassword()) {
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire('', 'Password is not matching with confirm password', 'info');
      return;
    }
    setLoading(true);
    // let keycloakid = "";
    // await axios
    //   .post(`${baseURL}/auth/getuserinfo`, {
    //     email: email,
    //   })
    //   .then((response) => {
    //     keycloakid = response.data.result.id;
    //   })
    //   .catch((error) => console.log(error.message));

    await axios
      .post(`${baseURL}/auth/setpassword`, {
        email,
        inviteId: id,
        newPassword: password,
      })
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          Swal.fire('', 'Password updated successfully', 'success').then(() => {
            navigate('/signin');
          });
        } else {
          Swal.fire('', 'Unable to update password', 'info').then(() => {
            navigate('/signin');
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        Swal.fire('', 'Something went wrong', 'error');
        navigate('/signin');
      });
  }
  function CheckPassword() {
    const res = validatePassword(password);
    if (res === true) {
      return true;
    }
    setPasswordError(res);
    return false;
  }
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%", backgroundColor: "#cfd8dc60", minHeight: '70vh' }}
    >
      {" "}
      {/* <BrandedPaper> */}
      <Card sx={{ padding: "30px" }}>
        <Stack alignItems="center" justifyContent="center">
          {loading ? (
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color={theme.palette.primary.main}
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible
            />
          ) : (
            !otpToken &&
            !id && (
              <form onSubmit={handleSubmit(requestOTP)}>
                <Typography variant="h5Bold">Reset password</Typography>
                <Typography variant="body2" my={3}>
                  To reset your password, enter the email address you use to
                  sign in to ProLegion.
                </Typography>
                <Stack alignItems="center" gap={3}>
                  {inValidEmailAlert && (
                    <Alert severity="error">{inValidEmailAlert}</Alert>
                  )}
                  <TextField
                    type="text"
                    placeholder='name@example.com'
                    label="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    fullWidth
                  />
                  {errors.email ? (
                    <Typography variant="subtitle2" color="error.main">
                      {errors.email.message}
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Button
                    type="submit"
                    parentClassName='!w-full'
                    className='w-full'
                    disabled={loading || !emailValue?.length}
                    title='Continue'
                  />
                </Stack>
              </form>
            )
          )}
          {!emailVerified && otpToken && (
            <div className="otp-container">
              <form className="otp-form-container" onSubmit={verifyOTP}>
                <p className="mb-4">
                  Check your email and use the OTP sent to you.
                </p>
                <div className='flex justify-center my-4'>
                <OTPInput
                  value={code}
                  onChange={(e) => {
                    setCode(e);
                  }}
                  numInputs={6}
                  inputStyle={{
                    width: '40px',
                    padding: theme.spacing(1),
                    borderWidth: 1,
                    borderColor: theme.palette.primary.main,
                    outline: 'none',
                    borderRadius: 5,
                    ...theme.typography.body2,
                  }}
                  containerStyle={{
                    flex: 1,
                    justifyContent: 'center',
                    width: 'fit-content',
                    gap: theme.spacing(1),
                  }}
                  renderInput={(props) => <input {...props} />}
                />
                </div>
                <Button
                  type="submit"
                  parentClassName='w-full'
                  className='w-full'
                  title='Verify OTP'
                />
              </form>
            </div>
          )}
          {emailVerified && (
            <div>
              <form onSubmit={setPasswordSubmit}>
                <h2 className="mb-3">Set new password for your account</h2>
                <TextField
                  className="mb-3"
                  type="password"
                  id="outlined-basic"
                  margin="normal"
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  className="mb-3"
                  type="password"
                  id="outlined-basic"
                  margin="normal"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                />
                {passwordError.length > 0 && (
                  <h4 className="text-red-600 text-xs pt-2 pb-2">
                    {passwordError}
                  </h4>
                )}
                <Button
                  type="submit"
                  parentClassName='!w-full'
                  className='w-full'
                  title={id ? "Set Password" : "Change Password"}
                />
              </form>
            </div>
          )}
        </Stack>
      </Card>
      {/* </BrandedPaper> */}
    </Stack>
  );
}

export default ForgotPassword;
