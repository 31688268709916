//for RazorPay amount
export const razorPayItemAmount = ({ unit_amount, amount }) => {
  if (unit_amount) return unit_amount;
  return amount;
};

//RazorPay Popup Script
export const handleRazorPaySubscriptionRedirect = (subscription, { first_name, last_name, email }) => {
  const options = {
    // key: "rzp_test_A5e1hLfAh7ZgEA",
    key: process.env.REACT_APP_RAZORPAY_KEY,
    subscription_id: subscription.id,
    name: "ProLegion Pvt. Ltd",
    description: "Monthly Test Plan",
    image: "/prolegion_favicon.ico",
    callback_url: `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/webhooks/razorpay-subscription`,
    prefill: {
      name: `${first_name} ${last_name}`,
      email: email,
    },
    theme: {
      color: "rgb(11, 133, 186)",
    },
  };
  const rzp1 = new window.Razorpay(options);
  rzp1.open();
};

export const checkIncreasedSizeAndDisable = (previousState, currentState) => {
  let isSizeIncreased = false;

  const updatedState = currentState.map((currentItem, index) => {
    const previousItem = previousState[index];
    if (currentItem.size > previousItem.size) {
      isSizeIncreased = true;
      return { ...currentItem, disabled: false };
    } else {
      return { ...currentItem, disabled: false };
    }
  });

  if (isSizeIncreased) {
    return updatedState.map((item, index) => {
      if (item.size === currentState[index].size) {
        return { ...item, disabled: true };
      }
      return item;
    });
  }

  return updatedState;
};


export const allPlansList = {
  'CLIENT': [
    {
      type: "Basic",
      name: '',
      iconType: 'free',
      description: "For Individuals and Small Teams",
      additional: "What’s included",
      price: "Free",
      features: [
        "1 Account User",
        "Manage 1 Vendor",
        "View Timesheets",
        "Create Company Profile",
        "Create Unlimited Jobs",
        "Publish Jobs in Marketplace",
        "Help and Support",
      ],
      buttonText: "Get started",
      freeButtonText: "Get started",
      isFree: true,
      remove: true
    },
    {
      type: "Professional",
      iconType: 'pro',
      name: 'Client professional plan',
      description: "For Growing Teams",
      additional: "Everything Free Plan has Plus - ",
      monthlyPrice: { inr: '2999', usd: '49' },
      yearlyPrice: { inr: '24000', usd: '392' },
      features: [
        "Manage Resources",
        "AI Enabled Features",
        "Manage up-to 5 Vendors",
        "Manage up-to 2 Account Users",
        "Manage Timesheets",
        "Manage Invoices",
        "Explore New Vendors in Marketplace",
        "View Allocated Resources",
      ],
      buttonText: "Upgrade Now",
      freeButtonText: "Start Free Trial",
    },
    {
      type: "Enterprise",
      iconType: 'enterprise',
      name: 'Client enterprise plan',
      monthlyPrice: { inr: '4999', usd: '79' },
      yearlyPrice: { inr: '40000', usd: '632' },
      description: "For Industry Leaders",
      additional: "Everything Professional has Plus - ",
      index: 1,
      features: [
        "Manage Unlimited Vendors",
        "      Manage Unlimited Account users",
        "      Personalized Account Assistance",
        "      Adequate Resource Pool",
        "      Priority Help and Support",
      ],
      buttonText: "Upgrade Now",
      freeButtonText: "Start Free Trial",
    }
  ],
  'VENDOR': [
    {
      type: "Basic",
      name: 'basic plan',
      iconType: 'free',
      description: "For Individuals and Small Teams",
      additional: "What’s included",
      monthlyPrice: { inr: '999', usd: '19' },
      yearlyPrice: { inr: '8000', usd: '152' },
      features: [
        "Manage Clients",
        "Manage Vendors",
        "Build Company Profile",
        "Manage up-to 5 Resources ",
        "Manage up-to 2 Account Users. ",
        "Share Resources to partners",
        "Manage Timesheets & Invoices",
        "Help and Support",
      ],
      buttonText: "Upgrade Now",
      freeButtonText: "Start Free Trial",
    },
    {
      type: "Professional",
      iconType: 'pro',
      name: 'professional plan',
      description: "For Growing Teams",
      additional: "Everything Basic Plan has Plus - ",
      monthlyPrice: { inr: '2999', usd: '49' },
      yearlyPrice: { inr: '24000', usd: '392' },
      features: [
        "Publish Up-to 5 Jobs in Marketplace",
        "AI Enabled Features",
        "Manage up-to 3 Account Users",
        "Manage up-to 10 Resources",
        "Apply for Jobs in Marketplace",
        "Manage Job Applicants",
      ],
      buttonText: "Upgrade Now",
      freeButtonText: "Start Free Trial",
    },
    {
      type: "Enterprise",
      iconType: 'enterprise',
      name: 'enterprise plan',
      description: "For Industry Leaders",
      additional: "Everything Professional has Plus - ",
      monthlyPrice: { inr: '5999', usd: '99' },
      yearlyPrice: { inr: '48000', usd: '792' },
      index: 1,
      features: [
        "Manage Unlimited Resources",
        "Manage Unlimited Account users",
        "Publish Unlimited Jobs in Marketplace",
        "AI Features",
        "Personalized Account Assistance",
        "Priority Help and Support",
      ],
      buttonText: "Upgrade Now",
      freeButtonText: "Start Free Trial",
    }
  ],
  'CONSULTANT': [
    {
      type: "Basic",
      name: 'basic plan',
      iconType: 'free',
      price: 'Free',
      description: "For Individuals",
      additional: "What’s included",
      monthlyPrice: '',
      yearlyPrice: '',
      features: [
        "ProLegion Profile",
        "Submit / Manage Timesheets",
        "Manage Jobs",
        "References",
        "Find Jobs",
        "Publish Profile"
      ],
      buttonText: "Get started",
      freeButtonText: "Get Started"
    }
  ]
};
