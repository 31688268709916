import { Card, CardActionArea, Grid } from '@mui/material';
import React from 'react';

export default function DashboardWidget({ onClick, children }) {
  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ height: '100%', borderRadius: '8px' }}>
        <CardActionArea
          onClick={onClick}
          sx={{
            minHeight: '125px',
            backgroundColor: 'common.white',
            display: 'flex',
            direction: 'column',
            alignItems: 'flex-start',
            p: (theme) => theme.spacing(2, 2.5),
          }}
        >
          {children}
        </CardActionArea>
      </Card>
    </Grid>
  );
}
