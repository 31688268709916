import ErrorScreen from 'Components/Common/ErrorScreen';
import { SiteWrapper } from 'Components/Layout';
import React from 'react';
import { useRouteError } from 'react-router-dom';

export function RootErrorBoundary() {
  const error = useRouteError();
  return (
    <SiteWrapper marketing={false}>
      <ErrorScreen
        statusCode={500}
        errorHeading='ROOT - Uh oh, something went terribly wrong 😩'
        errorDescription={error.message || JSON.stringify(error)}
      />
    </SiteWrapper>
  );
}
