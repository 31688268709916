import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { CustomTable } from "Components";
import { getAllDocuments, updateDocument } from "./document.api";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MuiModal from "Components/Common/MuiModal";
import ViewDetailModal from "./ViewDetailModal";
import { useAlertProvider } from "Providers/util/Alert";
import moment from "moment";
import ViewDescriptionModal from "./ViewDescriptionModal";
import { selectUserRole } from "Redux/Feature/user";
import { useSelector } from "react-redux";

const DocumentTable = ({ type = 0, documentList, getDocuments }) => {
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = React.useState(0);
    const { success, error } = useAlertProvider()
    const [rejectPopup, setRejectPopup] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState(null);
    const [openDetailsModal, setOpenDetailModal] = useState(false);
    const [openDescription, setOpenDescriptionModal] = useState(false);
    const [columnIndex, setColumnIndex] = useState(type)
    const [pagination, setPagination] = React.useState({
      pageSize: 10,
      pageIndex: 0,
    });
    
    useEffect(() => {
      setColumnIndex(type)
    }, [type])

    const [sorting, setSorting] = React.useState([
      { id: "createdAt", desc: true },
    ]);

    const handlePopup = (details) => {
      setRejectPopup(true)
      setSelectedDetails(details) 
    }

    const approveHandler = async (details) => {
      const resp = await updateDocument(details.id, { status: 'ACCEPTED'})
      if (resp && resp.success) {
        success('Document accepted successfully')
        getDocuments()
        return
      }
      error('Something went wrong!!!')
    }
    const rejectHandler = async () => {
      const resp = await updateDocument(selectedDetails.id, { status: 'REJECTED', comment: selectedDetails.comment})
      if (resp && resp.success) {
        success('Document rejected successfully')
        setRejectPopup(false)
        getDocuments()
        return
      }
      error('Something went wrong!!!')
    }

    const columnsDefs = {
        name: {
          accessorKey: "name",
          header: "Document Title",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell.getValue()}
            </Typography>
          ),
          size: 120,
        },
        isOpened: {
          accessorKey: "receiver_user",
          header: "Document Title",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell?.getValue()?.firstName || ''}{' '}{cell?.getValue()?.lastName || ''}
            </Typography>
          ),
          size: 120,
        },
        senderuser: {
          accessorKey: "sender_user",
          header: "Contact Person",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell?.getValue()?.firstName || ''}{' '}{cell?.getValue()?.lastName || ''}
            </Typography>
          ),
          size: 120,
        },
        companyName: {
          accessorKey: "sender_user",
          header: "Company Name",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell?.getValue()?.company || ''}
            </Typography>
          ),
          size: 120,
        },
        viewDocument: {
          accessorKey: "file_path",
          header: "View Document",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#0B85BA", cursor: 'pointer' }} onClick={() => window.open(cell?.getValue(), '__blank')}>
              <RemoveRedEyeIcon />
            </Typography>
          ),
          size: 120,
        },
        action: {
          accessorKey: "status",
          header: "Action Performed",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell?.getValue()}
            </Typography>
          ),
          size: 120,
        },
        viewDetails: {
          accessorKey: "document_id",
          header: "View Details",
          Cell: ({ row }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743", cursor: 'pointer' }} onClick={() => (setOpenDetailModal(true), setSelectedDetails(row.original))}>
              View Details
            </Typography>
          ),
          size: 120,
        },
        approveReject: {
          accessorKey: "status",
          header: "Action Performed",
          Cell: ({ row }) => (
            row.original.isApprovedRequired ? row.original.status === 'REQUESTED' ? <Box >
                <Typography variant="body2Normal" sx={{ color: "#0B85BA", cursor: 'pointer' }} onClick={() => approveHandler(row.original)}>Approve</Typography>
                <Typography variant="body2Normal" onClick={() => handlePopup(row.original)} sx={{ paddingLeft: '10px', color: "red", cursor: 'pointer' }}>Reject</Typography>
            </Box>
            : row.original.status === 'ACCEPTED' ? <Box sx={{ color: '#0B85BA' }} variant="body2Normal">Accepted</Box>
            : <Box sx={{ color: 'red' }} variant="body2Normal">Rejected</Box> : ''
          ),
          size: 120,
        },
        comment: {
          accessorKey: "comment",
          header: "Comment (If any)",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell?.getValue()}
            </Typography>
          ),
          size: 120,
        },
        statusOfBlock: {
          accessorKey: "status",
          header: "Status",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {cell?.getValue()}
            </Typography>
          ),
          size: 120,
        },
        documentSendDate : {
          accessorKey: "created_date",
          header: "Received Date",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {/* {cell?.getValue()} */}
              {moment(cell?.getValue()).format("DD MMM, YYYY")}
            </Typography>
          ),
          size: 120
        },
        documentReceivedDate : {
          accessorKey: "created_date",
          header: "Send Date",
          Cell: ({ cell }) => (
            <Typography variant="body2Normal" sx={{ color: "#182743" }}>
              {/* {cell?.getValue()} */}
              {moment(cell?.getValue()).format("DD MMM, YYYY")}
            </Typography>
          ),
          size: 120
        },
        description : {
          accessorKey: "description",
          header: "Description",
          Cell: ({ cell }) => cell.getValue() === null ? (<Typography variant="defaultFont">No Description</Typography>) : (
            <Typography variant="body2Normal" sx={{ color: "#182743", cursor: 'pointer' }} onClick={() => (setOpenDescriptionModal(true), setSelectedDescription(cell.getValue()))}>
              View Description
            </Typography>
          ),
          size: 120
        }
      };
    
      const columns = [
        [
            columnsDefs.name,
            columnsDefs.companyName,
            columnsDefs.senderuser,
            columnsDefs.viewDocument,
            columnsDefs.description,
            // columnsDefs.approveReject,
            // columnsDefs.statusOfBlock,
            columnsDefs.documentSendDate,
        ],
        [
        columnsDefs.name,
        columnsDefs.description,
        columnsDefs.viewDocument,
        columnsDefs.viewDetails,
        columnsDefs.documentReceivedDate
        ],
        [
        columnsDefs.name,
        columnsDefs.description,
        columnsDefs.viewDocument
        ]
      ]
      
  return (
    <Box marginTop={'40px'}>
      {type ===0 && <CustomTable
        state={{
          isLoading: loading,
          // sorting,
          // pagination,
        }}
        // onPaginationChange={setPagination}
        // onSortingChange={setSorting}
        // manualPagination={true}
        // rowCount={rowCount}
        columns={columns[0]}
        data={documentList}
        enableColumnActions
        enableColumnFilterModes
        enableColumnFilters
        enableTopToolbar
      />}
      {type ===1 && <CustomTable
        state={{
          isLoading: loading,
          // sorting,
          // pagination,
        }}
        // onPaginationChange={setPagination}
        // onSortingChange={setSorting}
        // manualPagination={true}
        // rowCount={rowCount}
        columns={columns[1]}
        data={documentList}
        enableColumnActions
        enableColumnFilterModes
        enableColumnFilters
        enableTopToolbar
      />}
      {type === 2 && <CustomTable
        state={{
          isLoading: loading,
          // sorting,
          // pagination,
        }}
        // onPaginationChange={setPagination}
        // onSortingChange={setSorting}
        // manualPagination={true}
        // rowCount={rowCount}
        columns={columns[2]}
        data={documentList}
        enableColumnActions
        enableColumnFilterModes
        enableColumnFilters
        enableTopToolbar
      />}
      <ViewDetailModal details={selectedDetails} open={openDetailsModal} onClose={() => setOpenDetailModal(false)} />
      <ViewDescriptionModal description={selectedDescription} open={openDescription} onClose={() => setOpenDescriptionModal(false)} />
      <MuiModal
        open={rejectPopup}
        onCancel={() => setRejectPopup(false)}
        maxWith="sm"
      >
        <Stack flexDirection={'column'}>
        <Typography variant="cardHeader">Please enter reason and click on confirm to reject the proposal.</Typography>
        <TextField sx={{ marginTop: '20px' }} placeholder="comment" value={selectedDetails?.comment} onChange={(e) => setSelectedDetails({ ...selectedDetails, comment: e.target.value })} />

        <Button fullWidth variant="contained" sx={{ padding: '10px', marginTop: '20px' }} onClick={rejectHandler}>Confirm</Button>
        </Stack>
      </MuiModal>
    </Box>
  );
};

export default DocumentTable;
