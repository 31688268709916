import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { userPermissions } from "../../Utils/constants";
import { useJobProvider } from "../../Providers/Job.provider";
import { getPermissions } from "Utils/helper";

const invoiceData = [
  {
    label: "Fixed Invoice",
    value: "fixed",
  },
  {
    label: "Flexible Invoice",
    value: "flexible",
  },
  {
    label: "Jobs based Invoice",
    value: "jobs",
  },
];

export default function CreateInvoiceButton() {
  const navigate = useNavigate();
  const { fetchJobsHavingTimesheet } = useJobProvider();
  const permissions = getPermissions();
  const [selectedInvoice, setSelectedInvoice] = useState("");

  const canAdd = permissions.includes(userPermissions.invoices.add); // && jobs.length > 0;

  useEffect(() => {
    if (permissions.includes(userPermissions.invoices.add)) {
      fetchJobsHavingTimesheet();
    }
  }, []);

  const handleContinue = (event) => {
    const value = event.target.value;
    setSelectedInvoice(value);
    if (value === "fixed") return navigate("/dashboard/invoices/create");
    else if (value === "flexible")
      return navigate("/dashboard/invoices/flexible/create");
    return navigate("/dashboard/invoices/jobs/create");
  };

  return !canAdd ? null : (
    <FormControl variant="outlined" sx={{ width: '20%' }}>
      <Select
        value={selectedInvoice}
        onChange={handleContinue}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                <AddIcon sx={{ marginRight: 1 }} />
                Generate Invoice
              </Box>
            );
          }
          const selectedOption = invoiceData.find(option => option.value === selected);
          return selectedOption?.label || '';
        }}
        inputProps={{
          sx: { color: '#ffffff' }
        }}
      >
        {invoiceData.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
