import { Add } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissions } from 'Utils/helper';
import { userPermissions } from '../../Utils/constants';
import Button from 'Components/Common/semantic_tags/Button';

export default function CreateTimesheetLink() {
  const navigate = useNavigate();
  const permissions = getPermissions();

  const canAdd = permissions.includes(userPermissions.timesheet.add);

  const handleClick = () => {
    navigate('/dashboard/timesheets/create');
  };

  return !canAdd ? <></> : (
    <Button
      onClick={handleClick}
      title={<><Add sx={{ color: 'white' }} />New Timesheet</>}
    />
  );
}
