import { Avatar, Box, Paper, Typography } from '@mui/material'
import { CustomTable } from 'Components';
import React from 'react';
import { fetchResourcesListofClient } from '../api';
import { Link } from 'react-router-dom';
import { stringAvatar } from 'Utils/avatar';

function ClientResourcePage() {
  const [resources, setResources] = React.useState([]);
  const [toggleIndex, setToggleIndex] = React.useState(null);
  const [rowCount, setRowCount] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    pageIndex: 0,
  });

  const [sorting, setSorting] = React.useState([
    { id: "createdAt", desc: true },
  ]);

  const resourcesColumnDefs = {
    serialNumber: {
      accessorKey: "serialNumber",
      header: "Sno.",
      Cell: ({row, cell}) => {
        return(
          <Typography variant="defaultFont">{cell?.getValue()}</Typography>
        )
      }
    },
    name: {
      accessorKey: "name",
      header: "Name",
      Cell: ({row, cell}) => {
        return(
          <Link to={`/${row?.original?.username}`} target='_blank'>
            <div className="flex items-center">
              <Avatar
                {...stringAvatar(row?.original?.name)}
                className="mr-2.5"
                size="large"
                src={row?.original?.profilePhoto}
              />
              <span>
                {cell?.getValue()}
              </span>
            </div>
          </Link>
        )
      }
    },
    companyName: {
      accessorKey: "companyName",
      header: "Vendor Name",
      Cell: ({row, cell}) => {
        return(
          <Link to={`/${row?.original?.vendorUsername}`} target='_blank'>
            <div className="flex items-center">
              <Avatar
                {...stringAvatar(row?.original?.companyName)}
                className="mr-2.5"
                size="large"
                src={row?.original?.vendorCompanyProfilePhoto}
              />
              <span>
                {row?.original?.companyName}
              </span>
            </div>
          </Link>
        )
      }
    },
    jobs: {
      accessorKey: "jobs",
      header: "Jobs",
      Cell: ({ cell, row }) => {
        const toggleShowIndex = (index) => {
          if (index === toggleIndex) {
            setToggleIndex(null);
          } else {
            setToggleIndex(index);
          }
        }

        const isToggle = row.index === toggleIndex;

        return (
        <Typography variant="defaultFont">
          {isToggle
            ? row?.original?.jobs?.join(", ")
            : row?.original?.jobs?.slice(0, 3).join(", ")
          }
          {row?.original?.jobs?.length > 3 &&
            <Typography
              variant='defaultFont'
              sx={{ color: "#0B85BA", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
              onClick={() => toggleShowIndex(row.index)}
            >
              {isToggle ? " less..." : " more..."}
            </Typography>}
        </Typography>
        )
      },
    },
    timesheets: {
      accessorKey: "tiemsheets",
      header: "Timesheet",
      Cell: ({row, cell}) => {
        return(
          <Link to={`/dashboard/timesheets?jobIds=${row?.original?.jobIds}&page=1&pageSize=10`} variant='defaultFont' style={{color: "#0B85BA"}}>View Details</Link>
        )
      }
    }
  }
  
  const resourcesColumns = [
    // resourcesColumnDefs.serialNumber,
    resourcesColumnDefs.name,
    resourcesColumnDefs.companyName,
    resourcesColumnDefs.jobs,
    resourcesColumnDefs.timesheets
  ]
  
  React.useEffect(()=> {
    fetchResourcesListofClient(pagination,setRowCount).then((res)=>{
      setResources(res);
    })
  },[pagination]);

  const resourcesMapping = React.useMemo(()=>{
    return resources?.map((item, index)=> {
      const activeJobsIds = item?.active_jobs?.map((item)=>item?.id);
      const jobsName = item?.active_jobs?.map((item) => item?.name);
      return {
        serialNumber: index + 1,
        username: item?.consultant?.username,
        name: `${item?.consultant?.firstName} ${item?.consultant?.lastName}`,
        companyName: item?.vendor?.company,
        profilePhoto: item?.consultant?.profilePhoto,
        vendorCompanyProfilePhoto: item?.vendor?.profilePhoto,
        vendorUsername: item?.vendor?.username,
        jobIds: JSON.stringify(activeJobsIds),
        jobs: jobsName
      }
    })
  },[resources]);


  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={1} sx={{ p: 3, borderRadius: "8px", width: "100%" }}>
        <Box paddingBottom={"1rem"}>
          <Typography variant='h1' sx={{ fontSize: "20px", color: "#182743", lineHeight: "normal" }}>Resources</Typography>
        </Box>
        <CustomTable
          state={{
            // isLoading: loading,
            sorting,
            pagination,
          }}
          data={resourcesMapping}
          columns={resourcesColumns}
          manualPagination
          paginationDisplayMode="pages"
          rowCount={rowCount}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
        />
      </Paper>
    </Box>
  )
}

export default ClientResourcePage;