import {
    Button,
    IconButton, Stack, Tooltip, Typography,
  } from '@mui/material';
  import React from 'react';
  import { ManageAccounts, NoAccounts } from '@mui/icons-material';
  import { useCommonUserProvider } from '../../../Providers/Common/User';
  import { CustomTable } from '../../../Components';
  import { ModifyUserModal } from './ModifyUserModal';
import ClientModifyUserModal from './ModifyUserModal/ClientModifyUser.modal';
  
  export default function ClientUserManagementTab() {
    const {
      enableDisableUser, getChildUsers, childUsers,
    } = useCommonUserProvider();
  
    const [modalOpen, setModalOpen] = React.useState(false);
    const [userIdToEdit, setUserIdToEdit] = React.useState(null);
  
    const handleEditUser = (userId) => {
      setModalOpen(true);
      setUserIdToEdit(userId);
    };
  
    const handleModalClose = () => {
      setModalOpen(false);
      setUserIdToEdit(null);
    };
  
    const columns = React.useMemo(() => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell, row }) => {
          return(
            <Typography variant="body1Semibold" sx={{ color: '#182743', fontWeight: `${row.index === 0 && "bolder"}` }}>
              {row.index === 0 && `${row?.original?.firstName} ${row.original?.lastName}`}
              {row.original.user ? `${row?.original.user?.firstName} ${row.original.user?.lastName}` : cell.getValue()}
            </Typography>
          )
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        Cell: ({ cell, row }) => (
          <Typography variant="body2Normal" sx={{ color: '#6B7280', fontWeight: `${row.index === 0 && "bolder"}` }}>{cell.getValue()}</Typography>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <Stack direction="row" gap={2}>
            {row.original.user && (
              <>
                <Tooltip title="Edit User">
                  <IconButton
                    onClick={() => handleEditUser(row.original.user?.id)}
                  >
                    <ManageAccounts color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={row.original.user?.isBlocked ? 'Enable User' : 'Disable User'}>
                  <IconButton onClick={() => enableDisableUser(row.original.user?.id, 0, 10)}>
                    {row.original.user?.isBlocked ? (
                      <NoAccounts color="primary" />
                    ) : (
                      <NoAccounts color="error" />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        ),
      },
    ], []);
  
    React.useEffect(() => {
      getChildUsers(0, 100);
    }, []);
  
    return (
      <>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 1, mb: 3 }}>
          <Button
            variant="contained"
            color="info"
            onClick={() => handleEditUser(null)}
            sx={{
              typography: 'captionMedium',
              color: '#F9FAFB',
              backgroundColor: '#0B85BA',
              py: 1,
              px: 2.5,
              borderRadius: '8px',
            }}
          >
            Add User
          </Button>
        </Stack>
        <CustomTable
          initialState={{
            sorting: [{ id: 'lastName', desc: false }],
            pagination: { pageSize: 100, pageIndex: 0 },
          }}
          paginationDisplayMode="pages"
          columns={columns}
          data={childUsers}
        />
        <ClientModifyUserModal
          open={modalOpen}
          userId={userIdToEdit}
          onClose={handleModalClose}
        />
      </>
    );
  }
  