import React from 'react';
import {
  Typography,
  Stack,
  Grid,
} from '@mui/material';

export function FeatureSection({ features }) {
  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="stretch"
      xs={16}
      columns={12}
    >
      {features.map((card) => (
        <Grid
          item
          key={card.id}
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flex: 1,
            flexGrow: 1,
          }}
        >
          <Stack
            gap={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              border: '1px solid #F3F4F6',
              borderRadius: '12px',
              flex: 1,
              alignSelf: 'stretch',
              backgroundColor: '#F9FDFF',
              textAlign: 'center',
              p: 3,
            }}
          >
            <Typography variant="h5Bold">{card.heading}</Typography>
            <Typography variant="body1Normal" color="#6B7280">{card.subHeading}</Typography>
            <img src={card.img} alt="feature" />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
