import React, { useMemo } from 'react';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar, Box, Button, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { removeDataFromLocalStorage } from 'Utils/helper';
import { useCommonUserProvider } from '../Providers/Common/User';
import api from '../Utils/api';
import {
  checkHybridConsultant,
  selectUserDetails,
  selectUserRole, setLogout, setUser,
} from '../Redux/Feature/user';
import LogoutIcon from './Icons/Logout.icon';
import { stringAvatar } from 'Utils/avatar';

const UserMenuButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body2Normal,
  padding: theme.spacing(1, 2),
  color: '#374151',
  justifyContent: 'space-between',

  '&:hover': {
    backgroundColor: '#F9FAFB',
  },

  '.MuiButton-endIcon': {
    marginRight: 0,
  },
}));

export default function UserMenu() {
  const { getHybridUserDetails } = useCommonUserProvider();

  const { isConsultant, isVendor } = useSelector(selectUserRole);
  const userDetails = useSelector(selectUserDetails);
  // retaining options
  const isHybridConsultantSelector = localStorage.getItem('isHybridConsultant');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [switchChecked, setSwitchChecked] = React.useState(isVendor);

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const logout = () => {
    removeDataFromLocalStorage();
    delete api.defaults.headers.Role;
    delete api.defaults.headers.Authorization;
    dispatch(setLogout());
    navigate('/');
  };

  const handleRoleChange = (e) => {
    setSwitchChecked(!switchChecked);
    const isHybridSwitchCheck = switchChecked ? '?isSwitch=false' : '?isSwitch=true';
    getHybridUserDetails(isHybridSwitchCheck)
      .then((res) => {
        localStorage.removeItem('permission');
        localStorage.removeItem('userId');
        localStorage.removeItem('role');
        localStorage.setItem('role', res?.data?.result?.role);
        localStorage.setItem('userId', res?.data?.result?.userId);
        localStorage.setItem('permission', JSON.stringify(res?.data?.result?.permissions) ?? JSON.stringify([]));
        window.location.href = '/dashboard';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        aria-label='Expand User Menu'
        onClick={handleClick}
        sx={{ padding: 0, ml: 1 }}
      >
        {userDetails?.profile_image ? <Avatar
          src={userDetails.profile_image}
          sx={{ w: 4, h: 4 }}
          alt={`${userDetails.first_name} ${userDetails.last_name}`}
        /> :
        userDetails?.first_name && <Avatar
          className="!rounded-full !text-xs"
          {...stringAvatar(userDetails.first_name ?? "ProLegion User", {})}
          sx={{ w: 4, h: 4 }}
        />
                    }
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={closeMenu}
        PaperProps={{
          elevation: 1,
          sx: {
            width: '224px',
            mt: 5,
            borderRadius: '8px',
            '.MuiMenu-list': {
              pt: 0,
              pb: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack sx={{ py: 1, px: 2 }}>
          <Typography variant='body2Semibold' color='#111928'>
            {/* {!isConsultant && !userDetails?.organisation?.name ? `${userDetails?.first_name} ${userDetails?.last_name}` : userDetails?.organisation?.name}
            {isConsultant && `${userDetails?.first_name} ${userDetails?.last_name}`} */}
            {`${userDetails?.first_name} ${userDetails?.last_name}`}
          </Typography>
          <Typography variant='captionNormal' color='#182743'>{userDetails.company}</Typography>
          <Typography variant='captionNormal' color='#6B7280'>
            ProLegion ID:
            {' '}
            {userDetails.username}
          </Typography>
        </Stack>
        <Stack sx={{ py: 0.5, borderTop: '1px solid #F3F4F6', borderBottom: '1px solid #F3F4F6' }}>
          <UserMenuButton
            fullWidth
            variant='text'
            onClick={() => {
              navigate('/dashboard/myaccount');
              closeMenu();
            }}
          >
            {isConsultant ? 'My Profile' : 'Company Profile'}
          </UserMenuButton>
          <UserMenuButton
            fullWidth
            variant='text'
            onClick={() => {
              navigate('/dashboard/settings');
              closeMenu();
            }}
          >
            Settings
          </UserMenuButton>
          {isHybridConsultantSelector && (
            <Box padding='1rem'>
              <IOSSwitch
                checked={switchChecked}
                onChange={(e) => handleRoleChange(e)}
              />
              &nbsp;&nbsp;&nbsp;
              <h1 className='text-sm text-center inline font-semibold'>
                Switch to
                {' '}
                {switchChecked ? 'Consultant' : 'Vendor'}
              </h1>
            </Box>
          )}
          {/* {isHybridConsultantSelector && <UserMenuButton
            fullWidth
            variant='text'
            onClick={() => {

            }}
          >
            Switch to {isConsultant ? 'Consultant' : 'Vendor'}
          </UserMenuButton>} */}
        </Stack>
        <Box sx={{ py: 0.5 }}>
          <UserMenuButton
            fullWidth
            variant='text'
            onClick={logout}
            endIcon={<LogoutIcon />}
            sx={{ color: '#E02424' }}
          >
            Logout
          </UserMenuButton>
        </Box>
      </Menu>
    </>
  );
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#2EBAA3',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
