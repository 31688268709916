import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { UpdateUser } from "Pages/editProfile/api";
import { useAlertProvider } from "Providers/util/Alert";
import { selectIsUserLoggedIn } from "Redux/Feature/user";
import { useSelector } from "react-redux";

const types = {
  are_references_public: {
    label: "Public References Visibility",
  },
  are_resources_public: {
    label: "Public Resources Visibility",
  },
};

const ResourcesToogle = ({ profile, type }) => {
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  if (!isLoggedIn) return;

  const [checked, setChecked] = useState(false);

  async function updatePublicVisiblity(updatedValue) {
    try {
      await UpdateUser({ [type]: updatedValue });
      // setPopupMessage(`${types[type]?.label} updated successfully`)
    } catch (err) {
      console.warn(err);
      // setPopupMessage(err?.message);
    }
  }

  const handleChange = async (event) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);

    updatePublicVisiblity(updatedValue);
  };

  useEffect(() => {
    if (profile) {
      setChecked(profile[type]);
    }
  }, [profile]);

  return (
    <>
      <Stack direction={"row"} justifyContent={"end"} alignItems={"center"}>
        <Typography variant="body2Normal">{types[type]?.label}</Typography>
        <Switch checked={checked} onChange={handleChange} />
      </Stack>
    </>
  );
};

export default ResourcesToogle;
