import { Box, Typography } from '@mui/material'
import ReactDropzone from 'Components/Common/ReactDropzone'
import React from 'react'
import { Controller } from 'react-hook-form'

function HookFormUploadFile({
  name,
  // type,
  control,
  // validation,
  // placeholder,
  // required,
  // errors,
  setDetails,
  files
}) {
  return (
    <Box sx={{paddingTop: "1rem"}}>
      <Controller
        name={name}
        control={control}
        render={({field: {onChange}})=>{
          return(
            <ReactDropzone files={files} setDetails={setDetails} />
          )
        }} 
      />
    </Box>
  )
}

export default HookFormUploadFile