import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { stripHtmlTags } from "Utils/constants";

const InvoiceTextDark = styled(Typography)(() => ({
  color: '#1A1C21',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '8px !important',
  fontStyle: 'normal',
  fontWeight: 200,
  lineHeight: '14px', /* 140% */
}));

const InvoiceTextDarkDiv = styled(Box)(() => ({
  color: '#1A1C21',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '8px !important',
  fontStyle: 'normal',
  lineHeight: '14px', /* 140% */
}));

const InvoiceTextLight = styled(Typography)(() => ({
  color: '#5E6470',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '8px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '14px', /* 140% */
}));

const InvoiceTextLabel = styled(Typography)(() => ({
  color: '#5E6470',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '8px',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '14px', /* 175% */
  letterSpacing: '0.32px',
  textTransform: 'uppercase',
}));

const Invoice = ({
  invoiceDetails,
  jobsOfInvoice,
  clientDetails,
  vendorDetails,
  headerKeys,
  type = 'fixed'
}) => {
  return (
    <Box
      id='divToPrint'
      sx={{ margin: '0px auto', width: '510px', background: '#F9FAFC' }}
    >
      <Stack
          id='divToPrintSecond'
          sx={{
            p: 4
          }}
        >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={6}
        >
          <Stack direction="row" gap="20px">
            {(vendorDetails?.profilePhoto || vendorDetails?.profile_image) ? (
              <img
                src={vendorDetails?.profilePhoto || vendorDetails?.profile_image}
                alt="logo"
                style={{ width: "100px", height: "100px", borderRadius: "8px" }}
              />
            ) : (
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "8px",
                  background: "#F3F4F6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: '26px'
                }}
              >
                {vendorDetails?.firstName?.slice(0, 1)}
              </Box>
            )}
            <Stack direction="column">
              <Typography
                sx={{
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: "34px",
                  color: "#1A1C21",
                }}
              >
                {vendorDetails?.company ||
                  `${vendorDetails?.firstName} ${vendorDetails?.lastName}`}
              </Typography>
              <InvoiceTextLight>{vendorDetails?.address}</InvoiceTextLight>
              <InvoiceTextLight>
                {vendorDetails?.city} {vendorDetails?.state}{" "} {vendorDetails?.country}
              </InvoiceTextLight>
            </Stack>
          </Stack>
          <Box sx={{ textAlign: "right" }}>
            <Typography
              sx={{
                color: "#B2B7C2",
                textAlign: "right",
                leadingTrim: "both",
                textEdge: "cap",
                fontFamily: "Plus Jakarta Sans",
                fontSize: "26px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "70px" /* 111.111% */,
                letterSpacing: "-1.08px",
              }}
            >
              Invoice
            </Typography>
            <Typography
              sx={{
                color: "#5E6470",
                textAlign: "right",
                fontFamily: "Plus Jakarta Sans",
                fontSize: '12px',
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "16px" /* 133.333% */,
                letterSpacing: "0.24px",
              }}
            >
              #{invoiceDetails.invoiceno}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="row"
          flex={1}
          alignItems="flex-start"
          mb={2}
          sx={{
            width: "100%",
            borderTop: "1px solid #D7DAE0",
            borderBottom: "1px solid #D7DAE0",
            "> *": {},
          }}
        >
          <Box sx={{ flex: 1, pt: 3 }}>
            <InvoiceTextLabel>Billed To</InvoiceTextLabel>
            <>
                <InvoiceTextDark>
                  {clientDetails?.company || clientDetails?.companyName || `${clientDetails?.firstName || ''} ${clientDetails?.lastName || ''}`}
                </InvoiceTextDark>
                <InvoiceTextLight>{clientDetails?.address || ""}</InvoiceTextLight>
                <InvoiceTextLight>{clientDetails?.email || ""}</InvoiceTextLight>
              </>
          </Box>
          <Box
            sx={{
              py: 3,
              borderLeft: "1px solid #D7DAE0",
              borderRight: "1px solid #D7DAE0",
              flex: 1,
            }}
          >
            <Box pl={3}>
              <InvoiceTextLabel
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Invoice Date
              </InvoiceTextLabel>
              <InvoiceTextDark>
                {invoiceDetails?.createdAt &&
                  moment(new Date(invoiceDetails?.createdAt)).format('DD MMM, YYYY')
                }
              </InvoiceTextDark>
              <InvoiceTextLabel>Due Date</InvoiceTextLabel>
              <InvoiceTextDark>
                {invoiceDetails?.dueDate &&
                  moment(new Date(invoiceDetails?.dueDate)).format('DD MMM, YYYY')
                }
              </InvoiceTextDark>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
            pt={3}
          >
            <InvoiceTextLabel>Amount Due</InvoiceTextLabel>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: '#0B85BA',
                padding: '4px',
                marginLeft: '10px'
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "right",
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: '10px',
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px" /* 142.857% */,
                  letterSpacing: "-0.14px",
                }}
              >
                {invoiceDetails?.currency_code === 'USD' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.grand_total_amount) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.grand_total_amount)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={0.5}
          mb={2}
        >
          <InvoiceTextLabel>Period :</InvoiceTextLabel>
          <InvoiceTextDark>
            
            {invoiceDetails?.endDate ? invoiceDetails?.startDate && moment(new Date(invoiceDetails?.startDate)).format('DD MMM, YYYY') : invoiceDetails?.startDate && moment(new Date(invoiceDetails?.startDate)).format('MMM, YYYY')}{" "}
            {invoiceDetails?.endDate ? '-' : ''} {" "}
            {invoiceDetails?.endDate && moment(new Date(invoiceDetails?.endDate)).format('DD MMM, YYYY')}
          </InvoiceTextDark>
        </Stack>
        <Table
          sx={{
            mb: 2,
            ".MuiTableCell-root": {
              p: 1,
              "&:first-of-type": {
                pl: 0,
              },
              "&:last-of-type": {
                pr: 0,
                textAlign: "right",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <InvoiceTextLabel>#</InvoiceTextLabel>
              </TableCell>
              {
                headerKeys.map((headers) => {
                  return <TableCell>
                    <InvoiceTextLabel>{headers}</InvoiceTextLabel>
                  </TableCell>
                })
              }
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              ".MuiTableCell-root": {
                pb: "20px",
              },
            }}
          >
            {type === 'fixed' && jobsOfInvoice.map((jobdetails, key) => {
              const sum = jobdetails?.timesheets?.reduce((acc, curr) => {
                return acc + Number(curr?.total_timesheet_days || 0);
              }, 0);
              return (
                <TableRow>
                  <TableCell>
                    <InvoiceTextDark>{key + 1}</InvoiceTextDark>
                  </TableCell>
                  <TableCell>
                    <InvoiceTextDark>{jobdetails.name}</InvoiceTextDark>
                  </TableCell>
                  <TableCell>
                    <InvoiceTextDark>
                      {jobdetails?.consultant?.firstName}{" "}
                      {jobdetails?.consultant?.lastName}
                    </InvoiceTextDark>
                  </TableCell>
                  <TableCell>
                  <InvoiceTextDark>
                    { jobdetails?.total_timesheet_days || sum }
                    </InvoiceTextDark>
                  </TableCell>
                  <TableCell>
                    <InvoiceTextDark>
                    {
                    invoiceDetails?.currency_code === 'USD' 
                      ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(jobdetails?.rate)
                      : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(jobdetails?.rate)
                    }
                    { jobdetails?.rate_type === 'HOURLY' ? "/Hr" : "/Month" }
                    </InvoiceTextDark>
                  </TableCell>
                  <TableCell>
                    <InvoiceTextDark>{jobdetails?.rate_type === 'HOURLY' ? (!!jobdetails?.amount ? jobdetails?.amount/jobdetails.rate : jobdetails?.total_hours) : (jobdetails?.total_hours || jobdetails?.hours)}</InvoiceTextDark>
                  </TableCell>
                  <TableCell>
                    <InvoiceTextLight>
                      {invoiceDetails?.currency_code === 'USD' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(jobdetails?.rate_type === 'HOURLY' ? jobdetails?.amount || jobdetails?.total_amount : jobdetails?.rate) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(jobdetails?.rate_type === 'HOURLY' ? jobdetails?.amount || jobdetails?.total_amount : jobdetails?.rate)}
                    </InvoiceTextLight>
                  </TableCell>
                </TableRow>
              );
            })}
            {
              type === 'flexible' && jobsOfInvoice.map((project, key) => {
                return (
                  <>
                    <TableRow>
                      <TableCell sx={{ flexDirection:'column' }}>
                        <InvoiceTextDark>{key + 1}</InvoiceTextDark>
                      </TableCell>
                      <TableCell>
                        <InvoiceTextDark>{project.project?.title}</InvoiceTextDark>
                      </TableCell>
                      <TableCell>
                        <InvoiceTextDark>
                          {
                            invoiceDetails?.currency_code === 'USD'
                              ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(project?.amount)
                              : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(project?.amount)
                          }
                        </InvoiceTextDark>
                      </TableCell>
                      <TableCell>
                        <InvoiceTextDark>{(project?.jobs || []).map(i => i?.name || '').join(', ')}</InvoiceTextDark>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            }
            {
              type === 'jobs' && jobsOfInvoice.map((job, key) => {
                return (
                  <TableRow>
                    <TableCell>
                      <InvoiceTextDark>{key + 1}</InvoiceTextDark>
                    </TableCell>
                    <TableCell>
                      <InvoiceTextDark>{job?.job?.name || job?.name}</InvoiceTextDark>
                    </TableCell>
                    <TableCell>
                      <InvoiceTextDark>
                      {
                      invoiceDetails?.currency_code === 'USD' 
                        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.sub_total_amount)
                        : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.sub_total_amount)
                      }
                      </InvoiceTextDark>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        <Stack
          direction="column"
          sx={{
            alignSelf: "flex-end",
            width: "200px",
            div: {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #D7DAE0",
              py: "10px",
              "&:nth-last-child(2), &:last-of-type": {
                borderBottom: "2px solid #0B85BA",
              },
            },
          }}
        >
          {jobsOfInvoice && jobsOfInvoice?.length > 1 ? <div>
            <InvoiceTextDark>Subtotal</InvoiceTextDark>
            <InvoiceTextLight>
              {invoiceDetails?.currency_code === 'USD' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.sub_total_amount) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.sub_total_amount)}
              </InvoiceTextLight>
          </div> : <div></div>}
          {
            invoiceDetails?.adjustment && Object.keys(invoiceDetails?.adjustment)?.length > 0 && Object.keys(invoiceDetails?.adjustment).map((adjust) => {
              return adjust ? <div>
                <InvoiceTextDark>{adjust}</InvoiceTextDark>
                <InvoiceTextLight>
                  {invoiceDetails?.currency_code === 'USD' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.adjustment[adjust]) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.adjustment[adjust])}
                </InvoiceTextLight>
              </div> : <></>;
            })
          }
          {/* <div>
            <InvoiceTextDark>Total</InvoiceTextDark>
            <InvoiceTextLight>
            {invoiceDetails?.currency_code === 'USD' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.grand_total_amount) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.grand_total_amount)}
            </InvoiceTextLight>
          </div> */}
          <div>
            <InvoiceTextDark sx={{ color: "#0B85BA" }}>
              Amount Due
            </InvoiceTextDark>
            <InvoiceTextDark sx={{ color: "#0B85BA" }}>
              {invoiceDetails?.currency_code === 'USD' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoiceDetails?.grand_total_amount) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(invoiceDetails?.grand_total_amount)}
            </InvoiceTextDark>
          </div>
        </Stack>
        {invoiceDetails?.comments && stripHtmlTags(invoiceDetails?.comments).trim() && (
          <>
            <InvoiceTextLabel sx={{ mt: 6, mb: 0.5 }}>
              Payment Terms and Details :
            </InvoiceTextLabel>
            <InvoiceTextDarkDiv dangerouslySetInnerHTML={{ __html: invoiceDetails?.comments }} />
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Invoice;
