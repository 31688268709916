import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Avatar } from '@mui/material';
import Button from 'Components/Common/semantic_tags/Button';
import Typography from 'Components/Common/semantic_tags/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserRole } from 'Redux/Feature/user';
import { stringAvatar } from 'Utils/avatar';
import SendInvitedJobModal from './SendInvitedJobModal';

const ResourceCard = ({
  first_name,
  last_name,
  profileLink,
  email,
  linkdin,
  phone,
  location,
  profile_image,
  skills,
  about_me,
  availability,
  resume,
  company,
  mainRole,
  ...rest
}) => {
  
  const [modalOpen, setModalOpen] = useState(false);
  const { isVendor } = useSelector(selectUserRole);
  const handleOpenJobModal = ()=> {
    setModalOpen(true);
  }

  return (
    <div className="border rounded-lg p-4 mb-4 shadow-md">
      <div className="flex items-center">
        {
          profile_image
            ? <img src={profile_image} className="w-16 h-16 rounded-lg object-cover" alt="Profile" />
            : <Avatar
              variant="square"
              {...stringAvatar(first_name ?? "ProLegion User")}
              className="!rounded-lg !text-xl font-bold !w-16 !h-16"
            />
        }
        <div className="ml-4 w-[80%]">
          <div className="flex items-center cursor-pointer" onClick={() => window.open(window.location.origin + `/${rest?.username || ""}`, '__blank')}>
            <Typography className="text-xl font-semibold">{isVendor ? `${first_name || ''} ${last_name || ''}` : `${company || ''}`}</Typography>
          </div>
          <div className="text-sm text-gray-500 flex items-center">
            {/* {linkdin && <Typography onClick={() => window.open(linkdin, '__blank')}  className="text-blue-400 mr-2 cursor-pointer"><LinkedInIcon className='font-[10px]' /></Typography>} */}
            {mainRole === 'VENDOR' && <Typography className="mr-2">Experience: {rest?.industry_experience > 0 ? rest?.industry_experience: 'fresher'}</Typography>}
            {/* {email && <a href={`mailto:${email}`} className="mr-2">{email}</a>}
            {phone && <Typography className="underline">{phone}</Typography>} */}
          </div>
          <div className="text-sm text-gray-500">{location}</div>
        </div>
        <div className={`flex gap-4 items-center justify-end`}>
          {isVendor && <Button onClick={handleOpenJobModal} className="border-[#3985B6]" title={"Invite for a Job"} />}
          {modalOpen && <SendInvitedJobModal openModal={modalOpen} setModalOpen={setModalOpen} consultantId={rest?.user_id}/>}
          {/* <div className='text-xs text-[#3985B6] font-bold cursor-pointer' onClick={() => window.open(window.location.origin + `/${rest?.username || ""}`, '__blank')}><LaunchIcon className='text-[10px]' />View</div>
          {resume && <div className='text-xs text-[#3985B6] font-bold cursor-pointer' onClick={() => window.open(resume, '__blank')}><DownloadForOfflineIcon className='text-[10px]' />Resume</div>} */}
        </div>
      </div>
      <div className="mt-4">
        {skills && skills.length > 0 && <div className="text-sm mt-2 mb-4">
          <Typography className="text-md font-bold">SKILLS</Typography>
          <div>{skills.join(', ')}</div>
        </div>}
        {about_me && <div className="text-sm mt-2">
          <Typography className="text-md font-bold">ABOUT</Typography>
          <div className="text-xs uniform-font max-h-[50px] overflow-hidden text-ellipsis"
            style={{
              color: '#6B7280',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }} dangerouslySetInnerHTML={{ __html: about_me }}></div>
        </div>}
        {mainRole === 'VENDOR' && availability && <div className="text-sm mt-2">
          <Typography className="text-md font-bold">Availability</Typography>
          <div>{availability}</div>
        </div>}
      </div>
    </div>
  );
};

export default ResourceCard;
