import themeBase from '../themeBase';

export const MuiFormControlLabelOverride = {
  styleOverrides: {
    root: {
      gap: themeBase.spacing(1),
      '& .MuiFormControlLabel-label': {
        ...themeBase.typography.body2Semibold,
        color: '#111928',
        transform: 'none',
      },

      '&.Mui-focused': {
        color: themeBase.palette.primary.main,
      },
    },
  },
};
