import { createSlice } from "@reduxjs/toolkit";

export const createTimesheetSlice = createSlice({
    name: "createtimesheet",
    initialState: {
        value: false,
        timesheetToken: '',
        timesheetDate: ''
    },
    reducers: {
        setTimesheetSliceOn: (state) => {
            state.value = true;
        },
        setTimesheetSliceOff: (state) => {
            state.value = false;
        },
        setTimesheetToken: (state, action) => {
            state.timesheetToken = action.payload;
        },
        setSelectedTimesheetDate: (state, action) => {
            state.timesheetDate = action.payload
        }
    },
});

export const { setTimesheetSliceOn, setTimesheetSliceOff, setTimesheetToken, setSelectedTimesheetDate } = createTimesheetSlice.actions;

export default createTimesheetSlice.reducer;
