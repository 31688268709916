import React from 'react';

export default function ConsultantsIcon({ color, size = '16px' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path d="M8 7.57895C7.17845 7.57895 6.37534 7.3567 5.69225 6.94031C5.00915 6.52391 4.47674 5.93208 4.16235 5.23964C3.84795 4.54721 3.76569 3.78527 3.92597 3.05019C4.08625 2.3151 4.48186 1.63988 5.06279 1.10991C5.64371 0.579945 6.38386 0.219033 7.18962 0.0728154C7.99539 -0.0734023 8.83059 0.0016419 9.58961 0.288458C10.3486 0.575274 10.9974 1.06098 11.4538 1.68416C11.9102 2.30733 12.1538 3.03999 12.1538 3.78947C12.1526 4.79416 11.7146 5.75738 10.9359 6.46781C10.1571 7.17823 9.10129 7.57783 8 7.57895ZM8 1.68421C7.54358 1.68421 7.09741 1.80768 6.71792 2.03901C6.33842 2.27034 6.04263 2.59914 5.86797 2.98383C5.69331 3.36851 5.64761 3.79181 5.73665 4.20019C5.82569 4.60857 6.04548 4.98369 6.36822 5.27812C6.69095 5.57255 7.10214 5.77305 7.54979 5.85429C7.99744 5.93552 8.46144 5.89383 8.88312 5.73448C9.30479 5.57514 9.6652 5.30531 9.91878 4.9591C10.1723 4.61289 10.3077 4.20586 10.3077 3.78947C10.3077 3.23112 10.0646 2.69564 9.63178 2.30083C9.19901 1.90602 8.61204 1.68421 8 1.68421Z" fill={color || '#9CA3AF'} />
      <path d="M13.0769 16H2.92308C2.67826 16 2.44347 15.9113 2.27036 15.7534C2.09725 15.5954 2 15.3812 2 15.1579V12.6316C2.00147 11.5153 2.4882 10.4451 3.35343 9.65576C4.21867 8.86643 5.39176 8.42239 6.61538 8.42105H9.38462C10.6082 8.42239 11.7813 8.86643 12.6466 9.65576C13.5118 10.4451 13.9985 11.5153 14 12.6316V15.1579C14 15.3812 13.9027 15.5954 13.7296 15.7534C13.5565 15.9113 13.3217 16 13.0769 16ZM3.84615 14.3158H12.1538V12.6316C12.1538 11.9616 11.8621 11.319 11.3428 10.8452C10.8234 10.3714 10.1191 10.1053 9.38462 10.1053H6.61538C5.88094 10.1053 5.17657 10.3714 4.65724 10.8452C4.13791 11.319 3.84615 11.9616 3.84615 12.6316V14.3158Z" fill={color || '#9CA3AF'} />
    </svg>
  );
}
